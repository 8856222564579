import React from "react";
import Button from "react-bootstrap/button";


const ButtonCustom = ({ variant, type, ...props }) => {
    variant = variant === undefined ? "info" : variant;
    type = type === undefined ? "button": type;

    return (
        <Button variant={variant} type={type} {...props} ></Button>
    )
}

export default ButtonCustom;

