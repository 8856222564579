import React, { useState, useEffect } from "react";
import "./InputCustom.scss";
import Form from "react-bootstrap/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

var nStyle = {};
// var lStyle = {};


const Percent = styled.div`
    display: inline-block;
    &:after {
        color: ${props => props.color};
        content: '${props => props.content}';
        font-family: "Ubuntu Mono";
        width: 1em;
        height: 1em;
        position: absolute;
        top: 47%;
        left: ${props => props.align === "left" ? ((((props.len === 0 ? 0 : props.len - 1) * 8) + 22) > 137 ? 137 : (((props.len === 0 ? 0 : props.len - 1) * 8) + 22)) + "px" : "unset"};
        right: ${props => props.align === "right" ? (((props.content.toString().length === 0 ? 0 : props.content.toString().length - 1) * 8) + 5) + "px" : "unset"};
        display: block;
        font-size: 16px;
        pointer-events: none;
        transform: translateY(-0%);
    }
`;

function SearchInputCustom(props) {
    let { id, size, type, name, label, onSearchClick, prefixValue, style, autoComplete, register, errors, errorStyle, placeholder, labelStyle, formGroupStyle, suffix, suffixContent, suffixAlign, onChange, ...rest } = props;
    size = (size === undefined) ? "small" : size;

    const [len, setLen] = useState(0);

    if (errors && errors[name]) {
        nStyle = { ...style, borderBottomColor: "rgb(235, 79, 107)" }
    } else {
        nStyle = style;
    }

    if (suffix !== undefined && suffix === true) {
        nStyle = { paddingRight: "25px", ...nStyle }
        formGroupStyle = { ...formGroupStyle, position: "relative" }
    }

    const onValueChange = (e) => {
        if (suffix === true) {
            setLen(e.target.value.toString().length);
        }

        if (onChange !== undefined && typeof onChange === "function")
            return onChange(e);
    }

    const handleKeyPress = (target) => {
        if (target.charCode === 13) {
            onSearchClick();
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (suffix === true && prefixValue !== undefined) {
                setLen(`${prefixValue}`.length);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [prefixValue]);

    return (
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
            <Form.Group controlId={`${id}-group`} style={formGroupStyle} >
                {
                    suffix ? (
                        <Percent len={len} color={"#495057"} content={suffixContent === undefined ? '%' : suffixContent} key={`${id}-suffix`} align={suffixAlign} />
                    ) : (
                            <></>
                        )
                }
                <Form.Control type={type} name={name} autoComplete={autoComplete} ref={register} placeholder={placeholder} {...rest} onChange={e => onValueChange(e)} style={nStyle} onKeyPress={handleKeyPress} />
            </Form.Group>
            <span style={{ cursor: "pointer", paddingTop: "10px", marginBottom: "16px", background: formGroupStyle.background, paddingRight: "10px", paddingLeft: "10px" }} onClick={onSearchClick} ><FontAwesomeIcon size="1x" icon={faSearch} /></span>
        </div>
    )
}

export default SearchInputCustom;
