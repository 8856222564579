const { isNumeric } = require('./utils.js');
require('./utils.js')

/* Calculates total carbon footprint (tonnes CO<sub>2</sub>e) for metal used.  */
function footprintForTransportCalc(amount, unitValue,scope1,scope3) {

    const transportTonnes = (unitValue === "tonnes" || unitValue === "litres") ? amount : 0
    // if (!isNumeric(amount)) throw TypeError("amount must be numeric")
    // if(amount < 0) throw RangeError("'amount' can't be less than 0")
    if (!isNumeric(scope1)) throw TypeError("'scope1' must be numeric")
    if (!isNumeric(scope3)) throw TypeError("'scope3' must be numeric")

    const scope1CarbonFactor = scope1 === undefined || scope1 === "" ? "scope1 value not defined" : scope1;
    const scope3CarbonFactor = scope3 === undefined || scope3 === "" ? "scope3 value not defined" : scope3;

    const CarbonFootprintTransport = transportTonnes * (scope1CarbonFactor + scope3CarbonFactor);
    return CarbonFootprintTransport;
}

module.exports = {
    footprintForTransportCalc: footprintForTransportCalc,
}