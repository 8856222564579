
const initialState = {
    projectId: "",
    projectName: "",
    salesStage: [],
    sector: [],
    deliveryLine: [],
    typeOfEngagement: [],
    country: [],
    activity: [],
    ctFuel: false,
    ctElectricity: false,
    ctDelivery: false,
    ctDataCentre: false,
    ctWater: false,
    ctPaper: false,
    ctPlastic: false,
    ctMetal: false,
    ctTransport: false,
    ctElectricalItems: false,
    ctAviation: false,
    ctSeaFreight: false,
    testProject: "",
    flagCf: "",
}

const filterReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_FILTER_PROJECT_ID":
            state = {
                ...state,
                projectId: action.payload.projectId
            }
            break;
        case "SET_FILTER_PROJECT_NAME":
            state = {
                ...state,
                // projectName: (action.payload.projectName !== "" || action.payload.projectId !== "" ? action.payload.projectName || action.payload.projectId : null)
                projectName: action.payload.projectName
            }
            break;
        case "SET_FILTER_SALES_STAGE":
            state = {
                ...state,
                salesStage: (action.payload.salesStage === null || action.payload.salesStage === undefined) ? [] : action.payload.salesStage
            }
            break;
        case "SET_FILTER_SECTOR":
            state = {
                ...state,
                sector: (action.payload.sector === null || action.payload.sector === undefined) ? [] : action.payload.sector
            }
            break;
        case "SET_FILTER_DELIVERY_LINE":
            state = {
                ...state,
                deliveryLine: (action.payload.deliveryLine === null || action.payload.deliveryLine === undefined) ? [] : action.payload.deliveryLine
            }
            break;
        case "SET_FILTER_TYPE_OF_ENGAGEMENT":
            state = {
                ...state,
                typeOfEngagement: (action.payload.typeOfEngagement === null || action.payload.typeOfEngagement === undefined) ? [] : action.payload.typeOfEngagement
            }
            break;
        case "SET_FILTER_COUNTRY":
            state = {
                ...state,
                country: (action.payload.country === null || action.payload.country === undefined) ? [] : action.payload.country
            }
            break;
        case "SET_FILTER_ACTIVITY":
            state = {
                ...state,
                activity: (action.payload.activity === null || action.payload.activity === undefined) ? [] : action.payload.activity
            }
            break;
        case "SET_FILTER_CT_FUEL":
            state = {
                ...state,
                ctFuel: action.payload.ctFuel
            }
            break;
        case "SET_FILTER_CT_ELECTRICITY":
            state = {
                ...state,
                ctElectricity: action.payload.ctElectricity
            }
            break;
        case "SET_FILTER_CT_DELIVERY":
            state = {
                ...state,
                ctDelivery: action.payload.ctDelivery
            }
            break;
        case "SET_FILTER_CT_DATACENTRE":
            state = {
                ...state,
                ctDataCentre: action.payload.ctDataCentre
            }
            break;
        case "SET_FILTER_CT_WATER":
            state = {
                ...state,
                ctWater: action.payload.ctWater
            }
            break;
        case "SET_FILTER_CT_PAPER":
            state = {
                ...state,
                ctPaper: action.payload.ctPaper
            }
            console.log(state, "state in filter ct apaer");
            break;
        case "SET_FILTER_CT_PLASTIC":
            state = {
                ...state,
                ctPlastic: action.payload.ctPlastic
            }
            console.log(state, "state in filter ct plastic");
            break;
        case "SET_FILTER_CT_METAL":
            state = {
                ...state,
                ctMetal: action.payload.ctMetal
            }
            break;
        case "SET_FILTER_CT_TRANSPORT":
            state = {
                ...state,
                ctTransport: action.payload.ctTransport
            }
            break;
        case "SET_FILTER_CT_ELECTRICAL_ITEMS":
            state = {
                ...state,
                ctElectricalItems: action.payload.ctElectricalItems
            }
            break;
        case "SET_FILTER_CT_AVIATION":
            state = {
                ...state,
                ctAviation: action.payload.ctAviation
            }
            break;
        case "SET_FILTER_CT_SEA_FREIGHT":
            state = {
                ...state,
                ctSeaFreight: action.payload.ctSeaFreight
            }
            break;
        case "SET_FILTER_TEST_PROJECT":
            state = {
                ...state,
                testProject: (action.payload.testProject === null || action.payload.testProject === undefined) ? [] : action.payload.testProject
            }
            break;
        case "SET_FILTER_FLAG_CF":
            state = {
                ...state,
                flagCf: (action.payload.flagCf === null || action.payload.flagCf === undefined) ? [] : action.payload.flagCf
            }
            break;
        case "CLEAR_FILTER":
            state = initialState;
            break;
        default:
            break;
    }
    return state;
}

export default filterReducer;