import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import convert from "convert-units";
import { Element } from "react-scroll";
import { footprintForFuelByVolume } from "../../calculatorFunctions/fuel_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const unitOptions = {
    diesel: [{ value: "litre", label: "l" }, { value: "gallon", label: "gal" }],
    petrol: [{ value: "litre", label: "l" }, { value: "gallon", label: "gal" }],
    cng: [{ value: "litre", label: "l" }, { value: "gallon", label: "gal" }],
    electric: [{ value: "killowatt hour", label: "kWH" }],
    lpg: [{ value: "litre", label: "l" }, { value: "gallon", label: "gal" }],
    hybrid: [{ value: "litre", label: "l" }, { value: "gallon", label: "gal" }]
};

const CollapseFuelConsumptionCustom = (props) => {

    let { collapseControll, id, inputName, inputLabel, inputValue, inputPlaceholder, selectName, selectLabel, selectValue, primaryCountry, control, register, errors } = props;

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [countryElectricFactor, setCountryElectricFactor] = useState({});
    const [unit, setUnit] = useState((selectValue === undefined || selectValue === "") ? unitOptions[selectName][0] : selectValue);

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions[selectName]);
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const inputTextChange = (val) => {
        setCF((val !== undefined && val !== "") ? val : 0);
    }

    const setCF = (val) => {
        var name = inputName;
        var cf = 0;
        let consumption = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].consumption !== undefined ? props.master.fuelFactorMaster[name].consumption : 0;
        let upstream = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].upstream !== undefined ? props.master.fuelFactorMaster[name].upstream : 0;
        let year = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].year !== undefined ? props.master.fuelFactorMaster[name].year : 0;
        let source = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].source !== undefined ? props.master.fuelFactorMaster[name].source : null;
        let uFactor = 0;
        if (inputName !== "electric") {
            cf = footprintForFuelByVolume(convert(val).from(unit.label).to("l"), consumption, upstream);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, parent: "fuelConsumption", cf: { value: cf, consumption, upstream, year, source } });
            setCarbonFootprint(cf);
        } else {
            uFactor = countryElectricFactor[primaryCountry] !== undefined && countryElectricFactor[primaryCountry].efficiency !== undefined ? countryElectricFactor[primaryCountry].efficiency : 0;
            cf = footprintForFuelByVolume(val, 0, uFactor);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, parent: "fuelConsumption", cf: { value: cf, consumption: 0, upstream: uFactor, year: new Date().getFullYear(), source } });
            setCarbonFootprint(cf);
        }

    }

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(countryElectricFactor).length === 0) {
                    setCountryElectricFactor(props.master.countryElectricEfficiency);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [props.master.status]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCF(inputValue);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countryElectricFactor]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCF(inputValue);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [unit]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCF(inputValue);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [primaryCountry]);

    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "cng" ? `scrollFuelFuelconsumption${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollFuelFuelconsumptionCNG"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-fuelConsumptionCollapse`} >
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-amount`} name={`${id}-${inputName}-amount`} label={inputLabel} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTextChange(e.target.value)} />
                                {
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label={selectLabel} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#F9950080" />
                                            )}
                                            control={control}
                                            name={`${id}-${selectName}-amount-select`}
                                            defaultValue={unit}
                                        />
                                    ) : (<></>)
                                }
                            </div>
                        </Col>
                        <Col lg={4} md={3} sm={12} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                            {
                                inputName === "electric" && countryElectricFactor[primaryCountry] === undefined ? (
                                    <span>This primary country is currently not available !</span>
                                ) : (<></>)
                            }
                        </Col>
                        <Col lg={2} md={3} sm={12}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseFuelConsumptionCustom));