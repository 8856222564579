const { isNumeric } = require('./utils.js');
require('./utils.js')

/** 
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for freight aviation type.
 * @param  {number} km Aviation Distance travelled (km) [user(text)]
 * @param  {number} tonnes Number of weight used for aviation (tonnes) 
 * @param  {number} scope3
 * @param  {number} wtt
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */

function footprintForSeaFreightSeaTankerCalc(tonnes, miles, scope3, wtt) {

    const emission = (scope3 + wtt);
    const km = miles * 1.852 ;

    const carbonFootprintSeaTanker = tonnes * km * emission ;
    return (carbonFootprintSeaTanker/1000);
}

function footprintForSeaFreightCargoShipCalc(tonnes, miles, scope3, wtt) {

    const emission = (scope3 + wtt);
    const km = miles * 1.852 ;

    const carbonFootprintCargoShip = tonnes * km * emission ;
    return (carbonFootprintCargoShip/1000);
}

module.exports = {
    footprintForSeaFreightSeaTankerCalc: footprintForSeaFreightSeaTankerCalc,
    footprintForSeaFreightCargoShipCalc: footprintForSeaFreightCargoShipCalc,
}