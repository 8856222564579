const { isNumeric } = require('./utils.js');
require('./utils.js')

/* Calculates total carbon footprint (tonnes CO<sub>2</sub>e) for paper used.  */
function footprintForPaperCalc(amount,unitValue,materialValue,disposalValue,postageValue) {
   //  console.log(amount,unitValue,materialValue,disposalValue,postageValue, "in calc");

    const paperUse = unitValue === "tonnes" ? amount : unitValue === "kg" ? (amount/1000) : unitValue === "sheets" ? ((amount*5)/1000000): 0
    if (!isNumeric(materialValue)) throw TypeError("'materialValue' must be numeric")
    if (!isNumeric(disposalValue)) throw TypeError("'disposalValue' must be numeric")

    const NoOfSheets = unitValue === "tonnes" ? ((paperUse*1000000)/5) : unitValue === "kg" ? ((paperUse*1000000)/5) : unitValue === "sheets" ? amount : 0
    const materialCarbonFactor = materialValue === undefined || materialValue === "" ? "material value not defined" : materialValue;
    const disposalCarbonFactor =  disposalValue === undefined || disposalValue === "" ? "disposal value not defined" : disposalValue;
    const postageCarbonFactor =  postageValue === "Yes" ? ((0.36*NoOfSheets)/1000) : postageValue === "No" ? 0 : "Not Valid";

    const CarbonFootprintPaper = (paperUse * materialCarbonFactor) + (paperUse *  disposalCarbonFactor) + postageCarbonFactor;
    return CarbonFootprintPaper;
}

module.exports = {
    footprintForPaperCalc: footprintForPaperCalc,
}