function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function isString(n) {
  return typeof n === "string"
}

module.exports = {
  isNumeric: isNumeric,
  isString: isString
}