const { isNumeric } = require('./utils.js');
require('./utils.js')

/**
 * Calculates yearly savings in carbon footprint (kgCO<sub>2</sub>e/year) for water consumption.
 * @param  {number} volume Volume (litres) [user(text)]
 * @param  {number} supplyFactor Water supply factor (KgCO<sub>2</sub>e/l) [country based]
 * @param  {number} treatmentFactor Water supply factor (KgCO<sub>2</sub>e/l) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForWaterByVolume(volume, supplyFactor, treatmentFactor) {
    if (!isNumeric(volume)) throw TypeError("'volume' must be numeric")
    if (!isNumeric(supplyFactor)) throw TypeError("'supplyFactor' must be numeric")
    if (!isNumeric(treatmentFactor)) throw TypeError("'treatmentFactor' must be numeric")
    // if (volume < 0) throw RangeError("'volume' can't be less than 0")
    if (supplyFactor < 0) throw RangeError("'supplyFactor' can't be less than 0")
    if (treatmentFactor < 0) throw RangeError("'treatmentFactor' can't be less than 0")

    return ((volume * supplyFactor) + (volume * treatmentFactor));
}

module.exports = {
    footprintForWaterByVolume: footprintForWaterByVolume,
}