import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForSeaFreightSeaTankerCalc } from "../../calculatorFunctions/sea_freight_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const CollapseSeaFreightSeaTankerCustom = (props) => {

    const seaTankerCrudeTankerOptions = props.seaTankerCrudeTankerOptionsInCollapseData;
    const seaTankerProductsTankerOptions = props.seaTankerProductsTankerOptionsInCollapseData;
    const seaTankerChemicalTankerOptions = props.seaTankerChemicalTankerOptionsInCollapseData;
    const seaTankerLngTankerOptions = props.seaTankerLngTankerOptionsInCollapseData;
    const seaTankerLpgTankerOptions = props.seaTankerLpgTankerOptionsInCollapseData;

    let { collapseControll, control, id, inputName, selectName, seaTankerVal, inputTonnes, seaFreightVal,
        selectCrudeTankerValue, selectProductsTankerValue, selectChemicalTankerValue,
        selectLngTankerValue, selectLpgTankerValue, inputKm, label, inputPlaceholder, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [tonnes, setTonnes] = useState((inputTonnes === undefined || inputTonnes === "") ? 0 : inputTonnes);
    const [km, setKm] = useState((inputKm === undefined || inputKm === "") ? 0 : inputKm);
    const [crudeTanker, setCrudeTanker] = useState((selectCrudeTankerValue === undefined || selectCrudeTankerValue === "") ? seaTankerCrudeTankerOptions[0] : selectCrudeTankerValue);
    const [productsTanker, setProductsTanker] = useState((selectProductsTankerValue === undefined || selectProductsTankerValue === "") ? seaTankerProductsTankerOptions[0] : selectProductsTankerValue);
    const [chemicalTanker, setChemicalTanker] = useState((selectChemicalTankerValue === undefined || selectChemicalTankerValue === "") ? seaTankerChemicalTankerOptions[0] : selectChemicalTankerValue);
    const [lngTanker, setLngTanker] = useState((selectLngTankerValue === undefined || selectLngTankerValue === "") ? seaTankerLngTankerOptions[0] : selectLngTankerValue);
    const [lpgTanker, setLpgTanker] = useState((selectLpgTankerValue === undefined || selectLpgTankerValue === "") ? seaTankerLpgTankerOptions[0] : selectLpgTankerValue);

    const getCrudeTankerOptions = (inputVal, callback) => {
        callback(seaTankerCrudeTankerOptions)
    }

    const getProductsTankerOptions = (inputVal, callback) => {
        callback(seaTankerProductsTankerOptions)
    }

    const getChemicalTankerOptions = (inputVal, callback) => {
        callback(seaTankerChemicalTankerOptions)
    }

    const getLngTankerOptions = (inputVal, callback) => {
        callback(seaTankerLngTankerOptions)
    }

    const getLpgTankerOptions = (inputVal, callback) => {
        callback(seaTankerLpgTankerOptions)
    }

    const onCrudeTankerChange = (val) => {
        setCrudeTanker(val);
    }

    const onProductsTankerChange = (val) => {
        setProductsTanker(val);
    }

    const onChemicalTankerChange = (val) => {
        setChemicalTanker(val);
    }

    const onLngTankerChange = (val) => {
        setLngTanker(val);
    }

    const onLpgTankerChange = (val) => {
        setLpgTanker(val);
    }

    const inputTonnesChange = (val) => {
        setTonnes((val !== undefined && val !== "") ? val : 0);
    }

    const inputKmChange = (val) => {
        setKm((val !== undefined && val !== "") ? val : 0);
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const setCF = (val) => {
        var cf = 0;
        let wtt = 0;
        let scope3 = 0;
        let crudeName = seaTankerCrudeTankerOptions.map(item => {
            return `Sea Tanker_${inputName}_${item.value}`;
        });
        let productName = seaTankerProductsTankerOptions.map(item => {
            return `Sea Tanker_${inputName}_${item.value}`;
        });
        let chemicalName = seaTankerChemicalTankerOptions.map(item => {
            return `Sea Tanker_${inputName}_${item.value}`;
        });
        let lngName = seaTankerLngTankerOptions.map(item => {
            return `Sea Tanker_${inputName}_${item.value}`;
        });
        let lpgName = seaTankerLpgTankerOptions.map(item => {
            return `Sea Tanker_${inputName}_${item.value}`;
        });
        let distance = km === undefined || km === "" ? 0 : km;
        let weightInTonnes = tonnes === undefined || tonnes === "" ? 0 : tonnes;
        let seaFreightSizeVal = "";
        if (inputName === "Crude tanker") {
            if (crudeTanker.value === seaTankerCrudeTankerOptions[0].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[0]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[0]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].scope3 : 0;
            } else if (crudeTanker.value === seaTankerCrudeTankerOptions[1].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[1]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[1]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[1]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[1]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[1]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[1]].scope3 : 0;
            } else if (crudeTanker.value === seaTankerCrudeTankerOptions[2].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[2]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[2]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[2]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[2]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[2]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[2]].scope3 : 0;
            } else if (crudeTanker.value === seaTankerCrudeTankerOptions[3].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[3]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[3]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[3]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[3]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[3]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[3]].scope3 : 0;
            } else if (crudeTanker.value === seaTankerCrudeTankerOptions[4].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[4]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[4]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[4]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[4]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[4]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[4]].scope3 : 0;
            } else if (crudeTanker.value === seaTankerCrudeTankerOptions[5].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[5]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[5]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[5]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[5]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[5]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[5]].scope3 : 0;
            } else if (crudeTanker.value === seaTankerCrudeTankerOptions[6].value) {
                seaFreightSizeVal = crudeTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[6]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[6]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[6]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[6]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[6]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[6]].scope3 : 0;
            }
        } else if (inputName === "Products tanker") {
            if (productsTanker.value === seaTankerProductsTankerOptions[0].value) {
                seaFreightSizeVal = productsTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[0]) && props.master.seaFreightSeaTankerSizeMaster[productName[0]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[0]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[0]) && props.master.seaFreightSeaTankerSizeMaster[productName[0]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[0]].scope3 : 0;
            } else if (productsTanker.value === seaTankerProductsTankerOptions[1].value) {
                seaFreightSizeVal = productsTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[1]) && props.master.seaFreightSeaTankerSizeMaster[productName[1]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[1]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[1]) && props.master.seaFreightSeaTankerSizeMaster[productName[1]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[1]].scope3 : 0;
            } else if (productsTanker.value === seaTankerProductsTankerOptions[2].value) {
                seaFreightSizeVal = productsTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[2]) && props.master.seaFreightSeaTankerSizeMaster[productName[2]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[2]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[2]) && props.master.seaFreightSeaTankerSizeMaster[productName[2]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[2]].scope3 : 0;
            } else if (productsTanker.value === seaTankerProductsTankerOptions[3].value) {
                seaFreightSizeVal = productsTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[3]) && props.master.seaFreightSeaTankerSizeMaster[productName[3]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[3]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[3]) && props.master.seaFreightSeaTankerSizeMaster[productName[3]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[3]].scope3 : 0;
            } else if (productsTanker.value === seaTankerProductsTankerOptions[4].value) {
                seaFreightSizeVal = productsTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[4]) && props.master.seaFreightSeaTankerSizeMaster[productName[4]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[4]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[4]) && props.master.seaFreightSeaTankerSizeMaster[productName[4]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[4]].scope3 : 0;
            } else if (productsTanker.value === seaTankerProductsTankerOptions[5].value) {
                seaFreightSizeVal = productsTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[5]) && props.master.seaFreightSeaTankerSizeMaster[productName[5]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[5]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(productName[5]) && props.master.seaFreightSeaTankerSizeMaster[productName[5]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[productName[5]].scope3 : 0;
            }
        } else if (inputName === "Chemical tanker") {
            if (chemicalTanker.value === seaTankerChemicalTankerOptions[0].value) {
                seaFreightSizeVal = chemicalTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[0]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[0]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[0]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[0]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[0]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[0]].scope3 : 0;
            } else if (chemicalTanker.value === seaTankerChemicalTankerOptions[1].value) {
                seaFreightSizeVal = chemicalTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[1]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[1]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[1]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[1]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[1]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[1]].scope3 : 0;
            } else if (chemicalTanker.value === seaTankerChemicalTankerOptions[2].value) {
                seaFreightSizeVal = chemicalTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[2]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[2]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[2]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[2]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[2]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[2]].scope3 : 0;
            } else if (chemicalTanker.value === seaTankerChemicalTankerOptions[3].value) {
                seaFreightSizeVal = chemicalTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[3]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[3]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[3]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[3]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[3]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[3]].scope3 : 0;
            } else if (chemicalTanker.value === seaTankerChemicalTankerOptions[4].value) {
                seaFreightSizeVal = chemicalTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[4]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[4]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[4]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(chemicalName[4]) && props.master.seaFreightSeaTankerSizeMaster[chemicalName[4]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[chemicalName[4]].scope3 : 0;
            }
        } else if (inputName === "LNG tanker") {
            if (lngTanker.value === seaTankerLngTankerOptions[0].value) {
                seaFreightSizeVal = lngTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lngName[0]) && props.master.seaFreightSeaTankerSizeMaster[lngName[0]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lngName[0]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lngName[0]) && props.master.seaFreightSeaTankerSizeMaster[lngName[0]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lngName[0]].scope3 : 0;
            } else if (lngTanker.value === seaTankerLngTankerOptions[1].value) {
                seaFreightSizeVal = lngTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lngName[1]) && props.master.seaFreightSeaTankerSizeMaster[lngName[1]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lngName[1]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lngName[1]) && props.master.seaFreightSeaTankerSizeMaster[lngName[1]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lngName[1]].scope3 : 0;
            } else if (lngTanker.value === seaTankerLngTankerOptions[2].value) {
                seaFreightSizeVal = lngTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lngName[2]) && props.master.seaFreightSeaTankerSizeMaster[lngName[2]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lngName[2]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lngName[2]) && props.master.seaFreightSeaTankerSizeMaster[lngName[2]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lngName[2]].scope3 : 0;
            }
        } else if (inputName === "LPG tanker") {
            if (lpgTanker.value === seaTankerLpgTankerOptions[0].value) {
                seaFreightSizeVal = lpgTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lpgName[0]) && props.master.seaFreightSeaTankerSizeMaster[lpgName[0]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lpgName[0]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lpgName[0]) && props.master.seaFreightSeaTankerSizeMaster[lpgName[0]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lpgName[0]].scope3 : 0;
            } else if (lpgTanker.value === seaTankerLpgTankerOptions[1].value) {
                seaFreightSizeVal = lpgTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lpgName[1]) && props.master.seaFreightSeaTankerSizeMaster[lpgName[1]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lpgName[1]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lpgName[1]) && props.master.seaFreightSeaTankerSizeMaster[lpgName[1]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lpgName[1]].scope3 : 0;
            } else if (lpgTanker.value === seaTankerLpgTankerOptions[2].value) {
                seaFreightSizeVal = lpgTanker.value;
                wtt = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lpgName[2]) && props.master.seaFreightSeaTankerSizeMaster[lpgName[2]].wtt !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lpgName[2]].wtt : 0;
                scope3 = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(lpgName[2]) && props.master.seaFreightSeaTankerSizeMaster[lpgName[2]].scope3 !== undefined ? props.master.seaFreightSeaTankerSizeMaster[lpgName[2]].scope3 : 0;
            }
        }

        let source = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[0]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].source !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].source : null;
        let year = props.master.seaFreightSeaTankerSizeMaster.hasOwnProperty(crudeName[0]) && props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].year !== undefined ? props.master.seaFreightSeaTankerSizeMaster[crudeName[0]].year : 0;
        let seaFreightTypeVal = inputName;
        let seaFreightActivityVal = seaFreightVal;
        if (distance || weightInTonnes > 0) {
            cf = footprintForSeaFreightSeaTankerCalc(weightInTonnes, distance, wtt, scope3);
        }

        if (seaFreightVal === "seaTanker") {
            props.cFootprint({
                name: inputName,
                parent: "seaTanker",
                cf: { value: cf, weightInTonnes, distance, seaFreightActivityVal, seaFreightTypeVal, seaFreightSizeVal, wtt, scope3, year, source }
            });
        }
        setCarbonFootprint(cf);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if (tonnes !== undefined && tonnes !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (km !== undefined && km !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [tonnes, km, crudeTanker, productsTanker, chemicalTanker, lngTanker, lpgTanker]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "Crude tanker" ? `scrollSeaFreight${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollAviationFreight"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#009dc4", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label} </span>
                    <Row>
                        <Col lg={9} md={6} sm={14}>
                            <div className={`${id}-${inputName}-seaTankerCollapse`} styles={{ width: "100%" }}>
                                {inputName === 'Crude tanker' ? (
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onCrudeTankerChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                    errors={errors} loadOptions={getCrudeTankerOptions} label="Size" defaultOptions={true}
                                                    formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                            )}
                                            control={control}
                                            name={`${id}-${inputName}-seaTankerCrudeTankerOptions-select`}
                                            defaultValue={crudeTanker}
                                        />
                                    ) : (
                                        <></>
                                    )
                                ) : inputName === 'Products tanker' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onProductsTankerChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getProductsTankerOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-seaTankerProductsTankerOptions-select`}
                                                defaultValue={productsTanker}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : inputName === 'Chemical tanker' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onChemicalTankerChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getChemicalTankerOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-seaTankerChemicalTankerOptions-select`}
                                                defaultValue={chemicalTanker}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : inputName === 'LNG tanker' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onLngTankerChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getLngTankerOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-seaTankerLngTankerOptions-select`}
                                                defaultValue={lngTanker}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : inputName === 'LPG tanker' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onLpgTankerChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getLpgTankerOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-seaTankerLpgTankerOptions-select`}
                                                defaultValue={lpgTanker}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : (
                                    <></>
                                )}
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-tonnes`} name={`${id}-${inputName}-tonnes`} label="Tonnes" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "1%", marginLeft: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTonnesChange(e.target.value)} />
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-km`} name={`${id}-${inputName}-km`} label= "Nautical Miles" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "1%", marginLeft: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputKmChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col></Col>
                        <Col lg={2} md={3} sm={12} styles={{ paddingBottom: "500px" }}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{
                        color: '#009dc4',
                        backgroundColor: '#009dc4',
                        height: .5,
                        borderColor: '#009dc4'
                    }} />

                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseSeaFreightSeaTankerCustom));