import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForFuelByValue } from "../../calculatorFunctions/fuel_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const CollapseFuelCostCustom = (props) => {

    let { collapseControll, id, label, inputName, inputLabel, inputValue, inputPlaceholder, inputPrice, primaryCountry, defaultCostPrice, register, errors } = props;

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [countryElectricFactor, setCountryElectricFactor] = useState({});
    const [costValue, setCostValue] = useState(0);
    const [costPrice, setCostPrice] = useState(defaultCostPrice);

    const costValueChange = (val) => {
        setCostValue((val !== undefined && val !== "") ? val : 0);
    }

    const costPriceChange = (val) => {
        setCostPrice((val !== undefined && val !== "") ? Math.abs(val) : defaultCostPrice);
    }

    const setCF = () => {
        var name = inputName;
        var cf = 0;
        let uFactor = 0;
        let consumption = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].consumption !== undefined ? props.master.fuelFactorMaster[name].consumption : 0;
        let upstream = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].upstream !== undefined ? props.master.fuelFactorMaster[name].upstream : 0;
        let year = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].year !== undefined ? props.master.fuelFactorMaster[name].year : 0;
        let source = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].source !== undefined ? props.master.fuelFactorMaster[name].source : null;
        if (inputName !== "electric") {
            cf = footprintForFuelByValue(costValue, costPrice, consumption, upstream);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, parent: "fuelCost", cf: { value: cf, consumption, upstream, year, source } });
            setCarbonFootprint(cf);
        } else {
            uFactor = countryElectricFactor[primaryCountry] !== undefined && countryElectricFactor[primaryCountry].efficiency !== undefined ? countryElectricFactor[primaryCountry].efficiency : 0;
            cf = footprintForFuelByValue(costValue, costPrice, 0, uFactor);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, parent: "fuelCost", cf: { value: cf, consumption: 0, upstream: uFactor, year, source } });
            setCarbonFootprint(cf);
        }

    }

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(countryElectricFactor).length === 0) {
                    setCountryElectricFactor(props.master.countryElectricEfficiency);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [props.master.status]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && inputPrice !== undefined && inputPrice !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCostValue((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
                setCostPrice((inputPrice !== undefined && inputPrice !== "") ? inputPrice : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countryElectricFactor]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (costValue !== undefined && costValue !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [costPrice]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (costPrice !== undefined && costPrice !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [costValue]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (costPrice !== undefined && costPrice !== "" && Object.keys(countryElectricFactor).length > 0) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [primaryCountry]);


    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "cng" ? `scrollFuelFuelcost${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollFuelFuelcostCNG"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#ffc107", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label} </span>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-fuelCostCollapse`} >
                                <Input className="numberType" suffix={true} suffixContent={'€'} suffixAlign={'right'} type="number" onWheel={(e) => onWheel(e)} id={`${id}-${inputName}-value`} name={`${id}-${inputName}-value`} label={inputLabel} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "50%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px", paddingRight: "28px" }} onChange={(e) => costValueChange(e.target.value)} />
                                <Input className="numberType" suffix={true} suffixContent={'€'} suffixAlign={'right'} type="number" onWheel={(e) => onWheel(e)} defaultValue={costPrice} id={`${id}-${inputName}-price`} name={`${id}-${inputName}-price`} label={inputName === "electric" ? "Price of electricity (€ / kWh)" : "Price of fuel (€ / litre)"} placeholder={defaultCostPrice} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "50%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px", paddingRight: "28px" }} onChange={(e) => costPriceChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col lg={4} md={3} sm={12} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                            {
                                inputName === "electric" && countryElectricFactor[primaryCountry] === undefined ? (
                                    <span>The primary country is currently not available !</span>
                                ) : (<></>)
                            }
                        </Col>
                        <Col lg={2} md={3} sm={12}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseFuelCostCustom));