import React, { useMemo } from "react";
import Form from "react-bootstrap/form";
import AsyncSelect from "react-select/async";

const defaultOptionsArray = [
    { value: 0, label: "Default Option A" },
    { value: 1, label: "Default Option B" },
    { value: 2, label: "Default Option C" }
]

var nStyle = {};
var lStyle = {};

const SelectCustom = (props) => {
    let { id, name, label, value, labelStyle, color, onClickColor, formGroupStyle, register, errors, cacheOptions, loadOptions, defaultOptions, onChange, onBlur, optionStyle, specialBlackEdition, ...rest } = props;

    const getOptionsData = (inputValue, callback) => {
        callback(defaultOptionsArray);
    }

    loadOptions = (loadOptions !== undefined && typeof loadOptions === "function") ? loadOptions : getOptionsData;
    onChange = typeof onChange === "function" ? onChange : () => {
        return null;
    };
    onBlur = typeof onBlur === "function" ? onBlur : () => {
        return null;
    };

    if (errors && errors[name]) {
        nStyle = { ...props.style, borderBottomColor: "rgb(235, 79, 107)" };
        lStyle = { ...labelStyle, color: "rgb(235, 79, 107)" };
    } else {
        nStyle = props.style;
        lStyle = labelStyle;
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? color : '#f0f0f0',
                color: "black",
                ...optionStyle,
                paddingTop: "8px",
                "&:active": {
                    backgroundColor: state.isSelected ? color : onClickColor
                },
                "&:hover": {
                    backgroundColor: state.isSelected ? color : onClickColor
                }
            }),
            control: (provided, state) => ({
                ...provided,
                borderRadius: "0px",
                borderWidth: "1px",
                backgroundColor: color,
                borderColor: "#525252",
                background: color,
                color: "#f0f0f0",
                boxShadow: "0 0 0 1px transparent !important",
                "&:hover": {
                    backgroundColor: color,
                    borderColor: "#525252",
                    background: color,
                    color: "#f0f0f0",
                },
                "&:active": {
                    backgroundColor: color,
                    borderColor: "#525252",
                    background: color,
                    color: "#f0f0f0",
                    boxShadow: "0 0 0 1px transparent",
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: 10,
                margin: "0px",
                border: "0",
                padding: "0px",
                borderRadius: "0px",
                borderWidth: "0px"
            }),
            menuList: (provided, state) => ({
                ...provided,
                margin: "0px",
                border: "0",
                padding: "0px",
                borderRadius: "0px",
                borderWidth: "0px"
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: color,
                fontSize: "16px"
            }),
            input: (provided, state) => ({
                ...provided,
                color: "#f0f0f0"
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                width: "0px"
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                "&:hover": {
                    color: `#f0f0f0 !important`
                }
            })
        }),
        // eslint-disable-next-line
        [color, onClickColor, optionStyle]
    );

    return (
        <Form.Group controlId={`${id}-group`} style={formGroupStyle}>
            <Form.Label style={lStyle}>{label}</Form.Label>
            <AsyncSelect
                ref={register}
                name={name}
                cacheOptions={true}
                loadOptions={loadOptions}
                defaultOptions={defaultOptions ? true : false}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                {...rest}
                styles={{ ...customStyles, ...nStyle }}
                isSearchable={true}
            />
        </Form.Group>
    )
}

export default SelectCustom;
