import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Collapse from "react-bootstrap/collapse";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import convert from "convert-units";
import { Element } from "react-scroll";
import { footprintForWaterByVolume } from "../../calculatorFunctions/water_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const unitOptions = [
    { value: "litre", label: "l" },
    { value: "gallon", label: "gal" }
];

const CollapseWaterConsumptionCustom = (props) => {
    let { collapseControll, id, label, inputName, volumeValue, selectName, selectLabel, selectValue, control, register, errors } = props;

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [volume, setVolume] = useState((volumeValue === undefined || volumeValue === "") ? 0 : volumeValue);
    const [unit, setUnit] = useState((selectValue === undefined || selectValue === "") ? unitOptions[0] : selectValue);

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const inputTextChange = (val) => {
        setVolume((val !== undefined && val !== "") ? val : 0);
    }

    const setCF = () => {
        var cf = 0;
        var supply = props.master.waterFactorMaster[inputName] !== undefined && props.master.waterFactorMaster[inputName].supply !== undefined ? props.master.waterFactorMaster[inputName].supply : 0;
        var treatment = props.master.waterFactorMaster[inputName] !== undefined && props.master.waterFactorMaster[inputName].treatment !== undefined ? props.master.waterFactorMaster[inputName].treatment : 0;
        var year = props.master.waterFactorMaster[inputName] !== undefined && props.master.waterFactorMaster[inputName].supplyYear !== undefined ? props.master.waterFactorMaster[inputName].supplyYear : new Date().getFullYear();
        var source = props.master.waterFactorMaster[inputName] !== undefined && props.master.waterFactorMaster[inputName].supplySource !== undefined ? props.master.waterFactorMaster[inputName].supplySource : null;
        cf = footprintForWaterByVolume(convert(volume).from(unit.label).to("l"), supply, treatment);
        cf = parseFloat(cf / 1000).toFixed(3);
        props.cFootprint({ name: inputName, parent: "waterConsumption", cf: { value: cf, supply, treatment, year, source } });
        setCarbonFootprint(cf);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (volume !== undefined && volume !== "") {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [unit, volume]);

    return (
        <Collapse in={collapseControll}>
            <Element name={`scrollWaterWaterconsumption${inputName.replace(" ", "")}`} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#148AFF", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label}</span>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-waterConsumptionCollapse`} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-volume`} name={`${id}-${inputName}-volume`} label={"Volume of water"} placeholder={"0.0"} tooltip={true}  register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", borderColor: "#6c757d", height: "40px" }} onChange={(e) => inputTextChange(e.target.value)} />
                                {
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label={selectLabel} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#148AFF80" />
                                            )}
                                            control={control}
                                            name={`${id}-${selectName}-volume-select`}
                                            defaultValue={unit}
                                        />
                                    ) : (<></>)
                                }
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={12} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                            {
                                props.master.electricityFactorMaster[inputName] === undefined ? (
                                    <span>This country is currently not available !</span>
                                ) : (<></>)
                            }
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master,
        project: state.project
    };
}

export default connect(mapStateToProps)(CollapseWaterConsumptionCustom);