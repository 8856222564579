import React from "react";

function CheckButton(props) {
  const { label, options, handleChange } = props;

  return (
    <>
      <p style={{ marginBottom: "6px" }}>{label}</p>
      {Object.keys(options).map((item) => {
        return (
          <button
            key={`${item}-${options[item].name}`}
            className={
              options[item].state ? "btn activebtn " : "btn inactivebtn"
            }
            onClick={handleChange}
            name={options[item].name}
          >
            {`${(options[item].name).charAt(0).toUpperCase()}${(options[item].name).slice(1)}`}
          </button>
        );
      })}
    </>
  );
}

export default CheckButton;
