export function setCurrentProjectId(projectId){
    return dispatch => {
        dispatch({
            type: "SET_PROJECT_ID",
            payload: { projectId }
        });
    }
}

export function unsetCurrentProjectId(){
    return dispatch => {
        dispatch({
            type: "UNSET_PROJECT_ID"
        });
    }
}

export function setCurrentPrimaryCountry(primaryCountry){
    return dispatch => {
        dispatch({
            type: "SET_PRIMARY_COUNTRY",
            payload: { primaryCountry }
        });
    }
}

export function unsetCurrentPrimaryCountry(){
    return dispatch => {
        dispatch({
            type: "UNSET_PRIMARY_COUNTRY"
        });
    }
}

export function setCurrentStartDate(startDate){
    return dispatch => {
        dispatch({
            type: "SET_START_DATE",
            payload: { startDate }
        });
    }
}

export function unsetCurrentStartDate(){
    return dispatch => {
        dispatch({
            type: "UNSET_START_DATE"
        });
    }
}

export function setCurrentEndDate(endDate){
    return dispatch => {
        dispatch({
            type: "SET_END_DATE",
            payload: { endDate }
        });
    }
}

export function unsetCurrentEndDate(){
    return dispatch => {
        dispatch({
            type: "UNSET_END_DATE"
        });
    }
}

export function setCurrentProject(data){
    return dispatch => {
        dispatch({
            type: "SET_CURRENT_PROJECT",
            payload: { projectId: data.projectId, primaryCountry: data.primaryCountry, startDate: data.startDate, endDate: data.endDate }
        });
    }
}

export function unsetCurrentProject(){
    return dispatch => {
        dispatch({
            type: "UNSET_CURRENT_PROJECT"
        });
    }
}