
const initialState = {
    currentProjectId: "",
    currentProjectPrimaryCountry: "",
    currentProjectStartDate: "",
    currentProjectEndDate: "",
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PROJECT_ID":
            state = {
                ...state,
                currentProjectId: action.payload.projectId
            }
            break;
        case "UNSET_PROJECT_ID":
            state = {
                ...state,
                currentProjectId: ""
            }
            break;
        case "SET_PRIMARY_COUNTRY":
            state = {
                ...state,
                currentProjectPrimaryCountry: action.payload.primaryCountry
            }
            break;
        case "UNSET_PRIMARY_COUNTRY":
            state = {
                ...state,
                currentProjectPrimaryCountry: ""
            }
            break;
        case "SET_START_DATE":
            state = {
                ...state,
                currentProjectStartDate: action.payload.startDate
            }
            break;
        case "UNSET_START_DATE":
            state = {
                ...state,
                currentProjectStartDate: ""
            }
            break;
        case "SET_END_DATE":
            state = {
                ...state,
                currentProjectEndDate: action.payload.endDate
            }
            break;
        case "UNSET_END_DATE":
            state = {
                ...state,
                currentProjectEndDate: ""
            }
            break;
        case "SET_CURRENT_PROJECT":
            state = {
                ...state,
                currentProjectId: action.payload.projectId,
                currentProjectPrimaryCountry: action.payload.primaryCountry,
                currentProjectStartDate: action.payload.startDate,
                currentProjectEndDate: action.payload.endDate
            }
            break;
        case "UNSET_CURRENT_PROJECT":
            state = initialState;
            break;
        default:
            break;
    }
    return state;
}

export default projectReducer;