import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import convert from "convert-units";
import { footprintForFuelByDistance } from "../../calculatorFunctions/fuel_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

var eff = 0;
var con = 0;
var ups = 0;
var year = new Date().getFullYear();
var source = null;

const ladenOptions = [
    { value: "0%", label: "0%" },
    { value: "50%", label: "50%" },
    { value: "100%", label: "100%" },
    { value: "average", label: "Average" },
];

const unitOptions = [
    { value: "kilometre", label: "km" },
    { value: "mile", label: "mi" },
];

const CollapseDistanceVehicleTypeCustom = (props) => {
    let { collapseControll, id, primaryCountry, fuelType, inputName, inputValue, inputLabel, inputPlaceholder, selectName, selectLabel, selectValue, selectLadenValue, control, register, errors } = props;

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [vehicleType, setVehicleType] = useState({});
    const [countryElectricFactor, setCountryElectricFactor] = useState({});
    const [laden, setLaden] = useState((selectLadenValue === undefined || selectLadenValue === "") ? ladenOptions[0] : selectLadenValue);
    const [unit, setUnit] = useState((selectValue === undefined || selectValue === "") ? unitOptions[0] : selectValue);
    const [isLadenNeeded, setIsLadenNeeded] = useState(false);

    var divWidthCSS = {};
    var inputFormGroupWidthCSS = {};
    var selectFormGroupWidthCSS = {};

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const getLadenOptions = (inputValue, callback) => {
        callback(ladenOptions);
    }

    const inputTextChange = (val) => {
        setCF((val !== undefined && val !== "") ? val : 0);
    }

    const onLadenChange = (val) => {
        setLaden(val);
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    const setCF = (val) => {
        var name = fuelType;
        var cf = 0;
        let efficiency = 0;
        let uFactor = 0;
        con = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].consumption !== undefined ? props.master.fuelFactorMaster[name].consumption : 0;
        ups = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].upstream !== undefined ? props.master.fuelFactorMaster[name].upstream : 0;
        year = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].year !== undefined ? props.master.fuelFactorMaster[name].year : 0;
        source = props.master.fuelFactorMaster.hasOwnProperty(name) && props.master.fuelFactorMaster[name].source !== undefined ? props.master.fuelFactorMaster[name].source : null;
        if (fuelType !== "electric") {
            for (const item of vehicleType[fuelType]) {
                if (item.name === inputName) {
                    if (item.laden === null) {
                        setIsLadenNeeded(false);
                        efficiency = item.efficiency;
                        break;
                    } else {
                        setIsLadenNeeded(true);
                        if (item.laden === laden.value) {
                            efficiency = item.efficiency;
                            break;
                        }
                    }
                }
            }
            eff = efficiency;
            cf = footprintForFuelByDistance(convert(parseFloat(val)).from(unit.label).to("km"), eff, con, ups);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, cf: { value: cf, efficiency: eff, consumption: con, upstream: ups, year, source } });
            setCarbonFootprint(cf);
        } else {
            for (const item of vehicleType[fuelType]) {
                if (item.name === inputName) {
                    if (item.laden === null) {
                        setIsLadenNeeded(false);
                        efficiency = item.efficiency;
                        break;
                    } else {
                        setIsLadenNeeded(true);
                        if (item.laden === laden.value) {
                            efficiency = item.efficiency;
                            break;
                        }
                    }
                }
            }
            uFactor = countryElectricFactor[primaryCountry] !== undefined && countryElectricFactor[primaryCountry].efficiency !== undefined ? countryElectricFactor[primaryCountry].efficiency : 0;
            eff = efficiency;
            con = 0;
            ups = uFactor;
            cf = footprintForFuelByDistance(convert(parseFloat(val)).from(unit.label).to("km"), eff, con, ups);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, cf: { value: cf, efficiency: eff, consumption: con, upstream: ups, year: new Date().getFullYear(), source } });
            setCarbonFootprint(cf);
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(countryElectricFactor).length === 0) {
                    setCountryElectricFactor(props.master.countryElectricEfficiency);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [props.master.status]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(vehicleType).length === 0 && Object.keys(countryElectricFactor).length > 0) {
                    setVehicleType(props.master.vehicleType);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countryElectricFactor]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(vehicleType).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [vehicleType]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(countryElectricFactor).length > 0 && Object.keys(vehicleType).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [unit]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (Object.keys(vehicleType).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [laden]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (Object.keys(vehicleType).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [primaryCountry]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (Object.keys(countryElectricFactor).length > 0 && Object.keys(vehicleType).length > 0) {
                for (const item of vehicleType[fuelType]) {
                    if (item.name === inputName) {
                        if (item.laden === null) {
                            setIsLadenNeeded(false);
                            break;
                        } else {
                            setIsLadenNeeded(true);
                            break;
                        }
                    }
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countryElectricFactor, vehicleType, fuelType, inputName]);

    if (!isLadenNeeded) {
        divWidthCSS = {
            width: "100%"
        }
        selectFormGroupWidthCSS = {
            width: "30%"
        }
        inputFormGroupWidthCSS = {
            width: "30%"
        }
    } else {
        divWidthCSS = {
            width: "100%"
        }
        inputFormGroupWidthCSS = {
            width: "40%"
        }
        selectFormGroupWidthCSS = {
            width: "20%"
        }
    }

    return (
        <Collapse in={collapseControll}>
            <div style={{ textAlign: "left" }}>
                <Row>
                    <Col lg={6} md={6} sm={12} >
                        <div className={`${id}-${inputName}-vtCollapse`} style={{ ...divWidthCSS }}>
                            <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-distance-vt`} name={`${id}-${inputName}-distance-vt`} label={inputLabel} placeholder={inputPlaceholder} register={register} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, ...inputFormGroupWidthCSS, marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTextChange(e.target.value)} />
                            {
                                control !== undefined ? (
                                    <Controller
                                        render={({ onChange, value, name }) => (
                                            <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label={selectLabel} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginRight: isLadenNeeded ? "10px" : "0px", ...selectFormGroupWidthCSS }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#F9950080" />
                                        )}
                                        control={control}
                                        name={`${id}-${selectName}-distance-vt-select`}
                                        defaultValue={unit}
                                    />
                                ) : (<></>)
                            }
                            {
                                control !== undefined && isLadenNeeded ? (
                                    <Controller
                                        render={({ onChange, value, name }) => (
                                            <Select
                                                id={name}
                                                name={name}
                                                value={value}
                                                onChange={(val) => {
                                                    onLadenChange(val);
                                                    return onChange(val)
                                                }}
                                                labelStyle={{ fontSize: "10px", color: "white" }}
                                                errors={errors}
                                                loadOptions={getLadenOptions}
                                                label={"Laden"}
                                                defaultOptions={true}
                                                formGroupStyle={{ flexGrow: 1, color: "black", ...selectFormGroupWidthCSS }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#44414136" />
                                        )}
                                        control={control}
                                        name={`${id}-${selectName}-distance-vt-laden-select`}
                                        defaultValue={laden}
                                    />
                                ) : (<></>)
                            }
                        </div>
                    </Col>
                    <Col lg={4} md={3} sm={12} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                        {
                            fuelType === "electric" && countryElectricFactor[primaryCountry] === undefined ? (
                                <span>The primary country is currently not available !</span>
                            ) : (<></>)
                        }
                    </Col>
                    <Col lg={2} md={3} sm={12} >
                        <div className="carbonFootprint" >
                            <span>Carbon Footprint</span>
                            <br />
                            <br />
                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                        </div>
                    </Col>
                </Row>
                <Row style={{ height: "0px", display: "none" }}>
                    <Col>
                        <Input hidden={true} readOnly={true} value={carbonFootprint} id={`${id}-${inputName}-distance-vt-cf`} name={`${id}-${inputName}-distance-vt-cf`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={eff} id={`${id}-${inputName}-distance-vt-efficiency`} name={`${id}-${inputName}-distance-vt-efficiency`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={con} id={`${id}-${inputName}-distance-vt-consumption`} name={`${id}-${inputName}-distance-vt-consumption`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={ups} id={`${id}-${inputName}-distance-vt-upstream`} name={`${id}-${inputName}-distance-vt-upstream`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={year} id={`${id}-${inputName}-distance-vt-year`} name={`${id}-${inputName}-distance-vt-year`} label={""} placeholder={inputPlaceholder} register={register} />
                    </Col>
                </Row>
            </div>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseDistanceVehicleTypeCustom));