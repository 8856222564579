import axios from "axios";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

export function logout(aadlogoutFn) {
    return dispatch => {
        axios.post(`${basePath}/auth/logout`)
            .then(res => {
                dispatch({
                    type: "LOGOUT"
                });
                aadlogoutFn();
            })
            .catch(err => {
                // console.log("Could not logout!");
            });
    }
}