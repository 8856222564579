import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddUsers from "../../pages/addUsers";
import { Provider } from "react-redux";
import store from "../../store";

const shareModal = () => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body shareUser" >
                    <Provider store={store}>
                        <AddUsers modalClose={onClose} />
                    </Provider>
                </div>
            );
        },
        closeOnEscape: true,
        closeOnClickOutside: false
    });
};

export default shareModal;