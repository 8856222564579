import React from "react";
import Toast from "react-bootstrap/toast";
import "./ToastCustom.css";

export default function ToastCustom(props) {
    let styles = {};
    
    if(props.variant==="warning"){
        styles = {backgroundColor: "#f7f5d5", color: "rgb(212 159 0)", borderColor: "#ffc107", position: "auto"}
    }
    if(props.variant==="bw"){
        styles = {backgroundColor: "#525252", color: "#f0f0f0", borderColor: "#f0f0f0", position: "auto"}
    }

    return (
        <Toast className="custom-toast-pdf" style={styles} onClose={props.onClose} show={props.show} delay={props.delay} autohide={props.autoHide}>
            <Toast.Header style={styles}>
                <span style={{margin: "0px"}}>
                    <i className="fa fa-info-circle" aria-hidden="true">&nbsp;&nbsp;</i>
                    <strong className="mr-auto">{props.header}&emsp;&emsp;&emsp;</strong>
                </span>
            </Toast.Header>
            <Toast.Body style={{textAlign: "left", fontSize: "11px", fontStyle: "normal", fontWeight: "normal"}}>{props.body}</Toast.Body>
        </Toast>
    )
}
