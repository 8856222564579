export const projectCardTitleLimit = (str) => {
    const limit_to = 80;
    const suffix = '...';
    const str_length = str.length;
    let final_return = '';

    if(str_length >= limit_to){
        final_return = str.substring(0,limit_to)+'...';
    } else {
        final_return = str; 
    }
    return final_return;
}