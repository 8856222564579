import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import convert from "convert-units";
import { Element } from "react-scroll";
import { footprintForPlasticCalc } from "../../calculatorFunctions/plastic_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const unitOptions = [
    { value: "tonnes", label: "Tonnes" },
    { value: "kg", label: "Kg" },
];

const materialOptions = [
    { value: "Primary Material Product", label: "Primary Material Product" },
    { value: "Closed-loop Source", label: "Closed-loop Source" },
];

const disposalOptions = [
    { value: "Landfill", label: "Landfill" },
    { value: "Closed-loop", label: "Closed-loop" },
];

const plasticType = {
    "AveragePlastics": "Average Plastics",
    "AveragePlasticFilm": "Average Plastic Film",
    "AveragePlasticRigid": "Average Plastic Rigid",
    "PET": "PET",
    "PVC": "PVC" 
};

const CollapsePlasticConsumptionCustom = (props) => {
    let { collapseControll, id, inputName, inputLabel, inputValue, inputPlaceholder, selectName, selectLabel, selectUnitValue, selectMaterialValue, selectDisposalValue, control, volumeValue, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [volume, setVolume] = useState((inputValue === undefined || inputValue === "") ? 0 : inputValue);
    const [unit, setUnit] = useState((selectUnitValue === undefined || selectUnitValue === "") ? unitOptions[0] : selectUnitValue);
    const [material, setMaterial] = useState((selectMaterialValue === undefined || selectMaterialValue === "") ? materialOptions[0] : selectMaterialValue);
    const [disposal, setDisposal] = useState((selectDisposalValue === undefined || selectDisposalValue === "") ? disposalOptions[0] : selectDisposalValue);

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const getMaterialOptions = (inputVal, callback) => {
        callback(materialOptions)
    }

    const getDisposalOptions = (inputVal, callback) => {
        callback(disposalOptions)
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const onMaterialChange = (val) => {
        setMaterial(val);
    }

    const onDisposalChange = (val) => {
        setDisposal(val);
    }

    const inputTextChange = (val) => {
        setVolume((val !== undefined && val !== "") ? val : 0);

    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const setCF = () => {
        var materialUseValue = "";
        var wasteDisposalValue = "";
        var name = plasticType[inputName];
        var cf = 0;
        var amount = volume === undefined || volume === "" ? 0 : volume;
        var unitValue = unit.value;
        let primaryMaterialProduct = props.master.plasticMaterialUseMaster.hasOwnProperty(name) && props.master.plasticMaterialUseMaster[name].primaryMaterialProduct !== undefined ? props.master.plasticMaterialUseMaster[name].primaryMaterialProduct : 0;
        let closedLoopSource = props.master.plasticMaterialUseMaster.hasOwnProperty(name) && props.master.plasticMaterialUseMaster[name].closedLoopSource !== undefined ? props.master.plasticMaterialUseMaster[name].closedLoopSource : 0;
        let landFill = props.master.plasticWasteDisposalMaster.hasOwnProperty(name) && props.master.plasticWasteDisposalMaster[name].landFill !== undefined ? props.master.plasticWasteDisposalMaster[name].landFill : 0;
        let closedLoop = props.master.plasticWasteDisposalMaster.hasOwnProperty(name) && props.master.plasticWasteDisposalMaster[name].closedLoop !== undefined ? props.master.plasticWasteDisposalMaster[name].closedLoop : 0;
        let year = props.master.plasticMaterialUseMaster.hasOwnProperty(name) && props.master.plasticMaterialUseMaster[name].year !== undefined ? props.master.plasticMaterialUseMaster[name].year : 0;
        let source = props.master.plasticMaterialUseMaster.hasOwnProperty(name) && props.master.plasticMaterialUseMaster[name].source !== undefined ? props.master.plasticMaterialUseMaster[name].source : null;
        
        if (material.value === "Primary Material Product") {
            materialUseValue = primaryMaterialProduct
        }else if (material.value === "Closed-loop Source") {
            materialUseValue = closedLoopSource
        }
        let materialValue = materialUseValue

        if (disposal.value === "Landfill") {
            wasteDisposalValue = landFill
        }
        else if (disposal.value === "Closed-loop") {
            wasteDisposalValue = closedLoop
        }
        let disposalValue = wasteDisposalValue
        cf = footprintForPlasticCalc(amount, unitValue, materialValue, disposalValue);
        props.cFootprint({
            name: inputName,
            parent: "plasticConsumption",
            cf: { value: cf, volume, unit, material, disposal, year, source },
        });
        setCarbonFootprint(cf / 1000);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if (unit !== undefined && unit !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (volume !== undefined && volume !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (material !== undefined && material !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }

            if (disposal !== undefined && disposal !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }

            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [unit, volume, material, disposal]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "pvc" ? `scrollPlasticconsumption${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollPlasticconsumption"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-plasticConsumptionCollapse`} style={{ fontSize: "12px", fontWeight: "normal" }} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-amount`} name={`${id}-${inputName}-amount`} label={inputLabel} placeholder={"0"} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "100%", marginRight: "0px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTextChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col>
                            <div className={`${id}-${inputName}-plasticConsumptionCollapse`} style={{ fontSize: "12px", fontWeight: "normal" }} >
                                {
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label={selectLabel} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#40A0B5" />
                                            )}
                                            control={control}
                                            name={`${id}-${inputName}-volume-select`}
                                            defaultValue={unit}
                                        />
                                    ) : (<></>)
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-plasticConsumptionCollapse`} >
                                {
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onMaterialChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getMaterialOptions} label="Material Use" defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#40A0B5" />
                                            )}
                                            control={control}
                                            name={`${id}-${inputName}-materialOptions-select`}
                                            defaultValue={material}
                                        />
                                    ) : (<></>)
                                }
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-plasticConsumptionCollapse`} >
                                {
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onDisposalChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getDisposalOptions} label="Waste Disposal" defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#40A0B5" />
                                            )}
                                            control={control}
                                            name={`${id}-${inputName}-disposalOptions-select`}
                                            defaultValue={disposal}
                                        />
                                    ) : (<></>)
                                }
                            </div>
                        </Col>
                    </Row>

                    {/* material and disposal */}
                    <Row>
                    </Row>
                    <div className="carbonFootprint" >
                        <span>Carbon Footprint</span>
                        <br />
                        <br />
                        <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                        <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                    </div>
                    <hr style={{
                        color: '#40A0B5',
                        backgroundColor: '#40A0B5',
                        height: .5,
                        borderColor: '#40A0B53'
                    }} />
                </div>
            </Element>
        </Collapse >
    )
}
const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}
export default connect(mapStateToProps)(memo(CollapsePlasticConsumptionCustom));