import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import InputTextArea from "../../components/inputTextArea/InputTextAreaCustom";
import Summary from "../summary/Summary";
import {
    initialImpactIWLWFH,
    initialImpactPlane,
    initialImpactTrain,
    initialImpactTaxi,
    initialImpactCar,
    initialImpactPublicTransport,
    initialImpactHotelNights,
    initialImpactWaste,
    initialImpactFood,
    initialImpactDigital,
    targetImpactPlane,
    targetImpactTrain,
    targetImpactHSTrain,
    targetImpactTaxi,
    targetImpactCar,
    targetImpactPublicTransport,
    targetImpactOffice,
    targetImpactWFH,
    targetImpactHotelNights,
    targetImpactWaste,
    targetImpactFood,
    targetImpactDigital,
    actualImpactTaxi,
    actualImpactCar,
    actualImpactPublicTransport,
    actualImpactWFH,
    actualImpactOffice,
    initialImpactIWL
} from "../../calculatorFunctions/delivery_calc";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function isInt(n) {
    return n % 1 === 0;
}

const unitOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" }
];

const foodOptions = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" }
]

const initialReducerData = {
    initial: {
        ftpCount: 0,
        projectLength: 0,
        plane: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        highSpeedTrain: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        averageTrain: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        taxi: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        car: { rateOfDailyJournies: 0, avgJourneyHoursPerDay: 0, unitaryEmissions: 0, cf: 0 },
        publicTransport: { rateOfDailyJournies: 0, avgJourneyHoursPerDay: 0, unitaryEmissions: 0, cf: 0 },
        hotel: { totalNights: 0, unitaryEmissions: 0, cf: 0 },
        digital: { commit: "no", mbOfEmailSentPerDayPerPerson: 0, emissionsPerMbSent: 0, mbOfStokedSharepoint: 0, mbOfStokedSentEmail: 0, mbOfStokedReceivedEmail: 0, emissionsMbStockedPerYear: 0, avgLifeTimeLength: 0, cf: 0 },
        office: { rateOfClient: 0, rateOfRemote: 0, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: 0 },
        wfh: { rateOfClient: 0, rateOfRemote: 0, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: 0 },
        food: { committedNoOfMealsPerWeek: 0, unitaryEmissions: 0, cf: 0 },
        waste: { commit: "no", unitaryEmissions: 0, cf: 0 },
        visited: false,
        assumption: ""
    },
    target: {
        ftpCount: 0,
        projectLength: 0,
        plane: { rateOfPlane: 0, rateOfHighSpeedTrain: 0, rateOfRemote: 0, unitaryEmissionsPlane: 0, unitaryEmissionsHighSpeedTrain: 0, cf: 0 },
        highSpeedTrain: { rateOfHighSpeedTrain: 0, rateOfRemote: 0, unitaryEmissionsHighSpeedTrain: 0, cf: 0 },
        averageTrain: { rateOfAverageTrain: 0, rateOfRemote: 0, unitaryEmissionsAverageTrain: 0, cf: 0 },
        taxi: { rateOfTaxi: 0, rateOfElectricTaxi: 0, rateOfPublicTransport: 0, rateOfRemote: 0, unitaryEmissionsTaxi: 0, unitaryEmissionsElectricTaxi: 0, unitaryEmissionsPublicTransport: 0, cf: 0 },
        car: { rateOfPrivateCar: 0, rateOfPublicTransport: 0, rateOfSoftTransport: 0, unitaryEmissionsPrivateCar: 0, unitaryEmissionsPublicTransport: 0, unitaryEmissionsSoftTransport: 0, cf: 0 },
        publicTransport: { rateOfPublicTransport: 0, rateOfSoftTransport: 0, unitaryEmissionsPublicTransport: 0, unitaryEmissionsSoftTransport: 0, cf: 0 },
        hotel: { totalNights: 0, unitaryEmissions: 0, cf: 0 },
        digital: { commit: "no", mbOfEmailSentPerDayPerPerson: 0, emissionsPerMbSent: 0, mbOfStokedSharepoint: 0, mbOfStokedSentEmail: 0, mbOfStokedReceivedEmail: 0, emissionsMbStockedPerYear: 0, avgLifeTimeLength: 0, rateOfDataAvoidedInEmailSentPerDayPerPerson: 0, rateOfDataAvoidedInStokedSharepoint: 0, rateOfDataAvoidedInStokedSentEmail: 0, rateOfDataAvoidedInStokedReceivedEmail: 0, cf: 0 },
        office: { rateOfClient: 0, rateOfRemote: 0, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: 0 },
        wfh: { rateOfClient: 0, rateOfRemote: 0, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: 0 },
        food: { unitaryEmissionsInNormalFood: 0, unitaryEmissionsInVegFood: 0, committedNoOfMealsPerWeek: 0, cf: 0 },
        waste: { commit: "no", initialUnitaryEmissions: 0, targetUnitaryEmissions: 0, cf: 0 },
        visited: false,
        assumption: ""
    },
    actual: {
        ftpCount: 0,
        projectLength: 0,
        plane: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        highSpeedTrain: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        averageTrain: { totalHours: 0, unitaryEmissions: 0, cf: 0 },
        taxi: { totalHoursTaxi: 0, totalHoursElectricTaxi: 0, totalHoursPublicTransport: 0, unitaryEmissionsTaxi: 0, unitaryEmissionsElectricTaxi: 0, unitaryEmissionsPublicTransport: 0, cf: 0 },
        car: { rateOfDailyJournies: 0, avgJourneyHoursPerDay: 0, unitaryEmissions: 0, cf: 0 },
        publicTransport: { rateOfDailyJournies: 0, avgJourneyHoursPerDay: 0, unitaryEmissions: 0, cf: 0 },
        hotel: { totalNights: 0, unitaryEmissions: 0, cf: 0 },
        digital: { commit: "no", mbOfEmailSentPerDayPerPerson: 0, emissionsPerMbSent: 0, mbOfStokedSharepoint: 0, mbOfStokedSentEmail: 0, mbOfStokedReceivedEmail: 0, emissionsMbStockedPerYear: 0, avgLifeTimeLength: 0, rateOfDataAvoidedInEmailSentPerDayPerPerson: 0, rateOfDataAvoidedInStokedSharepoint: 0, rateOfDataAvoidedInStokedSentEmail: 0, rateOfDataAvoidedInStokedReceivedEmail: 0, cf: 0 },
        office: { rateOfClient: 0, rateOfRemote: 0, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: 0 },
        wfh: { rateOfClient: 0, rateOfRemote: 0, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: 0 },
        food: { unitaryEmissionsInNormalFood: 0, unitaryEmissionsInVegFood: 0, committedNoOfMealsPerWeek: 0, cf: 0 },
        waste: { commit: "no", initialUnitaryEmissions: 0, targetUnitaryEmissions: 0, cf: 0 },
        visited: false,
        assumption: ""
    },
}

const CollapseNewDeliveryCustom = (props) => {
    let { collapseControll, id, label, inputName, inputPlaceholder, inputReducerData, setValue, control, register, checkError, errors } = props;
    const [key, setKey] = useState("");
    const [shouldChangeTab, setShouldChangeTab] = useState("");
    const [checkTabs, setCheckTabs] = useState(false);

    const reducer = (state, action) => {
        switch (action.type) {
            case `SET_NEW_DELIVERY`:
                state = action.payload;
                break;
            case `SET_NEW_DELIVERY_VISITED_VALUE`:
                state = {
                    ...state,
                    [action.stage]: { ...state[action.stage], [action.param]: action.payload }
                }
                break;
            case `SET_NEW_DELIVERY_ASSUMPTION_VALUE`:
                state = {
                    ...state,
                    [action.stage]: { ...state[action.stage], [action.param]: action.payload }
                }
                break;
            case `SET_NEW_DELIVERY_CF_VALUE`:
                if (action.param === "ftpCount" || action.param === "projectLength") {
                    state = {
                        ...state,
                        [action.stage]: { ...state[action.stage], [action.param]: action.payload }
                    }
                } else {
                    state = {
                        ...state,
                        [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], [action.elem]: action.payload } }
                    }
                }
                if (action.stage === "initial") {
                    let cf = 0;

                    if (action.param === "ftpCount" || action.param === "projectLength") {
                        //office
                        state = {
                            ...state,
                            [action.stage]: {
                                ...state[action.stage],
                                "office": {
                                    ...state[action.stage]["office"],
                                    numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365),
                                    numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235)
                                },
                                "wfh": {
                                    ...state[action.stage]["wfh"],
                                    numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235)
                                }
                            }
                        }

                        cf = initialImpactIWL(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        /*  cf = initialImpactOffice(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"]);
                            state = {
                              ...state,
                              [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                            } */
                        //
                        cf = initialImpactIWL(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                        }
                        //

                        // wfh
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) }, "wfh": { ...state[action.stage]["wfh"], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = initialImpactIWLWFH(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "wfh": { ...state[action.stage]["wfh"], cf: cf / 1000 } }
                        }

                        //car
                        if (state["target"]["visited"]) {
                            //car
                            state = {
                                ...state,
                                ["target"]: { ...state["target"], ["car"]: { ...state["target"]["car"], unitaryEmissionsPrivateCar: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                            }
                            cf = targetImpactCar(
                                state["target"]["car"]["rateOfPrivateCar"],
                                state["target"]["car"]["rateOfPublicTransport"],
                                state["target"]["car"]["rateOfSoftTransport"],
                                state["target"]["office"]["rateOfRemote"],
                                state["initial"]["car"]["rateOfDailyJournies"],
                                state["initial"]["car"]["avgJourneyHoursPerDay"],
                                state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                state["target"]["ftpCount"],
                                state["target"]["projectLength"],
                                state["target"]["car"]["unitaryEmissionsPrivateCar"],
                                state["target"]["car"]["unitaryEmissionsPublicTransport"],
                                state["target"]["car"]["unitaryEmissionsSoftTransport"],
                            );
                            state = {
                                ...state,
                                target: { ...state["target"], "car": { ...state["target"]["car"], cf: cf / 1000 } }
                            }
                            //publicTransport
                            state = {
                                ...state,
                                ["target"]: { ...state["target"], ["publicTransport"]: { ...state["target"]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                            }
                            cf = targetImpactPublicTransport(
                                state["target"]["publicTransport"]["rateOfPublicTransport"],
                                state["target"]["publicTransport"]["rateOfSoftTransport"],
                                state["target"]["office"]["rateOfRemote"],
                                state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                state["target"]["ftpCount"],
                                state["target"]["projectLength"],
                                state["target"]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                state["target"]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                state["initial"]["taxi"]["totalHours"],
                                state["target"]["taxi"]["rateOfPublicTransport"] ? 0 : state["target"]["taxi"]["rateOfPublicTransport"],
                                state["target"]["taxi"]["unitaryEmissionsPublicTransport"],
                                state["initial"]["car"]["avgJourneyHoursPerDay"] ? 0 : state["initial"]["car"]["avgJourneyHoursPerDay"],
                                state["initial"]["car"]["rateOfDailyJournies"] ? 0 : state["initial"]["car"]["rateOfDailyJournies"],
                                state["target"]["car"]["rateOfPublicTransport"] ? 0 : state["target"]["car"]["rateOfPublicTransport"]
                            );
                            state = {
                                ...state,
                                target: { ...state["target"], "publicTransport": { ...state["target"]["publicTransport"], cf: cf / 1000 } }
                            }
                        }
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["car"]: { ...state[action.stage]["car"], unitaryEmissions: props.master.transportFactorMaster["Taxi"]["unitaryEmission"] } }
                        }
                        cf = initialImpactCar(
                            state[action.stage]["car"]["rateOfDailyJournies"],
                            state[action.stage]["car"]["avgJourneyHoursPerDay"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["car"]["unitaryEmissions"],
                            state[action.stage]["office"]["rateOfRemote"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "car": { ...state[action.stage]["car"], cf: cf / 1000 } }
                        }

                        //publicTransport
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["publicTransport"]: { ...state[action.stage]["publicTransport"], unitaryEmissions: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = initialImpactPublicTransport(
                            state[action.stage]["publicTransport"]["rateOfDailyJournies"],
                            state[action.stage]["publicTransport"]["avgJourneyHoursPerDay"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["publicTransport"]["unitaryEmissions"],
                            state[action.stage]["office"]["rateOfRemote"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "publicTransport": { ...state[action.stage]["publicTransport"], cf: cf / 1000 } }
                        }
                        //digital
                        state = {
                            ...state,
                            [action.stage]: {
                                ...state[action.stage],
                                ["digital"]: {
                                    ...state[action.stage]["digital"],
                                    mbOfEmailSentPerDayPerPerson: props.master.digitalFactorMaster["Mb sent (per day per person)"]["numberOfMB"],
                                    emissionsPerMbSent: props.master.digitalFactorMaster["Mb sent (per day per person)"]["unitaryEmission"],
                                    mbOfStokedSharepoint: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["numberOfMB"],
                                    mbOfStokedSentEmail: props.master.digitalFactorMaster["Mb stocked (sent emails)"]["numberOfMB"],
                                    mbOfStokedReceivedEmail: props.master.digitalFactorMaster["Mb stocked (mail received)"]["numberOfMB"],
                                    emissionsMbStockedPerYear: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["unitaryEmission"],
                                    avgLifeTimeLength: 3,
                                    rateOfDataAvoidedInEmailSentPerDayPerPerson: 0,
                                    rateOfDataAvoidedInStokedSharepoint: 50,
                                    rateOfDataAvoidedInStokedSentEmail: 50,
                                    rateOfDataAvoidedInStokedReceivedEmail: 50
                                }
                            }
                        }
                        cf = targetImpactDigital(
                            state[action.stage]["digital"]["mbOfEmailSentPerDayPerPerson"],
                            state[action.stage]["digital"]["emissionsPerMbSent"],
                            state[action.stage]["digital"]["mbOfStokedSharepoint"],
                            state[action.stage]["digital"]["mbOfStokedSentEmail"],
                            state[action.stage]["digital"]["mbOfStokedReceivedEmail"],
                            state[action.stage]["digital"]["emissionsMbStockedPerYear"],
                            state[action.stage]["digital"]["avgLifeTimeLength"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["digital"]["commit"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "digital": { ...state[action.stage]["digital"], cf: cf / 1000 } }
                        }
                        //food
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["food"]: { ...state[action.stage]["food"], unitaryEmissionsInNormalFood: props.master.foodFactorMaster["Meat"]["unitaryEmission"], unitaryEmissionsInVegFood: props.master.foodFactorMaster["Vegetarian"]["unitaryEmission"] } }
                        }
                        cf = targetImpactFood(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["food"]["unitaryEmissionsInNormalFood"],
                            state[action.stage]["food"]["unitaryEmissionsInVegFood"],
                            state[action.stage]["food"]["committedNoOfMealsPerWeek"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "food": { ...state[action.stage]["food"], cf: cf / 1000 } }
                        }

                        // high speed train 
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["highSpeedTrain"]: { ...state[action.stage]["highSpeedTrain"], unitaryEmissions: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["highSpeedTrain"]["totalHours"], state[action.stage]["highSpeedTrain"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "highSpeedTrain": { ...state[action.stage]["highSpeedTrain"], cf: cf / 1000 } }
                        }
                        // average train
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["averageTrain"]: { ...state[action.stage]["averageTrain"], unitaryEmissions: (props.master.transportFactorMaster["Average Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["averageTrain"]["totalHours"], state[action.stage]["averageTrain"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "averageTrain": { ...state[action.stage]["averageTrain"], cf: cf / 1000 } }
                        }

                        // plane
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], unitaryEmissions: props.master.transportFactorMaster["Plane"]["unitaryEmission"] } }
                        }
                        cf = initialImpactPlane(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["plane"]["totalHours"], state[action.stage]["plane"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "plane": { ...state[action.stage]["plane"], cf: cf / 1000 } }
                        }
                        // hotel
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["hotel"]: { ...state[action.stage]["hotel"], unitaryEmissions: props.master.hotelFactorMaster[inputName]["unitaryEmission"] } }
                        }
                        cf = initialImpactHotelNights(state[action.stage]["hotel"]["totalNights"], state[action.stage]["hotel"]["unitaryEmissions"], state[action.stage]["ftpCount"], state[action.stage]["projectLength"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "hotel": { ...state[action.stage]["hotel"], cf: cf / 1000 } }
                        }
                        //waste
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["waste"]: { ...state[action.stage]["waste"], initialUnitaryEmissions: props.master.wasteFactorMaster["Waste No Effort"]["unitaryEmission"], targetUnitaryEmissions: props.master.wasteFactorMaster["Waste Effort"]["unitaryEmission"] } }
                        }
                        cf = targetImpactWaste(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["waste"]["initialUnitaryEmissions"],
                            state[action.stage]["waste"]["targetUnitaryEmissions"],
                            state[action.stage]["waste"]["commit"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "waste": { ...state[action.stage]["waste"], cf: cf / 1000 } }
                        }

                        // taxi

                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["taxi"]: { ...state[action.stage]["taxi"], unitaryEmissions: props.master.transportFactorMaster["Taxi"]["unitaryEmission"] } }
                        }
                        cf = initialImpactTaxi(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["taxi"]["totalHours"], state[action.stage]["taxi"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "taxi": { ...state[action.stage]["taxi"], cf: cf / 1000 } }
                        }
                    } else {
                        switch (action.param) {


                            case "plane":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.transportFactorMaster["Plane"]["unitaryEmission"] } }
                                }
                                cf = initialImpactPlane(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["plane"]["totalHours"], state[action.stage]["plane"]["unitaryEmissions"]);
                                break;
                            case "highSpeedTrain":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["highSpeedTrain"]["totalHours"], state[action.stage]["highSpeedTrain"]["unitaryEmissions"]);
                                break;
                            case "averageTrain":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: (props.master.transportFactorMaster["Average Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["averageTrain"]["totalHours"], state[action.stage]["averageTrain"]["unitaryEmissions"]);
                                break;
                            case "taxi":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.transportFactorMaster["Taxi"]["unitaryEmission"] } }
                                }
                                cf = initialImpactTaxi(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["taxi"]["totalHours"], state[action.stage]["taxi"]["unitaryEmissions"]);
                                break;
                            case "car":

                                // calculating CF for target car and public transport
                                if (state["target"]["visited"]) {
                                    //car
                                    state = {
                                        ...state,
                                        ["target"]: { ...state["target"], ["car"]: { ...state["target"]["car"], unitaryEmissionsPrivateCar: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                    }
                                    cf = targetImpactCar(
                                        state["target"]["car"]["rateOfPrivateCar"],
                                        state["target"]["car"]["rateOfPublicTransport"],
                                        state["target"]["car"]["rateOfSoftTransport"],
                                        state["target"]["office"]["rateOfRemote"],
                                        state["initial"]["car"]["rateOfDailyJournies"],
                                        state["initial"]["car"]["avgJourneyHoursPerDay"],
                                        state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                        state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                        state["target"]["ftpCount"],
                                        state["target"]["projectLength"],
                                        state["target"]["car"]["unitaryEmissionsPrivateCar"],
                                        state["target"]["car"]["unitaryEmissionsPublicTransport"],
                                        state["target"]["car"]["unitaryEmissionsSoftTransport"],
                                    );
                                    state = {
                                        ...state,
                                        target: { ...state["target"], "car": { ...state["target"]["car"], cf: cf / 1000 } }
                                    }
                                    //publicTransport
                                    state = {
                                        ...state,
                                        ["target"]: { ...state["target"], ["publicTransport"]: { ...state["target"]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                    }
                                    cf = targetImpactPublicTransport(
                                        state["target"]["publicTransport"]["rateOfPublicTransport"],
                                        state["target"]["publicTransport"]["rateOfSoftTransport"],
                                        state["target"]["office"]["rateOfRemote"],
                                        state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                        state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                        state["target"]["ftpCount"],
                                        state["target"]["projectLength"],
                                        state["target"]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                        state["target"]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                        state["initial"]["taxi"]["totalHours"],
                                        state["target"]["taxi"]["rateOfPublicTransport"] ? 0 : state["target"]["taxi"]["rateOfPublicTransport"],
                                        state["target"]["taxi"]["unitaryEmissionsPublicTransport"],
                                        state["initial"]["car"]["avgJourneyHoursPerDay"] ? 0 : state["initial"]["car"]["avgJourneyHoursPerDay"],
                                        state["initial"]["car"]["rateOfDailyJournies"] ? 0 : state["initial"]["car"]["rateOfDailyJournies"],
                                        state["target"]["car"]["rateOfPublicTransport"] ? 0 : state["target"]["car"]["rateOfPublicTransport"]
                                    );
                                    state = {
                                        ...state,
                                        target: { ...state["target"], "publicTransport": { ...state["target"]["publicTransport"], cf: cf / 1000 } }
                                    }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.transportFactorMaster["Taxi"]["unitaryEmission"] } }
                                }
                                cf = initialImpactCar(
                                    state[action.stage]["car"]["rateOfDailyJournies"],
                                    state[action.stage]["car"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["car"]["unitaryEmissions"],
                                    state[action.stage]["office"]["rateOfRemote"]
                                );

                                break;
                            case "office":
                                ///wfh
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = initialImpactIWLWFH(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["office"]["rateOfClient"], state[action.stage]["office"]["rateOfRemote"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]);
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "wfh": { ...state[action.stage]["wfh"], cf: cf / 1000 } }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) } }
                                }

                                cf = initialImpactIWL(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["office"]["rateOfClient"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]
                                );
                                break;
                            case "wfh":
                                // office
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) } }
                                }
                                cf = initialImpactIWL(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["office"]["rateOfClient"], state[action.stage]["office"]["rateOfRemote"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]);
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                                }

                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = initialImpactIWLWFH(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["office"]["rateOfClient"], state[action.stage]["office"]["rateOfRemote"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"], state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"]);
                                break;

                            case "publicTransport":
                                // calculating CF for target car and public transport
                                if (state["target"]["visited"]) {
                                    //car
                                    state = {
                                        ...state,
                                        ["target"]: { ...state["target"], ["car"]: { ...state["target"]["car"], unitaryEmissionsPrivateCar: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                    }
                                    cf = targetImpactCar(
                                        state["target"]["car"]["rateOfPrivateCar"],
                                        state["target"]["car"]["rateOfPublicTransport"],
                                        state["target"]["car"]["rateOfSoftTransport"],
                                        state["target"]["office"]["rateOfRemote"],
                                        state["initial"]["car"]["rateOfDailyJournies"],
                                        state["initial"]["car"]["avgJourneyHoursPerDay"],
                                        state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                        state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                        state["target"]["ftpCount"],
                                        state["target"]["projectLength"],
                                        state["target"]["car"]["unitaryEmissionsPrivateCar"],
                                        state["target"]["car"]["unitaryEmissionsPublicTransport"],
                                        state["target"]["car"]["unitaryEmissionsSoftTransport"],
                                    );
                                    state = {
                                        ...state,
                                        target: { ...state["target"], "car": { ...state["target"]["car"], cf: cf / 1000 } }
                                    }
                                    //publicTransport
                                    state = {
                                        ...state,
                                        ["target"]: { ...state["target"], ["publicTransport"]: { ...state["target"]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                    }
                                    cf = targetImpactPublicTransport(
                                        state["target"]["publicTransport"]["rateOfPublicTransport"],
                                        state["target"]["publicTransport"]["rateOfSoftTransport"],
                                        state["target"]["office"]["rateOfRemote"],
                                        state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                        state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                        state["target"]["ftpCount"],
                                        state["target"]["projectLength"],
                                        state["target"]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                        state["target"]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                        state["initial"]["taxi"]["totalHours"],
                                        state["target"]["taxi"]["rateOfPublicTransport"] ? 0 : state["target"]["taxi"]["rateOfPublicTransport"],
                                        state["target"]["taxi"]["unitaryEmissionsPublicTransport"],
                                        state["initial"]["car"]["avgJourneyHoursPerDay"] ? 0 : state["initial"]["car"]["avgJourneyHoursPerDay"],
                                        state["initial"]["car"]["rateOfDailyJournies"] ? 0 : state["initial"]["car"]["rateOfDailyJournies"],
                                        state["target"]["car"]["rateOfPublicTransport"] ? 0 : state["target"]["car"]["rateOfPublicTransport"]
                                    );
                                    state = {
                                        ...state,
                                        target: { ...state["target"], "publicTransport": { ...state["target"]["publicTransport"], cf: cf / 1000 } }
                                    }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = initialImpactPublicTransport(
                                    state[action.stage]["publicTransport"]["rateOfDailyJournies"],
                                    state[action.stage]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"], state[action.stage]["projectLength"],
                                    state[action.stage]["publicTransport"]["unitaryEmissions"],
                                    state["initial"]["office"]["rateOfRemote"]
                                );
                                // calculating CF for target car and public transport

                                break;
                            case "hotel":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.hotelFactorMaster[inputName]["unitaryEmission"] } }
                                }
                                cf = initialImpactHotelNights(state[action.stage]["hotel"]["totalNights"], state[action.stage]["hotel"]["unitaryEmissions"], state[action.stage]["ftpCount"], state[action.stage]["projectLength"]);
                                break;
                            case "waste":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], initialUnitaryEmissions: props.master.wasteFactorMaster["Waste No Effort"]["unitaryEmission"], targetUnitaryEmissions: props.master.wasteFactorMaster["Waste Effort"]["unitaryEmission"] } }
                                }
                                cf = targetImpactWaste(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["waste"]["initialUnitaryEmissions"],
                                    state[action.stage]["waste"]["targetUnitaryEmissions"],
                                    state[action.stage]["waste"]["commit"],
                                );
                                break;
                            case "food":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsInNormalFood: props.master.foodFactorMaster["Meat"]["unitaryEmission"], unitaryEmissionsInVegFood: props.master.foodFactorMaster["Vegetarian"]["unitaryEmission"] } }
                                }
                                cf = targetImpactFood(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["food"]["unitaryEmissionsInNormalFood"],
                                    state[action.stage]["food"]["unitaryEmissionsInVegFood"],
                                    state[action.stage]["food"]["committedNoOfMealsPerWeek"],
                                );
                                break;
                            case "digital":
                                state = {
                                    ...state,
                                    [action.stage]: {
                                        ...state[action.stage],
                                        [action.param]: {
                                            ...state[action.stage][action.param],
                                            mbOfEmailSentPerDayPerPerson: props.master.digitalFactorMaster["Mb sent (per day per person)"]["numberOfMB"],
                                            emissionsPerMbSent: props.master.digitalFactorMaster["Mb sent (per day per person)"]["unitaryEmission"],
                                            mbOfStokedSharepoint: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["numberOfMB"],
                                            mbOfStokedSentEmail: props.master.digitalFactorMaster["Mb stocked (sent emails)"]["numberOfMB"],
                                            mbOfStokedReceivedEmail: props.master.digitalFactorMaster["Mb stocked (mail received)"]["numberOfMB"],
                                            emissionsMbStockedPerYear: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["unitaryEmission"],
                                            avgLifeTimeLength: 3,
                                            rateOfDataAvoidedInEmailSentPerDayPerPerson: 0,
                                            rateOfDataAvoidedInStokedSharepoint: 50,
                                            rateOfDataAvoidedInStokedSentEmail: 50,
                                            rateOfDataAvoidedInStokedReceivedEmail: 50
                                        }
                                    }
                                }
                                cf = targetImpactDigital(
                                    state[action.stage]["digital"]["mbOfEmailSentPerDayPerPerson"],
                                    state[action.stage]["digital"]["emissionsPerMbSent"],
                                    state[action.stage]["digital"]["mbOfStokedSharepoint"],
                                    state[action.stage]["digital"]["mbOfStokedSentEmail"],
                                    state[action.stage]["digital"]["mbOfStokedReceivedEmail"],
                                    state[action.stage]["digital"]["emissionsMbStockedPerYear"],
                                    state[action.stage]["digital"]["avgLifeTimeLength"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["digital"]["commit"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"]
                                );
                                break;
                            default:
                                break;
                        }
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], cf: cf / 1000 } }
                        }
                    }
                } else if (action.stage === "target") {
                    let cf = 0;
                    if (action.param === "ftpCount" || action.param === "projectLength") {

                        //wfh
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["office"]: { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) }, ["wfh"]: { ...state[action.stage]["wfh"], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = targetImpactWFH(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );

                        //office
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["office"]: { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) } }
                        }
                        cf = targetImpactOffice(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                        }

                        // plane
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], unitaryEmissions: props.master.transportFactorMaster["Plane"]["unitaryEmission"] } }
                        }
                        cf = targetImpactPlane(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state["initial"]["plane"]["totalHours"],
                            state[action.stage]["plane"]["rateOfPlane"],
                            state[action.stage]["plane"]["rateOfHighSpeedTrain"],
                            state[action.stage]["plane"]["rateOfRemote"],
                            state[action.stage]["plane"]["unitaryEmissionsPlane"],
                            state[action.stage]["plane"]["unitaryEmissionsHighSpeedTrain"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], cf: cf / 1000 } }
                        }

                        // HS train
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["highSpeedTrain"]: { ...state[action.stage]["highSpeedTrain"], unitaryEmissionsHighSpeedTrain: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = targetImpactHSTrain(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state["initial"]["highSpeedTrain"]["totalHours"],
                            state[action.stage]["highSpeedTrain"]["rateOfHighSpeedTrain"],
                            state[action.stage]["highSpeedTrain"]["rateOfRemote"],
                            state[action.stage]["highSpeedTrain"]["unitaryEmissionsHighSpeedTrain"],
                            state["initial"]["plane"]["totalHours"],
                            state[action.stage]["plane"]["rateOfHighSpeedTrain"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["highSpeedTrain"]: { ...state[action.stage]["highSpeedTrain"], cf: cf / 1000 } }
                        }

                        // average train
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["averageTrain"]: { ...state[action.stage]["averageTrain"], unitaryEmissionsAverageTrain: (props.master.transportFactorMaster["Average Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = targetImpactTrain(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state["initial"]["averageTrain"]["totalHours"],
                            state[action.stage]["averageTrain"]["rateOfAverageTrain"],
                            state[action.stage]["averageTrain"]["rateOfRemote"],
                            state[action.stage]["averageTrain"]["unitaryEmissionsAverageTrain"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["averageTrain"]: { ...state[action.stage]["averageTrain"], cf: cf / 1000 } }
                        }

                        //car
                        state = {
                            ...state,
                            ["target"]: { ...state["target"], ["car"]: { ...state["target"]["car"], unitaryEmissionsPrivateCar: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                        }
                        cf = targetImpactCar(
                            state[action.stage]["car"]["rateOfPrivateCar"],
                            state[action.stage]["car"]["rateOfPublicTransport"],
                            state[action.stage]["car"]["rateOfSoftTransport"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state["initial"]["car"]["rateOfDailyJournies"],
                            state["initial"]["car"]["avgJourneyHoursPerDay"],
                            state["initial"]["publicTransport"]["rateOfDailyJournies"],
                            state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["car"]["unitaryEmissionsPrivateCar"],
                            state[action.stage]["car"]["unitaryEmissionsPublicTransport"],
                            state[action.stage]["car"]["unitaryEmissionsSoftTransport"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "car": { ...state[action.stage]["car"], cf: cf / 1000 } }
                        }
                        //publicTransport
                        state = {
                            ...state,
                            ["target"]: { ...state["target"], ["publicTransport"]: { ...state["target"]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                        }
                        cf = targetImpactPublicTransport(
                            state[action.stage]["publicTransport"]["rateOfPublicTransport"],
                            state[action.stage]["publicTransport"]["rateOfSoftTransport"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state["initial"]["publicTransport"]["rateOfDailyJournies"],
                            state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["publicTransport"]["unitaryEmissionsPublicTransport"],
                            state[action.stage]["publicTransport"]["unitaryEmissionsSoftTransport"],
                            state["initial"]["taxi"]["totalHours"],
                            state[action.stage]["taxi"]["rateOfPublicTransport"],
                            state[action.stage]["publicTransport"]["unitaryEmissionsPublicTransport"],
                            state["initial"]["car"]["avgJourneyHoursPerDay"],
                            state["initial"]["car"]["rateOfDailyJournies"],
                            state["target"]["car"]["rateOfPublicTransport"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "publicTransport": { ...state[action.stage]["publicTransport"], cf: cf / 1000 } }
                        }
                        //digital
                        state = {
                            ...state,
                            [action.stage]: {
                                ...state[action.stage],
                                ["digital"]: {
                                    ...state[action.stage]["digital"],
                                    mbOfEmailSentPerDayPerPerson: props.master.digitalFactorMaster["Mb sent (per day per person)"]["numberOfMB"],
                                    emissionsPerMbSent: props.master.digitalFactorMaster["Mb sent (per day per person)"]["unitaryEmission"],
                                    mbOfStokedSharepoint: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["numberOfMB"],
                                    mbOfStokedSentEmail: props.master.digitalFactorMaster["Mb stocked (sent emails)"]["numberOfMB"],
                                    mbOfStokedReceivedEmail: props.master.digitalFactorMaster["Mb stocked (mail received)"]["numberOfMB"],
                                    emissionsMbStockedPerYear: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["unitaryEmission"],
                                    avgLifeTimeLength: 3,
                                    rateOfDataAvoidedInEmailSentPerDayPerPerson: 0,
                                    rateOfDataAvoidedInStokedSharepoint: 50,
                                    rateOfDataAvoidedInStokedSentEmail: 50,
                                    rateOfDataAvoidedInStokedReceivedEmail: 50,
                                }
                            }
                        }
                        cf = targetImpactDigital(
                            state[action.stage]["digital"]["mbOfEmailSentPerDayPerPerson"],
                            state[action.stage]["digital"]["emissionsPerMbSent"],
                            state[action.stage]["digital"]["mbOfStokedSharepoint"],
                            state[action.stage]["digital"]["mbOfStokedSentEmail"],
                            state[action.stage]["digital"]["mbOfStokedReceivedEmail"],
                            state[action.stage]["digital"]["emissionsMbStockedPerYear"],
                            state[action.stage]["digital"]["avgLifeTimeLength"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["digital"]["commit"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "digital": { ...state[action.stage]["digital"], cf: cf / 1000 } }
                        }
                        //food
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["food"]: { ...state[action.stage]["food"], unitaryEmissionsInNormalFood: props.master.foodFactorMaster["Meat"]["unitaryEmission"], unitaryEmissionsInVegFood: props.master.foodFactorMaster["Vegetarian"]["unitaryEmission"] } }
                        }
                        cf = targetImpactFood(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["food"]["unitaryEmissionsInNormalFood"],
                            state[action.stage]["food"]["unitaryEmissionsInVegFood"],
                            state[action.stage]["food"]["committedNoOfMealsPerWeek"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "food": { ...state[action.stage]["food"], cf: cf / 1000 } }
                        }
                        //waste
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["waste"]: { ...state[action.stage]["waste"], initialUnitaryEmissions: props.master.wasteFactorMaster["Waste No Effort"]["unitaryEmission"], targetUnitaryEmissions: props.master.wasteFactorMaster["Waste Effort"]["unitaryEmission"] } }
                        }
                        cf = targetImpactWaste(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["waste"]["initialUnitaryEmissions"],
                            state[action.stage]["waste"]["targetUnitaryEmissions"],
                            state[action.stage]["waste"]["commit"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "waste": { ...state[action.stage]["waste"], cf: cf / 1000 } }
                        }

                        // taxi
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["taxi"]: { ...state[action.stage]["taxi"], unitaryEmissionsTaxi: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsElectricTaxi: (props.master.transportFactorMaster["Electric Taxi"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = targetImpactTaxi(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state["initial"]["taxi"]["totalHours"],
                            state[action.stage]["taxi"]["rateOfTaxi"],
                            state[action.stage]["taxi"]["rateOfElectricTaxi"],
                            state[action.stage]["taxi"]["rateOfPublicTransport"],
                            state[action.stage]["taxi"]["rateOfRemote"],
                            state[action.stage]["taxi"]["unitaryEmissionsTaxi"],
                            state[action.stage]["taxi"]["unitaryEmissionsElectricTaxi"],
                            state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["taxi"]: { ...state[action.stage]["taxi"], cf: cf / 1000 } }
                        }
                        // hotel
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["hotel"]: { ...state[action.stage]["hotel"], unitaryEmissions: props.master.hotelFactorMaster[inputName]["unitaryEmission"] } }
                        }
                        cf = targetImpactHotelNights(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["hotel"]["totalNights"], state[action.stage]["hotel"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["hotel"]: { ...state[action.stage]["hotel"], cf: cf / 1000 } }
                        }
                        //wfh
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["wfh"]: { ...state[action.stage]["wfh"], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = targetImpactWFH(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "wfh": { ...state[action.stage]["wfh"], cf: cf / 1000 } }
                        }

                        //office
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["office"]: { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365), numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = targetImpactOffice(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                        }
                        break;
                    } else {
                        switch (action.param) {
                            case "office":
                                //car
                                state = {
                                    ...state,
                                    ["target"]: { ...state["target"], ["car"]: { ...state["target"]["car"], unitaryEmissionsPrivateCar: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                }
                                state = {
                                    ...state,
                                    ["target"]: { ...state["target"], ["publicTransport"]: { ...state["target"]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                }
                                cf = targetImpactCar(
                                    state[action.stage]["car"]["rateOfPrivateCar"],
                                    state[action.stage]["car"]["rateOfPublicTransport"],
                                    state[action.stage]["car"]["rateOfSoftTransport"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state["initial"]["car"]["rateOfDailyJournies"],
                                    state["initial"]["car"]["avgJourneyHoursPerDay"],
                                    state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                    state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["car"]["unitaryEmissionsPrivateCar"],
                                    state[action.stage]["car"]["unitaryEmissionsPublicTransport"],
                                    state[action.stage]["car"]["unitaryEmissionsSoftTransport"],
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "car": { ...state[action.stage]["car"], cf: cf / 1000 } }
                                }
                                //publicTransport
                                cf = targetImpactPublicTransport(
                                    state[action.stage]["publicTransport"]["rateOfPublicTransport"],
                                    state[action.stage]["publicTransport"]["rateOfSoftTransport"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                    state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                    state["initial"]["taxi"]["totalHours"],
                                    state[action.stage]["taxi"]["rateOfPublicTransport"],
                                    state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                                    state["initial"]["car"]["avgJourneyHoursPerDay"],
                                    state["initial"]["car"]["rateOfDailyJournies"],
                                    state["target"]["car"]["rateOfPublicTransport"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "publicTransport": { ...state[action.stage]["publicTransport"], cf: cf / 1000 } }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], unitaryEmissions: props.master.transportFactorMaster["Plane"]["unitaryEmission"] } }
                                }
                                cf = targetImpactPlane(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state["initial"]["plane"]["totalHours"],
                                    state[action.stage]["plane"]["rateOfPlane"],
                                    state[action.stage]["plane"]["rateOfHighSpeedTrain"],
                                    state[action.stage]["plane"]["rateOfRemote"],
                                    state[action.stage]["plane"]["unitaryEmissionsPlane"],
                                    state[action.stage]["plane"]["unitaryEmissionsHighSpeedTrain"],
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], cf: cf / 1000 } }
                                }
                                // wfh
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = targetImpactWFH(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "wfh": { ...state[action.stage]["wfh"], cf: cf / 1000 } }
                                }

                                // office
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) } }
                                }
                                cf = targetImpactOffice(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["rateOfClient"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );
                                break;
                            case "wfh":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365), numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = targetImpactOffice(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["rateOfClient"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                                }

                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = targetImpactWFH(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );
                                break;
                            case "plane":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "highSpeedTrain": { ...state[action.stage]["highSpeedTrain"], unitaryEmissionsHighSpeedTrain: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = targetImpactHSTrain(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state["initial"]["highSpeedTrain"]["totalHours"],
                                    state[action.stage]["highSpeedTrain"]["rateOfHighSpeedTrain"],
                                    state[action.stage]["highSpeedTrain"]["rateOfRemote"],
                                    state[action.stage]["highSpeedTrain"]["unitaryEmissionsHighSpeedTrain"],
                                    state["initial"]["plane"]["totalHours"],
                                    state[action.stage]["plane"]["rateOfHighSpeedTrain"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["highSpeedTrain"]: { ...state[action.stage]["highSpeedTrain"], cf: cf / 1000 } }
                                }

                                // plane
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsPlane: props.master.transportFactorMaster["Plane"]["unitaryEmission"], unitaryEmissionsHighSpeedTrain: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = targetImpactPlane(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state["initial"]["plane"]["totalHours"],
                                    state[action.stage]["plane"]["rateOfPlane"],
                                    state[action.stage]["plane"]["rateOfHighSpeedTrain"],
                                    state[action.stage]["plane"]["rateOfRemote"],
                                    state[action.stage]["plane"]["unitaryEmissionsPlane"],
                                    state[action.stage]["plane"]["unitaryEmissionsHighSpeedTrain"],
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], cf: cf / 1000 } }
                                }

                                break;
                            case "highSpeedTrain":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsHighSpeedTrain: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = targetImpactHSTrain(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state["initial"]["highSpeedTrain"]["totalHours"],
                                    state[action.stage]["highSpeedTrain"]["rateOfHighSpeedTrain"],
                                    state[action.stage]["highSpeedTrain"]["rateOfRemote"],
                                    state[action.stage]["highSpeedTrain"]["unitaryEmissionsHighSpeedTrain"],
                                    state["initial"]["plane"]["totalHours"],
                                    state[action.stage]["plane"]["rateOfHighSpeedTrain"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], cf: cf / 1000 } }
                                }
                                break;
                            case "averageTrain":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsAverageTrain: (props.master.transportFactorMaster["Average Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = targetImpactTrain(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state["initial"]["averageTrain"]["totalHours"],
                                    state[action.stage]["averageTrain"]["rateOfAverageTrain"],
                                    state[action.stage]["averageTrain"]["rateOfRemote"],
                                    state[action.stage]["averageTrain"]["unitaryEmissionsAverageTrain"],
                                );
                                break;
                            case "taxi":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsTaxi: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsElectricTaxi: (props.master.transportFactorMaster["Electric Taxi"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["publicTransport"]: { ...state[action.stage]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                }
                                cf = targetImpactPublicTransport(
                                    state[action.stage]["publicTransport"]["rateOfPublicTransport"],
                                    state[action.stage]["publicTransport"]["rateOfSoftTransport"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                    state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                    state["initial"]["taxi"]["totalHours"],
                                    state[action.stage]["taxi"]["rateOfPublicTransport"],
                                    state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                                    state["initial"]["car"]["avgJourneyHoursPerDay"],
                                    state["initial"]["car"]["rateOfDailyJournies"],
                                    state["target"]["car"]["rateOfPublicTransport"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["publicTransport"]: { ...state[action.stage]["publicTransport"], cf: cf / 1000 } }
                                }
                                cf = targetImpactTaxi(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state["initial"]["taxi"]["totalHours"],
                                    state[action.stage]["taxi"]["rateOfTaxi"],
                                    state[action.stage]["taxi"]["rateOfElectricTaxi"],
                                    state[action.stage]["taxi"]["rateOfPublicTransport"],
                                    state[action.stage]["taxi"]["rateOfRemote"],
                                    state[action.stage]["taxi"]["unitaryEmissionsTaxi"],
                                    state[action.stage]["taxi"]["unitaryEmissionsElectricTaxi"],
                                    state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                                );
                                break;
                            case "car":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsPrivateCar: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["taxi"]: { ...state[action.stage]["taxi"], unitaryEmissionsTaxi: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsElectricTaxi: (props.master.transportFactorMaster["Electric Taxi"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], ["publicTransport"]: { ...state[action.stage]["publicTransport"], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                }
                                cf = targetImpactPublicTransport(
                                    state[action.stage]["publicTransport"]["rateOfPublicTransport"],
                                    state[action.stage]["publicTransport"]["rateOfSoftTransport"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                    state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                    state["initial"]["taxi"]["totalHours"],
                                    state[action.stage]["taxi"]["rateOfPublicTransport"],
                                    state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                                    state["initial"]["car"]["avgJourneyHoursPerDay"],
                                    state["initial"]["car"]["rateOfDailyJournies"],
                                    state["target"]["car"]["rateOfPublicTransport"]
                                );
                                cf = targetImpactCar(
                                    state[action.stage]["car"]["rateOfPrivateCar"],
                                    state[action.stage]["car"]["rateOfPublicTransport"],
                                    state[action.stage]["car"]["rateOfSoftTransport"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state["initial"]["car"]["rateOfDailyJournies"],
                                    state["initial"]["car"]["avgJourneyHoursPerDay"],
                                    state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                    state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["car"]["unitaryEmissionsPrivateCar"],
                                    state[action.stage]["car"]["unitaryEmissionsPublicTransport"],
                                    state[action.stage]["car"]["unitaryEmissionsSoftTransport"],
                                );
                                break;
                            case "publicTransport":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsSoftTransport: 0 } }
                                }
                                cf = targetImpactPublicTransport(
                                    state[action.stage]["publicTransport"]["rateOfPublicTransport"],
                                    state[action.stage]["publicTransport"]["rateOfSoftTransport"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state["initial"]["publicTransport"]["rateOfDailyJournies"],
                                    state["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsPublicTransport"],
                                    state[action.stage]["publicTransport"]["unitaryEmissionsSoftTransport"],
                                    state["initial"]["taxi"]["totalHours"],
                                    state[action.stage]["taxi"]["rateOfPublicTransport"],
                                    state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                                    state["initial"]["car"]["avgJourneyHoursPerDay"],
                                    state["initial"]["car"]["rateOfDailyJournies"],
                                    state["target"]["car"]["rateOfPublicTransport"]
                                );
                                break;
                            case "digital":
                                state = {
                                    ...state,
                                    [action.stage]: {
                                        ...state[action.stage],
                                        [action.param]: {
                                            ...state[action.stage][action.param],
                                            mbOfEmailSentPerDayPerPerson: props.master.digitalFactorMaster["Mb sent (per day per person)"]["numberOfMB"],
                                            emissionsPerMbSent: props.master.digitalFactorMaster["Mb sent (per day per person)"]["unitaryEmission"],
                                            mbOfStokedSharepoint: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["numberOfMB"],
                                            mbOfStokedSentEmail: props.master.digitalFactorMaster["Mb stocked (sent emails)"]["numberOfMB"],
                                            mbOfStokedReceivedEmail: props.master.digitalFactorMaster["Mb stocked (mail received)"]["numberOfMB"],
                                            emissionsMbStockedPerYear: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["unitaryEmission"],
                                            avgLifeTimeLength: 3,
                                            rateOfDataAvoidedInEmailSentPerDayPerPerson: 0,
                                            rateOfDataAvoidedInStokedSharepoint: 50,
                                            rateOfDataAvoidedInStokedSentEmail: 50,
                                            rateOfDataAvoidedInStokedReceivedEmail: 50,
                                        }
                                    }
                                }
                                cf = targetImpactDigital(
                                    state[action.stage]["digital"]["mbOfEmailSentPerDayPerPerson"],
                                    state[action.stage]["digital"]["emissionsPerMbSent"],
                                    state[action.stage]["digital"]["mbOfStokedSharepoint"],
                                    state[action.stage]["digital"]["mbOfStokedSentEmail"],
                                    state[action.stage]["digital"]["mbOfStokedReceivedEmail"],
                                    state[action.stage]["digital"]["emissionsMbStockedPerYear"],
                                    state[action.stage]["digital"]["avgLifeTimeLength"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["digital"]["commit"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"]
                                );
                                break;
                            case "food":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsInNormalFood: props.master.foodFactorMaster["Meat"]["unitaryEmission"], unitaryEmissionsInVegFood: props.master.foodFactorMaster["Vegetarian"]["unitaryEmission"] } }
                                }
                                cf = targetImpactFood(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["food"]["unitaryEmissionsInNormalFood"],
                                    state[action.stage]["food"]["unitaryEmissionsInVegFood"],
                                    state[action.stage]["food"]["committedNoOfMealsPerWeek"],
                                );
                                break;
                            case "waste":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], initialUnitaryEmissions: props.master.wasteFactorMaster["Waste No Effort"]["unitaryEmission"], targetUnitaryEmissions: props.master.wasteFactorMaster["Waste Effort"]["unitaryEmission"] } }
                                }
                                cf = targetImpactWaste(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["waste"]["initialUnitaryEmissions"],
                                    state[action.stage]["waste"]["targetUnitaryEmissions"],
                                    state[action.stage]["waste"]["commit"],
                                );
                                break;
                            case "hotel":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.hotelFactorMaster[inputName]["unitaryEmission"] } }
                                }
                                cf = targetImpactHotelNights(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["hotel"]["totalNights"], state[action.stage]["hotel"]["unitaryEmissions"]);
                                break;
                            default:
                                break;
                        }
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], cf: cf / 1000 } }
                        }
                    }
                } else if (action.stage === "actual") {
                    let cf = 0;
                    if (action.param === "ftpCount" || action.param === "projectLength") {

                        //wfh
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["office"]: { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) }, ["wfh"]: { ...state[action.stage]["wfh"], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = actualImpactWFH(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );

                        //office
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["office"]: { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) } }
                        }
                        cf = actualImpactOffice(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );

                        // plane
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["plane"]: { ...state[action.stage]["plane"], unitaryEmissions: props.master.transportFactorMaster["Plane"]["unitaryEmission"] } }
                        }
                        cf = initialImpactPlane(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["plane"]["totalHours"], state[action.stage]["plane"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "plane": { ...state[action.stage]["plane"], cf: cf / 1000 } }
                        }

                        // taxi
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["taxi"]: { ...state[action.stage]["taxi"], unitaryEmissionsTaxi: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsElectricTaxi: (props.master.transportFactorMaster["Electric Taxi"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = actualImpactTaxi(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["taxi"]["totalHoursTaxi"],
                            state[action.stage]["taxi"]["totalHoursElectricTaxi"],
                            state[action.stage]["taxi"]["totalHoursPublicTransport"],
                            state[action.stage]["taxi"]["unitaryEmissionsTaxi"],
                            state[action.stage]["taxi"]["unitaryEmissionsElectricTaxi"],
                            state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "taxi": { ...state[action.stage]["taxi"], cf: cf / 1000 } }
                        }
                        //car
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["car"]: { ...state[action.stage]["car"], unitaryEmissions: props.master.transportFactorMaster["Taxi"]["unitaryEmission"] } }
                        }
                        cf = actualImpactCar(
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["car"]["rateOfDailyJournies"],
                            state[action.stage]["car"]["avgJourneyHoursPerDay"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["car"]["unitaryEmissions"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "car": { ...state[action.stage]["car"], cf: cf / 1000 } }
                        }
                        //publicTransport
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["publicTransport"]: { ...state[action.stage]["publicTransport"], unitaryEmissions: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = actualImpactPublicTransport(
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["publicTransport"]["rateOfDailyJournies"],
                            state[action.stage]["publicTransport"]["avgJourneyHoursPerDay"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["publicTransport"]["unitaryEmissions"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "publicTransport": { ...state[action.stage]["publicTransport"], cf: cf / 1000 } }
                        }
                        //digital
                        state = {
                            ...state,
                            [action.stage]: {
                                ...state[action.stage],
                                ["digital"]: {
                                    ...state[action.stage]["digital"],
                                    mbOfEmailSentPerDayPerPerson: props.master.digitalFactorMaster["Mb sent (per day per person)"]["numberOfMB"],
                                    emissionsPerMbSent: props.master.digitalFactorMaster["Mb sent (per day per person)"]["unitaryEmission"],
                                    mbOfStokedSharepoint: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["numberOfMB"],
                                    mbOfStokedSentEmail: props.master.digitalFactorMaster["Mb stocked (sent emails)"]["numberOfMB"],
                                    mbOfStokedReceivedEmail: props.master.digitalFactorMaster["Mb stocked (mail received)"]["numberOfMB"],
                                    emissionsMbStockedPerYear: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["unitaryEmission"],
                                    avgLifeTimeLength: 3,
                                    rateOfDataAvoidedInEmailSentPerDayPerPerson: 0,
                                    rateOfDataAvoidedInStokedSharepoint: 50,
                                    rateOfDataAvoidedInStokedSentEmail: 50,
                                    rateOfDataAvoidedInStokedReceivedEmail: 50,
                                }
                            }
                        }
                        cf = targetImpactDigital(
                            state[action.stage]["digital"]["mbOfEmailSentPerDayPerPerson"],
                            state[action.stage]["digital"]["emissionsPerMbSent"],
                            state[action.stage]["digital"]["mbOfStokedSharepoint"],
                            state[action.stage]["digital"]["mbOfStokedSentEmail"],
                            state[action.stage]["digital"]["mbOfStokedReceivedEmail"],
                            state[action.stage]["digital"]["emissionsMbStockedPerYear"],
                            state[action.stage]["digital"]["avgLifeTimeLength"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["digital"]["commit"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                            state[action.stage]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "digital": { ...state[action.stage]["digital"], cf: cf / 1000 } }
                        }
                        //food
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["food"]: { ...state[action.stage]["food"], unitaryEmissionsInNormalFood: props.master.foodFactorMaster["Meat"]["unitaryEmission"], unitaryEmissionsInVegFood: props.master.foodFactorMaster["Vegetarian"]["unitaryEmission"] } }
                        }
                        cf = targetImpactFood(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["food"]["unitaryEmissionsInNormalFood"],
                            state[action.stage]["food"]["unitaryEmissionsInVegFood"],
                            state[action.stage]["food"]["committedNoOfMealsPerWeek"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "food": { ...state[action.stage]["food"], cf: cf / 1000 } }
                        }
                        // hotel
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["hotel"]: { ...state[action.stage]["hotel"], unitaryEmissions: props.master.hotelFactorMaster[inputName]["unitaryEmission"] } }
                        }
                        cf = targetImpactHotelNights(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["hotel"]["totalNights"], state[action.stage]["hotel"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["hotel"]: { ...state[action.stage]["hotel"], cf: cf / 1000 } }
                        }
                        // high speed train 
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["highSpeedTrain"]: { ...state[action.stage]["highSpeedTrain"], unitaryEmissions: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["highSpeedTrain"]["totalHours"], state[action.stage]["highSpeedTrain"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "highSpeedTrain": { ...state[action.stage]["highSpeedTrain"], cf: cf / 1000 } }
                        }
                        // average train
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["averageTrain"]: { ...state[action.stage]["averageTrain"], unitaryEmissions: (props.master.transportFactorMaster["Average Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                        }
                        cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["averageTrain"]["totalHours"], state[action.stage]["averageTrain"]["unitaryEmissions"]);
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "averageTrain": { ...state[action.stage]["averageTrain"], cf: cf / 1000 } }
                        }
                        //waste
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["waste"]: { ...state[action.stage]["waste"], initialUnitaryEmissions: props.master.wasteFactorMaster["Waste No Effort"]["unitaryEmission"], targetUnitaryEmissions: props.master.wasteFactorMaster["Waste Effort"]["unitaryEmission"] } }
                        }
                        cf = targetImpactWaste(
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"],
                            state[action.stage]["waste"]["initialUnitaryEmissions"],
                            state[action.stage]["waste"]["targetUnitaryEmissions"],
                            state[action.stage]["waste"]["commit"],
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "waste": { ...state[action.stage]["waste"], cf: cf / 1000 } }
                        }
                        //wfh
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["wfh"]: { ...state[action.stage]["wfh"], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = actualImpactWFH(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["rateOfRemote"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "wfh": { ...state[action.stage]["wfh"], cf: cf / 1000 } }
                        }

                        //office
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], ["office"]: { ...state[action.stage]["office"], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365), numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                        }
                        cf = actualImpactOffice(
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                            state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                            state[action.stage]["office"]["rateOfClient"],
                            state[action.stage]["ftpCount"],
                            state[action.stage]["projectLength"]
                        );
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                        }
                    } else {
                        switch (action.param) {

                            case "wfh":

                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365), numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = actualImpactOffice(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["rateOfClient"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "office": { ...state[action.stage]["office"], cf: cf / 1000 } }
                                }

                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = actualImpactWFH(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );

                                break;

                            case "office":

                                // wfh
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtRemote: (props.master.peopleFactorMaster[inputName]["wfh"] / 235) } }
                                }
                                cf = actualImpactWFH(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], "wfh": { ...state[action.stage]["wfh"], cf: cf / 1000 } }
                                }

                                // office
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], numberKWHPerDayPerFTPAtOffice: (props.master.peopleFactorMaster[inputName]["office"] / 365) } }
                                }
                                cf = actualImpactOffice(
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtOffice"],
                                    state[action.stage]["office"]["numberKWHPerDayPerFTPAtRemote"],
                                    state[action.stage]["office"]["rateOfClient"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"]
                                );

                                break;
                            case "plane":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.transportFactorMaster["Plane"]["unitaryEmission"] } }
                                }
                                cf = initialImpactPlane(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["plane"]["totalHours"], state[action.stage]["plane"]["unitaryEmissions"]);
                                break;
                            case "highSpeedTrain":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: (props.master.transportFactorMaster["High Speed Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["highSpeedTrain"]["totalHours"], state[action.stage]["highSpeedTrain"]["unitaryEmissions"]);
                                break;
                            case "averageTrain":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: (props.master.transportFactorMaster["Average Train"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = initialImpactTrain(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["averageTrain"]["totalHours"], state[action.stage]["averageTrain"]["unitaryEmissions"]);
                                break;
                            case "taxi":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsTaxi: props.master.transportFactorMaster["Taxi"]["unitaryEmission"], unitaryEmissionsElectricTaxi: (props.master.transportFactorMaster["Electric Taxi"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])), unitaryEmissionsPublicTransport: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = actualImpactTaxi(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["taxi"]["totalHoursTaxi"],
                                    state[action.stage]["taxi"]["totalHoursElectricTaxi"],
                                    state[action.stage]["taxi"]["totalHoursPublicTransport"],
                                    state[action.stage]["taxi"]["unitaryEmissionsTaxi"],
                                    state[action.stage]["taxi"]["unitaryEmissionsElectricTaxi"],
                                    state[action.stage]["taxi"]["unitaryEmissionsPublicTransport"],
                                );
                                break;
                            case "car":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.transportFactorMaster["Taxi"]["unitaryEmission"] } }
                                }
                                cf = actualImpactCar(
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["car"]["rateOfDailyJournies"],
                                    state[action.stage]["car"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["car"]["unitaryEmissions"]
                                );
                                break;

                            case "publicTransport":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: (props.master.transportFactorMaster["Public Transport"]["unitaryEmission"] / props.master.electricityFactorMaster["France"]["generation"] * (props.master.electricityFactorMaster[inputName]["generation"] + props.master.electricityFactorMaster[inputName]["tnd"])) } }
                                }
                                cf = actualImpactPublicTransport(
                                    state[action.stage]["office"]["rateOfRemote"],
                                    state[action.stage]["publicTransport"]["rateOfDailyJournies"],
                                    state[action.stage]["publicTransport"]["avgJourneyHoursPerDay"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["publicTransport"]["unitaryEmissions"]
                                );
                                break;
                            case "digital":
                                state = {
                                    ...state,
                                    [action.stage]: {
                                        ...state[action.stage],
                                        [action.param]: {
                                            ...state[action.stage][action.param],
                                            mbOfEmailSentPerDayPerPerson: props.master.digitalFactorMaster["Mb sent (per day per person)"]["numberOfMB"],
                                            emissionsPerMbSent: props.master.digitalFactorMaster["Mb sent (per day per person)"]["unitaryEmission"],
                                            mbOfStokedSharepoint: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["numberOfMB"],
                                            mbOfStokedSentEmail: props.master.digitalFactorMaster["Mb stocked (sent emails)"]["numberOfMB"],
                                            mbOfStokedReceivedEmail: props.master.digitalFactorMaster["Mb stocked (mail received)"]["numberOfMB"],
                                            emissionsMbStockedPerYear: props.master.digitalFactorMaster["Mb stocked (total sharepoint)"]["unitaryEmission"],
                                            avgLifeTimeLength: 3,
                                            rateOfDataAvoidedInEmailSentPerDayPerPerson: 0,
                                            rateOfDataAvoidedInStokedSharepoint: 50,
                                            rateOfDataAvoidedInStokedSentEmail: 50,
                                            rateOfDataAvoidedInStokedReceivedEmail: 50,
                                        }
                                    }
                                }
                                cf = targetImpactDigital(
                                    state[action.stage]["digital"]["mbOfEmailSentPerDayPerPerson"],
                                    state[action.stage]["digital"]["emissionsPerMbSent"],
                                    state[action.stage]["digital"]["mbOfStokedSharepoint"],
                                    state[action.stage]["digital"]["mbOfStokedSentEmail"],
                                    state[action.stage]["digital"]["mbOfStokedReceivedEmail"],
                                    state[action.stage]["digital"]["emissionsMbStockedPerYear"],
                                    state[action.stage]["digital"]["avgLifeTimeLength"],
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["digital"]["commit"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                                    state[action.stage]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"]
                                );
                                break;
                            case "food":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissionsInNormalFood: props.master.foodFactorMaster["Meat"]["unitaryEmission"], unitaryEmissionsInVegFood: props.master.foodFactorMaster["Vegetarian"]["unitaryEmission"] } }
                                }
                                cf = targetImpactFood(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["food"]["unitaryEmissionsInNormalFood"],
                                    state[action.stage]["food"]["unitaryEmissionsInVegFood"],
                                    state[action.stage]["food"]["committedNoOfMealsPerWeek"],
                                );
                                break;
                            case "waste":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], initialUnitaryEmissions: props.master.wasteFactorMaster["Waste No Effort"]["unitaryEmission"], targetUnitaryEmissions: props.master.wasteFactorMaster["Waste Effort"]["unitaryEmission"] } }
                                }
                                cf = targetImpactWaste(
                                    state[action.stage]["ftpCount"],
                                    state[action.stage]["projectLength"],
                                    state[action.stage]["waste"]["initialUnitaryEmissions"],
                                    state[action.stage]["waste"]["targetUnitaryEmissions"],
                                    state[action.stage]["waste"]["commit"],
                                );
                                break;
                            case "hotel":
                                state = {
                                    ...state,
                                    [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], unitaryEmissions: props.master.hotelFactorMaster[inputName]["unitaryEmission"] } }
                                }
                                cf = targetImpactHotelNights(state[action.stage]["ftpCount"], state[action.stage]["projectLength"], state[action.stage]["hotel"]["totalNights"], state[action.stage]["hotel"]["unitaryEmissions"]);
                                break;
                            default:
                                break;
                        }
                        state = {
                            ...state,
                            [action.stage]: { ...state[action.stage], [action.param]: { ...state[action.stage][action.param], cf: cf / 1000 } }
                        }
                    }
                }
                break;
            default:
                break;
        }
        return state;
    }

    const [reducerData, dispatchToReducer] = useReducer(reducer, initialReducerData);
    const [initialCharacterCount, setInitialCharacterCount] = useState(0);
    const [targetCharacterCount, setTargetCharacterCount] = useState(0);
    const [actualCharacterCount, setActualCharacterCount] = useState(0);

    const initialCharacterChange = (chr) => {
        setInitialCharacterCount((chr !== undefined && chr !== "") ? chr : 0);
    }

    useEffect(() => {
        if (props.data !== undefined && props.data.initialAssumptions !== undefined && props.data.initialAssumptions.length !== undefined && props.data.initialAssumptions.length !== "" && props.data.initialAssumptions.length !== null && props.data.initialAssumptions.length > 0) {
            setInitialCharacterCount(props.data.initialAssumptions.length)
        }
    }, []);

    const targetCharacterChange = (chr) => {
        setTargetCharacterCount((chr !== undefined && chr !== "") ? chr : 0);
    }

    useEffect(() => {
        if (props.data !== undefined && props.data.targetAssumptions !== undefined && props.data.targetAssumptions.length !== undefined && props.data.targetAssumptions.length !== "" && props.data.targetAssumptions.length !== null && props.data.targetAssumptions.length > 0) {
            setTargetCharacterCount(props.data.targetAssumptions.length)
        }
    }, []);

    const actualCharacterChange = (chr) => {
        setActualCharacterCount((chr !== undefined && chr !== "") ? chr : 0);
    }

    useEffect(() => {
        if (props.data !== undefined && props.data.actualAssumptions !== undefined && props.data.actualAssumptions.length !== undefined && props.data.actualAssumptions.length !== "" && props.data.actualAssumptions.length !== null && props.data.actualAssumptions.length > 0) {
            setActualCharacterCount(props.data.actualAssumptions.length)
        }
    }, []);

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const getFoodOptions = (inputVal, callback) => {
        callback(foodOptions);
    }

    const totalCF = (stage) => {
        return reducerData[stage]["office"]["cf"] +
            reducerData[stage]["wfh"]["cf"] +
            reducerData[stage]["plane"]["cf"] +
            reducerData[stage]["highSpeedTrain"]["cf"] +
            reducerData[stage]["averageTrain"]["cf"] +
            reducerData[stage]["taxi"]["cf"] +
            reducerData[stage]["car"]["cf"] +
            reducerData[stage]["publicTransport"]["cf"] +
            reducerData[stage]["digital"]["cf"] +
            reducerData[stage]["food"]["cf"] +
            reducerData[stage]["waste"]["cf"] +
            reducerData[stage]["hotel"]["cf"]
    }

    const inputInitialTextChange = (e) => {
        let stage = e.target.name.split('-')[e.target.name.split('-').length - 3];
        let param = e.target.name.split('-')[e.target.name.split('-').length - 2];
        let elem = e.target.name.split('-')[e.target.name.split('-').length - 1];

        dispatchToReducer({ type: `SET_NEW_DELIVERY_ASSUMPTION_VALUE`, stage: stage, param: param, elem: elem, payload: (e.target.value !== "" && e.target.value !== undefined) ? e.target.value : "" })
        setValue(`${id}-${inputName}-initial-assumption-data`, (e.target.value !== "" && e.target.value !== undefined) ? e.target.value : "", { shouldValidate: true });
    }

    const inputTargetTextChange = (e) => {
        let stage = e.target.name.split('-')[e.target.name.split('-').length - 3];
        let param = e.target.name.split('-')[e.target.name.split('-').length - 2];
        let elem = e.target.name.split('-')[e.target.name.split('-').length - 1];

        dispatchToReducer({ type: `SET_NEW_DELIVERY_ASSUMPTION_VALUE`, stage: stage, param: param, elem: elem, payload: (e.target.value !== "" && e.target.value !== undefined) ? e.target.value : "" })
        setValue(`${id}-${inputName}-target-assumption-data`, (e.target.value !== "" && e.target.value !== undefined) ? e.target.value : "", { shouldValidate: true });
    }

    const inputActualTextChange = (e) => {
        let stage = e.target.name.split('-')[e.target.name.split('-').length - 3];
        let param = e.target.name.split('-')[e.target.name.split('-').length - 2];
        let elem = e.target.name.split('-')[e.target.name.split('-').length - 1];

        dispatchToReducer({ type: `SET_NEW_DELIVERY_ASSUMPTION_VALUE`, stage: stage, param: param, elem: elem, payload: (e.target.value !== "" && e.target.value !== undefined) ? e.target.value : "" })
        setValue(`${id}-${inputName}-actual-assumption-data`, (e.target.value !== "" && e.target.value !== undefined) ? e.target.value : "", { shouldValidate: true });
    }

    const inputChange = (e) => {
        let stage = e.target.name.split('-')[e.target.name.split('-').length - 3];
        let param = e.target.name.split('-')[e.target.name.split('-').length - 2];
        let elem = e.target.name.split('-')[e.target.name.split('-').length - 1];

        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: elem, payload: (e.target.value !== "" && e.target.value !== "0" && parseInt(e.target.value) >= 0) ? parseFloat(e.target.value) : 0 })
        setTimeout(() => {
            if (param === "ftpCount" || param === "projectLength") {
                // Checking if visited
                if (reducerData["target"]["visited"]) {
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "target", param: param, payload: (e.target.value !== "" && e.target.value !== "0" && parseInt(e.target.value) >= 0) ? parseFloat(e.target.value) : 0 });
                    setValue(`${id}-${inputName}-target-${param}-value`, (e.target.value !== "" && e.target.value !== "0" && parseInt(e.target.value) >= 0) ? parseFloat(e.target.value) : 0, { shouldValidate: true });
                }
                if (reducerData["actual"]["visited"]) {
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "actual", param: param, payload: (e.target.value !== "" && e.target.value !== "0" && parseInt(e.target.value) >= 0) ? parseFloat(e.target.value) : 0 });
                    setValue(`${id}-${inputName}-actual-${param}-value`, (e.target.value !== "" && e.target.value !== "0" && parseInt(e.target.value) >= 0) ? parseFloat(e.target.value) : 0, { shouldValidate: true });
                }
            } else if (param === "office" && elem === "rateOfClient") {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - parseFloat(e.target.value)) });
                } else {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, "", { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: 0 });
                }
            } else if (param === "office" && elem === "rateOfRemote") {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfClient`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfClient", payload: (100 - parseFloat(e.target.value)) });
                } else {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfClient`, "", { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfClient", payload: 0 });
                }
            } else if (stage === "initial" && (param === "plane" || param === "highSpeedTrain" || param === "averageTrain") && reducerData["target"]["visited"] === true) {
                if (param === "plane") {
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfPlane`, reducerData["target"][param]["rateOfPlane"] === 0 ? "" : reducerData["target"][param]["rateOfPlane"], { shouldValidate: true });
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfHighSpeedTrain`, reducerData["target"][param]["rateOfHighSpeedTrain"] === 0 ? "" : reducerData["target"][param]["rateOfHighSpeedTrain"], { shouldValidate: true });
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfRemote`, reducerData["target"][param]["rateOfRemote"] === 0 ? "" : reducerData["target"][param]["rateOfRemote"], { shouldValidate: true });
                } else if (param === "highSpeedTrain") {
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfHighSpeedTrain`, reducerData["target"][param]["rateOfHighSpeedTrain"] === 0 ? "" : reducerData["target"][param]["rateOfHighSpeedTrain"], { shouldValidate: true });
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfRemote`, reducerData["target"][param]["rateOfRemote"] === 0 ? "" : reducerData["target"][param]["rateOfRemote"], { shouldValidate: true });
                } else {
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfAverageTrain`, reducerData["target"][param]["rateOfAverageTrain"] === 0 ? "" : reducerData["target"][param]["rateOfAverageTrain"], { shouldValidate: true });
                    setValue(`${id}-${inputName}-${"target"}-${param}-rateOfRemote`, reducerData["target"][param]["rateOfRemote"] === 0 ? "" : reducerData["target"][param]["rateOfRemote"], { shouldValidate: true });
                }
            } else if (stage === "initial" && param === "taxi" && reducerData["target"]["visited"] === true) {
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfTaxi`, reducerData["target"][param]["rateOfTaxi"] === 0 ? "" : reducerData["target"][param]["rateOfTaxi"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfElectricTaxi`, reducerData["target"][param]["rateOfElectricTaxi"] === 0 ? "" : reducerData["target"][param]["rateOfElectricTaxi"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfPublicTransport`, reducerData["target"][param]["rateOfPublicTransport"] === 0 ? "" : reducerData["target"][param]["rateOfPublicTransport"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfRemote`, reducerData["target"][param]["rateOfRemote"] === 0 ? "" : reducerData["target"][param]["rateOfRemote"], { shouldValidate: true });
            } else if (stage !== "target" && param === "car" && elem === "rateOfDailyJournies") {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    setValue(`${id}-${inputName}-${stage}-publicTransport-rateOfDailyJournies`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: "publicTransport", elem: "rateOfDailyJournies", payload: (100 - parseFloat(e.target.value)) });
                } else {
                    setValue(`${id}-${inputName}-${stage}-publicTransport-rateOfDailyJournies`, "", { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: "publicTransport", elem: "rateOfDailyJournies", payload: 0 });
                }
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfPrivateCar`, reducerData["target"][param]["rateOfPrivateCar"] === 0 ? "" : reducerData["target"][param]["rateOfPrivateCar"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfPublicTransport`, reducerData["target"][param]["rateOfPublicTransport"] === 0 ? "" : reducerData["target"][param]["rateOfPublicTransport"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfSoftTransport`, reducerData["target"][param]["rateOfSoftTransport"] === 0 ? "" : reducerData["target"][param]["rateOfSoftTransport"], { shouldValidate: true });
            } else if (stage !== "target" && param === "publicTransport" && elem === "rateOfDailyJournies") {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    setValue(`${id}-${inputName}-${stage}-car-rateOfDailyJournies`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: "car", elem: "rateOfDailyJournies", payload: (100 - parseFloat(e.target.value)) });
                } else {
                    setValue(`${id}-${inputName}-${stage}-car-rateOfDailyJournies`, "", { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: "car", elem: "rateOfDailyJournies", payload: 0 });
                }
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfPublicTransport`, reducerData["target"][param]["rateOfPublicTransport"] === 0 ? "" : reducerData["target"][param]["rateOfPublicTransport"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${"target"}-${param}-rateOfSoftTransport`, reducerData["target"][param]["rateOfSoftTransport"] === 0 ? "" : reducerData["target"][param]["rateOfSoftTransport"], { shouldValidate: true });
            } else if (stage === "target" && (param === "plane" || param === "highSpeedTrain" || param === "averageTrain") && (elem === "rateOfPlane" || elem === "rateOfHighSpeedTrain" || elem === "rateOfAverageTrain")) {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    if (param === "plane") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - ((elem === "rateOfHighSpeedTrain" ? parseFloat(reducerData[stage][param]["rateOfPlane"]) : parseFloat(reducerData[stage][param]["rateOfHighSpeedTrain"])) + parseFloat(e.target.value))), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - ((elem === "rateOfHighSpeedTrain" ? parseFloat(reducerData[stage][param]["rateOfPlane"]) : parseFloat(reducerData[stage][param]["rateOfHighSpeedTrain"])) + parseFloat(e.target.value))) });
                        if (elem !== "rateOfPlane") {
                            setValue(`${id}-${inputName}-${stage}-${param}-rateOfPlane`, reducerData[stage][param]["rateOfPlane"] === 0 ? "" : reducerData[stage][param]["rateOfPlane"], { shouldValidate: true });
                        }
                        if (elem !== "rateOfHighSpeedTrain") {
                            setValue(`${id}-${inputName}-${stage}-${param}-rateOfHighSpeedTrain`, reducerData[stage][param]["rateOfHighSpeedTrain"] === 0 ? "" : reducerData[stage][param]["rateOfHighSpeedTrain"], { shouldValidate: true });
                        }
                    } else if (param === "highSpeedTrain") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - parseFloat(e.target.value)) });
                    } else {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - parseFloat(e.target.value)) });
                    }
                } else {
                    if (param === "plane") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - ((elem === "rateOfPlane" ? 0 : reducerData[stage][param]["rateOfPlane"]) + (elem === "rateOfHighSpeedTrain" ? 0 : reducerData[stage][param]["rateOfHighSpeedTrain"]))) === 0 ? "" : (100 - ((elem === "rateOfPlane" ? 0 : reducerData[stage][param]["rateOfPlane"]) + (elem === "rateOfHighSpeedTrain" ? 0 : reducerData[stage][param]["rateOfHighSpeedTrain"]))), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - ((elem === "rateOfPlane" ? 0 : reducerData[stage][param]["rateOfPlane"]) + (elem === "rateOfHighSpeedTrain" ? 0 : reducerData[stage][param]["rateOfHighSpeedTrain"]))) });
                    } else if (param === "highSpeedTrain") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - reducerData[stage][param]["rateOfHighSpeedTrain"]) === 0 ? "" : (100 - reducerData[stage][param]["rateOfHighSpeedTrain"]), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - reducerData[stage][param]["rateOfHighSpeedTrain"]) });
                    } else {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - reducerData[stage][param]["rateOfAverageTrain"]) === 0 ? "" : (100 - reducerData[stage][param]["rateOfAverageTrain"]), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - reducerData[stage][param]["rateOfAverageTrain"]) });
                    }
                }
            } else if (stage === "target" && (param === "plane" || param === "highSpeedTrain" || param === "averageTrain") && elem === "rateOfRemote") {
                if (param === "plane") {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfPlane`, (100 - reducerData[stage][param]["rateOfHighSpeedTrain"] - parseFloat(e.target.value)), reducerData[stage][param]["rateOfPlane"] === 0 ? "" : reducerData[stage][param]["rateOfPlane"], { shouldValidate: true });
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfHighSpeedTrain`, reducerData[stage][param]["rateOfHighSpeedTrain"] === 0 ? "" : reducerData[stage][param]["rateOfHighSpeedTrain"], { shouldValidate: true });
                } else if (param === "highSpeedTrain") {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfHighSpeedTrain`, (100 - parseFloat(e.target.value)), reducerData[stage][param]["rateOfHighSpeedTrain"] === 0 ? "" : reducerData[stage][param]["rateOfHighSpeedTrain"], { shouldValidate: true });
                } else {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfAverageTrain`, (100 - parseFloat(e.target.value)), reducerData[stage][param]["rateOfAverageTrain"] === 0 ? "" : reducerData[stage][param]["rateOfAverageTrain"], { shouldValidate: true });
                }
            } else if (stage === "target" && param === "taxi" && elem !== "rateOfRemote") {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    setValue(
                        `${id}-${inputName}-${stage}-${param}-rateOfRemote`,
                        (100 -
                            (
                                (
                                    elem === "rateOfTaxi"
                                        ? (parseFloat(reducerData[stage][param]["rateOfElectricTaxi"] + reducerData[stage][param]["rateOfPublicTransport"]))
                                        : elem === "rateOfElectricTaxi"
                                            ? (parseFloat(reducerData[stage][param]["rateOfTaxi"] + reducerData[stage][param]["rateOfPublicTransport"]))
                                            : (parseFloat(reducerData[stage][param]["rateOfTaxi"] + reducerData[stage][param]["rateOfElectricTaxi"]))
                                ) +
                                parseFloat(e.target.value)
                            )
                        ),
                        { shouldValidate: true }
                    );
                    dispatchToReducer({
                        type: `SET_NEW_DELIVERY_CF_VALUE`,
                        stage: stage,
                        param: param,
                        elem: "rateOfRemote",
                        payload: (100 -
                            (
                                (
                                    elem === "rateOfTaxi"
                                        ? (parseFloat(reducerData[stage][param]["rateOfElectricTaxi"] + reducerData[stage][param]["rateOfPublicTransport"]))
                                        : elem === "rateOfElectricTaxi"
                                            ? (parseFloat(reducerData[stage][param]["rateOfTaxi"] + reducerData[stage][param]["rateOfPublicTransport"]))
                                            : (parseFloat(reducerData[stage][param]["rateOfTaxi"] + reducerData[stage][param]["rateOfElectricTaxi"]))
                                ) +
                                parseFloat(e.target.value)
                            )
                        )
                    });
                    if (elem !== "rateOfTaxi") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfTaxi`, reducerData[stage][param]["rateOfTaxi"] === 0 ? "" : reducerData[stage][param]["rateOfTaxi"], { shouldValidate: true });
                    }
                    if (elem !== "rateOfElectricTaxi") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfElectricTaxi`, reducerData[stage][param]["rateOfElectricTaxi"] === 0 ? "" : reducerData[stage][param]["rateOfElectricTaxi"], { shouldValidate: true });
                    }
                    if (elem !== "rateOfPublicTransport") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfPublicTransport`, reducerData[stage][param]["rateOfPublicTransport"] === 0 ? "" : reducerData[stage][param]["rateOfPublicTransport"], { shouldValidate: true });
                    }
                } else {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfRemote`, (100 - ((elem === "rateOfTaxi" ? 0 : reducerData[stage][param]["rateOfTaxi"]) + (elem === "rateOfElectricTaxi" ? 0 : reducerData[stage][param]["rateOfElectricTaxi"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))) === 0 ? "" : (100 - ((elem === "rateOfTaxi" ? 0 : reducerData[stage][param]["rateOfTaxi"]) + (elem === "rateOfElectricTaxi" ? 0 : reducerData[stage][param]["rateOfElectricTaxi"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))), { shouldValidate: true });
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfRemote", payload: (100 - ((elem === "rateOfTaxi" ? 0 : reducerData[stage][param]["rateOfTaxi"]) + (elem === "rateOfElectricTaxi" ? 0 : reducerData[stage][param]["rateOfElectricTaxi"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))) === 0 ? "" : (100 - ((elem === "rateOfTaxi" ? 0 : reducerData[stage][param]["rateOfTaxi"]) + (elem === "rateOfElectricTaxi" ? 0 : reducerData[stage][param]["rateOfElectricTaxi"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))) });
                }
            } else if (stage === "target" && param === "taxi" && elem === "rateOfRemote") {
                setValue(`${id}-${inputName}-${stage}-${param}-rateOfTaxi`, (100 - reducerData[stage][param]["rateOfElectricTaxi"] - reducerData[stage][param]["rateOfPublicTransport"] - parseFloat(e.target.value)), reducerData[stage][param]["rateOfTaxi"] === 0 ? "" : reducerData[stage][param]["rateOfTaxi"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${stage}-${param}-rateOfElectricTaxi`, reducerData[stage][param]["rateOfElectricTaxi"] === 0 ? "" : reducerData[stage][param]["rateOfElectricTaxi"], { shouldValidate: true });
                setValue(`${id}-${inputName}-${stage}-${param}-rateOfPublicTransport`, reducerData[stage][param]["rateOfPublicTransport"] === 0 ? "" : reducerData[stage][param]["rateOfPublicTransport"], { shouldValidate: true });
            } else if (stage === "target" && (param === "car" || param === "publicTransport") && (elem === "rateOfPrivateCar" || elem === "rateOfPublicTransport")) {
                if (e.target.value !== "" && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
                    if (param === "car") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfSoftTransport`,
                            (100 - ((elem === "rateOfPublicTransport" ? parseFloat(reducerData[stage][param]["rateOfPrivateCar"]) : parseFloat(reducerData[stage][param]["rateOfPublicTransport"])) + parseFloat(e.target.value))),
                            { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfSoftTransport", payload: (100 - ((elem === "rateOfPublicTransport" ? parseFloat(reducerData[stage][param]["rateOfPrivateCar"]) : parseFloat(reducerData[stage][param]["rateOfPublicTransport"])) + parseFloat(e.target.value))) });
                        if (elem !== "rateOfPrivateCar") {
                            setValue(`${id}-${inputName}-${stage}-${param}-rateOfPrivateCar`,
                                reducerData[stage][param]["rateOfPrivateCar"] === 0 ? "" : reducerData[stage][param]["rateOfPrivateCar"],
                                { shouldValidate: true });
                        }
                        if (elem !== "rateOfPublicTransport") {
                            setValue(`${id}-${inputName}-${stage}-${param}-rateOfPublicTransport`, reducerData[stage][param]["rateOfPublicTransport"] === 0 ? "" : reducerData[stage][param]["rateOfPublicTransport"], { shouldValidate: true });
                        }
                    } else {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfSoftTransport`, (100 - parseFloat(e.target.value)), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfSoftTransport", payload: (100 - parseFloat(e.target.value)) });
                    }
                } else {
                    if (param === "car") {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfSoftTransport`, (100 - ((elem === "rateOfPrivateCar" ? 0 : reducerData[stage][param]["rateOfPrivateCar"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))) === 0 ? "" : (100 - ((elem === "rateOfPrivateCar" ? 0 : reducerData[stage][param]["rateOfPrivateCar"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfSoftTransport", payload: (100 - ((elem === "rateOfPrivateCar" ? 0 : reducerData[stage][param]["rateOfPrivateCar"]) + (elem === "rateOfPublicTransport" ? 0 : reducerData[stage][param]["rateOfPublicTransport"]))) });
                    } else {
                        setValue(`${id}-${inputName}-${stage}-${param}-rateOfSoftTransport`, (100 - reducerData[stage][param]["rateOfPublicTransport"]) === 0 ? "" : (100 - reducerData[stage][param]["rateOfPublicTransport"]), { shouldValidate: true });
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: "rateOfSoftTransport", payload: (100 - reducerData[stage][param]["rateOfPublicTransport"]) });
                    }
                }
            } else if (stage === "target" && (param === "car" || param === "publicTransport") && elem === "rateOfSoftTransport") {
                if (param === "car") {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfPrivateCar`,
                        (100 - reducerData[stage][param]["rateOfPublicTransport"] - parseFloat(e.target.value)),
                        { shouldValidate: true });
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfPublicTransport`, reducerData[stage][param]["rateOfPublicTransport"] === 0 ? "" : reducerData[stage][param]["rateOfPublicTransport"], { shouldValidate: true });
                } else {
                    setValue(`${id}-${inputName}-${stage}-${param}-rateOfPublicTransport`, (100 - parseFloat(e.target.value)), reducerData[stage][param]["rateOfPublicTransport"] === 0 ? "" : reducerData[stage][param]["rateOfPublicTransport"], { shouldValidate: true });
                }
            }
        }, 10);
    }

    const onSelectChange = (name, val) => {
        let stage = name.split('-')[name.split('-').length - 3];
        let param = name.split('-')[name.split('-').length - 2];
        let elem = name.split('-')[name.split('-').length - 1];
        dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: stage, param: param, elem: elem, payload: val.value });
    }

    const setCF = () => {
        props.cFootprint({ name: inputName, parent: "deliveryPeople", data: reducerData, cf: reducerData });
    }

    const getErrors = () => {
        let err = [];
        Object.keys(errors).forEach(k => {
            let stage = k.split('-')[k.split('-').length - 3];
            if (!err.includes(stage)) {
                err.push(stage);
            }
        });
        return err;
    }

    const setUnsetTabs = () => {
        if (shouldChangeTab !== "" && shouldChangeTab !== key) {
            let err = getErrors();
            if (shouldChangeTab === "target") {
                if (reducerData["initial"]["visited"] === true && !err.includes("initial")) {
                    dispatchToReducer({ type: `SET_NEW_DELIVERY_VISITED_VALUE`, stage: shouldChangeTab, param: "visited", payload: true });
                    setKey(shouldChangeTab);
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format in '1-Initial Impact' tab!" });
                }
            } else if (shouldChangeTab === "actual") {
                if (reducerData["initial"]["visited"] === true && !err.includes("initial")) {
                    if (reducerData["target"]["visited"] === true && !err.includes("target")) {
                        dispatchToReducer({ type: `SET_NEW_DELIVERY_VISITED_VALUE`, stage: shouldChangeTab, param: "visited", payload: true });
                        setKey(shouldChangeTab);
                    } else {
                        props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format in '2-Target Impact' tab!" });
                    }
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format in '1-Initial Impact' tab!" });
                }
            }
            setShouldChangeTab("");
        } else {
            if (shouldChangeTab !== "") {
                setShouldChangeTab("");
            }
        }
    }

    useEffect(() => {
        if (inputReducerData === undefined || inputReducerData === null) {
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "initial", param: "office", elem: "rateOfClient", payload: 100 });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "initial", param: "digital", elem: "commit", payload: "no" });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "initial", param: "food", elem: "committedNoOfMealsPerWeek", payload: 0 });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "initial", param: "waste", elem: "commit", payload: "no" });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "target", param: "digital", elem: "commit", payload: "no" });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "target", param: "food", elem: "committedNoOfMealsPerWeek", payload: 0 });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "target", param: "waste", elem: "commit", payload: "no" });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "actual", param: "digital", elem: "commit", payload: "no" });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "actual", param: "food", elem: "committedNoOfMealsPerWeek", payload: 0 });
            dispatchToReducer({ type: `SET_NEW_DELIVERY_CF_VALUE`, stage: "actual", param: "waste", elem: "commit", payload: "no" });
        }
    }, []);

    useEffect(() => {
        if (inputReducerData !== undefined && inputReducerData !== null && Object.keys(inputReducerData).length > 0) {
            setCheckTabs(true);
            dispatchToReducer({ type: `SET_NEW_DELIVERY`, payload: inputReducerData });

            //Set all the values to inputs
            //initial
            setValue(`${id}-${inputName}-initial-ftpCount-value`, inputReducerData["initial"]["ftpCount"] !== 0 ? inputReducerData["initial"]["ftpCount"] : "");
            setValue(`${id}-${inputName}-initial-projectLength-value`, inputReducerData["initial"]["projectLength"] !== 0 ? inputReducerData["initial"]["projectLength"] : "");
            setValue(`${id}-${inputName}-initial-office-rateOfClient`, ((inputReducerData["initial"]["office"]["rateOfClient"] + inputReducerData["initial"]["office"]["rateOfRemote"]) === 100) ? inputReducerData["initial"]["office"]["rateOfClient"] : "");
            setValue(`${id}-${inputName}-initial-office-rateOfRemote`, ((inputReducerData["initial"]["office"]["rateOfClient"] + inputReducerData["initial"]["office"]["rateOfRemote"]) === 100) ? inputReducerData["initial"]["office"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-initial-plane-totalHours`, inputReducerData["initial"]["plane"]["totalHours"] !== 0 ? inputReducerData["initial"]["plane"]["totalHours"] : "");
            setValue(`${id}-${inputName}-initial-highSpeedTrain-totalHours`, inputReducerData["initial"]["highSpeedTrain"]["totalHours"] !== 0 ? inputReducerData["initial"]["highSpeedTrain"]["totalHours"] : "");
            setValue(`${id}-${inputName}-initial-averageTrain-totalHours`, inputReducerData["initial"]["averageTrain"]["totalHours"] !== 0 ? inputReducerData["initial"]["averageTrain"]["totalHours"] : "");
            setValue(`${id}-${inputName}-initial-taxi-totalHours`, inputReducerData["initial"]["taxi"]["totalHours"] !== 0 ? inputReducerData["initial"]["taxi"]["totalHours"] : "");
            setValue(`${id}-${inputName}-initial-car-rateOfDailyJournies`, ((inputReducerData["initial"]["car"]["rateOfDailyJournies"] + inputReducerData["initial"]["publicTransport"]["rateOfDailyJournies"]) === 100) ? inputReducerData["initial"]["car"]["rateOfDailyJournies"] : "");
            setValue(`${id}-${inputName}-initial-car-avgJourneyHoursPerDay`, inputReducerData["initial"]["car"]["avgJourneyHoursPerDay"] !== 0 ? inputReducerData["initial"]["car"]["avgJourneyHoursPerDay"] : "");
            setValue(`${id}-${inputName}-initial-publicTransport-rateOfDailyJournies`, ((inputReducerData["initial"]["car"]["rateOfDailyJournies"] + inputReducerData["initial"]["publicTransport"]["rateOfDailyJournies"]) === 100) ? inputReducerData["initial"]["publicTransport"]["rateOfDailyJournies"] : "");
            setValue(`${id}-${inputName}-initial-publicTransport-avgJourneyHoursPerDay`, inputReducerData["initial"]["publicTransport"]["avgJourneyHoursPerDay"] !== 0 ? inputReducerData["initial"]["publicTransport"]["avgJourneyHoursPerDay"] : "");
            setValue(`${id}-${inputName}-initial-digital-commit`, { value: inputReducerData["initial"]["digital"]["commit"], label: inputReducerData["initial"]["digital"]["commit"].toString()[0].toUpperCase() + inputReducerData["initial"]["digital"]["commit"].substring(1) });
            setValue(`${id}-${inputName}-initial-food-committedNoOfMealsPerWeek`, { value: inputReducerData["initial"]["food"]["committedNoOfMealsPerWeek"], label: inputReducerData["initial"]["food"]["committedNoOfMealsPerWeek"].toString() });
            setValue(`${id}-${inputName}-initial-waste-commit`, { value: inputReducerData["initial"]["waste"]["commit"], label: inputReducerData["initial"]["waste"]["commit"].toString()[0].toUpperCase() + inputReducerData["initial"]["waste"]["commit"].substring(1) });
            setValue(`${id}-${inputName}-initial-hotel-totalNights`, inputReducerData["initial"]["hotel"]["totalNights"] !== 0 ? inputReducerData["initial"]["hotel"]["totalNights"] : "");
            setValue(`${id}-${inputName}-initial-assumption-data`, inputReducerData["initial"]["assumption"] !== "" ? inputReducerData["initial"]["assumption"] : "");
            //target
            setValue(`${id}-${inputName}-target-ftpCount-value`, inputReducerData["target"]["ftpCount"] !== 0 ? inputReducerData["target"]["ftpCount"] : "");
            setValue(`${id}-${inputName}-target-projectLength-value`, inputReducerData["target"]["projectLength"] !== 0 ? inputReducerData["target"]["projectLength"] : "");
            setValue(`${id}-${inputName}-target-office-rateOfClient`, ((inputReducerData["target"]["office"]["rateOfClient"] + inputReducerData["target"]["office"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["office"]["rateOfClient"] : "");
            setValue(`${id}-${inputName}-target-office-rateOfRemote`, ((inputReducerData["target"]["office"]["rateOfClient"] + inputReducerData["target"]["office"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["office"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-target-plane-rateOfPlane`, ((inputReducerData["target"]["plane"]["rateOfPlane"] + inputReducerData["target"]["plane"]["rateOfHighSpeedTrain"] + inputReducerData["target"]["plane"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["plane"]["rateOfPlane"] : "");
            setValue(`${id}-${inputName}-target-plane-rateOfHighSpeedTrain`, ((inputReducerData["target"]["plane"]["rateOfPlane"] + inputReducerData["target"]["plane"]["rateOfHighSpeedTrain"] + inputReducerData["target"]["plane"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["plane"]["rateOfHighSpeedTrain"] : "");
            setValue(`${id}-${inputName}-target-plane-rateOfRemote`, ((inputReducerData["target"]["plane"]["rateOfPlane"] + inputReducerData["target"]["plane"]["rateOfHighSpeedTrain"] + inputReducerData["target"]["plane"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["plane"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-target-highSpeedTrain-rateOfHighSpeedTrain`, ((inputReducerData["target"]["highSpeedTrain"]["rateOfHighSpeedTrain"] + inputReducerData["target"]["highSpeedTrain"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["highSpeedTrain"]["rateOfHighSpeedTrain"] : "");
            setValue(`${id}-${inputName}-target-highSpeedTrain-rateOfRemote`, ((inputReducerData["target"]["highSpeedTrain"]["rateOfHighSpeedTrain"] + inputReducerData["target"]["highSpeedTrain"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["highSpeedTrain"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-target-averageTrain-rateOfAverageTrain`, ((inputReducerData["target"]["averageTrain"]["rateOfAverageTrain"] + inputReducerData["target"]["averageTrain"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["averageTrain"]["rateOfAverageTrain"] : "");
            setValue(`${id}-${inputName}-target-averageTrain-rateOfRemote`, ((inputReducerData["target"]["averageTrain"]["rateOfAverageTrain"] + inputReducerData["target"]["averageTrain"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["averageTrain"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-target-taxi-rateOfTaxi`, ((inputReducerData["target"]["taxi"]["rateOfTaxi"] + inputReducerData["target"]["taxi"]["rateOfElectricTaxi"] + inputReducerData["target"]["taxi"]["rateOfPublicTransport"] + inputReducerData["target"]["taxi"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["taxi"]["rateOfTaxi"] : "");
            setValue(`${id}-${inputName}-target-taxi-rateOfElectricTaxi`, ((inputReducerData["target"]["taxi"]["rateOfTaxi"] + inputReducerData["target"]["taxi"]["rateOfElectricTaxi"] + inputReducerData["target"]["taxi"]["rateOfPublicTransport"] + inputReducerData["target"]["taxi"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["taxi"]["rateOfElectricTaxi"] : "");
            setValue(`${id}-${inputName}-target-taxi-rateOfPublicTransport`, ((inputReducerData["target"]["taxi"]["rateOfTaxi"] + inputReducerData["target"]["taxi"]["rateOfElectricTaxi"] + inputReducerData["target"]["taxi"]["rateOfPublicTransport"] + inputReducerData["target"]["taxi"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["taxi"]["rateOfPublicTransport"] : "");
            setValue(`${id}-${inputName}-target-taxi-rateOfRemote`, ((inputReducerData["target"]["taxi"]["rateOfTaxi"] + inputReducerData["target"]["taxi"]["rateOfElectricTaxi"] + inputReducerData["target"]["taxi"]["rateOfPublicTransport"] + inputReducerData["target"]["taxi"]["rateOfRemote"]) === 100) ? inputReducerData["target"]["taxi"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-target-car-rateOfPrivateCar`, ((inputReducerData["target"]["car"]["rateOfPrivateCar"] + inputReducerData["target"]["car"]["rateOfPublicTransport"] + inputReducerData["target"]["car"]["rateOfSoftTransport"]) === 100) ? inputReducerData["target"]["car"]["rateOfPrivateCar"] : "");
            setValue(`${id}-${inputName}-target-car-rateOfPublicTransport`, ((inputReducerData["target"]["car"]["rateOfPrivateCar"] + inputReducerData["target"]["car"]["rateOfPublicTransport"] + inputReducerData["target"]["car"]["rateOfSoftTransport"]) === 100) ? inputReducerData["target"]["car"]["rateOfPublicTransport"] : "");
            setValue(`${id}-${inputName}-target-car-rateOfSoftTransport`, ((inputReducerData["target"]["car"]["rateOfPrivateCar"] + inputReducerData["target"]["car"]["rateOfPublicTransport"] + inputReducerData["target"]["car"]["rateOfSoftTransport"]) === 100) ? inputReducerData["target"]["car"]["rateOfSoftTransport"] : "");
            setValue(`${id}-${inputName}-target-publicTransport-rateOfPublicTransport`, ((inputReducerData["target"]["publicTransport"]["rateOfPublicTransport"] + inputReducerData["target"]["publicTransport"]["rateOfSoftTransport"]) === 100) ? inputReducerData["target"]["publicTransport"]["rateOfPublicTransport"] : "");
            setValue(`${id}-${inputName}-target-publicTransport-rateOfSoftTransport`, ((inputReducerData["target"]["publicTransport"]["rateOfPublicTransport"] + inputReducerData["target"]["publicTransport"]["rateOfSoftTransport"]) === 100) ? inputReducerData["target"]["publicTransport"]["rateOfSoftTransport"] : "");
            setValue(`${id}-${inputName}-target-digital-commit`, { value: inputReducerData["target"]["digital"]["commit"], label: inputReducerData["target"]["digital"]["commit"].toString()[0].toUpperCase() + inputReducerData["target"]["digital"]["commit"].substring(1) });
            setValue(`${id}-${inputName}-target-food-committedNoOfMealsPerWeek`, { value: inputReducerData["target"]["food"]["committedNoOfMealsPerWeek"], label: inputReducerData["target"]["food"]["committedNoOfMealsPerWeek"].toString() });
            setValue(`${id}-${inputName}-target-waste-commit`, { value: inputReducerData["target"]["waste"]["commit"], label: inputReducerData["target"]["waste"]["commit"].toString()[0].toUpperCase() + inputReducerData["target"]["waste"]["commit"].substring(1) });
            setValue(`${id}-${inputName}-target-hotel-totalNights`, inputReducerData["target"]["hotel"]["totalNights"] !== 0 ? inputReducerData["target"]["hotel"]["totalNights"] : "");
            setValue(`${id}-${inputName}-target-assumption-data`, inputReducerData["target"]["assumption"] !== "" ? inputReducerData["target"]["assumption"] : "");
            //actual
            setValue(`${id}-${inputName}-actual-ftpCount-value`, inputReducerData["actual"]["ftpCount"] !== 0 ? inputReducerData["actual"]["ftpCount"] : "");
            setValue(`${id}-${inputName}-actual-projectLength-value`, inputReducerData["actual"]["projectLength"] !== 0 ? inputReducerData["actual"]["projectLength"] : "");
            setValue(`${id}-${inputName}-actual-office-rateOfClient`, ((inputReducerData["actual"]["office"]["rateOfClient"] + inputReducerData["actual"]["office"]["rateOfRemote"]) === 100) ? inputReducerData["actual"]["office"]["rateOfClient"] : "");
            setValue(`${id}-${inputName}-actual-office-rateOfRemote`, ((inputReducerData["actual"]["office"]["rateOfClient"] + inputReducerData["actual"]["office"]["rateOfRemote"]) === 100) ? inputReducerData["actual"]["office"]["rateOfRemote"] : "");
            setValue(`${id}-${inputName}-actual-plane-totalHours`, inputReducerData["actual"]["plane"]["totalHours"] !== 0 ? inputReducerData["actual"]["plane"]["totalHours"] : "");
            setValue(`${id}-${inputName}-actual-highSpeedTrain-totalHours`, inputReducerData["actual"]["highSpeedTrain"]["totalHours"] !== 0 ? inputReducerData["actual"]["highSpeedTrain"]["totalHours"] : "");
            setValue(`${id}-${inputName}-actual-averageTrain-totalHours`, inputReducerData["actual"]["averageTrain"]["totalHours"] !== 0 ? inputReducerData["actual"]["averageTrain"]["totalHours"] : "");
            setValue(`${id}-${inputName}-actual-taxi-totalHoursTaxi`, inputReducerData["actual"]["taxi"]["totalHoursTaxi"] !== 0 ? inputReducerData["actual"]["taxi"]["totalHoursTaxi"] : "");
            setValue(`${id}-${inputName}-actual-taxi-totalHoursElectricTaxi`, inputReducerData["actual"]["taxi"]["totalHoursElectricTaxi"] !== 0 ? inputReducerData["actual"]["taxi"]["totalHoursElectricTaxi"] : "");
            setValue(`${id}-${inputName}-actual-taxi-totalHoursPublicTransport`, inputReducerData["actual"]["taxi"]["totalHoursPublicTransport"] !== 0 ? inputReducerData["actual"]["taxi"]["totalHoursPublicTransport"] : "");
            setValue(`${id}-${inputName}-actual-car-rateOfDailyJournies`, ((inputReducerData["actual"]["car"]["rateOfDailyJournies"] + inputReducerData["actual"]["publicTransport"]["rateOfDailyJournies"]) === 100) ? inputReducerData["actual"]["car"]["rateOfDailyJournies"] : "");
            setValue(`${id}-${inputName}-actual-car-avgJourneyHoursPerDay`, inputReducerData["actual"]["car"]["avgJourneyHoursPerDay"] !== 0 ? inputReducerData["actual"]["car"]["avgJourneyHoursPerDay"] : "");
            setValue(`${id}-${inputName}-actual-publicTransport-rateOfDailyJournies`, ((inputReducerData["actual"]["car"]["rateOfDailyJournies"] + inputReducerData["actual"]["publicTransport"]["rateOfDailyJournies"]) === 100) ? inputReducerData["actual"]["publicTransport"]["rateOfDailyJournies"] : "");
            setValue(`${id}-${inputName}-actual-publicTransport-avgJourneyHoursPerDay`, inputReducerData["actual"]["publicTransport"]["avgJourneyHoursPerDay"] !== 0 ? inputReducerData["actual"]["publicTransport"]["avgJourneyHoursPerDay"] : "");
            setValue(`${id}-${inputName}-actual-digital-commit`, { value: inputReducerData["actual"]["digital"]["commit"], label: inputReducerData["actual"]["digital"]["commit"].toString()[0].toUpperCase() + inputReducerData["actual"]["digital"]["commit"].substring(1) });
            setValue(`${id}-${inputName}-actual-food-committedNoOfMealsPerWeek`, { value: inputReducerData["actual"]["food"]["committedNoOfMealsPerWeek"], label: inputReducerData["actual"]["food"]["committedNoOfMealsPerWeek"].toString() });
            setValue(`${id}-${inputName}-actual-waste-commit`, { value: inputReducerData["actual"]["waste"]["commit"], label: inputReducerData["actual"]["waste"]["commit"].toString()[0].toUpperCase() + inputReducerData["actual"]["waste"]["commit"].substring(1) });
            setValue(`${id}-${inputName}-actual-hotel-totalNights`, inputReducerData["actual"]["hotel"]["totalNights"] !== 0 ? inputReducerData["actual"]["hotel"]["totalNights"] : "");
            setValue(`${id}-${inputName}-actual-assumption-data`, inputReducerData["actual"]["assumption"] !== "" ? inputReducerData["actual"]["assumption"] : "");
        }
    }, [inputReducerData]);

    useEffect(() => {
        if (checkTabs) {
            if (reducerData["actual"]["visited"] === true) {
                setKey("actual");
            } else if (reducerData["target"]["visited"] === true) {
                setKey("target");
            } else if (reducerData["initial"]["visited"] === true) {
                setKey("initial");
            }
            setCheckTabs(false);
            setCF();
        } else {
            setCF();
        }
    }, [reducerData, checkTabs]);

    useEffect(() => {
        if (shouldChangeTab !== "") {
            if (!reducerData[shouldChangeTab]["visited"]) {
                checkError();
            } else {
                setUnsetTabs();
            }
        }
    }, [shouldChangeTab]);

    useEffect(() => {
        setUnsetTabs();
    }, [errors]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    const ColoredLine = ({ color, height, styles }) => (
        <hr
            style={{
                backgroundColor: color,
                height: height,
                ...styles,
                marginLeft: "-20px",
                marginRight: "-10px",
            }}
        />
    );

    const impactTabs = {
        [`initialImpact`]: (
            <Tab eventKey={`initial`} title="1 - Initial Impact" key={`initialImpact`} >
                <Tab.Container>
                    <div style={{ paddingLeft: 20, paddingRight: 10, marginTop: 15, paddingBottom: 25 }}>
                        <div style={{ display: "flex", marginRight: 10, justifyContent: "space-between", marginLeft: 0 }}>
                            <Input className="numberType" type="number" id={`${id}-${inputName}-initial-ftpCount-value`} name={`${id}-${inputName}-initial-ftpCount-value`} label="Number of team members" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="If resource start or end dates are staggered, estimate the number of full time team members for the duration of the project." register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value > 0 ? true : "Only non-negative number greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                            <Input className="numberType" type="number" id={`${id}-${inputName}-initial-projectLength-value`} name={`${id}-${inputName}-initial-projectLength-value`} label="Project length (in days)" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value > 0 ? true : "Only non-negative number greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                        </div>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={4} md={6} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Work Location</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={8} md={6} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["initial"]["office"]["cf"] + reducerData["initial"]["wfh"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>% of time</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Capgemini / Client site energy</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-office-rateOfClient`} name={`${id}-${inputName}-initial-office-rateOfClient`} prefixValue={reducerData["initial"]["office"]["rateOfClient"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Initial calculations assume all work takes place on client site or in a Capgemini office." register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} value={reducerData["initial"]["office"]["rateOfClient"]} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Working from home</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" prefixValue={reducerData["initial"]["office"]["rateOfRemote"]} id={`${id}-${inputName}-initial-office-rateOfRemote`} name={`${id}-${inputName}-initial-office-rateOfRemote`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Initial calculations assume all work takes place on client site or in a Capgemini office." register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} value={reducerData["initial"]["office"]["rateOfRemote"]} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row style={{ marginBottom: 25 }}>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={4} md={6} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Transportation</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={8} md={6} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["initial"]["plane"]["cf"] + reducerData["initial"]["highSpeedTrain"]["cf"] + reducerData["initial"]["averageTrain"]["cf"] + reducerData["initial"]["taxi"]["cf"] + reducerData["initial"]["car"]["cf"] + reducerData["initial"]["publicTransport"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "11px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}><b>Business Travel</b></p>
                                        <span style={{ fontSize: "9px" }}>Long distance journeys, not including daily commutes to and from <br />Capgemini/client offices from home</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px", marginTop: "30px" }}>Total hours throughout the project length</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>Plane</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-plane-totalHours`} name={`${id}-${inputName}-initial-plane-totalHours`} prefixValue={reducerData["initial"]["plane"]["totalHours"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>High Speed Train</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-highSpeedTrain-totalHours`} name={`${id}-${inputName}-initial-highSpeedTrain-totalHours`} prefixValue={reducerData["initial"]["highSpeedTrain"]["totalHours"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Trains that regularly reach speeds of 300km/h or above are considered high speed trains" register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>Average Train</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-averageTrain-totalHours`} name={`${id}-${inputName}-initial-averageTrain-totalHours`} prefixValue={reducerData["initial"]["averageTrain"]["totalHours"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["initial"]["plane"]["cf"] + reducerData["initial"]["highSpeedTrain"]["cf"] + reducerData["initial"]["averageTrain"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted / project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ paddingTop: 30 }}>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Short Distance Journey</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Total hours throughout the project length</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Ride hailing service / Taxi</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-taxi-totalHours`} name={`${id}-${inputName}-initial-taxi-totalHours`} prefixValue={reducerData["initial"]["taxi"]["totalHours"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="On average a car will travel 40km in 1 hour within an urban environment" register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["initial"]["taxi"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <hr style={{ borderTopWidth: "1px", borderTopStyle: "dotted", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>

                                <Row style={{ paddingTop: 5 }}>
                                    <Col lg={12} md={12} sm={12}>
                                        <p style={{ fontSize: "11px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}><b>Daily Commute</b></p>
                                        <span style={{ fontSize: "9px" }}>Daily journeys from home to Capgemini/client offices</span>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>% of individual car</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-car-rateOfDailyJournies`} name={`${id}-${inputName}-initial-car-rateOfDailyJournies`} prefixValue={reducerData["initial"]["car"]["rateOfDailyJournies"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : reducerData["initial"]["publicTransport"]["rateOfDailyJournies"] === 100 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>% of public transport</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["initial"]["publicTransport"]["rateOfDailyJournies"]} type="number" id={`${id}-${inputName}-initial-publicTransport-rateOfDailyJournies`} name={`${id}-${inputName}-initial-publicTransport-rateOfDailyJournies`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : reducerData["initial"]["car"]["rateOfDailyJournies"] === 100 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px", paddingTop: 3 }}>Daily average for 1 FTE</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Individual car</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-car-avgJourneyHoursPerDay`} name={`${id}-${inputName}-initial-car-avgJourneyHoursPerDay`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value >= 0 ? true : "Only non-negative number including 0 and greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Public transport</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} type="number" id={`${id}-${inputName}-initial-publicTransport-avgJourneyHoursPerDay`} name={`${id}-${inputName}-initial-publicTransport-avgJourneyHoursPerDay`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value >= 0 ? true : "Only non-negative number including 0 and greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["initial"]["car"]["cf"] + reducerData["initial"]["publicTransport"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={4} md={6} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Digital</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={8} md={6} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["initial"]["digital"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>The team commits to proceed to a SharePoint and mail box cleaning at the end of the project</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="Initial calculation assume there is no commitment to clean up SharePoint or mailboxes" errors={errors} loadOptions={getUnitOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-initial-digital-commit`}
                                                    defaultValue={unitOptions[1]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={4} md={6} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Other</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={8} md={6} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["initial"]["food"]["cf"] + reducerData["initial"]["waste"]["cf"] + reducerData["initial"]["hotel"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Food <br />(number of days the team commits to eating vegetarian)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="Initial calculation assume all meals contain meat" errors={errors} loadOptions={getFoodOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-initial-food-committedNoOfMealsPerWeek`}
                                                    defaultValue={foodOptions[0]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["initial"]["food"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Waste <br />(The team commits to minimize waste, eg, using resuable water bottle)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="Initial calculation assume there is no commitment to minimize waste" errors={errors} loadOptions={getUnitOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-initial-waste-commit`}
                                                    defaultValue={unitOptions[1]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["initial"]["waste"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Number of days</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Number of hotel night <br />(total for the team over entire project length)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" type="number" id={`${id}-${inputName}-initial-hotel-totalNights`} name={`${id}-${inputName}-initial-hotel-totalNights`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["initial"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["initial"]["hotel"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 20 }}>
                            <Col>
                                <InputTextArea rows={"3"} className="textType" type="text" id={`${id}-${inputName}-initial-assumption-data`} name={`${id}-${inputName}-initial-assumption-data`} label={"Initial Assumption"} placeholder={"Assumptions"} maxLength={1000} register={register({ maxLength: 1000 })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "150%", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "70px", width: "65%" }} onChange={(e) => { inputInitialTextChange(e); initialCharacterChange(e.target.value); setInitialCharacterCount(e.target.value.length) }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span style={{ fontSize: "10px", fontWeight: "normal" }} register={register} >Remaining characters  -  {1000 - initialCharacterCount}</span>
                            </Col>
                        </Row>
                    </div>
                </Tab.Container>
            </Tab >
        ),
        [`targetImpact`]: (
            <Tab eventKey={`target`} title="2 - Target Impact" key={`targetImpact`}>
                <Tab.Container>
                    {/* <p>Target Impact</p> */}
                    <div style={{ paddingLeft: 20, paddingRight: 10, marginTop: 15, paddingBottom: 25 }}>
                        <div style={{ display: "flex", marginRight: 10, justifyContent: "space-between", marginLeft: 0 }}>
                            <Input className="numberType" type="number" id={`${id}-${inputName}-target-ftpCount-value`} name={`${id}-${inputName}-target-ftpCount-value`} label="Number of team members" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="If resource start or end dates are staggered, estimate the number of full time team members for the duration of the project." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : value > 0 ? true : "Only non-negative number greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                            <Input className="numberType" type="number" id={`${id}-${inputName}-target-projectLength-value`} name={`${id}-${inputName}-target-projectLength-value`} label="Project length (in days)" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : value > 0 ? true : "Only non-negative number greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                        </div>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Work Location</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{reducerData["initial"]["office"]["cf"] === 0 ? 0 : parseFloat((reducerData["initial"]["office"]["cf"] - reducerData["target"]["office"]["cf"]) / reducerData["initial"]["office"]["cf"] * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["target"]["office"]["cf"] + reducerData["target"]["wfh"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px", paddingTop: 3 }}>% of time</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0, letterSpacing: 0.1, paddingTop: 3 }}>Capgemini / Client site energy</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={7} type="number" prefixValue={reducerData["target"]["office"]["rateOfClient"]} id={`${id}-${inputName}-target-office-rateOfClient`} name={`${id}-${inputName}-target-office-rateOfClient`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Average for the duration of the project.<br />These calculations are country specific based on a data from Capgemini offices and the local electricity mix." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["target"]["office"]["rateOfRemote"] === 100 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0, letterSpacing: 0.1, paddingTop: 3 }}>Working from home</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={7} type="number" prefixValue={reducerData["target"]["office"]["rateOfRemote"]} id={`${id}-${inputName}-target-office-rateOfRemote`} name={`${id}-${inputName}-target-office-rateOfRemote`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Average for the duration of the project.<br />These calculations are country specific based on a data from Capgemini offices and the local electricity mix." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["target"]["office"]["rateOfClient"] === 100 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row style={{ marginBottom: 25 }}>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Transportation</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{(
                                                reducerData["initial"]["plane"]["cf"] +
                                                reducerData["initial"]["highSpeedTrain"]["cf"] +
                                                reducerData["initial"]["averageTrain"]["cf"] +
                                                reducerData["initial"]["taxi"]["cf"] +
                                                reducerData["initial"]["car"]["cf"] +
                                                reducerData["initial"]["publicTransport"]["cf"]
                                            ) === 0 ? 0
                                                : parseFloat((
                                                    (
                                                        reducerData["initial"]["plane"]["cf"] +
                                                        reducerData["initial"]["highSpeedTrain"]["cf"] +
                                                        reducerData["initial"]["averageTrain"]["cf"] +
                                                        reducerData["initial"]["taxi"]["cf"] +
                                                        reducerData["initial"]["car"]["cf"] +
                                                        reducerData["initial"]["publicTransport"]["cf"]
                                                    ) - (
                                                        reducerData["target"]["plane"]["cf"] +
                                                        reducerData["target"]["highSpeedTrain"]["cf"] +
                                                        reducerData["target"]["averageTrain"]["cf"] +
                                                        reducerData["target"]["taxi"]["cf"] +
                                                        reducerData["target"]["car"]["cf"] +
                                                        reducerData["target"]["publicTransport"]["cf"]
                                                    )
                                                ) / (
                                                        reducerData["initial"]["plane"]["cf"] +
                                                        reducerData["initial"]["highSpeedTrain"]["cf"] +
                                                        reducerData["initial"]["averageTrain"]["cf"] +
                                                        reducerData["initial"]["taxi"]["cf"] +
                                                        reducerData["initial"]["car"]["cf"] +
                                                        reducerData["initial"]["publicTransport"]["cf"]
                                                    ) * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["target"]["plane"]["cf"] + reducerData["target"]["highSpeedTrain"]["cf"] + reducerData["target"]["averageTrain"]["cf"] + reducerData["target"]["taxi"]["cf"] + reducerData["target"]["car"]["cf"] + reducerData["target"]["publicTransport"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "11px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}><b>Business Travel</b></p>
                                        <span style={{ fontSize: "9px" }}>Long distance journeys, not including daily commutes to and from <br />Capgemini/client offices from home</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15, paddingTop: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 30 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>Plane replacement</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={1} prefixValue={reducerData["target"]["plane"]["rateOfPlane"]} type="number" id={`${id}-${inputName}-target-plane-rateOfPlane`} name={`${id}-${inputName}-target-plane-rateOfPlane`} label="Plane" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["plane"]["totalHours"] <= 0 ? true : (reducerData["target"]["plane"]["rateOfHighSpeedTrain"] + reducerData["target"]["plane"]["rateOfRemote"]) === 100 ? true : ((reducerData["target"]["plane"]["rateOfHighSpeedTrain"] === 100 && reducerData["target"]["plane"]["rateOfRemote"] === 0) || (reducerData["target"]["plane"]["rateOfHighSpeedTrain"] !== 100 && reducerData["target"]["plane"]["rateOfRemote"] === 100)) ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingRight: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={1} prefixValue={reducerData["target"]["plane"]["rateOfHighSpeedTrain"]} type="number" id={`${id}-${inputName}-target-plane-rateOfHighSpeedTrain`} name={`${id}-${inputName}-target-plane-rateOfHighSpeedTrain`} label="High Speed Train" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Trains that regularly reach speeds of 300km/h or above are considered high speed trains. For replacement of plane journeys by train journeys, it is consider that a 1h flight is equivalent to a 4h train journey." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["plane"]["totalHours"] <= 0 ? true : (reducerData["target"]["plane"]["rateOfPlane"] + reducerData["target"]["plane"]["rateOfRemote"]) === 100 ? true : value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", padddingLeft: 2.5, paddingRight: 2.5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={15} suffixBottom={1} prefixValue={reducerData["target"]["plane"]["rateOfRemote"]} type="number" id={`${id}-${inputName}-target-plane-rateOfRemote`} name={`${id}-${inputName}-target-plane-rateOfRemote`} label="Remote" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true}
                                                register={register({
                                                    validate: (value) => !reducerData["target"]["visited"] ? true
                                                        : reducerData["initial"]["plane"]["totalHours"] <= 0 ? true
                                                            : (reducerData["target"]["plane"]["rateOfPlane"] + reducerData["target"]["plane"]["rateOfHighSpeedTrain"]) === 100 ? true
                                                                : value !== "" && value >= 0 ? value <= (100 - (reducerData["target"]["plane"]["rateOfPlane"] + reducerData["target"]["plane"]["rateOfHighSpeedTrain"])) ? true
                                                                    : "The sum of all replacement % should be 100"
                                                                    : "Only non-negative number between 0 - 100 is allowed"
                                                })}
                                                errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15, paddingTop: 10 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1, marginBottom: 0, paddingTop: 3 }}>High Speed Train replacement excluding air travel</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="replacement"></span>
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={7} prefixValue={reducerData["target"]["highSpeedTrain"]["rateOfHighSpeedTrain"]} type="number" id={`${id}-${inputName}-target-highSpeedTrain-rateOfHighSpeedTrain`} name={`${id}-${inputName}-target-highSpeedTrain-rateOfHighSpeedTrain`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Trains that regularly reach speeds of 300km/h or above are considered high speed trains" register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["highSpeedTrain"]["totalHours"] <= 0 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", padddingLeft: 2.5, paddingRight: 2.5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={15} suffixBottom={7} prefixValue={reducerData["target"]["highSpeedTrain"]["rateOfRemote"]} type="number" id={`${id}-${inputName}-target-highSpeedTrain-rateOfRemote`} name={`${id}-${inputName}-target-highSpeedTrain-rateOfRemote`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["highSpeedTrain"]["totalHours"] <= 0 ? true : value !== "" && value >= 0 ? (value <= (100 - reducerData["target"]["highSpeedTrain"]["rateOfHighSpeedTrain"])) ? true : "The sum of all replacement % should be 100" : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15, paddingTop: 10 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1, marginBottom: 0, paddingTop: 3 }}>Average Train replacement excluding air travel</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="replacement"></span>
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={0} prefixValue={reducerData["target"]["averageTrain"]["rateOfAverageTrain"]} type="number" id={`${id}-${inputName}-target-averageTrain-rateOfAverageTrain`} name={`${id}-${inputName}-target-averageTrain-rateOfAverageTrain`} label="Average Train" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["averageTrain"]["totalHours"] <= 0 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", padddingLeft: 2.5, paddingRight: 2.5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={15} suffixBottom={0} prefixValue={reducerData["target"]["averageTrain"]["rateOfRemote"]} type="number" id={`${id}-${inputName}-target-averageTrain-rateOfRemote`} name={`${id}-${inputName}-target-averageTrain-rateOfRemote`} label="Remote" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["averageTrain"]["totalHours"] <= 0 ? true : value !== "" && value >= 0 ? (value <= (100 - reducerData["target"]["averageTrain"]["rateOfAverageTrain"])) ? true : "The sum of all replacement % should be 100" : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5, marginTop: 15 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["target"]["plane"]["cf"] + reducerData["target"]["highSpeedTrain"]["cf"] + reducerData["target"]["averageTrain"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ paddingTop: 30 }}>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Short Distance Journey</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15, paddingTop: 15 }}>
                                    <Col lg={4} md={4} sm={4} style={{ paddingTop: 30 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1, marginBottom: 0, paddingTop: 3 }}>Ride hailing / Taxi replacement</p>
                                    </Col>
                                    <Col lg={8} md={8} sm={8}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={1} prefixValue={reducerData["target"]["taxi"]["rateOfTaxi"]} type="number" id={`${id}-${inputName}-target-taxi-rateOfTaxi`} name={`${id}-${inputName}-target-taxi-rateOfTaxi`} label="Taxi" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["taxi"]["totalHours"] <= 0 ? true : (reducerData["target"]["taxi"]["rateOfElectricTaxi"] + reducerData["target"]["taxi"]["rateOfPublicTransport"] + reducerData["target"]["taxi"]["rateOfRemote"]) === 100 ? true : (reducerData["target"]["taxi"]["rateOfElectricTaxi"] > 0 || reducerData["target"]["taxi"]["rateOfPublicTransport"] > 0 || reducerData["target"]["taxi"]["rateOfRemote"] > 0) ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingRight: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={1} prefixValue={reducerData["target"]["taxi"]["rateOfElectricTaxi"]} type="number" id={`${id}-${inputName}-target-taxi-rateOfElectricTaxi`} name={`${id}-${inputName}-target-taxi-rateOfElectricTaxi`} label="Electric Taxi" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="For electric vehicles the electricity mix for the selected country is used to calculate the emission." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["taxi"]["totalHours"] <= 0 ? true : (reducerData["target"]["taxi"]["rateOfTaxi"] + reducerData["target"]["taxi"]["rateOfPublicTransport"] + reducerData["target"]["taxi"]["rateOfRemote"]) === 100 ? true : value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", padddingLeft: 2.5, paddingRight: 2.5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={15} suffixBottom={1} prefixValue={reducerData["target"]["taxi"]["rateOfPublicTransport"]} type="number" id={`${id}-${inputName}-target-taxi-rateOfPublicTransport`} name={`${id}-${inputName}-target-taxi-rateOfPublicTransport`} label="Public Transport" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["taxi"]["totalHours"] <= 0 ? true : (reducerData["target"]["taxi"]["rateOfTaxi"] + reducerData["target"]["taxi"]["rateOfElectricTaxi"] + reducerData["target"]["taxi"]["rateOfRemote"]) === 100 ? true : value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={15} suffixBottom={1} prefixValue={reducerData["target"]["taxi"]["rateOfRemote"]} type="number" id={`${id}-${inputName}-target-taxi-rateOfRemote`} name={`${id}-${inputName}-target-taxi-rateOfRemote`} label="Remote" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["taxi"]["totalHours"] <= 0 ? true : (reducerData["target"]["taxi"]["rateOfTaxi"] + reducerData["target"]["taxi"]["rateOfElectricTaxi"] + reducerData["target"]["taxi"]["rateOfPublicTransport"]) === 100 ? true : value !== "" && value >= 0 ? (value <= (100 - (reducerData["target"]["taxi"]["rateOfTaxi"] + reducerData["target"]["taxi"]["rateOfElectricTaxi"] + reducerData["target"]["taxi"]["rateOfPublicTransport"]))) ? true : "The sum of all replacement % should be 100" : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5, marginTop: 15 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["target"]["taxi"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <hr style={{ borderTopWidth: "1px", borderTopStyle: "dotted", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>

                                <Row style={{ paddingTop: 5 }}>
                                    <Col lg={12} md={12} sm={12}>
                                        <p style={{ fontSize: "11px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}><b>Daily Commute</b></p>
                                        <span style={{ fontSize: "9px" }}>Daily journeys from home to Capgemini/client offices</span>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15, paddingTop: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 30 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1, marginBottom: 0, paddingTop: 3 }}>Private car replacement</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={0} prefixValue={reducerData["target"]["car"]["rateOfPrivateCar"]} type="number" id={`${id}-${inputName}-target-car-rateOfPrivateCar`} name={`${id}-${inputName}-target-car-rateOfPrivateCar`} label="Private Car" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["car"]["avgJourneyHoursPerDay"] <= 0 ? true : (reducerData["target"]["car"]["rateOfPublicTransport"] + reducerData["target"]["car"]["rateOfSoftTransport"]) === 100 ? true : ((reducerData["target"]["car"]["rateOfPublicTransport"] === 100 && reducerData["target"]["car"]["rateOfSoftTransport"] === 0) || (reducerData["target"]["car"]["rateOfPublicTransport"] !== 100 && reducerData["target"]["car"]["rateOfSoftTransport"] === 100)) ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingRight: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={0} prefixValue={reducerData["target"]["car"]["rateOfPublicTransport"]} type="number" id={`${id}-${inputName}-target-car-rateOfPublicTransport`} name={`${id}-${inputName}-target-car-rateOfPublicTransport`} label="Public Transport" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : (reducerData["target"]["car"]["rateOfPrivateCaar"] + reducerData["target"]["car"]["rateOfSoftTransport"]) === 100 ? true : value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", padddingLeft: 2.5, paddingRight: 2.5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={0} prefixValue={reducerData["target"]["car"]["rateOfSoftTransport"]} type="number" id={`${id}-${inputName}-target-car-rateOfSoftTransport`} name={`${id}-${inputName}-target-car-rateOfSoftTransport`} label="Soft Transport" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Soft transport includes walking & biking and is considered to have zero carbon emissions." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : (reducerData["target"]["car"]["rateOfPrivateCaar"] + reducerData["target"]["car"]["rateOfPublicTransport"]) === 100 ? true : value !== "" && value >= 0 ? (value <= (100 - (reducerData["target"]["car"]["rateOfPrivateCar"] + reducerData["target"]["car"]["rateOfPublicTransport"]))) ? true : "The sum of all replacement % should be 100" : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15, paddingTop: 10 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1, marginBottom: 0, paddingTop: 3 }}>Public transport replacement</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="replacement"></span>
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["target"]["publicTransport"]["rateOfPublicTransport"]} type="number" id={`${id}-${inputName}-target-publicTransport-rateOfPublicTransport`} name={`${id}-${inputName}-target-publicTransport-rateOfPublicTransport`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["publicTransport"]["avgJourneyHoursPerDay"] <= 0 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", padddingLeft: 2.5, paddingRight: 2.5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                            <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["target"]["publicTransport"]["rateOfSoftTransport"]} type="number" id={`${id}-${inputName}-target-publicTransport-rateOfSoftTransport`} name={`${id}-${inputName}-target-publicTransport-rateOfSoftTransport`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Soft transport includes walking & biking and is considered to have zero carbon emissions." register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : reducerData["initial"]["publicTransport"]["avgJourneyHoursPerDay"] <= 0 ? true : value !== "" && value >= 0 ? (value <= (100 - (reducerData["target"]["publicTransport"]["rateOfPublicTransport"]))) ? true : "The sum of all replacement % should be 100" : "Only non-negative number between 0 - 100 is allowed" })} errors={errors} labelStyle={{ fontSize: 9, color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginLeft: 5 }} formGroupStyle={{ flexGrow: 1, minWidth: "50px", maxWidth: "100px", marginLeft: "0px", marginBottom: "0px", paddingLeft: 5 }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5, marginTop: 15 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["target"]["car"]["cf"] + reducerData["target"]["publicTransport"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Digital</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{reducerData["initial"]["digital"]["cf"] === 0 ? 0 : parseFloat((reducerData["initial"]["digital"]["cf"] - reducerData["target"]["digital"]["cf"]) / reducerData["initial"]["digital"]["cf"] * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["target"]["digital"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>The team commits to proceed to a SharePoint and mail box cleaning at the end of the project</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="The proxy for emissions related to the use of digital by consultants was defined based on several types of data.<br /><br />- First, empirical results have been collected. The running of a “digital cleaning event” within the EUC MU of Capgemini Invent gathered more than 30 people. It enabled the collection of data underlying our assumptions, including those about average mailbox and sharepoint sizes.<br />- Second, some data (such as the carbon impact in g CO2 eq of the sending or stocking of a Mb) issued by specialised organisations (ADEME, The Shift Project)<br />- Third, some assumptions have been made for variables for which we didn’t have access to appropriate data sources." errors={errors} loadOptions={getUnitOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-target-digital-commit`}
                                                    defaultValue={unitOptions[1]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Other</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{(
                                                reducerData["initial"]["food"]["cf"] +
                                                reducerData["initial"]["waste"]["cf"] +
                                                reducerData["initial"]["hotel"]["cf"]
                                            ) === 0 ? 0
                                                : parseFloat((
                                                    (
                                                        reducerData["initial"]["food"]["cf"] +
                                                        reducerData["initial"]["waste"]["cf"] +
                                                        reducerData["initial"]["hotel"]["cf"]
                                                    ) - (
                                                        reducerData["target"]["food"]["cf"] +
                                                        reducerData["target"]["waste"]["cf"] +
                                                        reducerData["target"]["hotel"]["cf"]
                                                    )
                                                ) / (
                                                        reducerData["initial"]["food"]["cf"] +
                                                        reducerData["initial"]["waste"]["cf"] +
                                                        reducerData["initial"]["hotel"]["cf"]
                                                    ) * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["target"]["food"]["cf"] + reducerData["target"]["waste"]["cf"] + reducerData["target"]["hotel"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Food <br />(number of days the team commits to eating vegetarian)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="Initial calculation assume all meals contain meat" errors={errors} loadOptions={getFoodOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-target-food-committedNoOfMealsPerWeek`}
                                                    defaultValue={foodOptions[0]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["target"]["food"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Waste <br />(The team commits to minimize waste, eg, using resuable water bottle)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="When estimated the carbon impact of waste this calculator includes plastic bottles, paper cups and lunch packaging.<br />For a more detailed view contact your local sustainability lead." errors={errors} loadOptions={getUnitOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-target-waste-commit`}
                                                    defaultValue={unitOptions[1]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["target"]["waste"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Number of days</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Number of hotel night <br />(total for the team over entire project length)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" type="number" id={`${id}-${inputName}-target-hotel-totalNights`} name={`${id}-${inputName}-target-hotel-totalNights`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["target"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5, marginTop: 15 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["target"]["hotel"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 20 }}>
                            <Col>
                                <InputTextArea rows={"3"} className="textType" type="text" id={`${id}-${inputName}-target-assumption-data`} name={`${id}-${inputName}-target-assumption-data`} label={"Target Assumption"} placeholder={"Assumptions"} maxLength={1000} register={register({ maxLength: 1000 })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "150%", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "70px", width: "65%" }} onChange={(e) => { inputTargetTextChange(e); targetCharacterChange(e.target.value); setTargetCharacterCount(e.target.value.length) }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span style={{ fontSize: "10px", fontWeight: "normal" }} register={register} >Remaining characters  -  {1000 - targetCharacterCount}</span>
                            </Col>
                        </Row>
                    </div>
                </Tab.Container>
            </Tab>
        ),
        [`actualImpact`]: (
            <Tab eventKey={`actual`} title="3 - Actual Impact" key={`actualImpact`}>
                <Tab.Container>
                    <div style={{ paddingLeft: 20, paddingRight: 10, marginTop: 15, paddingBottom: 25 }}>
                        <div style={{ display: "flex", marginRight: 10, justifyContent: "space-between", marginLeft: 0 }}>
                            <Input className="numberType" type="number" id={`${id}-${inputName}-actual-ftpCount-value`} name={`${id}-${inputName}-actual-ftpCount-value`} label="Number of team members" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="If resource start or end dates are staggered, estimate the number of full time team members for the duration of the project." register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value > 0 ? true : "Only non-negative number greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                            <Input className="numberType" type="number" id={`${id}-${inputName}-actual-projectLength-value`} name={`${id}-${inputName}-actual-projectLength-value`} label="Project length (in days)" onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value > 0 ? true : "Only non-negative number greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                        </div>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Work Location</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{reducerData["initial"]["office"]["cf"] === 0 ? 0 : parseFloat((reducerData["initial"]["office"]["cf"] - reducerData["actual"]["office"]["cf"]) / reducerData["initial"]["office"]["cf"] * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{reducerData["target"]["office"]["cf"] === 0 ? 0 : parseFloat((reducerData["target"]["office"]["cf"] - reducerData["actual"]["office"]["cf"]) / reducerData["target"]["office"]["cf"] * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to target</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["actual"]["office"]["cf"] + reducerData["actual"]["wfh"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px", paddingTop: 3 }}>% of time</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0, letterSpacing: 0.1, paddingTop: 3 }}>Capgemini / Client site energy</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={7} type="number" prefixValue={reducerData["actual"]["office"]["rateOfClient"]} id={`${id}-${inputName}-actual-office-rateOfClient`} name={`${id}-${inputName}-actual-office-rateOfClient`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Average for the duration of the project.<br />These calculations are country specific based on a data from Capgemini offices and the local electricity mix." register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} value={reducerData["actual"]["office"]["rateOfClient"]} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0, letterSpacing: 0.1, paddingTop: 3 }}>Working from home</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={7} prefixValue={reducerData["actual"]["office"]["rateOfRemote"]} type="number" id={`${id}-${inputName}-actual-office-rateOfRemote`} name={`${id}-${inputName}-actual-office-rateOfRemote`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Average for the duration of the project.<br />These calculations are country specific based on a data from Capgemini offices and the local electricity mix." register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 && value <= 100 ? true : value !== "" && value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" : true })} value={reducerData["actual"]["office"]["rateOfRemote"]} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row style={{ marginBottom: 25 }}>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Transportation</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{(
                                                reducerData["initial"]["plane"]["cf"] +
                                                reducerData["initial"]["highSpeedTrain"]["cf"] +
                                                reducerData["initial"]["averageTrain"]["cf"] +
                                                reducerData["initial"]["taxi"]["cf"] +
                                                reducerData["initial"]["car"]["cf"] +
                                                reducerData["initial"]["publicTransport"]["cf"]
                                            ) === 0 ? 0
                                                : parseFloat((
                                                    (
                                                        reducerData["initial"]["plane"]["cf"] +
                                                        reducerData["initial"]["highSpeedTrain"]["cf"] +
                                                        reducerData["initial"]["averageTrain"]["cf"] +
                                                        reducerData["initial"]["taxi"]["cf"] +
                                                        reducerData["initial"]["car"]["cf"] +
                                                        reducerData["initial"]["publicTransport"]["cf"]
                                                    ) - (
                                                        reducerData["actual"]["plane"]["cf"] +
                                                        reducerData["actual"]["highSpeedTrain"]["cf"] +
                                                        reducerData["actual"]["averageTrain"]["cf"] +
                                                        reducerData["actual"]["taxi"]["cf"] +
                                                        reducerData["actual"]["car"]["cf"] +
                                                        reducerData["actual"]["publicTransport"]["cf"]
                                                    )
                                                ) / (
                                                        reducerData["initial"]["plane"]["cf"] +
                                                        reducerData["initial"]["highSpeedTrain"]["cf"] +
                                                        reducerData["initial"]["averageTrain"]["cf"] +
                                                        reducerData["initial"]["taxi"]["cf"] +
                                                        reducerData["initial"]["car"]["cf"] +
                                                        reducerData["initial"]["publicTransport"]["cf"]
                                                    ) * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{(
                                                reducerData["target"]["plane"]["cf"] +
                                                reducerData["target"]["highSpeedTrain"]["cf"] +
                                                reducerData["target"]["averageTrain"]["cf"] +
                                                reducerData["target"]["taxi"]["cf"] +
                                                reducerData["target"]["car"]["cf"] +
                                                reducerData["target"]["publicTransport"]["cf"]
                                            ) === 0 ? 0
                                                : parseFloat((
                                                    (
                                                        reducerData["target"]["plane"]["cf"] +
                                                        reducerData["target"]["highSpeedTrain"]["cf"] +
                                                        reducerData["target"]["averageTrain"]["cf"] +
                                                        reducerData["target"]["taxi"]["cf"] +
                                                        reducerData["target"]["car"]["cf"] +
                                                        reducerData["target"]["publicTransport"]["cf"]
                                                    ) - (
                                                        reducerData["actual"]["plane"]["cf"] +
                                                        reducerData["actual"]["highSpeedTrain"]["cf"] +
                                                        reducerData["actual"]["averageTrain"]["cf"] +
                                                        reducerData["actual"]["taxi"]["cf"] +
                                                        reducerData["actual"]["car"]["cf"] +
                                                        reducerData["actual"]["publicTransport"]["cf"]
                                                    )
                                                ) / (
                                                        reducerData["target"]["plane"]["cf"] +
                                                        reducerData["target"]["highSpeedTrain"]["cf"] +
                                                        reducerData["target"]["averageTrain"]["cf"] +
                                                        reducerData["target"]["taxi"]["cf"] +
                                                        reducerData["target"]["car"]["cf"] +
                                                        reducerData["target"]["publicTransport"]["cf"]
                                                    ) * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to target</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["actual"]["plane"]["cf"] + reducerData["actual"]["highSpeedTrain"]["cf"] + reducerData["actual"]["averageTrain"]["cf"] + reducerData["actual"]["taxi"]["cf"] + reducerData["actual"]["car"]["cf"] + reducerData["actual"]["publicTransport"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "11px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}><b>Business Travel</b></p>
                                        <span style={{ fontSize: "9px" }}>Long distance journeys, not including daily commutes to and from <br /> Capgemini/client offices from home</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px", marginTop: "30px" }}>Total hours throughout the project length</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>Plane</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["plane"]["totalHours"]} type="number" id={`${id}-${inputName}-actual-plane-totalHours`} name={`${id}-${inputName}-actual-plane-totalHours`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>High Speed Train</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["highSpeedTrain"]["totalHours"]} type="number" id={`${id}-${inputName}-actual-highSpeedTrain-totalHours`} name={`${id}-${inputName}-actual-highSpeedTrain-totalHours`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="Trains that regularly reach speeds of 300km/h or above are considered high speed trains" register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4" }}>Average Train</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["averageTrain"]["totalHours"]} type="number" id={`${id}-${inputName}-actual-averageTrain-totalHours`} name={`${id}-${inputName}-actual-averageTrain-totalHours`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["actual"]["plane"]["cf"] + reducerData["actual"]["highSpeedTrain"]["cf"] + reducerData["actual"]["averageTrain"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{ paddingTop: 30 }}>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Short Distance Journey</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Total hours throughout the project length</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Ride hailing service / Taxi</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["taxi"]["totalHoursTaxi"]} type="number" id={`${id}-${inputName}-actual-taxi-totalHoursTaxi`} name={`${id}-${inputName}-actual-taxi-totalHoursTaxi`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="On average a car will travel 40km in 1 hour within an urban environment" register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Taxi green <br />(replacement for Taxi)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["taxi"]["totalHoursElectricTaxi"]} type="number" id={`${id}-${inputName}-actual-taxi-totalHoursElectricTaxi`} name={`${id}-${inputName}-actual-taxi-totalHoursElectricTaxi`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} tooltipMessage="For electric vehicles the electricity mix for the selected country is used to calculate the emission." register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Public Transport <br />(replacement for Taxi)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["taxi"]["totalHoursPublicTransport"]} type="number" id={`${id}-${inputName}-actual-taxi-totalHoursPublicTransport`} name={`${id}-${inputName}-actual-taxi-totalHoursPublicTransport`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["actual"]["taxi"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <hr style={{ borderTopWidth: "1px", borderTopStyle: "dotted", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>

                                <Row style={{ paddingTop: 5 }}>
                                    <Col lg={12} md={12} sm={12}>
                                        <p style={{ fontSize: "11px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}><b>Daily Commute</b></p>
                                        <span style={{ fontSize: "9px" }}>Daily journeys from home to Capgemini/client offices</span>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>% of individual car</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["car"]["rateOfDailyJournies"]} type="number" id={`${id}-${inputName}-actual-car-rateOfDailyJournies`} name={`${id}-${inputName}-actual-car-rateOfDailyJournies`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" : true })} value={reducerData["actual"]["car"]["rateOfDailyJournies"]} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>% of public transport</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["publicTransport"]["rateOfDailyJournies"]} type="number" id={`${id}-${inputName}-actual-publicTransport-rateOfDailyJournies`} name={`${id}-${inputName}-actual-publicTransport-rateOfDailyJournies`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed" : true })} value={reducerData["actual"]["publicTransport"]["rateOfDailyJournies"]} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px", paddingTop: 3 }}>Daily average for 1 FTE</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Individual car</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["car"]["avgJourneyHoursPerDay"]} type="number" id={`${id}-${inputName}-actual-car-avgJourneyHoursPerDay`} name={`${id}-${inputName}-actual-car-avgJourneyHoursPerDay`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value >= 0 ? true : "Only non-negative number including 0 and greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 24 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", letterSpacing: 0.1 }}>Public transport</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" suffix={true} suffixContent={'hrs'} suffixAlign={'left'} suffixLeft={10} suffixBottom={8} prefixValue={reducerData["actual"]["publicTransport"]["avgJourneyHoursPerDay"]} type="number" id={`${id}-${inputName}-actual-publicTransport-avgJourneyHoursPerDay`} name={`${id}-${inputName}-actual-publicTransport-avgJourneyHoursPerDay`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value >= 0 ? true : "Only non-negative number including 0 and greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ marginTop: 10, display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["actual"]["car"]["cf"] + reducerData["actual"]["publicTransport"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Digital</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{reducerData["initial"]["digital"]["cf"] === 0 ? 0 : parseFloat((reducerData["initial"]["digital"]["cf"] - reducerData["actual"]["digital"]["cf"]) / reducerData["initial"]["digital"]["cf"] * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{reducerData["target"]["digital"]["cf"] === 0 ? 0 : parseFloat((reducerData["target"]["digital"]["cf"] - reducerData["actual"]["digital"]["cf"]) / reducerData["target"]["digital"]["cf"] * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to target</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["actual"]["digital"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>The team commits to proceed to a SharePoint and mail box cleaning at the end of the project</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="The proxy for emissions related to the use of digital by consultants was defined based on several types of data.<br /><br />- First, empirical results have been collected. The running of a “digital cleaning event” within the EUC MU of Capgemini Invent gathered more than 30 people. It enabled the collection of data underlying our assumptions, including those about average mailbox and sharepoint sizes.<br />- Second, some data (such as the carbon impact in g CO2 eq of the sending or stocking of a Mb) issued by specialised organisations (ADEME, The Shift Project)<br />- Third, some assumptions have been made for variables for which we didn’t have access to appropriate data sources." errors={errors} loadOptions={getUnitOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-actual-digital-commit`}
                                                    defaultValue={unitOptions[1]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "rgba(255, 255, 255, 0.87)", marginLeft: "-10px" }}></hr>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <div style={{ fontSize: "16px" }}>Other</div>
                                        <span style={{ fontSize: "9px" }}>Related impact</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{(
                                                reducerData["initial"]["food"]["cf"] +
                                                reducerData["initial"]["waste"]["cf"] +
                                                reducerData["initial"]["hotel"]["cf"]
                                            ) === 0 ? 0
                                                : parseFloat((
                                                    (
                                                        reducerData["initial"]["food"]["cf"] +
                                                        reducerData["initial"]["waste"]["cf"] +
                                                        reducerData["initial"]["hotel"]["cf"]
                                                    ) - (
                                                        reducerData["actual"]["food"]["cf"] +
                                                        reducerData["actual"]["waste"]["cf"] +
                                                        reducerData["actual"]["hotel"]["cf"]
                                                    )
                                                ) / (
                                                        reducerData["initial"]["food"]["cf"] +
                                                        reducerData["initial"]["waste"]["cf"] +
                                                        reducerData["initial"]["hotel"]["cf"]
                                                    ) * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to initial</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{(
                                                reducerData["target"]["food"]["cf"] +
                                                reducerData["target"]["waste"]["cf"] +
                                                reducerData["target"]["hotel"]["cf"]
                                            ) === 0 ? 0
                                                : parseFloat((
                                                    (
                                                        reducerData["target"]["food"]["cf"] +
                                                        reducerData["target"]["waste"]["cf"] +
                                                        reducerData["target"]["hotel"]["cf"]
                                                    ) - (
                                                        reducerData["actual"]["food"]["cf"] +
                                                        reducerData["actual"]["waste"]["cf"] +
                                                        reducerData["actual"]["hotel"]["cf"]
                                                    )
                                                ) / (
                                                        reducerData["target"]["food"]["cf"] +
                                                        reducerData["target"]["waste"]["cf"] +
                                                        reducerData["target"]["hotel"]["cf"]
                                                    ) * 100).toFixed(0)}%</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>reduction compared to target</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className="carbonFootprint" style={{ paddingTop: 2 }}>
                                            <h6 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(reducerData["actual"]["food"]["cf"] + reducerData["actual"]["waste"]["cf"] + reducerData["actual"]["hotel"]["cf"]).toFixed(3))}</h6>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Food <br />(number of days the team commits to eating vegetarian)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getFoodOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-actual-food-committedNoOfMealsPerWeek`}
                                                    defaultValue={foodOptions[0]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["actual"]["food"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Waste <br />(The team commits to minimize waste, eg, using resuable water bottle)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        {
                                            control !== undefined ? (
                                                <Controller
                                                    render={({ onChange, value, name }) => (
                                                        <Select id={name} name={name} value={value} onChange={(val) => { onSelectChange(name, val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} tooltip={true} tooltipMessage="When estimated the carbon impact of waste this calculator includes plastic bottles, paper cups and lunch packaging.<br />For a more detailed view contact your local sustainability lead." errors={errors} loadOptions={getUnitOptions} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginLeft: 5, marginRight: -5 }} styles={{ color: "black", fontSize: "16px" }} color={`#6c757d`} onClickColor="#7444DA80" />
                                                    )}
                                                    control={control}
                                                    name={`${id}-${inputName}-actual-waste-commit`}
                                                    defaultValue={unitOptions[1]}
                                                />
                                            ) : (<></>)
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["actual"]["waste"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <p style={{ fontSize: "9px", color: "rgba(255, 255, 255, 0.87)", letterSpacing: 0.4, marginBottom: "0px" }}>Number of days</p>
                                    </Col>
                                </Row>
                                <Row style={{ paddingRight: 15 }}>
                                    <Col lg={6} md={6} sm={6} style={{ paddingTop: 18 }}>
                                        <p style={{ fontSize: "11px", color: "#F4F4F4", marginBottom: 0 }}>Number of hotel night <br />(total for the team over entire project length)</p>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Input className="numberType" type="number" id={`${id}-${inputName}-actual-hotel-totalNights`} name={`${id}-${inputName}-actual-hotel-totalNights`} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => !reducerData["actual"]["visited"] ? true : value !== "" ? value >= 0 ? true : "Only non-negative number greater than or equal to 0 allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, minWidth: "185px", marginLeft: "0px", marginBottom: "0px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px", marginLeft: 5 }} onChange={(e) => inputChange(e)} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <div className="carbonFootprint" style={{ display: "flex", justifyContent: "flex-end", marginLeft: 5, marginRight: -5, marginTop: 15 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, marginRight: 0 }}>
                                                <span style={{ fontSize: "11px", fontWeight: "normal", paddingTop: 1.9 }}>Sub total : </span>
                                                <div style={{ marginRight: 2, marginLeft: 10 }}>
                                                    <span className="numberType" style={{ fontSize: "13px", fontWeight: "bold" }}>{numberWithCommas(parseFloat(reducerData["actual"]["hotel"]["cf"]).toFixed(3))}</span>
                                                    <span style={{ marginLeft: 10, fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 20 }}>
                            <Col>
                                <InputTextArea rows={"3"} className="textType" type="text" id={`${id}-${inputName}-actual-assumption-data`} name={`${id}-${inputName}-actual-assumption-data`} label={"Actual Assumption"} placeholder={"Assumptions"} maxLength={1000} register={register({ maxLength: 1000 })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "150%", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "70px", width: "65%" }} onChange={(e) => { inputActualTextChange(e); actualCharacterChange(e.target.value); setActualCharacterCount(e.target.value.length) }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span style={{ fontSize: "10px", fontWeight: "normal" }} register={register} >Remaining characters  -  {1000 - actualCharacterCount}</span>
                            </Col>
                        </Row>
                    </div>
                </Tab.Container>
            </Tab>
        )
    }

    return (
        <Collapse in={collapseControll}>
            <Element name={`scrollDetailedDelivery${inputName.replace(" ", "")}`} className="element" >
                <div>
                    {
                        totalCF("initial") ?
                            (<Summary
                                initialimpacttonnes={totalCF("initial")}
                                targetimpacttonnes={totalCF("target")}
                                reductiontoinitialtargetimpact={totalCF("initial") === 0 ? 0 : parseFloat((totalCF("initial") - totalCF("target")) / totalCF("initial") * 100).toFixed(0)}
                                actualimpacttonnes={totalCF("actual")}
                                reductiontoinitial={totalCF("initial") === 0 ? 0 : parseFloat((totalCF("initial") - totalCF("actual")) / totalCF("initial") * 100).toFixed(0)}
                                reductiontotarget={totalCF("target") === 0 ? 0 : parseFloat((totalCF("target") - totalCF("actual")) / totalCF("target") * 100).toFixed(0)}
                            />)
                            : null
                    }
                </div>

                <div style={{ margin: "10px", marginBottom: "0px", marginTop: "0px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <div style={{ padding: "0px", position: "sticky", top: "107px", zIndex: 2, backgroundColor: "#343434", marginLeft: "-20px", marginRight: "-10px", minHeight: "70px", borderTopWidth: "1.5px", borderTopColor: "#262626", borderTopStyle: "solid", borderBottomWidth: "1.5px", borderBottomColor: "#262626", borderBottomStyle: "solid" }}>
                        <Row style={{ padding: "5px 10px 5px 20px" }}>
                            <Col lg={3} md={3} sm={12}>
                                <div style={{ paddingTop: "1.4rem", paddingBottom: "0.4rem", fontSize: "16px", textAlign: "left", color: "#F4F4F4", backgroundColor: "#343434" }}>{label}</div>
                            </Col>
                            <Col lg={3} md={3} sm={12}>
                                {
                                    key === "actual" && (
                                        <div className="carbonFootprint">
                                            <p style={{ paddingTop: "1px" }}>Reduction compared to initial</p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{`${(totalCF("initial") === 0 || totalCF("actual") === 0) ? 0 : parseFloat((totalCF("initial") - totalCF("actual")) / totalCF("initial") * 100).toFixed(0)}%`}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}></span>
                                        </div>
                                    )
                                }
                            </Col>
                            <Col lg={3} md={3} sm={12}>
                                {
                                    key === "target" && (
                                        <div className="carbonFootprint">
                                            <p style={{ paddingTop: "1px" }}>Reduction compared to initial</p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{`${(totalCF("initial") === 0 || totalCF("target") === 0) ? 0 : parseFloat((totalCF("initial") - totalCF("target")) / totalCF("initial") * 100).toFixed(0)}%`}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}></span>
                                        </div>
                                    )
                                }
                                {
                                    key === "actual" && (
                                        <div className="carbonFootprint">
                                            <p style={{ paddingTop: "1px" }}>Reduction compared to target</p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{`${(totalCF("target") === 0 || totalCF("actual") === 0) ? 0 : parseFloat((totalCF("target") - totalCF("actual")) / totalCF("target") * 100).toFixed(0)}%`}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}></span>
                                        </div>
                                    )
                                }
                            </Col>
                            <Col lg={3} md={3} sm={12}>
                                {
                                    key === "initial" && (
                                        <div className="carbonFootprint">
                                            <p style={{ paddingTop: "1px" }}>Initial impact </p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType" >{numberWithCommas(parseFloat(totalCF("initial")).toFixed(3))}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    )
                                }
                                {
                                    key === "target" && (
                                        <div className="carbonFootprint">
                                            <p style={{ paddingTop: "1px" }}>Target impact</p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{numberWithCommas(parseFloat(totalCF("target")).toFixed(3))}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    )
                                }
                                {
                                    (key === "actual") && (
                                        <div className="carbonFootprint">
                                            <p style={{ paddingTop: "1px" }}>Actual impact</p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{numberWithCommas(parseFloat(totalCF("actual")).toFixed(3))}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className="newDeliveryPillsCollapse" id="impactTabs" style={{ padding: "0px", marginLeft: "-20px", marginRight: "-10px" }}>
                        <Tabs
                            id={`${id}-impactTabs`}
                            activeKey={key}
                            onSelect={(k) => {
                                if (k === "initial") {
                                    dispatchToReducer({ type: `SET_NEW_DELIVERY_VISITED_VALUE`, stage: k, param: "visited", payload: true });
                                    setKey(k);
                                } else {
                                    if (key !== "") {
                                        setShouldChangeTab(k);
                                    } else {
                                        props.toastMessage({ state: true, header: "Alert", body: "Please start with '1-Initial Impact' and fill in the details!" });
                                    }
                                }
                            }}
                        >
                            {
                                Object.keys(impactTabs).map(tabItem => impactTabs[tabItem])
                            }
                        </Tabs>
                    </div>
                </div>
            </Element>
        </Collapse >
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(CollapseNewDeliveryCustom);
