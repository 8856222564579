import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForSeaFreightCargoShipCalc } from "../../calculatorFunctions/sea_freight_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const CollapseSeaFreightCargoShipCustom = (props) => {
    const cargoShipBulkCarrierOptions = props.cargoShipBulkCarrierOptionsInCollapseData;
    const cargoShipGeneralCargoOptions = props.cargoShipGeneralCargoOptionsInCollapseData;
    const cargoShipContainerShipOptions = props.cargoShipContainerShipOptionsInCollapseData;
    const cargoShipVehicleTransportOptions = props.cargoShipVehicleTransportOptionsInCollapseData;
    const cargoShipRoroFerryOptions = props.cargoShipRoroFerryOptionsInCollapseData;

    let { collapseControll, id, inputName, control, cargoShipVal,
        selectBulkCarrierValue, selectGeneralCargoValue, selectContainerShipValue, selectVehicleTransportValue,
        selectRoroFerryValue, inputTonnes, inputKm, label, inputPlaceholder, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [tonnes, setTonnes] = useState((inputTonnes === undefined || inputTonnes === "") ? 0 : inputTonnes);
    const [km, setKm] = useState((inputKm === undefined || inputKm === "") ? 0 : inputKm);
    const [bulkCarrier, setBulkCarrier] = useState((selectBulkCarrierValue === undefined || selectBulkCarrierValue === "") ? cargoShipBulkCarrierOptions[0] : selectBulkCarrierValue);
    const [generalCargo, setGeneralCargo] = useState((selectGeneralCargoValue === undefined || selectGeneralCargoValue === "") ? cargoShipGeneralCargoOptions[0] : selectGeneralCargoValue);
    const [containerShip, setContainerShip] = useState((selectContainerShipValue === undefined || selectContainerShipValue === "") ? cargoShipContainerShipOptions[0] : selectContainerShipValue);
    const [vehicleTransport, setVehicleTransport] = useState((selectVehicleTransportValue === undefined || selectVehicleTransportValue === "") ? cargoShipVehicleTransportOptions[0] : selectVehicleTransportValue);
    const [roroFerry, setRoroFerry] = useState((selectRoroFerryValue === undefined || selectRoroFerryValue === "") ? cargoShipRoroFerryOptions[0] : selectRoroFerryValue);

    const getBulkCarrierOptions = (inputVal, callback) => {
        callback(cargoShipBulkCarrierOptions)
    }

    const getGeneralCargoOptions = (inputVal, callback) => {
        callback(cargoShipGeneralCargoOptions)
    }

    const getContainerShipOptions = (inputVal, callback) => {
        callback(cargoShipContainerShipOptions)
    }

    const getVehicleTransportOptions = (inputVal, callback) => {
        callback(cargoShipVehicleTransportOptions)
    }

    const getRoroFerryOptions = (inputVal, callback) => {
        callback(cargoShipRoroFerryOptions)
    }

    const onBulkCarrierChange = (val) => {
        setBulkCarrier(val);
    }

    const onGeneralCargoChange = (val) => {
        setGeneralCargo(val);
    }

    const onContainerShipChange = (val) => {
        setContainerShip(val);
    }

    const onVehicleTransportChange = (val) => {
        setVehicleTransport(val);
    }

    const onRoroFerrryChange = (val) => {
        setRoroFerry(val);
    }

    const inputTonnesChange = (val) => {
        setTonnes((val !== undefined && val !== "") ? val : 0);
    }

    const inputKmChange = (val) => {
        setKm((val !== undefined && val !== "") ? val : 0);
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const setCF = (val) => {
        var cf = 0;
        let wtt = 0;
        let scope3 = 0;
        let bulkName = cargoShipBulkCarrierOptions.map(item => {
            return `Cargo Ship_Bulk carrier_${item.value}`;
        });

        let generalName = cargoShipGeneralCargoOptions.map(item => {
            return `Cargo Ship_General cargo_${item.value}`;
        });
        let containerName = cargoShipContainerShipOptions.map(item => {
            return `Cargo Ship_Container ship_${item.value}`;
        });
        let vehicleName = cargoShipVehicleTransportOptions.map(item => {
            return `Cargo Ship_Vehicle transport_${item.value}`;
        });
        let roroName = cargoShipRoroFerryOptions.map(item => {
            return `Cargo Ship_RoRo-Ferry_${item.value}`;
        });
        let distance = km === undefined || km === "" ? 0 : km;
        let weightInTonnes = tonnes === undefined || tonnes === "" ? 0 : tonnes;
        let seaFreightSizeVal = "";

        if (inputName === "Bulk carrier") {
            if (bulkCarrier.value === cargoShipBulkCarrierOptions[0].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[0]) && props.master.seaFreightCargoShipSizeMaster[bulkName[0]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[0]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[0]) && props.master.seaFreightCargoShipSizeMaster[bulkName[0]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[0]].scope3 : 0;
            } else if (bulkCarrier.value === cargoShipBulkCarrierOptions[1].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[1]) && props.master.seaFreightCargoShipSizeMaster[bulkName[1]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[1]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[1]) && props.master.seaFreightCargoShipSizeMaster[bulkName[1]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[1]].scope3 : 0;
            } else if (bulkCarrier.value === cargoShipBulkCarrierOptions[2].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[2]) && props.master.seaFreightCargoShipSizeMaster[bulkName[2]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[2]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[2]) && props.master.seaFreightCargoShipSizeMaster[bulkName[2]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[2]].scope3 : 0;
            } else if (bulkCarrier.value === cargoShipBulkCarrierOptions[3].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[3]) && props.master.seaFreightCargoShipSizeMaster[bulkName[3]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[3]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[3]) && props.master.seaFreightCargoShipSizeMaster[bulkName[3]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[3]].scope3 : 0;
            } else if (bulkCarrier.value === cargoShipBulkCarrierOptions[4].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[4]) && props.master.seaFreightCargoShipSizeMaster[bulkName[4]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[4]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[4]) && props.master.seaFreightCargoShipSizeMaster[bulkName[4]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[4]].scope3 : 0;
            } else if (bulkCarrier.value === cargoShipBulkCarrierOptions[5].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[5]) && props.master.seaFreightCargoShipSizeMaster[bulkName[5]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[5]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[5]) && props.master.seaFreightCargoShipSizeMaster[bulkName[5]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[5]].scope3 : 0;
            } else if (bulkCarrier.value === cargoShipBulkCarrierOptions[6].value) {
                seaFreightSizeVal = bulkCarrier.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[6]) && props.master.seaFreightCargoShipSizeMaster[bulkName[6]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[6]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[6]) && props.master.seaFreightCargoShipSizeMaster[bulkName[6]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[6]].scope3 : 0;
            }
        } else if (inputName === "General cargo") {
            if (generalCargo.value === cargoShipGeneralCargoOptions[0].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[0]) && props.master.seaFreightCargoShipSizeMaster[generalName[0]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[0]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[0]) && props.master.seaFreightCargoShipSizeMaster[generalName[0]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[0]].scope3 : 0;
            } else if (generalCargo.value === cargoShipGeneralCargoOptions[1].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[1]) && props.master.seaFreightCargoShipSizeMaster[generalName[1]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[1]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[1]) && props.master.seaFreightCargoShipSizeMaster[generalName[1]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[1]].scope3 : 0;
            } else if (generalCargo.value === cargoShipGeneralCargoOptions[2].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[2]) && props.master.seaFreightCargoShipSizeMaster[generalName[2]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[2]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[2]) && props.master.seaFreightCargoShipSizeMaster[generalName[2]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[2]].scope3 : 0;
            } else if (generalCargo.value === cargoShipGeneralCargoOptions[3].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[3]) && props.master.seaFreightCargoShipSizeMaster[generalName[3]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[3]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[3]) && props.master.seaFreightCargoShipSizeMaster[generalName[3]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[3]].scope3 : 0;
            } else if (generalCargo.value === cargoShipGeneralCargoOptions[4].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[4]) && props.master.seaFreightCargoShipSizeMaster[generalName[4]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[4]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[4]) && props.master.seaFreightCargoShipSizeMaster[generalName[4]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[4]].scope3 : 0;
            } else if (generalCargo.value === cargoShipGeneralCargoOptions[5].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[5]) && props.master.seaFreightCargoShipSizeMaster[generalName[5]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[5]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[5]) && props.master.seaFreightCargoShipSizeMaster[generalName[5]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[5]].scope3 : 0;
            } else if (generalCargo.value === cargoShipGeneralCargoOptions[6].value) {
                seaFreightSizeVal = generalCargo.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[6]) && props.master.seaFreightCargoShipSizeMaster[generalName[6]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[6]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(generalName[6]) && props.master.seaFreightCargoShipSizeMaster[generalName[6]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[generalName[6]].scope3 : 0;
            }
        } else if (inputName === "Container ship") {
            if (containerShip.value === cargoShipContainerShipOptions[0].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[0]) && props.master.seaFreightCargoShipSizeMaster[containerName[0]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[0]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[0]) && props.master.seaFreightCargoShipSizeMaster[containerName[0]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[0]].scope3 : 0;
            } else if (containerShip.value === cargoShipContainerShipOptions[1].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[1]) && props.master.seaFreightCargoShipSizeMaster[containerName[1]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[1]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[1]) && props.master.seaFreightCargoShipSizeMaster[containerName[1]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[1]].scope3 : 0;
            } else if (containerShip.value === cargoShipContainerShipOptions[2].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[2]) && props.master.seaFreightCargoShipSizeMaster[containerName[2]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[2]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[2]) && props.master.seaFreightCargoShipSizeMaster[containerName[2]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[2]].scope3 : 0;
            } else if (containerShip.value === cargoShipContainerShipOptions[3].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[3]) && props.master.seaFreightCargoShipSizeMaster[containerName[3]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[3]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[3]) && props.master.seaFreightCargoShipSizeMaster[containerName[3]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[3]].scope3 : 0;
            } else if (containerShip.value === cargoShipContainerShipOptions[4].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[4]) && props.master.seaFreightCargoShipSizeMaster[containerName[4]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[4]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[4]) && props.master.seaFreightCargoShipSizeMaster[containerName[4]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[4]].scope3 : 0;
            } else if (containerShip.value === cargoShipContainerShipOptions[5].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[5]) && props.master.seaFreightCargoShipSizeMaster[containerName[5]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[5]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[5]) && props.master.seaFreightCargoShipSizeMaster[containerName[5]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[5]].scope3 : 0;
            } else if (containerShip.value === cargoShipContainerShipOptions[6].value) {
                seaFreightSizeVal = containerShip.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[6]) && props.master.seaFreightCargoShipSizeMaster[containerName[6]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[6]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(containerName[6]) && props.master.seaFreightCargoShipSizeMaster[containerName[6]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[containerName[6]].scope3 : 0;
            }
        } else if (inputName === "Vehicle transport") {
            if (vehicleTransport.value === cargoShipVehicleTransportOptions[0].value) {
                seaFreightSizeVal = vehicleTransport.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(vehicleName[0]) && props.master.seaFreightCargoShipSizeMaster[vehicleName[0]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[vehicleName[0]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(vehicleName[0]) && props.master.seaFreightCargoShipSizeMaster[vehicleName[0]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[vehicleName[0]].scope3 : 0;
            } else if (vehicleTransport.value === cargoShipVehicleTransportOptions[1].value) {
                seaFreightSizeVal = vehicleTransport.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(vehicleName[1]) && props.master.seaFreightCargoShipSizeMaster[vehicleName[1]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[vehicleName[1]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(vehicleName[1]) && props.master.seaFreightCargoShipSizeMaster[vehicleName[1]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[vehicleName[1]].scope3 : 0;
            } else if (vehicleTransport.value === cargoShipVehicleTransportOptions[2].value) {
                seaFreightSizeVal = vehicleTransport.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(vehicleName[2]) && props.master.seaFreightCargoShipSizeMaster[vehicleName[2]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[vehicleName[2]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(vehicleName[2]) && props.master.seaFreightCargoShipSizeMaster[vehicleName[2]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[vehicleName[2]].scope3 : 0;
            }
        } else if (inputName === "RoRo-Ferry") {
            if (roroFerry.value === cargoShipRoroFerryOptions[0].value) {
                seaFreightSizeVal = roroFerry.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(roroName[0]) && props.master.seaFreightCargoShipSizeMaster[roroName[0]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[roroName[0]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(roroName[0]) && props.master.seaFreightCargoShipSizeMaster[roroName[0]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[roroName[0]].scope3 : 0;
            } else if (roroFerry.value === cargoShipRoroFerryOptions[1].value) {
                seaFreightSizeVal = roroFerry.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(roroName[1]) && props.master.seaFreightCargoShipSizeMaster[roroName[1]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[roroName[1]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(roroName[1]) && props.master.seaFreightCargoShipSizeMaster[roroName[1]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[roroName[1]].scope3 : 0;
            } else if (roroFerry.value === cargoShipRoroFerryOptions[2].value) {
                seaFreightSizeVal = roroFerry.value;
                wtt = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(roroName[2]) && props.master.seaFreightCargoShipSizeMaster[roroName[2]].wtt !== undefined ? props.master.seaFreightCargoShipSizeMaster[roroName[2]].wtt : 0;
                scope3 = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(roroName[2]) && props.master.seaFreightCargoShipSizeMaster[roroName[2]].scope3 !== undefined ? props.master.seaFreightCargoShipSizeMaster[roroName[2]].scope3 : 0;
            }
        }

        let source = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[0]) && props.master.seaFreightCargoShipSizeMaster[bulkName[0]].source !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[0]].source : null;
        let year = props.master.seaFreightCargoShipSizeMaster.hasOwnProperty(bulkName[0]) && props.master.seaFreightCargoShipSizeMaster[bulkName[0]].year !== undefined ? props.master.seaFreightCargoShipSizeMaster[bulkName[0]].year : 0;
        let seaFreightTypeVal = inputName;
        let seaFreightActivityVal = cargoShipVal;
        if (distance || weightInTonnes > 0) {
            cf = footprintForSeaFreightCargoShipCalc(weightInTonnes, distance, wtt, scope3);
        }

        if (cargoShipVal === "cargoShip") {
            props.cFootprint({
                name: inputName,
                parent: "cargoShip",
                cf: { value: cf, weightInTonnes, distance, seaFreightActivityVal, seaFreightTypeVal, seaFreightSizeVal, wtt, scope3, year, source }
            });
        }
        setCarbonFootprint(cf);

    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if (tonnes !== undefined && tonnes !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (km !== undefined && km !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [tonnes, km, bulkCarrier, generalCargo, containerShip, vehicleTransport, roroFerry]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "BulkCarrier" ? `scrollSeaFreight${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollAviationFreight"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#009dc4", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label} </span>
                    <Row>
                        <Col lg={9} md={6} sm={14}>
                            <div className={`${id}-${inputName}-seaTankerCollapse`} styles={{ width: "100%" }}>
                                {inputName === 'Bulk carrier' ? (
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onBulkCarrierChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                    errors={errors} loadOptions={getBulkCarrierOptions} label="Size" defaultOptions={true}
                                                    formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                            )}
                                            control={control}
                                            name={`${id}-${inputName}-cargoShipBulkCarrierOptions-select`}
                                            defaultValue={bulkCarrier}
                                        />
                                    ) : (
                                        <></>
                                    )
                                ) : inputName === 'General cargo' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onGeneralCargoChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getGeneralCargoOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-cargoShipGeneralCargoOptions-select`}
                                                defaultValue={generalCargo}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : inputName === 'Container ship' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onContainerShipChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getContainerShipOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-cargoShipContainerShipOptions-select`}
                                                defaultValue={containerShip}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : inputName === 'Vehicle transport' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onVehicleTransportChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getVehicleTransportOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-cargoShipVehicleTransportOptions-select`}
                                                defaultValue={vehicleTransport}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : inputName === 'RoRo-Ferry' ? (
                                    control !== undefined ?
                                        (
                                            <Controller
                                                render={({ onChange, value, name }) => (
                                                    <Select id={name} name={name} value={value} onChange={(val) => { onRoroFerrryChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }}
                                                        errors={errors} loadOptions={getRoroFerryOptions} label="Size" defaultOptions={true}
                                                        formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#d7dbdc" />
                                                )}
                                                control={control}
                                                name={`${id}-${inputName}-cargoShipRoroFerryOptions-select`}
                                                defaultValue={roroFerry}
                                            />
                                        )
                                        : (
                                            <></>
                                        )
                                ) : (
                                    <></>
                                )}
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-tonnes`} name={`${id}-${inputName}-tonnes`} label="Tonnes" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "1%", marginLeft: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTonnesChange(e.target.value)} />
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-km`} name={`${id}-${inputName}-km`} label= "Nautical Miles" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "1%", marginLeft: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputKmChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col></Col>
                        <Col lg={2} md={3} sm={12} styles={{ paddingBottom: "500px" }}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{
                        color: '#009dc4',
                        backgroundColor: '#009dc4',
                        height: .5,
                        borderColor: '#009dc4'
                    }} />

                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseSeaFreightCargoShipCustom));