import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import logo from "./cg.png";
import ad from "./ad.png";
import UbuntuB from "../../fonts/Ubuntu-B.ttf";
import UbuntuN from "../../fonts/Ubuntu-R.ttf";
import UbuntuMonoB from "../../fonts/UbuntuMono-B.ttf";
import UbuntuMonoN from "../../fonts/UbuntuMono-R.ttf";
import { validateClaimsRequest } from 'msal/lib-commonjs/AuthenticationParameters';
import { initialImpactPlane } from '../../calculatorFunctions/delivery_calc';

Font.register({
    family: 'Ubuntu',
    fonts: [
        {
            src: UbuntuN,
            fontWeight: "normal",
            fontStyle: "normal"
        },
        {
            src: UbuntuB,
            fontWeight: "bold",
            fontStyle: "normal"
        }
    ]
});

Font.register({
    family: 'UbuntuMono',
    fonts: [
        {
            src: UbuntuMonoN,
            fontWeight: "normal",
            fontStyle: "normal"
        },
        {
            src: UbuntuMonoB,
            fontWeight: "bold",
            fontStyle: "normal"
        }
    ]
});

const getTextWidth = (text, font, letterSpacing) => {
    const canvas = document.createElement('canvas');
    canvas.style.letterSpacing = letterSpacing;
    const context = canvas.getContext('2d');
    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
}

const camelcaseToSpaceSeparated = (text) => {
    return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, "$1").toString().toLowerCase();
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const styles = StyleSheet.create({
    body: {
        position: "relative",
    },
    logo: {
        position: "absolute",
        top: 56,
        left: 73,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 158,
        height: 36
    },
    logo1: {
        position: "absolute",
        top: 20,
        left: 73,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 158,
        height: 36
    },
    chart1: {
        position: "absolute",
        top: 172,
        left: 73,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 220,
        height: 220
    },
    chart2: {
        position: "absolute",
        top: 172,
        left: 300,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 220,
        height: 220
    },
    chart3: {
        position: "absolute",
        top: 396,
        left: 73,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 220,
        height: 220
    },
    chart4: {
        position: "absolute",
        top: 396,
        left: 300,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 220,
        height: 220
    },
    chart5: {
        position: "absolute",
        top: 200,
        left: 100,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 350,
        height: 220
    },
    title: {
        position: "absolute",
        left: 73,
        top: 116,
        fontFamily: "Ubuntu",
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: 17,
        lineHeight: 25,
        letterSpacing: -0.374,
        color: "#0070AD",
    },
    title1: {
        position: "absolute",
        left: 73,
        top: 68,
        fontFamily: "Ubuntu",
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: 17,
        lineHeight: 25,
        letterSpacing: -0.374,
        color: "#0070AD",
    },
    titleDDAssump: {
        position: "absolute",
        left: 73,
        top: 50,
        fontFamily: "Ubuntu",
        fontWeight: "bold",
        fontStyle: "normal",
        fontSize: 17,
        lineHeight: 25,
        letterSpacing: -0.374,
        color: "#0070AD",
    },
    ldl: {
        position: "absolute",
        right: 73,
        top: 151,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    sector: {
        position: "absolute",
        right: 73,
        top: 163,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    thor: {
        position: "absolute",
        right: 73,
        top: 175,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    projectId: {
        position: "absolute",
        left: 73,
        top: 186,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    e3DId: {
        position: "absolute",
        left: 73,
        top: 200,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    projectName: {
        position: "absolute",
        left: 73,
        top: 214,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    clientName: {
        position: "absolute",
        left: 73,
        top: 228,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    country: {
        position: "absolute",
        left: 73,
        top: 242,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    leftPanel: {
        position: "absolute",
        left: 73,
        top: 256,
        width: 230
    },
    description: {
        position: "relative",
        margin: 0,
        padding: 0,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 0,
        letterSpacing: 0.18,
        color: "#2B0A3D",
        justifyContent: "flex-start"
    },
    date: {
        marginTop: 14,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        lineHeight: 10,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    ad: {
        position: "absolute",
        top: 623,
        left: 73,
        paddingVertical: 1,
        paddingHorizontal: 1,
        width: 344,
        height: 53
    },
    disclaimer: {
        flexDirection: "row",
        position: "absolute",
        width: 345,
        height: 96,
        left: 73,
        top: 688,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 7,
        lineHeight: 0,
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    stmt: {
        position: "relative",
        width: 450,
        left: 73,
        top: 173,
    },
    stmt1: {
        position: "relative",
        width: 450,
        left: 73,
        top: 110,
    },
    stmt2: {
        position: "relative",
        width: 450,
        left: 73,
        top: 130,
    },
    stmtDDAssump: {
        position: "relative",
        width: 450,
        left: 73,
        top: 80,
    },
    stmtPdf: {
        position: "relative",
        width: 450,
        left: 73,
        top: 50,
    },
    stmtHeader: {
        height: 16,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "bold",
        paddingHorizontal: 8,
        backgroundColor: "#0070AD",
        color: "#ffffff",
        fontSize: 10

    },
    stmtHeader2: {
        height: 14,
        marginTop: -1,
        paddingHorizontal: 8,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        backgroundColor: "#0070AD",
        color: "#ffffff",
        fontSize: 7
    },
    stmtContent: {
        height: "auto",
        color: "#2B0A3D"
    },
    stmtContentSub: {
        color: "#2B0A3D",
        fontSize: 7
    },
    summaryValue: {
        fontFamily: "UbuntuMono",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 24,
        textAlign: "right",
        letterSpacing: 0.216,
        color: "#0070AD"
    },
    summaryValueUnit: {
        fontFamily: "UbuntuMono",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 8,
        textAlign: "right",
        letterSpacing: 0.1,
        color: "#2B0A3D"
    },
    summaryText: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 10,
        textAlign: "right",
        letterSpacing: 0.4,
        color: "#2B0A3D",
        marginTop: 3
    },
    pageNumber: {
        flexDirection: "row",
        position: "absolute",
        top: 810,
        right: 54,
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 7,
        color: "#2B0A3D"
    },
    assumptionHeight: {
        height: 300
    },
    pdfDate: {
        marginTop: '20px',
        fontSize: 8,
        marginLeft: "450px",
        fontWeight: "bold"
    }
});

const calculatorColor = {
    electricity: { color: "#FCFDC0" },
    fuel: { color: "#FDDFB2" },
    paper: { color: "#FFFFF0" },
    plastic: { color: "#40A0B5" },
    metal: { color: "#d7dbdc" },
    transport: { color: "#f37d7d" },
    electricalItems: { color: "#228C22" },
    aviation: { color: "#6f91ad" },
    seaFreight: { color: "#009dc4" },
    dataCentre: { color: "#C2FFEB" },
    waste: { color: "#40A0B5" },
    delivery: { color: "#ECECEC" },
    people: { color: "#ECECEC" },
    water: { color: "#A1D0FF" },
    detailedDelivery: { color: "#ECECEC" },
    transport: { color: "#f37d7d" }
}

const monthName = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
}
const CreatePDF = (props) => {
    const { data, waterInput, fuelInput, timeStampPdfVal, ddAssumpData, dcAssumpData, simpleInput, plasticInput, metalInput, transportInput, electricalItemsInput, aviationInput, seaFreightInput, electricityInput, dataCentreInput, carboninput, detailCf, initialimpacttonnes, targetimpacttonnes, reductiontoinitialtargetimpact, actualimpacttonnes, reductiontoinitial, reductiontotarget, characterdetails } = props;

    const { report, sourceYear, imgUri, commonActiveTab } = data;
    const deliveryBreakdown = {
        footprintForBusinessTravel: 0,
        footprintForCommuting: 0,
        footprintForDataCentre: 0,
        footprintForOffice: 0,
        footprintForWfh: 0,
        footprintForGoodsAndServices: 0
    };
    const ldlWidth = getTextWidth(report.deliveryLine.name, 9, 0.1);
    const sectorWidth = getTextWidth(report.sector.name, 9, 0.1);
    const thorIdWidth = getTextWidth(report.thorId, 9, 0.1);
    var rWidth = 0;

    let ecsSubTotal = 0;
    let piSubTotal = 0;
    let detSubTotal = 0;
    let startDate = new Date(report.startDate);
    let endDate = new Date(report.endDate);

    if (ldlWidth > sectorWidth) {
        if (ldlWidth > thorIdWidth) {
            rWidth = ldlWidth;
        } else {
            rWidth = thorIdWidth;
        }
    } else {
        if (sectorWidth > thorIdWidth) {
            rWidth = sectorWidth;
        } else {
            rWidth = thorIdWidth;
        }
    }

    report.calculatorData.forEach(item => {
        if (item.name === "people") {
            item.data.forEach(factor => {
                deliveryBreakdown.footprintForBusinessTravel += factor.footprintForBusinessTravel;
                deliveryBreakdown.footprintForCommuting += factor.footprintForCommuting;
                deliveryBreakdown.footprintForDataCentre += factor.footprintForDataCentre;
                deliveryBreakdown.footprintForOffice += factor.footprintForOffice;
                deliveryBreakdown.footprintForWfh += factor.footprintForWfh;
                deliveryBreakdown.footprintForGoodsAndServices += factor.footprintForGoodsAndServices;
            });
        }
    });

    const ecsData = report.calculatorData.map(item => {
        if (item.name == "fuel" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            const totalsByFuelType = {};
            Object.keys(item.data).forEach(k => {
                const fuelType = item.data[k].fuelType.name;
                const carbonFootprint = item.data[k].carbonFootprint;
                if (!totalsByFuelType[fuelType]) {
                    totalsByFuelType[fuelType] = 0;
                }
                totalsByFuelType[fuelType] += carbonFootprint;
            });
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {Object.entries(totalsByFuelType).map(([fuelType, total]) => (
                            <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{fuelType}</Text>
                                <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(total).toFixed(3))}</Text>
                            </View>
                        ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />
                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name == "plastic" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {
                            Object.keys(item.data).map(k => (
                                <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                    <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{item.data[k].plasticSourceType.name}</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.data[k].carbonFootprint).toFixed(3))}</Text>
                                </View>
                            ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />

                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name == "metal" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {
                            Object.keys(item.data).map(k => (
                                <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                    <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{item.data[k].metalSourceType.name}</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.data[k].carbonFootprint).toFixed(3))}</Text>
                                </View>
                            ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />
                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name == "transport" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {
                            Object.keys(item.data).map(k => (
                                <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                    <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{item.data[k].transportType.name}</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.data[k].carbonFootprint).toFixed(3))}</Text>
                                </View>
                            ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />
                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name == "electricalItems" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {
                            Object.keys(item.data).map(k => (
                                <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                    <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{item.data[k].electricalItemsSourceType.name}</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.data[k].carbonFootprint).toFixed(3))}</Text>
                                </View>
                            ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />
                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name == "aviation" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {
                            Object.keys(item.data).map(k => (
                                <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                    <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{item.data[k].aviationDistanceType.name}</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.data[k].carbonFootprint).toFixed(3))}</Text>
                                </View>
                            ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />
                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name == "seaFreight" && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        {
                            Object.keys(item.data).map(k => (
                                <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 3, paddingHorizontal: 8 }}>
                                    <Text style={{ paddingTop: "4px", fontSize: 6, right: 107, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 7, left: 10 }}>{item.data[k].seaFreightType.name}</Text>
                                    <Text style={{ paddingTop: "2px", fontSize: 9, right: 2, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.data[k].carbonFootprint).toFixed(3))}</Text>
                                </View>
                            ))}
                        <hr style={{
                            top: "10px",
                            color: '#000000',
                            backgroundColor: '#000000',
                            height: .5,
                            borderColor: '#000000'
                        }} />
                        <Text style={{ paddingTop: "13px", textAlign: "right", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 9.5 }}>
                            {numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}
                        </Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if ((item.name == "water" || item.name == "paper" || item.name == "dataCentre" || item.name == "electricity") && item.totalCarbonFootprint !== 0) {
            ecsSubTotal += item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor[item.name].color, marginTop: 8, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 9 }}>
                            {item.totalCarbonFootprint > 0 ? `Reduction in ${camelcaseToSpaceSeparated(item.name)} consumption` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e/year</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(item.totalCarbonFootprint).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else {
            return false;
        }
    });

    let initialOfficeCF = 0;
    let initialWFHCF = 0;
    let initialPlaneCF = 0;
    let initialHighSpeedTrainCF = 0;
    let initialAverageTrainCF = 0;
    let initialTaxiCF = 0;
    let initialCarCF = 0;
    let initialPublicTransportCF = 0;
    let initialDigitalCF = 0;
    let initialFoodCF = 0;
    let initialWasteCF = 0;
    let initialHotelCF = 0;
    let targetOfficeCF = 0;
    let targetWFHCF = 0;
    let targetPlaneCF = 0;
    let targetHighSpeedTrainCF = 0;
    let targetAverageTrainCF = 0;
    let targetTaxiCF = 0;
    let targetCarCF = 0;
    let targetPublicTransportCF = 0;
    let targetDigitalCF = 0;
    let targetFoodCF = 0;
    let targetWasteCF = 0;
    let targetHotelCF = 0;
    let actualOfficeCF = 0;
    let actualWFHCF = 0;
    let actualPlaneCF = 0;
    let actualHighSpeedTrainCF = 0;
    let actualAverageTrainCF = 0;
    let actualTaxiCF = 0;
    let actualCarCF = 0;
    let actualPublicTransportCF = 0;
    let actualDigitalCF = 0;
    let actualFoodCF = 0;
    let actualWasteCF = 0;
    let actualHotelCF = 0;
    const ddData = report.calculatorData.map((item, i) => {
        if (item.name === "detailedDelivery" && item.totalCarbonFootprint !== 0 && piSubTotal === 0) {
            return (
                Object.keys(item.data).map(k => (
                    initialOfficeCF = item.data[k].initialOfficeCF + initialOfficeCF,
                    initialWFHCF = item.data[k].initialWfhCF + initialWFHCF,
                    initialPlaneCF = item.data[k].initialPlaneCF + initialPlaneCF,
                    initialHighSpeedTrainCF = item.data[k].initialHighSpeedTrainCF + initialHighSpeedTrainCF,
                    initialAverageTrainCF = item.data[k].initialAverageTrainCF + initialAverageTrainCF,
                    initialTaxiCF = item.data[k].initialTaxiCF + initialTaxiCF,
                    initialCarCF = item.data[k].initialCarCF + initialCarCF,
                    initialPublicTransportCF = item.data[k].initialPublicTransportCF + initialPublicTransportCF,
                    initialDigitalCF = item.data[k].initialDigitalCF + initialDigitalCF,
                    initialFoodCF = item.data[k].initialFoodCF + initialFoodCF,
                    initialWasteCF = item.data[k].initialWasteCF + initialWasteCF,
                    initialHotelCF = item.data[k].initialHotelCF + initialHotelCF,
                    targetOfficeCF = item.data[k].targetOfficeCF + targetOfficeCF,
                    targetWFHCF = item.data[k].targetWfhCF + targetWFHCF,
                    targetPlaneCF = item.data[k].targetPlaneCF + targetPlaneCF,
                    targetHighSpeedTrainCF = item.data[k].targetHighSpeedTrainCF + targetHighSpeedTrainCF,
                    targetAverageTrainCF = item.data[k].targetAverageTrainCF + targetAverageTrainCF,
                    targetTaxiCF = item.data[k].targetTaxiCF + targetTaxiCF,
                    targetCarCF = item.data[k].targetCarCF + targetCarCF,
                    targetPublicTransportCF = item.data[k].targetPublicTransportCF + targetPublicTransportCF,
                    targetDigitalCF = item.data[k].targetDigitalCF + targetDigitalCF,
                    targetFoodCF = item.data[k].targetFoodCF + targetFoodCF,
                    targetWasteCF = item.data[k].targetWasteCF + targetWasteCF,
                    targetHotelCF = item.data[k].targetHotelCF + targetHotelCF,
                    actualOfficeCF = item.data[k].actualOfficeCF + actualOfficeCF,
                    actualWFHCF = item.data[k].actualWfhCF + actualWFHCF,
                    actualPlaneCF = item.data[k].actualPlaneCF + actualPlaneCF,
                    actualHighSpeedTrainCF = item.data[k].actualHighSpeedTrainCF + actualHighSpeedTrainCF,
                    actualAverageTrainCF = item.data[k].actualAverageTrainCF + actualAverageTrainCF,
                    actualTaxiCF = item.data[k].actualTaxiCF + actualTaxiCF,
                    actualCarCF = item.data[k].actualCarCF + actualCarCF,
                    actualPublicTransportCF = item.data[k].actualPublicTransportCF + actualPublicTransportCF,
                    actualDigitalCF = item.data[k].actualDigitalCF + actualDigitalCF,
                    actualFoodCF = item.data[k].actualFoodCF + actualFoodCF,
                    actualWasteCF = item.data[k].actualWasteCF + actualWasteCF,
                    actualHotelCF = item.data[k].actualHotelCF + actualHotelCF
                ))
            )
        }
    })

    const piData = report.calculatorData.map(item => {
        if (item.name === "detailedDelivery" && item.totalCarbonFootprint !== 0 && piSubTotal === 0) {
            piSubTotal = item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={styles.stmt}>
                        <View style={{ ...styles.stmtHeader, marginTop: "-200px", height: 25, left: -73 }}>
                            <Text style={{ paddingTop: "3px" }}>Project implementation</Text>
                            <Text style={{ paddingTop: "3px", right: 28, position: "absolute" }}>Amount</Text>
                        </View>
                        <View style={{ ...styles.stmtHeader2, left: -73 }}>
                            <Text style={{ paddingTop: "-3px", left: 7 }}>The anticipated one-time effect of Capgemini implementing the project would be​</Text>
                        </View>
                    </View>
                    <View style={{ backgroundColor: "#ECECEC", marginTop: 20, height: "250px" }}>
                        <View>
                            <Text style={{ paddingTop: "10px", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", fontSize: 8, left: 25 }}>Project Impact</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 220, position: "absolute", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", }}>Unit</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", }}>Initial</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", }}>Target</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute", fontFamily: "Ubuntu", fontStyle: "normal", fontWeight: "bold", }}>Actual</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Office</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialOfficeCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetOfficeCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualOfficeCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>WFH</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialWFHCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetWFHCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualWFHCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Plane</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialPlaneCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetPlaneCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualPlaneCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>High Speed Train</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialHighSpeedTrainCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetHighSpeedTrainCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualHighSpeedTrainCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Average Train</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialAverageTrainCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetAverageTrainCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualAverageTrainCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Taxi</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialTaxiCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetTaxiCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualTaxiCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Car</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialCarCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetCarCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualCarCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Public Transport</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialPublicTransportCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetPublicTransportCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualPublicTransportCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Digital</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialDigitalCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetDigitalCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualDigitalCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Food</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialFoodCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetFoodCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualFoodCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Waste</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialWasteCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetWasteCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualWasteCF).toFixed(3))}</Text>
                        </View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 8, left: 25 }}>Hotel</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 195, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 120, position: "absolute" }}>{numberWithCommas(parseFloat(initialHotelCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 65, position: "absolute" }}>{numberWithCommas(parseFloat(targetHotelCF).toFixed(3))}</Text>
                            <Text style={{ paddingTop: "10px", fontSize: 8, right: 10, position: "absolute" }}>{numberWithCommas(parseFloat(actualHotelCF).toFixed(3))}</Text>
                        </View>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, minHeight: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear[item.name]["sources"]).length > 0 && sourceYear[item.name]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear[item.name]["years"].map(year => {
                                            return sourceYear[item.name]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear[item.name]["years"][sourceYear[item.name]["years"].length - 1] === year && sourceYear[item.name]["sources"][year].source[sourceYear[item.name]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "people" && piSubTotal === 0) {
            piSubTotal = item.totalCarbonFootprint;
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtHeader, marginTop: 0 }}>
                        <Text style={{ paddingTop: "3px" }}>Project implementation</Text>
                        <Text style={{ paddingTop: "3px", right: 114, position: "absolute" }}>Unit</Text>
                        <Text style={{ paddingTop: "3px", right: 8, position: "absolute" }}>Amount</Text>
                    </View>
                    <View style={styles.stmtHeader2}>
                        <Text style={{ paddingTop: "3px" }}>The anticipated one-time effect of Capgemini implementing the project would be​</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: 8, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 9 }}>Project energy consumption &amp; digital footprint</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: -1, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 7, paddingLeft: "10px" }}>Business Travel</Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(deliveryBreakdown.footprintForBusinessTravel).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: -1, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 7, paddingLeft: "10px" }}>Commute</Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(deliveryBreakdown.footprintForCommuting).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: -1, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 7, paddingLeft: "10px" }}>Data Centre</Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(deliveryBreakdown.footprintForDataCentre).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: -1, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 7, paddingLeft: "10px" }}>Office</Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(deliveryBreakdown.footprintForOffice).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: -1, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 7, paddingLeft: "10px" }}>Work From Home (WFH)</Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(deliveryBreakdown.footprintForWfh).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: calculatorColor["delivery"].color, marginTop: -1, height: 16, paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 7, paddingLeft: "10px" }}>Goods and Services</Text>
                        <Text style={{ paddingTop: "5px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e</Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, right: 8, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal" }}>{numberWithCommas(parseFloat(deliveryBreakdown.footprintForGoodsAndServices).toFixed(3))}</Text>
                    </View>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, height: 14, paddingHorizontal: 8 }}>
                        {
                            (
                                sourceYear !== undefined && sourceYear !== null && Object.keys(sourceYear["delivery"]["sources"]).length > 0 && sourceYear["delivery"]["years"].length > 0
                            ) ? (
                                <Text style={{ paddingTop: "5px", width: "250px" }}>This carbon saving was calculated using data from
                                    {
                                        sourceYear["delivery"]["years"].map(year => {
                                            return sourceYear["delivery"]["sources"][year].source.map(source => ` ${source} ${year}${sourceYear["delivery"]["years"][sourceYear["delivery"]["years"].length - 1] === year && sourceYear["delivery"]["sources"][year].source[sourceYear["delivery"]["sources"][year].source.length - 1] === source ? "" : ","}`)
                                        })
                                    }
                                </Text>
                            ) : (
                                <></>
                            )
                        }
                    </View>
                </React.Fragment>
            )

        }
        else {
            return false;
        }
    });


    var initialPlane;
    var actualPlane;
    var actualOffice;
    var actualWFH, initialTotalCF, targetTotalCF, actualTotalCF;

    const planeData = carboninput.data.map(item => {
        Object.keys(item).map(k => {
            initialPlane = item[k].initial.Plane
            actualPlane = item[k].actual.Plane
            actualOffice = item[k].actual.Office
            actualWFH = item[k].actual.WFH
            initialTotalCF = item[k].allCFToalValues.initialTotalCF
            targetTotalCF = item[k].allCFToalValues.targetTotalCF
            actualTotalCF = item[k].allCFToalValues.actualTotalCF
            return actualPlane, initialPlane, actualOffice, actualWFH, initialTotalCF, targetTotalCF, actualTotalCF
        })
    })
    var uptoTargetCF = initialTotalCF + targetTotalCF;
    var uptoActualCF = initialTotalCF + targetTotalCF + actualTotalCF;



    const siData = report.calculatorData.map(item => {
        if (item.name === "detailedDelivery") {
            return (
                <React.Fragment key={`report-${item.name}`}>
                    {item.totalCarbonFootprint > 0 ?
                        <View style={{ ...styles.stmtContentSub, height: 19, marginTop: 10, paddingHorizontal: 0 }}>
                            <Text style={{ paddingTop: "0px", fontSize: 9, right: 114, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Sub Total</Text>
                            <Text style={{ paddingTop: "14px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", lineHeight: 0.1 }}>for the duration of the project</Text>
                            <Text style={{ padding: "4px 8px", width: 100, textAlign: "right", fontSize: 12, right: 0, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", backgroundColor: "#0070AD", color: "#ffffff" }}>
                                {commonActiveTab === "actual" && actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs" ? <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(-piSubTotal).toFixed(3))}</> : <>{numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-actualTotalCF).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(-piSubTotal).toFixed(3))}</> : <>{numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(actualTotalCF).toFixed(3))}</>}</>}</> :
                                    <>{targetTotalCF > 0 ? <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(-piSubTotal).toFixed(3))}</> : <>{numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-targetTotalCF).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(-piSubTotal).toFixed(3))}</> : <>{numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(targetTotalCF).toFixed(3))}</>}</>}</> :
                                        <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(-piSubTotal).toFixed(3))}</> : <>{numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-initialTotalCF).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(-piSubTotal).toFixed(3))}</> : <>{numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(initialTotalCF).toFixed(3))}</>}</>}</>
                                    }</>}

                            </Text>
                        </View>
                        : null}
                </React.Fragment >
            )
        }
        else if (item.name === "people") {
            return (
                <React.Fragment key={`report-${item.name}`}>
                    {item.totalCarbonFootprint > 0 ?
                        <View style={{ ...styles.stmtContentSub, height: 19, marginTop: 10, paddingHorizontal: 0 }}>
                            <Text style={{ paddingTop: "0px", fontSize: 9, right: 114, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Sub Total</Text>
                            <Text style={{ paddingTop: "14px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", lineHeight: 0.1 }}>for the duration of the project</Text>
                            <Text style={{ padding: "4px 8px", width: 100, textAlign: "right", fontSize: 12, right: 0, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", backgroundColor: "#0070AD", color: "#ffffff" }}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(-piSubTotal).toFixed(3)) : numberWithCommas(parseFloat(piSubTotal).toFixed(3))}</Text>
                        </View>
                        : null}
                </React.Fragment>
            )
        }
        else {
            return false
        }

    })


    const emissionData = report.calculatorData.map(item => {
        if (item.name === "detailedDelivery" && item.totalCarbonFootprint !== null) {
            return (
                <React.Fragment key={`report-${item.name}`}>
                    {item.totalCarbonFootprint > 0 ?
                        <View style={{ right: 75 }}>
                            <Text style={styles.summaryValue}>
                                {ecsSubTotal < 0 && piSubTotal > 0 ?
                                    <>{numberWithCommas(parseFloat(-(ecsSubTotal - piSubTotal).toFixed(3)))}</>
                                    :
                                    <>
                                        {report.noOfYears == null ?
                                            <>
                                                {commonActiveTab === "actual" && actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs" ? <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(((ecsSubTotal) - actualTotalCF)).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - actualTotalCF)).toFixed(3))}</>}</>}</> :
                                                    <>{targetTotalCF > 0 ? <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(((ecsSubTotal) - targetTotalCF)).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - targetTotalCF)).toFixed(3))}</>}</>}</> :
                                                        <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(((ecsSubTotal) - initialTotalCF)).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal) - initialTotalCF)).toFixed(3))}</>}</>}</>
                                                    }</>}
                                            </>
                                            :
                                            <>
                                                {commonActiveTab === "actual" && actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs" ? <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - actualTotalCF)).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - actualTotalCF)).toFixed(3))}</>}</>}</> :
                                                    <>{targetTotalCF > 0 ? <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - targetTotalCF)).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - targetTotalCF)).toFixed(3))}</>}</>}</> :
                                                        <>{ecsSubTotal > 0 ? <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - initialTotalCF)).toFixed(3))}</>}</> : <>{item.data.length > 1 ? <>{ecsSubTotal > 0 ? <>{numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</>}</> : <>{numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - initialTotalCF)).toFixed(3))}</>}</>}</>
                                                    }</>}
                                            </>
                                        }
                                    </>
                                }
                            </Text>
                            {ecsSubTotal > 0 ?
                                <>
                                    {report.noOfYears == null ?
                                        <Text style={styles.summaryValueUnit}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e / year</Text>
                                        :
                                        <Text style={styles.summaryValueUnit}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e / {report.noOfYears} years</Text>
                                    }
                                </>
                                :
                                <Text style={styles.summaryValueUnit}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e </Text>
                            }
                            <Text style={styles.summaryText}>{ecsSubTotal > 0 ? "Estimated net savings" : "Estimated delivery emission"}</Text>
                        </View>
                        :
                        <View style={{ right: 75 }}>
                            {ecsSubTotal < 0 ?
                                <>
                                    {(ecsSubTotal < 0 && piSubTotal > 0) ?
                                        <Text style={styles.summaryValue}>{numberWithCommas(parseFloat(-(ecsSubTotal - piSubTotal).toFixed(3)))}</Text>
                                        :
                                        <Text style={styles.summaryValue}>{numberWithCommas(parseFloat((-ecsSubTotal).toFixed(3)))}</Text>
                                    }
                                </>
                                :
                                <>
                                    {report.noOfYears == null ?
                                        <Text style={styles.summaryValue}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(((ecsSubTotal) - piSubTotal)).toFixed(3)) : numberWithCommas(parseFloat(-((ecsSubTotal) - piSubTotal)).toFixed(3))}</Text>
                                        :
                                        <Text style={styles.summaryValue}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3)) : numberWithCommas(parseFloat(-((ecsSubTotal * report.noOfYears) - piSubTotal)).toFixed(3))}</Text>
                                    }
                                </>
                            }
                            {ecsSubTotal > 0 ?
                                <>
                                    {report.noOfYears == null ?
                                        <Text style={styles.summaryValueUnit}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e / year</Text>
                                        :
                                        <Text style={styles.summaryValueUnit}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e / {report.noOfYears} years</Text>
                                    }
                                </>
                                :
                                <Text style={styles.summaryValueUnit}>tonnes CO<Text style={{ fontSize: 4 }}>2</Text>e </Text>
                            }
                            <Text style={styles.summaryText}>{ecsSubTotal > 0 ? "Estimated net savings" : "Estimated delivery emission"}</Text>
                        </View>
                    }

                </React.Fragment >
            )
        }
        else {
            return false
        }

    })

    var initialPlaneData;
    if (initialPlane > "0 hrs" && imgUri) {
        initialPlaneData = (commonActiveTab === "initial" || commonActiveTab === "target" || commonActiveTab === "actual") && (
            <Image style={styles.chart1} src={imgUri[0]} />
        );
    } else {
        initialPlaneData = null;
    }

    let actualPlaneData;
    if ((initialPlane > "0 hrs" || actualPlane > "0 hrs") && imgUri) {
        actualPlaneData = commonActiveTab === "actual" && (
            <Image style={styles.chart1} src={imgUri[2]} />
        )
    } else {
        actualPlaneData = null;
    }

    const graphData = imgUri !== undefined && Object.keys(imgUri).filter(key => key === "0").map(key => {
        if (imgUri[0] !== undefined && imgUri[1] !== undefined && imgUri[2] !== undefined && imgUri[3] !== undefined) {
            return (
                <View key={`graph-${key}`}>
                    <Text style={styles.title}>Detailed Delivery Graphs</Text>
                    {initialPlaneData ? <>{initialPlaneData}{commonActiveTab === "target" || commonActiveTab === "actual" && (
                        <Image
                            style={styles.chart2}
                            src={imgUri[1]}
                        />
                    )}</> : commonActiveTab === "target" || commonActiveTab === "actual" && (
                        <Image
                            style={styles.chart5}
                            src={imgUri[1]}
                        />
                    )}


                    {commonActiveTab === "actual" && (actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs") ?
                        <>
                            {actualPlaneData ? <>{actualPlaneData}{commonActiveTab === "actual" && (
                                <Image
                                    style={styles.chart2}
                                    src={imgUri[3]}
                                />
                            )}</> : commonActiveTab === "actual" && (
                                <Image
                                    style={styles.chart5}
                                    src={imgUri[3]}
                                />
                            )}
                        </>
                        :
                        <>
                            {initialPlaneData ? <>{initialPlaneData}{commonActiveTab === "target" && (
                                <Image
                                    style={styles.chart2}
                                    src={imgUri[1]}
                                />
                            )}</> : commonActiveTab === "target" && (
                                <Image
                                    style={styles.chart5}
                                    src={imgUri[1]}
                                />
                            )}
                        </>
                    }
                </View>
            )
        } else {
            return false;
        }
    });


    let countryCfData = 0;
    if (detailCf) {
        countryCfData = Object.keys(detailCf).map(cfval => {
            return detailCf[cfval].cf
        })
    }

    let totalInitial = 0;
    carboninput.data.map((item, i) => {
        Object.keys(item).map(k => (
            totalInitial = item[k].allCFToalValues.initialTotalCF + totalInitial
        ))
    })
    let totalTarget = 0;
    carboninput.data.map((item, i) => {
        Object.keys(item).map(k => (
            totalTarget = item[k].allCFToalValues.targetTotalCF + totalTarget
        ))
    })
    let totalReductionInitialPer = 0;
    let totalReductionInitial = 0;
    carboninput.data.map((item, i) => {
        Object.keys(item).map(k => (
            totalReductionInitial = item[k].allCFToalValues.reductionInitial + totalReductionInitial
        ))

    })
    totalReductionInitialPer = (totalInitial - totalTarget) / (totalInitial) * 100
    let totalActual = 0;
    carboninput.data.map((item, i) => {
        Object.keys(item).map(k => (
            totalActual = item[k].allCFToalValues.actualTotalCF + totalActual
        ))
    })
    let totalInitialReduction = 0;
    let totalInitialReductionPer = 0;
    carboninput.data.map((item, i) => {
        Object.keys(item).map(k => (
            totalInitialReduction = item[k].allCFToalValues.initialreduction + totalInitialReduction
        ))
    })
    totalInitialReductionPer = (totalInitial - totalActual) / (totalInitial) * 100

    let totalTargetReduction = 0;
    let totalTargetReductionPer = 0;
    carboninput.data.map((item, i) => {
        Object.keys(item).map(k => (
            totalTargetReduction = item[k].allCFToalValues.targetreduction + totalTargetReduction
        ))
    })
    totalTargetReductionPer = (totalTarget - totalActual) / (totalTarget) * 100

    const pdfData = carboninput.data.map((item, i) => {
        if (item) {
            return (
                <React.Fragment key={`report-${i}`}>
                    {
                        Object.keys(item).map(k => (
                            <View style={styles.stmtContent}>
                                {commonActiveTab === "actual" ? null : <><Text style={{ paddingTop: "450px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated initial impact :  </Text><Text style={{ paddingTop: "450px", fontSize: 7.4, right: 380, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(totalInitial).toFixed(3))} tonnes</Text></>}
                                {commonActiveTab === "actual" ? <><Text style={{ paddingTop: "465px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated initial impact :  </Text><Text style={{ paddingTop: "465px", fontSize: 7.4, right: 380, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(totalInitial).toFixed(3))} tonnes</Text></> : <><Text style={{ paddingTop: "465px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated target impact :  </Text><Text style={{ paddingTop: "465px", fontSize: 7.4, right: 380, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(totalTarget).toFixed(3))} tonnes</Text></>}
                                {commonActiveTab === "actual" ? <><Text style={{ paddingTop: "480px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated target impact :  </Text><Text style={{ paddingTop: "480px", fontSize: 7.4, right: 380, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(totalTarget).toFixed(3))} tonnes</Text></> : <><Text style={{ paddingTop: "480px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated percentage {reductiontoinitialtargetimpact > 0 ? "reduction" : "increase"} target compared to initial :</Text><Text style={{ paddingTop: "480px", color: (reductiontoinitialtargetimpact > 0) ? "#008000" : "#FF0000", fontSize: 7.4, right: 295, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(Math.abs(totalReductionInitialPer)).toFixed(0))}%</Text></>}
                                {commonActiveTab === "actual" && (actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs") ? <><Text style={{ paddingTop: "495px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated actual impact :  </Text><Text style={{ paddingTop: "495px", fontSize: 7.4, right: 380, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(totalActual).toFixed(3))} tonnes</Text></> : null}
                                {commonActiveTab === "actual" && (actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs") ? <><Text style={{ paddingTop: "510px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated percentage {reductiontoinitial > 0 ? "reduction" : "increase"} actual compared to initial :</Text><Text style={{ paddingTop: "510px", color: (reductiontoinitial > 0) ? "#008000" : "#FF0000", fontSize: 7.4, right: 295, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(Math.abs(totalInitialReductionPer)).toFixed(0))}%</Text></> : null}
                                {commonActiveTab === "actual" && (actualOffice > "0%" || actualWFH > "0%" || actualPlane > "0hrs") ? <><Text style={{ paddingTop: "525px", fontSize: 7.4, left: 70, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Estimated percentage {reductiontotarget > 0 ? "reduction" : "increase"} actual compared to target :</Text><Text style={{ paddingTop: "525px", color: (reductiontotarget > 0) ? "#008000" : "#FF0000", fontSize: 7.4, right: 295, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{numberWithCommas(parseFloat(Math.abs(totalTargetReductionPer)).toFixed(0))}%</Text></> : null}
                            </View>
                        ))
                    }
                </React.Fragment >
            )
        }

        else {
            return false
        }

    })

    const inpuDataTab = carboninput.data.map((item, i) => {
        if (item) {
            return (
                <React.Fragment key={`report-${i}`}>
                    <Page>
                        <Image
                            style={styles.logo}
                            src={logo}
                        />
                        <Text style={{ position: "absolute", left: 70, top: 120, fontFamily: "Ubuntu", fontWeight: "bold", fontStyle: "normal", fontSize: 17, lineHeight: 25, letterSpacing: -0.374, color: "#0070AD", }}>Detailed Delivery Calculator Input Data</Text>
                        <View style={styles.stmt}>
                            <View>
                                <Text style={{ paddingTop: "0px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                                <View style={{ ...styles.stmtHeader, marginTop: 0, height: 20 }}>
                                    <Text style={{ paddingTop: "3px" }}>Project Data</Text>
                                    <Text style={{ paddingTop: "3px", right: 255, position: "absolute" }}>Initial Input Values</Text>
                                    <Text style={{ paddingTop: "3px", right: 110, position: "absolute" }}>Target Input Values</Text>
                                    <Text style={{ paddingTop: "3px", right: 8, position: "absolute" }}>Actual Input Values</Text>
                                </View>
                                {
                                    Object.keys(item).map(k => (
                                        <View style={{ backgroundColor: "#ECECEC" }}>
                                            <View>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, left: 5 }}>ftpCount</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.ftpCount}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.ftpCount}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.ftpCount}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, left: 5 }}>projectLength</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.projectLength}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.projectLength}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.projectLength}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, left: 5 }}>Office</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Office}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.Office}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Office}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, left: 5 }}>WFH</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.WFH}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.WFH}</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.WFH}</Text>
                                            </View>
                                            <View style={{ marginTop: "1px", marginBottom: "1px" }}>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>Plane</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Plane}</Text>
                                                <Text style={{ paddingTop: "10px", fontSize: 6, right: 110, position: "absolute" }}>Plane | HSTrain | Remote</Text>
                                                <Text style={{ paddingTop: "20px", fontSize: 8, right: 112, position: "absolute" }}>{item[k].target.Plane}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Plane}</Text>
                                            </View>
                                            <View style={{ marginTop: "1px", marginBottom: "1px" }}>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>HighSpeedTrain</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.highSpeedTrain}</Text>
                                                <Text style={{ paddingTop: "10px", fontSize: 6, right: 110, position: "absolute" }}> HSTrain | Remote</Text>
                                                <Text style={{ paddingTop: "20px", fontSize: 8, right: 112, position: "absolute" }}>{item[k].target.highSpeedTrain}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.highSpeedTrain}</Text>
                                            </View>
                                            <View style={{ marginTop: "1px", marginBottom: "1px" }}>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>AverageTrain</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.averageTrain}</Text>
                                                <Text style={{ paddingTop: "10px", fontSize: 6, right: 110, position: "absolute" }}> AverageTrain | Remote</Text>
                                                <Text style={{ paddingTop: "20px", fontSize: 8, right: 112, position: "absolute" }}>{item[k].target.averageTrain}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.averageTrain}</Text>
                                            </View>
                                            <View style={{ marginTop: "1px", marginBottom: "1px" }}>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>Taxi</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Taxi}</Text>
                                                <Text style={{ paddingTop: "10px", fontSize: 6, right: 110, position: "absolute" }}> Taxi|ElectricTaxi|PublicTransportC|Remote</Text>
                                                <Text style={{ paddingTop: "20px", fontSize: 8, right: 112, position: "absolute" }}>{item[k].target.Taxi}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Taxi}</Text>
                                            </View>
                                            <View style={{ marginTop: "1px", marginBottom: "1px" }}>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>Car</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Car}</Text>
                                                <Text style={{ paddingTop: "10px", fontSize: 6, right: 110, position: "absolute" }}> PrivateCar|PublicTransport|SoftTransport</Text>
                                                <Text style={{ paddingTop: "20px", fontSize: 8, right: 112, position: "absolute" }}>{item[k].target.Car}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Car}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>PublicTransport</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.publicTransport}</Text>
                                                <Text style={{ paddingTop: "10px", fontSize: 6, right: 110, position: "absolute" }}> PublicTransport|SoftTransport</Text>
                                                <Text style={{ paddingTop: "20px", fontSize: 8, right: 112, position: "absolute" }}>{item[k].target.publicTransport}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.publicTransport}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, left: 5 }}>Digital</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Digital}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.Digital}</Text>
                                                <Text style={{ paddingTop: "15px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Digital}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, left: 5 }}>Food</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Food}</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.Food}</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Food}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, left: 5 }}>Waste</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Waste}</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.Waste}</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Waste}</Text>
                                            </View>
                                            <View>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, left: 5 }}>Hotel</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 255, position: "absolute" }}>{item[k].initial.Hotel}</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 110, position: "absolute" }}>{item[k].target.Hotel}</Text>
                                                <Text style={{ paddingTop: "8px", fontSize: 8, right: 8, position: "absolute" }}>{item[k].actual.Hotel}</Text>
                                            </View>
                                        </View>
                                    ))
                                }
                                {
                                    Object.keys(item).map(k => (
                                        <View style={{ ...styles.stmtContentSub, height: 19, marginTop: 15, marginBottom: 10, paddingHorizontal: 0 }}>
                                            <Text style={{ paddingTop: "0px", fontSize: 9, left: 0, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1, marginTop: 3 }}>Sub Total</Text>
                                            <Text style={{ padding: "6px 8px", width: 100, textAlign: "right", fontSize: 12, right: 260, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", color: "#000000", marginBottom: "5px" }}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(-item[k].allCFToalValues.initialTotalCF).toFixed(3)) : numberWithCommas(parseFloat(item[k].allCFToalValues.initialTotalCF).toFixed(3))}</Text>
                                            <Text style={{ left: 95, top: 16 }}>tonnes CO2e/project duration</Text>
                                            <Text style={{ padding: "6px 8px", width: 100, textAlign: "right", fontSize: 12, right: 115, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", color: "#000000" }}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(-item[k].allCFToalValues.targetTotalCF).toFixed(3)) : numberWithCommas(parseFloat(item[k].allCFToalValues.targetTotalCF).toFixed(3))}</Text>
                                            <Text style={{ left: 240, top: 8 }}>tonnes CO2e/project duration</Text>
                                            <Text style={{ padding: "6px 8px", width: 100, textAlign: "right", fontSize: 12, right: 0, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", color: "#000000" }}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(-item[k].allCFToalValues.actualTotalCF).toFixed(3)) : numberWithCommas(parseFloat(item[k].allCFToalValues.actualTotalCF).toFixed(3))}</Text>
                                            <Text style={{ left: 355, top: 1 }}>tonnes CO2e/project duration</Text>
                                        </View>
                                    ))
                                }
                            </View>
                        </View>
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                    </Page>
                </React.Fragment>
            )
        }
    })

    // Check if need to display the section
    let display_dd_assump = false;
    let dd_country_disp = [];
    ddAssumpData.data.map((item, i) => {
        if (item) {
            Object.keys(item).map(k => {
                dd_country_disp[k] = false;
                if (item[k].initial.initialAssumptions.length > 0 || item[k].target.targetAssumptions.length > 0 || item[k].actual.actualAssumptions.length) {
                    display_dd_assump = true;
                    dd_country_disp[k] = true;
                }
            })
        }
    })

    const ddAssumptionData = ddAssumpData.data.map((item, i) => {
        if (dd_country_disp[Object.keys(item).toString()]) {
            return (
                <React.Fragment key={`report-${i}`}>
                    <View style={{ marginBottom: "50px" }}>
                        <Text style={{ paddingTop: "0px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                        <View style={{ ...styles.stmtHeader, marginTop: 0, height: 20 }}>
                            <Text style={{ paddingTop: "3px" }}>Calculator Assumptions </Text>
                            <Text style={{ paddingTop: "3px", right: 255, position: "absolute" }}>Details</Text>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View>
                                    {item[k].initial.initialAssumptions.length > 0 ?
                                        <View style={{ height: (item[k].initial.initialAssumptions.length <= 250 ? "50" : (item[k].initial.initialAssumptions.length > 250 && item[k].initial.initialAssumptions.length <= 500) ? "80" : (item[k].initial.initialAssumptions.length > 500 && item[k].initial.initialAssumptions.length <= 750) ? "120" : (item[k].initial.initialAssumptions.length > 750 && item[k].initial.initialAssumptions.length <= 1000) ? "150" : null) }}>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 3 }}>Initial Assumption</Text>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 160, width: 290, position: "absolute" }}>{item[k].initial.initialAssumptions}</Text>
                                        </View>
                                        : null}
                                    {item[k].target.targetAssumptions.length > 0 ?
                                        <View style={{ height: (item[k].target.targetAssumptions.length <= 250 ? "50" : (item[k].target.targetAssumptions.length > 250 && item[k].target.targetAssumptions.length <= 500) ? "80" : (item[k].target.targetAssumptions.length > 500 && item[k].target.targetAssumptions.length <= 750) ? "120" : (item[k].target.targetAssumptions.length > 750 && item[k].target.targetAssumptions.length <= 1000) ? "150" : null) }}>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 3 }}>Target Assumption</Text>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 160, width: 290, position: "absolute" }}>{item[k].target.targetAssumptions}</Text>
                                        </View>
                                        : null}
                                    {item[k].actual.actualAssumptions.length > 0 ?
                                        <View style={{ height: (item[k].actual.actualAssumptions.length <= 250 ? "50" : (item[k].actual.actualAssumptions.length > 250 && item[k].actual.actualAssumptions.length <= 500) ? "80" : (item[k].actual.actualAssumptions.length > 500 && item[k].actual.actualAssumptions.length <= 750) ? "120" : (item[k].actual.actualAssumptions.length > 750 && item[k].actual.actualAssumptions.length <= 1000) ? "150" : null) }}>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 3 }}>Actual Assumption</Text>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 160, width: 290, position: "absolute" }}>{item[k].actual.actualAssumptions}</Text>
                                        </View>
                                        : null}
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment>
            )
        } else {
            return false;
        }

    })

    // dc

    // Check if need to display the section
    let display_dc_assump = false;
    let dc_country_disp = [];
    dcAssumpData.data.map((item, i) => {
        if (item) {
            Object.keys(item).map(k => {
                dc_country_disp[k] = false;
                if (item[k].assumptions.assumptions.length > 0) {
                    display_dc_assump = true;
                    dc_country_disp[k] = true;
                }
            })
        }
    })

    const dcAssumptionData = dcAssumpData.data.map((item, i) => {
        if (dc_country_disp[Object.keys(item).toString()]) {
            // display_dc_assump = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View style={{ marginBottom: "30px" }}>
                        <Text style={{ paddingTop: "0px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                        <View style={{ ...styles.stmtHeader, marginTop: 0, height: 20 }}>
                            <Text style={{ paddingTop: "3px" }}>Calculator Assumptions </Text>
                            <Text style={{ paddingTop: "3px", right: 255, position: "absolute" }}>Details</Text>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View>
                                    {item[k].assumptions.assumptions.length > 0 ?
                                        <View style={{ height: (item[k].assumptions.assumptions.length <= 250 ? "50" : (item[k].assumptions.assumptions.length > 250 && item[k].assumptions.assumptions.length <= 500) ? "80" : (item[k].assumptions.assumptions.length > 500 && item[k].assumptions.assumptions.length <= 750) ? "120" : (item[k].assumptions.assumptions.length > 750 && item[k].assumptions.assumptions.length <= 1000) ? "150" : null) }}>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 3 }}>Data Centre Assumption</Text>
                                            <Text style={{ paddingTop: "5px", fontSize: 10, left: 160, width: 290, position: "absolute" }}>{item[k].assumptions.assumptions}</Text>
                                        </View>
                                        : null}
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment>
            )
        } else {
            return false;
        }

    })

    let display_assumption = false;
    const assumptionsData = report.calculatorData.map((item, key) => {
        if (item.name === "paper" && (item.data[0] && item.data[0].assumptions.length > 0)) { //  && item.totalCarbonFootprint !== 0 
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    {report.calculatorData[1].data[0].assumptions.length > 0 ?
                        <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.data[0].assumptions.length <= 250 ? "30" : (item.data[0].assumptions.length > 250 && item.data[0].assumptions.length <= 500) ? "70" : (item.data[0].assumptions.length > 500 && item.data[0].assumptions.length <= 750) ? "110" : (item.data[0].assumptions.length > 750 && item.data[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                            <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                                {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                            </Text>
                            <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{report.calculatorData[1].data[0].assumptions}</Text>
                        </View>
                        : null}
                </React.Fragment>
            )
        } else if (item.name === "fuel" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "water" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "electricity" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "plastic" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "metal" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "transport" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "electricalItems" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "aviation" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "seaFreight" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, marginBottom: 40, height: (item.dataAssumption[0].assumptions.length <= 250 ? "30" : (item.dataAssumption[0].assumptions.length > 250 && item.dataAssumption[0].assumptions.length <= 500) ? "70" : (item.dataAssumption[0].assumptions.length > 500 && item.dataAssumption[0].assumptions.length <= 750) ? "110" : (item.dataAssumption[0].assumptions.length > 750 && item.dataAssumption[0].assumptions.length <= 1000) ? "150" : null), paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption  ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else if (item.name === "people" && (item.dataAssumption && item.dataAssumption.length > 0 && item.dataAssumption[0].assumptions.length > 0)) {
            display_assumption = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, marginTop: 0, height: (characterdetails < 500) ? "200" : "100", paddingHorizontal: 8 }}>
                        <Text style={{ paddingTop: "3px", fontSize: 10, left: -5 }}>
                            {item.totalCarbonFootprint > 0 ? ` ${camelcaseToSpaceSeparated(item.name)} calculator assumption ` : `${camelcaseToSpaceSeparated(item.name).charAt(0).toUpperCase() + camelcaseToSpaceSeparated(item.name).slice(1)} consumption`}
                        </Text>
                        < Text style={{ paddingTop: "3px", fontSize: 10, left: 181, width: 290, position: "absolute" }}>{item.dataAssumption[0].assumptions}</Text>
                    </View>
                </React.Fragment>
            )
        } else {
            return false
        }
    })

    let display_paper_table = false;
    const paperInputData = report.calculatorData.map(item => {
        if (item.name === "paper" && item.totalCarbonFootprint !== 0) {
            display_paper_table = true
            return (
                <React.Fragment key={`report-${item.name}`}>
                    <View style={{ ...styles.stmtContentSub, backgroundColor: "#ECECEC", marginTop: 0, height: "80", paddingHorizontal: 8 }}>

                        <Text style={{ paddingTop: "3px", fontSize: 9, left: 10, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Yearly amount of paper consumed</Text><Text style={{ paddingTop: "3px", fontSize: 9, left: 200, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{report.calculatorData[1].data[0].amount}</Text>
                        <Text style={{ paddingTop: "18px", fontSize: 9, left: 10, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Units </Text><Text style={{ paddingTop: "18px", fontSize: 9, left: 200, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{report.calculatorData[1].data[0].paperUnitMeasurment.name}</Text>
                        <Text style={{ paddingTop: "33px", fontSize: 9, left: 10, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Material </Text><Text style={{ paddingTop: "33px", fontSize: 9, left: 200, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{report.calculatorData[1].data[0].paperMaterialUse.name}</Text>
                        <Text style={{ paddingTop: "48px", fontSize: 9, left: 10, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Waste Disposal </Text><Text style={{ paddingTop: "48px", fontSize: 9, left: 200, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{report.calculatorData[1].data[0].paperWasteDisposal.name}</Text>
                        <Text style={{ paddingTop: "63px", fontSize: 9, left: 10, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Postage </Text><Text style={{ paddingTop: "63px", fontSize: 9, left: 200, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>{report.calculatorData[1].data[0].paperPostage.name}</Text>
                    </View>
                </React.Fragment>
            )
        }
        else {
            return false
        }
    })

    let display_water_table = false;
    const waterInputData = waterInput.data.map((item, i) => {
        if (item) {
            display_water_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View style={{ backgroundColor: "#ECECEC", height: "40" }}>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Volume of water</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].waterInputData.volume}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].waterInputData.unitOfMeasurement}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let display_simple_table = false;
    const simpleInputData = simpleInput.data.map((item, i) => {
        if (item) {
            display_simple_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View style={{ backgroundColor: "#ECECEC", height: "50" }}>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Number of project members</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].simpleData.fteCount}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Average days working from home</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].simpleData.wfhDays}</Text>
                                    </View>
                                    {/* <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Purchased Goods & Services</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].simpleData.purchasedGoodsAndServices == true ? "Include" : "Don't Include"}</Text>
                                    </View> */}
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Business Travel</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].simpleData.businessTravel == true ? "Include" : "Don't Include"}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let display_plastic_table = false;
    const plasticInputData = plasticInput.data.map((item, i) => {
        if (item) {
            display_plastic_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "7px", fontSize: 10, marginBottom: "10px" }}>Source Type: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View style={{ backgroundColor: "#ECECEC", height: "70" }}>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Amount</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].plasticData.amount}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].plasticData.plasticUnitMeasurment}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Material Use</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].plasticData.plasticMaterialUse}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Waste Disposal</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].plasticData.plasticWasteDisposal}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let display_metal_table = false;
    const metalInputData = metalInput.data.map((item, i) => {
        if (item) {
            display_metal_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "7px", fontSize: 10, marginBottom: "10px" }}>Source Type: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View style={{ backgroundColor: "#ECECEC", height: "70" }}>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Amount</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].metalData.amount}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].metalData.metalUnitMeasurement}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Material Use</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].metalData.metalMaterialUse}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Waste Disposal</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].metalData.metalWasteDisposal}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let display_transport_table = false;
    const transportInputData = transportInput.data.map((item, i) => {
        if (item) {
            display_transport_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "7px", fontSize: 10, marginBottom: "10px" }}>Source Type: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View style={{ backgroundColor: "#ECECEC", height: "40" }}>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Amount</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].transportData.amount}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].transportData.transportUnitMeasurement}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let display_electrical_items_table = false;
    const electricalItemsInputData = electricalItemsInput.data.map((item, i) => {
        if (item) {
            display_electrical_items_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "7px", fontSize: 10, marginBottom: "10px" }}>Source Type: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <View style={{ backgroundColor: "#ECECEC", height: "70" }}>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Amount</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].electricalItemsData.amount}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].electricalItemsData.electricalItemsUnitMeasurement}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Material Use</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].electricalItemsData.electricalItemsMaterialUse}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Waste Disposal</Text>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 200, position: "absolute" }}>{item[k].electricalItemsData.electricalItemsWasteDisposal}</Text>
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let aviationType = " ";
    const aviationTypeData = aviationInput.data.map((item) => {
        if (item) {
            Object.keys(item).map(j => (
                aviationType = item[j].aviationInputData.aviationType
            ))
        }
    })

    let display_aviation_table = false;
    const aviationInputData = aviationInput.data.map((item, i) => {
        if (item) {
            display_aviation_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <Text style={{ paddingTop: "10px", fontSize: 10, marginBottom: "10px" }}>Aviation Distance type: {Object.keys(item)}</Text>
                        {aviationType === "Freight" ?
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 250, position: "absolute" }}>Input Values</Text>
                            </View>
                            :
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Labels</Text>
                                <Text style={{ paddingTop: "4px", left: 250, position: "absolute" }}>No of passengers</Text>
                            </View>
                        }
                        <View style={styles.stmtHeader2}>
                            <Text style={{ paddingTop: "3px" }}></Text>
                        </View>
                    </View>
                    <View>
                        {
                            Object.keys(item).map(k => (
                                <>
                                    <View >
                                        {item[k].aviationInputData.aviationType === "Freight" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "40" }} >
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Tonnes</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.tonnes}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>KM</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.km}</Text>
                                                </View>
                                            </View>
                                            : null}

                                        {item[k].aviationInputData.aviationType === "Passenger" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: (item[k].aviationInputData.aviationDistanceType === "Shorthaul") ? "70" : "100" }}>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Unknown Class</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.averagePassenger}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Economy Class</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.economyClass}</Text>
                                                </View>
                                                {item[k].aviationInputData.aviationDistanceType === "Shorthaul" ? null :
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Premium Economy Class</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.premiumEconomyClass}</Text>
                                                    </View>
                                                }
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Business Class</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.businessClass}</Text>
                                                </View>
                                                {item[k].aviationInputData.aviationDistanceType === "Shorthaul" ? null :
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>First Class</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.firstClass}</Text>
                                                    </View>
                                                }
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>KM</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].aviationInputData.km}</Text>
                                                </View>
                                            </View>
                                            : null}
                                    </View>
                                </>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let seaFreightType = " ";
    const seaFreightTypeData = seaFreightInput.data.map((item) => {
        if (item) {
            Object.keys(item).map(j => (
                seaFreightType = item[j].seaFreightInputData.seaFreightActivity
            ))
        }
    })

    let display_seaFreight_table = false;
    const seaFreightInputData = seaFreightInput.data.map((item, i) => {
        if (item) {
            display_seaFreight_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <Text style={{ paddingTop: "10px", fontSize: 10, marginBottom: "10px" }}>Sea Freight Type: {Object.keys(item)}</Text>

                        <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                            <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                            <Text style={{ paddingTop: "4px", left: 250, position: "absolute" }}>Input Values</Text>
                        </View>

                        <View style={styles.stmtHeader2}>
                            <Text style={{ paddingTop: "3px" }}></Text>
                        </View>
                    </View>
                    <View>
                        {
                            Object.keys(item).map(k => (
                                <>
                                    <View >
                                        {item[k].seaFreightInputData.seaFreightActivity === "Sea Tanker" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "50" }} >
                                                {item[k].seaFreightInputData.seaFreightType === "Crude tanker" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightSeaTankerSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "Products tanker" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightSeaTankerSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "Chemical tanker" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightSeaTankerSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "LNG tanker" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightSeaTankerSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "LPG tanker" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightSeaTankerSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Tonnes</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.tonnes}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Nautical Miles</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.km}</Text>
                                                </View>
                                            </View>
                                            : null}
                                        {item[k].seaFreightInputData.seaFreightActivity === "Cargo Ship" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "50" }}>
                                                {item[k].seaFreightInputData.seaFreightType === "Bulk carrier" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightCargoShipSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "General cargo" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightCargoShipSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "Container ship" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightCargoShipSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "Vehicle transport" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightCargoShipSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                {item[k].seaFreightInputData.seaFreightType === "RoRo-Ferry" ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Size</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.seaFreightCargoShipSize}</Text>
                                                    </View>
                                                    : null
                                                }
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Tonnes</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.tonnes}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Nautical Miles</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].seaFreightInputData.km}</Text>
                                                </View>
                                            </View>
                                            : null}
                                    </View>
                                </>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let comType = "";
    const eleSourceType = electricityInput.data.map((item, i, k) => {
        Object.keys(item).map(k => (
            comType = item[k].electricityData.electricitySourceType
        ))
    })

    var display_electricity_table = false;
    const electricityInputData = electricityInput.data.map((item, i, k) => {
        if (item) {
            display_electricity_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <View>
                            <Text style={{ paddingTop: "10px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                            <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                                <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                <Text style={{ paddingTop: "4px", left: 250, position: "absolute" }}>Input Values</Text>
                            </View>
                            <View style={styles.stmtHeader2}>
                                <Text style={{ paddingTop: "3px" }}></Text>
                            </View>
                        </View>
                        {
                            Object.keys(item).map(k => (
                                <>
                                    <View >
                                        {item[k].electricityData.electricitySourceType === "Electricity consumption" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "20" }} >
                                                <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Yearly amount of electricity in kWh</Text>
                                                <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].electricityData.amount}</Text>
                                            </View>
                                            : null}
                                        {item[k].electricityData.electricitySourceType === "Electricity cost" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "40" }}>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Expected yearly savings in EURO </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].electricityData.value}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Price of electricity (€/kWh)</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].electricityData.price}</Text>
                                                </View>
                                            </View>
                                            : null}
                                    </View>
                                </>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let display_dataCentre_table = false;
    const dataCentreInputData = dataCentreInput.data.map((item, i) => {
        if (item) {
            display_dataCentre_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    {display_dataCentre_table &&
                        <Page>
                            <Image
                                style={styles.logo}
                                src={logo}
                            />
                            <Text style={{ position: "absolute", left: 70, top: 120, fontFamily: "Ubuntu", fontWeight: "bold", fontStyle: "normal", fontSize: 17, lineHeight: 25, letterSpacing: -0.374, color: "#0070AD", }}>Data Centre Calculator Input Data</Text>
                            <View style={styles.stmtPdf}>
                                <Text style={{ paddingTop: "100px", fontSize: 10, marginBottom: "10px" }}>SourceType: Migration</Text>
                                <View>
                                    <View>
                                        <Text style={{ paddingTop: "5px", fontSize: 10, marginBottom: "10px" }}>Country: {Object.keys(item)}</Text>
                                        <View style={{ ...styles.stmtHeader, marginTop: 5, height: 25 }}>
                                            <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                                            <Text style={{ paddingTop: "4px", left: 270, position: "absolute" }}>Before</Text>
                                            <Text style={{ paddingTop: "4px", left: 370, position: "absolute" }}>After</Text>
                                        </View>
                                    </View>
                                    {
                                        Object.keys(item).map(k => (
                                            <View style={{ backgroundColor: "#ECECEC", height: "295" }}>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 12.5, left: 10 }}>Number of physical servers/virtual machines </Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Physical servers in estate </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.physicalServerBefore}</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.physicalServerAfter}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>VMs in physical/private cloud</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.privateServerBefore}</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.privateServerAfter}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>VMs in public cloud </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.publicServerBefore}</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.publicServerAfter}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}></Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}></Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 12.5, left: 10 }}>Breakdown of physical servers in estate by size</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Small physical servers </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.smallPhysicalServerBefore}%</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.smallPhysicalServerAfter}%</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Medium physical servers </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.mediumPhysicalServerBefore}%</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.mediumPhysicalServerAfter}%</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Large physical servers </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.largePhysicalServerBefore}%</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.largePhysicalServerAfter}%</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}></Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}></Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 12.5, left: 10 }}>Typical server utilisation </Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Physical servers </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.physicalServerUtilizationBefore}%</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.physicalServerUtilizationAfter}%</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Private cloud physical servers</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.privateServerUtilizationBefore}%</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.privateServerUtilizationAfter}%</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Public cloud physical servers </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.publicServerUtilizationBefore}%</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.publicServerUtilizationAfter}%</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}></Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}></Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 12.5, left: 10 }}>Data centre efficiency metric (PUE) </Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Data centre efficiency statistics </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.premisePueBefore}</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.premisePueAfter}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Public cloud environment efficiency statistics </Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 270, position: "absolute" }}>{item[k].dataCentre.publicPueBefore}</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 370, position: "absolute" }}>{item[k].dataCentre.publicPueAfter}</Text>
                                                </View>
                                            </View>
                                        ))
                                    }
                                </View>
                            </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )} fixed />
                        </Page>
                    }
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    let ladenType = "";
    const fuelladenType = fuelInput.data.map((item, i, k) => {
        Object.keys(item).map(k => (
            ladenType = item[k].fuelInputData.laden
        ))
    })


    let fuelDistance = " ";
    const fuelDistanceData = fuelInput.data.map((item, i, k, j) => {
        if (item) {
            Object.keys(item).map(j => (
                fuelDistance = item[j].fuelInputData.fuelSourceType
            ))
        }
    })


    var display_fuel_table = false;
    const fuelInputData = fuelInput.data.map((item, i, k, j) => {
        if (item) {
            display_fuel_table = true
            return (
                <React.Fragment key={`report-${i}`}>
                    <View>
                        <Text style={{ paddingTop: "10px", fontSize: 10, marginBottom: "10px" }}>Fuel type: {Object.keys(item)}</Text>
                        {
                            Object.keys(item).map(j => (
                                <>
                                    {item[j].fuelInputData.fuelSourceType === "Distance New" ?
                                        <View>
                                            <Text style={{ paddingTop: "0px", fontSize: 10, marginBottom: "10px" }}>Vehicle Type: {item[j].fuelInputData.vehicleType1.name}</Text>
                                        </View>
                                        : null
                                    }
                                </>
                            ))
                        }
                        <View style={{ ...styles.stmtHeader, marginTop: 5 }}>
                            <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                            <Text style={{ paddingTop: "4px", left: 250, position: "absolute" }}>Input Values</Text>
                        </View>
                        <View style={styles.stmtHeader2}>
                            <Text style={{ paddingTop: "3px" }}></Text>
                        </View>
                    </View>
                    <View>
                        {
                            Object.keys(item).map(k => (
                                <>
                                    <View >
                                        {item[k].fuelInputData.fuelSourceType === "Fuel consumption" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "40" }} >
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Amount</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.amount}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.unitOfMeasurement.name}</Text>
                                                </View>
                                            </View>
                                            : null}
                                        {item[k].fuelInputData.fuelSourceType === "Distance New" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: (ladenType === "") ? "40" : "65" }}>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Distance</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.distance}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Units</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.unitOfMeasurement.name}</Text>
                                                </View>
                                                {item[k]?.fuelInputData?.laden !== null ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Laden</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.laden}</Text>
                                                    </View>
                                                    : null}
                                                {item[k]?.fuelInputData?.otherLaden !== null ?
                                                    <View>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Laden %</Text>
                                                        <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.otherLaden}</Text>
                                                    </View>
                                                    :
                                                    null
                                                }
                                            </View>
                                            : null}
                                        {item[k].fuelInputData.fuelSourceType === "Fuel cost" ?
                                            <View style={{ backgroundColor: "#ECECEC", height: "40" }} >
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Expected yearly savings in EURO</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.value}</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 10 }}>Price of fuel (€/litre)</Text>
                                                    <Text style={{ paddingTop: "5px", fontSize: 10, left: 250, position: "absolute" }}>{item[k].fuelInputData.price}</Text>
                                                </View>
                                            </View>
                                            : null}
                                    </View>
                                </>
                            ))
                        }
                    </View>
                </React.Fragment >
            )
        } else {
            return false
        }
    })

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <Image
                    style={styles.logo}
                    src={logo}
                />
                <Text style={{ ...styles.pdfDate }}>{timeStampPdfVal}</Text>
                <Text style={styles.title}>Client Carbon Impact Report</Text>
                <Text style={{ ...styles.ldl, right: (parseInt(rWidth) + 80) }}>{`Lead Delivery Line    :`}</Text>
                <Text style={styles.ldl}>{report.deliveryLine.name}</Text>
                <Text style={{ ...styles.sector, right: (parseInt(rWidth) + 80) }}>{`Sector    :`}</Text>
                <Text style={styles.sector}>{report.sector.name}</Text>
                <Text style={{ ...styles.thor, right: (parseInt(rWidth) + 80) }}>{`Thor ID    :`}</Text>
                <Text style={styles.thor}>{report.thorId}</Text>
                {report.e3DId !== null ?
                    <><Text style={styles.projectId}>{`Project ID`}</Text>
                        <Text style={{ ...styles.projectId, left: 140 }}>{`:    CCIC - ${report.id}`}</Text></>
                    : null}
                {report.e3DId !== null ?
                    <><Text style={styles.e3DId}>{`e3D-ID`}</Text>
                        <Text style={{ ...styles.e3DId, left: 140 }}>{`:    ${report.e3DId}`}</Text></>
                    : <><Text style={styles.e3DId}>{`Project ID`}</Text>
                        <Text style={{ ...styles.e3DId, left: 140 }}>{`:    CCIC - ${report.id}`}</Text></>}
                <Text style={styles.projectName}>{`Project Name`}</Text>
                <Text style={{ ...styles.projectName, left: 140 }}>{`:    ${report.name}`}</Text>
                <Text style={styles.clientName}>{`Client Name`}</Text>
                <Text style={{ ...styles.clientName, left: 140 }}>{`:    ${report.clientName}`}</Text>
                <Text style={styles.country}>{`Country`}</Text>
                <Text style={{ ...styles.country, left: 140 }}>{`:    ${report.country.name}`}</Text>
                <View style={styles.leftPanel}>
                    <Text style={styles.description}>{`Description        :    ${report.description}`}</Text>
                    <Text style={styles.date}>{`${monthName[startDate.getMonth()]} ${startDate.getFullYear()} to ${monthName[endDate.getMonth()]} ${endDate.getFullYear()}`}</Text>
                </View>
                <Image
                    style={styles.ad}
                    src={ad}
                />
                <View style={styles.disclaimer}>
                    <Text style={{ fontWeight: "400" }}><Text style={{ fontWeight: "bold" }}>DISCLAIMER</Text> - The information in this report constitutes indicative estimates based on the data provided at the time of completion. These outcomes are not guaranteed and no reliance should be placed on them. Capgemini makes no representations and gives no assurances or warranties as to the accuracy or completeness of the information in the report and accepts no liability should the estimates not be achieved.</Text>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            <Page>
                <Image
                    style={styles.logo1}
                    src={logo}
                />
                <Text style={styles.title1}>Client Estimation Statement</Text>
                {(ecsSubTotal < 0 || ecsSubTotal > 0) ?
                    <View style={styles.stmt1}>
                        <View style={{ ...styles.stmtHeader, marginTop: 0 }}>
                            <Text style={{ paddingTop: "3px" }}>Estimated Carbon Savings</Text>
                            <Text style={{ paddingTop: "3px", right: 114, position: "absolute" }}>Unit</Text>
                            <Text style={{ paddingTop: "3px", right: 8, position: "absolute" }}>Amount</Text>
                        </View>
                        <View style={styles.stmtHeader2}>
                            <Text style={{ paddingTop: "3px" }}>The anticipated carbon saving from implementing this project are:</Text>
                        </View>
                        <View style={{ ...styles.stmtContent }}>
                            {ecsData}
                            { report.noOfYears > 1 ?
                            <View style={{ ...styles.stmtContentSub, height: 19, marginTop: 10, paddingHorizontal: 0 }}>
                                <Text style={{ paddingTop: "0px", fontSize: 9, right: 114, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Sub Total</Text>
                                <Text style={{ paddingTop: "14px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", lineHeight: 0.1 }}>{ecsSubTotal > 0 ? "1 year saving" : "for the duration of the project"}</Text>
                                <Text style={{ padding: "4px 8px", width: 100, textAlign: "right", fontSize: 12, right: 0, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", backgroundColor: "#0070AD", color: "#ffffff" }}>{ecsSubTotal > 0 ? numberWithCommas(parseFloat(ecsSubTotal).toFixed(3)) : numberWithCommas(parseFloat(-ecsSubTotal).toFixed(3))}</Text>
                            </View> : null}
                            {ecsSubTotal > 0 ?
                                <View style={{ ...styles.stmtContentSub, height: 19, marginTop: 8, paddingHorizontal: 0 }}>
                                    <Text style={{ paddingTop: "0px", fontSize: 9, right: 114, position: "absolute", fontFamily: "Ubuntu", fontWeight: "normal", lineHeight: 0.1 }}>Gross Total</Text>
                                    <Text style={{ paddingTop: "14px", fontSize: 6, right: 114, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", lineHeight: 0.1 }}>{report.noOfYears} year saving horizon</Text>
                                    {report.noOfYears == null ?
                                        <Text style={{ padding: "4px 8px", width: 100, textAlign: "right", fontSize: 12, right: 0, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", backgroundColor: "#0070AD", color: "#ffffff" }}>{numberWithCommas(parseFloat(ecsSubTotal).toFixed(3))}</Text>
                                        :
                                        <Text style={{ padding: "4px 8px", width: 100, textAlign: "right", fontSize: 12, right: 0, position: "absolute", fontFamily: "UbuntuMono", fontWeight: "normal", backgroundColor: "#0070AD", color: "#ffffff" }}>{numberWithCommas(parseFloat(ecsSubTotal * report.noOfYears).toFixed(3))}</Text>
                                    }
                                </View>
                                : null
                            }
                        </View>
                    </View>
                    : null }
                    {piSubTotal > 0 ?
                        <View style={styles.stmt2}>
                            <View style={{ ...styles.stmtContent, marginTop: 33 }}>
                                {piData}
                                {siData}
                            </View>
                        </View>
                        : null}
                    <View style={{ marginTop: 157 }}>
                        {emissionData}
                    </View>

                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
            </Page>

            {imgUri !== undefined && commonActiveTab !== "initial" && (
                <Page >
                    {graphData}
                    {pdfData}
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            )
            }

            {display_assumption &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Assumptions</Text>
                    <View style={styles.stmt}>
                        <View style={{ ...styles.stmtHeader, marginTop: 0 }}>
                            <Text style={{ paddingTop: "4px" }}>Calculator Assumptions</Text>
                            <Text style={{ paddingTop: "4px", right: 235, position: "absolute" }}>Details</Text>
                        </View>
                        <View style={styles.stmtHeader2}>
                            <Text style={{ paddingTop: "3px" }}></Text>
                        </View>
                        <View style={styles.stmtContent}>
                            {assumptionsData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_dd_assump &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Detail Delivery Assumptions</Text>
                    <View style={styles.stmt}>
                        <View style={styles.stmtContent}>
                            {ddAssumptionData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_dc_assump &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Data Center Assumptions</Text>
                    <View style={styles.stmt}>
                        <View style={styles.stmtContent}>
                            {dcAssumptionData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_paper_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Paper Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        <View style={{ ...styles.stmtHeader, marginTop: 0 }}>
                            <Text style={{ paddingTop: "4px" }}>Input Fields</Text>
                            <Text style={{ paddingTop: "4px", left: 200, position: "absolute" }}>Input Values</Text>
                        </View>
                        <View style={styles.stmtHeader2}>
                            <Text style={{ paddingTop: "3px" }}></Text>
                        </View>
                        <View style={styles.stmtContent}>
                            {paperInputData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_simple_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Simple Delivery Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        <View style={styles.stmtContent}>
                            {simpleInputData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_water_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Water Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        <View style={styles.stmtContent}>
                            {waterInputData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_plastic_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Plastic Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        {plasticInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_metal_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Metal Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        {metalInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_transport_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Transport Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        {transportInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_electrical_items_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Electrical Items Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        {electricalItemsInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_aviation_table &&
                <Page>
                    <Image
                        style={styles.logo1}
                        src={logo}
                    />
                    <Text style={{ position: "absolute", left: 70, top: 70, fontFamily: "Ubuntu", fontWeight: "bold", fontStyle: "normal", fontSize: 17, lineHeight: 25, letterSpacing: -0.374, color: "#0070AD", }}>Aviation Calculator Input Data</Text>
                    <View style={styles.stmtPdf}>
                        <View>
                            <Text style={{ paddingTop: "50px", fontSize: 10, marginBottom: "10px" }}>Aviation Type: {aviationType}</Text>
                        </View>
                        {aviationInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_seaFreight_table &&
                <Page>
                    <Image
                        style={styles.logo1}
                        src={logo}
                    />
                    <Text style={{ position: "absolute", left: 70, top: 70, fontFamily: "Ubuntu", fontWeight: "bold", fontStyle: "normal", fontSize: 17, lineHeight: 25, letterSpacing: -0.374, color: "#0070AD", }}>Sea Freight Calculator Input Data</Text>
                    <View style={styles.stmtPdf}>
                        <View>
                            <Text style={{ paddingTop: "50px", fontSize: 10, marginBottom: "10px" }}>Sea Freight Activity: {seaFreightType}</Text>
                        </View>
                        {seaFreightInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {display_electricity_table &&
                <Page>
                    <Image
                        style={styles.logo}
                        src={logo}
                    />
                    <Text style={styles.title}>Electricity Calculator Input Data</Text>
                    <View style={styles.stmt}>
                        <View style={styles.stmtContent}>
                            <View>
                                <Text style={{ paddingTop: "5px", fontSize: 10, marginBottom: "10px" }}>SourceType: {comType}</Text>
                            </View>
                            {electricityInputData}
                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {dataCentreInputData}

            {display_fuel_table &&
                <Page>
                    <Image
                        style={styles.logo1}
                        src={logo}
                    />
                    <Text style={{ position: "absolute", left: 70, top: 70, fontFamily: "Ubuntu", fontWeight: "bold", fontStyle: "normal", fontSize: 17, lineHeight: 25, letterSpacing: -0.374, color: "#0070AD", }}>Fuel Calculator Input Data</Text>
                    <View style={styles.stmtPdf}>
                        <View>
                            <Text style={{ paddingTop: "50px", fontSize: 10, marginBottom: "10px" }}>Source Type: {fuelDistance === "Distance New" ? "Distance" : fuelDistance}</Text>
                        </View>
                        {fuelInputData}
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {inpuDataTab}

        </Document >

    );
}
export default CreatePDF;