import React, { useState, useEffect } from "react";
import ToastCustom from "../../toastCustom/ToastCustom3";

function WarningPopup() {
    const [showToast, setShowToast] = useState({ state: false, header: "", body: "" });

    useEffect(() => {
        setShowToast({
            state: true,
            header: "Important Update",
            body: <div style={{ fontSize: "15px" }}>
                <p>
                    Database Migration - There will ba a scheduled Database Migration activity will be performed on 
                    <strong> 4 Aug 2023 10:00 AM to 02:00 PM IST. </strong> 
                    Carbon Calculator will be unavailable during this time Duration.
                </p>
            </div>
        })
    }, [])

    return (
        <React.Fragment>
            <ToastCustom onClose={() => setShowToast({ state: false, header: "", body: "" })} show={showToast.state} delay={35000} header={showToast.header} body={showToast.body} autoHide={false} />
        </React.Fragment>
    )
}

export default WarningPopup
