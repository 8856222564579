const { isNumeric } = require('./utils.js');
require('./utils.js')

/* Calculates total carbon footprint (tonnes CO<sub>2</sub>e) for metal used.  */
function footprintForMetalCalc(amount, unitValue, materialValue, disposalValue) {

    const materialTonnes = unitValue === "tonnes" ? amount : unitValue === "kg" ? (amount / 1000) : 0
    if (!isNumeric(materialValue)) throw TypeError("'materialValue' must be numeric")
    if (!isNumeric(disposalValue)) throw TypeError("'disposalValue' must be numeric")

    const materialCarbonFactor = materialValue === undefined || materialValue === "" ? "material value not defined" : materialValue;
    const disposalCarbonFactor = disposalValue === undefined || disposalValue === "" ? "disposal value not defined" : disposalValue;

    const CarbonFootprintMetal = materialTonnes * (materialCarbonFactor + disposalCarbonFactor);
    return CarbonFootprintMetal;
}

module.exports = {
    footprintForMetalCalc: footprintForMetalCalc,
}