const { footprintForElectricityByAmount } = require('./elec_calc.js');
const { isNumeric } = require('./utils.js');

require('./utils.js')
require('./elec_calc.js')

/**
 * Calculates carbon footprint (kgCO<sub>2</sub>e) for physical servers on estate.
 * 
 * @param {number} smallServerPowerUsage Power usage of small servers (kW)
 * @param {number} mediumServerPowerUsage Power usage of medium servers (kW)
 * @param {number} largeServerPowerUsage Power usage of large servers (kW)
 * @param {number} generationFactor generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 */
function footprintForPhysicalServers(smallServerPowerUsage, mediumServerPowerUsage, largeServerPowerUsage, generationFactor){
  if (!isNumeric(smallServerPowerUsage)) throw TypeError("'smallServerPowerUsage' must be numeric")
  if (!isNumeric(mediumServerPowerUsage)) throw TypeError("'mediumServerPowerUsage' must be numeric")
  if (!isNumeric(largeServerPowerUsage)) throw TypeError("'largeServerPowerUsage' must be numeric")
  if (smallServerPowerUsage < 0) throw RangeError("'smallServerPowerUsage' can't be less than 0")
  if (mediumServerPowerUsage < 0) throw RangeError("'mediumServerPowerUsage' can't be less than 0")
  if (largeServerPowerUsage < 0) throw RangeError("'largeServerPowerUsage' can't be less than 0")
  if (!isNumeric(generationFactor)) throw TypeError("'generationFactor' must be numeric")

  let totalPowerDraw = smallServerPowerUsage + mediumServerPowerUsage + largeServerPowerUsage 
  let yearlyElectricityUsed = parseFloat((totalPowerDraw * 365 * 24).toFixed(4)) // kWH = kW * num_hours
  return footprintForElectricityByAmount(yearlyElectricityUsed, generationFactor)
}

/**
 * Calculates carbon footprint (kgCO<sub>2</sub>e) for cloud.
 * 
 * @param {number} vmPowerUsage 
 * @param {number} generationFactor 
 * @param {number} tndFactor 
 */
function footprintForCloud(vmPowerUsage, generationFactor, tndFactor){
  if (!isNumeric(vmPowerUsage)) throw TypeError("'vmPowerUsage' must be numeric")
  if (!isNumeric(generationFactor)) throw TypeError("'generationFactor' must be numeric")
  if (!isNumeric(tndFactor)) throw TypeError("'tndFactor' must be numeric")

  let yearlyElectricityUsed = parseFloat((vmPowerUsage * 365 * 24).toFixed(4)) // kWH = kW * num_hours
  return footprintForElectricityByAmount(yearlyElectricityUsed, generationFactor)
}


module.exports = {
  footprintForCloud: footprintForCloud,
  footprintForPhysicalServers: footprintForPhysicalServers,
}