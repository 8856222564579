import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactExport from "react-data-export";
import { connect } from "react-redux";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import { Container } from "react-bootstrap";
import { CardDeck } from "react-bootstrap";
import Card from "../../components/card";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const Report = (props) => {
    const [projectData, setProjectData] = useState([]);
    const [paperData, setPaperData] = useState([]);
    const [plasticData, setPlasticData] = useState([]);
    const [metalData, setMetalData] = useState([]);
    const [transportData, setTransportData] = useState([]);
    const [electricalItemsData, setElectricalItemsData] = useState([]);
    const [aviationData, setAviationData] = useState([]);
    const [seaFreightData, setSeaFreightData] = useState([]);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [fuelData, setFuelData] = useState([]);
    const [electricityData, setElectricityData] = useState([]);
    const [peopleData, setPeopleData] = useState([]);
    const [dataCentreData, setDataCentreData] = useState([]);
    const [waterData, setWaterData] = useState([]);
    const [detailedDeliveryData, setDetailedDeliveryData] = useState([]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.login.isValidUser) {
                const getProjectData = async () => {
                    const res = await axios.get(`${basePath}/analytics/getAllProjects`, {
                        headers: {
                            authorization: `Bearer ${props.login.token}`,
                        },
                    });
                    const projects = res.data.value;
                    let projectUpdated = projects;
                    const projectLen = projects.length;
                    for (let i = 0; i < projectLen; i++) {
                        const calculators = projects[i].calculatorData;
                        const calculatorsLen = calculators.length;
                        projectUpdated[i]['total_saving'] = 0;
                        projectUpdated[i]['total_saving_three'] = 0;
                        projectUpdated[i]['negative_impact'] = 0;
                        projectUpdated[i]['net_saving'] = 0;
                        for (let j = 0; j < calculatorsLen; j++) {
                            if (calculators[j].name === "fuel") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;                                
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                    var temp = fuelData;
                                    temp.push(tempElem2);
                                    setFuelData(temp);
                                });
                                
                            } else if (calculators[j].name === "paper") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                   // projectUpdated[i]['total_saving'] = elem2.carbonFootprint;
                                    var temp = paperData;
                                    temp.push(tempElem2);
                                    setPaperData(temp);
                                });
                               
                            } else if (calculators[j].name === "plastic") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                   
                                   // projectUpdated[i]['total_saving'] = elem2.carbonFootprint;
                                    var temp = plasticData;
                                    temp.push(tempElem2);
                                    setPlasticData(temp);
                                });
                              
                            } else if (calculators[j].name === "metal") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                    var temp = metalData;
                                    temp.push(tempElem2);
                                    setMetalData(temp);
                                });                               
                            }  else if (calculators[j].name === "transport") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                    var temp = transportData;
                                    temp.push(tempElem2);
                                    setTransportData(temp);
                                });                               
                            } else if (calculators[j].name === "electricalItems") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                    var temp = electricalItemsData;
                                    temp.push(tempElem2);
                                    setElectricalItemsData(temp);
                                });                               
                            } else if (calculators[j].name === "aviation") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                    var temp = aviationData;
                                    temp.push(tempElem2);
                                    setAviationData(temp);
                                });                               
                            } else if (calculators[j].name === "seaFreight") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                    var temp = seaFreightData;
                                    temp.push(tempElem2);
                                    setSeaFreightData(temp);
                                });                               
                            } else if (calculators[j].name === "electricity") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                   // projectUpdated[i]['total_saving'] = elem2.carbonFootprint;
                                    var temp = electricityData;
                                    temp.push(tempElem2);
                                    setElectricityData(temp);
                                });
                            } else if (calculators[j].name === "people") {
                                projectUpdated[i]['negative_impact'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                   // projectUpdated[i]['negative_impact'] = elem2.carbonFootprint;
                                    var temp = peopleData;
                                    temp.push(tempElem2);
                                    setPeopleData(temp);
                                });
                            } else if (calculators[j].name === "dataCentre") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                   // projectUpdated[i]['total_saving'] = elem2.carbonFootprint;
                                    var temp = dataCentreData;
                                    temp.push(tempElem2);
                                    setDataCentreData(temp);
                                });
                            } else if (calculators[j].name === "water") {
                                projectUpdated[i]['total_saving'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                   // projectUpdated[i]['total_saving'] = elem2.carbonFootprint;
                                    var temp = waterData;
                                    temp.push(tempElem2);
                                    setWaterData(temp);
                                });
                            } else if (calculators[j].name === "detailedDelivery") {
                                projectUpdated[i]['negative_impact'] += calculators[j].totalCarbonFootprint;
                                calculators[j].data.forEach((elem2) => {
                                    var tempElem2 = { ...elem2, projectId: projects[i].id };
                                  //  projectUpdated[i]['negative_impact'] = elem2.carbonFootprint;
                                    var temp = detailedDeliveryData;
                                    temp.push(tempElem2);
                                    setDetailedDeliveryData(temp);
                                });
                            }
                        }
                        projectUpdated[i]['total_saving_three'] = projectUpdated[i]['total_saving'] * 3;
                        projectUpdated[i]['net_saving'] = projectUpdated[i]['total_saving_three'] - projectUpdated[i]['negative_impact'];
                        projectUpdated[i]['negative_impact'] = -projectUpdated[i]['negative_impact'];
                    }
                    setProjectData(projectUpdated);
                }
                getProjectData();
                if (props.login.role === "admin") {
                    const getAnalyticsData = async () => {
                        const res = await axios.get(`${basePath}/analytics`, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`,
                            },
                        });
                        setAnalyticsData(res.data);
                    }
                    getAnalyticsData();
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [props.login.isValidUser]);

    return (
        <Container style={{ width: "90%" }}>
            {
                props.login.isValidUser ? (
                    <CardDeck style={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", margin: 0, marginRight: "8px", marginTop: 50, justifyContent: "space-between" }}>
                        {
                            projectData.length > 0 ? (
                                <>
                                    <ExcelFile element={<Card key={"project-report"} id={"project-report"} title={props.login.role === "admin" ? "All Projects Report" : "My Projects Report"} subTitle={`Download an excel report which has details of all the projects${props.login.role === "admin" ? "" : " that you have created"}`} style={{ cursor: "pointer", minHeight: "130px", width: "450px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} isCalculatorTypeButton={false} clickable={true} />} filename={`Carbon-Calculator-Project-Report-${new Date().toLocaleString("en-GB", {day: "numeric", month: "short", year: "numeric" })}`} >
                                        <ExcelSheet data={projectData} name="Project Details">
                                            <ExcelColumn label="Id" value="id" />
                                            <ExcelColumn label="Name" value="name" />
                                            <ExcelColumn label="Test Project" value="testProject" />
                                            <ExcelColumn label="Description" value="description" />
                                            <ExcelColumn label="Client Name" value="clientName" />
                                            <ExcelColumn label="THOR ID" value="thorId" />
                                            <ExcelColumn label="Start Date" value="startDate" />
                                            <ExcelColumn label="End Date" value="endDate" />
                                            <ExcelColumn label="Estimated Savings Per Year" value="total_saving" />
                                            <ExcelColumn label="Estimated Savings 3 Years" value="total_saving_three" />                                            
                                            <ExcelColumn label="Estimated Delivery Impact" value="negative_impact" />
                                            <ExcelColumn label="Estimated Net Impact" value="net_saving" />

                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn label="Updated At" value="updatedAt" />

                                            <ExcelColumn
                                                label="Sales Stage"
                                                value={(col) => (col.salesStage ? col.salesStage.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Sector"
                                                value={(col) => (col.sector ? col.sector.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Delivery Line"
                                                value={(col) => (col.deliveryLine ? col.deliveryLine.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Type Of Engagement"
                                                value={(col) =>
                                                    col.typeOfEngagement ? col.typeOfEngagement.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Country"
                                                value={(col) =>
                                                    col.country
                                                        ? col.country.name + ", " + col.country.continent.name
                                                        : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Created By"
                                                value={(col) =>
                                                    col.firstCreatedBy
                                                        ? col.firstCreatedBy.username +
                                                        " (" +
                                                        col.firstCreatedBy.email +
                                                        " )"
                                                        : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Last Modified By"
                                                value={(col) =>
                                                    col.lastModifiedBy
                                                        ? col.lastModifiedBy.username +
                                                        " (" +
                                                        col.lastModifiedBy.email +
                                                        " )"
                                                        : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Activities"
                                                value={(col) => {
                                                    var temp = "";
                                                    if (col.activities !== undefined && col.activities !== null && col.activities.length > 0) {
                                                        for (const item of col.activities) {
                                                            if (temp === "") {
                                                                temp = item.name;
                                                            } else {
                                                                temp += ", " + item.name;
                                                            }
                                                        };
                                                    }
                                                    return temp;
                                                }}
                                            />
                                        </ExcelSheet>

                                        <ExcelSheet data={fuelData} name="Fuel">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Distance" value="distance" />
                                            <ExcelColumn label="Efficiency" value="efficiency" />
                                            <ExcelColumn label="Value" value="value" />
                                            <ExcelColumn label="Price" value="price" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Fuel Source Type"
                                                value={(col) => (col.fuelSourceType ? col.fuelSourceType.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Fuel Type"
                                                value={(col) => (col.fuelType ? col.fuelType.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Unit Of Measurement"
                                                value={(col) =>
                                                    col.unitOfMeasurement
                                                        ? col.unitOfMeasurement.name +
                                                        "(" +
                                                        col.unitOfMeasurement.shortName +
                                                        ")"
                                                        : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Fuel Factor Consumption"
                                                value={(col) => (col.fuelFactor ? col.fuelFactor.consumption : "")}
                                            />
                                            <ExcelColumn
                                                label="Fuel Factor Upstream"
                                                value={(col) => (col.fuelFactor ? col.fuelFactor.upstream : "")}
                                            />
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={paperData} name="Paper">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="CreatedA t" value="createdAt" />
                                            <ExcelColumn
                                                label="Paper Material Use"
                                                value={(col) =>
                                                    col.paperMaterialUse ? col.paperMaterialUse.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Paper Postage"
                                                value={(col) =>
                                                    col.paperPostage ? col.paperPostage.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Paper Source Type"
                                                value={(col) =>
                                                    col.paperSourceType ? col.paperSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Paper Unit Measurment"
                                                value={(col) =>
                                                    col.paperUnitMeasurment ? col.paperUnitMeasurment.name : ""
                                                }
                                            />
                                            <ExcelColumn label="Paper UnitMeasurmentId" value="paperUnitMeasurmentId" />
                                            <ExcelColumn
                                                label="Paper Waste Disposal"
                                                value={(col) =>
                                                    col.paperWasteDisposal ? col.paperWasteDisposal.name : ""
                                                }
                                            />
                                             <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={plasticData} name="Plastic">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Carbon Footprint" 
                                             value={(col) =>
                                                col.carbonFootprint 
                                            }
                                             />
                                            <ExcelColumn label="CreatedAt" value="createdAt" />
                                            <ExcelColumn
                                                label="Plastic Material Use"
                                                value={(col) =>
                                                    col.plasticMaterialUse ? col.plasticMaterialUse.name : ""
                                                }
                                            />
                                            
                                            <ExcelColumn
                                                label="Plastic Source Type"
                                                value={(col) =>
                                                    col.plasticSourceType ? col.plasticSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Plastic Unit Measurment"
                                                value={(col) =>
                                                    col.plasticUnitMeasurment ? col.plasticUnitMeasurment.name : ""
                                                }
                                            />
                                            
                                            <ExcelColumn
                                                label="Plastic Waste Disposal"
                                                value={(col) =>
                                                    col.plasticWasteDisposal ? col.plasticWasteDisposal.name : ""
                                                }
                                            />
                                             <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={metalData} name="Metal">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Carbon Footprint" 
                                             value={(col) =>
                                                col.carbonFootprint 
                                            }
                                             />
                                            <ExcelColumn label="CreatedAt" value="createdAt" />
                                            <ExcelColumn
                                                label="Metal Material Use"
                                                value={(col) =>
                                                    col.metalMaterialUse ? col.metalMaterialUse.name : ""
                                                }
                                            />
                                            
                                            <ExcelColumn
                                                label="Metal Source Type"
                                                value={(col) =>
                                                    col.metalSourceType ? col.metalSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Metal Unit Measurement"
                                                value={(col) =>
                                                    col.metalUnitMeasurement ? col.metalUnitMeasurement.name : ""
                                                }
                                            />
                                            
                                            <ExcelColumn
                                                label="Metal Waste Disposal"
                                                value={(col) =>
                                                    col.metalWasteDisposal ? col.metalWasteDisposal.name : ""
                                                }
                                            />
                                             <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={transportData} name="Transport">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Carbon Footprint" 
                                             value={(col) =>
                                                col.carbonFootprint 
                                            }
                                             />
                                            <ExcelColumn label="CreatedAt" value="createdAt" />                                            
                                            <ExcelColumn
                                                label="Transport Type"
                                                value={(col) =>
                                                    col.transportType ? col.transportType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Transport Unit Measurement"
                                                value={(col) =>
                                                    col.transportUnitMeasurement ? col.transportUnitMeasurement.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Transport Liquid Factor Scope1"
                                                value={(col) => (col.transportLiquidFactor ? col.transportLiquidFactor.scope1 : "")}
                                            />
                                            <ExcelColumn
                                                label="Transport Liquid Factor Scope3"
                                                value={(col) => (col.transportLiquidFactor ? col.transportLiquidFactor.scope3 : "")}
                                            />
                                             <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={electricalItemsData} name="Electrical Items">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Carbon Footprint" 
                                             value={(col) =>
                                                col.carbonFootprint 
                                            }
                                             />
                                            <ExcelColumn label="CreatedAt" value="createdAt" />
                                            <ExcelColumn
                                                label="Electrical Items Material Use"
                                                value={(col) =>
                                                    col.electricalItemsMaterialUse ? col.electricalItemsMaterialUse.name : ""
                                                }
                                            />
                                            
                                            <ExcelColumn
                                                label="Electrical Items Source Type"
                                                value={(col) =>
                                                    col.electricalItemsSourceType ? col.electricalItemsSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Electrical Items Unit Measurement"
                                                value={(col) =>
                                                    col.electricalItemsUnitMeasurement ? col.electricalItemsUnitMeasurement.name : ""
                                                }
                                            />
                                            
                                            <ExcelColumn
                                                label="Electrical Items Waste Disposal"
                                                value={(col) =>
                                                    col.electricalItemsWasteDisposal ? col.electricalItemsWasteDisposal.name : ""
                                                }
                                            />
                                             <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={aviationData} name="Aviation">
                                            <ExcelColumn label="Tonnes" value="tonnes" />
                                            <ExcelColumn label="KM" value="km" />
                                            <ExcelColumn label="Unknown Class" value="averagePassenger" />
                                            <ExcelColumn label="Economy Class" value="economyClass" />
                                            <ExcelColumn label="Premium Economy Class" value="premiumeconomyClass" />
                                            <ExcelColumn label="Business Class" value="businessClass" />
                                            <ExcelColumn label="First Class" value="firstClass" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Aviation Type"
                                                value={(col) => (col.aviationType ? col.aviationType.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Aviation Distance Type"
                                                value={(col) => (col.aviationDistanceType ? col.aviationDistanceType.name : "")}
                                            />            
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={seaFreightData} name="Sea Freight">
                                            <ExcelColumn label="Tonnes" value="tonnes" />
                                            <ExcelColumn label= "Nautical Miles" value="km" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Sea Freight Activity"
                                                value={(col) => (col.seaFreightActivity ? col.seaFreightActivity.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Sea Freight Type"
                                                value={(col) => (col.seaFreightType ? col.seaFreightType.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Sea Freight SeaTanker Size"
                                                value={(col) => (col.seaFreightSeaTankerSize ? col.seaFreightSeaTankerSize.name : "")}
                                            /> 
                                            <ExcelColumn
                                                label="Sea Freight CargoShip Size"
                                                value={(col) => (col.seaFreightCargoShipSize ? col.seaFreightCargoShipSize.name : "")}
                                            />            
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>
                                        <ExcelSheet data={electricityData} name="Electricity">
                                            <ExcelColumn label="Amount" value="amount" />
                                            <ExcelColumn label="Value" value="value" />
                                            <ExcelColumn label="Price" value="price" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Electricity Source Type"
                                                value={(col) =>
                                                    col.electricitySourceType ? col.electricitySourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Country"
                                                value={(col) => (col.country ? col.country.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Generation"
                                                value={(col) =>
                                                    col.electricityFactor ? col.electricityFactor.generation : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Factor TND"
                                                value={(col) =>
                                                    col.electricityFactor ? col.electricityFactor.tnd : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Factor WTT"
                                                value={(col) =>
                                                    col.electricityFactor ? col.electricityFactor.wtt : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Factor TNDWTT"
                                                value={(col) =>
                                                    col.electricityFactor ? col.electricityFactor.tndWtt : ""
                                                }
                                            />
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={dataCentreData} name="Data Centre">
                                            <ExcelColumn label="Physical Server Before" value="physicalServerBefore" />
                                            <ExcelColumn label="Physical Server After" value="physicalServerAfter" />
                                            <ExcelColumn label="Private Server Before" value="privateServerBefore" />
                                            <ExcelColumn label="Private Server After" value="privateServerAfter" />
                                            <ExcelColumn label="Public Server Before" value="publicServerBefore" />
                                            <ExcelColumn label="Public Server After" value="publicServerAfter" />
                                            <ExcelColumn label="Small Physical Server Before" value="smallPhysicalServerBefore" />
                                            <ExcelColumn label="Small Physical Server After" value="smallPhysicalServerAfter" />
                                            <ExcelColumn label="Medium Physical Server Before" value="mediumPhysicalServerBefore" />
                                            <ExcelColumn label="Medium Physical Server After" value="mediumPhysicalServerAfter" />
                                            <ExcelColumn label="Large Physical Server Before" value="largePhysicalServerBefore" />
                                            <ExcelColumn label="Large Physical Server After" value="largePhysicalServerAfter" />
                                            <ExcelColumn label="Physical Server Utilization Before" value="physicalServerUtilizationBefore" />
                                            <ExcelColumn label="Physical Server Utilization After" value="physicalServerUtilizationAfter" />
                                            <ExcelColumn label="Private Server Utilization Before" value="privateServerUtilizationBefore" />
                                            <ExcelColumn label="Private Server Utilization After" value="privateServerUtilizationAfter" />
                                            <ExcelColumn label="Public Server Utilization Before" value="publicServerUtilizationBefore" />
                                            <ExcelColumn label="Public Server Utilization After" value="publicServerUtilizationAfter" />
                                            <ExcelColumn label="Premise Pue Before" value="premisePueBefore" />
                                            <ExcelColumn label="Premise Pue After" value="premisePueAfter" />
                                            <ExcelColumn label="Public Pue Before" value="publicPueBefore" />
                                            <ExcelColumn label="Public Pue After" value="publicPueAfter" />
                                            <ExcelColumn label="Small Power Usage Before" value="smallPowerUsageBefore" />
                                            <ExcelColumn label="Small Power Usage After" value="smallPowerUsageAfter" />
                                            <ExcelColumn label="Medium Power Usage Before" value="mediumPowerUsageBefore" />
                                            <ExcelColumn label="Medium Power Usage After" value="mediumPowerUsageAfter" />
                                            <ExcelColumn label="Large Power Usage Before" value="largePowerUsageBefore" />
                                            <ExcelColumn label="Large Power Usage After" value="largePowerUsageAfter" />
                                            <ExcelColumn label="Private Power Usage Before" value="privatePowerUsageBefore" />
                                            <ExcelColumn label="Private Power Usage After" value="privatePowerUsageAfter" />
                                            <ExcelColumn label="Public Power Usage Before" value="publicPowerUsageBefore" />
                                            <ExcelColumn label="Public Power Usage After" value="publicPowerUsageAfter" />

                                            <ExcelColumn label="Carbon Footprint Physical Before" value="cfPhysicalBefore" />
                                            <ExcelColumn label="Carbon Footprint Physical After" value="cfPhysicalAfter" />
                                            <ExcelColumn label="Carbon Footprint Private Before" value="cfPrivateBefore" />
                                            <ExcelColumn label="Carbon Footprint Private After" value="cfPrivateAfter" />
                                            <ExcelColumn label="Carbon Footprint Public Before" value="cfPublicBefore" />
                                            <ExcelColumn label="Carbon Footprint Public After" value="cfPublicAfter" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />

                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Data Centre Source Type"
                                                value={(col) =>
                                                    col.dataCentreSourceType ? col.dataCentreSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Country"
                                                value={(col) => (col.country ? col.country.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Generation"
                                                value={(col) =>
                                                    col.dataCentreFactor ? col.dataCentreFactor.generation : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Factor TND"
                                                value={(col) => (col.dataCentreFactor ? col.dataCentreFactor.tnd : "")}
                                            />
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={waterData} name="Water">
                                            <ExcelColumn label="Volume" value="volume" />
                                            <ExcelColumn
                                                label="Unit Of Measurement"
                                                value={(col) =>
                                                    col.unitOfMeasurement
                                                        ? col.unitOfMeasurement.name +
                                                        "(" +
                                                        col.unitOfMeasurement.shortName +
                                                        ")"
                                                        : ""
                                                }
                                            />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Water Source Type"
                                                value={(col) =>
                                                    col.waterSourceType ? col.waterSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Country"
                                                value={(col) => (col.country ? col.country.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Supply"
                                                value={(col) =>
                                                    col.waterFactor ? col.waterFactor.supply : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Factor Treatment"
                                                value={(col) => (col.waterFactor ? col.waterFactor.treatment : "")}
                                            />
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>
                                        <ExcelSheet data={peopleData} name="Simple Delivery Impact">
                                            <ExcelColumn label="FTE Count" value="fteCount" />
                                            <ExcelColumn label="WFH days" value="wfhDays" />
                                            <ExcelColumn label="Business Travel Carbon Footprint" value="footprintForBusinessTravel" />
                                            <ExcelColumn label="Commuting Carbon Footprint" value="footprintForCommuting" />
                                            <ExcelColumn label="Data Centre Carbon Footprint" value="footprintForDataCentre" />
                                            <ExcelColumn label="Office Carbon Footprint" value="footprintForOffice" />
                                            <ExcelColumn label="WFH Carbon Footprint" value="footprintForWfh" />
                                            <ExcelColumn label="Goods and Services Carbon Footprint" value="footprintForGoodsAndServices" />
                                            <ExcelColumn label="Goods and Services Toggle" value="goodsAndServicesToggle" />
                                            <ExcelColumn label="Business Travel Toggle" value="businessTravelToggle" />
                                            <ExcelColumn label="Carbon Footprint" value="carbonFootprint" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Delivery Source Type"
                                                value={(col) =>
                                                    col.peopleSourceType ? col.peopleSourceType.name : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Country"
                                                value={(col) => (col.country ? col.country.name : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Business Travel"
                                                value={(col) =>
                                                    col.peopleFactor ? col.peopleFactor.businessTravel : ""
                                                }
                                            />
                                            <ExcelColumn
                                                label="Factor Office"
                                                value={(col) => (col.peopleFactor ? col.peopleFactor.office : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Commute"
                                                value={(col) => (col.peopleFactor ? col.peopleFactor.commute : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor WFH"
                                                value={(col) => (col.peopleFactor ? col.peopleFactor.wfh : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor GoodsAndServices"
                                                value={(col) => (col.peopleFactor ? col.peopleFactor.goodsAndServices : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Digital"
                                                value={(col) => (col.peopleFactor ? col.peopleFactor.dataCentre : "")}
                                            />
                                            <ExcelColumn
                                                label="Factor Average Days WFH Per Week"
                                                value={(col) => (col.peopleFactor ? col.peopleFactor.avgDaysWFHPerWeek : "")}
                                            />
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>

                                        <ExcelSheet data={detailedDeliveryData} name="Detailed Project Impact">
                                            <ExcelColumn label="(Initial) FTP Count" value="initialFtpCount" />
                                            <ExcelColumn label="(Initial) Project Length" value="initialProjectLength" />
                                            <ExcelColumn label="(Initial) Plane Total Hours" value="initialPlaneTotalHours" />
                                            <ExcelColumn label="(Initial) Plane CF" value="initialPlaneCF" />
                                            <ExcelColumn label="(Initial) High Speed Train Total Hours" value="initialHighSpeedTrainTotalHours" />
                                            <ExcelColumn label="(Initial) High Speed Train CF " value="initialHighSpeedTrainCF" />
                                            <ExcelColumn label="(Initial) Average Train Total Hours" value="initialAverageTrainTotalHours" />
                                            <ExcelColumn label="(Initial) Average Train" value="initialAverageTrainCF" />
                                            <ExcelColumn label="(Initial) Taxi Total Hours" value="initialTaxiTotalHours" />
                                            <ExcelColumn label="(Initial) Taxi CF" value="initialTaxiCF" />
                                            <ExcelColumn label="(Initial) Car Rate Of Daily Journies" value="initialCarRateOfDailyJournies" />
                                            <ExcelColumn label="(Initial) Car Avg Journey Hours Per Day" value="initialCarAvgJourneyHoursPerDay" />
                                            <ExcelColumn label="(Initial) Car CF" value="initialCarCF" />
                                            <ExcelColumn label="(Initial) Public Transport Rate Of Daily Journies" value="initialPublicTransportRateOfDailyJournies" />
                                            <ExcelColumn label="(Initial) Public Transport Avg Journey Hours Per Day" value="initialPublicTransportAvgJourneyHoursPerDay" />
                                            <ExcelColumn label="(Initial) Public Transport CF" value="initialPublicTransportCF" />
                                            <ExcelColumn label="(Initial) Hotel Total Nights" value="initialHotelTotalNights" />
                                            <ExcelColumn label="(Initial) Hotel CF" value="initialHotelCF" />
                                            <ExcelColumn label="(Initial) Digital Commit" value="initialDigitalCommit" />
                                            <ExcelColumn label="(Initial) Digital Avg Lifetime Length" value="initialDigitalAvgLifeTimeLength" />
                                            <ExcelColumn label="(Initial) Digital CF" value="initialDigitalCF" />
                                            <ExcelColumn label="(Initial) Office Rate Of Client" value="initialOfficeRateOfClient" />
                                            <ExcelColumn label="(Initial) Office Rate of Remote" value="initialOfficeRateOfRemote" />
                                            <ExcelColumn label="(Initial) Office CF" value="initialOfficeCF" />
                                            <ExcelColumn label="(Initial) WFH CF" value="initialWfhCF" />
                                            <ExcelColumn label="(Initial) Food Committed Number Of Meals Per Week" value="initialFoodCommittedNoOfMealsPerWeek" />
                                            <ExcelColumn label="(Initial) Food CF" value="initialFoodCF" />
                                            <ExcelColumn label="(Initial) Waste Commit" value="initialWasteCommit" />
                                            <ExcelColumn label="(Initial) Waste CF" value="initialWasteCF" />
                                            <ExcelColumn label="(Initial) Visited" value="initialVisited" />
                                            <ExcelColumn label="(Target) FTP Count" value="targetFtpCount" />
                                            <ExcelColumn label="(Target) Project Length" value="targetProjectLength" />
                                            <ExcelColumn label="(Target) Rate Of Plane" value="targetPlaneRateOfPlane" />
                                            <ExcelColumn label="(Target) Rate Of Plane Replaced By High Speed Train" value="targetPlaneRateOfHighSpeedTrain" />
                                            <ExcelColumn label="(Target) Rate Of Plane Replaced By Remote" value="targetPlaneRateOfRemote" />
                                            <ExcelColumn label="(Target) Plane CF" value="targetPlaneCF" />
                                            <ExcelColumn label="(Target) Rate Of High Speed Train" value="targetHighSpeedTrainRateOfHighSpeedTrain" />
                                            <ExcelColumn label="(Target) Rate Of High Speed Train Replaced By Remote" value="targetHighSpeedTrainRateOfRemote" />
                                            <ExcelColumn label="(Target) High Speed Train CF" value="targetHighSpeedTrainCF" />
                                            <ExcelColumn label="(Target) Rate Of Average Train" value="targetAverageTrainRateOfAverageTrain" />
                                            <ExcelColumn label="(Target) Rate Of Average Train Replaced By Remote" value="targetAverageTrainRateOfRemote" />
                                            <ExcelColumn label="(Target) Average Train CF" value="targetAverageTrainCF" />
                                            <ExcelColumn label="(Target) Rate Of Taxi Replaced" value="targetTaxiRateOfTaxi" />
                                            <ExcelColumn label="(Target) Rate Of Taxi Replaced By Electric Taxi" value="targetTaxiRateOfElectricTaxi" />
                                            <ExcelColumn label="(Target) Rate Of Taxi Replaced By Public Transport" value="targetTaxiRateOfPublicTransport" />
                                            <ExcelColumn label="(Target) Rate Of Taxi Replaced By Remote" value="targetTaxiRateOfRemote" />
                                            <ExcelColumn label="(Target) Taxi CF" value="targetTaxiCF" />
                                            <ExcelColumn label="(Target) Rate Of Car" value="targetCarRateOfPrivateCar" />
                                            <ExcelColumn label="(Target) Rate Of Car Replaced By Public Transport" value="targetCarRateOfPublicTransport" />
                                            <ExcelColumn label="(Target) Rate Of Car Replaced By Soft Transport" value="targetCarRateOfSoftTransport" />
                                            <ExcelColumn label="(Target) Car CF" value="targetCarCF" />
                                            <ExcelColumn label="(Target) Rate Of Public Transport" value="targetPublicTransportRateOfPublicTransport" />
                                            <ExcelColumn label="(Target) Rate Of Public Transport Replaced By Soft Transport" value="targetPublicTransportRateOfSoftTransport" />
                                            <ExcelColumn label="(Target) Public Transport CF" value="targetPublicTransportCF" />
                                            <ExcelColumn label="(Target) Hotel Total Nights" value="targetHotelTotalNights" />
                                            <ExcelColumn label="(Target) Hotel CF" value="targetHotelCF" />
                                            <ExcelColumn label="(Target) Digital Commit" value="targetDigitalCommit" />
                                            <ExcelColumn label="(Target) Digital Avg Lifetime Length" value="targetDigitalAvgLifeTimeLength" />
                                            <ExcelColumn label="(Target) Digital Rate Of Data Avoided In Email Sent Per Day Per Person" value="targetDigitalRateOfDataAvoidedInEmailSentPerDayPerPerson" />
                                            <ExcelColumn label="(Target) Digital Rate Of Data Avoided In Stoked Sharepoint" value="targetDigitalRateOfDataAvoidedInStokedSharepoint" />
                                            <ExcelColumn label="(Target) Digital Rate Of Data Avoided In Stoked Sent Email" value="targetDigitalRateOfDataAvoidedInStokedSentEmail" />
                                            <ExcelColumn label="(Target) Digital Rate Of Data Avoided In Stoked Received Email" value="targetDigitalRateOfDataAvoidedInStokedReceivedEmail" />
                                            <ExcelColumn label="(Target) Digital CF" value="targetDigitalCF" />
                                            <ExcelColumn label="(Target) Office Rate Of Client" value="targetOfficeRateOfClient" />
                                            <ExcelColumn label="(Target) Office Rate Of Remote" value="targetOfficeRateOfRemote" />
                                            <ExcelColumn label="(Target) Office CF" value="targetOfficeCF" />
                                            <ExcelColumn label="(Target) WFH CF" value="targetWfhCF" />
                                            <ExcelColumn label="(Target) Food Committed Number Of Meals Per Week" value="targetFoodCommittedNoOfMealsPerWeek" />
                                            <ExcelColumn label="(Target) Food CF" value="targetFoodCF" />
                                            <ExcelColumn label="(Target) Waste Commit" value="targetWasteCommit" />
                                            <ExcelColumn label="(Target) Waste CF" value="targetWasteCF" />
                                            <ExcelColumn label="(Target) Visited" value="targetVisited" />
                                            <ExcelColumn label="(Actual) FTP Count" value="actualFtpCount" />
                                            <ExcelColumn label="(Actual) Project Length" value="actualProjectLength" />
                                            <ExcelColumn label="(Actual) Plane Total Hours" value="actualPlaneTotalHours" />
                                            <ExcelColumn label="(Actual) Plane CF" value="actualPlaneCF" />
                                            <ExcelColumn label="(Actual) High Speed Train Total Hours" value="actualHighSpeedTrainTotalHours" />
                                            <ExcelColumn label="(Actual) High Speed Train CF" value="actualHighSpeedTrainCF" />
                                            <ExcelColumn label="(Actual) Average Train Total Hours" value="actualAverageTrainTotalHours" />
                                            <ExcelColumn label="(Actual) Average Train CF" value="actualAverageTrainCF" />
                                            <ExcelColumn label="(Actual) Taxi Total Hours Taxi" value="actualTaxiTotalHoursTaxi" />
                                            <ExcelColumn label="(Actual) Taxi Total Hours Electric Taxi" value="actualTaxiTotalHoursElectricTaxi" />
                                            <ExcelColumn label="(Actual) Taxi Total Hours Public Transport" value="actualTaxiTotalHoursPublicTransport" />
                                            <ExcelColumn label="(Actual) Taxi CF" value="actualTaxiCF" />
                                            <ExcelColumn label="(Actual) Car Rate Of Daily Journies" value="actualCarRateOfDailyJournies" />
                                            <ExcelColumn label="(Actual) Car Avg Journey Hours Per Day" value="actualCarAvgJourneyHoursPerDay" />
                                            <ExcelColumn label="(Actual) Car CF" value="actualCarCF" />
                                            <ExcelColumn label="(Actual) Public Transport Rate Of Daily Jounies" value="actualPublicTransportRateOfDailyJournies" />
                                            <ExcelColumn label="(Actual) Public Transport Avg Journey Hours Per Day" value="actualPublicTransportAvgJourneyHoursPerDay" />
                                            <ExcelColumn label="(Actual) Public Transport CF" value="actualPublicTransportCF" />
                                            <ExcelColumn label="(Actual) Hotel Total Nights" value="actualHotelTotalNights" />
                                            <ExcelColumn label="(Actual) Hotel CF" value="actualHotelCF" />
                                            <ExcelColumn label="(Actual) Digital Commmit" value="actualDigitalCommit" />
                                            <ExcelColumn label="(Actual) Digital Avg Lifetime Length" value="actualDigitalAvgLifeTimeLength" />
                                            <ExcelColumn label="(Actual) Digital Rate Of Data Avoided In Email Sent Per Day Per Person" value="actualDigitalRateOfDataAvoidedInEmailSentPerDayPerPerson" />
                                            <ExcelColumn label="(Actual) Digital Rate Of Data Avoided In Stoked Sharepoint" value="actualDigitalRateOfDataAvoidedInStokedSharepoint" />
                                            <ExcelColumn label="(Actual) Digital Rate Of Data Avoided In Stoked Sent Email" value="actualDigitalRateOfDataAvoidedInStokedSentEmail" />
                                            <ExcelColumn label="(Actual) Digital Rate Of Data Avoided In Stoked Received Email" value="actualDigitalRateOfDataAvoidedInStokedReceivedEmail" />
                                            <ExcelColumn label="(Actual) Digital CF" value="actualDigitalCF" />
                                            <ExcelColumn label="(Actual) Office Rate Of Client" value="actualOfficeRateOfClient" />
                                            <ExcelColumn label="(Actual) Office Rate Of Remote" value="actualOfficeRateOfRemote" />
                                            <ExcelColumn label="(Actual) Office CF" value="actualOfficeCF" />
                                            <ExcelColumn label="(Actual) WFH CF" value="actualWfhCF" />
                                            <ExcelColumn label="(Actual) Food Committed Number Of Meals Per Week" value="actualFoodCommittedNoOfMealsPerWeek" />
                                            <ExcelColumn label="(Actual) Food CF" value="actualFoodCF" />
                                            <ExcelColumn label="(Actual) Waste Commit" value="actualWasteCommit" />
                                            <ExcelColumn label="(Actual) Waste CF" value="actualWasteCF" />
                                            <ExcelColumn label="(Actual) Visited" value="actualVisited" />
                                            <ExcelColumn label="Created At" value="createdAt" />
                                            <ExcelColumn
                                                label="Country"
                                                value={(col) => (col.country ? col.country.name : "")}
                                            />
                                            <ExcelColumn label="Project Id" value="projectId" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </>
                            ) : (
                                    <span>
                                        <Card key={"project-report"} id={"project-report"} tooltip={true} tooltipMessage="Generating this report may take some time. Please wait..." title={props.login.role === "admin" ? "All Projects Report" : "My Projects Report"} subTitle={`Download an excel report which has details of all the projects${props.login.role === "admin" ? "" : " that you have created"}`} style={{ cursor: "not-allowed", minHeight: "130px", width: "450px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} isCalculatorTypeButton={false} clickable={false} loaderShow={true} />
                                    </span>
                                )
                        }
                        {
                            props.login.role === "admin" ? (
                                <>
                                    {
                                        Object.keys(analyticsData).length > 0 ? (
                                            <ExcelFile element={<Card key={"analytics-report"} id={"analytics-report"} title="Analytics Report" subTitle="Download an excel report which has some insights of carbon calculator" style={{ cursor: "pointer", minHeight: "130px", width: "450px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} isCalculatorTypeButton={false} clickable={true} />} filename={`Carbon-Calculator-Analytics-Report-${new Date().toLocaleString("en-GB", {day: "numeric", month: "short", year: "numeric" })}`}>
                                                <ExcelSheet data={analyticsData} name="Info">
                                                    <ExcelColumn label={`New Users (${new Date().toLocaleString("en-GB", { month: "short", year: "numeric", })})`} value="currentMonthUsers" />
                                                    <ExcelColumn label="Total Users (till now)" value="totalUsers" />
                                                    <ExcelColumn label={`New Projects Test (${new Date().toLocaleString("en-GB", { month: "short", year: "numeric", })})`} value="currentMonthTestProjects" />
                                                    <ExcelColumn label={`New Projects (Client) (${new Date().toLocaleString("en-GB", { month: "short", year: "numeric", })})`} value="currentMonthClientProjects" />
                                                    <ExcelColumn label="Total Projects Test (till now)" value="totalTestProjects" />
                                                    <ExcelColumn label="Total Projects (Client) (till now)" value="totalClientProjects" />
                                                    <ExcelColumn label="Carbon Saving Per Year" value="carbonSavings" />
                                                    <ExcelColumn label="Carbon Consumption (till now)" value="carbonConsumption" />
                                                </ExcelSheet>
                                                <ExcelSheet data={analyticsData[0].sectorWiseProjects} name="Sector Wise Projects">
                                                    <ExcelColumn label="Sectors" value="name" />
                                                    <ExcelColumn label="Number of Projects" value="count" />
                                                </ExcelSheet>
                                                <ExcelSheet data={analyticsData[0].countryWiseProjects} name="Country Wise Projects">
                                                    <ExcelColumn label="Countries" value="name" />
                                                    <ExcelColumn label="Number of Projects" value="count" />
                                                </ExcelSheet>
                                                <ExcelSheet data={analyticsData[0].continentWiseProjects} name="Continent Wise Projects">
                                                    <ExcelColumn label="Continents" value="name" />
                                                    <ExcelColumn label="Number of Projects" value="count" />
                                                </ExcelSheet>
                                                <ExcelSheet data={analyticsData[0].calculators} name="Calculators Count and Savings">
                                                    <ExcelColumn label="Calculators" value="name" />
                                                    <ExcelColumn label="Counts" value="count" />
                                                    <ExcelColumn label="Carbon Savings" value="carbonSavings" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                        ) : (
                                                <span>
                                                    <Card key={"analytics-report"} id={"analytics-report"} tooltip={true} tooltipMessage="Generating this report may take some time. Please wait..." title="Analytics Report" subTitle="Download an excel report which has some insights of carbon calculator" style={{ cursor: "not-allowed", minHeight: "130px", width: "450px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} isCalculatorTypeButton={false} clickable={false} loaderShow={true} />
                                                </span>
                                            )
                                    }
                                </>
                            ) : (
                                    <span>
                                        <Card key={"analytics-report"} id={"analytics-report"} tooltip={true} tooltipMessage="You are not authorized to get this report" title="Analytics Report" subTitle="Download an excel report which has some insights of carbon calculator" style={{ cursor: "not-allowed", minHeight: "130px", width: "450px", flex: "1 0 350px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`light`} data={null} noMargin={true} isCalculatorTypeButton={false} clickable={false} />
                                    </span>
                                )
                        }
                    </CardDeck>
                ) : (<div>Please login to get the report !</div>)
            }
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        project: state.project,
        master: state.master,
    };
};

export default errorBoundaryWrapper(
    ErrorBoundaryForPage,
    connect(mapStateToProps)(Report)
);