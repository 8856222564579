import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import convert from "convert-units";
import { footprintForFuelByDistanceNew, footprintForFuelLaden } from "../../calculatorFunctions/fuel_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

var eff = 0;
var con = 0;
var ups = 0;
var scope1 = 0;
var scope3 = 0;
var year = new Date().getFullYear();
var source = null;

const ladenOptions = [
    { value: "0%", label: "0%" },
    { value: "50%", label: "50%" },
    { value: "100%", label: "100%" },
    { value: "average", label: "Average" },
    { value: "other", label: "Other" }
];

const unitOptions = [
    { value: "kilometre", label: "km" },
    { value: "mile", label: "mi" },
];

const NewCollapseDistanceVehicleTypeCustom = (props) => {

    let { collapseControll, id, primaryCountry, fuelType, inputName, otherLadenValue, inputValue, inputLabel, inputPlaceholder, selectName, selectLabel, selectValue, selectLadenValue, control, register, errors } = props;

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [vehicleType1, setVehicleType1] = useState({});
    const [countryElectricFactor, setCountryElectricFactor] = useState({});
    const [laden, setLaden] = useState((selectLadenValue === undefined || selectLadenValue === "") ? ladenOptions[0] : selectLadenValue);
    const [unit, setUnit] = useState((selectValue === undefined || selectValue === "") ? unitOptions[0] : selectValue);
    const [isLadenNeeded, setIsLadenNeeded] = useState(false);
    const [showOtherLadenInput, setShowOtherLadenInput] = useState(false);
    const [inputOtherLaden, setOtherLaden] = useState((otherLadenValue === undefined || otherLadenValue === "") ? 0 : otherLadenValue);

    var divWidthCSS = {};
    var inputFormGroupWidthCSS = {};
    var selectFormGroupWidthCSS = {};

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const getLadenOptions = (inputValue, callback) => {
        callback(ladenOptions);
    }

    const inputTextChange = (val) => {
        setCF((val !== undefined && val !== "") ? val : 0);
    }
    const inputLadenChange = (val) => {
        setOtherLaden((val !== undefined && val !== "") ? val : 0);
        // setCF((ladenVal !== undefined && ladenVal !== "") ? ladenVal : 0);
    }

    const onLadenChange = (val) => {
        setLaden(val);
        if (val.value == 'other') {
            setShowOtherLadenInput(true);
        } else {
            setShowOtherLadenInput(false);
        }
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    const setCF = (val) => {
        var name = fuelType;
        var cf = 0;
        let efficiency = 0;
        let uFactor = 0;
        var itemScope1 = 0;
        var itemScope3 = 0;
        var itemName = "";
        var scope1Laden0 = 0;
        var scope1Laden100 = 0;
        var scope3Laden0 = 0;
        var scope3Laden100 = 0;

        year = props.master.fuelFactorVolume.hasOwnProperty(name) && props.master.fuelFactorVolume[name].year !== undefined ? props.master.fuelFactorVolume[name].year : 0;
        source = props.master.fuelFactorVolume.hasOwnProperty(name) && props.master.fuelFactorVolume[name].source !== undefined ? props.master.fuelFactorVolume[name].source : null;
        if (fuelType !== "electric") {
            for (const item of vehicleType1[fuelType]) {
                if (item.name === inputName) {
                    if (item.laden === null) {
                        setIsLadenNeeded(false);
                        efficiency = item.efficiency;
                        itemName = item.name;
                        itemScope1 = item.scope1;
                        itemScope3 = item.scope3;
                        break;
                    } else {
                        setIsLadenNeeded(true);
                        if (item.laden == "0%") {
                            scope1Laden0 = item.scope1;
                            scope3Laden0 = item.scope3;
                        }
                        if (item.laden == "100%") {
                            scope1Laden100 = item.scope1;
                            scope3Laden100 = item.scope3;
                        }
                        if (item.laden === laden.value) {
                            efficiency = item.efficiency;
                            itemName = item.name;
                            itemScope1 = item.scope1;
                            itemScope3 = item.scope3;
                            break;
                        }
                    }
                }
            }
            eff = efficiency;
            scope1 = itemScope1;
            scope3 = itemScope3;
            if (laden.value == "other" && inputOtherLaden) {
                cf = footprintForFuelLaden(convert(parseFloat(inputValue)).from(unit.label).to("km"), scope1Laden0, scope1Laden100, scope3Laden0, scope3Laden100, inputOtherLaden);
                cf = (parseFloat(cf) / 1000).toFixed(3);
                props.cFootprint({ name: inputName, cf: { value: cf, year, source } });
                setCarbonFootprint(cf);
            } else {
                cf = footprintForFuelByDistanceNew(convert(parseFloat(val)).from(unit.label).to("km"), scope1, scope3, year, source);
                cf = (parseFloat(cf) / 1000).toFixed(3);
                props.cFootprint({ name: inputName, cf: { value: cf, year, source } });
                setCarbonFootprint(cf);
            }
        } else {
            for (const item of vehicleType1[fuelType]) {
                if (item.name === inputName) {
                    if (item.laden === null) {
                        setIsLadenNeeded(false);
                        itemName = item.name;
                        itemScope1 = item.scope1;
                        itemScope3 = item.scope3;

                        break;
                    } else {
                        setIsLadenNeeded(true);
                        if (item.laden === laden.value) {
                            itemName = item.name;
                            itemScope1 = item.scope1;
                            itemScope3 = item.scope3;
                            break;
                        }
                    }
                }
            }
            uFactor = countryElectricFactor[primaryCountry] !== undefined && countryElectricFactor[primaryCountry].efficiency !== undefined ? countryElectricFactor[primaryCountry].efficiency : 0;
            eff = efficiency;
            scope1 = itemScope1;
            scope3 = itemScope3;

            cf = footprintForFuelByDistanceNew(convert(parseFloat(val)).from(unit.label).to("km"), scope1, scope3);
            cf = (parseFloat(cf) / 1000).toFixed(3);
            props.cFootprint({ name: inputName, cf: { value: cf, scope1: scope1, scope3: scope3, year: new Date().getFullYear() } });
            setCarbonFootprint(cf);
        }
    }

    useEffect(() => {
        if (laden.value == 'other') {
            setShowOtherLadenInput(true);
        } else {
            setShowOtherLadenInput(false);
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(countryElectricFactor).length === 0) {
                    setCountryElectricFactor(props.master.countryElectricEfficiency);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [props.master.status]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(vehicleType1).length === 0 && Object.keys(countryElectricFactor).length > 0) {
                    setVehicleType1(props.master.vehicleType1);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countryElectricFactor]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputOtherLaden !== undefined && inputOtherLaden !== "" && Object.keys(vehicleType1).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [inputOtherLaden]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(vehicleType1).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [vehicleType1]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "" && Object.keys(countryElectricFactor).length > 0 && Object.keys(vehicleType1).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [unit]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (Object.keys(vehicleType1).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [laden]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (Object.keys(vehicleType1).length > 0) {
                setCF((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [primaryCountry]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (Object.keys(countryElectricFactor).length > 0 && Object.keys(vehicleType1).length > 0) {
                for (const item of vehicleType1[fuelType]) {
                    if (item.name === inputName) {
                        if (item.laden === null) {
                            setIsLadenNeeded(false);
                            break;
                        } else {
                            setIsLadenNeeded(true);
                            break;
                        }
                    }
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countryElectricFactor, vehicleType1, fuelType, inputName]);

    if (!isLadenNeeded) {
        divWidthCSS = {
            width: "100%"
        }
        selectFormGroupWidthCSS = {
            width: "30%"
        }
        inputFormGroupWidthCSS = {
            width: "30%"
        }
    } else {
        divWidthCSS = {
            width: "100%"
        }
        inputFormGroupWidthCSS = {
            width: "40%"
        }
        selectFormGroupWidthCSS = {
            width: "20%"
        }
    }
    return (
        <Collapse in={collapseControll}>
            <div style={{ textAlign: "left" }}>
                <Row>
                    <Col lg={8} md={8} sm={12} >
                        <div className={`${id}-${inputName}-vtCollapse`} style={{ ...divWidthCSS }}>
                            <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-distance-vt`} name={`${id}-${inputName}-distance-vt`} label={inputLabel} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, ...inputFormGroupWidthCSS, marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTextChange(e.target.value)} />
                            {
                                control !== undefined ? (
                                    <Controller
                                        render={({ onChange, value, name }) => (
                                            <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label={selectLabel} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black", marginRight: isLadenNeeded ? "10px" : "0px", ...selectFormGroupWidthCSS }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#F9950080" />
                                        )}
                                        control={control}
                                        name={`${id}-${selectName}-distance-vt-select`}
                                        defaultValue={unit}
                                    />
                                ) : (<></>)
                            }
                            {
                                control !== undefined && isLadenNeeded ? (
                                    <Controller
                                        render={({ onChange, value, name }) => (
                                            <Select
                                                id={name}
                                                name={name}
                                                value={value}
                                                onChange={(val) => {
                                                    onLadenChange(val);
                                                    return onChange(val)
                                                }}
                                                labelStyle={{ fontSize: "10px", color: "white" }}
                                                errors={errors}
                                                loadOptions={getLadenOptions}
                                                label={"Laden"}
                                                defaultOptions={true}
                                                formGroupStyle={{ flexGrow: 1, color: "black", ...selectFormGroupWidthCSS }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#44414136" />
                                        )}
                                        control={control}
                                        name={`${id}-${selectName}-distance-vt-laden-select`}
                                        defaultValue={laden}
                                    />
                                ) : (<></>)
                            }
                            {showOtherLadenInput && (<Input className="numberType" onWheel={(e) => onWheel(e)} id={`${id}-${inputName}-distance-vt-otherLaden`} name={`${id}-${inputName}-distance-vt-otherLaden`} type="number" label={'Laden %'} placeholder="0" tooltip={true} register={register({ validate: (value) => value !== "" && value >= 0 && value <= 100 ? true : "The number should be between 0-100" })} errors={errors} labelStyle={{ fontSize: "10px", marginLeft: '10%' }} formGroupStyle={{ width: '80px' }} style={{ marginLeft: '10%' }} onChange={(e) => inputLadenChange(e.target.value)} />)}
                        </div>
                        
                    </Col>
                    <Col lg={2} md={2} sm={12} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                        {/*
                            fuelType === "electric" && countryElectricFactor[primaryCountry] === undefined ? (
                                <span>The primary country is currently not available !</span>
                            ) : (<></>)
                            */
                            <></>
                        }
                    </Col>
                    <Col lg={2} md={2} sm={12} >
                        <div className="carbonFootprint" >
                            <span>Carbon Footprint</span>
                            <br />
                            <br />
                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                        </div>
                    </Col>
                </Row>
                <Row style={{ height: "0px", display: "none" }}>
                    <Col>
                        <Input hidden={true} readOnly={true} value={carbonFootprint} id={`${id}-${inputName}-distance-vt-cf`} name={`${id}-${inputName}-distance-vt-cf`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={scope1} id={`${id}-${inputName}-distance-vt-scope1`} name={`${id}-${inputName}-distance-vt-scope1`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={scope3} id={`${id}-${inputName}-distance-vt-scope3`} name={`${id}-${inputName}-distance-vt-scope3`} label={""} placeholder={inputPlaceholder} register={register} />
                        <Input hidden={true} readOnly={true} value={year} id={`${id}-${inputName}-distance-vt-year`} name={`${id}-${inputName}-distance-vt-year`} label={""} placeholder={inputPlaceholder} register={register} />
                    </Col>
                </Row>
            </div>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(NewCollapseDistanceVehicleTypeCustom));