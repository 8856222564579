import React, { useState, useReducer, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./NewDeliveryTabs.css";
import Select from "../select/SelectCustomCountry";
import CollapseNewDeliveryCustom from "./CollapseNewDeliveryCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../errorBoundaries/ErrorBoundaryForPage";
import { mainCountries } from "../../calculatorFunctions/mainCountries/mainCountries";
import { deleteConfirm } from "../deleteConfirm/deleteConfirm";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf, status: true }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
let reducerData = {};
var countryData = {};
var commonActiveTab = "initial";
var sTime = new Date();
var currentParent = "";

const NewDeliveryTabs = (props) => {

    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, {});
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control, formState, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [clickedSave, setClickedSave] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const filterCountry = (inputValue) => {
        const grouped = groupBy(countries, country => country.label);
        const top = grouped.get("top")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        const bottom = grouped.get("bottom")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return [
            {
                label: "top",
                options: top
            },
            {
                label: "bottom",
                options: bottom
            }
        ];
    };

    const getCountryOptionsData = (inputValue, callback) => {
        callback(filterCountry(inputValue));
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            let data = {};
            data["projectId"] = projectId;
            data["data"] = [];
            data["genericFactors"] = {};
            data["genericFactors"]["hotelFactor"] = [];
            if (Object.keys(collapseState).filter(country => collapseState[country]["status"] === collapseState[country]["status"]).length > 0) { // (collapseState[country]["status"] === true) to filter active status countries     
                if (Object.keys(collapseState).filter(country => collapseState[country]["cf"]["initial"]["visited"] === true).length > 0) {
                    Object.keys(collapseState).filter(country => collapseState[country]["status"] === collapseState[country]["status"]).forEach(country => { // collapseState[country]["status"] === true
                        let countryData = {
                            "projectId": projectId,
                            "initialFtpCount": collapseState[country]["cf"]["initial"]["ftpCount"],
                            "initialProjectLength": collapseState[country]["cf"]["initial"]["projectLength"],
                            "initialPlaneTotalHours": collapseState[country]["cf"]["initial"]["plane"]["totalHours"],
                            "initialPlaneCF": collapseState[country]["cf"]["initial"]["plane"]["cf"],
                            "initialHighSpeedTrainTotalHours": collapseState[country]["cf"]["initial"]["highSpeedTrain"]["totalHours"],
                            "initialHighSpeedTrainCF": collapseState[country]["cf"]["initial"]["highSpeedTrain"]["cf"],
                            "initialAverageTrainTotalHours": collapseState[country]["cf"]["initial"]["averageTrain"]["totalHours"],
                            "initialAverageTrainCF": collapseState[country]["cf"]["initial"]["averageTrain"]["cf"],
                            "initialTaxiTotalHours": collapseState[country]["cf"]["initial"]["taxi"]["totalHours"],
                            "initialTaxiCF": collapseState[country]["cf"]["initial"]["taxi"]["cf"],
                            "initialCarRateOfDailyJournies": collapseState[country]["cf"]["initial"]["car"]["rateOfDailyJournies"],
                            "initialCarAvgJourneyHoursPerDay": collapseState[country]["cf"]["initial"]["car"]["avgJourneyHoursPerDay"],
                            "initialCarCF": collapseState[country]["cf"]["initial"]["car"]["cf"],
                            "initialPublicTransportRateOfDailyJournies": collapseState[country]["cf"]["initial"]["publicTransport"]["rateOfDailyJournies"],
                            "initialPublicTransportAvgJourneyHoursPerDay": collapseState[country]["cf"]["initial"]["publicTransport"]["avgJourneyHoursPerDay"],
                            "initialPublicTransportCF": collapseState[country]["cf"]["initial"]["publicTransport"]["cf"],
                            "initialHotelTotalNights": collapseState[country]["cf"]["initial"]["hotel"]["totalNights"],
                            "initialHotelCF": collapseState[country]["cf"]["initial"]["hotel"]["cf"],
                            "initialDigitalCommit": collapseState[country]["cf"]["initial"]["digital"]["commit"],
                            "initialDigitalAvgLifeTimeLength": collapseState[country]["cf"]["initial"]["digital"]["avgLifeTimeLength"],
                            "initialDigitalCF": collapseState[country]["cf"]["initial"]["digital"]["cf"],
                            "initialOfficeRateOfClient": collapseState[country]["cf"]["initial"]["office"]["rateOfClient"],
                            "initialOfficeRateOfRemote": collapseState[country]["cf"]["initial"]["office"]["rateOfRemote"],
                            "initialOfficeCF": collapseState[country]["cf"]["initial"]["office"]["cf"],
                            "initialWfhCF": collapseState[country]["cf"]["initial"]["wfh"]["cf"],
                            "initialFoodCommittedNoOfMealsPerWeek": collapseState[country]["cf"]["initial"]["food"]["committedNoOfMealsPerWeek"],
                            "initialFoodCF": collapseState[country]["cf"]["initial"]["food"]["cf"],
                            "initialWasteCommit": collapseState[country]["cf"]["initial"]["waste"]["commit"],
                            "initialWasteCF": collapseState[country]["cf"]["initial"]["waste"]["cf"],
                            "initialVisited": collapseState[country]["cf"]["initial"]["visited"],
                            "initialAssumptions": collapseState[country]["cf"]["initial"]["assumption"],
                            "targetFtpCount": collapseState[country]["cf"]["target"]["ftpCount"],
                            "targetProjectLength": collapseState[country]["cf"]["target"]["projectLength"],
                            "targetPlaneRateOfPlane": collapseState[country]["cf"]["target"]["plane"]["rateOfPlane"],
                            "targetPlaneRateOfHighSpeedTrain": collapseState[country]["cf"]["target"]["plane"]["rateOfHighSpeedTrain"],
                            "targetPlaneRateOfRemote": collapseState[country]["cf"]["target"]["plane"]["rateOfRemote"],
                            "targetPlaneCF": collapseState[country]["cf"]["target"]["plane"]["cf"],
                            "targetHighSpeedTrainRateOfHighSpeedTrain": collapseState[country]["cf"]["target"]["highSpeedTrain"]["rateOfHighSpeedTrain"],
                            "targetHighSpeedTrainRateOfRemote": collapseState[country]["cf"]["target"]["highSpeedTrain"]["rateOfRemote"],
                            "targetHighSpeedTrainCF": collapseState[country]["cf"]["target"]["highSpeedTrain"]["cf"],
                            "targetAverageTrainRateOfAverageTrain": collapseState[country]["cf"]["target"]["averageTrain"]["rateOfAverageTrain"],
                            "targetAverageTrainRateOfRemote": collapseState[country]["cf"]["target"]["averageTrain"]["rateOfRemote"],
                            "targetAverageTrainCF": collapseState[country]["cf"]["target"]["averageTrain"]["cf"],
                            "targetTaxiRateOfTaxi": collapseState[country]["cf"]["target"]["taxi"]["rateOfTaxi"],
                            "targetTaxiRateOfElectricTaxi": collapseState[country]["cf"]["target"]["taxi"]["rateOfElectricTaxi"],
                            "targetTaxiRateOfPublicTransport": collapseState[country]["cf"]["target"]["taxi"]["rateOfPublicTransport"],
                            "targetTaxiRateOfRemote": collapseState[country]["cf"]["target"]["taxi"]["rateOfRemote"],
                            "targetTaxiCF": collapseState[country]["cf"]["target"]["taxi"]["cf"],
                            "targetCarRateOfPrivateCar": collapseState[country]["cf"]["target"]["car"]["rateOfPrivateCar"],
                            "targetCarRateOfPublicTransport": collapseState[country]["cf"]["target"]["car"]["rateOfPublicTransport"],
                            "targetCarRateOfSoftTransport": collapseState[country]["cf"]["target"]["car"]["rateOfSoftTransport"],
                            "targetCarCF": collapseState[country]["cf"]["target"]["car"]["cf"],
                            "targetPublicTransportRateOfPublicTransport": collapseState[country]["cf"]["target"]["publicTransport"]["rateOfPublicTransport"],
                            "targetPublicTransportRateOfSoftTransport": collapseState[country]["cf"]["target"]["publicTransport"]["rateOfSoftTransport"],
                            "targetPublicTransportCF": collapseState[country]["cf"]["target"]["publicTransport"]["cf"],
                            "targetHotelTotalNights": collapseState[country]["cf"]["target"]["hotel"]["totalNights"],
                            "targetHotelCF": collapseState[country]["cf"]["target"]["hotel"]["cf"],
                            "targetDigitalCommit": collapseState[country]["cf"]["target"]["digital"]["commit"],
                            "targetDigitalAvgLifeTimeLength": collapseState[country]["cf"]["target"]["digital"]["avgLifeTimeLength"],
                            "targetDigitalRateOfDataAvoidedInEmailSentPerDayPerPerson": collapseState[country]["cf"]["target"]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                            "targetDigitalRateOfDataAvoidedInStokedSharepoint": collapseState[country]["cf"]["target"]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                            "targetDigitalRateOfDataAvoidedInStokedSentEmail": collapseState[country]["cf"]["target"]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                            "targetDigitalRateOfDataAvoidedInStokedReceivedEmail": collapseState[country]["cf"]["target"]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"],
                            "targetDigitalCF": collapseState[country]["cf"]["target"]["digital"]["cf"],
                            "targetOfficeRateOfClient": collapseState[country]["cf"]["target"]["office"]["rateOfClient"],
                            "targetOfficeRateOfRemote": collapseState[country]["cf"]["target"]["office"]["rateOfRemote"],
                            "targetOfficeCF": collapseState[country]["cf"]["target"]["office"]["cf"],
                            "targetWfhCF": collapseState[country]["cf"]["target"]["wfh"]["cf"],
                            "targetFoodCommittedNoOfMealsPerWeek": collapseState[country]["cf"]["target"]["food"]["committedNoOfMealsPerWeek"],
                            "targetFoodCF": collapseState[country]["cf"]["target"]["food"]["cf"],
                            "targetWasteCommit": collapseState[country]["cf"]["target"]["waste"]["commit"],
                            "targetWasteCF": collapseState[country]["cf"]["target"]["waste"]["cf"],
                            "targetVisited": collapseState[country]["cf"]["target"]["visited"],
                            "targetAssumptions": collapseState[country]["cf"]["target"]["assumption"],
                            "actualFtpCount": collapseState[country]["cf"]["actual"]["ftpCount"],
                            "actualProjectLength": collapseState[country]["cf"]["actual"]["projectLength"],
                            "actualPlaneTotalHours": collapseState[country]["cf"]["actual"]["plane"]["totalHours"],
                            "actualPlaneCF": collapseState[country]["cf"]["actual"]["plane"]["cf"],
                            "actualHighSpeedTrainTotalHours": collapseState[country]["cf"]["actual"]["highSpeedTrain"]["totalHours"],
                            "actualHighSpeedTrainCF": collapseState[country]["cf"]["actual"]["highSpeedTrain"]["cf"],
                            "actualAverageTrainTotalHours": collapseState[country]["cf"]["actual"]["averageTrain"]["totalHours"],
                            "actualAverageTrainCF": collapseState[country]["cf"]["actual"]["averageTrain"]["cf"],
                            "actualTaxiTotalHoursTaxi": collapseState[country]["cf"]["actual"]["taxi"]["totalHoursTaxi"],
                            "actualTaxiTotalHoursElectricTaxi": collapseState[country]["cf"]["actual"]["taxi"]["totalHoursElectricTaxi"],
                            "actualTaxiTotalHoursPublicTransport": collapseState[country]["cf"]["actual"]["taxi"]["totalHoursPublicTransport"],
                            "actualTaxiCF": collapseState[country]["cf"]["actual"]["taxi"]["cf"],
                            "actualCarRateOfDailyJournies": collapseState[country]["cf"]["actual"]["car"]["rateOfDailyJournies"],
                            "actualCarAvgJourneyHoursPerDay": collapseState[country]["cf"]["actual"]["car"]["avgJourneyHoursPerDay"],
                            "actualCarCF": collapseState[country]["cf"]["actual"]["car"]["cf"],
                            "actualPublicTransportRateOfDailyJournies": collapseState[country]["cf"]["actual"]["publicTransport"]["rateOfDailyJournies"],
                            "actualPublicTransportAvgJourneyHoursPerDay": collapseState[country]["cf"]["actual"]["publicTransport"]["avgJourneyHoursPerDay"],
                            "actualPublicTransportCF": collapseState[country]["cf"]["actual"]["publicTransport"]["cf"],
                            "actualHotelTotalNights": collapseState[country]["cf"]["actual"]["hotel"]["totalNights"],
                            "actualHotelCF": collapseState[country]["cf"]["actual"]["hotel"]["cf"],
                            "actualDigitalCommit": collapseState[country]["cf"]["actual"]["digital"]["commit"],
                            "actualDigitalAvgLifeTimeLength": collapseState[country]["cf"]["actual"]["digital"]["avgLifeTimeLength"],
                            "actualDigitalRateOfDataAvoidedInEmailSentPerDayPerPerson": collapseState[country]["cf"]["actual"]["digital"]["rateOfDataAvoidedInEmailSentPerDayPerPerson"],
                            "actualDigitalRateOfDataAvoidedInStokedSharepoint": collapseState[country]["cf"]["actual"]["digital"]["rateOfDataAvoidedInStokedSharepoint"],
                            "actualDigitalRateOfDataAvoidedInStokedSentEmail": collapseState[country]["cf"]["actual"]["digital"]["rateOfDataAvoidedInStokedSentEmail"],
                            "actualDigitalRateOfDataAvoidedInStokedReceivedEmail": collapseState[country]["cf"]["actual"]["digital"]["rateOfDataAvoidedInStokedReceivedEmail"],
                            "actualDigitalCF": collapseState[country]["cf"]["actual"]["digital"]["cf"],
                            "actualOfficeRateOfClient": collapseState[country]["cf"]["actual"]["office"]["rateOfClient"],
                            "actualOfficeRateOfRemote": collapseState[country]["cf"]["actual"]["office"]["rateOfRemote"],
                            "actualOfficeCF": collapseState[country]["cf"]["actual"]["office"]["cf"],
                            "actualWfhCF": collapseState[country]["cf"]["actual"]["wfh"]["cf"],
                            "actualFoodCommittedNoOfMealsPerWeek": collapseState[country]["cf"]["actual"]["food"]["committedNoOfMealsPerWeek"],
                            "actualFoodCF": collapseState[country]["cf"]["actual"]["food"]["cf"],
                            "actualWasteCommit": collapseState[country]["cf"]["actual"]["waste"]["commit"],
                            "actualWasteCF": collapseState[country]["cf"]["actual"]["waste"]["cf"],
                            "actualVisited": collapseState[country]["cf"]["actual"]["visited"],
                            "actualAssumptions": collapseState[country]["cf"]["actual"]["assumption"],
                            "country": {
                                "name": country
                            }
                        }
                        data["data"].push(countryData);

                        const { unitaryEmission, year } = props.master.hotelFactorMaster[country];
                        data["genericFactors"]["hotelFactor"].push({ unitaryEmission, year, country: { name: country } });
                    });

                    data["genericFactors"]["transportFactor"] = [];
                    Object.keys(props.master.transportFactorMaster).forEach(item => {
                        const { category, unitaryEmission, year } = props.master.transportFactorMaster[item];
                        data["genericFactors"]["transportFactor"].push({ category, unitaryEmission, year });
                    });

                    data["genericFactors"]["wasteFactor"] = [];
                    Object.keys(props.master.wasteFactorMaster).forEach(item => {
                        const { category, unitaryEmission, year } = props.master.wasteFactorMaster[item];
                        data["genericFactors"]["wasteFactor"].push({ category, unitaryEmission, year });
                    });

                    data["genericFactors"]["foodFactor"] = [];
                    Object.keys(props.master.foodFactorMaster).forEach(item => {
                        const { category, unitaryEmission, year } = props.master.foodFactorMaster[item];
                        data["genericFactors"]["foodFactor"].push({ category, unitaryEmission, year });
                    });

                    data["genericFactors"]["digitalFactor"] = [];
                    Object.keys(props.master.digitalFactorMaster).forEach(item => {
                        const { category, numberOfMB, unitaryEmission, year } = props.master.digitalFactorMaster[item];
                        data["genericFactors"]["digitalFactor"].push({ category, numberOfMB, unitaryEmission, year });
                    });

                    axios.post(`${basePath}/calculator/detailedDelivery`, data, {
                        headers: {
                            authorization: `Bearer ${props.login.token}`
                        }
                    })
                        .then(res => {
                            sTime = new Date();
                            setIsDataPresent(true);
                            setSavedTime(sTime);
                            props.calculateSummary();
                            props.toastMessage({ state: true, header: "Success", body: "Detailed Project Impact calculator data saved!" });
                        })
                        .catch(err => {
                            props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                        }); 
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please start with '1-Initial Impact' and fill in the details!" });
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a country and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const setUnsetCountry = (val, parent) => {
        currentParent = parent;
        if (val !== null) {
            setSelectedCountries(val);
        } else {
            setSelectedCountries([]);
        }
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent === val.parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/detailedDelivery/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            reducerData = {};
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Detailed Project Impact calculator cannot be deleted! Server Error!" });
                    });
            } else {
                if (isMountedRef.current) {
                    reducerData = {};
                    props.onDelete(componentId);
                }
            }
        }
    }

    const formatGroupLabel = (data) => {
        if (data.label !== "top") {
            return (
                <hr style={{ left: "-10px", height: "0.5px", backgroundColor: "#f0f0f0", margin: "0px", padding: "0px", marginTop: "-10px" }} />
            )
        } else {
            return false;
        }
    };

    const getTotalCarbonFootprint = () => {
        var total = 0;
        Object.keys(collapseState).forEach(key => {
            if (typeof collapseState[key].cf === "object" && collapseState[key].status === true) {
                total += collapseState[key].cf[commonActiveTab]["office"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["wfh"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["plane"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["highSpeedTrain"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["averageTrain"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["taxi"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["car"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["publicTransport"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["digital"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["food"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["waste"]["cf"] +
                    collapseState[key].cf[commonActiveTab]["hotel"]["cf"]
            }
        });
        setTotalCarbonFootprint(parseFloat(total).toFixed(3));
    }

    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (selectedCountries.length > 0) {
                Object.keys(collapseState).forEach(k => {
                    var found = false;
                    for (const item of selectedCountries) {
                        if (k === item.value) {
                            found = true;
                        }
                    }
                    if (!found) {
                        dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                    }
                });
                for (const item of selectedCountries) {
                    if (!collapseState.hasOwnProperty(item.value)) {
                        dispatchCollapseState({ type: "COLLAPSE_ADD", payload: { name: item.value, parent: currentParent } });
                    }
                }
            } else {
                Object.keys(collapseState).forEach(k => {
                    dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                });
            }
        }
        // eslint-disable-next-line
    }, [selectedCountries])

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                if (countries.length === 0) {
                    var tempGroup = [];
                    let temp = [];
                    for (const c of Object.keys(mainCountries)) {
                        if (props.master.hotelFactorMaster.hasOwnProperty(c)) {
                            temp.push({ value: c, label: c });
                        }
                    }
                    tempGroup.push(
                        {
                            label: "top",
                            options: temp
                        }
                    );
                    temp = [];
                    for (const c of props.master.country) {
                        if (props.master.hotelFactorMaster.hasOwnProperty(c.value) && !mainCountries.hasOwnProperty(c.value) && c.value !== "Other") {
                            temp.push({ value: c.value, label: c.value });
                        }
                    }
                    tempGroup.push(
                        {
                            label: "bottom",
                            options: temp
                        }
                    );
                    if (Object.keys(sources).length === 0) {
                        for (const tfm of Object.keys(props.master.transportFactorMaster)) {
                            if (!years.includes(props.master.transportFactorMaster[tfm]["year"])) {
                                years.push(props.master.transportFactorMaster[tfm]["year"]);
                            }

                            if (sources[props.master.transportFactorMaster[tfm]["year"]] === undefined) {
                                sources[props.master.transportFactorMaster[tfm]["year"]] = { source: [] };
                            }
                            if (!sources[props.master.transportFactorMaster[tfm]["year"]].source.includes(props.master.transportFactorMaster[tfm]["source"])) {
                                sources[props.master.transportFactorMaster[tfm]["year"]].source.push(props.master.transportFactorMaster[tfm]["source"]);
                                sources[props.master.transportFactorMaster[tfm]["year"]].source.sort();
                            }
                        }
                        for (const ffm of Object.keys(props.master.foodFactorMaster)) {
                            if (!years.includes(props.master.foodFactorMaster[ffm]["year"])) {
                                years.push(props.master.foodFactorMaster[ffm]["year"]);
                            }

                            if (sources[props.master.foodFactorMaster[ffm]["year"]] === undefined) {
                                sources[props.master.foodFactorMaster[ffm]["year"]] = { source: [] };
                            }
                            if (!sources[props.master.foodFactorMaster[ffm]["year"]].source.includes(props.master.foodFactorMaster[ffm]["source"])) {
                                sources[props.master.foodFactorMaster[ffm]["year"]].source.push(props.master.foodFactorMaster[ffm]["source"]);
                                sources[props.master.foodFactorMaster[ffm]["year"]].source.sort();
                            }
                        }
                        for (const wfm of Object.keys(props.master.wasteFactorMaster)) {
                            if (!years.includes(props.master.wasteFactorMaster[wfm]["year"])) {
                                years.push(props.master.wasteFactorMaster[wfm]["year"]);
                            }

                            if (sources[props.master.wasteFactorMaster[wfm]["year"]] === undefined) {
                                sources[props.master.wasteFactorMaster[wfm]["year"]] = { source: [] };
                            }
                            if (!sources[props.master.wasteFactorMaster[wfm]["year"]].source.includes(props.master.wasteFactorMaster[wfm]["source"])) {
                                sources[props.master.wasteFactorMaster[wfm]["year"]].source.push(props.master.wasteFactorMaster[wfm]["source"]);
                                sources[props.master.wasteFactorMaster[wfm]["year"]].source.sort();
                            }
                        }
                        for (const dfm of Object.keys(props.master.digitalFactorMaster)) {
                            if (!years.includes(props.master.digitalFactorMaster[dfm]["year"])) {
                                years.push(props.master.digitalFactorMaster[dfm]["year"]);
                            }

                            if (sources[props.master.digitalFactorMaster[dfm]["year"]] === undefined) {
                                sources[props.master.digitalFactorMaster[dfm]["year"]] = { source: [] };
                            }
                            if (!sources[props.master.digitalFactorMaster[dfm]["year"]].source.includes(props.master.digitalFactorMaster[dfm]["source"])) {
                                sources[props.master.digitalFactorMaster[dfm]["year"]].source.push(props.master.digitalFactorMaster[dfm]["source"]);
                                sources[props.master.digitalFactorMaster[dfm]["year"]].source.sort();
                            }
                        }
                        for (const hfm of Object.keys(props.master.hotelFactorMaster)) {
                            if (!years.includes(props.master.hotelFactorMaster[hfm]["year"])) {
                                years.push(props.master.hotelFactorMaster[hfm]["year"]);
                            }

                            if (sources[props.master.hotelFactorMaster[hfm]["year"]] === undefined) {
                                sources[props.master.hotelFactorMaster[hfm]["year"]] = { source: [] };
                            }
                            if (!sources[props.master.hotelFactorMaster[hfm]["year"]].source.includes(props.master.hotelFactorMaster[hfm]["source"])) {
                                sources[props.master.hotelFactorMaster[hfm]["year"]].source.push(props.master.hotelFactorMaster[hfm]["source"]);
                                sources[props.master.hotelFactorMaster[hfm]["year"]].source.sort();
                            }
                        }
                        years = years.sort((a, b) => a - b);
                    }
                    setCountries(tempGroup);
                }
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                setKey(`${componentId}-deliveryPeople`);
            } else {
                countryData = {};
                setKey(`${componentId}-deliveryPeople`);
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            let initial = 0;
            let target = 0;
            let actual = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].status === true) {
                    if (collapseState[key].cf.initial.visited) {
                        initial++;
                    }
                    if (collapseState[key].cf.target.visited) {
                        target++;
                    }
                    if (collapseState[key].cf.actual.visited) {
                        actual++;
                    }
                }
            });
            let len = Object.keys(collapseState).length;
            while (len !== 0) {
                if (len === actual) {
                    commonActiveTab = "actual";
                    break;
                }
                if (len === target) {
                    commonActiveTab = "target";
                    break;
                }
                if (len === initial) {
                    commonActiveTab = "initial";
                    break;
                }
                len--;
            }
            getTotalCarbonFootprint();
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
                reducerData = {};
                // console.log("Detailed Project Impact Component Added");
                setRefresh(prev => ++prev);
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    let data = {};
                    data["data"] = {};
                    var sCountries = [];
                    var st = "";
                    countryData = {};
                    props.data.data.forEach(obj => {
                        countryData = {
                            ...countryData,
                            [obj.country.name]: obj
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.country.name, parent: "deliveryPeople", status: true } });
                        for (const gc of countries) {
                            for (const c of gc.options) {
                                if (c.value === obj.country.name) {
                                    sCountries.push(c);
                                    break;
                                }
                            }
                        }
                        reducerData[obj.country.name] = {
                            initial: {
                                ftpCount: obj.initialFtpCount,
                                projectLength: obj.initialProjectLength,
                                plane: { totalHours: obj.initialPlaneTotalHours, unitaryEmissions: 0, cf: obj.initialPlaneCF },
                                highSpeedTrain: { totalHours: obj.initialHighSpeedTrainTotalHours, unitaryEmissions: 0, cf: obj.initialHighSpeedTrainCF },
                                averageTrain: { totalHours: obj.initialAverageTrainTotalHours, unitaryEmissions: 0, cf: obj.initialAverageTrainCF },
                                taxi: { totalHours: obj.initialTaxiTotalHours, unitaryEmissions: 0, cf: obj.initialTaxiCF },
                                car: { rateOfDailyJournies: obj.initialCarRateOfDailyJournies, avgJourneyHoursPerDay: obj.initialCarAvgJourneyHoursPerDay, unitaryEmissions: 0, cf: obj.initialCarCF },
                                publicTransport: { rateOfDailyJournies: obj.initialPublicTransportRateOfDailyJournies, avgJourneyHoursPerDay: obj.initialPublicTransportAvgJourneyHoursPerDay, unitaryEmissions: 0, cf: obj.initialPublicTransportCF },
                                hotel: { totalNights: obj.initialHotelTotalNights, unitaryEmissions: 0, cf: obj.initialHotelCF },
                                digital: { commit: obj.initialDigitalCommit, mbOfEmailSentPerDayPerPerson: 0, emissionsPerMbSent: 0, mbOfStokedSharepoint: 0, mbOfStokedSentEmail: 0, mbOfStokedReceivedEmail: 0, emissionsMbStockedPerYear: 0, avgLifeTimeLength: obj.initialDigitalAvgLifeTimeLength, cf: obj.initialDigitalCF },
                                // office: { rateOfClient: obj.initialOfficeRateOfClient, rateOfRemote: obj.initialOfficeRateOfRemote, numberKWHPerDayPerFTPAtOffice: 0, cf: obj.initialOfficeCF },
                                // wfh: { rateOfClient: obj.initialOfficeRateOfClient, rateOfRemote: obj.initialOfficeRateOfRemote, numberKWHPerDayPerFTPAtRemote: 0, cf: obj.initialOfficeCF },
                                office: { rateOfClient: obj.initialOfficeRateOfClient, rateOfRemote: obj.initialOfficeRateOfRemote, numberKWHPerDayPerFTPAtOffice: 0, cf: obj.initialOfficeCF },
                                wfh: { rateOfClient: obj.initialOfficeRateOfClient, rateOfRemote: obj.initialOfficeRateOfRemote, numberKWHPerDayPerFTPAtRemote: 0, cf: obj.initialWfhCF },
                                food: { committedNoOfMealsPerWeek: obj.initialFoodCommittedNoOfMealsPerWeek, unitaryEmissions: 0, cf: obj.initialFoodCF },
                                waste: { commit: obj.initialWasteCommit, unitaryEmissions: 0, cf: obj.initialWasteCF },
                                visited: obj.initialVisited === 1 ? true : false,
                                assumption: obj.initialAssumptions
                            },
                            target: {
                                ftpCount: obj.targetFtpCount,
                                projectLength: obj.targetProjectLength,
                                plane: { rateOfPlane: obj.targetPlaneRateOfPlane, rateOfHighSpeedTrain: obj.targetPlaneRateOfHighSpeedTrain, rateOfRemote: obj.targetPlaneRateOfRemote, unitaryEmissionsPlane: 0, unitaryEmissionsHighSpeedTrain: 0, cf: obj.targetPlaneCF },
                                highSpeedTrain: { rateOfHighSpeedTrain: obj.targetHighSpeedTrainRateOfHighSpeedTrain, rateOfRemote: obj.targetHighSpeedTrainRateOfRemote, unitaryEmissionsHighSpeedTrain: 0, cf: obj.targetHighSpeedTrainCF },
                                averageTrain: { rateOfAverageTrain: obj.targetAverageTrainRateOfAverageTrain, rateOfRemote: obj.targetAverageTrainRateOfRemote, unitaryEmissionsAverageTrain: 0, cf: obj.targetAverageTrainCF },
                                taxi: { rateOfTaxi: obj.targetTaxiRateOfTaxi, rateOfElectricTaxi: obj.targetTaxiRateOfElectricTaxi, rateOfPublicTransport: obj.targetTaxiRateOfPublicTransport, rateOfRemote: obj.targetTaxiRateOfRemote, unitaryEmissionsTaxi: 0, unitaryEmissionsElectricTaxi: 0, unitaryEmissionsPublicTransport: 0, cf: obj.targetTaxiCF },
                                car: { rateOfPrivateCar: obj.targetCarRateOfPrivateCar, rateOfPublicTransport: obj.targetCarRateOfPublicTransport, rateOfSoftTransport: obj.targetCarRateOfSoftTransport, unitaryEmissionsPrivateCar: 0, unitaryEmissionsPublicTransport: 0, unitaryEmissionsSoftTransport: 0, cf: obj.targetCarCF },
                                publicTransport: { rateOfPublicTransport: obj.targetPublicTransportRateOfPublicTransport, rateOfSoftTransport: obj.targetPublicTransportRateOfSoftTransport, unitaryEmissionsPublicTransport: 0, unitaryEmissionsSoftTransport: 0, cf: obj.targetPublicTransportCF },
                                hotel: { totalNights: obj.targetHotelTotalNights, unitaryEmissions: 0, cf: obj.targetHotelCF },
                                digital: { commit: obj.targetDigitalCommit, mbOfEmailSentPerDayPerPerson: 0, emissionsPerMbSent: 0, mbOfStokedSharepoint: 0, mbOfStokedSentEmail: 0, mbOfStokedReceivedEmail: 0, emissionsMbStockedPerYear: 0, avgLifeTimeLength: obj.targetDigitalAvgLifeTimeLength, rateOfDataAvoidedInEmailSentPerDayPerPerson: obj.targetDigitalRateOfDataAvoidedInEmailSentPerDayPerPerson, rateOfDataAvoidedInStokedSharepoint: obj.targetDigitalRateOfDataAvoidedInStokedSharepoint, rateOfDataAvoidedInStokedSentEmail: obj.targetDigitalRateOfDataAvoidedInStokedSentEmail, rateOfDataAvoidedInStokedReceivedEmail: obj.targetDigitalRateOfDataAvoidedInStokedReceivedEmail, cf: obj.targetDigitalCF },
                                office: { rateOfClient: obj.targetOfficeRateOfClient, rateOfRemote: obj.targetOfficeRateOfRemote, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: obj.targetOfficeCF },
                                wfh: { rateOfClient: obj.targetOfficeRateOfClient, rateOfRemote: obj.targetOfficeRateOfRemote, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: obj.targetWfhCF },
                                food: { unitaryEmissionsInNormalFood: 0, unitaryEmissionsInVegFood: 0, committedNoOfMealsPerWeek: obj.targetFoodCommittedNoOfMealsPerWeek, cf: obj.targetFoodCF },
                                waste: { commit: obj.targetWasteCommit, initialUnitaryEmissions: 0, targetUnitaryEmissions: 0, cf: obj.targetWasteCF },
                                visited: obj.targetVisited === 1 ? true : false,
                                assumption: obj.targetAssumptions
                            },
                            actual: {
                                ftpCount: obj.actualFtpCount,
                                projectLength: obj.actualProjectLength,
                                plane: { totalHours: obj.actualPlaneTotalHours, unitaryEmissions: 0, cf: obj.actualPlaneCF },
                                highSpeedTrain: { totalHours: obj.actualHighSpeedTrainTotalHours, unitaryEmissions: 0, cf: obj.actualHighSpeedTrainCF },
                                averageTrain: { totalHours: obj.actualAverageTrainTotalHours, unitaryEmissions: 0, cf: obj.actualAverageTrainCF },
                                taxi: { totalHoursTaxi: obj.actualTaxiTotalHoursTaxi, totalHoursElectricTaxi: obj.actualTaxiTotalHoursElectricTaxi, totalHoursPublicTransport: obj.actualTaxiTotalHoursPublicTransport, unitaryEmissionsTaxi: 0, unitaryEmissionsElectricTaxi: 0, unitaryEmissionsPublicTransport: 0, cf: obj.actualTaxiCF },
                                car: { rateOfDailyJournies: obj.actualCarRateOfDailyJournies, avgJourneyHoursPerDay: obj.actualCarAvgJourneyHoursPerDay, unitaryEmissions: 0, cf: obj.actualCarCF },
                                publicTransport: { rateOfDailyJournies: obj.actualPublicTransportRateOfDailyJournies, avgJourneyHoursPerDay: obj.actualPublicTransportAvgJourneyHoursPerDay, unitaryEmissions: 0, cf: obj.actualPublicTransportCF },
                                hotel: { totalNights: obj.actualHotelTotalNights, unitaryEmissions: 0, cf: obj.actualHotelCF },
                                digital: { commit: obj.actualDigitalCommit, mbOfEmailSentPerDayPerPerson: 0, emissionsPerMbSent: 0, mbOfStokedSharepoint: 0, mbOfStokedSentEmail: 0, mbOfStokedReceivedEmail: 0, emissionsMbStockedPerYear: 0, avgLifeTimeLength: obj.actualDigitalAvgLifeTimeLength, rateOfDataAvoidedInEmailSentPerDayPerPerson: obj.actualDigitalRateOfDataAvoidedInEmailSentPerDayPerPerson, rateOfDataAvoidedInStokedSharepoint: obj.actualDigitalRateOfDataAvoidedInStokedSharepoint, rateOfDataAvoidedInStokedSentEmail: obj.actualDigitalRateOfDataAvoidedInStokedSentEmail, rateOfDataAvoidedInStokedReceivedEmail: obj.actualDigitalRateOfDataAvoidedInStokedReceivedEmail, cf: obj.actualDigitalCF },
                                office: { rateOfClient: obj.actualOfficeRateOfClient, rateOfRemote: obj.actualOfficeRateOfRemote, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: obj.actualOfficeCF },
                                wfh: { rateOfClient: obj.actualOfficeRateOfClient, rateOfRemote: obj.actualOfficeRateOfRemote, numberKWHPerDayPerFTPAtOffice: 0, numberKWHPerDayPerFTPAtRemote: 0, cf: obj.actualWfhCF },
                                food: { unitaryEmissionsInNormalFood: 0, unitaryEmissionsInVegFood: 0, committedNoOfMealsPerWeek: obj.actualFoodCommittedNoOfMealsPerWeek, cf: obj.actualFoodCF },
                                waste: { commit: obj.actualWasteCommit, initialUnitaryEmissions: 0, targetUnitaryEmissions: 0, cf: obj.actualWasteCF },
                                visited: obj.actualVisited === 1 ? true : false,
                                assumption: obj.actualAssumptions
                            },
                        }
                        st = obj.createdAt;
                    });
                    setSelectedCountries(sCountries);
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);

    const ColoredLine = ({ color, height, styles }) => (
        <hr
            style={{
                backgroundColor: color,
                height: height,
                ...styles
            }}
        />
    );

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#7444DA", padding: "0px", position: "sticky", top: "50px", zIndex: 3, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "5px" }}>
                                    <span>Detailed Project Impact</span>
                                    <Link className={"calculatorActionDelete"} disabled={!formState.isValid} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Detailed Project Impact calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <div className="newDeliveryTabs">
                                <Tabs
                                    id={`${componentId}-newDeliveryTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >
                                    <Tab eventKey={`${componentId}-deliveryPeople`} title="" disabled={key !== "" && key !== `${componentId}-deliveryPeople`} key={`${componentId}-deliveryPeople`}>
                                        <Tab.Container>
                                            <ColoredLine color="#161616" height="1.5px" styles={{ margin: "0px" }} />
                                            <Row>
                                                <Col sm={12} style={{ textAlign: "left", paddingTop: "10px" }}>
                                                    <Controller
                                                        render={({ onChange, onBlur, value, name }) => (
                                                            <Select name={name} label={"Country"} formatGroupLabel={formatGroupLabel} closeMenuOnSelect={true} isMulti={true} onChange={(val) => { setUnsetCountry(val, "deliveryPeople"); return onChange(val) }} onBlur={onBlur} value={selectedCountries} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ zIndex: 10, width: "auto", flexGrow: 1, marginLeft: "10px", marginRight: "10px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", zIndex: 10 }} onClickColor="#7444DA80" singleValueFontSize={"12px"} />
                                                        )}
                                                        control={control}
                                                        name="country2"
                                                        defaultValue={''}
                                                    />
                                                </Col>
                                             {/*{   <Col sm={12}>
                                                    <Nav variant="pills" id={`${componentId}-deliveryPeoplePills`} className="flex-row newDeliveryPills">
                                                        {
                                                            Object.keys(collapseState).map(item => {
                                                                return (
                                                                    <Nav.Item key={`${componentId}-deliveryPeople-${item}-collapsePills`}>
                                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "deliveryPeople" && collapseState[item].status === true} disabled={collapseState[item].parent !== "deliveryPeople" && collapseState[item].status === true} aria-controls={`${componentId}-deliveryPeople-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "deliveryPeople", status: !collapseState[item].status } })}>{`${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            })
                                                        }
                                                    </Nav>
                                                </Col> */}
                                                <Col sm={12}>
                                                    <div className="newDeliveryPillsCollapse">
                                                        {
                                                            Object.keys(collapseState).map(item => (
                                                                <CollapseNewDeliveryCustom
                                                                    key={`${componentId}-deliveryPeople-${item}-collapse`}
                                                                    collapseControll={getLogic(item, "deliveryPeople")}
                                                                    data={countryData[item]}
                                                                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                                                    id={`${componentId}-deliveryPeople`}
                                                                    inputPlaceholder={`0`}
                                                                    inputName={item}
                                                                    inputReducerData={reducerData[item]}
                                                                    control={control}
                                                                    register={register}
                                                                    setValue={setValue}
                                                                    toastMessage={props.toastMessage}
                                                                    checkError={handleSubmit(data => { })}
                                                                    errors={errors}
                                                                    cFootprint={(val) => calulateTotalCF(val)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <div className="newDeliveryBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total {commonActiveTab}</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>project delivery impact</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted/ project duration</span>
                                    </Col>
                                </Row>
                                <Row style={{ width: "75%" }}>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                    <p>
                                                        <span>This calculation was done using reference data from</span>
                                                        <span style={{ color: "#f0f0f0" }}>
                                                            {
                                                                years.map(year => {
                                                                    return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                                })
                                                            }
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <></>
                                                )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)
            }
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(NewDeliveryTabs));