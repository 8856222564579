import React, { useState, useEffect } from "react";
import "./InputCustom.scss";
import Form from "react-bootstrap/form";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

var nStyle = {};
var lStyle = {};


const Percent = styled.div`
    display: inline-block;
    &:after {
        color: ${props => props.color};
        content: '${props => props.content}';
        font-family: "Ubuntu Mono";
        width: 1em;
        height: 1em;
        position: absolute;
        top: ${props => props.bottom ? (47 - parseInt(props.bottom)) : 47}%;
        left: ${props => props.align === "left" ? ((((props.len === 0 ? 0 : props.len - 1) * 8) + 22 + (props.left ? props.left : 0)) > 137 ? 137 : (((props.len === 0 ? 0 : props.len - 1) * 8) + 22 + (props.left ? props.left : 0))) + "px" : "unset"};
        right: ${props => props.align === "right" ? (((props.content.toString().length === 0 ? 0 : props.content.toString().length - 1) * 8) + 5 + (props.right ? props.right : 0)) + "px" : "unset"};
        display: block;
        font-size: 16px;
        pointer-events: none;
        transform: translateY(-0%);
    }
`;

function InputCustom(props) {
    let { id, size, type, name, label, prefixValue, style, autoComplete, register, errors, errorStyle, placeholder, labelStyle, formGroupStyle, suffix, suffixContent, suffixAlign, suffixLeft, suffixRight, suffixBottom, onChange, tooltip, tooltipPosition, tooltipMessage, ...rest } = props;
    size = (size === undefined) ? "small" : size;

    const [len, setLen] = useState(0);

    if (errors && errors[name]) {
        nStyle = { ...style, borderBottomColor: "rgb(235, 79, 107)" }
        lStyle = { ...labelStyle, color: "rgb(235, 79, 107)" }
    } else {
        nStyle = style;
        lStyle = labelStyle;
    }

    if (suffix !== undefined && suffix === true) {
        nStyle = { paddingRight: "25px", ...nStyle }
        formGroupStyle = { ...formGroupStyle, position: "relative" }
    }

    const onValueChange = (e) => {
        if (suffix === true) {
            setLen(e.target.value.toString().length);
        }

        if (onChange !== undefined && typeof onChange === "function")
            return onChange(e);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (suffix === true && prefixValue !== undefined) {
                setLen(`${prefixValue}`.length);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [prefixValue]);

    return (
        <React.Fragment key={`${id}-input-fragment`}>
            <Form.Group controlId={`${id}-group`} style={formGroupStyle} data-tip={errors && errors[name] ? errors[name]["message"] : tooltipMessage !== undefined && typeof tooltipMessage === "string" ? tooltipMessage : ""} data-for={`${id}-tooltip`}>
                {
                    suffix ? (
                        <Percent len={len} color={"#495057"} content={suffixContent === undefined ? '%' : suffixContent} key={`${id}-suffix`} align={suffixAlign} left={suffixLeft} right={suffixRight} bottom={suffixBottom} />
                    ) : (
                            <></>
                        )
                }
                <Form.Label style={lStyle}>{label}</Form.Label>
                <Form.Control type={type} name={name} autoComplete={autoComplete} ref={register} placeholder={placeholder} {...rest} onChange={e => onValueChange(e)} style={nStyle} />
            </Form.Group>
            {
                tooltip === true && ((errors && errors[name]) || (tooltipMessage !== undefined && typeof tooltipMessage === "string")) && (
                    <ReactTooltip id={`${id}-tooltip`} className='customInputTooltip' place={tooltipPosition} offset={{ top: 20 }} arrowColor="#000000" multiline={true} />
                )
            }
        </React.Fragment>
    )
}

InputCustom.defaultProps = {
    tooltipPosition: "top"
}

export default InputCustom;
