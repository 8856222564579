import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import CheckButton from "./CheckButton";
import Collapse from "react-bootstrap/collapse";
import NewCollapseDistanceVehicleTypeCustom from "./NewCollapseDistanceVehicleTypeCustom";
import { Element } from "react-scroll";

const NewCollapseCustom = (props) => {

  let { collapseControll, id, label, primaryCountry, inputName, onVehicleTypeChange, getValues, control, register, errors, vehicleType1 } = props;

  const [optionVehicleType, setOptionVehicleType] = useState({});

  const handleChange = (e) => {
    var elem2Name = e.target.name;
    setOptionVehicleType((prevValue) => {
      return {
        ...prevValue,
        [elem2Name]: {
          ...prevValue[elem2Name],
          state: !prevValue[elem2Name].state,
        },
      };
    });
  };

  const calulateTotalCF = (val) => {
    setOptionVehicleType(prev => {
      return {
        ...prev,
        [val.name]: { ...prev[val.name], cf: val.cf }
      }
    });
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted && collapseControll) {
      if (Object.keys(optionVehicleType).length > 0) {
        var totalCF = 0;
        var sourceValue, yearValue = null;
        for (const key in optionVehicleType) {
          if (optionVehicleType[key].state === true) {
            totalCF += typeof optionVehicleType[key].cf === "object" ? parseFloat(optionVehicleType[key].cf.value) : 0;
            sourceValue = optionVehicleType[key].cf.source;
            yearValue = optionVehicleType[key].cf.year;
          }
        }
        props.cFootprint({ name: inputName, parent: "distanceNew", cf: { value: totalCF, source: sourceValue, year: yearValue } });
      }
    }
    return () => isMounted = false;
    // eslint-disable-next-line
  }, [optionVehicleType])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (Object.keys(optionVehicleType).length > 0) {
        var data = { [inputName]: [] };
        for (const key in optionVehicleType) {
          if (optionVehicleType.hasOwnProperty(key)) {
            if (optionVehicleType[key].state) {
              data[inputName].push(key);
            }
          }
        }
        onVehicleTypeChange(data);
      }
    }
    return () => isMounted = false;
    // eslint-disable-next-line
  }, [optionVehicleType]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (props.master.status === true) {
        if (Object.keys(optionVehicleType).length === 0) {
          var temp = JSON.stringify(props.master.fuelVehicle1[inputName]);
          temp = JSON.parse(temp);
          if (vehicleType1 !== undefined && Object.keys(vehicleType1).length > 0) {
            for (const k in vehicleType1) {
              if (k === inputName) {
                for (const vt of vehicleType1[k]) {
                  temp[vt]["state"] = true;
                }
              }
            }
          }
          setOptionVehicleType(temp);
        }
      }
    }
    return () => isMounted = false;
    // eslint-disable-next-line
  }, [props.master.status]);

  return (
    <Collapse in={collapseControll}>
      <Element name={inputName !== "cng" ? `scrollFuelDistance${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollFuelDistanceCNG"} className="element" >
        <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
          <span style={{ color: "#f0f0f0", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>Please select vehicle type for {label === "Cng" ? "CNG" : label}</span>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className={`vehicleType`} style={{ paddingTop: "10px" }}>
                {
                  Object.keys(optionVehicleType).length > 0 ? (
                    <CheckButton
                      label=""
                      handleChange={handleChange}
                      options={optionVehicleType}
                    />
                  ) : (<></>)
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              {
                Object.keys(optionVehicleType).map(vt => {
                  return (
                    <NewCollapseDistanceVehicleTypeCustom
                      key={`${id}-${inputName}-distanceCollapse-${vt}-subCollapse`}
                      collapseControll={optionVehicleType[vt].state}
                      id={`${id}-${inputName}-distanceCollapse`}
                      primaryCountry={primaryCountry}
                      fuelType={inputName}
                      inputName={vt}
                      inputValue={getValues(`${id}-${inputName}-distanceCollapse-${vt}-distance-vt`)}
                      inputLabel={`${vt.charAt(0).toUpperCase()}${vt.slice(1)} distance`}
                      inputPlaceholder={"0.0"}
                      selectName={vt}
                      selectLabel={"Units"}
                      selectValue={getValues(`${id}-${inputName}-distanceCollapse-${vt}-distance-vt-select`)}
                      selectLadenValue={getValues(`${id}-${inputName}-distanceCollapse-${vt}-distance-vt-laden-select`)}
                      otherLadenValue={getValues(`${id}-${inputName}-distanceCollapse-${vt}-distance-vt-otherLaden`)}
                      control={control}
                      register={register}
                      errors={errors}
                      cFootprint={(val) => calulateTotalCF(val)}
                    />
                  )
                })
              }
            </Col>
          </Row>
          <hr style={{ border: "1px solid #F99500", borderRadius: "1px" }}></hr>
        </div>
      </Element>
    </Collapse >
  )
}

const mapStateToProps = (state) => {
  return {
    master: state.master
  };
}

export default connect(mapStateToProps)(memo(NewCollapseCustom));