	 import React, { useState, useEffect, useReducer, useRef } from "react";
     import axios from "axios";
     import { withRouter, useParams, useHistory } from "react-router-dom";
     import { connect } from "react-redux";
     import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
     import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";

     const environment = process.env.REACT_APP_API_ENVIRONMENT;
     const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;
     
     function useIsMountedRef() {
         const isMountedRef = useRef(null);
         useEffect(() => {
             isMountedRef.current = true;
             return () => {
                 isMountedRef.current = false;
             }
         });
         return isMountedRef;
     }
     
     const EMonitoringAPI = (props) => {
        
         const isMountedRef = useIsMountedRef();
         const [project, setProject] = useState({});
         const [calculators, setCalculators] = useState({});
         let { pid } = useParams();
         const history = useHistory();
     
         const apiData = () => {
             if(project){
                 pid = props.project.currentProjectId;
             } 
         }
         var projectId = pid;
     
         useEffect(() => {
             if (isMountedRef.current) {
                 if ((props.login.token !== "" && projectId !== "" && projectId !== undefined && Object.keys(calculators).length === 0)) {
                     axios.get(`${basePath}/project/${projectId}`, {
                         headers: {
                             authorization: `Bearer ${props.login.token}`
                         }
                     })
                         .then(res => {
                             if (isMountedRef.current) {
                                 setProject(res.data[0]);
                             }
                             history.push('/calculator',  { pid } );
                         })
                         .catch(err => {
                             const projIDError = err.response ? err.response.data.message : null;
                             if (projIDError && projIDError.includes("Cannot read properties")) {
                                 history.push('/projects');
                                 setTimeout(() => {
                                     alert("Project ID doesn't exist!");
                                 }, 1000);
                             } else if (projIDError && projIDError.includes("dont have the access to the project")) {
                                 history.push('/projects');
                                 setTimeout(() => {
                                     alert("Project exist but you don't have access to it");
                                 }, 1000);
                             }
                             else {
                                 console.log(projIDError, 'projIDError');
                             }
                         });
                     axios.get(`${basePath}/calculator/${projectId}`, {
                         headers: {
                             authorization: `Bearer ${props.login.token}`
                         }
                     })
                         .then(res => {
                             if (isMountedRef.current) {
                                 setCalculators(res.data);
                             }
                             history.push('/calculator', { pid } );
                         })
                         .catch(err => {
     
                         });
                 }
             }
             // eslint-disable-next-line
         }, [props.login.token, projectId, isMountedRef]);
     
         return (
                 <></>
         )
     
     }
     
     const mapStateToProps = (state) => {
         return {
             login: state.login,
             project: state.project,
             master: state.master
         };
     }
     
     export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(withRouter(EMonitoringAPI)));