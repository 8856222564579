import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForElectricityByAmount } from "../../calculatorFunctions/elec_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const CollapseElectricityConsumptionCustom = (props) => {
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [amount, setAmount] = useState(0);

    let { collapseControll, id, label, inputName, inputLabel, inputValue, inputPlaceholder, register, errors } = props;

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    const inputTextChange = (val) => {
        setAmount((val !== undefined && val !== "") ? val : 0);
    }

    const setCF = () => {
        var cf = 0;
        var generation = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].generation !== undefined ? props.master.electricityFactorMaster[inputName].generation : 0;
        var tnd = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].tnd !== undefined ? props.master.electricityFactorMaster[inputName].tnd : 0;
        var wtt = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].wtt !== undefined ? props.master.electricityFactorMaster[inputName].wtt : 0;
        var tndWtt = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].tndWtt !== undefined ? props.master.electricityFactorMaster[inputName].tndWtt : 0;
        var year = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].generationYear !== undefined ? props.master.electricityFactorMaster[inputName].generationYear : new Date().getFullYear();
        var source = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].generationSource !== undefined ? props.master.electricityFactorMaster[inputName].generationSource : null;

        cf = footprintForElectricityByAmount(amount, generation, tnd, wtt, tndWtt);
        cf = (parseFloat(cf) / 1000).toFixed(3);
        props.cFootprint({ name: inputName, parent: "electricityConsumption", cf: { value: cf, generation, tnd, wtt, tndWtt, year, source } });
        setCarbonFootprint(cf);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (inputValue !== undefined && inputValue !== "") {
                setAmount((inputValue !== undefined && inputValue !== "") ? inputValue : 0);
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [inputValue]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (amount !== undefined && amount !== "") {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [amount]);

    return (
        <Collapse in={collapseControll}>
            <Element name={`scrollElectricityElectricityconsumption${inputName.replace(" ", "")}`} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#FFFF00", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label}</span>
                    <Row>
                        <Col lg={4} md={4} sm={6}>
                            <div className={`${id}-${inputName}-electricityConsumptionCollapse`} >
                                <Input className="numberType" suffix={true} suffixContent={'kWh'} suffixAlign={'right'} onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-amount`} name={`${id}-${inputName}-amount`} label={inputLabel} placeholder={inputPlaceholder} tooltip={true}  register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", borderColor: "#6c757d", height: "40px", paddingRight: "45px" }} onChange={(e) => inputTextChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={6} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                            {
                                props.master.electricityFactorMaster[inputName] === undefined ? (
                                    <span>This country is currently not available !</span>
                                ) : (<></>)
                            }
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(CollapseElectricityConsumptionCustom);