import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Col,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/button/ButtonCustom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Input from "../../components/input/InputCustom";
import FocusTrap from "focus-trap-react";
import "./AddUsers.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
});

function useIsMountedRef() {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    }
  });
  return isMountedRef;
}

const AddUsers = (props) => {

  const projectId = props.project.currentProjectId;
  const isMountedRef = useIsMountedRef();
  const [isLoaded, setLoaded] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [project, setProject] = useState({});

  const { register, handleSubmit, errors, reset, formState, getValues, trigger } = useForm({
    mode: "all",
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const getData = () => {
    axios.get(`${basePath}/project/allUsersOfProject/${projectId}`, {
      headers: {
        authorization: "Bearer " + props.login.token,
      },
    })
      .then((res) => {
        setUsersData(res.data);
        setLoaded(true);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [props.login.token]);

  const deleteConfirm = async (data) => {
    await axios.put(
      `${basePath}/project/deleteUserFromProject/` + projectId,
      data,
      {
        headers: {
          authorization: "Bearer " + props.login.token,
        },
      }
    )
      .then((res) => {
        getData();
      }) // refresh data after delete
      .catch((err) => {
        // console.log(err)
      });
  };

  const submitForm = (data, e) => {
    e.preventDefault();
    axios.put(`${basePath}/project/addUserToProject/` + projectId, data, {
      headers: {
        authorization: "Bearer " + props.login.token,
      },
    })
      .then((res) => {
        reset();
        getData();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleClick = (dd) => {
    dd = { ...dd, role: "user" };
    deleteConfirm(dd);
  };

  useEffect(() => {
    if (isMountedRef.current) {
      if (props.login.token !== "" && projectId !== "" && projectId !== undefined) {
        axios.get(`${basePath}/project/${projectId}`, {
          headers: {
            authorization: `Bearer ${props.login.token}`
          }
        })
          .then(res => {
            if (isMountedRef.current) {
              setProject(res.data[0]);
            }
          })
          .catch(err => {
            // console.log(err);
          });
      }
    }
    // eslint-disable-next-line
  }, [props.login.token, projectId, isMountedRef]);

  return (
    <Container style={{ width: "90%", paddingTop: "18px", paddingBottom: "10px" }} >
      {
        Object.keys(project).length > 0 ? (
          <>
            <FocusTrap>
              <div className="trap" >
                <span style={{ float: "right", color: "#f0f0f0", marginTop: "5px", marginRight: "-10px", cursor: "pointer" }} onClick={props.modalClose}><FontAwesomeIcon size="1x" icon={faTimes} /></span>
                <div style={{ textAlign: "center" }}>
                  <h3 style={{ color: "grey" }}>
                    List of all users for project - {project.name}
                  </h3>
                </div>
                <hr />
                <div style={{ display: "inline-block", width: "100%", height: "226px", overflow: "auto" }} >
                  <Table id="projectUserTable" style={{ color: "#f0f0f0", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}>
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>UserName</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoaded ? (
                        usersData.map((d) => (
                          <tr key={d.email}>
                            <td>{d.email}</td>
                            <td>{d.username}</td>
                            <td>
                              <FontAwesomeIcon size="1x" icon={faTrash} className="addUsersActionDelete" onClick={() => { handleClick(d); }} />
                            </td>
                          </tr>
                        ))
                      ) : (
                          <></>
                        )}
                    </tbody>
                  </Table>
                </div>
                <hr />

                <Form onSubmit={handleSubmit(submitForm)}>
                  <Row>
                    <Col sm={12} md={8} lg={10} style={{ textAlign: "left" }} >
                      <Input
                        id={`email`}
                        type="email"
                        name={`email`}
                        placeholder={`Enter email address...`}
                        register={register}
                        errors={errors}
                        autoComplete={"off"}
                        label={"Email"}
                        labelStyle={{
                          fontWeight: "300",
                          fontSize: "12px",
                          paddingLeft: "12px",
                          marginBottom: "0px",
                        }}
                        formGroupStyle={{
                          flexGrow: 1,
                          borderRadius: 0,
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212",
                          marginRight: "3px",
                        }}
                        style={{
                          borderBottomWidth: "1px",
                          borderRadius: 0,
                          borderColor: "transparent",
                          borderBottomColor: "white",
                          height: "28px",
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212",
                          fontSize: "16px",
                          lineHeight: "36px",
                          color: "rgba(255, 255, 255, 0.87)",
                        }}
                        onChange={val => trigger()}
                      />
                    </Col>
                    <Col sm={12} md={4} lg={2} style={{ padding: "14px 16px 0px 0px" }} >
                      <Button variant="secondary" className="fullWidth" type="submit" disabled={!formState.isValid || getValues("email") === "" || getValues("email") === null || getValues("email") === undefined}>
                        Add User
                  </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </FocusTrap>
          </>
        ) : (
            <>
              <span>Loading...</span>
            </>
          )
      }
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    project: state.project
  };
};

export default connect(mapStateToProps)(AddUsers);
