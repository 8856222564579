import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForTransportCalc } from "../../calculatorFunctions/transport_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const unitOptions = [
    { value: "tonnes", label: "tonnes" },
    { value: "litres", label: "litres" }
];

const transportType = {
    "Aviation Spirit": "Aviation Spirit",
    "Aviation Turbine Fuel": "Aviation Turbine Fuel",
    "Marine Gas Oil": "Marine Gas Oil",
    "Marine Fuel Oil": "Marine Fuel Oil"
};

const CollapseTransportConsumptionCustom = (props) => {
    let { collapseControll, id, inputName, inputLabel, inputValue, selectLabel, selectUnitValue, control, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [volume, setVolume] = useState((inputValue === undefined || inputValue === "") ? 0 : inputValue);
    const [unit, setUnit] = useState((selectUnitValue === undefined || selectUnitValue === "") ? unitOptions[0] : selectUnitValue);

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const inputTextChange = (val) => {
        setVolume((val !== undefined && val !== "") ? val : 0);
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const setCF = () => {
        var name = transportType[inputName] + '_' + unit.value;
        var cf = 0;
        var amount = volume === undefined || volume === "" ? 0 : volume;
        var unitValue = unit.value;
        let year = props.master.transportLiquidFactorMaster.hasOwnProperty(name) && props.master.transportLiquidFactorMaster[name].year !== undefined ? props.master.transportLiquidFactorMaster[name].year : 0;
        let source = props.master.transportLiquidFactorMaster.hasOwnProperty(name) && props.master.transportLiquidFactorMaster[name].source !== undefined ? props.master.transportLiquidFactorMaster[name].source : null;
        let scope1 = props.master.transportLiquidFactorMaster.hasOwnProperty(name) && props.master.transportLiquidFactorMaster[name].scope1 !== undefined ? props.master.transportLiquidFactorMaster[name].scope1 : 0;
        let scope3 = props.master.transportLiquidFactorMaster.hasOwnProperty(name) && props.master.transportLiquidFactorMaster[name].scope3 !== undefined ? props.master.transportLiquidFactorMaster[name].scope3 : 0;
        if (amount > 0) {
            cf = footprintForTransportCalc(amount, unitValue, scope1, scope3);
        }
        props.cFootprint({
            name: inputName,
            parent: "transportConsumption",
            cf: { value: cf, volume, scope1, scope3, unit, year, source },
        });
        setCarbonFootprint(cf / 1000);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if (unit !== undefined && unit !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (volume !== undefined && volume !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [unit, volume]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "pvc" ? `scrollTransportconsumption${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollTransportconsumption"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-transportConsumptionCollapse`} style={{ fontSize: "12px", fontWeight: "normal" }} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-amount`} name={`${id}-${inputName}-amount`} label={inputLabel} placeholder={"0"} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "100%", marginRight: "0px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTextChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col>
                            <div className={`${id}-${inputName}-transportConsumptionCollapse`} style={{ fontSize: "12px", fontWeight: "normal" }} >
                                {
                                    control !== undefined ? (
                                        <Controller
                                            render={({ onChange, value, name }) => (
                                                <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label={selectLabel} defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#f37d7d" />
                                            )}
                                            control={control}
                                            name={`${id}-${inputName}-volume-select`}
                                            defaultValue={unit}
                                        />
                                    ) : (<></>)
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="carbonFootprint" >
                        <span>Carbon Footprint</span>
                        <br />
                        <br />
                        <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                        <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                    </div>
                    <hr style={{
                        color: '#f37d7d',
                        backgroundColor: '#f37d7d',
                        height: .5,
                        borderColor: '#f37d7d'
                    }} />
                </div>
            </Element>
        </Collapse >
    )
}
const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}
export default connect(mapStateToProps)(memo(CollapseTransportConsumptionCustom));