
const initialState = {
    sortBy: "updatedAt,DESC"
}

const sortReducer = (state = initialState, action) => {

    switch (action.type) {
        case "SET_SORT":
            state = {
                ...state,
                sortBy: action.payload.sortBy
            }
            break;
        case "RESET_SORT":
            state = initialState;
            break;
        default:
            break;
    }
    return state;
}

export default sortReducer;