import React, { useMemo } from "react";
import Form from "react-bootstrap/form";
import AsyncSelect from "react-select/async";
import ReactTooltip from "react-tooltip";
import "./SelectCustom.scss";

const defaultOptionsArray = [
    { value: 0, label: "Default Option A" },
    { value: 1, label: "Default Option B" },
    { value: 2, label: "Default Option C" }
]

const SelectCustom = (props) => {
    let { id, name, label, value, labelStyle, color, onClickColor, formGroupStyle, register, errors, cacheOptions, loadOptions, defaultOptions, onChange, onBlur, optionStyle, isSearchable, singleValueFontSize, valueContainer, dropdownIndicator, tooltip, tooltipPosition, tooltipMessage, ...rest } = props;
    color = color !== undefined ? color : "inherit";
    valueContainer = valueContainer !== undefined ? valueContainer : {};
    dropdownIndicator = dropdownIndicator !== undefined ? dropdownIndicator : {};
    if (optionStyle === undefined) {
        optionStyle = {}
        optionStyle["color"] = "inherit";
    } else if (!optionStyle["color"] === undefined) {
        optionStyle["color"] = "inherit";
    }
    var nStyle = useMemo(() => { }, []);
    var lStyle = {};

    const getOptionsData = (inputValue, callback) => {
        callback(defaultOptionsArray);
    }

    loadOptions = (loadOptions !== undefined && typeof loadOptions === "function") ? loadOptions : getOptionsData;
    onChange = typeof onChange === "function" ? onChange : () => {
        return null;
    };
    onBlur = typeof onBlur === "function" ? onBlur : () => {
        return null;
    };

    if (errors && errors[name]) {
        nStyle = { ...props.style, borderBottomColor: "rgb(235, 79, 107)" };
        lStyle = { ...labelStyle, color: "rgb(235, 79, 107)" };
    } else {
        nStyle = props.style;
        lStyle = labelStyle;
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                fontSize: singleValueFontSize !== undefined ? singleValueFontSize : "16px",
                backgroundColor: state.isFocused || state.isSelected ? onClickColor : 'inherit',
                ...optionStyle,
                paddingTop: "8px",
                "&:active": {
                    backgroundColor: state.isDisabled ? color : onClickColor
                },
                "&:hover": {
                    backgroundColor: state.isDisabled ? color : onClickColor
                },
                color: state.isDisabled ? "#f0f0f050" : optionStyle.color !== undefined ? optionStyle.color : "#f0f0f0"
            }),
            control: (provided, state) => ({
                ...provided,
                fontSize: singleValueFontSize !== undefined ? singleValueFontSize : "16px",
                ...nStyle,
                color: "white",
                boxShadow: "0 0 0 1px transparent !important",
                "&:hover": {
                    ...nStyle
                },
                "&:active": {
                    boxShadow: "0 0 0 1px transparent"
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: 10,
                margin: "0px",
                border: "0",
                padding: "0px",
                borderRadius: "0px",
                borderWidth: "0px",
                fontSize: singleValueFontSize !== undefined ? singleValueFontSize : "16px"
            }),
            menuList: (provided, state) => ({
                ...provided,
                margin: "0px",
                border: "0",
                padding: "0px",
                borderRadius: "0px",
                borderWidth: "0px"
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: color,
                fontSize: singleValueFontSize !== undefined ? singleValueFontSize : "16px"
            }),
            input: (provided, state) => ({
                ...provided,
                color: "#f0f0f0"
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                width: "0px"
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                ...dropdownIndicator,
                "&:hover": {
                    color: `white !important`
                }
            }),
            multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: "#cbcbcb"
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                ...valueContainer
            }),
            group: (provided, state) => ({
                ...provided,
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)"
            }),
            groupHeading: (provided, state) => ({
                ...provided,
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)"
            }),
            // eslint-disable-next-line
        }),
        [color, onClickColor, optionStyle, nStyle, singleValueFontSize, valueContainer, dropdownIndicator]
    );

    return (
        <React.Fragment key={`${id}-select-fragment`}>
            <Form.Group controlId={`${id}-group`} style={formGroupStyle} data-tip={errors && errors[name] ? errors[name]["message"] : tooltipMessage !== undefined && typeof tooltipMessage === "string" ? tooltipMessage : ""} data-for={`${id}-tooltip`}>
                <Form.Label style={lStyle}>{label}</Form.Label>
                <AsyncSelect
                    ref={register}
                    name={name}
                    cacheOptions={true}
                    loadOptions={loadOptions}
                    defaultOptions={defaultOptions ? true : false}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isSearchable={isSearchable !== undefined ? isSearchable : true}
                    {...rest}
                    styles={{ ...customStyles, ...nStyle }}
                    openMenuOnFocus={true}
                    backspaceRemovesValue={true}
                    captureMenuScroll={true}
                />
            </Form.Group>
            {
                tooltip === true && ((errors && errors[name]) || (tooltipMessage !== undefined && typeof tooltipMessage === "string")) && (
                    <ReactTooltip id={`${id}-tooltip`} className='customSelectTooltip' place={tooltipPosition} offset={{ top: 20 }} arrowColor="#000000" multiline={true} />
                )
            }
        </React.Fragment>
    )
}

SelectCustom.defaultProps = {
    tooltipPosition: "top"
}

export default SelectCustom;
