export const setFilterProjectId = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_PROJECT_ID",
            payload: { projectId: data }
        });
    }
}

export const setFilterProjectName = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_PROJECT_NAME",
            payload: { projectName: data }
        });
    }
}

export const setFilterSalesStage = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_SALES_STAGE",
            payload: { salesStage: data }
        });
    }
}

export const setFilterSector = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_SECTOR",
            payload: { sector: data }
        });
    }
}

export const setFilterDeliveryLine = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_DELIVERY_LINE",
            payload: { deliveryLine: data }
        });
    }
}

export const setFilterTypeOfEngagement = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_TYPE_OF_ENGAGEMENT",
            payload: { typeOfEngagement: data }
        });
    }
}

export const setFilterCountry = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_COUNTRY",
            payload: { country: data }
        });
    }
}

export const setFilterActivity = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_ACTIVITY",
            payload: { activity: data }
        });
    }
}

export const setFilterCTFuel = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_FUEL",
            payload: { ctFuel: data }
        });
    }
}

export const setFilterCTElectricity = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_ELECTRICITY",
            payload: { ctElectricity: data }
        });
    }
}

export const setFilterCTDelivery = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_DELIVERY",
            payload: { ctDelivery: data }
        });
    }
}

export const setFilterCTDataCentre = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_DATACENTRE",
            payload: { ctDataCentre: data }
        });
    }
}

export const setFilterCTWater = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_WATER",
            payload: { ctWater: data }
        });
    }
}

export const setFilterCTPaper = (data) => {
    console.log(data, 'data in setFilterCTPaper');
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_PAPER",
            payload: { ctPaper: data }
        });
    }
}


export const setFilterCTPlastic = (data) => {
    console.log(data, 'data in setFilterCTPlastic');
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_PLASTIC",
            payload: { ctPlastic: data }
        });
    }
}

export const setFilterCTMetal = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_METAL",
            payload: { ctMetal: data }
        });
    }
}

export const setFilterCTElectricalItems = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_ELECTRICAL_ITEMS",
            payload: { ctElectricalItems: data }
        });
    }
}

export const setFilterCTTransport = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_TRANSPORT",
            payload: { ctTransport: data }
        });
    }
}

export const setFilterCTAviation = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_AVIATION",
            payload: { ctAviation: data }
        });
    }
}

export const setFilterCTSeaFreight = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_CT_SEA_FREIGHT",
            payload: { ctSeaFreight: data }
        });
    }
}

export const setFilterTestProject = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_TEST_PROJECT",
            payload: { testProject: data }
        });
    }
}

export const setFilterFlagCf = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_FILTER_FLAG_CF",
            payload: { flagCf: data }
        });
    }
}

export const clearFilter = () => {
    return dispatch => {
        dispatch({
            type: "CLEAR_FILTER"
        });
    }
}

const filterAction = {
    setFilterProjectName,
    setFilterActivity,
    setFilterCountry,
    setFilterDeliveryLine,
    setFilterSalesStage,
    setFilterTypeOfEngagement,
    setFilterSector,
    setFilterCTFuel,
    setFilterCTElectricity,
    setFilterCTDelivery,
    setFilterCTDataCentre,
    setFilterCTWater,
    setFilterCTPaper,
    setFilterCTPlastic,
    setFilterCTMetal,
    setFilterCTElectricalItems,
    setFilterCTTransport,
    setFilterCTAviation,
    setFilterCTSeaFreight,
    setFilterTestProject,
    setFilterFlagCf,
    clearFilter
};

export default filterAction;