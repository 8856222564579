const { isNumeric, isString } = require('./utils.js');
require('./utils.js');

/**
 * INITIAL IMPACT FUNCTIONS
 * /

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) by plane.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours number of hours travelled over the length of the entire project (h)
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/h)
 */
function initialImpactPlane(ftpCount, projectLength, totalHours, unitaryEmissions) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return totalHours * unitaryEmissions
    } else {
        return 0;
    }
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) by train.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours number of hours travelled over the length of the entire project (h)
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/h)
 */
function initialImpactTrain(ftpCount, projectLength, totalHours, unitaryEmissions) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return totalHours * unitaryEmissions
    } else {
        return 0;
    }
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) by taxi.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours number of hours travelled over the length of the entire project (h)
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/h)
 */
function initialImpactTaxi(ftpCount, projectLength, totalHours, unitaryEmissions) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return totalHours * unitaryEmissions
    } else {
        return 0;
    }
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) by car.
 * 
 * @param {number} rateOfDailyJournies rate of dailys journey by individual car (%)
 * @param {number} avgJourneyHoursPerDay journey length (daily average for 1 FTP) (h)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} initialRateOfRemote rate of remote work in initial (%)
 */
function initialImpactCar(rateOfDailyJournies, avgJourneyHoursPerDay, ftpCount, projectLength, unitaryEmissions, initialRateOfRemote) {
    if (!isNumeric(rateOfDailyJournies)) throw TypeError("'rateOfDailyJournies' must be numeric")
    if (!isNumeric(avgJourneyHoursPerDay)) throw TypeError("'avgJourneyHoursPerDay' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (!isNumeric(initialRateOfRemote)) throw TypeError("'initialRateOfRemote' must be numeric")
    if (rateOfDailyJournies < 0 || rateOfDailyJournies > 100) return 0;
    if (initialRateOfRemote < 0 || initialRateOfRemote > 100) return 0;
    if (avgJourneyHoursPerDay < 0) throw RangeError("'avgJourneyHoursPerDay' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (initialRateOfRemote < 0) throw RangeError("'initialRateOfRemote' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return ((rateOfDailyJournies / 100) * avgJourneyHoursPerDay * ftpCount * projectLength * unitaryEmissions * (1 - (initialRateOfRemote / 100)))
    } else {
        return 0;
    }
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) by public transport.
 * 
 * @param {number} rateOfDailyJournies rate of dailys journey by public transport (%)
 * @param {number} avgJourneyHoursPerDay journey length (daily average for 1 FTP) (h)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} initialRateOfRemote rate of remote work in initial (%) 
 */
function initialImpactPublicTransport(rateOfDailyJournies, avgJourneyHoursPerDay, ftpCount, projectLength, unitaryEmissions, initialRateOfRemote) {
    if (!isNumeric(rateOfDailyJournies)) throw TypeError("'rateOfDailyJournies' must be numeric")
    if (!isNumeric(avgJourneyHoursPerDay)) throw TypeError("'avgJourneyHoursPerDay' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (!isNumeric(initialRateOfRemote)) throw TypeError("'initialRateOfRemote' must be numeric")
    if (rateOfDailyJournies < 0 || rateOfDailyJournies > 100) return 0;
    if (initialRateOfRemote < 0 || initialRateOfRemote > 100) return 0;
    if (avgJourneyHoursPerDay < 0) throw RangeError("'avgJourneyHoursPerDay' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (initialRateOfRemote < 0) throw RangeError("'initialRateOfRemote' can't be less than 0")
    return ((rateOfDailyJournies / 100) * avgJourneyHoursPerDay * ftpCount * projectLength * unitaryEmissions * (1 - (initialRateOfRemote / 100)));
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) at office.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} numberKWHPerDayPerFTP number of kWh per day per FTP
 */
function initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTP) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTP)) throw TypeError("'numberKWHPerDayPerFTP' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (numberKWHPerDayPerFTP < 0) throw RangeError("'numberKWHPerDayPerFTP' can't be less than 0")
    const office = ftpCount * projectLength * numberKWHPerDayPerFTP;
    return office
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) at office.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP
 */
 function initialImpactWFH(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTP' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTP' can't be less than 0")
    const office = ftpCount * projectLength * numberKWHPerDayPerFTPAtRemote;
    return office
}


/**
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} rateOfClient rate of client
 * @param {number} rateOfRemote rate of remote
 * @param {number} numberKWHPerDayPerFTPAtOffice number of kWh per day per FTP At Office
 * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP At Remote
 */

function initialImpactIWL(ftpCount, projectLength, rateOfClient, rateOfRemote, numberKWHPerDayPerFTPAtOffice, numberKWHPerDayPerFTPAtRemote) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(rateOfClient)) throw TypeError("'rateOfClient' must be numeric")
    if (!isNumeric(rateOfRemote)) throw TypeError("'rateOfRemote' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtOffice)) throw TypeError("'numberKWHPerDayPerFTPAtOffice' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTPAtRemote' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (rateOfClient < 0 || rateOfClient > 100) return 0;
    if (rateOfRemote < 0 || rateOfRemote > 100) return 0;
    if (numberKWHPerDayPerFTPAtOffice < 0) throw RangeError("'numberKWHPerDayPerFTPAtOffice' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTPAtRemote' can't be less than 0")
    let result = 0;
    const office = initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtOffice) * rateOfClient / 100
    const wfh = initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) * rateOfRemote / 100
    result = office;
    return result;
}


/**
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} rateOfClient rate of client
 * @param {number} rateOfRemote rate of remote
 * @param {number} numberKWHPerDayPerFTPAtOffice number of kWh per day per FTP At Office
 * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP At Remote
 */

function initialImpactIWLWFH(ftpCount, projectLength, rateOfClient, rateOfRemote, numberKWHPerDayPerFTPAtOffice, numberKWHPerDayPerFTPAtRemote) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(rateOfClient)) throw TypeError("'rateOfClient' must be numeric")
    if (!isNumeric(rateOfRemote)) throw TypeError("'rateOfRemote' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtOffice)) throw TypeError("'numberKWHPerDayPerFTPAtOffice' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTPAtRemote' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (rateOfClient < 0 || rateOfClient > 100) return 0;
    if (rateOfRemote < 0 || rateOfRemote > 100) return 0;
    if (numberKWHPerDayPerFTPAtOffice < 0) throw RangeError("'numberKWHPerDayPerFTPAtOffice' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTPAtRemote' can't be less than 0")
    let result = 0;
    // const office = initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtOffice) * rateOfClient / 100
    const wfh = initialImpactWFH(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) * rateOfRemote / 100
    result = wfh;
    console.log(result, 'initial wfh');
    return result;
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) at hotel.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} numberOfNights number of nights at hotel
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/roomNights) [country based]
 */

function initialImpactHotelNights(numberOfNights, unitaryEmissions, ftpCount, projectLength) {
    if (!isNumeric(numberOfNights)) throw TypeError("'numberOfNights' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (numberOfNights < 0) throw RangeError("'numberOfNights' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    const numberOfNightsHotel = numberOfNights * unitaryEmissions;
    if (ftpCount !== 0 && projectLength !== 0) {
        return numberOfNightsHotel;        
    } else {
        return 0;
    }
}


/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) in waste.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/pers/d)
 */
function initialImpactWaste(ftpCount, projectLength, unitaryEmissions) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    return ftpCount * projectLength * unitaryEmissions;
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) in normal food.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} unitaryEmissions unitary emissions in normal food in (kgCO<sub>2</sub>equivalent/meal)
 */
function initialImpactFood(ftpCount, projectLength, unitaryEmissions) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    return ftpCount * projectLength * unitaryEmissions;
}

/**
 * Calculates initial impact on carbon footprint (kgCO<sub>2</sub>e) in digital.
 * 
 * @param {number} mbOfEmailSentPerDayPerPerson Mb sent (per day per person)
 * @param {number} emissionsPerMbSent emission per Mb sent (kgCO<sub>2</sub>equivalent/Mb) 
 * @param {number} mbOfStokedSharepoint Mb stocked (total sharepoint)
 * @param {number} mbOfStokedSentEmail Mo stocked (sent emails)
 * @param {number} mbOfStokedReceivedEmail Mb stocked (mail received)
 * @param {number} emissionsMbStockedPerYear  emission per mb sent per year (kgCO<sub>2</sub>equivalent/Mb/y) 
 * @param {number} avgLifeTimeLength Average lifetime length (year(s))
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function initialImpactDigital(mbOfEmailSentPerDayPerPerson, emissionsPerMbSent, mbOfStokedSharepoint, mbOfStokedSentEmail, mbOfStokedReceivedEmail, emissionsMbStockedPerYear, avgLifeTimeLength, ftpCount, projectLength) {
    if (!isNumeric(mbOfEmailSentPerDayPerPerson)) throw TypeError("'mbOfEmailSentPerDayPerPerson' must be numeric")
    if (!isNumeric(emissionsPerMbSent)) throw TypeError("'emissionsPerMbSent' must be numeric")
    if (!isNumeric(mbOfStokedSharepoint)) throw TypeError("'mbOfStokedSharepoint' must be numeric")
    if (!isNumeric(mbOfStokedSentEmail)) throw TypeError("'mbOfStokedSentEmail' must be numeric")
    if (!isNumeric(mbOfStokedReceivedEmail)) throw TypeError("'mbOfStokedReceivedEmail' must be numeric")
    if (!isNumeric(emissionsMbStockedPerYear)) throw TypeError("'emissionsMbStockedPerYear' must be numeric")
    if (!isNumeric(avgLifeTimeLength)) throw TypeError("'avgLifeTimeLength' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (mbOfEmailSentPerDayPerPerson < 0) throw RangeError("'mbOfEmailSentPerDayPerPerson' can't be less than 0")
    if (emissionsPerMbSent < 0) throw RangeError("'emissionsPerMbSent' can't be less than 0")
    if (mbOfStokedSharepoint < 0) throw RangeError("'mbOfStokedSharepoint' can't be less than 0")
    if (mbOfStokedSentEmail < 0) throw RangeError("'mbOfStokedSentEmail' can't be less than 0")
    if (mbOfStokedReceivedEmail < 0) throw RangeError("'mbOfStokedReceivedEmail' can't be less than 0")
    if (emissionsMbStockedPerYear < 0) throw RangeError("'emissionsMbStockedPerYear' can't be less than 0")
    if (avgLifeTimeLength < 0) throw RangeError("'avgLifeTimeLength' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    const totalEmissionsByEmailSentPerDayPerPerson = mbOfEmailSentPerDayPerPerson * emissionsPerMbSent * ftpCount * projectLength;
    const totalEmissionsByStockedSharepoint = mbOfStokedSharepoint * emissionsMbStockedPerYear * avgLifeTimeLength;
    const totalEmissionsByStockedSentEmail = mbOfStokedSentEmail * emissionsMbStockedPerYear * avgLifeTimeLength * ftpCount * projectLength;
    const totalEmissionsByStockedReceivedEmail = mbOfStokedReceivedEmail * emissionsMbStockedPerYear * avgLifeTimeLength * ftpCount * projectLength;
    if (ftpCount !== 0 && projectLength !== 0) {
        return totalEmissionsByEmailSentPerDayPerPerson + totalEmissionsByStockedSharepoint + totalEmissionsByStockedSentEmail + totalEmissionsByStockedReceivedEmail;
    } else {
        return 0;
    }
}

/**
 * ADJUSTED IMPACT FUNCTIONS
 * /

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) by plane.
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours total number of hours travelled by plane over the length of the entire project initially (h)
 * @param {number} newPlaneJourneyPercent new target rate of journeys taken in plane over the length of the entire project (%)
 * @param {number} newTrainJourneyPercent new target rate of journeys taken in train over the length of the entire project (%)
 * @param {number} newRemoteWorkPercent new target rate of remote work (%)
 * @param {number} unitaryEmissionsPlane unitary emissions in plane in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} unitaryEmissionsTrain unitary emissions in train in (kgCO<sub>2</sub>equivalent/h)
 */
function targetImpactPlane(ftpCount, projectLength, totalHours, newPlaneJourneyPercent, newTrainJourneyPercent, newRemoteWorkPercent, unitaryEmissionsPlane, unitaryEmissionsTrain) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(newPlaneJourneyPercent)) throw TypeError("'newPlaneJourneyPercent' must be numeric")
    if (!isNumeric(newTrainJourneyPercent)) throw TypeError("'newTrainJourneyPercent' must be numeric")
    if (!isNumeric(newRemoteWorkPercent)) throw TypeError("'newRemoteWorkPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsPlane)) throw TypeError("'unitaryEmissionsPlane' must be numeric")
    if (!isNumeric(unitaryEmissionsTrain)) throw TypeError("'unitaryEmissionsTrain' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (newPlaneJourneyPercent < 0 || newPlaneJourneyPercent > 100) return 0;
    if (newTrainJourneyPercent < 0 || newTrainJourneyPercent > 100) return 0;
    if (newRemoteWorkPercent < 0 || newRemoteWorkPercent > 100) return 0;
    if (newPlaneJourneyPercent + newTrainJourneyPercent + newRemoteWorkPercent > 100) return 0;
    if (unitaryEmissionsPlane < 0) throw RangeError("'unitaryEmissionsPlane' can't be less than 0")
    if (unitaryEmissionsTrain < 0) throw RangeError("'unitaryEmissionsTrain' can't be less than 0")
    const targetPlaneInitialCf = initialImpactPlane(ftpCount, projectLength, ((totalHours * newPlaneJourneyPercent) / 100), unitaryEmissionsPlane);
    if (ftpCount !== 0 && projectLength !== 0) {
        return targetPlaneInitialCf
    } else {
        return 0;
    }

    //return initialImpactPlane(((totalHours * newPlaneJourneyPercent) / 100), unitaryEmissionsPlane) + initialImpactTrain(((totalHours * newTrainJourneyPercent * 4) / 100), unitaryEmissionsTrain)
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) by train.
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours total number of hours travelled by train over the length of the entire project intially (h)
 * @param {number} newTrainJourneyPercent new target rate of journeys taken in train over the length of the entire project (%)
 * @param {number} newRemoteWorkPercent new target rate of remote work (%)
 * @param {number} unitaryEmissionsTrain unitary emissions in train in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} totalPlaneHours total number of hours travelled by plane over the length of the entire project initially (h)
 * @param {number} newHSTrainJourneyPercent new target rate of journeys taken in train over the length of the entire project (%)
 */
function targetImpactHSTrain(ftpCount, projectLength, totalHours, newTrainJourneyPercent, newRemoteWorkPercent, unitaryEmissionsTrain, totalPlaneHours, newHSTrainJourneyPercent) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(newTrainJourneyPercent)) throw TypeError("'newTrainJourneyPercent' must be numeric")
    if (!isNumeric(newRemoteWorkPercent)) throw TypeError("'newRemoteWorkPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsTrain)) throw TypeError("'unitaryEmissionsTrain' must be numeric")
    if (!isNumeric(totalPlaneHours)) throw TypeError("'totalPlaneHours' must be numeric")
    if (!isNumeric(newHSTrainJourneyPercent)) throw TypeError("'newHSTrainJourneyPercent' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (newTrainJourneyPercent < 0 || newTrainJourneyPercent > 100) return 0;
    if (newRemoteWorkPercent < 0 || newRemoteWorkPercent > 100) return 0;
    if (newTrainJourneyPercent + newRemoteWorkPercent > 100) return 0;
    if (newHSTrainJourneyPercent < 0 || newHSTrainJourneyPercent > 100) return 0;
    if (unitaryEmissionsTrain < 0) throw RangeError("'unitaryEmissionsTrain' can't be less than 0")

    let result = 0;
    const trainimpact = initialImpactTrain(ftpCount, projectLength, ((totalHours * newTrainJourneyPercent) / 100), unitaryEmissionsTrain);
    const planetrainimpact = initialImpactTrain(ftpCount, projectLength, ((totalPlaneHours * newHSTrainJourneyPercent * 4) / 100), unitaryEmissionsTrain);
    result = trainimpact + planetrainimpact;
    if (ftpCount !== 0 && projectLength !== 0) {
        return result
    } else {
        return 0;
    }

    //return initialImpactTrain(((totalHours * newTrainJourneyPercent) / 100), unitaryEmissionsTrain)
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) by train.
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours total number of hours travelled by train over the length of the entire project intially (h)
 * @param {number} newTrainJourneyPercent new target rate of journeys taken in train over the length of the entire project (%)
 * @param {number} newRemoteWorkPercent new target rate of remote work (%)
 * @param {number} unitaryEmissionsTrain unitary emissions in train in (kgCO<sub>2</sub>equivalent/h)
 */
function targetImpactTrain(ftpCount, projectLength, totalHours, newTrainJourneyPercent, newRemoteWorkPercent, unitaryEmissionsTrain) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(newTrainJourneyPercent)) throw TypeError("'newTrainJourneyPercent' must be numeric")
    if (!isNumeric(newRemoteWorkPercent)) throw TypeError("'newRemoteWorkPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsTrain)) throw TypeError("'unitaryEmissionsTrain' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (newTrainJourneyPercent < 0 || newTrainJourneyPercent > 100) return 0;
    if (newRemoteWorkPercent < 0 || newRemoteWorkPercent > 100) return 0;
    if (newTrainJourneyPercent + newRemoteWorkPercent > 100) return 0;
    if (unitaryEmissionsTrain < 0) throw RangeError("'unitaryEmissionsTrain' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return initialImpactTrain(ftpCount, projectLength, ((totalHours * newTrainJourneyPercent) / 100), unitaryEmissionsTrain)
    } else {
        return 0;
    }

}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) by taxi.
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalHours total number of hours travelled by taxi over the length of the entire project intially (h)
 * @param {number} newTaxiJourneyPercent new target rate of journeys taken in taxi over the length of the entire project (%)
 * @param {number} newTaxiGreenJourneyPercent new target rate of journeys taken in taxi-green over the length of the entire project (%)
 * @param {number} newPublicTransportJourneyPercent new target rate of journeys taken in public transport over the length of the entire project (%)
 * @param {number} newRemoteWorkPercent new target rate of remote work (%)
 * @param {number} unitaryEmissionsTaxi unitary emissions in taxi in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} unitaryEmissionsTaxiGrreen unitary emissions in taxi-green in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} unitaryEmissionsPublicTransport unitary emissions in public transport in (kgCO<sub>2</sub>equivalent/h)
 */
function targetImpactTaxi(ftpCount, projectLength, totalHours, newTaxiJourneyPercent, newTaxiGreenJourneyPercent, newPublicTransportJourneyPercent, newRemoteWorkPercent, unitaryEmissionsTaxi, unitaryEmissionsTaxiGreen, unitaryEmissionsPublicTransport) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHours)) throw TypeError("'totalHours' must be numeric")
    if (!isNumeric(newTaxiJourneyPercent)) throw TypeError("'newTaxiJourneyPercent' must be numeric")
    if (!isNumeric(newTaxiGreenJourneyPercent)) throw TypeError("'newTaxiGreenJourneyPercent' must be numeric")
    //  if (!isNumeric(newPublicTransportJourneyPercent)) throw TypeError("'newPublicTransportJourneyPercent' must be numeric")
    if (!isNumeric(newRemoteWorkPercent)) throw TypeError("'newRemoteWorkPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsTaxi)) throw TypeError("'unitaryEmissionsTaxi' must be numeric")
    if (!isNumeric(unitaryEmissionsTaxiGreen)) throw TypeError("'unitaryEmissionsTaxiGreen' must be numeric")
    //  if (!isNumeric(unitaryEmissionsPublicTransport)) throw TypeError("'unitaryEmissionsPublicTransport' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHours < 0) throw RangeError("'totalHours' can't be less than 0")
    if (newTaxiJourneyPercent < 0 || newTaxiJourneyPercent > 100) return 0;
    if (newTaxiGreenJourneyPercent < 0 || newTaxiGreenJourneyPercent > 100) return 0;
    //  if (newPublicTransportJourneyPercent < 0 || newPublicTransportJourneyPercent > 100) return 0;
    if (newRemoteWorkPercent < 0 || newRemoteWorkPercent > 100) return 0;
    if (newTaxiJourneyPercent + newTaxiGreenJourneyPercent + newPublicTransportJourneyPercent + newRemoteWorkPercent > 100) return 0;
    if (unitaryEmissionsTaxi < 0) throw RangeError("'unitaryEmissionsTaxi' can't be less than 0")
    if (unitaryEmissionsTaxiGreen < 0) throw RangeError("'unitaryEmissionsTaxiGreen' can't be less than 0")
    //  if (unitaryEmissionsPublicTransport < 0) throw RangeError("'unitaryEmissionsPublicTransport' can't be less than 0")
    let total = 0;
    const taxitotal = initialImpactTaxi(ftpCount, projectLength, ((totalHours * newTaxiJourneyPercent) / 100), unitaryEmissionsTaxi)
    const taxiGtotal = initialImpactTaxi(ftpCount, projectLength, ((totalHours * newTaxiGreenJourneyPercent) / 100), unitaryEmissionsTaxiGreen)
    total = taxitotal + taxiGtotal;
    if (ftpCount !== 0 && projectLength !== 0) {
        return total
    } else {
        return 0;
    }

}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) by personal car.
 * 
 * @param {number} newCarJourneyPercent new target rate of journeys taken in personal car over the length of the entire project (%)
 * @param {number} newPublicTransportJourneyPercent new target rate of journeys taken in public transport over the length of the entire project (%)
 * @param {number} newSoftTransportJourneyPercent new target rate of journeys taken in soft-transport over the length of the entire project (%)
 * @param {number} newRemoteWorkPercent new target rate of remote work taken over the length of the entire project (%)
 * @param {number} unitaryEmissionsCar unitary emissions in personal car in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} unitaryEmissionsPublicTransport unitary emissions in public transport in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} unitaryEmissionsSoftTransport unitary emissions in soft transport in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} rateOfDailyCarJournies rate of dailys journey by car (%)
 * @param {number} avgCarJourneyHoursPerDay journey length by car (daily average for 1 FTP) (h)
 * @param {number} rateOfDailyPublicTransportJournies rate of dailys journey by public transport (%)
 * @param {number} avgPublicTransportJourneyHoursPerDay journey length by public transport (daily average for 1 FTP) (h)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function targetImpactCar(newCarJourneyPercent, newPublicTransportJourneyPercent, newSoftTransportJourneyPercent, newRemoteWorkPercent, rateOfDailyCarJournies, avgCarJourneyHoursPerDay, rateOfDailyPublicTransportJournies, avgPublicTransportJourneyHoursPerDay, ftpCount, projectLength, unitaryEmissionsCar, unitaryEmissionsPublicTransport, unitaryEmissionsSoftTransport = 0) {
    if (!isNumeric(newCarJourneyPercent)) throw TypeError("'newCarJourneyPercent' must be numeric")
    if (!isNumeric(newPublicTransportJourneyPercent)) throw TypeError("'newPublicTransportJourneyPercent' must be numeric")
    if (!isNumeric(newSoftTransportJourneyPercent)) throw TypeError("'newSoftTransportJourneyPercent' must be numeric")
    if (!isNumeric(newRemoteWorkPercent)) throw TypeError("'newRemoteWorkPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsCar)) throw TypeError("'unitaryEmissionsCar' must be numeric")
    if (!isNumeric(unitaryEmissionsPublicTransport)) throw TypeError("'unitaryEmissionsPublicTransport' must be numeric")
    if (!isNumeric(unitaryEmissionsSoftTransport)) throw TypeError("'unitaryEmissionsSoftTransport' must be numeric")
    if (!isNumeric(rateOfDailyCarJournies)) throw TypeError("'rateOfDailyCarJournies' must be numeric")
    if (!isNumeric(avgCarJourneyHoursPerDay)) throw TypeError("'avgCarJourneyHoursPerDay' must be numeric")
    if (!isNumeric(rateOfDailyPublicTransportJournies)) throw TypeError("'rateOfDailyPublicTransportJournies' must be numeric")
    if (!isNumeric(avgPublicTransportJourneyHoursPerDay)) throw TypeError("'avgPublicTransportJourneyHoursPerDay' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (newCarJourneyPercent < 0 || newCarJourneyPercent > 100) return 0;
    if (newPublicTransportJourneyPercent < 0 || newPublicTransportJourneyPercent > 100) return 0;
    if (newSoftTransportJourneyPercent < 0 || newSoftTransportJourneyPercent > 100) return 0;
    if (newRemoteWorkPercent < 0 || newRemoteWorkPercent > 100) return 0;
    if (newCarJourneyPercent + newPublicTransportJourneyPercent + newSoftTransportJourneyPercent > 100) return 0;
    if (unitaryEmissionsCar < 0) throw RangeError("'unitaryEmissionsCar' can't be less than 0")
    if (unitaryEmissionsPublicTransport < 0) throw RangeError("'unitaryEmissionsPublicTransport' can't be less than 0")
    if (unitaryEmissionsSoftTransport < 0) throw RangeError("'unitaryEmissionsSoftTransport' can't be less than 0")
    if (rateOfDailyCarJournies < 0) throw RangeError("'rateOfDailyCarJournies' can't be less than 0")
    if (avgCarJourneyHoursPerDay < 0) throw RangeError("'avgCarJourneyHoursPerDay' can't be less than 0")
    if (rateOfDailyPublicTransportJournies < 0) throw RangeError("'rateOfDailyPublicTransportJournies' can't be less than 0")
    if (avgPublicTransportJourneyHoursPerDay < 0) throw RangeError("'avgPublicTransportJourneyHoursPerDay' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return (((rateOfDailyCarJournies / 100) * avgCarJourneyHoursPerDay * ftpCount * projectLength) * (newCarJourneyPercent / 100) * (1 - (newRemoteWorkPercent / 100)) * unitaryEmissionsCar)
    } else {
        return 0;
    }

    //  + parseFloat(avgCarJourneyHoursPerDay === 0 ? 0 : (((((rateOfDailyCarJournies / 100) * avgCarJourneyHoursPerDay * ftpCount * projectLength) * (newPublicTransportJourneyPercent / 100) * (1 - (newRemoteWorkPercent / 100)) * avgPublicTransportJourneyHoursPerDay) / avgCarJourneyHoursPerDay) * unitaryEmissionsPublicTransport)))
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) by public transport.
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} newPublicTransportJourneyPercent new target rate of journeys taken in public transport over the length of the entire project (%)
 * @param {number} newSoftTransportJourneyPercent new target rate of journeys taken in soft-transport over the length of the entire project (%)
 * @param {number} newRemoteWorkPercent new target rate of remote work taken over the length of the entire project (%)
 * @param {number} unitaryEmissionsPublicTransport unitary emissions in public transport in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} unitaryEmissionsSoftTransport unitary emissions in soft transport in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} rateOfDailyPublicTransportJournies rate of dailys journey by public transport (%)
 * @param {number} avgPublicTransportJourneyHoursPerDay journey length by public transport (daily average for 1 FTP) (h)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} totalTXIHours total number of hours travelled by taxi over the length of the entire project intially (h)
 * @param {number} newTXIPublicTransportJourneyPercent new target rate of journeys taken in public transport over the length of the entire project (%)
 * @param {number} unitaryEmissionsTXIPublicTransport unitary emissions in public transport in (kgCO<sub>2</sub>equivalent/h)
 */

 function targetImpactPublicTransport(newPublicTransportJourneyPercent, newSoftTransportJourneyPercent, newRemoteWorkPercent, rateOfDailyPublicTransportJournies, avgPublicTransportJourneyHoursPerDay, ftpCount, projectLength, unitaryEmissionsPublicTransport, unitaryEmissionsSoftTransport = 0, totalTXIHours, newTXIPublicTransportJourneyPercent, unitaryEmissionsTXIPublicTransport, avgCarJourneyHoursPerDay, rateOfDailyCarJournies, newCarReplacePubliceTarnsprot) {
    if (!isNumeric(newPublicTransportJourneyPercent)) throw TypeError("'newPublicTransportJourneyPercent' must be numeric")
    if (!isNumeric(newSoftTransportJourneyPercent)) throw TypeError("'newSoftTransportJourneyPercent' must be numeric")
    if (!isNumeric(newRemoteWorkPercent)) throw TypeError("'newRemoteWorkPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsPublicTransport)) throw TypeError("'unitaryEmissionsPublicTransport' must be numeric")
    if (!isNumeric(unitaryEmissionsSoftTransport)) throw TypeError("'unitaryEmissionsSoftTransport' must be numeric")
    if (!isNumeric(rateOfDailyPublicTransportJournies)) throw TypeError("'rateOfDailyPublicTransportJournies' must be numeric")
    if (!isNumeric(avgPublicTransportJourneyHoursPerDay)) throw TypeError("'avgPublicTransportJourneyHoursPerDay' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(newTXIPublicTransportJourneyPercent)) throw TypeError("'newPublicTransportJourneyPercent' must be numeric")
    if (!isNumeric(unitaryEmissionsTXIPublicTransport)) throw TypeError("'unitaryEmissionsPublicTransport' must be numeric")
    if (!isNumeric(avgCarJourneyHoursPerDay)) throw TypeError("'avgCarJourneyHoursPerDay' must be numeric")
    if (!isNumeric(rateOfDailyCarJournies)) throw TypeError("'rateOfDailyCarJournies' must be numeric")
    if (!isNumeric(newCarReplacePubliceTarnsprot)) throw TypeError("'newCarReplacePubliceTarnsprot' must be numeric")    
    if (newTXIPublicTransportJourneyPercent < 0 || newTXIPublicTransportJourneyPercent > 100) return 0;
    if (unitaryEmissionsTXIPublicTransport < 0) throw RangeError("'unitaryEmissionsPublicTransport' can't be less than 0")
    if (newPublicTransportJourneyPercent < 0 || newPublicTransportJourneyPercent > 100) return 0;
    if (newSoftTransportJourneyPercent < 0 || newSoftTransportJourneyPercent > 100) return 0;
    if (newRemoteWorkPercent < 0 || newRemoteWorkPercent > 100) return 0;
    if (newPublicTransportJourneyPercent + newSoftTransportJourneyPercent > 100) return 0;
    if (unitaryEmissionsPublicTransport < 0) throw RangeError("'unitaryEmissionsPublicTransport' can't be less than 0")
    if (unitaryEmissionsSoftTransport < 0) throw RangeError("'unitaryEmissionsSoftTransport' can't be less than 0")
    if (rateOfDailyPublicTransportJournies < 0) throw RangeError("'rateOfDailyPublicTransportJournies' can't be less than 0")
    if (avgPublicTransportJourneyHoursPerDay < 0) throw RangeError("'avgPublicTransportJourneyHoursPerDay' can't be less than 0")
    if (avgCarJourneyHoursPerDay < 0) throw RangeError("'avgCarJourneyHoursPerDay' can't be less than 0")    
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    
    let total = 0;    
    const TIPT = (((rateOfDailyPublicTransportJournies / 100) * avgPublicTransportJourneyHoursPerDay * ftpCount * projectLength) * (newPublicTransportJourneyPercent / 100) * (1 - (newRemoteWorkPercent / 100)) * unitaryEmissionsPublicTransport)
    const TIPTTXI = initialImpactTaxi(ftpCount, projectLength, (totalTXIHours * (newTXIPublicTransportJourneyPercent / 100) * 2), unitaryEmissionsTXIPublicTransport)
    const TIPTCAR = (((((rateOfDailyCarJournies / 100) * avgCarJourneyHoursPerDay * ftpCount * projectLength) * (newCarReplacePubliceTarnsprot / 100)) * (1 - (newRemoteWorkPercent / 100))) *  unitaryEmissionsPublicTransport)
    total = TIPT + TIPTTXI + TIPTCAR;    
    return total
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) at office.
 * 
 * @param {number} numberKWHPerDayPerFTPAtOffice number of kWh per day per FTP at office
 * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP at home
 * @param {number} rateOfDaysInRemote rate of days in remote (%)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function targetImpactOffice(numberKWHPerDayPerFTPAtOffice, numberKWHPerDayPerFTPAtRemote, rateOfDaysInRemote, ftpCount, projectLength) {
    if (!isNumeric(numberKWHPerDayPerFTPAtOffice)) throw TypeError("'numberKWHPerDayPerFTPAtOffice' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTPAtRemote' must be numeric")
    if (!isNumeric(rateOfDaysInRemote)) throw TypeError("'rateOfDaysInRemote' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (numberKWHPerDayPerFTPAtOffice < 0) throw RangeError("'numberKWHPerDayPerFTPAtOffice' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTPAtRemote' can't be less than 0")
    if (rateOfDaysInRemote < 0 || rateOfDaysInRemote > 100) return 0;
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    let result = 0;
    const Office = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtOffice) * (rateOfDaysInRemote / 100));
    const WFH = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) * (rateOfDaysInRemote / 100));
    result = Office;
    return result
}


/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) at office.
 * * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP at home
 * @param {number} numberKWHPerDayPerFTPAtOffice number of kWh per day per FTP at office 
 * @param {number} rateOfDaysInRemote rate of days in remote (%)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function targetImpactWFH(numberKWHPerDayPerFTPAtRemote, numberKWHPerDayPerFTPAtOffice, rateOfDaysInRemote, ftpCount, projectLength) {
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTPAtRemote' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtOffice)) throw TypeError("'numberKWHPerDayPerFTPAtOffice' must be numeric")
    if (!isNumeric(rateOfDaysInRemote)) throw TypeError("'rateOfDaysInRemote' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (numberKWHPerDayPerFTPAtOffice < 0) throw RangeError("'numberKWHPerDayPerFTPAtOffice' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTPAtRemote' can't be less than 0")
    if (rateOfDaysInRemote < 0 || rateOfDaysInRemote > 100) return 0;
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    let result = 0;
    // const Office = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtOffice) * (rateOfDaysInRemote / 100));
    const WFH = (initialImpactWFH(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) * (rateOfDaysInRemote / 100));    
    result = WFH;
    console.log(result, 'wfh target');
    return result
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) at hotel.
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} targetNumberOfNights target number of nights at hotel
 * @param {number} unitaryEmissions unitary emissions in (kgCO<sub>2</sub>equivalent/roomNights) [country based]
 */
function targetImpactHotelNights(ftpCount, projectLength, targetNumberOfNights, unitaryEmissions) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(targetNumberOfNights)) throw TypeError("'targetNumberOfNights' must be numeric")
    if (!isNumeric(unitaryEmissions)) throw TypeError("'unitaryEmissions' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (targetNumberOfNights < 0) throw RangeError("'targetNumberOfNights' can't be less than 0")
    if (unitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return targetNumberOfNights * unitaryEmissions;
    } else {
        return 0;
    }

}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) in waste.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} initialUnitaryEmissions initial unitary emissions in (kgCO<sub>2</sub>equivalent/pers/d)
 * @param {number} targetUnitaryEmissions target unitary emissions in (kgCO<sub>2</sub>equivalent/pers/d)
 * @param {string} commitsToMinimizeWaste commits to minimize waste (yes/no)
 */
function targetImpactWaste(ftpCount, projectLength, initialUnitaryEmissions, targetUnitaryEmissions, commitsToMinimizeWaste) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(initialUnitaryEmissions)) throw TypeError("'initialUnitaryEmissions' must be numeric")
    if (!isNumeric(targetUnitaryEmissions)) throw TypeError("'targetUnitaryEmissions' must be numeric")
    if (!isString(commitsToMinimizeWaste)) throw TypeError("'initialUnitaryEmissions' must be string")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (initialUnitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (targetUnitaryEmissions < 0) throw RangeError("'unitaryEmissions' can't be less than 0")
    if (commitsToMinimizeWaste.toLowerCase() !== "yes" && commitsToMinimizeWaste.toLowerCase() !== "no") throw RangeError("'commitsToMinimizeWaste' can have only two values assigned either 'yes' or 'no'")
    return commitsToMinimizeWaste.toLowerCase() === "yes" ? (ftpCount * projectLength * targetUnitaryEmissions) : (ftpCount * projectLength * initialUnitaryEmissions);
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) in vegetarian food.
 * 
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 * @param {number} unitaryEmissionsInNormalFood unitary emissions in normal food in (kgCO<sub>2</sub>equivalent/meal)
 * @param {number} unitaryEmissionsInVegFood unitary emissions in vegetarian food in (kgCO<sub>2</sub>equivalent/meal)
 * @param {number} committedNoOfMealsPerDay committed number of meals per day
 */
function targetImpactFood(ftpCount, projectLength, unitaryEmissionsInNormalFood, unitaryEmissionsInVegFood, committedNoOfMealsPerDay) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(unitaryEmissionsInNormalFood)) throw TypeError("'unitaryEmissionsInNormalFood' must be numeric")
    if (!isNumeric(unitaryEmissionsInVegFood)) throw TypeError("'unitaryEmissionsInVegFood' must be numeric")
    if (!isNumeric(committedNoOfMealsPerDay)) throw TypeError("'committedNoOfMealsPerDay' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (unitaryEmissionsInNormalFood < 0) throw RangeError("'unitaryEmissionsInNormalFood' can't be less than 0")
    if (unitaryEmissionsInVegFood < 0) throw RangeError("'unitaryEmissionsInVegFood' can't be less than 0")
    if (committedNoOfMealsPerDay < 0) throw RangeError("'committedNoOfMealsPerDay' can't be less than 0")
    return ((unitaryEmissionsInNormalFood * ((5 - committedNoOfMealsPerDay) / 5)) + (unitaryEmissionsInVegFood * (committedNoOfMealsPerDay / 5))) * ftpCount * projectLength;
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) in digital.
 * 
 * @param {number} mbOfEmailSentPerDayPerPerson Mb sent (per day per person)
 * @param {number} emissionsPerMbSent emission per Mb sent (kgCO<sub>2</sub>equivalent/Mb) 
 * @param {number} mbOfStokedSharepoint Mb stocked (total sharepoint)
 * @param {number} mbOfStokedSentEmail Mo stocked (sent emails)
 * @param {number} mbOfStokedReceivedEmail Mb stocked (mail received)
 * @param {string} commitsToClean commits to clean sharepoint and mailbox (yes/no)
 * @param {number} rateOfDataAvoidedInEmailSentPerDayPerPerson rate of data avoided in email sent per day per person (%)
 * @param {number} rateOfDataAvoidedInStokedSharepoint rate of data avoided in stocked sharepoint per year (%)
 * @param {number} rateOfDataAvoidedInStokedSentEmail rate of data avoided in stocked sent emails per year (%)
 * @param {number} rateOfDataAvoidedInStokedReceivedEmail rate of data avoided in stocked received emails per year (%)
 * @param {number} emissionsMbStockedPerYear  emission per mb sent per year (kgCO<sub>2</sub>equivalent/Mb/y) 
 * @param {number} avgLifeTimeLength Average lifetime length (year(s))
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function targetImpactDigital(
    mbOfEmailSentPerDayPerPerson,
    emissionsPerMbSent,
    mbOfStokedSharepoint,
    mbOfStokedSentEmail,
    mbOfStokedReceivedEmail,
    emissionsMbStockedPerYear,
    avgLifeTimeLength,
    ftpCount,
    projectLength,
    commitsToClean,
    rateOfDataAvoidedInEmailSentPerDayPerPerson = 0,
    rateOfDataAvoidedInStokedSharepoint = 50,
    rateOfDataAvoidedInStokedSentEmail = 50,
    rateOfDataAvoidedInStokedReceivedEmail = 50,
) {
    if (!isNumeric(mbOfEmailSentPerDayPerPerson)) throw TypeError("'mbOfEmailSentPerDayPerPerson' must be numeric")
    if (!isNumeric(emissionsPerMbSent)) throw TypeError("'emissionsPerMbSent' must be numeric")
    if (!isNumeric(mbOfStokedSharepoint)) throw TypeError("'mbOfStokedSharepoint' must be numeric")
    if (!isNumeric(mbOfStokedSentEmail)) throw TypeError("'mbOfStokedSentEmail' must be numeric")
    if (!isNumeric(mbOfStokedReceivedEmail)) throw TypeError("'mbOfStokedReceivedEmail' must be numeric")
    if (!isNumeric(emissionsMbStockedPerYear)) throw TypeError("'emissionsMbStockedPerYear' must be numeric")
    if (!isNumeric(avgLifeTimeLength)) throw TypeError("'avgLifeTimeLength' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isString(commitsToClean)) throw TypeError("'commitsToClean' must be string")
    if (!isNumeric(rateOfDataAvoidedInEmailSentPerDayPerPerson)) throw TypeError("'rateOfDataAvoidedInEmailSentPerDayPerPerson' must be numeric")
    if (!isNumeric(rateOfDataAvoidedInStokedSharepoint)) throw TypeError("'rateOfDataAvoidedInStokedSharepoint' must be numeric")
    if (!isNumeric(rateOfDataAvoidedInStokedSentEmail)) throw TypeError("'rateOfDataAvoidedInStokedSentEmail' must be numeric")
    if (!isNumeric(rateOfDataAvoidedInStokedReceivedEmail)) throw TypeError("'rateOfDataAvoidedInStokedReceivedEmail' must be numeric")
    if (mbOfEmailSentPerDayPerPerson < 0) throw RangeError("'mbOfEmailSentPerDayPerPerson' can't be less than 0")
    if (emissionsPerMbSent < 0) throw RangeError("'emissionsPerMbSent' can't be less than 0")
    if (mbOfStokedSharepoint < 0) throw RangeError("'mbOfStokedSharepoint' can't be less than 0")
    if (mbOfStokedSentEmail < 0) throw RangeError("'mbOfStokedSentEmail' can't be less than 0")
    if (mbOfStokedReceivedEmail < 0) throw RangeError("'mbOfStokedReceivedEmail' can't be less than 0")
    if (emissionsMbStockedPerYear < 0) throw RangeError("'emissionsMbStockedPerYear' can't be less than 0")
    if (avgLifeTimeLength < 0) throw RangeError("'avgLifeTimeLength' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (commitsToClean.toLowerCase() !== "yes" && commitsToClean.toLowerCase() !== "no") throw RangeError("'commitsToClean' can have only two values assigned either 'yes' or 'no'")
    if (rateOfDataAvoidedInEmailSentPerDayPerPerson < 0 || rateOfDataAvoidedInEmailSentPerDayPerPerson > 100) return 0;
    if (rateOfDataAvoidedInStokedSharepoint < 0 || rateOfDataAvoidedInStokedSharepoint > 100) return 0;
    if (rateOfDataAvoidedInStokedSentEmail < 0 || rateOfDataAvoidedInStokedSentEmail > 100) return 0;
    if (rateOfDataAvoidedInStokedReceivedEmail < 0 || rateOfDataAvoidedInStokedReceivedEmail > 100) return 0;
    const totalEmissionsByEmailSentPerDayPerPerson = mbOfEmailSentPerDayPerPerson * emissionsPerMbSent * ftpCount * projectLength * parseFloat(commitsToClean.toLowerCase() === "yes" ? (1 - (rateOfDataAvoidedInEmailSentPerDayPerPerson / 100)) : (1));
    const totalEmissionsByStockedSharepoint = mbOfStokedSharepoint * emissionsMbStockedPerYear * avgLifeTimeLength * parseFloat(commitsToClean.toLowerCase() === "yes" ? (1 - (rateOfDataAvoidedInStokedSharepoint / 100)) : (1));
    const totalEmissionsByStockedSentEmail = mbOfStokedSentEmail * emissionsMbStockedPerYear * avgLifeTimeLength * ftpCount * projectLength * parseFloat(commitsToClean.toLowerCase() === "yes" ? (1 - (rateOfDataAvoidedInStokedSentEmail / 100)) : (1));
    const totalEmissionsByStockedReceivedEmail = mbOfStokedReceivedEmail * emissionsMbStockedPerYear * avgLifeTimeLength * ftpCount * projectLength * parseFloat(commitsToClean.toLowerCase() === "yes" ? (1 - (rateOfDataAvoidedInStokedReceivedEmail / 100)) : (1));
    if (ftpCount !== 0 && projectLength !== 0) {
        return totalEmissionsByEmailSentPerDayPerPerson + totalEmissionsByStockedSharepoint + totalEmissionsByStockedSentEmail + totalEmissionsByStockedReceivedEmail;
    } else {
        return 0;
    }

}

/**
 * Actual Impact
 */

/**
* Calculates actual impact on carbon footprint (kgCO<sub>2</sub>e) by taxi.
* @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
* @param {number} totalHoursTaxi total number of hours travelled by taxi over the length of the entire project intially (h)
* @param {number} totalHoursTaxiGreen total number of hours travelled by electric taxi/taxi-green over the length of the entire project intially (h)
* @param {number} totalHoursPublicTransport total number of hours travelled by public transport over the length of the entire project intially (h)
* @param {number} unitaryEmissionsTaxi unitary emissions in taxi in (kgCO<sub>2</sub>equivalent/h)
* @param {number} unitaryEmissionsTaxiGrreen unitary emissions in taxi-green in (kgCO<sub>2</sub>equivalent/h)
* @param {number} unitaryEmissionsPublicTransport unitary emissions in public transport in (kgCO<sub>2</sub>equivalent/h)
*/
function actualImpactTaxi(ftpCount, projectLength, totalHoursTaxi, totalHoursTaxiGreen, totalHoursPublicTransport, unitaryEmissionsTaxi, unitaryEmissionsTaxiGreen, unitaryEmissionsPublicTransport) {
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (!isNumeric(totalHoursTaxi)) throw TypeError("'totalHoursTaxi' must be numeric")
    if (!isNumeric(totalHoursTaxiGreen)) throw TypeError("'totalHoursTaxiGreen' must be numeric")
    if (!isNumeric(totalHoursPublicTransport)) throw TypeError("'totalHoursPublicTransport' must be numeric")
    if (!isNumeric(unitaryEmissionsTaxi)) throw TypeError("'unitaryEmissionsTaxi' must be numeric")
    if (!isNumeric(unitaryEmissionsTaxiGreen)) throw TypeError("'unitaryEmissionsTaxiGreen' must be numeric")
    if (!isNumeric(unitaryEmissionsPublicTransport)) throw TypeError("'unitaryEmissionsPublicTransport' must be numeric")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    if (totalHoursTaxi < 0) throw RangeError("'totalHoursTaxi' can't be less than 0")
    if (totalHoursTaxiGreen < 0) throw RangeError("'totalHoursTaxiGreen' can't be less than 0")
    if (totalHoursPublicTransport < 0) throw RangeError("'totalHoursPublicTransport' can't be less than 0")
    if (unitaryEmissionsTaxi < 0) throw RangeError("'unitaryEmissionsTaxi' can't be less than 0")
    if (unitaryEmissionsTaxiGreen < 0) throw RangeError("'unitaryEmissionsTaxiGreen' can't be less than 0")
    if (unitaryEmissionsPublicTransport < 0) throw RangeError("'unitaryEmissionsPublicTransport' can't be less than 0")
    if (ftpCount !== 0 && projectLength !== 0) {
        return initialImpactTaxi(ftpCount, projectLength, totalHoursTaxi, unitaryEmissionsTaxi)
            + initialImpactTaxi(ftpCount, projectLength, totalHoursTaxiGreen, unitaryEmissionsTaxiGreen)
            + initialImpactTaxi(ftpCount, projectLength, totalHoursPublicTransport, unitaryEmissionsPublicTransport)
    } else {
        return 0;
    }

}

/**
 * Calculates actual impact on carbon footprint (kgCO<sub>2</sub>e) by personal car.
 * 
 * @param {number} rateOfRemoteWork rate of remote work taken over the length of the entire project (%)
 * @param {number} unitaryEmissionsCar unitary emissions in personal car in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} rateOfDailyCarJournies rate of dailys journey by car (%)
 * @param {number} avgCarJourneyHoursPerDay journey length by car (daily average for 1 FTP) (h)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function actualImpactCar(rateOfRemoteWork, rateOfDailyCarJournies, avgCarJourneyHoursPerDay, ftpCount, projectLength, unitaryEmissionsCar) {
    if (!isNumeric(rateOfRemoteWork)) throw TypeError("'rateOfRemoteWork' must be numeric")
    if (!isNumeric(unitaryEmissionsCar)) throw TypeError("'unitaryEmissionsCar' must be numeric")
    if (!isNumeric(rateOfDailyCarJournies)) throw TypeError("'rateOfDailyCarJournies' must be numeric")
    if (!isNumeric(avgCarJourneyHoursPerDay)) throw TypeError("'avgCarJourneyHoursPerDay' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (rateOfRemoteWork < 0 || rateOfRemoteWork > 100) return 0;
    if (unitaryEmissionsCar < 0) throw RangeError("'unitaryEmissionsCar' can't be less than 0")
    if (rateOfDailyCarJournies < 0) throw RangeError("'rateOfDailyCarJournies' can't be less than 0")
    if (avgCarJourneyHoursPerDay < 0) throw RangeError("'avgCarJourneyHoursPerDay' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    return (((rateOfDailyCarJournies / 100) * avgCarJourneyHoursPerDay * ftpCount * projectLength) * (1 - (rateOfRemoteWork / 100)) * unitaryEmissionsCar)
}

/**
 * Calculates actual impact on carbon footprint (kgCO<sub>2</sub>e) by public transport.
 * 
 * @param {number} rateOfRemoteWork rate of remote work taken over the length of the entire project (%)
 * @param {number} unitaryEmissionsPublicTransport unitary emissions in public transport in (kgCO<sub>2</sub>equivalent/h)
 * @param {number} rateOfDailyPublicTransportJournies rate of dailys journey by public transport (%)
 * @param {number} avgPublicTransportJourneyHoursPerDay journey length by public transport (daily average for 1 FTP) (h)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function actualImpactPublicTransport(rateOfRemoteWork, rateOfDailyPublicTransportJournies, avgPublicTransportJourneyHoursPerDay, ftpCount, projectLength, unitaryEmissionsPublicTransport) {
    if (!isNumeric(rateOfRemoteWork)) throw TypeError("'rateOfRemoteWork' must be numeric")
    if (!isNumeric(unitaryEmissionsPublicTransport)) throw TypeError("'unitaryEmissionsPublicTransport' must be numeric")
    if (!isNumeric(rateOfDailyPublicTransportJournies)) throw TypeError("'rateOfDailyPublicTransportJournies' must be numeric")
    if (!isNumeric(avgPublicTransportJourneyHoursPerDay)) throw TypeError("'avgPublicTransportJourneyHoursPerDay' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (rateOfRemoteWork < 0 || rateOfRemoteWork > 100) return null;
    if (unitaryEmissionsPublicTransport < 0) throw RangeError("'unitaryEmissionsPublicTransport' can't be less than 0")
    if (rateOfDailyPublicTransportJournies < 0) throw RangeError("'rateOfDailyPublicTransportJournies' can't be less than 0")
    if (avgPublicTransportJourneyHoursPerDay < 0) throw RangeError("'avgPublicTransportJourneyHoursPerDay' can't be less than 0")
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    return (((rateOfDailyPublicTransportJournies / 100) * avgPublicTransportJourneyHoursPerDay * ftpCount * projectLength) * (1 - (rateOfRemoteWork / 100)) * unitaryEmissionsPublicTransport)
}

/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) at office.
 * 
 * @param {number} numberKWHPerDayPerFTPAtOffice number of kWh per day per FTP at office
 * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP at home
 * @param {number} rateOfDaysInRemote rate of days in remote (%)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function actualImpactOffice(numberKWHPerDayPerFTPAtOffice, numberKWHPerDayPerFTPAtRemote, rateOfDaysInRemote, ftpCount, projectLength) {
    if (!isNumeric(numberKWHPerDayPerFTPAtOffice)) throw TypeError("'numberKWHPerDayPerFTPAtOffice' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTPAtRemote' must be numeric")
    if (!isNumeric(rateOfDaysInRemote)) throw TypeError("'rateOfDaysInRemote' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (numberKWHPerDayPerFTPAtOffice < 0) throw RangeError("'numberKWHPerDayPerFTPAtOffice' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTPAtRemote' can't be less than 0")
    if (rateOfDaysInRemote < 0 || rateOfDaysInRemote > 100) return 0;
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    let result = 0;
    const Office = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtOffice) * (rateOfDaysInRemote / 100));
    const WFH = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) * (rateOfDaysInRemote / 100));
    result = Office;
    return result
}


/**
 * Calculates target impact on carbon footprint (kgCO<sub>2</sub>e) at office.
 * 
 * @param {number} numberKWHPerDayPerFTPAtOffice number of kWh per day per FTP at office
 * @param {number} numberKWHPerDayPerFTPAtRemote number of kWh per day per FTP at home
 * @param {number} rateOfDaysInRemote rate of days in remote (%)
 * @param {number} ftpCount ftp number
 * @param {number} projectLength project length (days)
 */
function actualImpactWFH(numberKWHPerDayPerFTPAtRemote, numberKWHPerDayPerFTPAtOffice, rateOfDaysInRemote, ftpCount, projectLength) {
    if (!isNumeric(numberKWHPerDayPerFTPAtRemote)) throw TypeError("'numberKWHPerDayPerFTPAtRemote' must be numeric")
    if (!isNumeric(numberKWHPerDayPerFTPAtOffice)) throw TypeError("'numberKWHPerDayPerFTPAtOffice' must be numeric")
    if (!isNumeric(rateOfDaysInRemote)) throw TypeError("'rateOfDaysInRemote' must be numeric")
    if (!isNumeric(ftpCount)) throw TypeError("'ftpCount' must be numeric")
    if (!isNumeric(projectLength)) throw TypeError("'projectLength' must be numeric")
    if (numberKWHPerDayPerFTPAtOffice < 0) throw RangeError("'numberKWHPerDayPerFTPAtOffice' can't be less than 0")
    if (numberKWHPerDayPerFTPAtRemote < 0) throw RangeError("'numberKWHPerDayPerFTPAtRemote' can't be less than 0")
    if (rateOfDaysInRemote < 0 || rateOfDaysInRemote > 100) return 0;
    if (ftpCount < 0) throw RangeError("'ftpCount' can't be less than 0")
    if (projectLength < 0) throw RangeError("'projectLength' can't be less than 0")
    let result = 0;
    const Office = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtOffice) * (rateOfDaysInRemote / 100));
    const WFH = (initialImpactOffice(ftpCount, projectLength, numberKWHPerDayPerFTPAtRemote) * (rateOfDaysInRemote / 100));
    result = WFH;
    return result
}

/**
 * Test function to check initial impact
 * 
 * remove this function once its approved
 */
function testIntialImpactFunctions() {
    const initialImpactPlaneVal = initialImpactPlane(10, 188);
    const initialImpactTrainVal = initialImpactTrain(20, 10.81397118);
    const initialImpactTaxiVal = initialImpactTaxi(10, 10.12);
    const initialImpactCarVal = initialImpactCar(50, 2, 4, 60, 10.12);
    const initialImpactPublicTransportVal = initialImpactPublicTransport(50, 1, 4, 60, 1.025878963);
    const initialImpactOfficeVal = initialImpactOffice(4, 60, 4.17, 0.4188);
    const initialImpactWFHVal = initialImpactWFH(4, 60, 4.17, 0.4188);
    const initialImpactIWLVal = initialImpactIWL(5, 35, 50, 50, 10, 20);
    const initialImpactIWLWFHVal = initialImpactIWLWFH(5, 35, 50, 50, 10, 20);
    const initialImpactHotelNightsVal = initialImpactHotelNights(21, 20.80);
    const initialImpactWasteVal = initialImpactWaste(4, 60, 0.78);
    const initialImpactFoodVal = initialImpactFood(4, 60, 2.04);
    const initialImpactDigitalVal = initialImpactDigital(15, 0.015, 12000, 15, 10, 0.01, 3, 4, 60);

    const total = initialImpactPlaneVal +
        initialImpactTrainVal +
        initialImpactTaxiVal +
        initialImpactCarVal +
        initialImpactPublicTransportVal +
        initialImpactOfficeVal +
        initialImpactWFHVal +
        initialImpactIWLVal +
        initialImpactIWLWFHVal +
        initialImpactHotelNightsVal +
        initialImpactWasteVal +
        initialImpactFoodVal +
        initialImpactDigitalVal;

    // console.log("initialImpactPlaneVal", initialImpactPlaneVal);
    // console.log("initialImpactTrainVal", initialImpactTrainVal);
    // console.log("initialImpactTaxiVal", initialImpactTaxiVal);
    // console.log("initialImpactCarVal", initialImpactCarVal);
    // console.log("initialImpactPublicTransportVal", initialImpactPublicTransportVal);
    // console.log("initialImpactOfficeVal", initialImpactOfficeVal);
    // console.log("initialImpactHotelNightsVal", initialImpactHotelNightsVal);
    // console.log("initialImpactWasteVal", initialImpactWasteVal);
    // console.log("initialImpactFoodVal", initialImpactFoodVal);
    // console.log("initialImpactDigitalVal", initialImpactDigitalVal);
    // console.log("Total", total);
}

/**
 * Test function to check target impact
 * 
 * remove this function once its approved
 */
function testTargetImpactFunctions() {
    const targetImpactPlaneVal = targetImpactPlane(10, 70, 20, 10, 188, 10.81397118);
    const targetImpactTrainVal = targetImpactTrain(20, 80, 20, 10.81397118);
    const targetImpactTaxiVal = targetImpactTaxi(10, 20, 20, 40, 20, 10.12, 4.827665706, 1.025878963);
    const targetImpactCarVal = targetImpactCar(20, 50, 30, 50, 50, 2, 50, 1, 4, 60, 10.12, 1.025878963, 0);
    const targetImpactPublicTransportVal = targetImpactPublicTransport(50, 50, 50, 50, 1, 4, 60, 1.025878963, 0);
    const targetImpactOfficeVal = targetImpactOffice(4.17, 0.84, 50, 0.4188, 4, 60);
    const targetImpactWFHVal = targetImpactWFH(4.17, 0.84, 50, 0.4188, 4, 60);
    const targetImpactHotelNightsVal = targetImpactHotelNights(20, 20.80);
    const targetImpactWasteVal = targetImpactWaste(4, 60, 0.78, 0.25, "No");
    const targetImpactFoodVal = targetImpactFood(4, 60, 2.04, 0.51, "Yes", 2);
    const targetImpactDigitalVal = targetImpactDigital(15, 0.015, 12000, 15, 10, 0.01, 3, 4, 60, "Yes", 0, 50, 50, 50);

    const total = targetImpactPlaneVal +
        targetImpactTrainVal +
        targetImpactTaxiVal +
        targetImpactCarVal +
        targetImpactPublicTransportVal +
        targetImpactOfficeVal +
        targetImpactWFHVal +
        targetImpactHotelNightsVal +
        targetImpactWasteVal +
        targetImpactFoodVal +
        targetImpactDigitalVal;

    // console.log("targetImpactPlaneVal", targetImpactPlaneVal);
    // console.log("targetImpactTrainVal", targetImpactTrainVal);
    // console.log("targetImpactTaxiVal", targetImpactTaxiVal);
    // console.log("targetImpactCarVal", targetImpactCarVal);
    // console.log("targetImpactPublicTransportVal", targetImpactPublicTransportVal);
    // console.log("targetImpactOfficeVal", targetImpactOfficeVal);
    // console.log("targetImpactHotelNightsVal", targetImpactHotelNightsVal);
    // console.log("targetImpactWasteVal", targetImpactWasteVal);
    // console.log("targetImpactFoodVal", targetImpactFoodVal);
    // console.log("targetImpactDigitalVal", targetImpactDigitalVal);
    // console.log("Total", total);
}

module.exports = {
    initialImpactPlane,
    initialImpactTrain,
    initialImpactTaxi,
    initialImpactCar,
    initialImpactPublicTransport,    
    initialImpactOffice,
    initialImpactWFH,
    initialImpactIWL,
    initialImpactIWLWFH,
    initialImpactHotelNights,
    initialImpactWaste,
    initialImpactFood,
    initialImpactDigital,
    targetImpactPlane,
    targetImpactHSTrain,
    targetImpactTrain,
    targetImpactTaxi,
    targetImpactCar,
    targetImpactPublicTransport,
    targetImpactOffice,
    targetImpactWFH,
    targetImpactHotelNights,
    targetImpactWaste,
    targetImpactFood,
    targetImpactDigital,
    actualImpactTaxi,
    actualImpactCar,
    actualImpactPublicTransport,
    actualImpactOffice,
    actualImpactWFH,
    testIntialImpactFunctions,
    testTargetImpactFunctions,
}