const { isNumeric } = require('./utils.js');
require('./utils.js')

/**
 * Calculates yearly carbon footprint (kgCO<sub>2</sub>e/year) for business travel.
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} businessTravelFactor Carbon footprint for business travel in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForBusinessTravel(fteCount, avgWfhDaysPerWeek, businessTravelFactor) {
  if (!isNumeric(fteCount)) throw TypeError("''fteCount'' must be numeric")
  if (!isNumeric(avgWfhDaysPerWeek)) throw TypeError("'avgWfhDaysPerWeek' must be numeric")
  if (!isNumeric(businessTravelFactor)) throw TypeError("'businessTravelFactor' must be numeric")
  if (fteCount < 0) throw RangeError("'fteCount' can't be less than 0")
  if (avgWfhDaysPerWeek < 0 || avgWfhDaysPerWeek > 5) throw RangeError("'avgWfhDaysPerWeek' can't be less than 0 or more than 5")
  if (businessTravelFactor < 0) throw RangeError("'businessTravelFactor' can't be less than 0")

  return fteCount * businessTravelFactor
}

/**
 * Calculates yearly carbon footprint (kgCO<sub>2</sub>e/year) for Capgemini Base Office Support.
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} officeFactor Carbon footprint for office support in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForOffice(fteCount, officeFactor) {
  if (!isNumeric(fteCount)) throw TypeError("''fteCount'' must be numeric")
  if (!isNumeric(officeFactor)) throw TypeError("'officeFactor' must be numeric")
  if (fteCount < 0) throw RangeError("'fteCount' can't be less than 0")
  if (officeFactor < 0) throw RangeError("'officeFactor' can't be less than 0")

  return fteCount * officeFactor
}

/**
 * Calculates yearly carbon footprint (kgCO<sub>2</sub>e/year) for commuting to work.
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} avgWfhDaysPerWeek Average number of work-from-home (WFH) days per week [user(text)]
 * @param  {number} commutingFactor Carbon footprint for commuting in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForCommuting(fteCount, avgWfhDaysPerWeek, commutingFactor, avgDaysWFHPerWeekFactor) {
  if (!isNumeric(fteCount)) throw TypeError("''fteCount'' must be numeric")
  if (!isNumeric(avgWfhDaysPerWeek)) throw TypeError("'avgWfhDaysPerWeek' must be numeric")
  if (!isNumeric(commutingFactor)) throw TypeError("'commutingFactor' must be numeric")
  if (fteCount < 0) throw RangeError("'fteCount' can't be less than 0")
  if (avgWfhDaysPerWeek < 0 || avgWfhDaysPerWeek > 5) throw RangeError("'avgWfhDaysPerWeek' can't be less than 0 or more than 5")
  if (commutingFactor < 0) throw RangeError("'commutingFactor' can't be less than 0")

  return fteCount * ((5 - avgWfhDaysPerWeek) / (5 - avgDaysWFHPerWeekFactor)) * commutingFactor

}

/**
 * Calculates yearly carbon footprint (kgCO<sub>2</sub>e/year) for Work From Home (WFH).
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} avgWfhDaysPerWeek Average number of work-from-home (WFH) days per week [user(text)]
 * @param  {number} wfhFactor Carbon footprint for WFH in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForWfh(fteCount, avgWfhDaysPerWeek, wfhFactor, avgDaysWFHPerWeekFactor) {
  if (!isNumeric(fteCount)) throw TypeError("''fteCount'' must be numeric")
  if (!isNumeric(avgWfhDaysPerWeek)) throw TypeError("'avgWfhDaysPerWeek' must be numeric")
  if (!isNumeric(wfhFactor)) throw TypeError("'wfhFactor' must be numeric")
  if (wfhFactor < 0) throw RangeError("'wfhFactor' can't be less than 0")
  if (avgWfhDaysPerWeek < 0 || avgWfhDaysPerWeek > 5) throw RangeError("'avgWfhDaysPerWeek' can't be less than 0 or more than 5")
  if (fteCount < 0) throw RangeError("'fteCount' can't be less than 0")

  return fteCount * (avgWfhDaysPerWeek / avgDaysWFHPerWeekFactor) * wfhFactor
}

/**
 * Calculates yearly carbon footprint (kgCO<sub>2</sub>e/year) for Work From Home (WFH).
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} digitalFactor Carbon footprint for digital tech in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForDigital(fteCount, digitalFactor) {
  if (!isNumeric(fteCount)) throw TypeError("''fteCount'' must be numeric")
  if (!isNumeric(digitalFactor)) throw TypeError("'digitalFactor' must be numeric")
  if (fteCount < 0) throw RangeError("'fteCount' can't be less than 0")
  if (digitalFactor < 0) throw RangeError("'digitalFactor' can't be less than 0")

  return fteCount * digitalFactor
}

/**
 * Calculates goods & service footprint .
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} goodsAndServicesFactor Carbon footprint for goodsAndServices in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 */
function footprintForGoodsAndServices(fteCount, goodsAndServicesFactor) {
  if (!isNumeric(fteCount)) throw TypeError("''fteCount'' must be numeric")
  if (!isNumeric(goodsAndServicesFactor)) throw TypeError("''goodsAndServicesFactor'' must be numeric")
  if (fteCount < 0) throw RangeError("'fteCount' can't be less than 0")
  if (goodsAndServicesFactor < 0) throw RangeError("'goodsAndServicesFactor' can't be less than 0")
  return fteCount * goodsAndServicesFactor
}

/**
 * Calculates yearly carbon footprint (kgCO<sub>2</sub>e/year) for delivery team in general. Aggregates all the individual factors.
 * @param  {number} fteCount Number of full-time employees (FTEs) on the project [user(text)]
 * @param  {number} avgWfhDaysPerWeek Average number of work-from-home (WFH) days per week [user(text)]
 * @param  {number} businessTravelFactor Carbon footprint for business travel in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @param  {number} officeFactor Carbon footprint for office support in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @param  {number} commutingFactor Carbon footprint for commuting in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @param  {number} wfhFactor Carbon footprint for WFH in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @param  {number} digitalFactor Carbon footprint for digital tech in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @param  {number} goodsAndServicesFactor Carbon footprint for goodsAndServices in the selected country for a single FTE per year (kgCO<sub>2</sub>e/FTE/year) [country based]
 * @return {number} Yearly carbon footprint (kgCO<sub>2</sub>e/year)
 */
function footprintForPeople(fteCount, avgWfhDaysPerWeek, businessTravelFactor, officeFactor, commutingFactor, wfhFactor, digitalFactor, goodsAndServicesFactor, avgDaysWFHPerWeekFactor) {
  return (
    footprintForBusinessTravel(fteCount, avgWfhDaysPerWeek, businessTravelFactor) +
    footprintForOffice(fteCount, officeFactor) +
    footprintForCommuting(fteCount, avgWfhDaysPerWeek, commutingFactor, avgDaysWFHPerWeekFactor) +
    footprintForWfh(fteCount, avgWfhDaysPerWeek, wfhFactor, avgDaysWFHPerWeekFactor) +
    footprintForDigital(fteCount, digitalFactor) +
    footprintForGoodsAndServices(fteCount, goodsAndServicesFactor)
  )
}

module.exports = {
  footprintForBusinessTravel: footprintForBusinessTravel,
  footprintForCommuting: footprintForCommuting,
  footprintForOffice: footprintForOffice,
  footprintForWfh: footprintForWfh,
  footprintForDigital: footprintForDigital,
  footprintForGoodsAndServices: footprintForGoodsAndServices,
  footprintForPeople: footprintForPeople
}