import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import {
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "./components/layout/header"
import Footer from "./components/layout/footer";
import ScrollToTop from "./components/layout/scrollToTop";
import Home from "./pages/home";
import Projects from "./pages/projects";
import About from "./pages/about";
import Calculator from "./pages/calculator/Calculator";
import Report from "./pages/report";
import getAllMasters from "./store/actions/masterActions/getAllMastersAction";
import { checkUserLogin } from "./store/actions/loginActions/loginAction";
import { logout } from "./store/actions/loginActions/logoutAction";
import WarningPopup from "./components/layout/warningPopup/WarningPopup";
import EMonitoringAPI from "./pages/e3DMonitoring/EMonitoringAPI";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const App = (props) => {

  const [uName, setUName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [aadLoggedIn, setAadLoggedIn] = useState(false);

  const login = (data) => {
    props.checkUserLogin(data);
  }

  useEffect(() => {
    const submitForm = () => {
      axios.post(`${basePath}/auth`, { username: uName, role: "user", email: emailId })
        .then(res => {
          if (typeof res === "object") {
            login(res.data);
          } else {
            logout();
          }
        })
        .catch(err => {
          logout();
        });
    }

    if (aadLoggedIn === true && props.login.isValidUser === false && (environment === "production" || environment === "uat")) {
      submitForm();
    }
    // eslint-disable-next-line
  }, [props.login, aadLoggedIn]);

  useEffect(() => {
    if (aadLoggedIn === true && props.master.status === false) {
      props.getAllMasters();
    }
    // eslint-disable-next-line
  }, [props.master.status, aadLoggedIn]);

  useEffect(() => {
    if (uName !== "" && emailId !== "") {
      setAadLoggedIn(true);
    }
  }, [uName, emailId]);

  useEffect(() => {
    if ((environment === "production" || environment === "uat") && props.aad !== null && Object.keys(props.aad).length > 0) {
      setUName(props.aad.aadResponse.account.name);
      setEmailId(props.aad.aadResponse.account.userName);
    } else {
      setAadLoggedIn(false);
      if (environment === "development") {
        setUName("Nair, Jatin");
        setEmailId("jatin.nair@capgemini.com");
      }
    }
    // eslint-disable-next-line
  }, [props.aad]);

  return (
    <React.Fragment>
      {
        (aadLoggedIn === true || environment === "development") ? (
          <React.Fragment>
            <Header aadLogoutFn={props.aadLogoutFn} />
            <Container>
              <div className="App">
                <Switch>
                  <Route exact path="/" render={() => {
                    return (
                      <Redirect to="/home" />
                    )
                  }}
                  />
                  <Route path="/home" component={Home} />
                  <Route path="/projects" component={Projects} />
                  <Route path="/about" component={About} />
                  <Route path="/report" component={Report} />
                  <Route path="/calculator" component={Calculator} />
                  <Route path="/e3dapi/:pid" component={EMonitoringAPI} />
                  <Route path="*" render={() => {
                    return (
                      <Redirect to="/" />
                    )
                  }}
                  />
                </Switch>
              </div>
            </Container>
            <ScrollToTop />
            <Footer label="Capgemini" />
           {/* <WarningPopup></WarningPopup>  */}
          </React.Fragment>
        ) : (
            <React.Fragment><p>Either you are not authorized to view this site or something went wrong!</p></React.Fragment>
          )
      }
    </React.Fragment>
  );
}


const mapStateToProps = (state) => {
  return {
    aad: state.aad,
    master: state.master,
    login: state.login
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserLogin: (data) => {
      dispatch(checkUserLogin(data));
    },
    logout: () => {
      dispatch(logout());
    },
    getAllMasters: () => {
      dispatch(getAllMasters());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
