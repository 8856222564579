import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForPhysicalServers, footprintForCloud } from "../../calculatorFunctions/data_calc";
import InputTextArea from "../../components/inputTextArea/InputTextAreaCustom";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const initialCountData = {
    physical: { after: 0, before: 0 },
    private: { after: 0, before: 0 },
    public: { after: 0, before: 0 },
    small: { after: 0, before: 0 },
    medium: { after: 0, before: 0 },
    large: { after: 0, before: 0 },
    physicalUtilization: { after: 65, before: 0 },
    privateUtilization: { after: 65, before: 0 },
    publicUtilization: { after: 90, before: 0 },
    premisePUE: { after: 1.7, before: 1.7 },
    publicPUE: { after: 1.11, before: 1.11 },
    assumptions: ""
}

const CollapseDataCentreMigrationCustom = (props) => {
    let { collapseControll, id, label, inputName, inputPlaceholder, data, register, errors } = props;

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [beforeCount, setBeforeCount] = useState(0);
    const [afterCount, setAfterCount] = useState(0);
    const [characterCountDC, setCharacterCountDC] = useState(0);

    const characterChange = (chr) => {
        setCharacterCountDC((chr !== undefined && chr !== "") ? chr : 0);
    }

    useEffect(() => {
        if (data !== undefined && data.assumptions !== undefined && data.assumptions.length !== undefined && data.assumptions.length !== "" && data.assumptions.length !== null && data.assumptions.length > 0){
            setCharacterCountDC(data.assumptions.length)
        }
    }, []);

    const countDataReducer = (state, action) => {
        switch (action.type) {
            case `SET_${inputName.toString().toUpperCase()}_PHYSICAL_BEFORE`:
                state = {
                    ...state,
                    physical: { ...state.physical, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PHYSICAL_AFTER`:
                state = {
                    ...state,
                    physical: { ...state.physical, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PRIVATE_BEFORE`:
                state = {
                    ...state,
                    private: { ...state.private, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PRIVATE_AFTER`:
                state = {
                    ...state,
                    private: { ...state.private, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PUBLIC_BEFORE`:
                state = {
                    ...state,
                    public: { ...state.public, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PUBLIC_AFTER`:
                state = {
                    ...state,
                    public: { ...state.public, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_SMALL_BEFORE`:
                state = {
                    ...state,
                    small: { ...state.small, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_SMALL_AFTER`:
                state = {
                    ...state,
                    small: { ...state.small, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_MEDIUM_BEFORE`:
                state = {
                    ...state,
                    medium: { ...state.medium, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_MEDIUM_AFTER`:
                state = {
                    ...state,
                    medium: { ...state.medium, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_LARGE_BEFORE`:
                state = {
                    ...state,
                    large: { ...state.large, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_LARGE_AFTER`:
                state = {
                    ...state,
                    large: { ...state.large, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PHYSICALUTILIZATION_BEFORE`:
                state = {
                    ...state,
                    physicalUtilization: { ...state.physicalUtilization, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PHYSICALUTILIZATION_AFTER`:
                state = {
                    ...state,
                    physicalUtilization: { ...state.physicalUtilization, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PRIVATEUTILIZATION_BEFORE`:
                state = {
                    ...state,
                    privateUtilization: { ...state.privateUtilization, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PRIVATEUTILIZATION_AFTER`:
                state = {
                    ...state,
                    privateUtilization: { ...state.privateUtilization, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PUBLICUTILIZATION_BEFORE`:
                state = {
                    ...state,
                    publicUtilization: { ...state.publicUtilization, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PUBLICUTILIZATION_AFTER`:
                state = {
                    ...state,
                    publicUtilization: { ...state.publicUtilization, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PREMISEPUE_BEFORE`:
                state = {
                    ...state,
                    premisePUE: { ...state.premisePUE, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PREMISEPUE_AFTER`:
                state = {
                    ...state,
                    premisePUE: { ...state.premisePUE, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PUBLICPUE_BEFORE`:
                state = {
                    ...state,
                    publicPUE: { ...state.publicPUE, before: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_PUBLICPUE_AFTER`:
                state = {
                    ...state,
                    publicPUE: { ...state.publicPUE, after: action.payload }
                }
                break;
            case `SET_${inputName.toString().toUpperCase()}_ALL`:
                state = {
                    ...state,
                    physical: { after: action.payload.physicalAfter, before: action.payload.physicalBefore },
                    private: { after: action.payload.privateAfter, before: action.payload.privateBefore },
                    public: { after: action.payload.publicAfter, before: action.payload.publicBefore },
                    small: { after: action.payload.smallAfter, before: action.payload.smallBefore },
                    medium: { after: action.payload.mediumAfter, before: action.payload.mediumBefore },
                    large: { after: action.payload.largeAfter, before: action.payload.largeBefore },
                    physicalUtilization: { after: action.payload.physicalUtilizationAfter, before: action.payload.physicalUtilizationBefore },
                    privateUtilization: { after: action.payload.privateUtilizationAfter, before: action.payload.privateUtilizationBefore },
                    publicUtilization: { after: action.payload.publicUtilizationAfter, before: action.payload.publicUtilizationBefore },
                    premisePUE: { after: action.payload.premisePUEAfter, before: action.payload.premisePUEBefore },
                    publicPUE: { after: action.payload.publicPUEAfter, before: action.payload.publicPUEBefore }
                }
                break;
            default:
                break;
        }
        return state;
    }

    const [countData, dispatchCountData] = useReducer(countDataReducer, initialCountData);

    const inputChange = (e) => {
        let n1 = e.target.name.split('-')[e.target.name.split('-').length - 2].toString().toUpperCase();
        let n2 = e.target.name.split('-')[e.target.name.split('-').length - 1].toString().toUpperCase();
        dispatchCountData({ type: `SET_${inputName.toString().toUpperCase()}_${n1}_${n2}`, payload: (e.target.value !== "" && e.target.value !== "0" && parseInt(e.target.value) >= 0) ? parseFloat(e.target.value) : ((n1 === "PUBLICPUE" || n1 === "PREMISEPUE") ? 1 : 0) })
    }

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    const setCF = () => {
        let cf = 0;
        let generation = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].generation !== undefined ? props.master.electricityFactorMaster[inputName].generation : 0;
        let tnd = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].tnd !== undefined ? props.master.electricityFactorMaster[inputName].tnd : 0;
        let year = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].generationYear !== undefined ? props.master.electricityFactorMaster[inputName].generationYear : new Date().getFullYear();
        let source = props.master.electricityFactorMaster[inputName] !== undefined && props.master.electricityFactorMaster[inputName].generationSource !== undefined ? props.master.electricityFactorMaster[inputName].generationSource : null;
        let smallWatt = props.master.serverType["small"] !== undefined && props.master.serverType["small"].watt !== undefined ? props.master.serverType["small"].watt : 0;
        let mediumWatt = props.master.serverType["medium"] !== undefined && props.master.serverType["medium"].watt !== undefined ? props.master.serverType["medium"].watt : 0;
        let largeWatt = props.master.serverType["large"] !== undefined && props.master.serverType["large"].watt !== undefined ? props.master.serverType["large"].watt : 0;
        let cloudWatt = props.master.serverType["cloud"] !== undefined && props.master.serverType["cloud"].watt !== undefined ? props.master.serverType["cloud"].watt : 0;

        let smallBeforeMigration = parseFloat(((countData.physical.before * (countData.small.before / 100) * smallWatt) / 1000).toFixed(4));
        let mediumBeforeMigration = parseFloat(((countData.physical.before * (countData.medium.before / 100) * mediumWatt) / 1000).toFixed(4));
        let largeBeforeMigration = parseFloat(((countData.physical.before * (countData.large.before / 100) * largeWatt) / 1000).toFixed(4));
        let privateBeforeMigration = parseFloat(((countData.private.before * ((countData.privateUtilization.before / 100)) * cloudWatt) / 1000).toFixed(4));
        let publicBeforeMigration = parseFloat(((countData.public.before * ((countData.publicUtilization.before / 100)) * cloudWatt) / 1000).toFixed(4));

        let smallAfterMigration = parseFloat(((countData.physical.after * (countData.small.after / 100) * smallWatt) / 1000).toFixed(4));
        let mediumAfterMigration = parseFloat(((countData.physical.after * (countData.medium.after / 100) * mediumWatt) / 1000).toFixed(4));
        let largeAfterMigration = parseFloat(((countData.physical.after * (countData.large.after / 100) * largeWatt) / 1000).toFixed(4));
        let privateAfterMigration = parseFloat(((countData.private.after * ((countData.privateUtilization.after / 100)) * cloudWatt) / 1000).toFixed(4));
        let publicAfterMigration = parseFloat(((countData.public.after * ((countData.publicUtilization.after / 100)) * cloudWatt) / 1000).toFixed(4));

        let onPremiseSmallPhysicalPowerUsageBefore = parseFloat((smallBeforeMigration * countData.premisePUE.before).toFixed(4));
        let onPremiseMediumPhysicalPowerUsageBefore = parseFloat((mediumBeforeMigration * countData.premisePUE.before).toFixed(4));
        let onPremiseLargePhysicalPowerUsageBefore = parseFloat((largeBeforeMigration * countData.premisePUE.before).toFixed(4));
        let onPremiseVirtualPowerUsageBefore = parseFloat((privateBeforeMigration * countData.premisePUE.before).toFixed(4));
        let publicCloudPowerUsageBefore = parseFloat((publicBeforeMigration * countData.publicPUE.before).toFixed(4)); // unlocked the field for publicPUE before, previoulsy it was disabled

        let onPremiseSmallPhysicalPowerUsageAfter = parseFloat((smallAfterMigration * countData.premisePUE.after).toFixed(4));
        let onPremiseMediumPhysicalPowerUsageAfter = parseFloat((mediumAfterMigration * countData.premisePUE.after).toFixed(4));
        let onPremiseLargePhysicalPowerUsageAfter = parseFloat((largeAfterMigration * countData.premisePUE.after).toFixed(4));
        let onPremiseVirtualPowerUsageAfter = parseFloat((privateAfterMigration * countData.premisePUE.after).toFixed(4));
        let publicCloudPowerUsageAfter = parseFloat((publicAfterMigration * countData.publicPUE.after).toFixed(4));

        let cfPhysicalServersBefore = parseFloat(footprintForPhysicalServers(
            onPremiseSmallPhysicalPowerUsageBefore,
            onPremiseMediumPhysicalPowerUsageBefore,
            onPremiseLargePhysicalPowerUsageBefore,
            generation
        ).toFixed(4));

        let cfPrivateCloudServersBefore = parseFloat(footprintForCloud(
            onPremiseVirtualPowerUsageBefore,
            generation,
            tnd
        ).toFixed(4));

        let cfPublicCloudServersBefore = parseFloat(footprintForCloud(
            publicCloudPowerUsageBefore,
            generation,
            tnd
        ).toFixed(4));

        let totalCarbonFootprintBefore = parseFloat((cfPhysicalServersBefore + cfPrivateCloudServersBefore + cfPublicCloudServersBefore).toFixed(4));

        let cfPhysicalServersAfter = parseFloat(footprintForPhysicalServers(
            onPremiseSmallPhysicalPowerUsageAfter,
            onPremiseMediumPhysicalPowerUsageAfter,
            onPremiseLargePhysicalPowerUsageAfter,
            generation,
        ).toFixed(4));
        let cfPrivateCloudServersAfter = parseFloat(footprintForCloud(
            onPremiseVirtualPowerUsageAfter,
            generation,
            tnd
        ).toFixed(4));
        let cfPublicCloudServersAfter = parseFloat(footprintForCloud(
            publicCloudPowerUsageAfter,
            generation,
            tnd
        ).toFixed(4));

        let totalCarbonFootprintAfter = parseFloat((cfPhysicalServersAfter + cfPrivateCloudServersAfter + cfPublicCloudServersAfter).toFixed(4));

        cf = parseFloat(((totalCarbonFootprintBefore - totalCarbonFootprintAfter) / 1000).toFixed(3));

        props.cFootprint({
            name: inputName,
            parent: "migration",
            cf: {
                value: cf,
                onPremiseSmallPhysicalPowerUsageBefore,
                onPremiseMediumPhysicalPowerUsageBefore,
                onPremiseLargePhysicalPowerUsageBefore,
                onPremiseVirtualPowerUsageBefore,
                publicCloudPowerUsageBefore,
                onPremiseSmallPhysicalPowerUsageAfter,
                onPremiseMediumPhysicalPowerUsageAfter,
                onPremiseLargePhysicalPowerUsageAfter,
                onPremiseVirtualPowerUsageAfter,
                publicCloudPowerUsageAfter,
                cfPhysicalServersBefore,
                cfPhysicalServersAfter,
                cfPrivateCloudServersBefore,
                cfPrivateCloudServersAfter,
                cfPublicCloudServersBefore,
                cfPublicCloudServersAfter,
                generation,
                tnd,
                year,
                source
            }
        });
        setCarbonFootprint(cf);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (data !== undefined && Object.keys(data).length > 0) {
                let tempData = {};

                tempData["physicalBefore"] = data.physicalServerBefore;
                tempData["physicalAfter"] = data.physicalServerAfter;
                tempData["privateBefore"] = data.privateServerBefore;
                tempData["privateAfter"] = data.privateServerAfter;
                tempData["publicBefore"] = data.publicServerBefore;
                tempData["publicAfter"] = data.publicServerAfter;
                tempData["smallBefore"] = data.smallPhysicalServerBefore;
                tempData["smallAfter"] = data.smallPhysicalServerAfter;
                tempData["mediumBefore"] = data.mediumPhysicalServerBefore;
                tempData["mediumAfter"] = data.mediumPhysicalServerAfter;
                tempData["largeBefore"] = data.largePhysicalServerBefore;
                tempData["largeAfter"] = data.largePhysicalServerAfter;
                tempData["physicalUtilizationBefore"] = data.physicalServerUtilizationBefore;
                tempData["physicalUtilizationAfter"] = data.physicalServerUtilizationAfter;
                tempData["privateUtilizationBefore"] = data.privateServerUtilizationBefore;
                tempData["privateUtilizationAfter"] = data.privateServerUtilizationAfter;
                tempData["publicUtilizationBefore"] = data.publicServerUtilizationBefore;
                tempData["publicUtilizationAfter"] = data.publicServerUtilizationAfter;
                tempData["premisePUEBefore"] = data.premisePueBefore;
                tempData["premisePUEAfter"] = data.premisePueAfter;
                tempData["publicPUEBefore"] = data.publicPueBefore;
                tempData["publicPUEAfter"] = data.publicPueAfter;

                dispatchCountData({ type: `SET_${inputName.toString().toUpperCase()}_ALL`, payload: tempData });
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let beforeC = 0;
            let afterC = 0;
            Object.keys(countData).forEach(key => {
                if (key === "physical" || key === "private" || key === "public") {
                    beforeC += countData[key].before;
                    afterC += countData[key].after;
                }
            });
            setBeforeCount(beforeC);
            setAfterCount(afterC);
            setCF();
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [countData]);

    const ColoredLine = ({ color, height, styles }) => (
        <hr
            style={{
                backgroundColor: color,
                height: height,
                ...styles,
                marginLeft: "-20px",
                marginRight: "-10px",
            }}
        />
    );

    return (
        <Collapse in={collapseControll}>
            <Element name={`scrollDataCentreMigration${inputName.replace(" ", "")}`} className="element" >
                <div style={{ margin: "10px", marginBottom: "0px", marginTop: "0px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <ColoredLine color="#161616" height="1.5px" styles={{ margin: "0px" }} />
                    <div style={{ padding: "0px", position: "sticky", top: "108px", zIndex: 1, backgroundColor: "#343434", marginLeft: "-20px", marginRight: "-10px" }}>
                        <Row style={{ padding: "5px 10px 5px 20px" }}>
                            <Col lg={4} md={4} sm={12}>
                                <div style={{ paddingTop: "1.4rem", paddingBottom: "0.4rem", fontSize: "16px", textAlign: "left", color: "#F4F4F4", backgroundColor: "#343434" }}>{label}</div>
                            </Col>
                            <Col lg={4} md={4} sm={12} style={{ paddingTop: "27px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                                {
                                    props.master.electricityFactorMaster[inputName] === undefined ? (
                                        <span>This country is currently not available !</span>
                                    ) : (<></>)
                                }
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                {
                                    carbonFootprint >= 0 ? (<div className="carbonFootprint" >
                                        <p style={{ paddingTop: "1px" }}>Carbon Footprint</p>
                                        <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                        <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </div>) : (
                                        <div className="carbonFootprint" >
                                            <p style={{ paddingTop: "1px" }}>Carbon Emission</p>
                                            <h5 style={{ lineHeight: "0.5", paddingTop: "5px", marginTop: "10px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                            <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                        </div>)
                                }

                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "14px", color: "#F4F4F4" }}>Number of physical servers / virtual machines</p>
                            <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "12px", color: "rgba(255, 255, 255, 0.87)" }}>Physical servers in estate</p>
                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-physical-before`} name={`${id}-${inputName}-physical-before`} label={"Before"} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                <Input className="numberType" type="number" id={`${id}-${inputName}-physical-after`} name={`${id}-${inputName}-physical-after`} label={"After"} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                            </div>
                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>VMs in physical / private cloud</p>
                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-private-before`} name={`${id}-${inputName}-private-before`} label={"Before"} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                <Input className="numberType" type="number" id={`${id}-${inputName}-private-after`} name={`${id}-${inputName}-private-after`} label={"After"} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                            </div>
                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>VMs in public cloud</p>
                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-public-before`} name={`${id}-${inputName}-public-before`} label={"Before"} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                <Input className="numberType" type="number" id={`${id}-${inputName}-public-after`} name={`${id}-${inputName}-public-after`} label={"After"} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                            </div>
                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "18px", fontFamily: "Ubuntu Mono" }}>Total server count</p>
                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} style={{ marginTop: "0px" }} >
                                <Row style={{ fontSize: "32px", fontFamily: "Ubuntu Mono", width: "100%" }}>
                                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "25px" }}>
                                        <span>{beforeCount}</span>
                                    </Col>
                                    <Col sm={6} md={6} lg={6} style={{ paddingLeft: "25px" }}>
                                        <span>{afterCount}</span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                        </Col>
                    </Row>
                    {
                        (countData["physical"].before > 0 || countData["physical"].after > 0) ?
                            (
                                <>
                                    <ColoredLine color="#161616" height="1.5px" styles={{ margin: "0px" }} />
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "14px", color: "#F4F4F4" }}>Breakdown of physical servers in estate by size</p>
                                            <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "12px", color: "rgba(255, 255, 255, 0.87)" }}>Smalls physical servers (e.g. HP DL360 - 100w)</p>
                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-small-before`} name={`${id}-${inputName}-small-before`} label={"% before"} prefixValue={countData["small"]["before"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: { positive: (value) => value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed", percentage: (value) => (value <= (100 - (countData.medium.before + countData.large.before))) ? true : "Small, Medium and Large physical server's before percentage should sum up less than or equal to 100" } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-small-after`} name={`${id}-${inputName}-small-after`} label={"% after"} prefixValue={countData["small"]["after"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: { positive: (value) => value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed", percentage: (value) => (value <= (100 - (countData.medium.after + countData.large.after))) ? true : "Small, Medium and Large physical server's after percentage should sum up less than or equal to 100" } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                            </div>
                                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>Medium physical servers (e.g. HP DL380 - 150w)</p>
                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-medium-before`} name={`${id}-${inputName}-medium-before`} label={"% before"} prefixValue={countData["medium"]["before"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: { positive: (value) => value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed", percentage: (value) => (value <= (100 - (countData.small.before + countData.large.before))) ? true : "Small, Medium and Large physical server's before percentage should sum up less than or equal to 100" } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-medium-after`} name={`${id}-${inputName}-medium-after`} label={"% after"} prefixValue={countData["medium"]["after"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: { positive: (value) => value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed", percentage: (value) => (value <= (100 - (countData.small.after + countData.large.after))) ? true : "Small, Medium and Large physical server's after percentage should sum up less than or equal to 100" } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                            </div>
                                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>Large physical servers (e.g. HP DL980 - 400w)</p>
                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-large-before`} name={`${id}-${inputName}-large-before`} label={"% before"} prefixValue={countData["large"]["before"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: { positive: (value) => value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed", percentage: (value) => (value <= (100 - (countData.small.before + countData.medium.before))) ? true : "Small, Medium and Large physical server's before percentage should sum up less than or equal to 100" } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-large-after`} name={`${id}-${inputName}-large-after`} label={"% after"} prefixValue={countData["large"]["after"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ validate: { positive: (value) => value >= 0 && value <= 100 ? true : "Only non-negative number between 0 - 100 is allowed", percentage: (value) => (value <= (100 - (countData.small.after + countData.medium.after))) ? true : "Small, Medium and Large physical server's after percentage should sum up less than or equal to 100" } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12}>
                                        </Col>
                                        <Col lg={3} md={3} sm={12}>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <></>
                            )
                    }
                    {
                        (countData["physical"].before > 0 || countData["physical"].after > 0 || countData["private"].before > 0 || countData["private"].after > 0 || countData["public"].before > 0 || countData["public"].after > 0) ?
                            (
                                <>
                                    <ColoredLine color="#161616" height="1.5px" styles={{ margin: "0px" }} />
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "14px", color: "#F4F4F4" }}>Typical server utilisation</p>
                                            {
                                                (countData["physical"].before > 0 || countData["physical"].after > 0) ?
                                                    (
                                                        <>
                                                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>Physical servers</p>
                                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-physicalUtilization-before`} name={`${id}-${inputName}-physicalUtilization-before`} label={"% before"} prefixValue={countData["physicalUtilization"]["before"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ required: false, validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-physicalUtilization-after`} name={`${id}-${inputName}-physicalUtilization-after`} label={"% after"} prefixValue={countData["physicalUtilization"]["after"]} defaultValue={countData["physicalUtilization"]["after"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ required: false, validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                            }
                                            {
                                                (countData["private"].before > 0 || countData["private"].after > 0) ?
                                                    (
                                                        <>
                                                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>Private cloud physical servers</p>
                                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-privateUtilization-before`} name={`${id}-${inputName}-privateUtilization-before`} label={"% before"} prefixValue={countData["privateUtilization"]["before"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ required: false, validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-privateUtilization-after`} name={`${id}-${inputName}-privateUtilization-after`} label={"% after"} prefixValue={countData["privateUtilization"]["after"]} defaultValue={countData["privateUtilization"]["after"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ required: false, validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                            }
                                            {
                                                (countData["public"].before > 0 || countData["public"].after > 0) ?
                                                    (
                                                        <>
                                                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>Public cloud physical servers</p>
                                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-publicUtilization-before`} name={`${id}-${inputName}-publicUtilization-before`} label={"% before"} prefixValue={countData["publicUtilization"]["before"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ required: false, validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                                <Input className="numberType" suffix={true} suffixContent={'%'} suffixAlign={'left'} type="number" id={`${id}-${inputName}-publicUtilization-after`} name={`${id}-${inputName}-publicUtilization-after`} label={"% after"} prefixValue={countData["publicUtilization"]["after"]} defaultValue={countData["publicUtilization"]["after"]} onWheel={(e) => onWheel(e)} placeholder={inputPlaceholder} tooltip={true} register={register({ required: false, validate: (value) => value !== "" ? value >= 0 ? true : "Only non-negative number is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                            }
                                        </Col>
                                        <Col lg={3} md={3} sm={12}>
                                        </Col>
                                        <Col lg={3} md={3} sm={12}>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <></>
                            )
                    }
                    {
                        (countData["physical"].before > 0 || countData["physical"].after > 0 || countData["private"].before > 0 || countData["private"].after > 0 || countData["public"].before > 0 || countData["public"].after > 0) ?
                            (
                                <>
                                    <ColoredLine color="#161616" height="1.5px" styles={{ margin: "0px" }} />
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "14px", color: "#F4F4F4" }}>Data centre efficiency metric (PUE)</p>
                                            <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "12px", color: "rgba(255, 255, 255, 0.87)" }}>Data centre efficiency statistics</p>
                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                <Input className="numberType" type="number" id={`${id}-${inputName}-premisePUE-before`} name={`${id}-${inputName}-premisePUE-before`} label={"Before"} onWheel={(e) => onWheel(e)} placeholder={"1.0"} defaultValue={1.7} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "Only number greater than or equal to 1 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                <Input className="numberType" type="number" id={`${id}-${inputName}-premisePUE-after`} name={`${id}-${inputName}-premisePUE-after`} label={"After"} onWheel={(e) => onWheel(e)} placeholder={"1.0"} defaultValue={1.7} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "Only number greater than or equal to 1 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                            </div>
                                            <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "12px" }}>Public cloud environment efficiency statistics</p>
                                            <div className={`${id}-${inputName}-dataCentreMigrationCollapse`} >
                                                <Input className="numberType" type="number" id={`${id}-${inputName}-publicPUE-before`} name={`${id}-${inputName}-publicPUE-before`} label={"Before"} onWheel={(e) => onWheel(e)} placeholder={"1.0"} defaultValue={1.11} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "Only number greater than or equal to 1 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                                <Input className="numberType" type="number" id={`${id}-${inputName}-publicPUE-after`} name={`${id}-${inputName}-publicPUE-after`} label={"After"} onWheel={(e) => onWheel(e)} placeholder={"1.0"} defaultValue={1.11} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "Only number greater than or equal to 1 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => inputChange(e)} />
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} sm={12}>
                                        </Col>
                                        <Col lg={3} md={3} sm={12}>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <></>
                            )
                    }
                    <Row style={{ paddingTop: 20 }}>
                        <Col>
                            <InputTextArea rows={"3"} className="textType" type="text" id={`${id}-${inputName}-assumptions`} name={`${id}-${inputName}-assumptions`} label={"Assumption"} placeholder={"Assumptions"} maxLength={1000} register={register({ maxLength: 1000 })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "150%", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", height: "70px", width: "65%" }} onChange={(e) => { inputChange(e); characterChange(e.target.value); setCharacterCountDC(e.target.value.length) }} />
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: 10 }}>
                        <Col>
                            <span style={{ fontSize: "10px", fontWeight: "normal" }} register={register} >Remaining characters  -  {1000 - characterCountDC}</span>
                        </Col>
                    </Row>
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(CollapseDataCentreMigrationCustom);