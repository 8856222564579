import React, { useState, useReducer, useEffect, useRef } from "react";
import {
    Link
} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./WaterTabs.css";
import Select from "../select/SelectCustom";
import CollapseWaterConsumptionCustom from "./CollapseWaterConsumptionCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../errorBoundaries/ErrorBoundaryForPage";
import { mainCountries } from "../../calculatorFunctions/mainCountries";
import { deleteConfirm } from "../deleteConfirm";
import Assumption from "./Assumption";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const unitOptions = {
    litre: { value: "litre", label: "l" },
    gallon: { value: "gallon", label: "gal" }
};

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var dataAssumption = {};
var sTime = new Date();
var currentParent = "";

const WaterTabs = (props) => {

    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, {});
    // const form = useForm();
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [waterSourceType, setWaterSourceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const filterCountry = (inputValue) => {
        const grouped = groupBy(countries, country => country.label);
        const top = grouped.get("top")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        const bottom = grouped.get("bottom")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return [
            {
                label: "top",
                options: top
            },
            {
                label: "bottom",
                options: bottom
            }
        ];
    };

    const getCountryOptionsData = (inputValue, callback) => {
        callback(filterCountry(inputValue));
    }

    const saveData = () => {

        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 1)];
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "waterConsumption") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, volume: getValues(`${key}-${item}-volume`), unit: getValues(`${key}-${item}-volume-select`), carbonFootprint: collapseState[item].cf.value, supply: collapseState[item].cf.supply, treatment: collapseState[item].cf.treatment, year: collapseState[item].cf.year } }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                        if (waterSourceType[dataArray[item]["dataSource"]] === "Water consumption") {
                            if (dataArray[item]["volume"] > 0) {
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "volume": dataArray[item]["volume"] === undefined || dataArray[item]["volume"] === "" ? null : parseFloat(dataArray[item]["volume"]),
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                            "projectId": projectId,
                                            "waterFactor": {
                                                "supply": dataArray[item]["supply"],
                                                "treatment": dataArray[item]["treatment"],
                                                "year": dataArray[item]["year"]
                                            },
                                            "country": {
                                                "name": item
                                            },
                                            "unitOfMeasurement": {
                                                "name": dataArray[item]["unit"].value
                                            },
                                            "waterSourceType": {
                                                "name": waterSourceType[dataArray[item]["dataSource"]]
                                            }
                                        }
                                    ]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                return false;
                            }
                        }

                        axios.post(`${basePath}/calculator/water`, jsonData, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`
                            }
                        })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "Water calculator data saved!" });
                                assumptionSaveData();
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                    });
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please select a country in water calculator and fill in the details!" });
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a country in water calculator and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const assumptionSaveData = () => {
        let wassumption = getValues(`${key}-assumptions`)
        if(wassumption === "" || wassumption.length > 0){
            let wdata = {
                "projectId": projectId,
                "data": [{
                    "projectId": projectId,
                    "assumptions": wassumption
                }]
            }
            axios.post(`${basePath}/calculator/waterAssumption`, wdata, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    sTime = new Date();
                    setIsDataPresent(true);
                    setSavedTime(sTime);
                    props.calculateSummary();
                    // props.toastMessage({ state: true, header: "Success", body: "Water calculator data saved!" });
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                });
         }

    }
    

    const setUnsetCountry = (val, parent) => {
        currentParent = parent;
        if (val !== null) {
            setSelectedCountries(val);
        } else {
            setSelectedCountries([]);
        }
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent === val.parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/water/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Water calculator cannot be deleted! Server Error" })
                    });

                axios.delete(`${basePath}/calculator/waterAssumption/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Water calculator cannot be deleted! Server Error" })
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    const formatGroupLabel = (data) => {
        if (data.label !== "top") {
            return (
                <hr style={{ left: "-10px", height: "0.5px", backgroundColor: "#f0f0f0", margin: "0px", padding: "0px", marginTop: "-10px" }} />
            )
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (selectedCountries.length > 0) {
                Object.keys(collapseState).forEach(k => {
                    var found = false;
                    for (const item of selectedCountries) {
                        if (k === item.value) {
                            found = true;
                        }
                    }
                    if (!found) {
                        dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                    }
                });
                for (const item of selectedCountries) {
                    if (!collapseState.hasOwnProperty(item.value)) {
                        dispatchCollapseState({ type: "COLLAPSE_ADD", payload: { name: item.value, parent: currentParent } });
                    }
                }
            } else {
                Object.keys(collapseState).forEach(k => {
                    dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                });
            }
        }
        // eslint-disable-next-line
    }, [selectedCountries])

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {};
                if (Object.keys(waterSourceType).length === 0) {
                    for (const fst of props.master.waterSourceType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setWaterSourceType(data);
                }
                if (countries.length === 0) {
                    var tempGroup = [];
                    let temp = [];
                    for (const c of Object.keys(mainCountries)) {
                        if (props.master.waterFactorMaster.hasOwnProperty(c)) {
                            temp.push({ value: c, label: c });
                        }
                    }
                    tempGroup.push(
                        {
                            label: "top",
                            options: temp
                        }
                    );
                    temp = [];
                    for (const c of props.master.country) {
                        if (props.master.waterFactorMaster.hasOwnProperty(c.value) && !mainCountries.hasOwnProperty(c.value) && c.value !== "Other") {
                            temp.push({ value: c.value, label: c.value });
                        }
                    }
                    temp.push({ value: "Other", label: "Other" });
                    tempGroup.push(
                        {
                            label: "bottom",
                            options: temp
                        }
                    );
                    setCountries(tempGroup);
                }

                if (Object.keys(sources).length === 0) {
                    for (const wfm of Object.keys(props.master.waterFactorMaster)) {
                        for (const wt of Object.keys(props.master.waterFactorMaster[wfm])) {
                            if (wt.split("Source").length > 1) {
                                if (!years.includes(props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"])) {
                                    years.push(props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]);
                                }

                                if (sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]] === undefined) {
                                    sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]] = { source: [] };
                                }
                                if (!sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]].source.includes(props.master.waterFactorMaster[wfm][wt])) {
                                    sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]].source.push(props.master.waterFactorMaster[wfm][wt]);
                                    sources[props.master.waterFactorMaster[wfm][wt.split("Source")[0] + "Year"]].source.sort();
                                }
                            }
                        }
                    }
                    years = years.sort((a, b) => a - b);
                }
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].waterSourceType.name === "Water consumption") {
                    data["dataSource"] = "waterConsumption";
                    setKey(`${componentId}-waterConsumption`);
                }
            } else {
                setKey(`${componentId}-waterConsumption`);
                setIsDataPresent(false);
            }
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                if (props.data.dataAssumption) {
                    // data["dataSource"] = "waterConsumption";
                    setKey(`${componentId}-waterConsumption`);
                }
            } else {
                setKey(`${componentId}-waterConsumption`);
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
                setRefresh(prev => ++prev);
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var sCountries = [];
                    var st = "";
                    props.data.data.forEach(obj => {
                        var fSourceType = "";
                        switch (obj.waterSourceType.name) {
                            case "Water consumption":
                                fSourceType = "waterConsumption";
                                break;
                            default:
                                fSourceType = "waterConsumption";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.country.name, parent: fSourceType, status: true } });
                        for (const gc of countries) {
                            for (const c of gc.options) {
                                if (c.value === obj.country.name) {
                                    sCountries.push(c);
                                    break;
                                }
                            }
                        }
                        if (fSourceType === "waterConsumption") {
                            setValue(`${componentId}-${fSourceType}-${obj.country.name}-volume`, obj.volume);
                            setValue(`${componentId}-${fSourceType}-${obj.country.name}-volume-select`, { value: unitOptions[obj.unitOfMeasurement.name].value, label: unitOptions[obj.unitOfMeasurement.name].label });
                        }
                        st = obj.createdAt;
                    });
                    setSelectedCountries(sCountries);
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
                // assumption
                if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                    data["dataAssumption"] = {};
                    var st = "";
                    props.data.dataAssumption.forEach(obj => {
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { status: true } });
                        setValue(`${componentId}-waterConsumption-assumptions`, obj.assumptions);
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [key, isMountedRef]);

    let collapseAssumption;
    if (isMountedRef.current) {
        if (selectedCountries.length > 0) {
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].status === true) {
                    collapseAssumption = <Assumption
                        key={`${componentId}-waterConsumption-collapse`}
                        collapseControll={getLogic(item, "waterConsumption")}
                        label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                        id={`${componentId}-waterConsumption`}
                        inputName={item}
                        textValue={getValues(`${componentId}-waterConsumption-assumptions`)}
                        selectName={item}
                        control={control}
                        register={register}
                        errors={errors}
                    />
                }
            })
        }
    }

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#148AFF", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "5px" }}>
                                    <span>Water</span><span style={{ marginLeft: "20px", fontSize: "10px", fontStyle: "italic" }}>Calculator sponsored by the UK EUC team</span>
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Water calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={saveData} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <div className="waterTabs">
                                <Tabs
                                    id={`${componentId}-waterTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >
                                    <Tab eventKey={`${componentId}-waterConsumption`} title="Water Consumption" disabled={key !== "" && key !== `${componentId}-waterConsumption`} key={`${componentId}-waterConsumption`}>
                                        <Tab.Container>
                                            <Row>
                                                <Col sm={12} style={{ textAlign: "left", paddingTop: "10px" }}>
                                                    <Controller
                                                        render={({ onChange, onBlur, value, name }) => (
                                                            <Select name={name} label={"Country"} formatGroupLabel={formatGroupLabel} closeMenuOnSelect={true} isMulti={true} onChange={(val) => { setUnsetCountry(val, "waterConsumption"); return onChange(val) }} onBlur={onBlur} value={selectedCountries} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ width: "auto", flexGrow: 1, marginLeft: "10px", marginRight: "10px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#148AFF80" singleValueFontSize={"12px"} />
                                                        )}
                                                        control={control}
                                                        name="country2"
                                                        defaultValue={''}
                                                    />
                                                </Col>
                                                <Col sm={12}>
                                                    <Nav variant="pills" id={`${componentId}-waterConsumptionPills`} className="flex-row waterPills">
                                                        {
                                                            Object.keys(collapseState).map(item => {
                                                                return (
                                                                    <Nav.Item key={`${componentId}-waterConsumption-${item}-collapsePills`}>
                                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "waterConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "waterConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-waterConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "waterConsumption", status: !collapseState[item].status } })}>{`${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            })
                                                        }
                                                    </Nav>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="waterPillsCollapse">
                                                        {
                                                            Object.keys(collapseState).map(item => (
                                                                <CollapseWaterConsumptionCustom
                                                                    key={`${componentId}-waterConsumption-${item}-collapse`}
                                                                    collapseControll={getLogic(item, "waterConsumption")}
                                                                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                                                    id={`${componentId}-waterConsumption`}
                                                                    inputName={item}
                                                                    volumeValue={getValues(`${componentId}-waterConsumption-${item}-volume`)}
                                                                    selectName={item}
                                                                    selectLabel={"Units"}
                                                                    selectValue={getValues(`${componentId}-waterConsumption-${item}-volume-select`)}
                                                                    control={control}
                                                                    register={register}
                                                                    errors={errors}
                                                                    cFootprint={(val) => calulateTotalCF(val)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Col>
                                                {collapseAssumption}
                                            </Row>
                                        </Tab.Container>
                                    </Tab>
                                    {/* )
                            } */}
                                </Tabs>
                            </div>
                        </div>
                        <div className="waterBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in water consumption</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(WaterTabs));