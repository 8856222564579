const { isNumeric } = require('./utils.js');
require('./utils.js')

/* Calculates total carbon footprint (tonnes CO<sub>2</sub>e) for plastic used.  */
    function footprintForPlasticCalc(amount, unitValue, materialValue,disposalValue) {
    //console.log(amount, unitValue, materialValue,disposalValue,  "in calc");
   
    const materialTonnes = unitValue === "tonnes" ?  amount : unitValue === "kg" ? (amount/1000) :0
    if (!isNumeric(materialValue)) throw TypeError("'materialValue' must be numeric")
    if (!isNumeric(disposalValue)) throw TypeError("'disposalValue' must be numeric")

     const materialCarbonFactor = materialValue === undefined || materialValue === "" ? "material value not defined" : materialValue;
    const disposalCarbonFactor =  disposalValue === undefined || disposalValue === "" ? "disposal value not defined" : disposalValue;

    const CarbonFootprintPlastic = (materialTonnes * materialCarbonFactor) + (materialTonnes *  disposalCarbonFactor);
    return CarbonFootprintPlastic;
        }
module.exports = {
    footprintForPlasticCalc: footprintForPlasticCalc,
}