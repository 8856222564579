import React, { useState } from "react";
import "./Footer.css";
import { Row, Col } from "react-bootstrap";
import {
    Link
} from "react-router-dom";
import footerLogo from "../../../newlogo.png";
import ToastCustom from "../../toastCustom/ToastCustom2";

function Footer(props) {

    const [showToast, setShowToast] = useState({ state: false, header: "", body: "" });

    return (
        <React.Fragment>
            <hr />
            <div className="footer-div">
                <footer style={{ width: "100%", alignSelf: "center" }}>
                    <Row style={{ margin: "0px" }}>
                        <Col xs={5} sm={6} md={3} lg={2} style={{ justifyContent: "flex-start", display: "flex" }}>
                            {/* <img width="141.24px" height="31.94px" src={footerLogo} alt={"Capgemini"} /> */}
                            <img width="171.24px" height="71.94px" src={footerLogo} alt={"Capgemini"} />
                        </Col>
                        <Col xs={7} sm={6} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 6 }} style={{ justifyContent: "flex-end", display: "flex" }}>
                            <Row style={{ marginTop: "20px", marginRight: "57px", maxWidth: "250px", minWidth: "200px" }}>
                                <Col>
                                    <Link to="/about" style={{ color: "rgba(255, 255, 255, 0.87)", fontSize: "14px" }} >About</Link>
                                </Col>
                                <Col  >
                                    <span
                                        style={{ color: "rgba(255, 255, 255, 0.87)", fontSize: "14px", cursor: "pointer" }}
                                        onClick={() => setShowToast({
                                            state: true,
                                            header: "Help",
                                            body: <div style={{ fontSize: "13px" }}><p>For more information please visit the <a href="https://x-port.capgemini.com/carbon-impact-solution-group-cisg/" target="_blank" style={{ fontSize: "13px", color: "#00008B" }}>Carbon Impact Solution Group </a>page. If you require support with calculations please use the <a href="https://forms.office.com/pages/responsepage.aspx?id=Wq6idgCfa0-V7V0z13xNYTCeeL7jB-FMrulVSv7B4GxUQ0I1UlI5WDlETUtKS0xTOUNOTE5VNVlFQyQlQCN0PWcu" target="_blank" style={{ fontSize: "13px", color: "#00008B" }}>request from</a> and a member of the team will be in contact. Should you have any feedback please <br />
                                                email (<span><a href="mailto: carbonimpactsolutiongroup.global@capgemini.com" style={{ color: "#00008B" }} >carbonimpactsolutiongroup.global@capgemini.com</a></span>).
                                            </p></div>
                                        })}
                                    > <Link style={{ color: "rgba(255, 255, 255, 0.87)", fontSize: "14px" }}>Help</Link></span>

                                </Col>
                                <Col >
                                    <span
                                        style={{ color: "rgba(255, 255, 255, 0.87)", fontSize: "14px", cursor: "pointer", right: "100px" }}
                                        onClick={() => setShowToast({
                                            state: true,
                                            header: "Disclaimer",
                                            body: <div style={{ fontSize: "13px" }}><p>The information in this report constitutes indicative estimates based on the data provided at the time of completion. These outcomes are not guaranteed and no reliance should be placed on them. Capgemini makes no representations and gives no assurances or warranties as to the accuracy or completeness of the information in the report and accepts no liability should the estimates not be achieved.</p></div>
                                        })}
                                    ><Link style={{ color: "rgba(255, 255, 255, 0.87)", fontSize: "14px" }}>Legal</Link></span>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </footer>
            </div>
            <ToastCustom variant="bw" onClose={() => setShowToast({ state: false, header: "", body: "" })} show={showToast.state} delay={35000} header={showToast.header} body={showToast.body} autoHide={false} />
        </React.Fragment>
    )
}

export default Footer
