import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import "./PaginationCustom.scss";

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

const PaginationCustom = (props) => {

    var { totalRecords = null, pageLimit = 30, pageNeighbours = 0, styles } = props;

    pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
    totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

    // pageNeighbours can be: 0, 1 or 2
    pageNeighbours = typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    const totalPages = Math.ceil(totalRecords / pageLimit);

    const [currentPage, setCurrentPage] = useState(1);


    const fetchPageNumbers = () => {
        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {

            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }

    useEffect(() => {
        gotoPage(1);
        setCurrentPage(1);
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        const { onPageChanged = f => f } = props;
        const paginationData = {
            currentPage,
            totalPages,
            pageLimit,
            totalRecords
        };
        onPageChanged(paginationData);
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        gotoPage(props.cPage);
        setCurrentPage(props.cPage);
        // eslint-disable-next-line
    }, [props.cPage, props.refreshPagination]);

    const gotoPage = page => {
        if (currentPage === page) {
            props.onClick(false);
        }
        setCurrentPage(Math.max(0, Math.min(page, totalPages)));
    }

    const handleClick = (evt, page) => {
        evt.preventDefault();
        props.onClick(true);
        gotoPage(page);
    }

    const handleMoveLeft = evt => {
        evt.preventDefault();
        props.onClick(true);
        gotoPage(currentPage - (pageNeighbours * 2) - 1);
    }

    const handleMoveRight = evt => {
        evt.preventDefault();
        props.onClick(true);
        gotoPage(currentPage + (pageNeighbours * 2) + 1);
    }

    if (!totalRecords || totalPages === 1) {
        return (
            <></>
        );
    } else {
        const pages = fetchPageNumbers();
        return (
            <Fragment>
                <nav aria-label="Pagination" style={styles}>
                    <ul className="pagination">
                        {pages.map((page, index) => {

                            if (page === LEFT_PAGE) return (
                                <li key={index} className="page-item">
                                    <a className="page-link" href="/#" aria-label="Previous" onClick={handleMoveLeft}>
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                            );

                            if (page === RIGHT_PAGE) return (
                                <li key={index} className="page-item">
                                    <a className="page-link" href="/#" aria-label="Next" onClick={handleMoveRight}>
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            );

                            return (
                                <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                                    <a className="page-link" href="/#" onClick={(e) => handleClick(e, page)}>{page}</a>
                                </li>
                            );

                        })}

                    </ul>
                </nav>
            </Fragment>
        );
    }
}

PaginationCustom.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func
};

export default PaginationCustom;