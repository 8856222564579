const { isNumeric } = require('./utils.js');

require('./utils.js')

/** 
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for passenger aviation type.
 * @param  {number} distance Aviation Distance travelled (km) [user(text)]
 * @param  {number} AvgPassenger Number of average passernger 
 * @param  {number} EcoPassenger Number of Economy passernger 
 * @param  {number} PreEcoPassenger Number of Premium Economy passernger 
 * @param  {number} BCPassenger Number of Business Class passernger 
 * @param  {number} FCPassenger Number of First Class passernger 
 * @param  {number} emissionFactorAvg Average Emission Factor
 * @param  {number} emissionFactorWTTAvg Average Emission Factor WTT
 * @param  {number} emissionFactorEco Economy Emission Factor
 * @param  {number} emissionFactorWTTEco Economy Emission Factor WTT
 * @param  {number} emissionFactorPreEco Premium Economy Emission Factor
 * @param  {number} emissionFactorWTTPreEco Premium Economy Emission Factor WTT
 * @param  {number} emissionFactorBC Business Class Emission Factor
 * @param  {number} emissionFactorWTTBC Business Class Emission Factor WTT
 * @param  {number} emissionFactorFC First Class Emission Factor
 * @param  {number} emissionFactorWTTFC First Class Emission Factor WTT
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */

function footprintForAviationByPassenger(
    distance, AvgPassenger,
    EcoPassenger, PreEcoPassenger,
    BCPassenger, FCPassenger,
    emissionFactorAvg, emissionFactorWTTAvg,
    emissionFactorEco, emissionFactorWTTEco,
    emissionFactorPreEco, emissionFactorWTTPreEco,
    emissionFactorBC, emissionFactorWTTBC,
    emissionFactorFC, emissionFactorWTTFC) {

    const noOfAvgPassenger = AvgPassenger ? AvgPassenger : 0;
    const noOfEcoPassenger = EcoPassenger ? EcoPassenger : 0;
    const noOfPreEcoPassenger = PreEcoPassenger ? PreEcoPassenger : 0;
    const noOfBCPassenger = BCPassenger ? BCPassenger : 0;
    const noOfFCPassenger = FCPassenger ? FCPassenger : 0;

    const carbonFoortprintOfAvgPassenger = (emissionFactorAvg + emissionFactorWTTAvg) * (distance * noOfAvgPassenger);
    const carbonFoortprintOfEcoPassenger = (emissionFactorEco + emissionFactorWTTEco) * (distance * noOfEcoPassenger);
    const carbonFoortprintOfPreEcoPassenger = (emissionFactorPreEco + emissionFactorWTTPreEco) * (distance * noOfPreEcoPassenger);
    const carbonFoortprintOfBCPassenger = (emissionFactorBC + emissionFactorWTTBC) * (distance * noOfBCPassenger);
    const carbonFoortprintOfAvgPassengerFCPassenger = (emissionFactorFC + emissionFactorWTTFC) * (distance * noOfFCPassenger);

    const carbonFootprintPassenger = carbonFoortprintOfAvgPassenger + carbonFoortprintOfEcoPassenger + carbonFoortprintOfPreEcoPassenger + carbonFoortprintOfBCPassenger + carbonFoortprintOfAvgPassengerFCPassenger;

    return (carbonFootprintPassenger/1000);
}

/** 
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for freight aviation type.
 * @param  {number} distance Aviation Distance travelled (km) [user(text)]
 * @param  {number} weightInTonnes Number of weight used for aviation (tonnes) 
 * @param  {number} emissionFactor Emission Factor
 * @param  {number} emissionFactorWTT Emission Factor WTT
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */

function footprintForAviationByFreight(
    distance, weightInTonnes,
    emissionFactor, emissionFactorWTT
) {
    const carbonFootprintFreight = (emissionFactor + emissionFactorWTT) * (distance * weightInTonnes);
    return (carbonFootprintFreight/1000);
}

module.exports = {
    footprintForAviationByPassenger: footprintForAviationByPassenger,
    footprintForAviationByFreight: footprintForAviationByFreight
}