import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import InputTextArea from "../../components/inputTextArea/InputTextAreaCustom";
import Input from "../input/InputCustom";

const Assumption = (props) => {
    let { collapseControll, id, inputName, textValue, register, errors } = props;

    const [text, setText] = useState((textValue === undefined || textValue === "") ? 0 : textValue);
    const [characterCountWater, setCharacterCountWater] = useState(0);

    const textChange = (txt) => {
        setText((txt !== undefined && txt !== "") ? txt : 0);
    }

    const characterChange = (chr) => {
        setCharacterCountWater((chr !== undefined && chr !== "") ? chr : 0);
    }

    // useEffect(() => {
    //     if(textValue !== undefined && textValue.length !== undefined && textValue.length !== "" && textValue.length !== null && textValue.length > 0){
    //         setCharacterCountWater(textValue.length)
    //     }
    //   }, []);

    useEffect(() => {
        if(text !== undefined && text.length !== undefined && text.length !== "" && text.length !== null && text.length > 0){
            setCharacterCountWater(text.length)
        }
      }, [characterCountWater]);

    
    return (
        <Collapse in={collapseControll}>
            <Element name={`scrollWaterWaterConsumption${inputName.replace(" ", "")}`} className="element" >
                <div className={`${id}-${inputName}-waterConsumptionCollapse`} style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <Row style={{ marginLeft: "3px" }}>
                        <Col>
                            <div className={`${id}-${inputName}-waterConsumptionCollapse`} >
                                <InputTextArea rows={"3"} className="textType" type="text" id={`${id}-assumptions`} name={`${id}-assumptions`} label={"Assumptions"} placeholder={"Assumptions"} maxLength={1000} register={register({ maxLength: 1000 })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "205%", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", borderColor: "#148AFF", height: "70px", width: "165%" }} onChange={(e) => { textChange(e.target.value); characterChange(e.target.value); setCharacterCountWater(e.target.value.length) }} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: "3px" }}>
                        <Col>
                            <span style={{ fontSize: "10px", fontWeight: "normal" }} register={register} >Remaining characters  -  {1000 - characterCountWater}</span>
                        </Col>
                    </Row>
                </div>
            </Element>
        </Collapse>        
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master,
        project: state.project
    };
}

export default connect(mapStateToProps)(Assumption);