export const setSort = (data) => {
    return dispatch => {
        dispatch({
            type: "SET_SORT",
            payload: { sortBy: data }
        });
    }
}

export const resetSort = () => {
    return dispatch => {
        dispatch({
            type: "RESET_SORT"
        });
    }
}

const sortAction = {
    setSort,
    resetSort
};

export default sortAction;
