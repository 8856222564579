import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Collapse from "react-bootstrap/collapse";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import convert from "convert-units";
import { Element } from "react-scroll";
import InputTextArea from "../../components/inputTextArea/InputTextAreaCustom";
import { footprintForPaperCalc } from "../../calculatorFunctions/paper_calc";
import { Text } from '@react-pdf/renderer';
import Summary from "../summary/Summary";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const unitOptions = [
    { value: "tonnes", label: "tonnes" },
    { value: "kg", label: "kg" },
    { value: "sheets", label: "sheets" },
];

const materialOptions = [
    { value: "Primary Material Product", label: "Primary Material Product" },
    { value: "Closed-loop Source", label: "Closed-loop Source" },
];

const disposalOptions = [
    { value: "Landfill", label: "Landfill" },
    { value: "Closed-loop", label: "Closed-loop" },
];

const postageOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
]

const CollapsePaperConsumptionCustom = (props) => {
    let { id, inputName, register, inputValue, selectName, selectMaterialValue, selectPost, selectDisp, selectDisposalValue, selectPostageValue, selectLabel, selectUnitValue, control, volumeValue, textValue, characterValue, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [text, setText] = useState((textValue === undefined || textValue === "") ? "" : textValue);
    const [volume, setVolume] = useState((volumeValue === undefined || volumeValue === "") ? 0 : volumeValue);
    const [unit, setUnit] = useState((selectUnitValue === undefined || selectUnitValue === "") ? unitOptions[0] : selectUnitValue);
    const [material, setMaterial] = useState((selectMaterialValue === undefined || selectMaterialValue === "") ? materialOptions[0] : selectMaterialValue);
    const [disposal, setDisposal] = useState((selectDisposalValue === undefined || selectDisposalValue === "") ? disposalOptions[0] : selectDisposalValue);
    const [postage, setPostage] = useState((selectPostageValue === undefined || selectPostageValue === "") ? postageOptions[0] : selectPostageValue);

    const [characterCount, setcharacterCount] = useState((characterValue === undefined || characterValue === "") ? 0 : characterValue);

    const getUnitOptions = (inputVal, callback) => {
        callback(unitOptions);
    }

    const getmaterialOptions = (inputVal, callback) => {
        callback(materialOptions)
    }

    const getDisposalOptions = (inputVal, callback) => {
        callback(disposalOptions)
    }

    const getPostageOptions = (inputVal, callback) => {
        callback(postageOptions)
    }

    const onUnitChange = (val) => {
        setUnit(val);
    }

    const onMaterialChange = (val) => {
        setMaterial(val);
    }

    const onDisposalChange = (val) => {
        setDisposal(val);
    }

    const onPostageChange = (val) => {
        setPostage(val);
    }

    const inputTextChange = (val) => {
        setVolume((val !== undefined && val !== "") ? val : "");
    }

    const textChange = (txt) => {
        setText((txt !== undefined && txt !== "") ? txt : "");
    }
    const characterChange = (chr) => {
        setcharacterCount((chr !== undefined && chr !== "") ? chr : 0);
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const setCF = () => {
        var cf = 0;
        var amount = volume === undefined || volume === "" ? 0 : volume;
        var assumptions = text === undefined || text === "" ? "" : text;
        var characters = characterCount=== undefined || characterCount === "" ? 0 : characterCount;
        var unitValue = unit.value;
        var materialValue =
            props.master.paperMaterialUseMaster[material.value] !== undefined &&
                props.master.paperMaterialUseMaster[material.value]
                    .materialUseFactor !== undefined
                ? props.master.paperMaterialUseMaster[material.value]
                    .materialUseFactor
                : 0;
        var disposalValue =
            props.master.paperWasteDisposalMaster[disposal.value] !== undefined &&
                props.master.paperWasteDisposalMaster[disposal.value]
                    .wasteDisposalFactor !== undefined
                ? props.master.paperWasteDisposalMaster[disposal.value]
                    .wasteDisposalFactor
                : 0;
        var postageValue = postage.value !== "undefined" || postage.value !== "" ? postage.value : 0;
        // var postageValue = postage.value;

        var year = props.master.paperMaterialUseMaster[material.value] !== undefined && props.master.paperMaterialUseMaster[material.value].year !== undefined ? props.master.paperMaterialUseMaster[material.value].year : new Date().getFullYear();
        var source = props.master.paperMaterialUseMaster[material.value] !== undefined && props.master.paperMaterialUseMaster[material.value].source !== undefined ? props.master.paperMaterialUseMaster[material.value].source : new Date().getFullYear();
        cf = footprintForPaperCalc(amount, unitValue, materialValue, disposalValue, postageValue);
        props.cFootprint({
            name: inputName,
            parent: "PaperConsumption",
            cf: { value: cf, volume, unit, material, disposal, postage, year, source, assumptions,characters },
        });
        setCarbonFootprint(cf / 1000);
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if (unit !== undefined && unit !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (volume !== undefined && volume !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (text !== undefined && text !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (characterCount !== undefined && characterCount !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (material !== undefined && material !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }

            if (disposal !== undefined && disposal !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }

            if (postage !== undefined && postage !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
    }, [unit, volume, text, material, disposal, postage]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    return (
        <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
             {
                    //    {1000 - characterCount} ?
                            (<Summary
                               characterdetails={1000 - characterCount}
                                
                            />)
                            // : null
                    }
            {/* amount of paper */}
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div className={`${id}-${inputName}-paperConsumptionCollapse`} style={{ fontSize: "12px", fontWeight: "normal" }} >
                        <Input className="numberType" type="number" id={`${id}-amount`} name={`${id}-amount`} label={"Yearly amount of paper consumed"} placeholder={"0"} tooltip={true} register={register({ validate: (value) => value > 0 && isInt(value) ? true : "Only non-negative integer greater than 0 allowed" })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "60%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", borderColor: "#6c757d", height: "40px" }} onChange={(e) => inputTextChange(e.target.value)} />
                    </div>
                </Col>
                <Col>
                    {
                        control !== undefined ? (
                            <Controller
                                render={({ onChange, value, name }) => (
                                    <Select id={name} name={name} value={value} onChange={(val) => { onUnitChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getUnitOptions} label="Units" defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#148AFF80" />
                                )}
                                control={control}
                                name={`${id}-volume-select`}
                                defaultValue={unit}
                            />
                        ) : (<></>)
                    }
                </Col>
            </Row>

            {/* material and disposal */}
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div className={`${id}-${inputName}-paperConsumptionCollapse`} >
                        {
                            control !== undefined ? (
                                <Controller
                                    render={({ onChange, value, name }) => (
                                        <Select id={name} name={name} value={value} onChange={(val) => { onMaterialChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getmaterialOptions} label="Material" defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#148AFF80" />
                                    )}
                                    control={control}
                                    name={`${id}-materialOptions-select`}
                                    defaultValue={material}
                                />
                            ) : (<></>)
                        }
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <div className={`${id}-${inputName}-paperConsumptionCollapse`} >
                        {
                            control !== undefined ? (
                                <Controller
                                    render={({ onChange, value, name }) => (
                                        <Select id={name} name={name} value={value} onChange={(val) => { onDisposalChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getDisposalOptions} label="Waste Disposal" defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#148AFF80" />
                                    )}
                                    control={control}
                                    name={`${id}-disposalOptions-select`}
                                    defaultValue={disposal}
                                />
                            ) : (<></>)
                        }
                    </div>
                </Col>
            </Row>

            {/* postage */}
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div className={`${id}-${inputName}-paperConsumptionCollapse`} >
                        {
                            control !== undefined ? (
                                <Controller
                                    render={({ onChange, value, name }) => (
                                        <Select id={name} name={name} value={value} onChange={(val) => { onPostageChange(val); return onChange(val); }} labelStyle={{ fontSize: "10px", color: "white" }} errors={errors} loadOptions={getPostageOptions} label="Postage" defaultOptions={true} formGroupStyle={{ flexGrow: 1, color: "black" }} styles={{ color: "black", background: "white !important", backgroundColor: "white !important", fontSize: "16px" }} color={`#6c757d`} onClickColor="#148AFF80" />
                                    )}
                                    control={control}
                                    name={`${id}-postageOptions-select`}
                                    defaultValue={postage}
                                />
                            ) : (<></>)
                        }
                    </div>

                                 
                </Col>
            </Row>

            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div className={`${id}-${inputName}-paperConsumptionCollapse`} style={{ fontSize: "12px", fontWeight: "normal" }} >
                        <InputTextArea rows={"3"} className="textType" type="text" id={`${id}-assumptions`} name={`${id}-assumptions`} label={"Assumptions"} placeholder={"Assumptions"} maxLength={1000} register={register({ maxLength: 1000 })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "208%", marginRight: "10px" }} style={{ textAlign: "left", borderBottomWidth: "3px", borderColor: "#6c757d", height: "70px" }} onChange={(e) => { textChange(e.target.value);characterChange(e.target.value); setcharacterCount(e.target.value.length) }} />
                        <Row>
                            <Col sm={12} md={12} lg={12} className="characterCount" style={{ marginLeft: '0px' }} register={ register }>
                                <span style={{ fontSize: "10px", fontWeight: "normal" }} register={ register }>Remaining characters  -  {1000 - characterCount}</span>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>


            <div className="papercarbonFootprint">
                <span>Carbon Footprint</span>
                <br />
                <br />
                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master,
        project: state.project
    };
}

export default connect(mapStateToProps)(CollapsePaperConsumptionCustom);