import React from "react";
import "./InputTextAreaCustom.css";
import Form from "react-bootstrap/form";

var nStyle = {};
var lStyle = {};

function InputTextAreaCustom(props) {
    let { id, size, type, name, label, rows, style, autoComplete, register, errors, errorStyle, placeholder, labelStyle, formGroupStyle, ...rest } = props;
    size = (size === undefined) ? "small" : size;

    if (errors && errors[name]) {
        nStyle = { ...style, borderBottomColor: "rgb(235, 79, 107)" }
        lStyle = { ...labelStyle, color: "rgb(235, 79, 107)" }
    } else {
        nStyle = style;
        lStyle = labelStyle;
    }

    return (
        <Form.Group controlId={`${id}-group`} style={formGroupStyle}>
            <Form.Label style={lStyle}>{label}</Form.Label>
            <Form.Control as={"textarea"} rows={rows} name={name} autoComplete={autoComplete} ref={register} placeholder={placeholder} {...rest} style={nStyle} />
        </Form.Group>
    )
}

export default InputTextAreaCustom;
