import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './store/ad-auth/authProvider';
import store, { history } from "./store";

const environment = process.env.REACT_APP_API_ENVIRONMENT;

ReactDOM.render(
  <>
    {
      (environment === "production" || environment === "uat") ? (
        <>
          <AzureAD provider={authProvider} reduxStore={store} forceLogin={true} >
            {
              ({ login, logout, authenticationState, error, accountInfo }) => {
                switch (authenticationState) {
                  case AuthenticationState.Authenticated:
                    return (
                      <Provider store={store}>
                        <ConnectedRouter history={history}>
                          <App aadLogoutFn={logout} authProvider={authProvider} />
                        </ConnectedRouter>
                      </Provider>
                    );
                  case AuthenticationState.Unauthenticated:
                    return (
                      <div>
                        <p>
                          <span>You have been logged out!</span>
                        </p>
                      </div>
                    );
                  case AuthenticationState.InProgress:
                    return (<p>Authenticating...</p>);
                  default:
                    return (<p>Loading...</p>);
                }
              }
            }
          </AzureAD>
        </>
      ) : (
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </Provider>
        )
    }
  </>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();