import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import Select from "../select";
import axios from "axios";
import {
    setFilterProjectId,
    setFilterProjectName,
    setFilterActivity,
    setFilterDeliveryLine,
    setFilterSector,
    setFilterCountry,
    setFilterTypeOfEngagement,
    setFilterSalesStage,
    setFilterTestProject,
    setFilterFlagCf,
} from "../../store/actions/filterActions/filterAction";

const initialFilterDetails = {
    projectId: "",
    projectName: "",
    country: "",
    deliveryline: "",
    sector: "",
    salesstage: "",
    typeOfEngagement: "",
    activity: "",
    testProject: "",
    flagCf: "",
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const FilterCustom = (props) => {
    const [masterData, setMasterData] = useState({});
    const { control } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: initialFilterDetails
    });

    const filterDeliveryLine = (inputValue) => {
        return masterData.deliveryLine.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getDeliveryLineOptionsData = (inputValue, callback) => {
        callback(filterDeliveryLine(inputValue));
    }

    const filterSalesStage = (inputValue) => {
        return masterData.salesStage.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getSalesStageOptionsData = (inputValue, callback) => {
        callback(filterSalesStage(inputValue));
    }

    const filterSector = (inputValue) => {
        return masterData.sector.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getSectorOptionsData = (inputValue, callback) => {
        callback(filterSector(inputValue));
    }

    const filterCountry = (inputValue) => {
        return masterData.country.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getCountryOptionsData = (inputValue, callback) => {
        callback(filterCountry(inputValue));
    }

    const filterTypeOfEngagement = (inputValue) => {
        return masterData.typeOfEngagement.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getTypeOfEngagementOptionsData = (inputValue, callback) => {
        callback(filterTypeOfEngagement(inputValue));
    }

    const filterActivity = (inputValue) => {
        return masterData.activity.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getActivityOptionsData = (inputValue, callback) => {
        callback(filterActivity(inputValue));
    }

    const filterTestProject = (inputValue) => {
        return masterData.testProject.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const filterFlagCf = (inputValue) => {
        return masterData.flagCf.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getTestProjectOptionsData = (inputValue, callback) => {
        callback(filterTestProject(inputValue));
    }

    const getFlagCfOptionsData = (inputValue, callback) => {
        callback(filterFlagCf(inputValue));
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.master.status === true) {
                if (Object.keys(masterData).length === 0) {
                    var data = {}
                    data["deliveryLine"] = props.master.deliveryLine;
                    data["salesStage"] = props.master.salesStage;
                    data["testProject"] = props.master.testProjectOptions;
                    data["flagCf"] = props.master.flagCfOptions;
                    data["sector"] = props.master.sector;
                    data["country"] = props.master.country;
                    data["typeOfEngagement"] = props.master.typeOfEngagement;
                    data["activity"] = props.master.activity;
                    setMasterData(data);
                }
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [props.master.status]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            // if (props.login.isValidUser) {
                if (props) {
                const getProjectData = async () => {
                    const res = await axios.get(`${basePath}/analytics/getAllProjects`, {
                        headers: {
                            authorization: `Bearer ${props.login.token}`,
                        },
                    });
                }
                getProjectData();
                if (props.login.role === "admin") {
                    console.log(props,'adminnnnnnnnnnnnn');
                    
                }
            }
        }
        return () => isMounted = false;
    }, [props]);
    // }, [props.login.isValidUser]);

    return (
        <div style={{ height: "440px", overflowY: "scroll", overflowX: "hidden" }}>
            {
                Object.keys(masterData).length > 0 ? (
                    <Row style={{ textAlign: "left" }}>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Primary country of client"} isMulti={true} onChange={(val) => { props.setFilterCountry(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="country"
                                defaultValue={props.filter.country}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Sales Stage"} isMulti={true} onChange={(val) => { props.setFilterSalesStage(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getSalesStageOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="salesStage"
                                defaultValue={props.filter.salesStage}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Sector"} isMulti={true} onChange={(val) => { props.setFilterSector(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getSectorOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="sector"
                                defaultValue={props.filter.sector}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Delivery Line"} isMulti={true} onChange={(val) => { props.setFilterDeliveryLine(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getDeliveryLineOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="deliveryLine"
                                defaultValue={props.filter.deliveryLine}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Type of Engagement"} isMulti={true} onChange={(val) => { props.setFilterTypeOfEngagement(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getTypeOfEngagementOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="typeOfEngagement"
                                defaultValue={props.filter.typeOfEngagement}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Activity"} isMulti={true} onChange={(val) => { props.setFilterActivity(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getActivityOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="activity"
                                defaultValue={props.filter.activity}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={12} >
                            <Controller
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select name={name} label={"Test Project"} isMulti={false} onChange={(val) => { props.setFilterTestProject(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getTestProjectOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                )}
                                control={control}
                                name="testProject"
                                defaultValue={props.filter.testProject}
                            />
                        </Col>
                      
                      {props.login.role === "admin" ?
                            <Col sm={6} md={6} lg={12} >
                                <Controller
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select name={name} label={"Flag Cf"} isMulti={false} onChange={(val) => { props.setFilterFlagCf(val); return onChange(val) }} onBlur={onBlur} value={value} loadOptions={getFlagCfOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ marginRight: "13px", flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" singleValueFontSize={"12px"} />
                                    )}
                                    control={control}
                                    name="flagCf"
                                    defaultValue={props.filter.flagCf}
                                />
                            </Col>
                        : null }
                    </Row>
                ) : (<></>)
            }
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        login : state.login,
        master: state.master,
        filter: state.filter
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFilterProjectId: (data) => {
            dispatch(setFilterProjectId(data));
        },
        setFilterProjectName: (data) => {
            dispatch(setFilterProjectName(data));
        },
        setFilterCountry: (data) => {
            dispatch(setFilterCountry(data));
        },
        setFilterSalesStage: (data) => {
            dispatch(setFilterSalesStage(data));
        },
        setFilterDeliveryLine: (data) => {
            dispatch(setFilterDeliveryLine(data));
        },
        setFilterTypeOfEngagement: (data) => {
            dispatch(setFilterTypeOfEngagement(data));
        },
        setFilterSector: (data) => {
            dispatch(setFilterSector(data));
        },
        setFilterActivity: (data) => {
            dispatch(setFilterActivity(data));
        },
        setFilterTestProject: (data) => {
            dispatch(setFilterTestProject(data));
        },
        setFilterFlagCf: (data) => {
            dispatch(setFilterFlagCf(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCustom);