import React from "react";
import "./CheckButtonCustom.css"

var lStyle = {};

const CheckButtonCustom = (props) => {
  const { name, label, labelStyle, options, handleChange, multiSelect, errors } = props;

  if (errors) {
    lStyle = { ...labelStyle, marginTop: "5px", marginBottom: "6px", color: "rgb(235, 79, 107)" }
  } else {
    lStyle = { ...labelStyle, marginTop: "5px", marginBottom: "6px" };
  }


  return (
    <>
      <p style={lStyle}>{label}</p>
      {
        Object.keys(options).map((item) => {
          return (
            <button
              key={`${name}-${item}-${options[item].name}`}
              className={
                errors ? "btn errorbtn" : options[item].state ? "btn activebtn " : "btn inactivebtn"
              }
              onClick={() => handleChange(multiSelect, options[item].name)}
              name={options[item].name}
            >
              {`${(options[item].name).charAt(0).toUpperCase()}${(options[item].name).slice(1)}`}
            </button>
          );
        })
      }
    </>
  );
}

export default CheckButtonCustom;