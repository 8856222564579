// NOTE: This component is not used after integrating Azure AD Authentication

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/form";
import FormControl from "react-bootstrap/formcontrol";
import InputGroup from "react-bootstrap/inputgroup";
import { connect } from "react-redux";
import { checkUserLogin } from "../../../store/actions/loginActions/loginAction";
import { logout } from "../../../store/actions/loginActions/logoutAction";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Logo from "../../../logo.png";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const schema = yup.object().shape({
    email: yup.string().email("Please enter a valid email address").required("Field is required!")
})

function LoginModal(props) {

    const { register, handleSubmit, errors } = useForm({ mode: "onTouched", shouldUnregister: true, resolver: yupResolver(schema) });

    const [loginError, setLoginError] = useState("");

    const login = (data) => {
        props.checkUserLogin(data);
    }

    const logout = () => {
        props.logout();
    }

    const submitForm = (data, e) => {
        e.preventDefault();
        axios.post(`${basePath}/auth`, { ...data })
            .then(res => {
                if (typeof res === "object") {
                    setLoginError("");
                    handleClose();
                    login(res.data);
                } else if (res.status === 401) {
                    setLoginError("Session Expired");
                    logout();
                } else {
                    setLoginError("Username or password is wrong!");
                }
            })
            .catch(err => {
                setLoginError("Server down! Please try again later");
                logout();
            });
    }

    const handleClose = () => {
        setLoginError("");
        props.onHide();
    }

    return (
        <Modal show={props.show} onHide={handleClose} dialogClassName="modal-90w medium-text" backdrop="static">
            <Modal.Body className="noMargin">
                <button type="button" className="close" onClick={handleClose}>&times;</button>
                <h3 className="centerText">
                    <img width="60" src={Logo} alt="Capgemini" />
                    <br />
                    <span>Sign In</span>
                </h3>
                <hr />
                <hr />
                <Form onSubmit={handleSubmit(submitForm)}>
                    <Form.Row>
                        <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                            Email
                        </Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="fa fa-user" style={{ fontSize: "15px" }}></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="text" id="inlineFormInputGroup" placeholder="Email" name={"email"} autoComplete={"off"} ref={register} />
                        </InputGroup>
                    </Form.Row>
                    <Form.Text className="text-muted error">
                        {errors && errors["email"] && (<i className="fa fa-times-circle" style={{ color: "red" }}> {errors["email"].message}</i>)}
                    </Form.Text>
                    <Form.Row>
                        <p className="error">{loginError}</p>
                    </Form.Row>
                    <hr />
                    <Button variant="primary" className="fullWidth" type="submit">Sign In</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkUserLogin: (data) => {
            dispatch(checkUserLogin(data));
        },
        logout: () => {
            dispatch(logout());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);