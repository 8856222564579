import React, { useEffect } from "react";
import Container from "react-bootstrap/container";
import { Row, Col } from "react-bootstrap";
import VisibilitySensor from 'react-visibility-sensor';
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import "./About.scss";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import aboutimage from '../../assets/images/aboutimage.png';
import aboutImage1 from '../../assets/images/aboutimage1.png';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactPlayer from "react-player";
const sasBlobStorage = process.env.REACT_APP_API_BLOB_STORAGE_SAS_DEV;
const basePath = process.env.REACT_APP_API_BLOB_STORAGE_BASE_PATH;

function About() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container style={{ width: "90%" }}>
            <Row style={{ textAlign: "left", marginTop: "30px", fontFamily: "Ubuntu Light" }}>
                <Col sm={12} md={12} lg={12}>
                    <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={['a', 'b', 'c', 'd']}>
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Capgemini Sustainability Ambitions
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>The greatest environmental impact we can have is through the services we deliver to our clients. We have an ambition to help our clients save 10 million carbon tonnes by 2030.

                                </p>

                                <p>Companies around the world are investing heavily in minimizing their environmental impacts and seeking to be more resource efficient. Increasingly clients are asking us about the carbon impacts of the services we provide, and the potential for technology to help them address their sustainability challenges.</p>

                                <p>As a sustainable business, we want to play a leadership role in ensuring technology creates a sustainable future, particularly enabling our clients to be smarter about their resources in the products and services they create. The ICT sector has the potential to cut 9.7 times more greenhouse gases than it produces and our position as a leading technology services provider working with some of the world’s largest companies puts us in a unique position to leverage all our capabilities, expertise and solutions to help our clients.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Introduction to the Client Carbon Impact Calculator
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>As part of our action on climate change, we have set out a global ambition to work collaboratively with clients to help them save 10 million tonnes of carbon emissions by 2030.

                                    Critical to being able to deliver this aim, is giving our client teams the ability to easily calculate the carbon impact of the projects and services we are providing. To achieve this, today we are launching our Client Carbon Impact Calculator.</p>

                                <p>The calculator provides an adaptable framework to enable the measurement of the carbon impacts across a broad range of sectors and projects. In addition, it also assesses the impacts of our project delivery teams to create an authentic estimate of the overall impact.

                                    Capgemini’s global Environmental Sustainability program in conjunction with the London and Mumbai AIEs have developed and trialled the calculator over the past months in collaboration with our global sales team.</p>

                                <p>To learn more about the Client Carbon Impact Calculator you can view a demo <a href="https://degreed.com/videos/capgemini-client-carbon-impact-calculator?d=30745859&orgsso=capgemini">here.</a></p>

                                <p><img style={{ width: "100%", marginTop: "15px" }} src={aboutimage} alt={"Capgemini"} /></p>
                                <p><img width="100%" src={aboutImage1} alt={"Capgemini"} /></p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid="c">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Using the calculator
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    <AccordionItem uuid="c1">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Dashboard
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The dashboard screen is where you will find an overview of all projects you have created and those shared with you. Each project is displayed in to form of a card which includes high level project information and the estimated carbon savings and delivery impact. The coloured bar indicates the estimated savings by category and hovering you mouse over a specific colour will show the estimated savings for that category.
                                            </p>
                                            <p>
                                                You have options to apply different filters to the project based on the project data entered when setting it up. Simply choose a filter and click ‘Apply’. You can have multiple filters. Once done simply click ‘Clear’ and all projects will be visible again. You also have the option to search for a specific project using the search bar new to the filter drop down.
                                            </p>
                                            <p>
                                                In the top right corner, there is a button called ‘Report’ which will give you the option to download all the data from all project in excel format should you wish to do further calculations or report on the data separately.
                                            </p>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}dashboard.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c2">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                New Project
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>To start a new project, go to the dashboard home screen and click on either ‘Client Carbon Impact’ or ‘Delivery Carbon Impact’. This will open a new page where you will be asked for the following details:  </p>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Project Name</span>
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>THOR ID</span> –  if one has been created, please enter the 8-digit Thor number. This is used for tracking purposes during the project lifecycle.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Project Description</span> – free text box to enter an overview of the project which is used by the Carbon Solutioning Impact Group to understand more about the engagement and for auditing purposes. <i>Please do not enter any confidential or financial information here.</i>
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Start Date</span> –  this is the estimated start month of the project. This can be in the past if the calculation is being retrospectively completed.  
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>End Date</span> – this is the estimated end month of the project.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Lead delivery line</span> – select the delivery line leading on this deal. 
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Sector</span> – select the appropriate sector from the drop-down list.  
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Client Name</span> – if this is for a secure client, please use the assigned code name.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Primary Country of Client</span> – this is the country where the primary carbon savings will be reported. This may be the clients main based or where the project is delivered. This list is searchable.  
                                                    </li>                                                                                                     
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Sales Stage</span> – please select the current sales stage of the project.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Test Project</span> – we encourage users to get comfortable with the calculator by creating test projects. If you are creating a test project please select Yes or if this calculation is for a real project please select No. 
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Type of Engagement</span> <i>(optional)</i> – select the type of engagement the project is most closely aligned too. You can only select one. 
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Activities</span> <i>(optional)</i> – please select the activities most closely aligned to the project. You can select multiple activities if required.  
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Add Calculator</span> –  From the table of calculators at the bottom of the page select the ones you wish to add and enter the information. Please refer to the specific calculator information for additional guidance.   
                                                    </li>
                                                </ul>
                                            </div>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    <ReactPlayer
                                                        url={`${basePath}newProject.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c3">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Fuel
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The fuel calculator requires one of three inputs in order to estimate the carbon savings.
                                            </p>
                                            <p>
                                                These are:
                                            </p>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Fuel Consumption</span> – what is the estimated fuel savings from this project
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Distance</span> – what is the estimated reduction in distance travelled because of the project
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Fuel Cost</span> – what is the estimated cost reduction from this project
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                To indicate an increase in consumption please enter a negative value.
                                            </p>
                                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                                <AccordionItem uuid="c31">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Fuel Consumption
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            Select the type of fuel you would like to calculate the savings for. Multiple fuel types can be selected. If you choose electric please note that it will use the electricity reference data based on the ‘primary country of client’ that was selected when setting up the project. The input units can be chased using the units drop down menu.
                                                        </p>
                                                        <p>
                                                            Once all data have been entered press the save button on the fuel calculator. You also have the option to delete the calculator should you wish. If you want to change the way you calculate the fuel you first have to delete the current calculator and add it again.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem uuid="c32">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Distance
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            Select the type of fuel you would like to calculate the savings for. Multiple fuel types can be selected. If you choose electric, please note that it will use the electricity reference data based on the ‘primary country of client’ that was selected when setting up the project.
                                                        </p>
                                                        <p>
                                                            For each type of fuel, you need to select the type of vehicle for which the distance will be reduced. If you selected an HGV you will also need to enter how laden the trucks are on average.
                                                        </p>
                                                        <p>
                                                            Enter the estimated distance saved, using the drop down to select the units you prefer.
                                                        </p>
                                                        <p>
                                                            Once all data have been entered press the save button on the fuel calculator. You also have the option to delete the calculator should you wish. If you want to change the way you calculate the fuel you first have to delete the current calculator and add it again.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem uuid="c33">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Fuel Cost
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            Select the type of fuel you would like to calculate the savings for. Multiple fuel types can be selected.
                                                        </p>
                                                        <p>
                                                            For the select type of fuel enter the expected savings in Euros. You are able to update the price of fuel to reflect the price the client pays. All values must be entered in Euros.
                                                        </p>
                                                        <p>
                                                            Once all data have been entered press the save button on the fuel calculator. You also have the option to delete the calculator should you wish. If you want to change the way you calculate the fuel you first have to delete the current calculator and add it again.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}fuel.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c4">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Electricity
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The electricity calculator requires one of two inputs in order to estimate the carbon savings.
                                            </p>
                                            <p>
                                                These are:
                                            </p>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Electricity Consumption</span> – what is the estimated electricity savings from this project
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Electricity Cost</span> – what is the estimated cost reduction from this project
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                To indicate an increase in consumption please enter a negative value.
                                            </p>
                                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                                <AccordionItem uuid="c41">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Electricity Consumption
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            Using the drop-down list selected the country where the saving will be achieved. You are able to select multiple countries. Only countries for where we have reference data can be selected. There is also an ‘Other’ option should the country you are looking for not available. If using ‘Other’ please remember the estimate will be less accurate.
                                                        </p>
                                                        <p>
                                                            For each country selected enter the estimated electricity savings in kWh.
                                                        </p>
                                                        <p>
                                                            Once all data have been entered press the save button on the fuel calculator. You also have the option to delete the calculator should you wish. If you want to change the way you calculate the electricity you first have to delete the current calculator and add it again.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem uuid="c42">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Electricity Cost
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>
                                                            Using the drop-down list selected the country where the saving will be achieved. You are able to select multiple countries. Only countries for where we have reference data can be selected. There is also an ‘Other’ option should the country you are looking for not available. If using ‘Other’ please remember the estimate will be less accurate.
                                                        </p>
                                                        <p>
                                                            For each country selected enter the estimated electricity savings in kWh. You are able to update the price of electricity to reflect what the client pays. Please note that this should be entered in Euros.
                                                        </p>
                                                        <p>
                                                            Once all data have been entered press the save button on the electricity calculator. You also have the option to delete the calculator should you wish. If you want to change the way you calculate the electricity you first have to delete the current calculator and add it again.
                                                        </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}electricity.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c5">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Water
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Using the drop-down list selected the country where the saving will be achieved. You are able to select multiple countries. Only countries for where we have reference data can be selected. There is also an ‘Other’ option should the country you are looking for not available. If using ‘Other’ please remember the estimate will be less accurate.
                                            </p>
                                            <p>
                                                Enter the estimated volume of water saved, using the drop down to select the units you prefer.
                                            </p>
                                            <p>
                                                Once all data have been entered press the save button on the water calculator. You also have the option to delete the calculator should you wish.
                                            </p>
                                            <p>
                                                To indicate an increase in consumption please enter a negative value.
                                            </p>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}water.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c6">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Data Centre
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                This calculator is used to estimate the carbon saving from a migration project.
                                            </p>
                                            <p>
                                                Using the drop-down list selected the country where the saving will be achieved. You are able to select multiple countries. Only countries for where we have reference data can be selected. There is also an ‘Other’ option should the country you are looking for not available. If using ‘Other’ please remember the estimate will be less accurate.
                                            </p>
                                            <p>
                                                Enter the number of physical and virtual machines before the migration. If there is no change enter the same value in both columns. There is a running total of servers below.
                                            </p>
                                            <p>
                                                Next enter the breakdown of servers by size. The value must add up to 100%, for the calculator to work. If not, a red line will indicate the value that needs to be updated.
                                            </p>
                                            <p>
                                                The next. Step is to enter the typical server utilisation before and after the migration.
                                            </p>
                                            <p>
                                                The last step is to enter the PUE value. If you are using multiple cloud providers with different PUE’s please use an average between them for this calculation.
                                            </p>
                                            <p>
                                                Once all data have been entered press the save button on the data centre calculator. You also have the option to delete the calculator should you wish.
                                            </p>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}dataCentre.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c7">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Paper
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                           <p>To complete the paper calculator, you will need to know the yearly paper consumption for the organisation you are calculating the saving for.  You can change the input unit by using the drop down (tonnes, kg or sheets).  </p>
                                           <p>Select the source material and waste disposal of the paper using the two drop downs. If you are unsure select ‘primary material product’ and ‘land fill’ for the worst-case scenario.</p>
                                           <p>You can select the emissions associated by postage by using the drop down (Yes or No). This is based on the emissions for an average letter in the UK.  </p>                                            
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c8">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Plastic
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                           <p>To calculate the estimated carbon savings from plastic, select one or multiple of the plastic types. For each type of plastics enter to estimated amount of plastic this project will save the clients. After entering the value, you can change the input units (tonnes or kg). </p>
                                           <p>Select the source material and waste disposal of the plastic using the two drop downs. If you are unsure select ‘primary material product’ and ‘land fill’ for the worst-case scenario. </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c9">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Simple Delivery Impact
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Unlike the other calculators that are intended to calculate savings, the deliver calculator indicates the carbon impact of actually delivering the project (increased carbon). This calculator uses the duration entered on the project datils page.
                                            </p>
                                            <p>
                                                Using the drop-down list selected the country where the saving will be achieved. You are able to select multiple countries. Only countries for where we have reference data can be selected. There is also an ‘Other’ option should the country you are looking for not available. If using ‘Other’ please remember the estimate will be less accurate.
                                            </p>
                                            <p>
                                                For each country indicate the number of people who will be working on the project (FTE’s) and on average how many days they will be working from home (0 always on site, 5 fulltime at home).
                                            </p>
                                            <p>
                                                This calculator considers five aspects in calculating the estimated impact. These are:
                                            </p>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Travel</span> – uses the average travel data per country. If the team works from home 5 days a week this value will be 0
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Base Office Support</span> – emissions associated with office facilities and support functions
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Commuting</span> – emission data based on the Capgemini travel survey. Emissions assigned based on the working from home pattern
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Working from Home</span> – based on the number of days a person works from home a standard emissions factor that has been calculated based on a UK household that works 8 hours a day is assigned. Currently the UK emissions factor is assigned to all countries while a more accurate emissions factor per country is developed
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Data Centre Usage</span> – carbon emissions assigned based on an individual’s average compute use within the Capgemini data centres
                                                    </li>
                                                </ul>
                                            </div>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}delivery.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c10">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Delivery Project Impact
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The delivery project impact calculator enables you to calculate the initial, target and actual carbon emissions of any engagement. It builds on the simple delivery calculator by going into more details around travel patterns, work location, commitments around digital and waste reduction and food consumption.
                                            </p>
                                            <p>
                                                The first step is to select the countries for which the delivery is taking place. You will then be asked to inter the initial data for each country. For the initial tab certain assumptions around work locations and savings commitments are set. Before you move to the target or actual tabs you are required to complete certain field.
                                            </p>
                                            <p>
                                                If you are calculating the carbon impact for multiple countries the total show will be the total of the highest common stage. For example, of country A and B has data for initial and target while country C has data for initial, target and actuals, the total for country A, B and C will be the total of the target calculations.
                                            </p>
                                            <p>
                                                The following categories are considered in the delivery project impact calculator:
                                            </p>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Location</span> – Working from home or from the office (Capgemini or client). The office data is based on average Capgemini office emissions per person per country.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Transport </span> – This section is broken down into 3 categories, long distance (plane, high speed train [TGV] and average train), short distance travel (taxi) and daily commuting. For each section you will be asked to enter the data in hours either for the total project or per day. If you however over any section it will display a pop up with any assumptions made.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Digital</span> – Considers the impact of email and SharePoint usage. This calculation uses an average from Capgemini France.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Food</span> – Eating meat has a greater environmental impact than vegetarian and although we are not looking to dictate what people eat, committing as a team to eat vegetarian a couple of day a week will greatly impact the carbon footprint of a project. Calculations are made based on an average meat and vegetarian meal and the exact impact will differ based on the exact meal and the region.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Waste</span> – Small improvement such as re-using water bottle or printing less helps to reduce the impact of a project. This calculation has made a number of assumption around how much waste an individual creates per day.
                                                    </li>
                                                    <li>
                                                        <span style={{ fontWeight: "bold" }}>Hotel</span> – To calculate the emissions per hotel night the calculator uses country specific data from DEFRA.
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                Once you have entered the data press save and you will be able to download a full report using the ‘download report’ on the right-hand side. Included in the report are several graphs to show differences in the emissions between the different project stages (initial, target, actual). Please not that if you have only entered the initial data no graphs will be visible.
                                            </p>
                                            <VisibilitySensor>
                                                {({ isVisible }) =>
                                                    < ReactPlayer
                                                        url={`${basePath}detailedDelivery.mp4?${sasBlobStorage}`}
                                                        config={{ file: { attributes: { controlsList: 'nodownload', fullscreen: 0 } } }}
                                                        onContextMenu={e => e.preventDefault()}
                                                        playing={isVisible}
                                                        controls
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                }
                                            </VisibilitySensor>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem uuid="c11">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Project Page
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                The project page is broken down into four main parts. At the top is the project information which can be edited on this page. Once any edits have been made you have to use the save button in the top right corner. There you will also find a delete button, which will delete the full project with all the calculators, and a share option, which will allow you to collaborate with other. To add a collaborator, enter the persons email and click add user. Under the share pop up window, you can remove a user’s access by clicking the bin icon. You are only able to add users within Capgemini.
                                            </p>
                                            <p>
                                                The second portion of the page is the calculators which have been described in detail in the above sections. Please remember to click save after you have finished entering any data for it to register.
                                            </p>
                                            <p>
                                                The third section is at the bottom where you have an option to add new calculators. In this section you can see all available calculators within the tool. Currently you are only able to add one calculator of each type.
                                            </p>
                                            <p>
                                                The forth section is located on the right hand side of the page. This is where you can view a summary of all calculations by type and the total carbon figure. The total figure is calculated for 3 years as it is generally agreed that we can only recognise savings for the amount of time. If you require a different time period you can use the yearly output and multiply it by the desired duration.
                                            </p>
                                            <p>In this forth section you will also find options to ‘Download Report’, ‘Save Report’ and ‘Show PDF Revisions’.  By downloading the PDF you will get a summary of all the data entered in the calculator including project information, summary of emissions and savings and a detailed breakdown per calculator, including any assumptions.  </p>
                                            <p>You can save the PDF report to the tool, as well as saving a local copy. By saving it to the tool you will be able to view the report later and track changes to your calculation over time. The view PDF revisions bring up a list of all saved PDF reports and allow you to view previous revisions.   </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>


                        <AccordionItem uuid="d">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Carbon Calculator FAQ
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    <AccordionItem uuid="d1">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                About the tool
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p><b>What is the Carbon Calculator?</b><br></br>The Client Carbon Impact Calculator, or Carbon Calculator for short, is a group tool developed and managed by the Capgemini Sustainably Team. It enables anyone in the business to calculate the carbon impact of any engagement. This includes the negative impact of Capgemini employees working on a project and potential carbon savings we can help our clients achieve because of our projects.  </p>

                                            <p><b>When should it be used?</b><br></br>The tool is intended to be used during the sales stage of any new deal although it can also be used retrospectively if required.  </p>

                                            <p><b>Who should use it?</b><br></br>The tool can be accessed and used by anyone with a Capgemini email via SSO. The primary user group includes anyone working on a proposal.  </p>

                                            <p><b>Who owns the tool?</b><br></br>The tool was developed and is managed by the AIE & AMS teams in India and the UK and is owned by the group sustainability team under the leadership of James Robey.  </p>

                                            <p><b>Is this a product we can sell?</b><br></br>The Carbon Calculator has been built as an internal tool for Capgemini and not as a product to be sold to clients. However, should your client express interest in a similar tool, and many have, please speak to your local sustainably contract for more information about how to approach this topic.  </p>

                                            <p><b>Where does the methodology come from?</b><br></br>The Carbon Calculator uses methodologies developed by DEFRA, a UK government agency, and complies to agreed guidelines for carbon calculations.  </p>

                                            <p><b>Can we guarantee these numbers?</b><br></br>No. All of the numbers in the calculator are estimates and should be communicated as such per the legal text available. This text can be found on the legal tab or on the downloaded report. The calculator uses several assumptions and is only as accurate as the data entered. For any questions on this please contact your local sustainability point of contact.  </p>



                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>


                                <AccordionItem uuid="d2">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Carbon Calculator Calculations
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p><b>Why am I required to enter project data to use the calculator?</b><br></br>The project data is used for reporting and audit purposed by Capgemini sustainably teams.</p>

                                        <p><b>How to Create a Carbon Calculation?</b><br></br>To create a carbon calculation, select ‘Client Carbon Impact’ on the home page. This will start a new calculator where you will be asked to enter some project data. Once entered press save and select the type of calculation from the list of available calculators.</p>

                                        <p><b>Which calculator should I use?</b><br></br>The calculator currently has six different calculators available, with new ones expected to be added in future releases. Which calculator to use will depend on the needs of your project  but as a guide, the ‘Simple Delivery Impact’ & and ‘Detailed Project Impact’ calculators are relevant to all projects. These two calculators consider the impact of Capgemini employees delivering the work.</p>

                                        <p>The other calculators (fuel, electricity, water, data centre) should only be used to calculate any carbon impact on our client because of Capgemini delivering a project. An example would be Capgemini delivering a route optimisation software for a transport company. In this case the fuel calculator would be used to work out any potential savings the project will deliver for the client.</p>

                                        <p><b>I have filled in either the ‘Simple Delivery Impact’ or Detailed Project Impact’ calculator, do I need to fill in anything else?</b><br></br>In most cases the answer will be no. You only need to fill in the other calculators if there is a carbon impact to the client based on the work we deliver. If there is no fuel, electricity, water or data centre charge for the client you do not need to fill anything else in. Energy used to deliver the project such as fuel to drive to the office is already captured in the delivery calculator.</p>

                                        <p><b>What is the difference between the ‘Simple Delivery Impact’ and ‘Detailed Project Impact’ calculators?</b><br></br>Both look at the impact of Capgemini delivering a project but go into different amounts of detail. It is recommended that you use the ‘Detailed Project Impact’ calculator when possible as it provides more granular information to allow the project take actions to reduce the deliver impact.</p>

                                        <p><b>Where do I get the data from for the fuel/electricity/water calculator?</b><br></br>This data can come from several sources including those set out by the client in RFI/FRP document, scope documents, during requirement gathering session or from speaking directly to clients or SMEs who have delivered similar projects before. Not every client will have considered this or have this information available. In these instances, asking the question has the potential to open new conversations and demonstrate Capgemini’s commitment to working with our clients to reduce carbon.</p>

                                        <p><b>Do I enter positive or negative figures?</b><br></br>You can enter both. A positive indicates a saving. For example, if a company used 100L of fuel before and because of the project outcome estimate they will use 60L in the future, the saving would be 40L.  There is occasion where the project might have a negative impact, for example increase fuel use, in which case you can enter a negative number. </p>

                                        <p><b>I can’t save my calculation, what should I do?</b><br></br>Firstly, check if you have saved the project details. There is a save at the top of the page. If the project details are saved you will see your name. Once that is done, please try saving your calculation again. If this still does not work please contact the support team.  </p>




                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="d3">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Detailed Project Impact
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p><b>What do the three tabs (initial, target, actual) mean?</b><br></br>The initial tab is the starting point for any project and assumes we make no effort to reduce our emissions. In a pre-pandemic world this would mean minimal or no hybrid working with consultants traveling to client offices on a regular basis. Even though travel is unlikely to return to pre-pandemic quickly, there is an increased expectation from clients to travel more which be reflect in this tab.</p>

                                        <p>The target tab is used to identify potential carbon savings based on the proposed project delivery. In this tab users can test different delivery options, including different modes of transport to reduce carbon emissions. The comparison between initial and target has in many cases been presented to clients as part of a proposal. This results in a positive conversation around reducing carbon emission from project delivery.</p>

                                        <p>The actual tab should be used at the end of a project to reflect what actually happened.</p>

                                        <p><b>Where does that date for client/Capgemini office and working from home come from?</b><br></br>Office data is country specific and is based on the average emissions from all offices within a given country. Working from home is based on an average value for a person in the UK working from home. This figure is constantly being reviewed and should only be seen as an estimate, as home conditions differ.</p>

                                        <p><b>How do I work out the hours of flights/train?</b><br></br>Consider what routes the team is likely to travel during the project and how often. For example, a one-way flight from New York to San Francisco take roughly 6.5 hours, so if two members of the project teams needs to make this trip 5 times during the project the total hours of flight time would be 130 hours. Remember this is an estimate based on your current knowledge of the project.</p>

                                        <p><b>What is a fast train?</b><br></br>Examples of fast trains would be the TGV in France, ICE in Germany or the Bullet Train in Japan. If you are unsure, please use ‘average train’.</p>

                                        <p><b>Do I need to enter anything under public transport or individual car?</b><br></br>Yes, currently the tool requires you to enter something here even if your project is fully removed. Please enter the lowers value possible, 0.1. Work is being done to update this for future release.</p>

                                        <p><b>What do the red lines mean?</b><br></br>Red lines mean some information is missing. For example, the percentages might not add up to 100%. Please either enter a value or adjust the others to correct this.</p>

                                        <p><b>What is soft transport?</b><br></br>Soft transport is any transport that does not use an engine such as walking, cycling, or even running to work.</p>

                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="d4">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Data Outputs
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p><b>Why is my value negative?</b><br></br>A negative value indicates that the project has no saving to the client. The most common example of this is if you only fill in the delivery calculator (this will be most projects). In this case our teams will produce carbon as part of the delivery, hence the negative value.  </p>

                                        <p>A negative value should not be seen a bad thing, but a recognition that there is an impact of us deliver work. The important aspect is to consider what we can do to reduce this impact.</p>

                                        <p>If the project also helps the client save carbon, for example from a cloud migration, the ‘total carbon saving’ line is the difference between the positive impact of the cloud migration against the negative impact of our teams delivering the work.</p>

                                        <p><b>Why are any savings considered for 3 years?</b><br></br>Capgemini recognise any carbon savings we help our clients achieve for 3 years. Any longer than that it is likely further improvements have been implemented. For any question around this please contact your local sustainability team. </p>

                                        <p><b>What does the carbon figure mean and what do I do now?</b><br></br>Every project depending on its scope and size will either have a negative or positive carbon statement. To most people this figure means very little so to help put it into perspective here are a few examples:

                                            <br></br>A return flight London to Boston emits about one tone of carbon per passenger

                                            <br></br>An average car in the US emits about 5 tonnes or carbon per year

                                            <br></br>The average emissions of a person living in Belgium is eight tonnes per year compared to 13 in the UK

                                            <br></br>To capture one tonne of carbon 50 trees must grow for one year

                                            <br></br>One tonne of carbon is the same as 125m3 of cola</p>

                                        <p>Having calculated the impact it is important to consider what can be done to reduce the emissions. This could be a conversation with our clients about reducing the number of days in their office or encouraging people to take the train instead of flying (when viable). If delivering a cloud migration this could involve speaking to the CTO about low carbon hosting, exploring low code option or auto shut down of system when not in use. </p>

                                        <p>There is not one answer to what to do with the data and no one is expected to be a sustainably expert. The important first step is that we calculate it and stop to consider what we can do differently. Within the Capgemini Group we have sustainably experts who will be happy to help.</p>



                                    </AccordionItemPanel>
                                </AccordionItem>





                                <AccordionItem uuid="d5">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Need Support
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p><b>Where can I find more information?</b><br></br>You can find more information about each calculator, including a video example, on the about page. You can also reach out to your local sustainability team for more support.</p>

                                        <p><b>I want to calculate the savings for something else not available in the calculator, what should it do?</b><br></br>The tool is constantly being improved based on your feedback and needs. Please reach out to your local sustainably team who can help you with other calculations and include your requirements into future release.  </p>


                                    </AccordionItemPanel>
                                </AccordionItem>

                            </AccordionItemPanel>
                        </AccordionItem>




                        <AccordionItem uuid="e">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Need help?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {/* <p>
                                    If you have any questions about the calculator, how it works and how the best use these numbers with your clients, please contact Matt Bradley (<span><a href="mailto:matthew.bradley@capgemini.com" style={{ color: "inherit" }}>matthew.bradley@capgemini.com</a></span>)
                                </p>
                                <p>
                                    For any technical questions please contact Johan Lonn (<span><a href="mailto:johan.lonn@capgemini.com" style={{ color: "inherit" }}>johan.lonn@capgemini.com</a></span>)
                                </p> */}
                                <p>
                                    For more information please visit the <a href="https://x-port.capgemini.com/carbon-impact-solution-group-cisg/" target="_blank" style={{ fontSize: "13px", color: "#FFFFFF"}}><u>Carbon Impact Solution Group</u></a> page. If you require support with calculations please use the <a href="https://forms.office.com/pages/responsepage.aspx?id=Wq6idgCfa0-V7V0z13xNYTCeeL7jB-FMrulVSv7B4GxUQ0I1UlI5WDlETUtKS0xTOUNOTE5VNVlFQyQlQCN0PWcu" target="_blank" style={{ fontSize: "13px", color: "#ffffff" }}><u>request from</u></a> and a member of the team will be in contact. Should you have any feedback please email (<span><a href="mailto: carbonimpactsolutiongroup.global@capgemini.com" style={{ color: "#ffffff"}} ><u>carbonimpactsolutiongroup.global@capgemini.com</u></a></span>).
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>


                    </Accordion>


                </Col>
            </Row>
        </Container>
    )
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, About)
