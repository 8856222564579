import { createStore, combineReducers, applyMiddleware, compose } from "redux";
// import logger from "redux-logger";
import loginReducer from "./reducers/loginReducer";
import masterReducer from "./reducers/masterReducer";
import projectReducer from "./reducers/projectReducer";
import filterReducer from "./reducers/filterReducer";
import sortReducer from "./reducers/sortReducer";
import aadReducer from "./reducers/aadReducer";
import * as historyFunc from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from "redux-state-sync";

export const history = historyFunc.createBrowserHistory();

const rMiddleware = routerMiddleware(history);

const config = {
    whitelist: ['CHECK_CREDENTIALS', 'LOGOUT'],
    broadcastChannelOption: { type: 'localstorage' },
};

function saveToSessionStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("state", serializedState);
    } catch (error) {
        // console.log(error);
    }
}

function loadFromSessionStorage() {
    try {
        const serializedState = sessionStorage.getItem("state");
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (error) {
        // console.log(error);
        return undefined;
    }
}

const persistedState = loadFromSessionStorage();

const store = createStore(
    withReduxStateSync(combineReducers({
        router: connectRouter(history),
        login: loginReducer,
        master: masterReducer,
        project: projectReducer,
        filter: filterReducer,
        sort: sortReducer,
        aad: aadReducer
    })),
    persistedState,
    compose(applyMiddleware(rMiddleware, thunk, createStateSyncMiddleware(config)))
);
 //compose(applyMiddleware(rMiddleware, thunk, createStateSyncMiddleware(config), logger)) // Use this when in development to view logs of redux states and routes

initStateWithPrevTab(store);

store.subscribe(() => saveToSessionStorage(store.getState()));

export default store;
