import React, { useState, useReducer, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./PlasticTabs.css";
import CollapsePlasticConsumptionCustom from "./CollapsePlasticConsumptionCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import { deleteConfirm } from "../deleteConfirm";
import Assumption from "./plasticAssumption";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const INITIAL_COLLAPSE_STATE = {
    "AveragePlastics": { parent: "", cf: {}, status: false, title: "Average Plastics" },
    "AveragePlasticFilm": { parent: "", cf: {}, status: false, title: "Average Plastic Film" },
    "AveragePlasticRigid": { parent: "", cf: {}, status: false, title: "Average Plastic Rigid" },
    "PET": { parent: "", cf: {}, status: false, title: "PET" },
    "PVC": { parent: "", cf: {}, status: false, title: "PVC" }
};

const unitOptions = {
    tonnes: { value: "tonnes", label: "tonnes" },
    kg: { value: "kg", label: "kg" }

};

const materialOptions = {
    PrimaryMaterialProduct : { value: "Primary Material Product", label: "Primary Material Product" },
    ClosedLoopSource : { value: "Closed-loop Source", label: "Closed-loop Source" },
};

const disposalOptions = {
    Landfill: { value: "Landfill", label: "Landfill" },
    ClosedLoop: { value: "Closed-loop", label: "Closed-loop" },
};

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();

const PlasticTabs = (props) => {
    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, INITIAL_COLLAPSE_STATE);
    //const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const form = useForm();
    const { register, errors, getValues, setValue, control, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [PlasticSourceType, setPlasticSourceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [clickedSave, setClickedSave] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 2)];
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                let itemTitle = INITIAL_COLLAPSE_STATE[item].title;
                if (collapseState[item].parent === "plasticConsumption") {
                    data["data"] = { ...data["data"], [itemTitle]: { status: collapseState[item].status, dataSource: data["dataSource"], amount: collapseState[item].cf.volume, unit: collapseState[item].cf.unit, carbonFootprint: collapseState[item].cf.value/1000, material: collapseState[item].cf.material, disposal: collapseState[item].cf.disposal } }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {    
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                    if (dataArray[item]["dataSource"] === "plastic") {
                            //  let itemTitle = INITIAL_COLLAPSE_STATE[item].title;
                            if (dataArray[item]["amount"] > 0) {
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "amount": dataArray[item]["amount"] === undefined || dataArray[item]["amount"] === "" ? null : parseFloat(dataArray[item]["amount"]),
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]),
                                            "projectId": projectId,
                                            "plasticMaterialUse": {
                                                "name": dataArray[item]["material"].value
                                            },
                                            "plasticWasteDisposal": {
                                                "name": dataArray[item]["disposal"].value
                                            },
                                            "plasticUnitMeasurment": {
                                                "name": dataArray[item]["unit"].value
                                            },
                                            "plasticSourceType": {
                                                "name": item
                                            }
                                        }]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                return false;
                            }

                        }
                        axios.post(`${basePath}/calculator/plastic`, jsonData, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`
                            }
                        })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "Plastic calculator data saved!" });
                                assumptionSaveData();
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                    });
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please select a plastic type and fill in the details!" });
                }
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const assumptionSaveData = () => {
        let passumption = getValues(`${key}-assumptions`)
        if (passumption === "" || passumption.length > 0) {
            let plasticAssumpData = {
                "projectId": projectId,
                "data": [{
                    "projectId": projectId,
                    "assumptions": passumption
                }]
            }
            axios.post(`${basePath}/calculator/plasticAssumption`, plasticAssumpData, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    sTime = new Date();
                    setIsDataPresent(true);
                    setSavedTime(sTime);
                    props.calculateSummary();
                    // props.toastMessage({ state: true, header: "Success", body: "Assumption Plastic calculator data saved!" });
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                });
        }
    }


    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/plastic/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Plastic calculator cannot be deleted! Server Error!" });
                    });
                axios.delete(`${basePath}/calculator/plasticAssumption/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Plastic Assumption cannot be deleted! Server Error!" });
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }
    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {}
                if (Object.keys(PlasticSourceType).length === 0) {
                    for (const fst of props.master.plasticSourceType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setPlasticSourceType(data);
                }
            }
            if (Object.keys(sources).length === 0) {
                for (const ffm of Object.keys(props.master.plasticMaterialUseMaster)) {
                    if (!years.includes(props.master.plasticMaterialUseMaster[ffm].year)) {
                        years.push(props.master.plasticMaterialUseMaster[ffm].year);
                    }
                    if (sources[props.master.plasticMaterialUseMaster[ffm].year] === undefined) {
                        sources[props.master.plasticMaterialUseMaster[ffm].year] = { source: [] };
                    }
                    if (!sources[props.master.plasticMaterialUseMaster[ffm].year].source.includes(props.master.plasticMaterialUseMaster[ffm].source)) {
                        sources[props.master.plasticMaterialUseMaster[ffm].year].source.push(props.master.plasticMaterialUseMaster[ffm].source);
                        sources[props.master.plasticMaterialUseMaster[ffm].year].source.sort();
                    }
                }
                years = years.sort((a, b) => a - b);
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].plasticSourceType.name === "Average Plastics") {
                    data["dataSource"] = "plasticConsumption";
                    setKey(`${componentId}-plasticConsumption`);
                } else if (props.data.data[0].plasticSourceType.name === "Average Plastic Film") {
                    data["dataSource"] = "plasticConsumption";
                    setKey(`${componentId}-plasticConsumption`);
                } else if (props.data.data[0].plasticSourceType.name === "Average Plastic Rigid") {
                    data["dataSource"] = "plasticConsumption";
                    setKey(`${componentId}-plasticConsumption`);
                } else if (props.data.data[0].plasticSourceType.name === "PET") {
                    data["dataSource"] = "plasticConsumption";
                    setKey(`${componentId}-plasticConsumption`);
                } else if (props.data.data[0].plasticSourceType.name === "PVC") {
                    data["dataSource"] = "plasticConsumption";
                    setKey(`${componentId}-plasticConsumption`);
                }
            } else {
                setKey(`${componentId}-plasticConsumption`);
                setIsDataPresent(false);
            }
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                if (props.data.dataAssumption) {

                    data["dataSource"] = "plasticConsumption";
                    setKey(`${componentId}-plasticConsumption`);
                }

            } else {
                setKey(`${componentId}-plasticConsumption`);
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total / 1000).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
                setRefresh(prev => ++prev);
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var st = "";
                    props.data.data.forEach(obj => {
                        var pSourceType = '';//obj.plasticSourceType.name;
                        switch (obj.plasticSourceType.name) {
                            case "Average Plastics":
                                pSourceType = "AveragePlastics";
                                break;
                            case "Average Plastic Film":
                                pSourceType = "AveragePlasticFilm";
                                break;
                            case "Average Plastic Rigid":
                                pSourceType = "AveragePlasticRigid";
                                break;
                            case "PET":
                                pSourceType = "PET";
                                break;
                            case "PVC":
                                pSourceType = "PVC";
                                break;
                            default:
                                pSourceType = "";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: pSourceType, parent: 'plasticConsumption', status: true }})
                        // if (pSourceType === "plasticConsumption") {
                                setValue(`${componentId}-plasticConsumption-${pSourceType}-amount`, obj.amount );
                                setValue(`${componentId}-plasticConsumption-${pSourceType}-volume-select`, { value: unitOptions[obj.plasticUnitMeasurment.name].value, label: unitOptions[obj.plasticUnitMeasurment.name].label });

                                if(obj.plasticMaterialUse.name.split(' ').join('').split('-').join('') === 'ClosedloopSource'){
                                    setValue(`${componentId}-plasticConsumption-${pSourceType}-materialOptions-select`, { value: materialOptions['ClosedLoopSource'].value, label: materialOptions['ClosedLoopSource'].label });
                                } else {
                                    setValue(`${componentId}-plasticConsumption-${pSourceType}-materialOptions-select`, { value: materialOptions[obj.plasticMaterialUse.name.split(' ').join('')].value, label: materialOptions[obj.plasticMaterialUse.name.split(' ').join('')].label });
                                }
                                if(obj.plasticWasteDisposal.name.split(' ').join('').split('-').join('') === 'Closedloop'){
                                    setValue(`${componentId}-plasticConsumption-${pSourceType}-disposalOptions-select`, { value: disposalOptions['ClosedLoop'].value, label: disposalOptions['ClosedLoop'].label });
                                } else {
                                    setValue(`${componentId}-plasticConsumption-${pSourceType}-disposalOptions-select`, { value: disposalOptions[obj.plasticWasteDisposal.name].value, label: disposalOptions[obj.plasticWasteDisposal.name].label });
                                }
                        //   }

                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
                if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                    data["dataAssumption"] = {};
                    var st = "";
                    props.data.dataAssumption.forEach(obj => {
                        //  dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { status: true }})
                        setValue(`${componentId}-plasticConsumption-assumptions`, obj.assumptions);
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [key, isMountedRef]);
    // useEffect(() => {
    //             if (props.data.dataAssumption.length) {
    //                 data["data"] = {};
    //                 var st = "";
    //                 props.data.dataAssumption.forEach(obj => {
    //                          dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { status: true }})

    //                             setValue(`${componentId}-plasticConsumption-assumptions`, obj.assumptions );                         

    //                     st = obj.createdAt;
    //                 });
    //                 setSavedTime(st);
    //                 setIsDataPresent(true);
    //                 setShouldRender(true);
    //             }
    // },[isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);
    let collapseAssumption;
    if (isMountedRef.current) {
        if (setPlasticSourceType.length > 0) {
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].status === true) {
                    collapseAssumption = <Assumption
                        key={`${componentId}-plasticConsumption-collapse`}
                        collapseControll={getLogic(item, "plasticConsumption")}
                        label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                        id={`${componentId}-plasticConsumption`}
                        inputName={item}
                        textValue={getValues(`${componentId}-plasticConsumption-assumptions`)}
                        selectName={item}
                        control={control}
                        register={register}
                        errors={errors}
                    />
                }
            })
        }
    }


    const plasticSourceTabs = {
        [`${componentId}-plasticConsumption`]: (
            <Tab eventKey={`${componentId}-plasticConsumption`} title="Plastic Consumption" disabled={key !== "" && key !== `${componentId}-plasticConsumption`} key={`${componentId}-plasticConsumption`} >
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" id={`${componentId}-plasticPills`} className="flex-row plasticPills">
                                {
                                    Object.keys(collapseState).map(item => {
                                        return (
                                            <Nav.Item key={`${componentId}-plasticConsumption-${item}-collapsePills`}>
                                                <Nav.Link eventKey={item} active={collapseState[item].parent === "plasticConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "plasticConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-plasticConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "plasticConsumption", status: !collapseState[item].status } })}>{item === "cng" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <div className="plasticPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapsePlasticConsumptionCustom
                                            key={`${componentId}-plasticConsumption-${item}-collapse`}
                                            collapseControll={getLogic(item, "plasticConsumption")}
                                            id={`${componentId}-plasticConsumption`}
                                            inputName={item}
                                            inputValue={getValues(`${componentId}-plasticConsumption-${item}-amount`)}
                                            inputLabel={item === "pvc" ? "PVC Amount" : `${item.charAt(0).toUpperCase()}${item.slice(1)} Amount`}
                                            inputPlaceholder={"0.0"}
                                            selectName={item}
                                            selectLabel={"Units"}
                                            selectUnitValue={getValues(`${componentId}-plasticConsumption-${item}-volume-select`)}
                                            selectMaterialValue={getValues(`${componentId}-plasticConsumption-${item}-materialOptions-select`)}
                                            selectDisposalValue={getValues(`${componentId}-plasticConsumption-${item}-disposalOptions-select`)}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        ),
    }

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#40A0B5", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "10px" }}>
                                    <span>Plastic</span>
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Plastic calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <p style={{ paddingLeft: "10px", textAlign: "left" }}>Please select one of the following data sources you have available </p>
                            <Row>
                                <Col sm={12}>
                                    <Nav variant="pills" id={`${componentId}-plasticPills`} className="flex-row plasticPills">
                                        {
                                            Object.keys(collapseState).map(item => {
                                                return (
                                                    <Nav.Item key={`${componentId}-plasticConsumption-${item}-collapsePills`}>
                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "plasticConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "plasticConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-plasticConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "plasticConsumption", status: !collapseState[item].status } })}>{item === "pvc" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <div className="plasticPillsCollapse">
                                        {
                                            Object.keys(collapseState).map(item => (
                                                <CollapsePlasticConsumptionCustom
                                                    key={`${componentId}-plasticConsumption-${item}-collapse`}
                                                    collapseControll={getLogic(item, "plasticConsumption")}
                                                    id={`${componentId}-plasticConsumption`}
                                                    inputName={item}
                                                    inputValue={getValues(`${componentId}-plasticConsumption-${item}-amount`)}
                                                    inputLabel={item === "pvc" ? "PVC Amount" : `${item.charAt(0).toUpperCase()}${item.slice(1)} Amount`}
                                                    inputPlaceholder={"0.0"}
                                                    selectName={item}
                                                    selectLabel={"Units"}
                                                    selectUnitValue={getValues(`${componentId}-plasticConsumption-${item}-volume-select`)}
                                                    selectMaterialValue={getValues(`${componentId}-plasticConsumption-${item}-materialOptions-select`)}
                                                    selectDisposalValue={getValues(`${componentId}-plasticConsumption-${item}-disposalOptions-select`)}
                                                    control={control}
                                                    register={register}
                                                    errors={errors}
                                                    cFootprint={(val) => calulateTotalCF(val)}
                                                />
                                            ))
                                        }
                                    </div>
                                </Col>
                                {collapseAssumption}
                            </Row>
                            {/*    <div className="plasticTabs">
                            <Tabs
                                id={`${componentId}-plasticTabs`}
                                activeKey={key}
                                onSelect={(k) => {
                                    if (key === "") {
                                        setKey(k);
                                    }
                                }}
                            >
                                {
                                    Object.keys(plasticSourceTabs).map(tabItem => {
                                        if (tabItem === key) {
                                            return (plasticSourceTabs[tabItem]);
                                        } else {
                                            return false;
                                        }
                                    })
                                }
                            </Tabs>
                        </div> */}
                        </div>
                        <div className="plasticBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in plastic consumption</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(memo(PlasticTabs)));