import React, { useState, useReducer, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import CollapseElectricalItemsConsumptionCustom from "./CollapseElectricalItemsConsumptionCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import { deleteConfirm } from "../deleteConfirm";
import Assumption from "./electricalItemsAssumption";
import "./ElectricalItemsTabs.css";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const INITIAL_COLLAPSE_STATE = {
    "Electrical items - large": { parent: "", cf: {}, status: false, title: "Electrical items - large" },
    "Electrical items - IT": { parent: "", cf: {}, status: false, title: "Electrical items - IT" },
    "Electrical items - small": { parent: "", cf: {}, status: false, title: "Electrical items - small" },
    "Batteries - Alkaline": { parent: "", cf: {}, status: false, title: "Batteries - Alkaline" },
    "Batteries - Li ion": { parent: "", cf: {}, status: false, title: "Batteries - Li ion" },
    "Batteries - NiMh": { parent: "", cf: {}, status: false, title: "Batteries - NiMh" }
};

const unitOptions = {
    tonnes: { value: "tonnes", label: "tonnes" },
    kg: { value: "kg", label: "kg" }
};

const materialOptions = {
    PrimaryMaterialProduct: { value: "Primary Material Product", label: "Primary Material Product" }
};

const disposalOptions = {
    OpenLoop: { value: "Open-loop", label: "Open-loop" }
};

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();

const ElectricalItemsTabs = (props) => {
    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, INITIAL_COLLAPSE_STATE);
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [ElectricalItemsSourceType, setElectricalItemsSourceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [clickedSave, setClickedSave] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 2)];
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                let itemTitle = INITIAL_COLLAPSE_STATE[item].title;
                if (collapseState[item].parent === "electricalItemsConsumption") {
                    data["data"] = {
                        ...data["data"], [itemTitle]: {
                            status: collapseState[item].status, dataSource: data["dataSource"],
                            amount: collapseState[item].cf.volume, unit: collapseState[item].cf.unit,
                            carbonFootprint: collapseState[item].cf.value / 1000,
                            material: collapseState[item].cf.material, disposal: collapseState[item].cf.disposal
                        }
                    }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                        if (dataArray[item]["dataSource"] === "electricalItems") {
                            if (dataArray[item]["amount"] > 0) {
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "amount": dataArray[item]["amount"] === undefined || dataArray[item]["amount"] === "" ? null : parseFloat(dataArray[item]["amount"]),
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]),
                                            "projectId": projectId,
                                            "electricalItemsMaterialUse": {
                                                "name": dataArray[item]["material"].value
                                            },
                                            "electricalItemsWasteDisposal": {
                                                "name": dataArray[item]["disposal"].value
                                            },
                                            "electricalItemsUnitMeasurement": {
                                                "name": dataArray[item]["unit"].value
                                            },
                                            "electricalItemsSourceType": {
                                                "name": item
                                            }
                                        }]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                return false;
                            }

                        }
                        axios.post(`${basePath}/calculator/electricalItems`, jsonData, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`
                            }
                        })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "ElectricalItems calculator data saved!" });
                                assumptionSaveData();
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                    });
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please select a electricalItems type and fill in the details!" });
                }
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const assumptionSaveData = () => {
        let electricalItemsAssumption = getValues(`${key}-assumptions`)
        if (electricalItemsAssumption === "" || electricalItemsAssumption.length > 0) {
            let electricalItemsAssumpData = {
                "projectId": projectId,
                "data": [{
                    "projectId": projectId,
                    "assumptions": electricalItemsAssumption
                }]
            }
            axios.post(`${basePath}/calculator/electricalItemsAssumption`, electricalItemsAssumpData, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    sTime = new Date();
                    setIsDataPresent(true);
                    setSavedTime(sTime);
                    props.calculateSummary();
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                });
        }
    }

    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/electricalItems/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "ElectricalItems calculator cannot be deleted! Server Error!" });
                    });
                axios.delete(`${basePath}/calculator/electricalItemsAssumption/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "ElectricalItems Assumption cannot be deleted! Server Error!" });
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {}
                if (Object.keys(ElectricalItemsSourceType).length === 0) {
                    for (const fst of props.master.electricalItemsSourceType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setElectricalItemsSourceType(data);
                }
            }
            if (Object.keys(sources).length === 0) {
                for (const ffm of Object.keys(props.master.electricalItemsMaterialUseMaster)) {
                    if (!years.includes(props.master.electricalItemsMaterialUseMaster[ffm].year)) {
                        years.push(props.master.electricalItemsMaterialUseMaster[ffm].year);
                    }
                    if (sources[props.master.electricalItemsMaterialUseMaster[ffm].year] === undefined) {
                        sources[props.master.electricalItemsMaterialUseMaster[ffm].year] = { source: [] };
                    }
                    if (!sources[props.master.electricalItemsMaterialUseMaster[ffm].year].source.includes(props.master.electricalItemsMaterialUseMaster[ffm].source)) {
                        sources[props.master.electricalItemsMaterialUseMaster[ffm].year].source.push(props.master.electricalItemsMaterialUseMaster[ffm].source);
                        sources[props.master.electricalItemsMaterialUseMaster[ffm].year].source.sort();
                    }
                }
                years = years.sort((a, b) => a - b);
            }
        }
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].electricalItemsSourceType.name === "Electrical items - large") {
                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                } else if (props.data.data[0].electricalItemsSourceType.name === "Electrical items - IT") {
                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                } else if (props.data.data[0].electricalItemsSourceType.name === "Electrical items - small") {
                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                } else if (props.data.data[0].electricalItemsSourceType.name === "Batteries - Alkaline") {
                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                } else if (props.data.data[0].electricalItemsSourceType.name === "Batteries - Li ion") {
                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                } else if (props.data.data[0].electricalItemsSourceType.name === "Batteries - NiMh") {
                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                }
            } else {
                setKey(`${componentId}-electricalItemsConsumption`);
                setIsDataPresent(false);
            }
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                if (props.data.dataAssumption) {

                    data["dataSource"] = "electricalItemsConsumption";
                    setKey(`${componentId}-electricalItemsConsumption`);
                }
            } else {
                setKey(`${componentId}-electricalItemsConsumption`);
                setIsDataPresent(false);
            }
        }
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total / 1000).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
                setRefresh(prev => ++prev);
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var st = "";
                    props.data.data.forEach(obj => {
                        var eSourceType = '';
                        switch (obj.electricalItemsSourceType.name) {
                            case "Electrical items - large":
                                eSourceType = "Electrical items - large";
                                break;
                            case "Electrical items - IT":
                                eSourceType = "Electrical items - IT";
                                break;
                            case "Electrical items - small":
                                eSourceType = "Electrical items - small";
                                break;
                            case "Batteries - Alkaline":
                                eSourceType = "Batteries - Alkaline";
                                break;
                            case "Batteries - Li ion":
                                eSourceType = "Batteries - Li ion";
                                break;
                            case "Batteries - NiMh":
                                eSourceType = "Batteries - NiMh";
                                break;
                            default:
                                eSourceType = "";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: eSourceType, parent: 'electricalItemsConsumption', status: true } })
                        setValue(`${componentId}-electricalItemsConsumption-${eSourceType}-amount`, obj.amount);
                        setValue(`${componentId}-electricalItemsConsumption-${eSourceType}-volume-select`, { value: unitOptions[obj.electricalItemsUnitMeasurement.name].value, label: unitOptions[obj.electricalItemsUnitMeasurement.name].label });

                        if (obj.electricalItemsMaterialUse.name.split(' ').join('').split('-').join('') === 'PrimaryMaterialProduct') {
                            setValue(`${componentId}-electricalItemsConsumption-${eSourceType}-materialOptions-select`, { value: materialOptions['PrimaryMaterialProduct'].value, label: materialOptions['PrimaryMaterialProduct'].label });
                        } else {
                            setValue(`${componentId}-electricalItemsConsumption-${eSourceType}-materialOptions-select`, { value: materialOptions[obj.electricalItemsMaterialUse.name.split(' ').join('')].value, label: materialOptions[obj.electricalItemsMaterialUse.name.split(' ').join('')].label });
                        }
                        if (obj.electricalItemsWasteDisposal.name.split(' ').join('').split('-').join('') === 'Openloop') {
                            setValue(`${componentId}-electricalItemsConsumption-${eSourceType}-disposalOptions-select`, { value: disposalOptions['OpenLoop'].value, label: disposalOptions['OpenLoop'].label });
                        } else {
                            setValue(`${componentId}-electricalItemsConsumption-${eSourceType}-disposalOptions-select`, { value: disposalOptions[obj.electricalItemsWasteDisposal.name].value, label: disposalOptions[obj.electricalItemsWasteDisposal.name].label });
                        }
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
                if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                    data["dataAssumption"] = {};
                    var st = "";
                    props.data.dataAssumption.forEach(obj => {
                        setValue(`${componentId}-electricalItemsConsumption-assumptions`, obj.assumptions);
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);
    let collapseAssumption;
    if (isMountedRef.current) {
        if (setElectricalItemsSourceType.length > 0) {
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].status === true) {
                    collapseAssumption = <Assumption
                        key={`${componentId}-electricalItemsConsumption-collapse`}
                        collapseControll={getLogic(item, "electricalItemsConsumption")}
                        label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                        id={`${componentId}-electricalItemsConsumption`}
                        inputName={item}
                        textValue={getValues(`${componentId}-electricalItemsConsumption-assumptions`)}
                        selectName={item}
                        control={control}
                        register={register}
                        errors={errors}
                    />
                }
            })
        }
    }

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#228C22", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "10px" }}>
                                    <span>Electrical Items</span>
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete ElectricalItems calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <p style={{ paddingLeft: "10px", textAlign: "left" }}>Please select one of the following data sources you have available </p>
                            <Row>
                                <Col sm={12}>
                                    <Nav variant="pills" id={`${componentId}-electricalItemsPills`} className="flex-row electricalItemsPills">
                                        {
                                            Object.keys(collapseState).map(item => {
                                                return (
                                                    <Nav.Item key={`${componentId}-electricalItemsConsumption-${item}-collapsePills`}>
                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "electricalItemsConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "electricalItemsConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-electricalItemsConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "electricalItemsConsumption", status: !collapseState[item].status } })}>{`${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <div className="electricalItemsPillsCollapse">
                                        {
                                            Object.keys(collapseState).map(item => (
                                                <CollapseElectricalItemsConsumptionCustom
                                                    key={`${componentId}-electricalItemsConsumption-${item}-collapse`}
                                                    collapseControll={getLogic(item, "electricalItemsConsumption")}
                                                    id={`${componentId}-electricalItemsConsumption`}
                                                    inputName={item}
                                                    inputValue={getValues(`${componentId}-electricalItemsConsumption-${item}-amount`)}
                                                    inputLabel={`${item.charAt(0).toUpperCase()}${item.slice(1)} Amount`}
                                                    inputPlaceholder={"0.0"}
                                                    selectName={item}
                                                    selectLabel={"Units"}
                                                    selectUnitValue={getValues(`${componentId}-electricalItemsConsumption-${item}-volume-select`)}
                                                    selectMaterialValue={getValues(`${componentId}-electricalItemsConsumption-${item}-materialOptions-select`)}
                                                    selectDisposalValue={getValues(`${componentId}-electricalItemsConsumption-${item}-disposalOptions-select`)}
                                                    control={control}
                                                    register={register}
                                                    errors={errors}
                                                    cFootprint={(val) => calulateTotalCF(val)}
                                                />
                                            ))
                                        }
                                    </div>
                                </Col>
                                {collapseAssumption}
                            </Row>
                        </div>
                        <div className="electricalItemsBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in electrical items consumption</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(memo(ElectricalItemsTabs)));