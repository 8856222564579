import React from "react";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/form";
import "./DatePickerCustom.css";

var lStyle = {};

const DatePickerCustom = (props) => {

    let { id, name, label, selected, onChange, dateFormat, register, errors, placeholder, labelStyle, formGroupStyle, noBorder, ...rest } = props;

    if (errors && errors[name]) {
        lStyle = { ...labelStyle, color: "rgb(235, 79, 107)" }
    } else {
        lStyle = labelStyle;
    }

    return (

        <Form.Group controlId={`${id}-group`} style={formGroupStyle}>
            <Form.Label style={lStyle}>{label}</Form.Label>
            <DatePicker
                id={errors && errors[name] ? `${id}-errorDatePicker` : noBorder !== undefined ? `${id}-react-datepicker-no-border` : `${id}-react-datepicker-border`}
                name={name}
                selected={selected}
                onChange={onChange}
                dateFormat={dateFormat}
                register={register}
                errors={errors}
                {...rest}
                autoComplete="off"
                className="numberType"
            />
        </Form.Group>
    )
}

export default DatePickerCustom;
