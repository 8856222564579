import React, { useState, useReducer, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./SeaFreightTabs.css";
import CollapseSeaFreightSeaTankerCustom from "./CollapseSeaFreightSeaTankerCustom";
import CollapseSeaFreightCargoShipCustom from "./CollapseSeaFreightCargoShipCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import { deleteConfirm } from "../deleteConfirm";
import SeaFreightAssumption from "./SeaFreightAssumption";
import ReactTooltip from 'react-tooltip';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const INITIAL_COLLAPSE_STATE = {
    "Crude tanker": { parent: "", cf: {}, status: false },
    "Products tanker": { parent: "", cf: {}, status: false },
    "Chemical tanker": { parent: "", cf: {}, status: false },
    "LNG tanker": { parent: "", cf: {}, status: false },
    "LPG tanker": { parent: "", cf: {}, status: false },
    "Bulk carrier": { parent: "", cf: {}, status: false },
    "General cargo": { parent: "", cf: {}, status: false },
    "Container ship": { parent: "", cf: {}, status: false },
    "Vehicle transport": { parent: "", cf: {}, status: false },
    "RoRo-Ferry": { parent: "", cf: {}, status: false },
};

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: "", cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            delete state[action.payload.name];
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();

const SeaFreightTabs = (props) => {
    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, INITIAL_COLLAPSE_STATE);
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [SeaFreightActivity, setSeaFreightActivity] = useState({});
    const [SeaFreightType, setSeaFreightType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [clickedSave, setClickedSave] = useState(false);

    const allSeaTankerSizes = Object.keys(props.master.seaFreightSeaTankerSizeMaster);
    const allCargoShipSizes = Object.keys(props.master.seaFreightCargoShipSizeMaster);

    const seaTankerCrudeTankerOptionsInCollapseData = allSeaTankerSizes.filter((data) => {
        return data.includes('Sea Tanker_Crude tanker');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const seaTankerProductsTankerOptionsInCollapseData = allSeaTankerSizes.filter((data) => {
        return data.includes('Sea Tanker_Products tanker');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const seaTankerChemicalTankerOptionsInCollapseData = allSeaTankerSizes.filter((data) => {
        return data.includes('Sea Tanker_Chemical tanker');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const seaTankerLngTankerOptionsInCollapseData = allSeaTankerSizes.filter((data) => {
        return data.includes('Sea Tanker_LNG tanker');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const seaTankerLpgTankerOptionsInCollapseData = allSeaTankerSizes.filter((data) => {
        return data.includes('Sea Tanker_LPG tanker');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })

    const cargoShipBulkCarrierOptionsInCollapseData = allCargoShipSizes.filter((data) => {
        return data.includes('Cargo Ship_Bulk carrier');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const cargoShipGeneralCargoOptionsInCollapseData = allCargoShipSizes.filter((data) => {
        return data.includes('Cargo Ship_General cargo');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const cargoShipContainerShipOptionsInCollapseData = allCargoShipSizes.filter((data) => {
        return data.includes('Cargo Ship_Container ship');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const cargoShipVehicleTransportOptionsInCollapseData = allCargoShipSizes.filter((data) => {
        return data.includes('Cargo Ship_Vehicle transport');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })
    const cargoShipRoroFerryOptionsInCollapseData = allCargoShipSizes.filter((data) => {
        return data.includes('Cargo Ship_RoRo-Ferry');
    }).map((val) => {
        return { 'value': val.split('_')[2], 'label': val.split('_')[2] }
    })

    let seaTankerCrudeTankerOptions = {};
    let seaTankerProductsTankerOptions = {};
    let seaTankerChemicalTankerOptions = {};
    let seaTankerLngTankerOptions = {};
    let seaTankerLpgTankerOptions = {};
    let cargoShipBulkCarrierOptions = {};
    let cargoShipGeneralCargoOptions = {};
    let cargoShipContainerShipOptions = {};
    let cargoShipVehicleTransportOptions = {};
    let cargoShipRoroFerryOptions = {};

    seaTankerCrudeTankerOptionsInCollapseData.forEach((data) => {
        seaTankerCrudeTankerOptions[`${data.value}`] = data
    })
    seaTankerProductsTankerOptionsInCollapseData.forEach((data) => {
        seaTankerProductsTankerOptions[`${data.value}`] = data
    })
    seaTankerChemicalTankerOptionsInCollapseData.forEach((data) => {
        seaTankerChemicalTankerOptions[`${data.value}`] = data
    })
    seaTankerLngTankerOptionsInCollapseData.forEach((data) => {
        seaTankerLngTankerOptions[`${data.value}`] = data
    })
    seaTankerLpgTankerOptionsInCollapseData.forEach((data) => {
        seaTankerLpgTankerOptions[`${data.value}`] = data
    })

    cargoShipBulkCarrierOptionsInCollapseData.forEach((data) => {
        cargoShipBulkCarrierOptions[`${data.value}`] = data
    })
    cargoShipGeneralCargoOptionsInCollapseData.forEach((data) => {
        cargoShipGeneralCargoOptions[`${data.value}`] = data
    })
    cargoShipContainerShipOptionsInCollapseData.forEach((data) => {
        cargoShipContainerShipOptions[`${data.value}`] = data
    })
    cargoShipVehicleTransportOptionsInCollapseData.forEach((data) => {
        cargoShipVehicleTransportOptions[`${data.value}`] = data
    })
    cargoShipRoroFerryOptionsInCollapseData.forEach((data) => {
        cargoShipRoroFerryOptions[`${data.value}`] = data
    })

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 1)];
            if (!data["dataSource"]) {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a tab in SeaFreight calculator and fill in the details!" });
                return false;
            }
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "seaTanker") {
                    data["data"] = {
                        ...data["data"], [item]: {
                            status: collapseState[item].status, dataSource: collapseState[item].parent, tonnes: getValues(`${key}-${item}-tonnes`), km: getValues(`${key}-${item}-km`),
                            seaFreightActivity: collapseState[item].cf.seaFreightActivityVal, seaFreightType: collapseState[item].cf.seaFreightTypeVal,
                            seaFreightSeaTankerSize: collapseState[item].cf.seaFreightSizeVal,
                            carbonFootprint: collapseState[item].cf.value, year: collapseState[item].cf.year
                        }
                    }
                } else if (collapseState[item].parent === "cargoShip") {
                    data["data"] = {
                        ...data["data"], [item]: {
                            status: collapseState[item].status, dataSource: collapseState[item].parent, tonnes: getValues(`${key}-${item}-tonnes`), km: getValues(`${key}-${item}-km`),
                            seaFreightActivity: collapseState[item].cf.seaFreightActivityVal, seaFreightType: collapseState[item].cf.seaFreightTypeVal,
                            seaFreightCargoShipSize: collapseState[item].cf.seaFreightSizeVal,
                            carbonFootprint: collapseState[item].cf.value, year: collapseState[item].cf.year
                        }
                    }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                let crudeTankerValidation = false;
                let productsTankerValidation = false;
                let chemicalTankerValidation = false;
                let lngTankerValidation = false;
                let lpgTankerValidation = false;
                let bulkCarrierValidation = false;
                let generalCargoValidation = false;
                let containerShipValidation = false;
                let vehicleTransportValidation = false;
                let roroFerryValidation = false;
                var seaTankerValidation = false;
                var cargoShipValidation = false;
                if (Object.keys(dataArray).length > 0) {
                    Object.keys(dataArray).forEach(item => {
                        if (dataArray[item]["dataSource"] === "seaTanker") {
                            if (((dataArray[item]["seaFreightType"] == 'Crude tanker') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["Crude tanker"] == undefined) || (dataArray["Crude tanker"] ? !dataArray["Crude tanker"].status : true)) {
                                crudeTankerValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'Products tanker') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["Products tanker"] === undefined) || (dataArray["Products tanker"] ? !dataArray["Products tanker"].status : true)) {
                                productsTankerValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'Chemical tanker') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["Chemical tanker"] === undefined) || (dataArray["Chemical tanker"] ? !dataArray["Chemical tanker"].status : true)) {
                                chemicalTankerValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'LNG tanker') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["LNG tanker"] === undefined) || (dataArray["LNG tanker"]  ? !dataArray["LNG tanker"] .status : true)) {
                                lngTankerValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'LPG tanker') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["LPG tanker"]  === undefined) || (dataArray["LPG tanker"]  ? !dataArray["LPG tanker"] .status : true)) {
                                lpgTankerValidation = true;
                            }
                        }
                        if (dataArray[item]["dataSource"] === "cargoShip") {
                            if (((dataArray[item]["seaFreightType"] == 'Bulk carrier') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["Bulk carrier"] == undefined) || (dataArray["Bulk carrier"] ? !dataArray["Bulk carrier"].status : true)) {
                                bulkCarrierValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'General cargo') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["General cargo"] === undefined) || (dataArray["General cargo"] ? !dataArray["General cargo"].status : true)) {
                                generalCargoValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'Container ship') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["Container ship"] === undefined) || (dataArray["Container ship"] ? !dataArray["Container ship"].status : true)) {
                                containerShipValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'Vehicle transport') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["Vehicle transport"] === undefined) || (dataArray["Vehicle transport"] ? !dataArray["Vehicle transport"].status : true)) {
                                vehicleTransportValidation = true;
                            }
                            if (((dataArray[item]["seaFreightType"] == 'RoRo-Ferry') && (dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray["RoRo-Ferry"] === undefined) || (dataArray["RoRo-Ferry"] ? !dataArray["RoRo-Ferry"].status : true)) {
                                roroFerryValidation = true;
                            }
                        }
                    });
                }
                seaTankerValidation = crudeTankerValidation && productsTankerValidation && chemicalTankerValidation && lngTankerValidation && lpgTankerValidation;
                cargoShipValidation = bulkCarrierValidation && generalCargoValidation && containerShipValidation && vehicleTransportValidation && roroFerryValidation;
                if (!seaTankerValidation && !cargoShipValidation) {
                    props.toastMessage({ state: true, header: "Alert", body: "Please enter atleast one type of input values before saving" });
                    return false;
                }
                if (seaTankerValidation || cargoShipValidation) {
                    if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                        Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                            var jsonData = {};
                            if (dataArray[item]["dataSource"] === "seaTanker") {
                                if ((dataArray[item]["tonnes"] && dataArray[item]["km"] && dataArray[item]["carbonFootprint"]) > 0) {
                                    jsonData = {
                                        "projectId": projectId,
                                        "data": [
                                            {
                                                "tonnes": dataArray[item]["tonnes"] === undefined || dataArray[item]["tonnes"] === "" ? null : parseFloat(dataArray[item]["tonnes"]).toFixed(3),
                                                "km": dataArray[item]["km"] === undefined || dataArray[item]["km"] === "" ? null : parseFloat(dataArray[item]["km"]).toFixed(3),
                                                "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                                "projectId": projectId,
                                                "seaFreightActivity": {
                                                    "name": SeaFreightActivity[dataArray[item]["dataSource"]]
                                                },
                                                "seaFreightType": {
                                                    "name": dataArray[item]["seaFreightType"]
                                                },
                                                "seaFreightSeaTankerSize": {
                                                    "name": dataArray[item]["seaFreightSeaTankerSize"]
                                                },
                                                "seaFreightCargoShipSize": {
                                                    "name": 'NULL'
                                                }
                                            }
                                        ]
                                    };
                                } else {
                                    props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                    return false;
                                }
                            } else if (dataArray[item]["dataSource"] === "cargoShip") {
                                if ((dataArray[item]["tonnes"] || dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0) {
                                    jsonData = {
                                        "projectId": projectId,
                                        "data": [
                                            {
                                                "tonnes": dataArray[item]["tonnes"] === undefined || dataArray[item]["tonnes"] === "" ? null : parseFloat(dataArray[item]["tonnes"]).toFixed(3),
                                                "km": dataArray[item]["km"] === undefined || dataArray[item]["km"] === "" ? null : parseFloat(dataArray[item]["km"]).toFixed(3),
                                                "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                                "projectId": projectId,
                                                "seaFreightActivity": {
                                                    "name": SeaFreightActivity[dataArray[item]["dataSource"]]
                                                },
                                                "seaFreightType": {
                                                    "name": dataArray[item]["seaFreightType"]
                                                },
                                                "seaFreightSeaTankerSize": {
                                                    "name": 'NULL'
                                                },
                                                "seaFreightCargoShipSize": {
                                                    "name": dataArray[item]["seaFreightCargoShipSize"]
                                                }
                                            }
                                        ]
                                    };
                                } else {
                                    props.toastMessage({ state: true, header: "Alert", body: "Please enter atleast one type of input value for the Cargo Ship before saving" });
                                    return false;
                                }
                            }
                            axios.post(`${basePath}/calculator/seaFreight`, jsonData, {
                                headers: {
                                    authorization: `Bearer ${props.login.token}`
                                }
                            })
                                .then(res => {
                                    sTime = new Date();
                                    setIsDataPresent(true);
                                    setSavedTime(sTime);
                                    props.calculateSummary();
                                    props.toastMessage({ state: true, header: "Success", body: "SeaFreight calculator data saved!" });
                                    assumptionSaveData();
                                })
                                .catch(err => {
                                    props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                                });

                        });
                    } else {
                        props.toastMessage({ state: true, header: "Alert", body: "Please select a SeaFreight type and fill in the details!" });
                    }
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a SeaFreight type and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const assumptionSaveData = () => {
        let SeaFreightassumption = getValues(`${key}-assumptions`)
        if (SeaFreightassumption === "" || SeaFreightassumption.length > 0) {
            let SeaFreightdata = {
                "projectId": projectId,
                "data": [{
                    "projectId": projectId,
                    "assumptions": SeaFreightassumption
                }]
            }
            axios.post(`${basePath}/calculator/seaFreightAssumption`, SeaFreightdata, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    sTime = new Date();
                    setIsDataPresent(true);
                    setSavedTime(sTime);
                    props.calculateSummary();
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Error", body: "Server Error from assumption!" });
                });
        }
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/seaFreight/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "SeaFreight calculator cannot be deleted! Server Error!" });
                    });
                axios.delete(`${basePath}/calculator/seaFreightAssumption/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "SeaFreight Assumption cannot be deleted! Server Error" })
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {}
                if (Object.keys(SeaFreightType).length === 0) {
                    for (const fst of props.master.seaFreightActivity) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setSeaFreightActivity(data);
                }
                if (Object.keys(SeaFreightType).length === 0) {
                    data = {};
                    for (const ft of props.master.seaFreightType) {
                        let val = "";
                        val = ft.value;
                        data[camelize(val.toLowerCase())] = ft.value
                    }
                    setSeaFreightType(data);
                }
            }
            if (Object.keys(sources).length === 0) {
                for (const ffm of Object.keys(props.master.seaFreightSeaTankerSizeMaster)) {
                    if (!years.includes(props.master.seaFreightSeaTankerSizeMaster[ffm].year)) {
                        years.push(props.master.seaFreightSeaTankerSizeMaster[ffm].year);
                    }
                    if (sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year] === undefined) {
                        sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year] = { source: [] };
                    }
                    if (!sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year].source.includes(props.master.seaFreightSeaTankerSizeMaster[ffm].source)) {
                        sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year].source.push(props.master.seaFreightSeaTankerSizeMaster[ffm].source);
                        sources[props.master.seaFreightSeaTankerSizeMaster[ffm].year].source.sort();
                    }
                }
                years = years.sort((a, b) => a - b);
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].seaFreightActivity.name === "Sea Tanker") {
                    data["dataSource"] = "seaTanker";
                    setKey(`${componentId}-seaTanker`);
                } else if (props.data.data[0].seaFreightActivity.name === "Cargo Ship") {
                    data["dataSource"] = "cargoShip";
                    setKey(`${componentId}-cargoShip`);
                }
            } else {
                setIsDataPresent(false);
            }

            //SeaFreightAssumption
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                if (props.data.data[0].seaFreightActivity.name === "Sea Tanker") {
                    data["dataSource"] = "seaTanker";
                    setKey(`${componentId}-seaTanker`);
                } else if (props.data.data[0].seaFreightActivity.name === "Cargo Ship") {
                    data["dataSource"] = "cargoShip";
                    setKey(`${componentId}-cargoShip`);
                }
            } else {
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var st = "";
                    props.data.data.forEach(obj => {
                        var fSourceType = "";
                        switch (obj.seaFreightActivity.name) {
                            case "Sea Tanker":
                                fSourceType = "seaTanker";
                                break;
                            case "Cargo Ship":
                                fSourceType = "cargoShip";
                                break;
                            default:
                                fSourceType = "seaTanker";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.seaFreightType.name, parent: fSourceType, status: true } });
                        if (fSourceType === "seaTanker") {
                            setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-tonnes`, obj.tonnes);
                            setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-km`, obj.km);
                            if (obj.seaFreightType.name === "Crude tanker") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-seaTankerCrudeTankerOptions-select`,
                                    { value: seaTankerCrudeTankerOptions[obj.seaFreightSeaTankerSize.name].value, label: seaTankerCrudeTankerOptions[obj.seaFreightSeaTankerSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "Products tanker") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-seaTankerProductsTankerOptions-select`,
                                    { value: seaTankerProductsTankerOptions[obj.seaFreightSeaTankerSize.name].value, label: seaTankerProductsTankerOptions[obj.seaFreightSeaTankerSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "Chemical tanker") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-seaTankerChemicalTankerOptions-select`,
                                    { value: seaTankerChemicalTankerOptions[obj.seaFreightSeaTankerSize.name].value, label: seaTankerChemicalTankerOptions[obj.seaFreightSeaTankerSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "LNG tanker") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-seaTankerLngTankerOptions-select`,
                                    { value: seaTankerLngTankerOptions[obj.seaFreightSeaTankerSize.name].value, label: seaTankerLngTankerOptions[obj.seaFreightSeaTankerSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "LPG tanker") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-seaTankerLpgTankerOptions-select`,
                                    { value: seaTankerLpgTankerOptions[obj.seaFreightSeaTankerSize.name].value, label: seaTankerLpgTankerOptions[obj.seaFreightSeaTankerSize.name].label }
                                );
                            }
                            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                props.data.dataAssumption.forEach(obj => {
                                    setValue(`${componentId}-seaTanker-assumptions`, obj.assumptions);
                                });
                            }
                        } else if (fSourceType === "cargoShip") {
                            setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-tonnes`, obj.tonnes);
                            setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-km`, obj.km);
                            if (obj.seaFreightType.name === "Bulk carrier") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-cargoShipBulkCarrierOptions-select`,
                                    { value: cargoShipBulkCarrierOptions[obj.seaFreightCargoShipSize.name].value, label: cargoShipBulkCarrierOptions[obj.seaFreightCargoShipSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "General cargo") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-cargoShipGeneralCargoOptions-select`,
                                    { value: cargoShipGeneralCargoOptions[obj.seaFreightCargoShipSize.name].value, label: cargoShipGeneralCargoOptions[obj.seaFreightCargoShipSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "Container ship") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-cargoShipContainerShipOptions-select`,
                                    { value: cargoShipContainerShipOptions[obj.seaFreightCargoShipSize.name].value, label: cargoShipContainerShipOptions[obj.seaFreightCargoShipSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "Vehicle transport") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-cargoShipVehicleTransportOptions-select`,
                                    { value: cargoShipVehicleTransportOptions[obj.seaFreightCargoShipSize.name].value, label: cargoShipVehicleTransportOptions[obj.seaFreightCargoShipSize.name].label }
                                );
                            } else if (obj.seaFreightType.name === "RoRo-Ferry") {
                                setValue(`${componentId}-${fSourceType}-${obj.seaFreightType.name}-cargoShipRoroFerryOptions-select`,
                                    { value: cargoShipRoroFerryOptions[obj.seaFreightCargoShipSize.name].value, label: cargoShipRoroFerryOptions[obj.seaFreightCargoShipSize.name].label }
                                );
                            }
                            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                props.data.dataAssumption.forEach(obj => {
                                    setValue(`${componentId}-cargoShip-assumptions`, obj.assumptions);
                                });
                            }
                        }
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);

                }
            }
        }
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);

    let collapseSeaTankerAssumption, collapseCargoShipAssumption;
    if (isMountedRef.current) {
        Object.keys(collapseState).forEach(item => {
            if (collapseState[item].status === true) {
                collapseSeaTankerAssumption = <SeaFreightAssumption
                    key={`${componentId}-seaTanker-collapse`}
                    collapseControll={getLogic(item, "seaTanker")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-seaTanker`}
                    inputName={item}
                    textValue={getValues(`${componentId}-seaTanker-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
                collapseCargoShipAssumption = <SeaFreightAssumption
                    key={`${componentId}-cargoShip-collapse`}
                    collapseControll={getLogic(item, "cargoShip")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-cargoShip`}
                    inputName={item}
                    textValue={getValues(`${componentId}-cargoShip-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />

            }
        })
    }

    const seaFreightSourceTabs = {
        [`${componentId}-seaTanker`]: (
            <Tab eventKey={`${componentId}-seaTanker`} title="Sea Tanker" disabled={key !== "" && key !== `${componentId}-seaTanker`} key={`${componentId}-seaTanker`} >
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <>
                                <Nav variant="pills" id={`${componentId}-seaFreightPills`} className="flex-row seaFreightPills">
                                    {
                                        Object.keys(collapseState).map(item => {
                                            return (
                                                <>
                                                    {(item === "Bulk carrier" || item === "General cargo" || item === "Container ship" || item === "Vehicle transport" || item === "RoRo-Ferry") ?
                                                        <Nav.Item key={`${componentId}-seaTanker-${item}-collapsePills`} className="seaFreightPills nav-item1">
                                                            <Nav.Link
                                                                eventKey={item}
                                                                active={collapseState[item].parent === 'seaTanker' && collapseState[item].status === true}
                                                                disabled={
                                                                    collapseState[item].parent !== 'seaTanker' && collapseState[item].status === true
                                                                }
                                                                aria-controls={`${componentId}-seaTanker-${item}-collapse`}
                                                                onClick={() =>
                                                                    dispatchCollapseState({
                                                                        type: 'COLLAPSE_SET_PARENT_STATUS',
                                                                        payload: { name: item, parent: 'seaTanker', status: !collapseState[item].status },
                                                                    })
                                                                }
                                                            >
                                                                {
                                                                    item === 'Crude tanker'
                                                                        ? 'Crude tanker'
                                                                        : item === 'Products tanker'
                                                                            ? 'Products tanker'
                                                                            : item === 'Chemical tanker'
                                                                                ? 'Chemical tanker'
                                                                                : item === 'LNG tanker'
                                                                                    ? 'LNG tanker'
                                                                                    : item === 'LPG tanker'
                                                                                        ? 'LPG tanker'
                                                                                        : item.charAt(0).toUpperCase() + item.slice(1)
                                                                }
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        :
                                                        <Nav.Item key={`${componentId}-seaTanker-${item}-collapsePills`} >
                                                            <Nav.Link
                                                                eventKey={item}
                                                                active={collapseState[item].parent === 'seaTanker' && collapseState[item].status === true}
                                                                disabled={
                                                                    collapseState[item].parent !== 'seaTanker' && collapseState[item].status === true
                                                                }
                                                                aria-controls={`${componentId}-seaTanker-${item}-collapse`}
                                                                onClick={() =>
                                                                    dispatchCollapseState({
                                                                        type: 'COLLAPSE_SET_PARENT_STATUS',
                                                                        payload: { name: item, parent: 'seaTanker', status: !collapseState[item].status },
                                                                    })
                                                                }
                                                            >
                                                                {
                                                                    item === 'Crude tanker'
                                                                        ? 'Crude tanker'
                                                                        : item === 'Products tanker'
                                                                            ? 'Products tanker'
                                                                            : item === 'Chemical tanker'
                                                                                ? 'Chemical tanker'
                                                                                : item === 'LNG ttanker'
                                                                                    ? 'LNG tanker'
                                                                                    : item === 'LPG tanker'
                                                                                        ? 'LPG tanker'
                                                                                        : item.charAt(0).toUpperCase() + item.slice(1)
                                                                }
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </Nav>
                            </>
                        </Col>
                        <Col sm={12}>
                            <div className="seaFreightPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapseSeaFreightSeaTankerCustom
                                            key={`${componentId}-seaTanker-${item}-collapse`}
                                            collapseControll={getLogic(item, "seaTanker")}
                                            id={`${componentId}-seaTanker`}
                                            label={item}
                                            inputName={item}
                                            inputTonnes={getValues(`${componentId}-seaTanker-${item}-tonnes`)}
                                            inputKm={getValues(`${componentId}-seaTanker-${item}-km`)}
                                            inputPlaceholder={"0"}
                                            selectName={item}
                                            seaTankerCrudeTankerOptionsInCollapseData={seaTankerCrudeTankerOptionsInCollapseData}
                                            seaTankerProductsTankerOptionsInCollapseData={seaTankerProductsTankerOptionsInCollapseData}
                                            seaTankerChemicalTankerOptionsInCollapseData={seaTankerChemicalTankerOptionsInCollapseData}
                                            seaTankerLngTankerOptionsInCollapseData={seaTankerLngTankerOptionsInCollapseData}
                                            seaTankerLpgTankerOptionsInCollapseData={seaTankerLpgTankerOptionsInCollapseData}
                                            selectCrudeTankerValue={getValues(`${componentId}-seaTanker-${item}-seaTankerCrudeTankerOptions-select`)}
                                            selectProductsTankerValue={getValues(`${componentId}-seaTanker-${item}-seaTankerProductsTankerOptions-select`)}
                                            selectChemicalTankerValue={getValues(`${componentId}-seaTanker-${item}-seaTankerChemicalTankerOptions-select`)}
                                            selectLngTankerValue={getValues(`${componentId}-seaTanker-${item}-seaTankerLngTankerOptions-select`)}
                                            selectLpgTankerValue={getValues(`${componentId}-seaTanker-${item}-seaTankerLpgTankerOptions-select`)}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            seaFreightVal={collapseState[item].parent}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseSeaTankerAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        ),
        [`${componentId}-cargoShip`]: (
            <Tab eventKey={`${componentId}-cargoShip`} title="Cargo Ship" disabled={key !== "" && key !== `${componentId}-cargoShip`} key={`${componentId}-cargoShip`}>
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" id={`${componentId}-cargoShipPills`} className="flex-row seaFreightPills">
                                {
                                    Object.keys(collapseState).map(item => {
                                        return (
                                            <>
                                                {(item === "Crude tanker" || item === "Products tanker" || item === "Chemical tanker" || item === "LNG tanker" || item === "LPG tanker") ?
                                                    <Nav.Item key={`${componentId}-cargoShip-${item}-collapsePills`} className="seaFreightPills nav-item1">
                                                        <Nav.Link
                                                            eventKey={item}
                                                            active={collapseState[item].parent === 'cargoShip' && collapseState[item].status === true}
                                                            disabled={
                                                                collapseState[item].parent !== 'cargoShip' && collapseState[item].status === true
                                                            }
                                                            aria-controls={`${componentId}-cargoShip-${item}-collapse`}
                                                            onClick={() =>
                                                                dispatchCollapseState({
                                                                    type: 'COLLAPSE_SET_PARENT_STATUS',
                                                                    payload: { name: item, parent: 'cargoShip', status: !collapseState[item].status },
                                                                })
                                                            }
                                                        >
                                                            {
                                                                item === 'Bulk carrier'
                                                                    ? 'Bulk carrier'
                                                                    : item === 'General cargo'
                                                                        ? 'General cargo'
                                                                        : item === 'Container ship'
                                                                            ? 'Container ship'
                                                                            : item === 'Vehicle transport'
                                                                                ? 'Vehicle transport'
                                                                                : item === 'RoRo-Ferry'
                                                                                    ? 'RoRo-Ferry'
                                                                                    : item.charAt(0).toUpperCase() + item.slice(1)
                                                            }
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    :
                                                    <Nav.Item key={`${componentId}-cargoShip-${item}-collapsePills`}>
                                                        <Nav.Link
                                                            eventKey={item}
                                                            active={collapseState[item].parent === 'cargoShip' && collapseState[item].status === true}
                                                            disabled={
                                                                collapseState[item].parent !== 'cargoShip' && collapseState[item].status === true
                                                            }
                                                            aria-controls={`${componentId}-cargoShip-${item}-collapse`}
                                                            onClick={() =>
                                                                dispatchCollapseState({
                                                                    type: 'COLLAPSE_SET_PARENT_STATUS',
                                                                    payload: { name: item, parent: 'cargoShip', status: !collapseState[item].status },
                                                                })
                                                            }
                                                        >
                                                            {
                                                                item === 'Bulk carrier'
                                                                    ? 'Bulk carrier'
                                                                    : item === 'General cargo'
                                                                        ? 'General cargo'
                                                                        : item === 'Container ship'
                                                                            ? 'Container ship'
                                                                            : item === 'Vehicle transport'
                                                                                ? 'Vehicle transport'
                                                                                : item === 'RoRo-Ferry'
                                                                                    ? 'RoRo-Ferry'
                                                                                    : item.charAt(0).toUpperCase() + item.slice(1)
                                                            }
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                }
                                            </>

                                        )
                                    })
                                }
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <div className="seaFreightPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapseSeaFreightCargoShipCustom
                                            key={`${componentId}-cargoShip-${item}-collapse`}
                                            collapseControll={getLogic(item, "cargoShip")}
                                            label={item}
                                            id={`${componentId}-cargoShip`}
                                            inputName={item}
                                            inputTonnes={getValues(`${componentId}-cargoShip-${item}-tonnes`)}
                                            inputKm={getValues(`${componentId}-cargoShip-${item}-km`)}
                                            inputPlaceholder={"0"}
                                            cargoShipBulkCarrierOptionsInCollapseData={cargoShipBulkCarrierOptionsInCollapseData}
                                            cargoShipGeneralCargoOptionsInCollapseData={cargoShipGeneralCargoOptionsInCollapseData}
                                            cargoShipContainerShipOptionsInCollapseData={cargoShipContainerShipOptionsInCollapseData}
                                            cargoShipVehicleTransportOptionsInCollapseData={cargoShipVehicleTransportOptionsInCollapseData}
                                            cargoShipRoroFerryOptionsInCollapseData={cargoShipRoroFerryOptionsInCollapseData}
                                            selectBulkCarrierValue={getValues(`${componentId}-cargoShip-${item}-cargoShipBulkCarrierOptions-select`)}
                                            selectGeneralCargoValue={getValues(`${componentId}-cargoShip-${item}-cargoShipGeneralCargoOptions-select`)}
                                            selectContainerShipValue={getValues(`${componentId}-cargoShip-${item}-cargoShipContainerShipOptions-select`)}
                                            selectVehicleTransportValue={getValues(`${componentId}-cargoShip-${item}-cargoShipVehicleTransportOptions-select`)}
                                            selectRoroFerryValue={getValues(`${componentId}-cargoShip-${item}-cargoShipRoroFerryOptions-select`)}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            cargoShipVal={collapseState[item].parent}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseCargoShipAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        )
    }

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#009dc4", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "10px" }}>
                                    <span>Sea Freight</span>
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete SeaFreight calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <p style={{ paddingLeft: "10px", textAlign: "left" }}>Please select one of the following data sources you have available </p>
                            <div className="seaFreightTabs">
                                <Tabs
                                    id={`${componentId}-seaFreightTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >
                                    {
                                        Object.keys(seaFreightSourceTabs).map(tabItem => {
                                            if (tabItem === key) {
                                                return (seaFreightSourceTabs[tabItem]);
                                            } else {
                                                return false;
                                            }
                                        })
                                    }
                                    {
                                        Object.keys(seaFreightSourceTabs).map(tabItem => {
                                            if (tabItem !== key) {
                                                return (seaFreightSourceTabs[tabItem]);
                                            } else {
                                                return false;
                                            }
                                        })
                                    }
                                </Tabs>
                            </div>
                        </div>
                        <div className="seaFreightBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in sea freight consumption</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(memo(SeaFreightTabs)));