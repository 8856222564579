import React, { useState, useReducer, useEffect, useRef } from "react";
import {
    Link
} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./ElectricityTabs.css";
import Select from "../select/SelectCustomCountry";
import CollapseElectricityConsumptionCustom from "./CollapseElectricityConsumptionCustom";
import CollapseElectricityCostCustom from "./CollapseElectricityCostCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../errorBoundaries/ErrorBoundaryForPage";
import { mainCountries } from "../../calculatorFunctions/mainCountries";
import { deleteConfirm } from "../deleteConfirm";
import ElectricityAssumption from "./ElectricityAssumption";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var primaryCountry;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();
var currentParent = "";

const ElectricityTabs = (props) => {
    // console.log(props, 'electricity tabs props');

    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    primaryCountry = props.project.currentProjectPrimaryCountry;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, {});
    // const form = useForm();
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [electricitySourceType, setElectricitySourceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const filterCountry = (inputValue) => {
        const grouped = groupBy(countries, country => country.label);
        const top = grouped.get("top")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        const bottom = grouped.get("bottom")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return [
            {
                label: "top",
                options: top
            },
            {
                label: "bottom",
                options: bottom
            }
        ];
    };

    const getCountryOptionsData = (inputValue, callback) => {
        callback(filterCountry(inputValue));
    }

    const saveData = () => {
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 1)];
            if (!data["dataSource"]) {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a tab in electricity calculator and fill in the details!" });
                return false;
            }
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "electricityConsumption") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, amount: getValues(`${key}-${item}-amount`), carbonFootprint: collapseState[item].cf.value, generation: collapseState[item].cf.generation, tnd: collapseState[item].cf.tnd, wtt: collapseState[item].cf.wtt, tndWtt: collapseState[item].cf.tndWtt, year: collapseState[item].cf.year } }
                } else if (collapseState[item].parent === "electricityCost") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: collapseState[item].parent, value: getValues(`${key}-${item}-value`), price: getValues(`${key}-${item}-price`), carbonFootprint: collapseState[item].cf.value, generation: collapseState[item].cf.generation, tnd: collapseState[item].cf.tnd, wtt: collapseState[item].cf.wtt, tndWtt: collapseState[item].cf.tndWtt, year: collapseState[item].cf.year } }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                        if (electricitySourceType[dataArray[item]["dataSource"]] === "Electricity consumption") {
                            if (dataArray[item]["amount"] > 0) {
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "amount": dataArray[item]["amount"] === undefined || dataArray[item]["amount"] === "" ? null : parseFloat(dataArray[item]["amount"]).toFixed(3),
                                            "value": null,
                                            "price": null,
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                            "projectId": projectId,
                                            "electricitySourceType": {
                                                "name": electricitySourceType[dataArray[item]["dataSource"]]
                                            },
                                            "country": {
                                                "name": item
                                            },
                                            "electricityFactor": {
                                                "generation": dataArray[item]["generation"],
                                                "tnd": dataArray[item]["tnd"],
                                                "wtt": dataArray[item]["wtt"],
                                                "tndWtt": dataArray[item]["tndWtt"],
                                                "year": dataArray[item]["year"]
                                            }
                                        }
                                    ]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                return false;
                            }

                        } else if (electricitySourceType[dataArray[item]["dataSource"]] === "Electricity cost") {
                            if (dataArray[item]["value"] > 0 && dataArray[item]["price"] > 0){
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "amount": null,
                                            "value": dataArray[item]["value"] === undefined || dataArray[item]["value"] === "" ? null : parseFloat(dataArray[item]["value"]).toFixed(3),
                                            "price": dataArray[item]["price"] === undefined || dataArray[item]["price"] === "" ? null : parseFloat(dataArray[item]["price"]).toFixed(3),
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                            "projectId": projectId,
                                            "electricitySourceType": {
                                                "name": electricitySourceType[dataArray[item]["dataSource"]]
                                            },
                                            "country": {
                                                "name": item
                                            },
                                            "electricityFactor": {
                                                "generation": dataArray[item]["generation"],
                                                "tnd": dataArray[item]["tnd"],
                                                "wtt": dataArray[item]["wtt"],
                                                "tndWtt": dataArray[item]["tndWtt"],
                                                "year": dataArray[item]["year"]
                                            }
                                        }
                                    ]
                                };
                            } else {
                            props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                            return false;
                            }
                        }

                        axios.post(`${basePath}/calculator/electricity`, jsonData, {
                        headers: {
                            authorization: `Bearer ${props.login.token}`
                        }
                    })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "Electricity calculator data saved!" });
                                assumptionSaveData();
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                });
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a country in electricity calculator and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please select a country in electricity calculator and fill in the details!" });
        }
    } else {
        props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" })
}
    }

const assumptionSaveData = () => {
    let eassumption = getValues(`${key}-assumptions`)
    if (eassumption === "" || eassumption.length > 0) {
        let edata = {
            "projectId": projectId,
            "data": [{
                "projectId": projectId,
                "assumptions": eassumption
            }]
        }
        //   console.log(edata, 'json data edata');
        axios.post(`${basePath}/calculator/electricityAssumption`, edata, {
            headers: {
                authorization: `Bearer ${props.login.token}`
            }
        })
            .then(res => {
                sTime = new Date();
                setIsDataPresent(true);
                setSavedTime(sTime);
                props.calculateSummary();
                // props.toastMessage({ state: true, header: "Success", body: "Electricity calculator data saved!" });
            })
            .catch(err => {
                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
            });
    }
}


const setUnsetCountry = (val, parent) => {
    currentParent = parent;
    if (val !== null) {
        setSelectedCountries(val);
    } else {
        setSelectedCountries([]);
    }
}

const calulateTotalCF = (val) => {
    if (collapseState[val.name].parent === val.parent) {
        dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
    }
}

const deleteCurrentCalculator = () => {
    if (isMountedRef.current) {
        if (projectId !== undefined && projectId !== "") {
            axios.delete(`${basePath}/calculator/electricity/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    if (isMountedRef.current) {
                        props.onDelete(componentId);
                        props.calculateSummary();
                    }
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Alert", body: "Electricity calculator cannot be deleted! Server Error!" });
                });

            axios.delete(`${basePath}/calculator/electricityAssumption/${projectId}`, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    if (isMountedRef.current) {
                        props.onDelete(componentId);
                        props.calculateSummary();
                    }
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Alert", body: "Water calculator cannot be deleted! Server Error" })
                });
        } else {
            if (isMountedRef.current) {
                props.onDelete(componentId);
            }
        }
    }
}

const formatGroupLabel = (data) => {
    if (data.label !== "top") {
        return (
            <hr style={{ left: "-10px", height: "0.5px", backgroundColor: "#f0f0f0", margin: "0px", padding: "0px", marginTop: "-10px" }} />
        )
    } else {
        return false;
    }
};

useEffect(() => {
    if (isMountedRef.current) {
        projectId = props.projectId || props.project.currentProjectId;
    }
}, [props.projectId, props.project.currentProjectId, isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        if (selectedCountries.length > 0) {
            Object.keys(collapseState).forEach(k => {
                var found = false;
                for (const item of selectedCountries) {
                    if (k === item.value) {
                        found = true;
                    }
                }
                if (!found) {
                    dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                }
            });
            for (const item of selectedCountries) {
                if (!collapseState.hasOwnProperty(item.value)) {
                    dispatchCollapseState({ type: "COLLAPSE_ADD", payload: { name: item.value, parent: currentParent } });
                }
            }
        } else {
            Object.keys(collapseState).forEach(k => {
                dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
            });
        }
    }
    // eslint-disable-next-line
}, [selectedCountries])

useEffect(() => {
    if (isMountedRef.current) {
        if (props.master.status === true) {
            let data = {}
            if (Object.keys(electricitySourceType).length === 0) {
                for (const fst of props.master.electricitySourceType) {
                    data[camelize(fst.value)] = fst.value
                }
                setElectricitySourceType(data);
            }
            if (countries.length === 0) {
                var tempGroup = [];
                let temp = [];
                for (const c of Object.keys(mainCountries)) {
                    if (props.master.electricityFactorMaster.hasOwnProperty(c)) {
                        temp.push({ value: c, label: c });
                    }
                }
                tempGroup.push(
                    {
                        label: "top",
                        options: temp
                    }
                );
                temp = [];
                for (const c of props.master.country) {
                    if (props.master.electricityFactorMaster.hasOwnProperty(c.value) && !mainCountries.hasOwnProperty(c.value) && c.value !== "Other") {
                        temp.push({ value: c.value, label: c.value });
                    }
                }
                temp.push({ value: "Other", label: "Other" });
                tempGroup.push(
                    {
                        label: "bottom",
                        options: temp
                    }
                );
                setCountries(tempGroup);
            }
            if (Object.keys(sources).length === 0) {
                for (const efm of Object.keys(props.master.electricityFactorMaster)) {
                    for (const ft of Object.keys(props.master.electricityFactorMaster[efm])) {
                        if (ft.split("Source").length > 1) {
                            if (!years.includes(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"])) {
                                years.push(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]);
                            }

                            if (sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] === undefined) {
                                sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] = { source: [] };
                            }
                            if (!sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.includes(props.master.electricityFactorMaster[efm][ft])) {
                                sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.push(props.master.electricityFactorMaster[efm][ft]);
                                sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.sort();
                            }
                        }
                    }
                }
                years = years.sort((a, b) => a - b);
            }
        }
    }
    // eslint-disable-next-line
}, [props.master.status, isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        if (props.data !== undefined && props.data.data.length > 0) {
            if (props.data.data[0].electricitySourceType.name === "Electricity consumption") {
                data["dataSource"] = "electricityConsumption";
                setKey(`${componentId}-electricityConsumption`);
            } else if (props.data.data[0].electricitySourceType.name === "Electricity cost") {
                data["dataSource"] = "electricityCost";
                setKey(`${componentId}-electricityCost`);
            }
        } else {
            setIsDataPresent(false);
        }
        if (props.data !== undefined && props.data.dataAssumption.length > 0) {
            if (props.data.data[0].electricitySourceType.name === "Electricity consumption") {
                data["dataSource"] = "electricityConsumption";
                setKey(`${componentId}-electricityConsumption`);
            } else if (props.data.data[0].electricitySourceType.name === "Electricity cost") {
                data["dataSource"] = "electricityCost";
                setKey(`${componentId}-electricityCost`);
            }
        } else {
            setIsDataPresent(false);
        }
    }
    // eslint-disable-next-line
}, [isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        var total = 0;
        Object.keys(collapseState).forEach(key => {
            if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                total += parseFloat(collapseState[key].cf.value);
            }
        });
        setTotalCarbonFootprint(parseFloat(total).toFixed(3));
    }
}, [collapseState, isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
    }
    // eslint-disable-next-line
}, [totalCarbonFootprint, isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        if (isDataPresent === true && savedTime !== "") {
            sTime = savedTime;
            setMonitorSavedTime(true);
            setShouldRender(true);
        } else {
            sTime = new Date();
            setMonitorSavedTime(false);
            if (props.type === "new") {
                setShouldRender(true);
            }
        }
    }
    // eslint-disable-next-line
}, [savedTime, isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        if (isDataPresent === true && monitorSavedTime === true) {
            setLastSaved(timeDiffCalc(new Date(), sTime));
            setInterval(() => {
                if (isMountedRef.current) {
                    if (monitorSavedTime === true) {
                        setLastSaved(timeDiffCalc(new Date(), sTime));
                    }
                }
            }, 1000);
        } else {
            setLastSaved("");
        }
    }
    // eslint-disable-next-line
}, [monitorSavedTime, isMountedRef])

useEffect(() => {
    if (isMountedRef.current) {
        if (refresh === 0) {
            // console.log("Electricity Component Added");
            setRefresh(prev => ++prev);
        }
    }
}, [refresh, isMountedRef]);

useEffect(() => {
    if (isMountedRef.current) {
        if (key !== "") {
            if (props.data !== undefined && props.data.data.length > 0) {
                data["data"] = {};
                var sCountries = [];
                var st = "";
                props.data.data.forEach(obj => {
                    var fSourceType = "";
                    switch (obj.electricitySourceType.name) {
                        case "Electricity consumption":
                            fSourceType = "electricityConsumption";
                            break;
                        case "Electricity cost":
                            fSourceType = "electricityCost";
                            break;
                        default:
                            fSourceType = "electricityConsumption";
                            break;
                    }
                    dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.country.name, parent: fSourceType, status: true } });
                    for (const gc of countries) {
                        for (const c of gc.options) {
                            if (c.value === obj.country.name) {
                                sCountries.push(c);
                                break;
                            }
                        }
                    }
                    if (fSourceType === "electricityConsumption") {
                        setValue(`${componentId}-${fSourceType}-${obj.country.name}-amount`, obj.amount);
                    } else if (fSourceType === "electricityCost") {
                        setValue(`${componentId}-${fSourceType}-${obj.country.name}-value`, obj.value);
                        setValue(`${componentId}-${fSourceType}-${obj.country.name}-price`, obj.price);
                    }
                    st = obj.createdAt;
                });
                setSelectedCountries(sCountries);
                setSavedTime(st);
                setIsDataPresent(true);
                setShouldRender(true);
            }
            // assumption
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                data["dataAssumption"] = {};
                var st = "";
                props.data.dataAssumption.forEach(obj => {
                    dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { status: true } });
                    setValue(`${componentId}-electricityConsumption-assumptions`, obj.assumptions);
                    setValue(`${componentId}-electricityCost-assumptions`, obj.assumptions);
                    st = obj.createdAt;
                });
                setSavedTime(st);
                setIsDataPresent(true);
                setShouldRender(true);
            }
        }
    }
    // eslint-disable-next-line
}, [key, isMountedRef]);

let collapseECAssumption;
let collapseAssumption;
if (isMountedRef.current) {
    if (selectedCountries.length > 0) {
        Object.keys(collapseState).forEach(item => {
            if (collapseState[item].status === true) {
                collapseECAssumption = <ElectricityAssumption
                    key={`${componentId}-electricityConsumption-collapse`}
                    collapseControll={getLogic(item, "electricityConsumption")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-electricityConsumption`}
                    inputName={item}
                    textValue={getValues(`${componentId}-electricityConsumption-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
                collapseAssumption = <ElectricityAssumption
                    key={`${componentId}-electricityCost-collapse`}
                    collapseControll={getLogic(item, "electricityCost")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-electricityCost`}
                    inputName={item}
                    textValue={getValues(`${componentId}-electricityCost-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
            }
        })
    }
}

const electricitySourceTabs = {
    [`${componentId}-electricityConsumption`]: (
        <Tab eventKey={`${componentId}-electricityConsumption`} title="Electricity Consumption" disabled={key !== "" && key !== `${componentId}-electricityConsumption`} key={`${componentId}-electricityConsumption`} >
            <Tab.Container>
                <Row>
                    <Col sm={12} style={{ textAlign: "left", paddingTop: "10px" }}>
                        <Controller
                            render={({ onChange, onBlur, value, name }) => (
                                <Select name={name} label={"Country"} formatGroupLabel={formatGroupLabel} closeMenuOnSelect={true} isMulti={true} onChange={(val) => { setUnsetCountry(val, "electricityConsumption"); return onChange(val) }} onBlur={onBlur} value={selectedCountries} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ width: "auto", flexGrow: 1, margin: "0px", marginLeft: "10px", marginRight: "10px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#FFFF0080" singleValueFontSize={"12px"} />
                            )}
                            control={control}
                            name="country"
                            defaultValue={''}
                        />
                    </Col>
                    <Col sm={12}>
                        <Nav variant="pills" id={`${componentId}-electricityPills`} className="flex-row electricityPills">
                            {
                                Object.keys(collapseState).map(item => {
                                    return (
                                        <Nav.Item key={`${componentId}-electricityConsumption-${item}-collapsePills`}>
                                            <Nav.Link eventKey={item} active={collapseState[item].parent === "electricityConsumption" && collapseState[item].status === true} disabled={collapseState[item].parent !== "electricityConsumption" && collapseState[item].status === true} aria-controls={`${componentId}-electricityConsumption-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "electricityConsumption", status: !collapseState[item].status } })}>{item === "cng" ? item.toLocaleUpperCase() : `${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="electricityPillsCollapse">
                            {
                                Object.keys(collapseState).map(item => (
                                    <CollapseElectricityConsumptionCustom
                                        key={`${componentId}-electricityConsumption-${item}-collapse`}
                                        collapseControll={getLogic(item, "electricityConsumption")}
                                        label={item}
                                        id={`${componentId}-electricityConsumption`}
                                        inputName={item}
                                        inputValue={getValues(`${componentId}-electricityConsumption-${item}-amount`)}
                                        inputLabel={`Yearly amount of electricity in kWh`}
                                        inputPlaceholder={"0.0"}
                                        primaryCountry={primaryCountry}
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        cFootprint={(val) => calulateTotalCF(val)}
                                    />
                                ))
                            }
                        </div>
                    </Col>
                    {collapseECAssumption}
                </Row>
            </Tab.Container>
        </Tab>
    ),
    [`${componentId}-electricityCost`]: (
        <Tab eventKey={`${componentId}-electricityCost`} title="Electricity Cost" disabled={key !== "" && key !== `${componentId}-electricityCost`} key={`${componentId}-electricityCost`} >
            <Tab.Container>
                <Row>
                    <Col sm={12} style={{ textAlign: "left", paddingTop: "10px" }}>
                        <Controller
                            render={({ onChange, onBlur, value, name }) => (
                                <Select name={name} label={"Country"} formatGroupLabel={formatGroupLabel} closeMenuOnSelect={false} isMulti={true} onChange={(val) => { setUnsetCountry(val, "electricityCost"); return onChange(val) }} onBlur={onBlur} value={selectedCountries} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ width: "auto", flexGrow: 1, marginLeft: "10px", marginRight: "10px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#FFFF0080" singleValueFontSize={"12px"} />
                            )}
                            control={control}
                            name="country2"
                            defaultValue={''}
                        />
                    </Col>
                    <Col sm={12}>
                        <Nav variant="pills" id={`${componentId}-electricityCostPills`} className="flex-row electricityPills">
                            {
                                Object.keys(collapseState).map(item => {
                                    return (
                                        <Nav.Item key={`${componentId}-electricityCost-${item}-collapsePills`}>
                                            <Nav.Link eventKey={item} active={collapseState[item].parent === "electricityCost" && collapseState[item].status === true} disabled={collapseState[item].parent !== "electricityCost" && collapseState[item].status === true} aria-controls={`${componentId}-electricityCost-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "electricityCost", status: !collapseState[item].status } })}>{`${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="electricityPillsCollapse">
                            {
                                Object.keys(collapseState).map(item => (
                                    <CollapseElectricityCostCustom
                                        key={`${componentId}-electricityCost-${item}-collapse`}
                                        collapseControll={getLogic(item, "electricityCost")}
                                        label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                        id={`${componentId}-electricityCost`}
                                        inputName={item}
                                        inputValue={getValues(`${componentId}-electricityCost-${item}-value`)}
                                        inputLabel={`Expected yearly savings in EURO`}
                                        inputPlaceholder={"0.0"}
                                        inputPrice={getValues(`${componentId}-electricityCost-${item}-price`)}
                                        primaryCountry={primaryCountry}
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        cFootprint={(val) => calulateTotalCF(val)}
                                    />
                                ))
                            }
                        </div>
                    </Col>
                    {collapseAssumption}
                </Row>
            </Tab.Container>
        </Tab>
    )
}

return (
    <React.Fragment>
        {
            shouldRender ? (
                <>
                    <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                        <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#FFFF00", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                            <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "10px" }}>
                                <span>Electricity</span>
                                <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Electricity calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={saveData} >Save</Link>
                                <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                            </p>
                        </div>
                        <p style={{ paddingLeft: "10px", textAlign: "left" }}>Please select one of the following data sources you have available </p>
                        <div className="electricityTabs">
                            <Tabs
                                id={`${componentId}-electricityTabs`}
                                activeKey={key}
                                onSelect={(k) => {
                                    if (key === "") {
                                        setKey(k);
                                    }
                                }}
                            >
                                {
                                    Object.keys(electricitySourceTabs).map(tabItem => {
                                        if (tabItem === key) {
                                            return (electricitySourceTabs[tabItem]);
                                        } else {
                                            return false;
                                        }
                                    })
                                }
                                {
                                    Object.keys(electricitySourceTabs).map(tabItem => {
                                        if (tabItem !== key) {
                                            return (electricitySourceTabs[tabItem]);
                                        } else {
                                            return false;
                                        }
                                    })
                                }
                            </Tabs>
                        </div>
                    </div>
                    <div className="electricityBottom" >
                        <div className="content" >
                            <Row>
                                <Col sm={12} md={5} lg={4}>
                                    <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                    <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in electricity consumption</span>
                                </Col>
                                <Col sm={12} md={7} lg={8}>
                                    <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                    <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                    {
                                        (
                                            Object.keys(sources).length > 0 && years.length > 0
                                        ) ? (
                                            <p>
                                                <span>This calculation was done using reference data from</span>
                                                <span style={{ color: "#f0f0f0" }}>
                                                    {
                                                        years.map(year => {
                                                            return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                        })
                                                    }
                                                </span>
                                            </p>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            ) : (<React.Fragment></React.Fragment>)}
    </React.Fragment>
)
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(ElectricityTabs));