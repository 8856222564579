import React, { useState, useEffect, useReducer, useRef } from "react";
import "./Calculator.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { withRouter, useHistory, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Container, Row, Col, CardDeck } from "react-bootstrap";
import { Element } from "react-scroll";
import FuelTabs from "../../components/fuelTabs";
import ElectricityTabs from "../../components/electricityTabs";
import DeliveryTabs from "../../components/deliveryTabs";
import DataCentreTabs from "../../components/dataCentreTabs";
import WaterTabs from "../../components/waterTabs";
import NewDeliveryTabs from "../../components/newDeliveryTabs";
import PaperTabs from "../../components/paperTabs";
import PlasticTabs from "../../components/plasticTabs";
import MetalTabs from "../../components/metalTabs";
import TransportTabs from "../../components/transportTabs";
import ElectricalItemsTabs from "../../components/electricalItemsTabs";
import AviationTabs from "../../components/aviationTabs";
import SeaFreightTabs from "../../components/seaFreightTabs";
import Card from "../../components/card";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import Input from "../../components/input";
import InputTextArea from "../../components/inputTextArea/InputTextAreaCustom";
import Select from "../../components/select/SelectCustom";
import SelectBlack from "../../components/select/SelectBlackEdition";
import SelectOption from "../../components/select/SelectOption";
import ToastCustom from "../../components/toastCustom";
import DatePicker from "../../components/datePicker";
import CheckButton from "../../components/checkButton";
import SummaryComponent from "../../components/summary/Summary";
import { setCurrentProject, unsetCurrentProject, setCurrentPrimaryCountry, setCurrentStartDate, setCurrentEndDate } from "../../store/actions/projectActions/projectAction";
import { deleteConfirm } from "../../components/deleteConfirm";
import shareModal from "../../components/shareModal";
import ReactTooltip from "react-tooltip";
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;
var counter = 0;
var comps2 = {};

const INITIAL_CALCULATOR_STATE = {
    fuel: { cf: 0, status: false },
    electricity: { cf: 0, status: false },
    dataCentre: { cf: 0, status: false },
    water: { cf: 0, status: false },
    delivery: { cf: 0, status: false },
    detailedDelivery: { cf: 0, status: false },
    paper: { cf: 0, status: false },
    plastic: { cf: 0, status: false },
    metal: { cf: 0, status: false },
    transport: { cf: 0, status: false },
    electricalItems: { cf: 0, status: false },
    aviation: { cf: 0, status: false },
    seaFreight: { cf: 0, status: false }
};

const calculatorReducer = (state, action) => {
    switch (action.type) {
        case "CALCULATOR_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "CALCULATOR_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

const initialProjectDetails = {
    projectName: "",
    projectDescription: "",
    thorId: "",
    clientName: "",
    country: "",
    deliveryline: "",
    sector: "",
    salesstage: "",
    testProject: "",
    typeOfEngagement: "",
    activity: [],
    startdate: "",
    enddate: "",
    noOfYears: ""
}

const isNum = (val) => {
    return /^\d+$/.test(val);
}

const pad = (n) => {
    return (n < 10) ? ("0" + n) : n;
}

const timeDiffCalc = (dateFuture, dateNow, str) => {
    dateFuture = new Date(dateFuture);
    dateNow = new Date(dateNow);
    var msec = dateFuture - dateNow;
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    var days = Math.floor(hrs / 24);
    var yrs = Math.floor(days / 365);
    if (yrs !== 0) return `${yrs} ${yrs === 1 ? "year ago" : "years ago"}`;
    if (days !== 0) return `${days} ${days === 1 ? "day ago" : "days ago"}`;
    if (hrs !== 0) return `${hrs} ${hrs === 1 ? "hour ago" : "hours ago"}`;
    if (mins !== 0) return `${mins} ${mins === 1 ? "minute ago" : "minutes ago"}`;
    return `just now`;
}

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

var projectLastUpdated = null;

const noOfYearList = [
    {label: '1 Year', value: '1'},
    {label: '2 Years', value: '2'},
    {label: '3 Years', value: '3'},
    {label: '4 Years', value: '4'},
    {label: '5 Years', value: '5'},
    {label: '6 Years', value: '6'},
    {label: '7 Years', value: '7'},
    {label: '8 Years', value: '8'},
    {label: '9 Years', value: '9'},
    {label: '10 Years', value: '10'}
];

const Calculator = (props) => {
    var projectId = props.project.currentProjectId;
    const isMountedRef = useIsMountedRef();
    const [calculators, setCalculators] = useState({});
    const [calculatorState, dispatchCalculator] = useReducer(calculatorReducer, INITIAL_CALCULATOR_STATE);
    const [comps, setComps] = useState({});
    const [project, setProject] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [createdBy, setCreatedBy] = useState({});
    const [updatedBy, setUpdatedBy] = useState({});
    const [noOfYearData, setNoOfYearData] = useState(noOfYearList);
    const [defaultNoOfYear, setDefaultNoOfYear] = useState(noOfYearList[0]);


    // PROJECT DETAILS
    const [showToast, setShowToast] = useState({ state: false, header: "", body: "" });
    const [isDataSaved, setIsDataSaved] = useState(false);
    const [masterData, setMasterData] = useState({});
    const { handleSubmit, register, errors, setValue, control, getValues, formState } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: initialProjectDetails
    });
    const [thorId, setThorId] = useState(getValues("thorId"));

    let date = new Date();
    let year = date.getFullYear();
    let month = date.toLocaleString('default', { month: 'short' });
    let day = date.toLocaleString('default', { weekday: 'short' });
    // let hour = date.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
    let hour = date.getHours();
    let dateVal = date.getDate();
    let minutes = date.getMinutes();
    let secconds = date.getSeconds();
    let timeStamp = day + '-' + month + '-' + dateVal + '-' + year + ':' + hour + ':' + minutes;
	let { pid } = useParams();
    const history = useHistory();
    const location = useLocation();

    const apiData = () => {
        if(project){
            pid = location.state?.pid;
        } 
    }

    var projectId = location.state?.pid ? location.state?.pid : props.project.currentProjectId;
    
    const handleChange = (isMultiSelect, parentKey, childKey) => {
        if (isMultiSelect === false) {
            let resetStateData = {};
            if (parentKey === "typeOfEngagement") {
                let newActivityData = {};
                for (const key in masterData[parentKey]) {
                    if (key === childKey) {
                        newActivityData = masterData[parentKey][key]["activities"];
                    }
                    resetStateData = { ...resetStateData, [key]: { ...masterData[parentKey][key], state: (key === childKey) } }
                }
                setMasterData((prevValue) => {
                    return {
                        ...prevValue,
                        [parentKey]: resetStateData,
                        activity: newActivityData
                    };
                });
                setValue(parentKey, childKey);
                setValue("activity", []);
            }
        } else {
            setMasterData((prevValue) => {
                var modifiedState = {
                    ...prevValue,
                    [parentKey]: {
                        ...prevValue[parentKey],
                        [childKey]: { ...prevValue[parentKey][childKey], state: !prevValue[parentKey][childKey].state },
                    }
                };
                let selectedActivities = [];
                if (parentKey === "activity") {
                    for (const aKey in modifiedState[parentKey]) {
                        if (modifiedState[parentKey][aKey].state === true) {
                            selectedActivities.push({ name: modifiedState[parentKey][aKey].name });
                        }
                    }
                    setValue(parentKey, selectedActivities);
                }
                return modifiedState;
            });
        }
    };

    const handleThorChange = (val) => {
        if (isNum(val)) {
            if (val.length < 9) {
                setValue("thorId", val);
                setThorId(val);
            }
        } else if (val === "") {
            setValue("thorId", "");
            setThorId("");
        } else {
            setValue("thorId", thorId === undefined ? "" : thorId);
            setThorId(thorId === undefined ? "" : thorId);
        }
    }

    const filterDeliveryLine = (inputValue) => {
        return masterData.deliveryLine.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getDeliveryLineOptionsData = (inputValue, callback) => {
        callback(filterDeliveryLine(inputValue));
    }

    const filterSalesStage = (inputValue) => {
        return masterData.salesStage.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const filterTestProject = (inputValue) => {
        return masterData.testProjectOptions.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getSalesStageOptionsData = (inputValue, callback) => {
        callback(filterSalesStage(inputValue));
    }

    const getTestProjectOptionsData = (inputValue, callback) => {
        callback(filterTestProject(inputValue))
    }

    const filterSector = (inputValue) => {
        return masterData.sector.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getSectorOptionsData = (inputValue, callback) => {
        callback(filterSector(inputValue));
    }

    const filterCountry = (inputValue) => {
        return masterData.country.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getCountryOptionsData = (inputValue, callback) => {
        callback(filterCountry(inputValue));
    }

    const filterTypeOfEngagement = (inputValue) => {
        return masterData.typeOfEngagement.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getTypeOfEngagementOptionsData = (inputValue, callback) => {
        callback(filterTypeOfEngagement(inputValue));
    }

    const getActivities = (val) => {
        let newActivityData = {};
        for (const item of masterData["typeOfEngagement"]) {
            if (item.value === val.value) {
                newActivityData = item["activities"];
                break;
            }
        }
        setMasterData((prevValue) => {
            return {
                ...prevValue,
                activity: newActivityData
            };
        });
        setValue("activity", []);
    }

    const filterNoOfYears = (inputValue) => {
        return masterData.noOfYears.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const getNoOfYearsData = (inputValue, callback) => {
        callback(filterNoOfYears(inputValue));
    }


    const saveProjectData = (data) => {
        if (data !== undefined) {//  && getValues("typeOfEngagement") !== undefined && getValues("typeOfEngagement") !== "" && getValues("activity") !== undefined && getValues("activity").length !== 0
            if (projectId === undefined || projectId === "") {
                let jsonData = {
                    name: data.projectName,
                    description: data.projectDescription,
                    clientName: data.clientName,
                    thorId: data.thorId,
                    startDate: `${new Date(data.startdate).getFullYear()}-${pad(new Date(data.startdate).getMonth() + 1)}`,
                    endDate: `${new Date(data.enddate).getFullYear()}-${pad(new Date(data.enddate).getMonth() + 1)}`,
                    country: {
                        name: data.country.value
                    },
                    salesStage: {
                        name: data.salesstage.value
                    },
                    testProject: data.testProject.value,
                    sector: {
                        name: data.sector.value
                    },
                    deliveryLine: {
                        name: data.deliveryline.value
                    },
                    typeOfEngagement: {
                        name: data.typeOfEngagement.value
                    },
                    activity: data.activity,
                    noOfYears: data?.noOfYears?.value ? Number(data.noOfYears.value) : 1
                };
                axios.post(`${basePath}/project`, jsonData, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        projectId = res.data[0].id;
                        let startDate = res.data[0].startDate;
                        let endDate = res.data[0].endDate;
                        let primaryCountry = res.data[0].country.name;
                        if (isMountedRef.current) {
                            props.setCP({ projectId, primaryCountry, startDate, endDate });
                            setCreatedBy({
                                ...res.data[0].firstCreatedBy,
                                createdAt: `${new Date(res.data[0].createdAt).toLocaleString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })}`
                            });
                            setRefresh(prev => ++prev);
                            setShowToast({ state: true, header: "Success", body: "Project data saved!" });
                            setIsDataSaved(true);
                        }
                    })
                    .catch(error => {
                        let thorError = error.response.data.error;
                        if (isMountedRef.current) {
                            if ((typeof (thorError) !== 'object') && (thorError.includes("THOR ID already exist - created by"))) {
                                setShowToast({ state: true, header: "Alert", body: `${thorError}` });
                                setIsDataSaved(false);
                            } else {
                                setShowToast({ state: true, header: "Error", body: "Server Error!" });
                                setIsDataSaved(false);
                            }
                        }
                    });
            } else {
                let jsonData = {
                    id: projectId,
                    name: data.projectName,
                    description: data.projectDescription,
                    clientName: data.clientName,
                    thorId: data.thorId,
                    startDate: `${new Date(data.startdate).getFullYear()}-${pad(new Date(data.startdate).getMonth() + 1)}`,
                    endDate: `${new Date(data.enddate).getFullYear()}-${pad(new Date(data.enddate).getMonth() + 1)}`,
                    country: {
                        name: data.country.value
                    },
                    salesStage: {
                        name: data.salesstage.value
                    },
                    testProject: data.testProject.value,
                    sector: {
                        name: data.sector.value
                    },
                    deliveryLine: {
                        name: data.deliveryline.value
                    },
                    typeOfEngagement: {
                        name: data.typeOfEngagement.value
                    },
                    activity: data.activity,
                    noOfYears: data?.noOfYears?.value ? Number(data.noOfYears.value) : 1
                };
                axios.put(`${basePath}/project/${projectId}`, jsonData, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.setCurrentPrimaryCountry(data.country.value);
                            props.setCurrentStartDate(res.data[0].startDate);
                            props.setCurrentEndDate(res.data[0].endDate);
                            setRefresh(prev => ++prev);
                            setShowToast({ state: true, header: "Success", body: "Project data saved!" });
                            setIsDataSaved(true);
                        }
                    })
                    .catch(error => {
                        let thorError = error.response.data.error;
                        if (isMountedRef.current) {
                            if ((typeof (thorError) !== 'object') && (thorError.includes("THOR ID already exist - created by"))) {
                                setShowToast({ state: true, header: "Alert", body: `${thorError}` });
                                setIsDataSaved(false);
                            } else {
                                setShowToast({ state: true, header: "Error", body: "Server Error!" });
                                setIsDataSaved(false);
                            }
                        }
                    });
            }
        }
    }

    const deleteProject = () => {
        axios.delete(`${basePath}/project/${projectId}`, {
            headers: {
                authorization: `Bearer ${props.login.token}`
            }
        })
            .then(res => {
                if (isMountedRef.current) {
                    setShowToast({ state: true, header: "Info", body: "Project Deleted!" });
                    setIsDataSaved(true);
                    setTimeout(() => {
                        props.unsetCP();
                        props.history.push("/projects");
                    }, 1000);
                }
            })
            .catch(err => {
                if (isMountedRef.current) {
                    setShowToast({ state: true, header: "Error", body: "Server Error!" });
                    setIsDataSaved(false);
                }
            });
    }
    //END

    const addFuelTabsComponent = (data) => {
        if (calculatorState["fuel"].status === false) {
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "fuel", status: true } });
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-fuel`]: {
                        name: "fuel", data: (
                            <Element name="scrollFuel" key={`scroll-${id}`} className="element" >
                                <FuelTabs key={id} id={`${id}-fuel`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
        }
    }

    const addElectricityTabsComponent = (data) => {
        if (calculatorState["electricity"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-electricity`]: {
                        name: "electricity", data: (
                            <Element name="scrollElectricity" key={`scroll-${id}`} className="element" >
                                <ElectricityTabs key={id} id={`${id}-electricity`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "electricity", status: true } });
        }
    }

    const addDeliveryTabsComponent = (data) => {
        if (calculatorState["delivery"].status === false && calculatorState["detailedDelivery"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-delivery`]: {
                        name: "delivery", data: (
                            <Element name="scrollDelivery" key={`scroll-${id}`} className="element" >
                                <DeliveryTabs key={id} id={`${id}-delivery`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "delivery", status: true } });
        }
    }

    const addDataCentreTabsComponent = (data) => {
        if (calculatorState["dataCentre"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-dataCentre`]: {
                        name: "dataCentre", data: (
                            <Element name="scrollDataCentre" key={`scroll-${id}`} className="element" >
                                <DataCentreTabs key={id} id={`${id}-dataCentre`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "dataCentre", status: true } });
        }
    }

    const addWaterTabsComponent = (data) => {
        if (calculatorState["water"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-water`]: {
                        name: "water", data: (
                            <Element name="scrollWater" key={`scroll-${id}`} className="element" >
                                <WaterTabs key={id} id={`${id}-water`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            if (typeof dataAssumption !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "water", status: true } });
        }
    }

    const addDetailedDeliveryTabsComponent = (data) => {
        if (calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-detailedDelivery`]: {
                        name: "detailedDelivery", data: (
                            <Element name="scrollDetailedDelivery" key={`scroll-${id}`} className="element" >
                                <NewDeliveryTabs key={id} id={`${id}-detailedDelivery`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "detailedDelivery", status: true } });
        }
    }

    const addPaperTabsComponent = (data) => {
        if (calculatorState["paper"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-paper`]: {
                        name: "paper", data: (
                            <Element name="scrollPaper" key={`scroll-${id}`} className="element" >
                                <PaperTabs key={id} id={`${id}-paper`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"} onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)} toastMessage={({ state, header, body }) => setShowToast({ state, header, body })}
                                />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "paper", status: true } });
        }
    }

    const addPlasticTabsComponent = (data) => {
        if (calculatorState["plastic"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-plastic`]: {
                        name: "plastic", data: (
                            <Element name="scrollPlastic" key={`scroll-${id}`} className="element" >
                                <PlasticTabs key={id} id={`${id}-plastic`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"}
                                    onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)}
                                    toastMessage={({ state, header, body }) => setShowToast({ state, header, body })} />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "plastic", status: true } });
        }
    }

    const addMetalTabsComponent = (data) => {
        if (calculatorState["metal"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-metal`]: {
                        name: "metal", data: (
                            <Element name="scrollMetal" key={`scroll-${id}`} className="element" >
                                <MetalTabs key={id} id={`${id}-metal`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"}
                                    onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)}
                                    toastMessage={({ state, header, body }) => setShowToast({ state, header, body })}
                                />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "metal", status: true } });
        }
    }

    const addTransportTabsComponent = (data) => {
        if (calculatorState["transport"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-transport`]: {
                        name: "transport", data: (
                            <Element name="scrollTransport" key={`scroll-${id}`} className="element" >
                                <TransportTabs key={id} id={`${id}-transport`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"}
                                    onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)}
                                    toastMessage={({ state, header, body }) => setShowToast({ state, header, body })}
                                />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "transport", status: true } });
        }
    }
    const addElectricalItemsTabsComponent = (data) => {
        if (calculatorState["electricalItems"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-electricalItems`]: {
                        name: "electricalItems", data: (
                            <Element name="scrollElectricalItems" key={`scroll-${id}`} className="element" >
                                <ElectricalItemsTabs key={id} id={`${id}-electricalItems`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"}
                                    onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)}
                                    toastMessage={({ state, header, body }) => setShowToast({ state, header, body })}
                                />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "electricalItems", status: true } });
        }
    }

    const addAviationTabsComponent = (data) => {
        if (calculatorState["aviation"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-aviation`]: {
                        name: "aviation", data: (
                            <Element name="scrollAviation" key={`scroll-${id}`} className="element" >
                                <AviationTabs key={id} id={`${id}-aviation`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"}
                                    onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)}
                                    toastMessage={({ state, header, body }) => setShowToast({ state, header, body })}
                                />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "aviation", status: true } });
        }
    }

    const addSeaFreightTabsComponent = (data) => {
        if (calculatorState["seaFreight"].status === false) {
            let id = new Date().toISOString();
            id = id.split(" ").join("").split(":").join("").split(".").join("");
            if (isMountedRef.current) {
                comps2 = {
                    ...comps2,
                    [`${id}-seaFreight`]: {
                        name: "seaFreight", data: (
                            <Element name="scrollSeaFreight" key={`scroll-${id}`} className="element" >
                                <SeaFreightTabs key={id} id={`${id}-seaFreight`} data={data} projectId={projectId} type={data === undefined ? "new" : "old"}
                                    onCFUpdate={(val) => onCFUpdate(val)} onDelete={(val) => deleteComp(val)} calculateSummary={() => setRefresh(prev => ++prev)}
                                    toastMessage={({ state, header, body }) => setShowToast({ state, header, body })}
                                />
                            </Element>
                        )
                    }
                }
            }
            if (typeof data !== "object") {
                if (isMountedRef.current) {
                    setIsDataLoaded(true);
                }
            }
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: "seaFreight", status: true } });
        }
    }

    const deleteComp = (key) => {
        delete comps2[key];
        if (isMountedRef.current) {
            dispatchCalculator({ type: "CALCULATOR_SET_STATUS", payload: { name: key.split("-")[(key.split("-").length - 1)], status: false } });
            setIsDataLoaded(true);
        }
    }

    const onCFUpdate = (val) => {
        if (isMountedRef.current) {
            dispatchCalculator({ type: "CALCULATOR_SET_CF", payload: { name: (val.name).split("-")[((val.name).split("-").length - 1)], cf: val.cf } });
        }
    }

    useEffect(() => {
        if (isMountedRef.current) {
            comps2 = {};
            counter = 0;
        }
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                if (Object.keys(masterData).length === 0) {
                    window.scrollTo(0, 0);
                    var data = {}
                    data["deliveryLine"] = props.master.deliveryLine;
                    data["salesStage"] = props.master.salesStage;
                    data["sector"] = props.master.sector;
                    data["country"] = props.master.country;
                    data["typeOfEngagement"] = {};
                    for (const item of props.master.typeOfEngagement) {
                        data["typeOfEngagement"] = { ...data["typeOfEngagement"], [item.label]: { name: item.label, state: false, activities: {} } };
                        for (const activity of item["activities"]) {
                            data["typeOfEngagement"][item.label]["activities"] = { ...data["typeOfEngagement"][item.label]["activities"], [activity.name]: { name: activity.name, state: false, calculators: activity.calculators } };
                        }
                    }
                    var temp = [];
                    for (const k in data["typeOfEngagement"]) {
                        temp.push({ value: data["typeOfEngagement"][k].name, label: data["typeOfEngagement"][k].name, state: data["typeOfEngagement"][k].state, activities: data["typeOfEngagement"][k].activities })
                    }
                    data["typeOfEngagement"] = temp;
                    data["testProjectOptions"] = props.master.testProjectOptions;
                    data["activity"] = {};
                    data["noOfYears"] = [
                        {label: '1 Year', value: '1'},
                        {label: '2 Years', value: '2'},
                        {label: '3 Years', value: '3'},
                        {label: '4 Years', value: '4'},
                        {label: '5 Years', value: '5'},
                        {label: '6 Years', value: '6'},
                        {label: '7 Years', value: '7'},
                        {label: '8 Years', value: '8'},
                        {label: '9 Years', value: '9'},
                        {label: '10 Years', value: '10'}
                    ];
                    setMasterData(data);
                }
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if ((props.login.token !== "" && projectId !== "" && projectId !== undefined && Object.keys(calculators).length === 0)) {
                axios.get(`${basePath}/project/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            setProject(res.data[0]);
                            let dnoOfYears = noOfYearData.find(e=>e.value == res?.data[0]?.noOfYears);
                            setDefaultNoOfYear(dnoOfYears)
                            setValue("noOfYears", dnoOfYears);
                        }
                    })
                    .catch(err => {

                    });
                axios.get(`${basePath}/calculator/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            setCalculators(res.data);
                        }
                    })
                    .catch(err => {

                    });
            }
        }
        // eslint-disable-next-line
    }, [props.login.token, projectId, isMountedRef]);

    const loopCalculators = () => {
        if (isMountedRef.current) {
            setTimeout(() => {
                if (counter < calculators.length && calculators[counter].name === "fuel" && calculators[counter].data.length > 0) {
                    addFuelTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === "electricity" && calculators[counter].data.length > 0) {
                    addElectricityTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === "dataCentre" && calculators[counter].data.length > 0) {
                    addDataCentreTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === "water" && calculators[counter].data.length > 0) {
                    addWaterTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === "people" && calculators[counter].data.length > 0) {
                    addDeliveryTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === "detailedDelivery" && calculators[counter].data.length > 0) {
                    addDetailedDeliveryTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'paper' && calculators[counter].data.length > 0) {
                    addPaperTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'plastic' && calculators[counter].data.length > 0) {
                    addPlasticTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'metal' && calculators[counter].data.length > 0) {
                    addMetalTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'transport' && calculators[counter].data.length > 0) {
                    addTransportTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'electricalItems' && calculators[counter].data.length > 0) {
                    addElectricalItemsTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'aviation' && calculators[counter].data.length > 0) {
                    addAviationTabsComponent(calculators[counter]);
                }
                else if (counter < calculators.length && calculators[counter].name === 'seaFreight' && calculators[counter].data.length > 0) {
                    addSeaFreightTabsComponent(calculators[counter]);
                }
                counter = counter + 1;
                if (counter < calculators.length) {
                    loopCalculators();
                } else {
                    setIsDataLoaded(true);
                }
            }, 50);
        }
    }

    useEffect(() => {
        if (isMountedRef.current) {
            if (calculators.length > 0 && Object.keys(comps2).length === 0) {// edit case
                loopCalculators();
            } else if (props.location !== undefined && props.location.state !== undefined && props.location.state.calculatorType !== undefined) {
                if (props.location.state.calculatorType === "fuel") {
                    addFuelTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "electricity") {
                    addElectricityTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "delivery") {
                    addDeliveryTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "dataCentre") {
                    addDataCentreTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "water") {
                    addWaterTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "detailedDelivery") {
                    addDetailedDeliveryTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "paper") {
                    addPaperTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "plastic") {
                    addPlasticTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "metal") {
                    addMetalTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "transport") {
                    addTransportTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "electricalItems") {
                    addElectricalItemsTabsComponent(undefined);
                } else if (props.location.state.calculatorType === "aviation") {
                    addAviationTabsComponent(undefined);
                }  else if (props.location.state.calculatorType === "seaFreight") {
                    addSeaFreightTabsComponent(undefined);
                }
            }
        }
        // eslint-disable-next-line
    }, [calculators, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataLoaded === true) {
                setComps(comps2);
                setIsDataLoaded(false);
            }
        }
    }, [isDataLoaded, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (project !== undefined && Object.keys(project).length > 0) {
                let toe = {};
                for (const item of masterData["typeOfEngagement"]) {
                    if (item.value === project.typeOfEngagement?.name) {
                        toe = item;
                        break;
                    }
                }
                setValue("typeOfEngagement", toe);
                var act = toe["activities"];
                let selectedActivities = [];
                for (const i of project.activities) {
                    for (const j in toe["activities"]) {
                        if (j === i.name) {
                            act[j]["state"] = true;
                            selectedActivities.push({ name: j });
                        }
                    }
                }
                setMasterData((prevValue) => {
                    return {
                        ...prevValue,
                        activity: act
                    };
                });
                setValue("activity", selectedActivities);
                setValue("projectName", project.name);
                setValue("projectDescription", project.description);
                setValue("startdate", new Date(project.startDate));
                setValue("enddate", new Date(project.endDate));

                let tp = {};
                for (const item of masterData["testProjectOptions"]) {
                    if (item.value === project.testProject) {
                        tp = item;
                        break;
                    }
                }
                setValue("testProject", tp);
                let dl = {};
                for (const item of masterData["deliveryLine"]) {
                    if (item.value === project.deliveryLine.name) {
                        dl = item;
                        break;
                    }
                }
                setValue("deliveryline", dl);
                let se = {};
                for (const item of masterData["sector"]) {
                    if (item.value === project.sector.name) {
                        se = item;
                        break;
                    }
                }
                setValue("sector", se);
                setValue("clientName", project.clientName);
                let c = {};
                for (const item of masterData["country"]) {
                    if (item.value === project.country.name) {
                        c = item;
                        break;
                    }
                }
                setValue("country", c);
                let ss = {};
                for (const item of masterData["salesStage"]) {
                    if (item.value === project.salesStage.name) {
                        ss = item;
                        break;
                    }
                }
                setValue("salesstage", ss);
                setValue("thorId", project.thorId);
                setCreatedBy({
                    ...project.firstCreatedBy,
                    createdAt: `${new Date(project.createdAt).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                    })}`
                });
                setUpdatedBy({
                    ...project.lastModifiedBy,
                    lastModifiedAt: timeDiffCalc(new Date(), project.updatedAt)
                });
                projectLastUpdated = setInterval(() => {
                    if (isMountedRef.current) {
                        setUpdatedBy(prev => ({
                            ...prev,
                            lastModifiedAt: timeDiffCalc(new Date(), project.updatedAt)
                        }));
                    }
                }, 1000);
            }
        }
        // eslint-disable-next-line
    }, [project, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh !== 0) {
                clearInterval(projectLastUpdated);
                let modifiedTime = new Date();
                setUpdatedBy(prev => ({
                    ...prev,
                    email: props.login.email,
                    username: props.login.username,
                    lastModifiedAt: timeDiffCalc(new Date(), modifiedTime)
                }));
                projectLastUpdated = setInterval(() => {
                    if (isMountedRef.current) {
                        setUpdatedBy(prev => ({
                            ...prev,
                            lastModifiedAt: timeDiffCalc(new Date(), modifiedTime)
                        }));
                    }
                }, 1000);
            }
        }
    }, [refresh, isMountedRef]);

    const typeofeng = (masterData) => {
        if (masterData) {
            delete masterData.activity.Digital
        }
        return true
    }

    return (
        <>
            {
                (props.login.isValidUser || projectId != null) ? (

                    <Container style={{ width: "90%" }}>
                        <>
                            {
                                Object.keys(masterData).length > 0 ? (
                                    <Container style={{ justifyContent: "center" }}>
                                        <Row>
                                            <Col xs={{ span: 12, order: "last" }} sm={{ span: 12, order: "last" }} md={{ span: 12, order: "last" }} lg={{ span: 8, order: "first" }}>
                                                <Row>
                                                    <Col sm={12} md={8} lg={8} style={{ textAlign: "left" }}>
                                                        <Input id={`projectName`} name={`projectName`} label={"Project Name"} placeholder={`Project Name...`} autoComplete={"off"} register={register({ required: "Required" })} errors={errors} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "43px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "32px", lineHeight: "36px", color: "rgba(255, 255, 255, 0.87)" }} />
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4} style={{ textAlign: "left" }}>
                                                        <Input className="numberType" type="text" id={`thorId`} name={`thorId`} label={"THOR ID"} autoComplete={"off"} placeholder={`THOR ID...`} value={thorId} maxLength={8} onChange={e => handleThorChange(e.target.value)} register={register({ maxLength: 8 })} errors={errors} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "43px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", lineHeight: "36px", color: "rgba(255, 255, 255, 0.87)" }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                                                        <InputTextArea rows={"3"} id={`projectDescription`} type="textarea" name={`projectDescription`} placeholder={`Project Description...`} register={register({ required: "Required" })} errors={errors} autoComplete={"off"} label={"Project Description"} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, borderRadius: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", lineHeight: "18px", color: "rgba(255, 255, 255, 0.87)" }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={{ span: 12, order: "first" }} sm={{ span: 12, order: "first" }} md={{ span: 12, order: "first" }} lg={{ span: 4, order: "last" }}>
                                                <Row style={{ justifyContent: "flex-end" }}>
                                                    <Col xs={{ span: 12, order: "last" }} sm={{ span: 5, order: "last" }} md={{ span: 4, order: "last", offset: 2 }} lg={{ span: 12, order: "first" }} style={{ height: "84px" }}>
                                                        <Row style={{ textAlign: "center", padding: "20px", justifyContent: "flex-end", flexWrap: "nowrap", height: "100%", alignItems: "center" }}>
                                                            <Col sm={4} md={4} lg={4}><Link className={"calculatorActionSave"} to="/calculator" onClick={handleSubmit((data) => saveProjectData(data))} >Save</Link></Col>
                                                            <Col sm={4} md={4} lg={4} style={{ paddingLeft: "10px" }}><Link className={"calculatorActionDelete"} to="/calculator" onClick={() => deleteConfirm("Do you want to delete the project ?", "Delete", "Cancel", deleteProject)}>Delete</Link></Col>
                                                            <Col sm={4} md={4} lg={4}><span className={"calculatorActionShare"} onClick={() => shareModal()} >Share</span></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={{ span: 12, order: "first" }} sm={{ span: 7, order: "first" }} md={{ span: 6, order: "first" }} lg={{ span: 12, order: "last" }}>
                                                        {
                                                            Object.keys(createdBy).length > 0 && Object.keys(updatedBy).length > 0 && (
                                                                <div style={{ borderWidth: "1px", borderStyle: "dashed", borderColor: "rgba(255, 255, 255, 0.87)", width: "-webkit-fill-available", height: "92px", marginTop: "3px", marginBottom: "20px", borderRadius: "15px", display: "flex", flexDirection: "column", justifyContent: "space-around", padding: "10px 20px", fontWeight: 300, fontSize: "12px", color: "rgb(162, 162, 162)" }}>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}>
                                                                        <span>Project-ID: </span>
                                                                        <div style={{ fontWeight: "700", display: "flex", justifyContent: "space-between", width: "100px" }}>
                                                                            <span>CCIC - {projectId}</span>
                                                                        </div>
                                                                    </div>
                                                                    {project.e3DId !== null ?
                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}>
                                                                            <span>e3D-ID: </span>
                                                                            <div style={{ fontWeight: "700", display: "flex", justifyContent: "space-between", width: "100px" }}>
                                                                                <span>{project.e3DId}</span>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}>
                                                                        <span>Created By : </span>
                                                                        <a href={`mailto:${createdBy.email}`} style={{ letterSpacing: "1px" }} data-tip data-for="createdByTooltip">{createdBy.username}</a>
                                                                        <ReactTooltip id="createdByTooltip" className='customCalculatorTooltip' place="top" offset={{ top: 20 }} arrowColor="#000000">
                                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "200px" }}>
                                                                                <span>Created on : </span>
                                                                                <span>{createdBy.createdAt}</span>
                                                                            </div>
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}>
                                                                        <span>Last Modified By : </span>
                                                                        <a href={`mailto:${updatedBy.email}`} style={{ letterSpacing: "1px" }} data-tip data-for="updatedByTooltip">{updatedBy.username}</a>
                                                                        <ReactTooltip id="updatedByTooltip" className='customCalculatorTooltip' place="top" offset={{ top: 20 }} arrowColor="#000000">
                                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "200px" }}>
                                                                                <span>Last modified : </span>
                                                                                <span>{updatedBy.lastModifiedAt}</span>
                                                                            </div>
                                                                        </ReactTooltip>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={3} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <DatePicker
                                                            id={`startDate`}
                                                            selected={value === undefined || value === "" ? null : value}
                                                            onChange={onChange}
                                                            dateFormat="MM/yyyy"
                                                            maxDate={getValues("enddate") || null}
                                                            showMonthYearPicker
                                                            showFullMonthYearPicker
                                                            showFourColumnMonthYearPicker
                                                            focusSelectedMonth
                                                            errors={errors}
                                                            noBorder={true}
                                                            name={name} label={"Start Date"} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }}
                                                        />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'Start date cannot be null!' }}
                                                    name="startdate"
                                                />
                                            </Col>
                                            <Col sm={12} md={3} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <DatePicker
                                                            id={`enddate`}
                                                            selected={value === undefined || value === "" ? null : value}
                                                            onChange={onChange}
                                                            dateFormat="MM/yyyy"
                                                            minDate={getValues("startdate") || null}
                                                            showMonthYearPicker
                                                            showFullMonthYearPicker
                                                            showFourColumnMonthYearPicker
                                                            focusSelectedMonth
                                                            errors={errors}
                                                            noBorder={true}
                                                            name={name} label={"End Date"} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }}
                                                        />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'End date cannot be null!' }}
                                                    name="enddate"
                                                />
                                            </Col>
                                            <Col sm={12} md={3} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <Select name={name} label={"Lead delivery line"} onChange={onChange} onBlur={onBlur} value={value} errors={errors} loadOptions={getDeliveryLineOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'Delivery Line cannot be null!' }}
                                                    name="deliveryline"
                                                />
                                            </Col>
                                            <Col sm={12} md={3} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <Select name={name} label={"Sector"} onChange={onChange} onBlur={onBlur} value={value} errors={errors} loadOptions={getSectorOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'Sector cannot be null!' }}
                                                    name="sector"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={4} lg={3} style={{ textAlign: "left" }}>
                                                <Input id={`clientName`} name={`clientName`} placeholder={`Client Name...`} autoComplete={"off"} register={register({ required: "Required" })} errors={errors} label={"Client Name"} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "38px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", lineHeight: "36px", color: "rgba(255, 255, 255, 0.87)" }} />
                                            </Col>
                                            <Col sm={12} md={4} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <Select name={name} label={"Primary country of client"} onChange={onChange} onBlur={onBlur} value={value} errors={errors} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'Country cannot be null!' }}
                                                    name="country"
                                                />
                                            </Col>
                                            <Col sm={12} md={4} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <Select name={name} label={"Sales Stage"} onChange={onChange} onBlur={onBlur} value={value} errors={errors} loadOptions={getSalesStageOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'Sales Stage cannot be null!' }}
                                                    name="salesstage"
                                                />
                                            </Col>
                                            <Col sm={12} md={4} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <Select name={name} label={"Test Project"} onChange={onChange} onBlur={onBlur} value={value} errors={errors} loadOptions={getTestProjectOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ flexGrow: 1, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", borderRadius: 0 }} style={{ borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" tooltip={true} tooltipMessage="If you are creating a test project with made up data please select YES to ensure this data is not reported on. If this calculation is for a actual project and you are using actual project data please select NO" />
                                                    )}
                                                    control={control}
                                                    rules={{ required: 'Select Project Type!' }}
                                                    name="testProject"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={4} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <SelectBlack name={name} label={"Type of Engagement"}
                                                            onChange={(val) => {
                                                                onChange(val);
                                                                getActivities(val);
                                                            }}
                                                            onBlur={onBlur} value={value} errors={errors} loadOptions={getTypeOfEngagementOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", marginBottom: "0px", color: "#a2a2a2" }} formGroupStyle={{ width: "-webkit-fill-available", float: "left", flexGrow: 1, background: "transparent", borderRadius: 0 }} style={{ borderWidth: "1px", borderRadius: 0, borderColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }} onClickColor="#525252" />
                                                    )}
                                                    control={control}
                                                    name="typeOfEngagement"
                                                    onChange={(val) => getActivities(val)}
                                                />
                                            </Col>
                                            <Col sm={12} md={4} lg={3} style={{ textAlign: "left" }}>
                                                <Controller
                                                    render={({ onChange, onBlur, value, name }) => (
                                                        <SelectOption name={name} label={"Report (tonnes CO2e) years"}
                                                            onChange={(val) => {
                                                                onChange(val);
                                                            }}
                                                            onBlur={onBlur} value={value} errors={errors}
                                                            loadOptions={getNoOfYearsData}
                                                            defaultOptions={true}
                                                            labelStyle={{ fontWeight: "300", fontSize: "12px", marginBottom: "0px", color: "#a2a2a2" }} 
                                                            formGroupStyle={{ width: "-webkit-fill-available", float: "left", flexGrow: 1, background: "transparent", borderRadius: 0 }} 
                                                            style={{ borderWidth: "1px", borderRadius: 0, borderColor: "white", height: "28px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", fontSize: "16px", color: "rgba(255, 255, 255, 0.87)" }} 
                                                            optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212" }}
                                                            onClickColor="#525252" />
                                                    )}
                                                    defaultValue={defaultNoOfYear}
                                                    control={control}
                                                    name="noOfYears"
                                                />
                                            </Col>
                                            {masterData?.activity &&
                                                Object.keys(masterData.activity).length > 0 ? (
                                                <Col sm={12} md={8} lg={9} style={{ textAlign: "left" }}>
                                                    {typeofeng(masterData) ?
                                                        <CheckButton
                                                            name="activity"
                                                            label="Activities"
                                                            labelStyle={{ color: "#a2a2a2", fontSize: "12px" }}
                                                            handleChange={(isMultiSelect, val) => handleChange(isMultiSelect, "activity", val)}
                                                            options={masterData.activity}
                                                            multiSelect={true}
                                                        />
                                                        : null}
                                                </Col>
                                            ) : (<Col sm={12} md={8} lg={9} style={{ textAlign: "left" }}></Col>)
                                            }
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs={12} sm={12} md={8} lg={9} style={{ textAlign: "left", paddingRight: "0px", zIndex: "0" }}>
                                                <div style={{ fontSize: "10px" }}>
                                                    {
                                                        Object.keys(comps).map(key => {
                                                            return (comps[key].data)
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={3} style={{ textAlign: "left", paddingBottom: "15px", paddingRight: "0px" }}>
                                                <div style={{ padding: "0px", position: "sticky", top: "52px" }}>
                                                    <SummaryComponent projectId={projectId} timeStampVal={timeStamp} reload={refresh} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : (
                                    <div>Loading...</div>
                                )
                            }
                        </>
                        <br />

                        <Row style={{ paddingLeft: 11, display: 'flex', flexDirection: 'row', flexWrap: "wrap", margin: 0, width: 1021 }}>
                            <Col style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: "#0070AD", height: 445, left: -10, margin: '0px' }}>
                                <div style={{ paddingLeft: 3, paddingTop: 10, textAlign: "left" }} ><h5>Capgemini Delivery</h5></div>
                                <p style={{ paddingLeft: 3, fontSize: 12, textAlign: "left", paddingTop: 15 }}>Use these modules to calculate the estimated carbon emissions resulting from Capgemini delivering the project (our teams) </p>
                                <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 23 }}>
                                    <Card key={"detailedDelivery-card"} id={"detailedDelivery-card"} tooltip={!(calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false)} tooltipMessage="Only one delivery calculator can be used per project." style={{ cursor: (calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addDetailedDeliveryTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Detailed Project Impact`} clickable={calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false} />
                                </Col>
                                <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                    <Card key={"poeple-card"} id={"delivery-card"} tooltip={!(calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false)} tooltipMessage="Only one delivery calculator can be used per project." style={{ cursor: (calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addDeliveryTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Simple Delivery Impact`} clickable={calculatorState["detailedDelivery"].status === false && calculatorState["delivery"].status === false} />
                                </Col>
                            </Col>

                            <Row style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: "#FFB24A", height: 720, margin: '0px', width: 730 }}>
                                <div style={{ paddingLeft: 15, paddingTop: 10 }} ><h5>Client Savings</h5></div>
                                <p style={{ paddingLeft: 15, fontSize: 12, textAlign: "left", paddingTop: -10 }}>Use these modules to calculate the estimated carbon savings we can help our clients achieve as a direct result from the projects <br></br>we are delivering.</p>
                                <Col style={{ border: '0px', margin: '0px', paddingLeft: '15px', paddingTop: 24 }}>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 0 }}>
                                        <Card key={"fuel-card"} id={"fuel-card"} style={{ cursor: (calculatorState["fuel"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addFuelTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Fuel`} clickable={calculatorState["fuel"].status === false} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"dataCentre-card"} id={"dataCentre-card"} style={{ cursor: (calculatorState["dataCentre"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addDataCentreTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Data Centre`} clickable={calculatorState["dataCentre"].status === false} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"metal-card"} id={"metal-card"} style={{ cursor: (calculatorState["metal"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} isCalculatorTypeButton={true} calculatorType={`Metal`} clickable={calculatorState["metal"].status === false} onClick={() => addMetalTabsComponent(undefined)} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"aviation-card"} id={"aviation-card"} style={{ cursor: (calculatorState["aviation"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} isCalculatorTypeButton={true} calculatorType={`Aviation`} clickable={calculatorState["aviation"].status === false} onClick={() => addAviationTabsComponent(undefined)} />
                                    </Col>
                                </Col>
                                <Col style={{ border: '0px', marginLeft: '-10px', padding: '0px', paddingTop: 24 }}>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 0 }}>
                                        <Card key={"electricity-card"} id={"electricity-card"} style={{ cursor: (calculatorState["electricity"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addElectricityTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Electricity`} clickable={calculatorState["electricity"].status === false} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"water-card"} id={"water-card"} style={{ cursor: (calculatorState["water"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addWaterTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Water`} clickable={calculatorState["water"].status === false} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"transport-card"} id={"transport-card"} style={{ cursor: (calculatorState["transport"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addTransportTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Transport`} clickable={calculatorState["transport"].status === false} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"seaFreight-card"} id={"seaFreight-card"} style={{ cursor: (calculatorState["seaFreight"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} onClick={() => addSeaFreightTabsComponent(undefined)} isCalculatorTypeButton={true} calculatorType={`Sea Freight`} clickable={calculatorState["seaFreight"].status === false} />
                                    </Col>
                                </Col>
                                <Col style={{ border: '0px', marginLeft: '-10px', paddingRight: '15px', paddingTop: 24 }}>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 0 }}>
                                        <Card key={"paper-c'ard"} id={"paper-card"} style={{ cursor: (calculatorState["paper"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} isCalculatorTypeButton={true} calculatorType={`Paper`} clickable={calculatorState["paper"].status === false} onClick={() => addPaperTabsComponent(undefined)} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"plastic-card"} id={"plastic-card"} style={{ cursor: (calculatorState["plastic"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} isCalculatorTypeButton={true} calculatorType={`Plastic`} clickable={calculatorState["plastic"].status === false} onClick={() => addPlasticTabsComponent(undefined)} />
                                    </Col>
                                    <Col style={{ border: '0px', margin: '0px', padding: '0px', paddingTop: 10 }}>
                                        <Card key={"electricalItems-card"} id={"electricalItems-card"} style={{ cursor: (calculatorState["electricalItems"].status === false) ? "pointer" : "not-allowed", height: "130px", flex: "1 0 300px", border: 0, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", margin: "0.5px" }} plus={false} text={`dark`} data={null} noMargin={true} isCalculatorTypeButton={true} calculatorType={`Electrical Items`} clickable={calculatorState["electricalItems"].status === false} onClick={() => addElectricalItemsTabsComponent(undefined)} />
                                    </Col>
                                </Col>
                            </Row>
                        </Row>

                        <div style={{ margin: 0, textAlign: "initial" }}>
                            <div id="chart1"></div>
                            <div id="chart2"></div>
                            <div id="chart3"></div>
                            <div id="chart4"></div>
                        </div>
                    </Container >
                ) : (<div>Please login to get the calculator details !</div>)
            }
            <ToastCustom variant="bw" onClose={() => setShowToast({ state: false, header: "", body: "" })} show={showToast.state} delay={3000} header={showToast.header} body={showToast.body} autoHide={true} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        project: state.project,
        master: state.master
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentPrimaryCountry: (primaryCountry) => {
            dispatch(setCurrentPrimaryCountry(primaryCountry));
        },
        setCurrentStartDate: (startDate) => {
            dispatch(setCurrentStartDate(startDate));
        },
        setCurrentEndDate: (endDate) => {
            dispatch(setCurrentEndDate(endDate));
        },
        setCP: (data) => {
            dispatch(setCurrentProject(data));
        },
        unsetCP: () => {
            dispatch(unsetCurrentProject());
        }
    }
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps, mapDispatchToProps)(withRouter(Calculator)));