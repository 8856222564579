import { MsalAuthProvider, LoginType } from 'react-aad-msal';

let tenantId = "";
let clientId = "";
const environment = process.env.REACT_APP_API_ENVIRONMENT;

if (environment === "production") {
  tenantId = process.env.REACT_APP_API_AAD_TENANT_ID_PROD;
  clientId = process.env.REACT_APP_API_AAD_CLIENT_ID_PROD;
} else {
  tenantId = process.env.REACT_APP_API_AAD_TENANT_ID_UAT;
  clientId = process.env.REACT_APP_API_AAD_CLIENT_ID_UAT;
}

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/' + tenantId,
    clientId: clientId,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid", "User.Read"]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)