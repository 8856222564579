
const initialState = {
    username: "Guest",
    email: "",
    role: "",
    token: "",
    isValidUser: false,
    sessionExpired: false
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_CREDENTIALS":
            state = {
                ...state,
                username: action.payload.username,
                email: action.payload.email,
                role: action.payload.role,
                isValidUser: action.payload.isValidUser ? action.payload.isValidUser : true,
                token: action.payload.token
            }
            break;
        case "LOGOUT":
            state = initialState;
            break;
        case "SESSION_EXPIRED":
            state = {
                ...initialState,
                sessionExpired: true
            }
            break;
        case "CLEAR_SESSION":
            state = {
                ...initialState,
                sessionExpired: false
            }
            break;
        default:
            break;
    }
    return state;
}

export default loginReducer