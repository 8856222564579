const { isNumeric } = require('./utils.js');

/** 
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for electricity used.
 * @param  {number} amount Amount of electricity used (kWH) [user(text)]
 * @param  {number} generationFactor generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} tndFactor Transmission and distribution (T&D) factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} wttGenerationFactor Well-to-tank (WTT) generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} wttTndFactor WTT-TDD factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */
function footprintForElectricityByAmount(amount, generationFactor, tndFactor = 0, wttGenerationFactor = 0, wttTndFactor = 0) {
    if (!isNumeric(amount)) throw TypeError("'amount' must be numeric")
    if (!isNumeric(generationFactor)) throw TypeError("'generationFactor' must be numeric")
    if (!isNumeric(tndFactor)) throw TypeError("'tndFactor' must be numeric")
    if (!isNumeric(wttGenerationFactor)) throw TypeError("'wttGenerationFactor' must be numeric")
    if (!isNumeric(wttTndFactor)) throw TypeError("'wttTndFactor' must be numeric")
    // if (amount < 0) throw RangeError("'amount' can't be be less than 0")
    if (generationFactor < 0) throw RangeError("'generationFactor' factor can't be less than 0")
    if (tndFactor < 0) throw RangeError("'tndFactor' can't be less than 0")
    if (wttGenerationFactor < 0) throw RangeError("'wttGenerationFactor' can't be less than 0")
    if (wttTndFactor < 0) throw RangeError("'wttTndFactor' can't be less than 0")

    return amount * (
        generationFactor +
        tndFactor +
        wttGenerationFactor +
        wttTndFactor
    )
}

/**
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for electricity used.
 * @param  {number} value Money spent on fuel (euros) [user(text)]
 * @param  {number} price Price of electricity (euros/kWH) [user(text) or ?]
 * @param  {number} generationFactor generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} tndFactor Transmission and distribution (T&D) factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} wttGenerationFactor Well-to-tank (WTT) generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} wttTndFactor WTT-TDD factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)  
 */
function footprintForElectricityByValue(value, price, generationFactor, tndFactor, wttGenerationFactor = 0, wttTndFactor = 0
) {
    if (!isNumeric(value)) throw TypeError("'value' must be numeric")
    if (!isNumeric(price)) throw TypeError("'price' must be numeric")
    // if (value < 0) throw RangeError("'value' can't be less than 0")
    // if (price <= 0) throw RangeError("'price' can't be less or equal to 0")

    return footprintForElectricityByAmount(
        value / price,
        generationFactor,
        tndFactor,
        wttGenerationFactor,
        wttTndFactor
    )
}

/** 
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for electricity used. Aggregates all electricity-to-footprint formulas.
 * @see footprintForElectricityByAmount
 * @see footprintForElectricityByValue
 * @param  {number} generationFactor generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} tndFactor Transmission and distribution (T&D) factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} wttGenerationFactor Well-to-tank (WTT) generation factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @param  {number} wttTndFactor WTT-TDD factor (KgCO<sub>2</sub>e/kWH) [country/type based]
 * @return Carbon footprint (kgCO<sub>2</sub>e)
 */
function footprintForElectricity(amount, value, price,
    generationFactor,
    tndFactor,
    wttGenerationFactor = 0,
    wttTndFactor = 0
) {
    if (amount != null) {
        return footprintForElectricityByAmount(amount,
            generationFactor,
            tndFactor,
            wttGenerationFactor,
            wttTndFactor
        )
    } else if (value != null && price != null) {
        return footprintForElectricityByValue(value, price,
            generationFactor,
            tndFactor,
            wttGenerationFactor,
            wttTndFactor
        )
    } else throw new Error("Not enough data to calculate")
}

module.exports = {
    footprintForElectricityByAmount: footprintForElectricityByAmount,
    footprintForElectricityByValue: footprintForElectricityByValue,
    footprintForElectricity: footprintForElectricity
}