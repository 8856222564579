import React, { useState, useReducer, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./AviationTabs.css";
import CollapseAviationFreightCustom from "./CollapseAviationFreightCustom";
import CollapseAviationPassengerCustom from "./CollapseAviationPassengerCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../../components/errorBoundaries/ErrorBoundaryForPage";
import { deleteConfirm } from "../deleteConfirm";
import AviationAssumption from "./AviationAssumption";
import ReactTooltip from 'react-tooltip';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

let SELECT_NOV_OPTIONS = [{ value: "weekly", label: "Weekly" }, { value: "monthly", label: "Monthly" }, { value: "annually", label: "Annually" }];

const INITIAL_COLLAPSE_STATE = {
    "Shorthaul": { parent: "", cf: {}, status: false },
    "Longhaul": { parent: "", cf: {}, status: false },
    "International": { parent: "", cf: {}, status: false }
};


function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: "", cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            delete state[action.payload.name];
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();


const AviationTabs = (props) => {
    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, INITIAL_COLLAPSE_STATE);
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [aviationType, setAviationType] = useState({});
    const [aviationDistanceType, setAviationDistanceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [clickedSave, setClickedSave] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 1)];
            if (!data["dataSource"]) {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a tab in aviation calculator and fill in the details!" });
                return false;
            }
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "freight") {
                    data["data"] = {
                        ...data["data"], [item]: {
                            status: collapseState[item].status, dataSource: collapseState[item].parent, tonnes: getValues(`${key}-${item}-tonnes`), km: getValues(`${key}-${item}-km`),
                            carbonFootprint: collapseState[item].cf.value, aviationType: collapseState[item].cf.aviationTypeVal,
                            aviationDistanceType: collapseState[item].cf.aviationDistanceTypeVal, year: collapseState[item].cf.year
                        }
                    }
                } else if (collapseState[item].parent === "passenger") {
                    data["data"] = {
                        ...data["data"], [item]: {
                            status: collapseState[item].status, dataSource: collapseState[item].parent, km: getValues(`${key}-${item}-km`),
                            averagePassenger: getValues(`${key}-${item}-averagePassenger`), economyClass: getValues(`${key}-${item}-economyClass`),
                            premiumEconomyClass: getValues(`${key}-${item}-premiumEconomyClass`), businessClass: getValues(`${key}-${item}-businessClass`),
                            firstClass: getValues(`${key}-${item}-firstClass`), carbonFootprint: collapseState[item].cf.value,
                            aviationType: collapseState[item].cf.aviationType, aviationDistanceType: collapseState[item].cf.aviationDistanceTypeVal,
                            year: collapseState[item].cf.year
                        }
                    }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                let shorthaulValidation = false;
                let longhaulValidation = false;
                let internationalValidation = false;
                let freightAviation = false;
                if (Object.keys(dataArray).length > 0) {
                    debugger
                    Object.keys(dataArray).forEach(item => {
                        if (dataArray[item]["dataSource"] === "freight") {
                            freightAviation = true;
                        }
                        if (dataArray[item]["dataSource"] === "passenger") {
                            if (((dataArray[item]["aviationDistanceType"] == 'Shorthaul') && (dataArray[item]["averagePassenger"] || dataArray[item]["economyClass"] || dataArray[item]["premiumEconomyClass"] || dataArray[item]["businessClass"] || dataArray[item]["firstClass"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray.Shorthaul == undefined) || (dataArray.Shorthaul ? !dataArray.Shorthaul.status : true)) {
                                shorthaulValidation = true;
                            }
                            if (((dataArray[item]["aviationDistanceType"] == 'Longhaul') && (dataArray[item]["averagePassenger"] || dataArray[item]["economyClass"] || dataArray[item]["premiumEconomyClass"] || dataArray[item]["businessClass"] || dataArray[item]["firstClass"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray.Longhaul === undefined) || (dataArray.Longhaul ? !dataArray.Longhaul.status : true)) {
                                longhaulValidation = true;
                            }
                            if (((dataArray[item]["aviationDistanceType"] == 'International') && (dataArray[item]["averagePassenger"] || dataArray[item]["economyClass"] || dataArray[item]["premiumEconomyClass"] || dataArray[item]["businessClass"] || dataArray[item]["firstClass"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0)
                                || (dataArray.International === undefined) || (dataArray.International ? !dataArray.International.status : true)) {
                                internationalValidation = true;
                            }
                        }
                    });
                }

                let validationForPassenger = shorthaulValidation && longhaulValidation && internationalValidation;
                if (!validationForPassenger && !freightAviation) {
                    props.toastMessage({ state: true, header: "Alert", body: "Please enter atleast one type of input value for the Passenger before saving" });
                    return false;
                }

                if (freightAviation || validationForPassenger) {
                    if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                        Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                            var jsonData = {};
                            if (dataArray[item]["dataSource"] === "freight") {
                                if ((dataArray[item]["tonnes"] && dataArray[item]["tonnes"] && dataArray[item]["carbonFootprint"]) > 0) {
                                    jsonData = {
                                        "projectId": projectId,
                                        "data": [
                                            {
                                                "tonnes": dataArray[item]["tonnes"] === undefined || dataArray[item]["tonnes"] === "" ? null : parseFloat(dataArray[item]["tonnes"]).toFixed(3),
                                                "km": dataArray[item]["km"] === undefined || dataArray[item]["km"] === "" ? null : parseFloat(dataArray[item]["km"]).toFixed(3),
                                                "averagePassenger": null,
                                                "economyClass": null,
                                                "premiumEconomyClass": null,
                                                "businessClass": null,
                                                "firstClass": null,
                                                "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                                "projectId": projectId,
                                                "aviationType": {
                                                    "name": aviationType[dataArray[item]["dataSource"]]
                                                },
                                                "aviationDistanceType": {
                                                    "name": dataArray[item]["aviationDistanceType"]
                                                }
                                            }
                                        ]
                                    };
                                } else {
                                    props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                    return false;
                                }
                            } else if (dataArray[item]["dataSource"] === "passenger") {
                                if ((dataArray[item]["averagePassenger"] || dataArray[item]["economyClass"] || dataArray[item]["premiumEconomyClass"] || dataArray[item]["businessClass"] || dataArray[item]["firstClass"]) > 0 && (dataArray[item]["km"]) > 0 && (dataArray[item]["carbonFootprint"]) > 0) {
                                    jsonData = {
                                        "projectId": projectId,
                                        "data": [
                                            {
                                                "tonnes": null,
                                                "km": dataArray[item]["km"] === undefined || dataArray[item]["km"] === "" ? null : parseFloat(dataArray[item]["km"]).toFixed(3),
                                                "averagePassenger": dataArray[item]["averagePassenger"] === undefined || dataArray[item]["averagePassenger"] === "" ? null : parseFloat(dataArray[item]["averagePassenger"]).toFixed(3),
                                                "economyClass": dataArray[item]["economyClass"] === undefined || dataArray[item]["economyClass"] === "" ? null : parseFloat(dataArray[item]["economyClass"]).toFixed(3),
                                                "premiumEconomyClass": dataArray[item]["premiumEconomyClass"] === undefined || dataArray[item]["premiumEconomyClass"] === "" ? null : parseFloat(dataArray[item]["premiumEconomyClass"]).toFixed(3),
                                                "businessClass": dataArray[item]["businessClass"] === undefined || dataArray[item]["businessClass"] === "" ? null : parseFloat(dataArray[item]["businessClass"]).toFixed(3),
                                                "firstClass": dataArray[item]["firstClass"] === undefined || dataArray[item]["firstClass"] === "" ? null : parseFloat(dataArray[item]["firstClass"]).toFixed(3),
                                                "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                                "projectId": projectId,
                                                "aviationType": {
                                                    "name": aviationType[dataArray[item]["dataSource"]]
                                                },
                                                "aviationDistanceType": {
                                                    "name": dataArray[item]["aviationDistanceType"]
                                                }
                                            }
                                        ]
                                    };
                                } else {
                                    props.toastMessage({ state: true, header: "Alert", body: "Please enter atleast one type of input value for the Passenger before saving" });
                                    return false;
                                }
                            }
                            axios.post(`${basePath}/calculator/aviation`, jsonData, {
                                headers: {
                                    authorization: `Bearer ${props.login.token}`
                                }
                            })
                                .then(res => {
                                    sTime = new Date();
                                    setIsDataPresent(true);
                                    setSavedTime(sTime);
                                    props.calculateSummary();
                                    props.toastMessage({ state: true, header: "Success", body: "Aviation calculator data saved!" });
                                    assumptionSaveData();
                                })
                                .catch(err => {
                                    props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                                });

                        });
                    } else {
                        props.toastMessage({ state: true, header: "Alert", body: "Please select a aviation type and fill in the details!" });
                    }
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a avition type and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }

    const assumptionSaveData = () => {
        let aviationassumption = getValues(`${key}-assumptions`)
        if (aviationassumption === "" || aviationassumption.length > 0) {
            let aviationdata = {
                "projectId": projectId,
                "data": [{
                    "projectId": projectId,
                    "assumptions": aviationassumption
                }]
            }
            axios.post(`${basePath}/calculator/aviationAssumption`, aviationdata, {
                headers: {
                    authorization: `Bearer ${props.login.token}`
                }
            })
                .then(res => {
                    sTime = new Date();
                    setIsDataPresent(true);
                    setSavedTime(sTime);
                    props.calculateSummary();
                })
                .catch(err => {
                    props.toastMessage({ state: true, header: "Error", body: "Server Error from assumption!" });
                });
        }
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/aviation/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Aviation calculator cannot be deleted! Server Error!" });
                    });
                axios.delete(`${basePath}/calculator/aviationAssumption/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Aviation Assumption cannot be deleted! Server Error" })
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {}
                if (Object.keys(aviationType).length === 0) {
                    for (const fst of props.master.aviationType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setAviationType(data);
                }
                if (Object.keys(aviationDistanceType).length === 0) {
                    data = {};
                    for (const ft of props.master.aviationDistanceType) {
                        let val = "";
                        val = ft.value;
                        data[camelize(val.toLowerCase())] = ft.value
                    }
                    setAviationDistanceType(data);
                }
            }
            if (Object.keys(sources).length === 0) {
                for (const ffm of Object.keys(props.master.aviationClassFactorMaster)) {
                    if (!years.includes(props.master.aviationClassFactorMaster[ffm].year)) {
                        years.push(props.master.aviationClassFactorMaster[ffm].year);
                    }
                    if (sources[props.master.aviationClassFactorMaster[ffm].year] === undefined) {
                        sources[props.master.aviationClassFactorMaster[ffm].year] = { source: [] };
                    }
                    if (!sources[props.master.aviationClassFactorMaster[ffm].year].source.includes(props.master.aviationClassFactorMaster[ffm].source)) {
                        sources[props.master.aviationClassFactorMaster[ffm].year].source.push(props.master.aviationClassFactorMaster[ffm].source);
                        sources[props.master.aviationClassFactorMaster[ffm].year].source.sort();
                    }
                }
                years = years.sort((a, b) => a - b);
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].aviationType.name === "Freight") {
                    data["dataSource"] = "freight";
                    setKey(`${componentId}-freight`);
                } else if (props.data.data[0].aviationType.name === "Passenger") {
                    data["dataSource"] = "passenger";
                    setKey(`${componentId}-passenger`);
                }
            } else {
                setIsDataPresent(false);
            }

            //aviationAssumption
            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                if (props.data.data[0].aviationType.name === "Freight") {
                    data["dataSource"] = "freight";
                    setKey(`${componentId}-freight`);
                } else if (props.data.data[0].aviationType.name === "Passenger") {
                    data["dataSource"] = "passenger";
                    setKey(`${componentId}-passenger`);
                }
            } else {
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var st = "";
                    props.data.data.forEach(obj => {
                        var fSourceType = "";
                        switch (obj.aviationType.name) {
                            case "Freight":
                                fSourceType = "freight";
                                break;
                            case "Passenger":
                                fSourceType = "passenger";
                                break;
                            default:
                                fSourceType = "freight";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.aviationDistanceType.name, parent: fSourceType, status: true } });
                        if (fSourceType === "freight") {
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-tonnes`, obj.tonnes);
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-km`, obj.km);
                            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                props.data.dataAssumption.forEach(obj => {
                                    setValue(`${componentId}-freight-assumptions`, obj.assumptions);
                                });
                            }
                        } else if (fSourceType === "passenger") {
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-km`, obj.km);
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-averagePassenger`, obj.averagePassenger);
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-economyClass`, obj.economyClass);
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-premiumEconomyClass`, obj.premiumEconomyClass);
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-businessClass`, obj.businessClass);
                            setValue(`${componentId}-${fSourceType}-${obj.aviationDistanceType.name}-firstClass`, obj.firstClass);
                            if (props.data !== undefined && props.data.dataAssumption.length > 0) {
                                props.data.dataAssumption.forEach(obj => {
                                    setValue(`${componentId}-passenger-assumptions`, obj.assumptions);
                                });
                            }
                        }
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);

    let collapseFreightAssumption, collapsePassengerAssumption;
    if (isMountedRef.current) {
        Object.keys(collapseState).forEach(item => {
            if (collapseState[item].status === true) {
                collapseFreightAssumption = <AviationAssumption
                    key={`${componentId}-freight-collapse`}
                    collapseControll={getLogic(item, "freight")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-freight`}
                    inputName={item}
                    textValue={getValues(`${componentId}-freight-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />
                collapsePassengerAssumption = <AviationAssumption
                    key={`${componentId}-passenger-collapse`}
                    collapseControll={getLogic(item, "passenger")}
                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                    id={`${componentId}-passenger`}
                    inputName={item}
                    textValue={getValues(`${componentId}-passenger-assumptions`)}
                    control={control}
                    register={register}
                    errors={errors}
                />

            }
        })
    }

    const getTooltipMessage = (item) => {
        if (item === 'Shorthaul') {
            return 'up to 3700km';
        } else if (item === 'Longhaul') {
            return 'above 3700km (Europe to other continent)';
        } else {
            return 'above 3700km (one continent to other, not including Europe)';
        }
    };

    const aviationSourceTabs = {
        [`${componentId}-freight`]: (
            <Tab eventKey={`${componentId}-freight`} title="Freight" disabled={key !== "" && key !== `${componentId}-freight`} key={`${componentId}-freight`} >
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <>
                                <Nav variant="pills" id={`${componentId}-aviationPills`} className="flex-row aviationPills">
                                    {
                                        Object.keys(collapseState).map(item => {
                                            return (
                                                <Nav.Item key={`${componentId}-freight-${item}-collapsePills`}>
                                                    <Nav.Link
                                                        eventKey={item}
                                                        active={collapseState[item].parent === 'freight' && collapseState[item].status === true}
                                                        disabled={
                                                            collapseState[item].parent !== 'freight' && collapseState[item].status === true
                                                        }
                                                        aria-controls={`${componentId}-freight-${item}-collapse`}
                                                        onClick={() =>
                                                            dispatchCollapseState({
                                                                type: 'COLLAPSE_SET_PARENT_STATUS',
                                                                payload: { name: item, parent: 'freight', status: !collapseState[item].status },
                                                            })
                                                        }
                                                    >
                                                        <span
                                                            data-tip={getTooltipMessage(item)}
                                                            data-for={`tooltip-${item}`}
                                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                                        >
                                                            {item === 'Shorthaul' ? 'Short - haul' : item === 'Longhaul' ? 'Long - haul' : item.charAt(0).toUpperCase() + item.slice(1)}
                                                        </span>
                                                    </Nav.Link>
                                                    <ReactTooltip id={`tooltip-${item}`} place="top" effect="solid" />
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                    <ReactTooltip id="tooltip" place="top" effect="solid" />
                                </Nav>
                            </>
                        </Col>
                        <Col sm={12}>
                            <div className="aviationPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapseAviationFreightCustom
                                            key={`${componentId}-freight-${item}-collapse`}
                                            collapseControll={getLogic(item, "freight")}
                                            id={`${componentId}-freight`}
                                            label={item === 'Shorthaul' ? (
                                                'Short - haul Distance'
                                            ) : item === 'Longhaul' ? (
                                                'Long - haul Distance'
                                            ) : (
                                                `${item.charAt(0).toUpperCase()}${item.slice(1)} Distance`
                                            )}
                                            inputName={item}
                                            inputTonnes={getValues(`${componentId}-freight-${item}-tonnes`)}
                                            inputKm={getValues(`${componentId}-freight-${item}-km`)}
                                            inputPlaceholder={"0"}
                                            selectName={item}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            freightVal={collapseState[item].parent}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapseFreightAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        ),
        [`${componentId}-passenger`]: (
            <Tab eventKey={`${componentId}-passenger`} title="Passenger" disabled={key !== "" && key !== `${componentId}-passenger`} key={`${componentId}-passenger`}>
                <Tab.Container>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" id={`${componentId}-passengerPills`} className="flex-row aviationPills">
                                {
                                    Object.keys(collapseState).map(item => {
                                        return (
                                            <Nav.Item key={`${componentId}-passenger-${item}-collapsePills`}>
                                                <Nav.Link
                                                    eventKey={item}
                                                    active={collapseState[item].parent === 'passenger' && collapseState[item].status === true}
                                                    disabled={
                                                        collapseState[item].parent !== 'passenger' && collapseState[item].status === true
                                                    }
                                                    aria-controls={`${componentId}-passenger-${item}-collapse`}
                                                    onClick={() =>
                                                        dispatchCollapseState({
                                                            type: 'COLLAPSE_SET_PARENT_STATUS',
                                                            payload: { name: item, parent: 'passenger', status: !collapseState[item].status },
                                                        })
                                                    }
                                                >
                                                    <span
                                                        data-tip={getTooltipMessage(item)}
                                                        data-for={`tooltip-${item}`}
                                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                                    >
                                                        {item === 'Shorthaul' ? 'Short - haul' : item === 'Longhaul' ? 'Long - haul' : item.charAt(0).toUpperCase() + item.slice(1)}
                                                    </span>
                                                </Nav.Link>
                                                <ReactTooltip id={`tooltip-${item}`} place="top" effect="solid" />
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <div className="aviationPillsCollapse">
                                {
                                    Object.keys(collapseState).map(item => (
                                        <CollapseAviationPassengerCustom
                                            key={`${componentId}-passenger-${item}-collapse`}
                                            collapseControll={getLogic(item, "passenger")}
                                            label={item === 'Shorthaul' ? (
                                                'Short - haul No. of passengers'
                                            ) : item === 'Longhaul' ? (
                                                'Long - haul No. of passengers'
                                            ) : (
                                                `${item.charAt(0).toUpperCase()}${item.slice(1)} No. of passengers`
                                            )}
                                            distanceLabel={item === 'Shorthaul' ? (
                                                'Short - haul distance'
                                            ) : item === 'Longhaul' ? (
                                                'Long - haul distance'
                                            ) : (
                                                `${item.charAt(0).toUpperCase()}${item.slice(1)} distance`
                                            )}
                                            id={`${componentId}-passenger`}
                                            inputName={item}
                                            inputKm={getValues(`${componentId}-passenger-${item}-km`)}
                                            inputKmLabel={`${item.charAt(0).toUpperCase()}${item.slice(1)} Km`}
                                            inputPlaceholder={"0"}
                                            inputAveragePassenger={getValues(`${componentId}-passenger-${item}-averagePassenger`)}
                                            inputEconomyClass={getValues(`${componentId}-passenger-${item}-economyClass`)}
                                            inputPremiumEconomyClass={getValues(`${componentId}-passenger-${item}-premiumEconomyClass`)}
                                            inputBusinessClass={getValues(`${componentId}-passenger-${item}-businessClass`)}
                                            inputFirstClass={getValues(`${componentId}-passenger-${item}-firstClass`)}
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            passengerVal={collapseState[item].parent}
                                            cFootprint={(val) => calulateTotalCF(val)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        {collapsePassengerAssumption}
                    </Row>
                </Tab.Container>
            </Tab>
        )
    }

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#6f91ad", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "10px" }}>
                                    <span>Aviation</span>
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Aviation calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <p style={{ paddingLeft: "10px", textAlign: "left" }}>Please select one of the following data sources you have available </p>
                            <div className="aviationTabs">
                                <Tabs
                                    id={`${componentId}-aviationTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >

                                    {
                                        Object.keys(aviationSourceTabs).map(tabItem => {
                                            if (tabItem === key) {
                                                return (aviationSourceTabs[tabItem]);
                                            } else {
                                                return false;
                                            }
                                        })
                                    }


                                    {
                                        Object.keys(aviationSourceTabs).map(tabItem => {
                                            if (tabItem !== key) {
                                                return (aviationSourceTabs[tabItem]);
                                            } else {
                                                return false;
                                            }
                                        })
                                    }
                                </Tabs>
                            </div>
                        </div>
                        <div className="aviationBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in aviation consumption</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(memo(AviationTabs)));