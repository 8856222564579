import React, { Component } from 'react';
import Alert from "react-bootstrap/alert";

export class ErrorBoundaryForPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Alert variant="danger">
                    <Alert.Heading>Something went wrong <i className="fa fa-exclamation" aria-hidden="true"></i></Alert.Heading>
                </Alert>
            )
        } else {
            return this.props.children
        }
    }
}

export default ErrorBoundaryForPage
