import React, { useState, useEffect } from "react";

export default function ScrollToTop(props) {

  const [is_visible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
  }, [])

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className="scroll-to-top">
      {is_visible && (
        <div onClick={scrollToTop}>
          <div className="top-link show" id="js-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6"><path d="M12 6H0l6-6z" /></svg>
            <span className="screen-reader-text">Back to top</span>
          </div>
        </div>
      )}
    </div>
  );
}
