import React, { useState } from "react";
import "./Header.css";
import {
    withRouter
} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/dropdown";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import LoginModal from "../loginModal";
import SignUpModal from "../signUpModal";
import LogoUnderline from "../../legend";
import { logout } from "../../../store/actions/loginActions/logoutAction";

function Header(props) {

    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    const handleLoginClose = () => setShowLogin(false);
    const handleLoginShow = () => setShowLogin(true);

    const handleSignUpClose = () => setShowSignUp(false);
    const handleSignUpShow = () => setShowSignUp(true);

    return (
      <React.Fragment>
        <Navbar bg="light" expand="lg" sticky="top">
          <LinkContainer
            exact
            to="/home"
            style={{ textAlign: "right", paddingTop: "10px" }}
          >
            <Navbar.Brand hidden={props.location.pathname === "/home"}>
              <p className="logoName">
                Carbon <span className="logoName2">Calculator</span>
              </p>
              <p style={{ marginBottom: "0px" }}>
                <LogoUnderline type="logoUnderline" name="header-logo" />
              </p>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav variant="pills" className="ml-auto right">
            {props.history.location.pathname === "/home" || props.history.location.pathname === "/projects"  ? (
                <Nav.Item className="navBarLink">
                  <LinkContainer exact to="/report">
                    <Nav.Link active={props.location.pathname === "/report"}>
                      Report
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ) : (
                ""
              )}
              <Dropdown alignRight className="navBarLink" id="loginDropdown">
                <Dropdown.Toggle
                  variant="basic"
                  id="dropdown-basic"
                  style={{ color: "white" }}
                >
                  Hi{" "}
                  {props.login.username.split(" ").length > 1
                    ? props.login.username.split(" ")[1]
                    : props.login.username.split(" ")[0]}
                  !
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.login.isValidUser ? (
                    <React.Fragment>
                      <Dropdown.Item
                        className="medium-text"
                        onClick={() => props.logoutApp(props.aadLogoutFn)}
                      >
                        Logout
                      </Dropdown.Item>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Dropdown.Item
                        className="medium-text"
                        onClick={handleLoginShow}
                      >
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="medium-text"
                        onClick={handleSignUpShow}
                      >
                        Sign Up
                      </Dropdown.Item>
                    </React.Fragment>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <LoginModal show={showLogin} onHide={handleLoginClose} />
        <SignUpModal show={showSignUp} onHide={handleSignUpClose} />
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        login: state.login
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutApp: (aadLogoutFn) => {
            dispatch(logout(aadLogoutFn));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
