import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import convert from "convert-units";
import { Element } from "react-scroll";
import { footprintForAviationByFreight } from "../../calculatorFunctions/aviation_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}


const CollapseAviationFreightCustom = (props) => {
    let { collapseControll, id, inputName, freightVal, inputTonnes, inputKm, label, inputPlaceholder, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [tonnes, setTonnes] = useState((inputTonnes === undefined || inputTonnes === "") ? 0 : inputTonnes);
    const [km, setKm] = useState((inputKm === undefined || inputKm === "") ? 0 : inputKm);

    const inputTonnesChange = (val) => {
        setTonnes((val !== undefined && val !== "") ? val : 0);
    }

    const inputKmChange = (val) => {
        setKm((val !== undefined && val !== "") ? val : 0);
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const setCF = (val) => {
        var cf = 0;
        let name = "Freight" + '_' + inputName + '_' + "TonnesKm";
        let distance = km === undefined || km === "" ? 0 : km;
        let weightInTonnes = tonnes === undefined || tonnes === "" ? 0 : tonnes;
        let emissionFactor = props.master.aviationFreightFactorMaster.hasOwnProperty(name) && props.master.aviationFreightFactorMaster[name].emissionFactor !== undefined ? props.master.aviationFreightFactorMaster[name].emissionFactor : 0;
        let emissionFactorWTT = props.master.aviationFreightFactorMaster.hasOwnProperty(name) && props.master.aviationFreightFactorMaster[name].emissionFactorWTT !== undefined ? props.master.aviationFreightFactorMaster[name].emissionFactorWTT : 0;
        let source = props.master.aviationFreightFactorMaster.hasOwnProperty(name) && props.master.aviationFreightFactorMaster[name].source !== undefined ? props.master.aviationFreightFactorMaster[name].source : null;
        let year = props.master.aviationFreightFactorMaster.hasOwnProperty(name) && props.master.aviationFreightFactorMaster[name].year !== undefined ? props.master.aviationFreightFactorMaster[name].year : 0;
        let aviationTypeVal = props.master.aviationFreightFactorMaster.hasOwnProperty(name) && props.master.aviationFreightFactorMaster[name].emissionFactor !== undefined ? props.master.aviationFreightFactorMaster[name].aviationType.name : null;
        let aviationDistanceTypeVal = props.master.aviationFreightFactorMaster.hasOwnProperty(name) && props.master.aviationFreightFactorMaster[name].emissionFactor !== undefined ? props.master.aviationFreightFactorMaster[name].aviationDistanceType.name : null;

        if (distance || weightInTonnes > 0) {
            cf = footprintForAviationByFreight(distance, weightInTonnes, emissionFactor, emissionFactorWTT);
        }
        if (freightVal === "freight") {
            props.cFootprint({
                name: inputName,
                parent: "freight",
                cf: { value: cf, distance, weightInTonnes, aviationTypeVal, aviationDistanceTypeVal, emissionFactor, emissionFactorWTT, year, source },
            });
        }
        setCarbonFootprint(cf);

    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if (tonnes !== undefined && tonnes !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (km !== undefined && km !== "") {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [tonnes, km]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }


    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "Shorthaul" ? `scrollAviationFreight${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollAviationFreight"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#6f91ad", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label} </span>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-freightCollapse`} >
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-tonnes`} name={`${id}-${inputName}-tonnes`} label="Tonnes" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputTonnesChange(e.target.value)} />
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-km`} name={`${id}-${inputName}-km`} label="KM" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputKmChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col></Col>
                        <Col lg={2} md={3} sm={12} styles={{ paddingBottom: "500px" }}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{
                        color: '#6f91ad',
                        backgroundColor: '#6f91ad',
                        height: .5,
                        borderColor: '#6f91ad'
                    }} />

                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseAviationFreightCustom));