import React, { useState, useReducer, useEffect, useRef } from "react";
import {
    Link
} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./PaperTabs.css";
import Select from "../select/SelectCustom";
import CollapsePaperConsumptionCustom from "./CollapsePaperConsumptionCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../errorBoundaries/ErrorBoundaryForPage";
import { mainCountries } from "../../calculatorFunctions/mainCountries";
import { deleteConfirm } from "../deleteConfirm";
import { LOCATION_CHANGE } from "connected-react-router";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const unitOptions = {
    tonnes: { value: "tonnes", label: "tonnes" },
    kg: { value: "kg", label: "kg" },
    sheets: { value: "sheets", label: "sheets" }
};

const materialOptions = {
    PrimaryMaterialProduct: { value: "Primary Material Product", label: "Primary Material Product" },
    ClosedLoopSource: { value: "Closed-loop Source", label: "Closed-loop Source" },
};

const disposalOptions = {
    Landfill: { value: "Landfill", label: "Landfill" },
    ClosedLoop: { value: "Closed-loop", label: "Closed-loop" },
};

const postageOptions = {
    Yes: { value: "Yes", label: "Yes" },
    No: { value: "No", label: "No" },
};

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {

    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };

            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }

            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var sTime = new Date();
var currentParent = "";

const PaperTabs = (props) => {
    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, {});
    const form = useForm();
    const { register, errors, getValues, setValue, control, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [PaperSourceType, setPaperSourceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [clickedSave, setClickedSave] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 2)];
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "PaperConsumption") {
                    data["data"] = { ...data["data"], [item]: { status: collapseState[item].status, dataSource: data["dataSource"], amount: collapseState[item].cf.volume, assumptions: collapseState[item].cf.assumptions,characters: collapseState[item].cf.characters, unit: collapseState[item].cf.unit, carbonFootprint: collapseState[item].cf.value, material: collapseState[item].cf.material, disposal: collapseState[item].cf.disposal, postage: collapseState[item].cf.postage } }
                }
            });
            const dataArray = data["data"];
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                        if (PaperSourceType[dataArray[item]["dataSource"]] === "Paper") {
                            jsonData = {
                                "projectId": projectId,
                                "data": [{
                                    "amount": dataArray[item]["amount"] === undefined || dataArray[item]["amount"] === "" ? null : parseFloat(dataArray[item]["amount"]),
                                    "assumptions": dataArray[item]["assumptions"] === undefined || dataArray[item]["assumptions"] === "" ? '' : (dataArray[item]["assumptions"]),
                                    "characters": dataArray[item]["characters"] === undefined || dataArray[item]["characters"] === "" ? '' : (dataArray[item]["characters"]),
                                    "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(totalCarbonFootprint),
                                    "projectId": projectId,
                                    "paperMaterialUse": {
                                        "name": dataArray[item]["material"].value
                                    },
                                    "paperWasteDisposal": {
                                        "name": dataArray[item]["disposal"].value
                                    },
                                    "paperUnitMeasurment": {
                                        "name": dataArray[item]["unit"].value
                                    },
                                    "paperPostage": {
                                        "name": dataArray[item]["postage"].value
                                    },
                                    "paperSourceType": {
                                        "name": PaperSourceType[dataArray[item]["dataSource"]]
                                    }
                                }]
                            };
                        }
                        axios.post(`${basePath}/calculator/Paper`, jsonData, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`
                            }
                        })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "Paper calculator data saved!" });
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                    });
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please select a country in Paper calculator and fill in the details!" });
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a country in Paper calculator and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" });
        }
    }


    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    // const calulateTotalCF = (val) => {
    //     if (collapseState[val.name].parent === val.parent) {
    //         dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
    //     }
    // }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent === val.parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/paper/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Paper calculator cannot be deleted! Server Error" })
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    const formatGroupLabel = (data) => {
        if (data.label !== "top") {
            return (
                <hr style={{ left: "-10px", height: "0.5px", backgroundColor: "#f0f0f0", margin: "0px", padding: "0px", marginTop: "-10px" }} />
            )
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {};
                if (Object.keys(PaperSourceType).length === 0) {
                    for (const fst of props.master.paperSourceType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setPaperSourceType(data);
                }
            }

            if (Object.keys(sources).length === 0) {
                for (const pfm of Object.keys(props.master.paperMaterialUseMaster)) {
                  //  console.log(props.master.paperMaterialUseMaster[pfm]);
                  //  console.log(pfm, "pfmmmm");
                    if (!years.includes(props.master.paperMaterialUseMaster[pfm].year)) {
                        years.push(props.master.paperMaterialUseMaster[pfm].year);
                    }
                    if (sources[props.master.paperMaterialUseMaster[pfm].year] === undefined) {
                        sources[props.master.paperMaterialUseMaster[pfm].year] = { source: [] };
                    }
                    if (!sources[props.master.paperMaterialUseMaster[pfm].year].source.includes(props.master.paperMaterialUseMaster[pfm].source)) {
                        sources[props.master.paperMaterialUseMaster[pfm].year].source.push(props.master.paperMaterialUseMaster[pfm].source);
                        sources[props.master.paperMaterialUseMaster[pfm].year].source.sort();
                    }
                }
                years = years.sort((a, b) => a - b);
               // console.log(years, "years====");
            }
        }
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].paperSourceType.name === "Paper") {
                    data["dataSource"] = "PaperConsumption";
                    setKey(`${componentId}-PaperConsumption`);
                }
            } else {
                setKey(`${componentId}-PaperConsumption`);
                setIsDataPresent(false);
            }
            dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: `${componentId}-PaperConsumption`, parent: "PaperConsumption", status: true } })
        }
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value)
                }
            });
            setTotalCarbonFootprint(parseFloat(total / 1000).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
                setRefresh(prev => ++prev);
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var st = "";
                    props.data.data.forEach(obj => {
                        var fSourceType = "";
                        switch (obj.paperSourceType.name) {
                            case "Paper":
                                fSourceType = "PaperConsumption";
                                break;
                            default:
                                fSourceType = "PaperConsumption";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: `${componentId}-PaperConsumption`, parent: fSourceType, status: true } })
                        if (fSourceType === "PaperConsumption") {
                            setValue(`${componentId}-${fSourceType}-amount`, obj.amount);
                            setValue(`${componentId}-${fSourceType}-assumptions`, obj.assumptions);
                            setValue(`${componentId}-${fSourceType}-characters`, obj.characters);
                            setValue(`${componentId}-${fSourceType}-volume-select`, { value: unitOptions[obj.paperUnitMeasurment.name].value, label: unitOptions[obj.paperUnitMeasurment.name].label });
                            setValue(`${componentId}-${fSourceType}-postageOptions-select`, { value: postageOptions[obj.paperPostage.name].value, label: postageOptions[obj.paperPostage.name].label });
                            if (obj.paperMaterialUse.name.split(' ').join('').split('-').join('') === 'ClosedloopSource') {
                                setValue(`${componentId}-${fSourceType}-materialOptions-select`, { value: materialOptions['ClosedLoopSource'].value, label: materialOptions['ClosedLoopSource'].label });
                            } else {
                                setValue(`${componentId}-${fSourceType}-materialOptions-select`, { value: materialOptions[obj.paperMaterialUse.name.split(' ').join('')].value, label: materialOptions[obj.paperMaterialUse.name.split(' ').join('')].label });
                            }
                            if (obj.paperWasteDisposal.name.split(' ').join('').split('-').join('') === 'Closedloop') {
                                setValue(`${componentId}-${fSourceType}-disposalOptions-select`, { value: disposalOptions['ClosedLoop'].value, label: disposalOptions['ClosedLoop'].label });
                            } else {
                                setValue(`${componentId}-${fSourceType}-disposalOptions-select`, { value: disposalOptions[obj.paperWasteDisposal.name].value, label: disposalOptions[obj.paperWasteDisposal.name].label });
                            }
                        }
                        st = obj.createdAt;
                    });
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);


    return (

        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#FFF", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "5px" }}>
                                    <span>Paper</span>
                                    <Link className={"calculatorActionDelete"} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Paper calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <div className="PaperTabs">
                                <Tabs
                                    id={`${componentId}-PaperTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >
                                    <Tab eventKey={`${componentId}-PaperConsumption`} disabled={key !== "" && key !== `${componentId}-PaperConsumption`} key={`${componentId}-PaperConsumption`}>
                                        <Tab.Container>
                                            <Row>
                                                <Col sm={12}>

                                                    <div className="PaperPillsCollapse">
                                                        {
                                                            Object.keys(collapseState).map(item => (
                                                                <CollapsePaperConsumptionCustom
                                                                    key={`${componentId}-PaperConsumption-${item}-collapse`}
                                                                    collapseControll={getLogic(item, "PaperConsumption")}
                                                                    id={`${componentId}-PaperConsumption`}
                                                                    inputName={item}
                                                                    volumeValue={getValues(`${componentId}-PaperConsumption-amount`)}
                                                                    textValue={getValues(`${componentId}-PaperConsumption-assumptions`)}
                                                                    characterValue={getValues(`${componentId}-PaperConsumption-characters`)}
                                                                    selectName={item}
                                                                    selectLabel={"Units"}
                                                                    selectUnitValue={getValues(`${componentId}-PaperConsumption-volume-select`)}
                                                                    selectDisposalValue={getValues(`${componentId}-PaperConsumption-disposalOptions-select`)}
                                                                    selectMaterialValue={getValues(`${componentId}-PaperConsumption-materialOptions-select`)}
                                                                    selectPostageValue={getValues(`${componentId}-PaperConsumption-postageOptions-select`)}
                                                                    control={control}
                                                                    register={register}
                                                                    errors={errors}
                                                                    cFootprint={(val) => calulateTotalCF(val)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Tab>
                                    {/* )
                            } */}
                                </Tabs>
                            </div>
                        </div>
                        <div className="PaperBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4} className="carbonFootprint" style={{ marginLeft: '0px' }}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>reduction in Paper consumption</span>
                                    </Col>
                                    <Col className="papercarbonFootprint">
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "8px" }}>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px", marginBottom: "10px" }} >
                                        {
                                            <p>
                                                <span>This calculation was done using reference data from </span>
                                                <span style={{ color: "#f0f0f0" }}>
                                                    {
                                                        years.map(year => {
                                                            return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                        })
                                                    }
                                                </span>
                                            </p>

                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(PaperTabs));