import axios from "axios";

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

const getAllMasters = () => {
    return dispatch => {
        axios.get(`${basePath}/master/getAllMasters`)
            .then(res => {
                const resData = res.data
                var data = {};
                Object.keys(resData).forEach(item => {
                    for (const val of resData[item]) {
                        if (!data[item]) {
                            if (item === "vehicleType" || item === "vehicleType1" || item === "fuelFactorMaster" || item === "fuelFactorVolume" || item === "electricityFactorMaster" || item === "peopleFactorMaster" || item === "waterFactorMaster" || item === "countryElectricEfficiency" || item === "serverType" || item === "transportFactorMaster" || item === "wasteFactorMaster" || item === "foodFactorMaster" || item === "digitalFactorMaster" || item === "hotelFactorMaster" || item === "paperMaterialUseMaster" || item === "paperWasteDisposalMaster" || item === "paperPostageMaster" || item === "plasticMaterialUseMaster" || item === "plasticWasteDisposalMaster" || item === "metalMaterialUseMaster" || item === "metalWasteDisposalMaster" || item === "transportLiquidFactorMaster" || item === "electricalItemsMaterialUseMaster" || item === "electricalItemsWasteDisposalMaster" || item === "aviationClassFactorMaster" || item === "aviationFreightFactorMaster" || item === "seaFreightSeaTankerSizeMaster" || item === "seaFreightCargoShipSizeMaster") {
                                data[item] = {};
                            } else {
                                data[item] = [];
                            }
                        }
                        if (item === "salesStage") {
                            data[item].push({ value: val.name, label: `(${val.order}) ${val.name}`, [(val.shortName === undefined) ? "label" : "shortName"]: ((val.shortName === undefined) ? `(${val.order}) ${val.name}` : val.shortName), [(val.order === undefined) ? "label" : "order"]: ((val.order === undefined) ? `(${val.order}) ${val.name}` : val.order), [(val.activities === undefined) ? "label" : "activities"]: ((val.activities === undefined) ? `(${val.order}) ${val.name}` : val.activities) });
                        } else if (item === "testProjectOptions") {
                            data[item].push({ value: val, label: val.toUpperCase() });
                        } else if (item === "flagCfOptions") {
                            data[item].push({ value: val, label: val.toUpperCase() });
                        } else if (item === "countryElectricEfficiency") {
                            if (!data[item][val.country.name]) {
                                data[item][val.country.name] = {};
                            }
                            data[item][val.country.name] = { country: val.country.name, efficiency: val.efficiency };
                        } else if (item === "vehicleType") {
                            if (!data[item][val.fuelType.name.toLowerCase()]) {
                                data[item][val.fuelType.name.toLowerCase()] = [];
                            }
                            data[item][val.fuelType.name.toLowerCase()].push({ name: val.name, efficiency: val.fuelEfficiency, laden: val.laden });
                        } else if (item === "vehicleType1") {
                            if (!data[item][val.fuelType.name.toLowerCase()]) {
                                data[item][val.fuelType.name.toLowerCase()] = [];
                            }
                            data[item][val.fuelType.name.toLowerCase()].push({ name: val.name, laden: val.laden, scope1: val.scope1, scope3: val.scope3, year: val.year, source: val.source });
                        } else if (item === "fuelFactorMaster" || item === "fuelFactorVolume") {
                            if (!data[item][val.fuelType.name.toLowerCase()]) {
                                data[item][val.fuelType.name.toLowerCase()] = {};
                            }
                            data[item][val.fuelType.name.toLowerCase()] = val;
                        } else if (item === "paperMaterialUseMaster") {
                            if (!data[item][val.paperMaterialUse.name]) {
                                data[item][val.paperMaterialUse.name] = {};
                            }
                            data[item][val.paperMaterialUse.name] = val;
                        } else if (item === "paperWasteDisposalMaster") {
                            if (!data[item][val.paperWasteDisposal.name]) {
                                data[item][val.paperWasteDisposal.name] = {};
                            }
                            data[item][val.paperWasteDisposal.name] = val;
                        }
                        else if (item === "paperPostageMaster") {
                            if (!data[item][val.paperPostage.name]) {
                                data[item][val.paperPostage.name] = {};
                            }
                            data[item][val.paperPostage.name] = val;
                        } else if (item === "plasticMaterialUseMaster") {
                            if (!data[item][val.plasticSourceType.name]) {
                                data[item][val.plasticSourceType.name] = {};
                            }
                            data[item][val.plasticSourceType.name] = val;
                        } else if (item === "plasticWasteDisposalMaster") {
                            if (!data[item][val.plasticSourceType.name]) {
                                data[item][val.plasticSourceType.name] = {};
                            }
                            data[item][val.plasticSourceType.name] = val;
                        } else if (item === "metalMaterialUseMaster") {
                            if (!data[item][val.metalSourceType.name]) {
                                data[item][val.metalSourceType.name] = {};
                            }
                            data[item][val.metalSourceType.name] = val;
                        } else if (item === "metalWasteDisposalMaster") {
                            if (!data[item][val.metalSourceType.name]) {
                                data[item][val.metalSourceType.name] = {};
                            }
                            data[item][val.metalSourceType.name] = val;
                        } else if (item === "transportLiquidFactorMaster") {
                            data[item][val.transportType.name + '_' + val.transportUnitMeasurement.name] = val;
                        } else if (item === "electricalItemsMaterialUseMaster") {
                            if (!data[item][val.electricalItemsSourceType.name]) {
                                data[item][val.electricalItemsSourceType.name] = {};
                            }
                            data[item][val.electricalItemsSourceType.name] = val;
                        } else if (item === "electricalItemsWasteDisposalMaster") {
                            if (!data[item][val.electricalItemsSourceType.name]) {
                                data[item][val.electricalItemsSourceType.name] = {};
                            }
                            data[item][val.electricalItemsSourceType.name] = val;
                        } else if (item === "aviationClassFactorMaster") {
                            data[item][val.aviationType.name + '_' + val.aviationDistanceType.name + '_' + val.aviationClassFactor.name] = val;
                        } else if (item === "aviationFreightFactorMaster") {
                            data[item][val.aviationType.name + '_' + val.aviationDistanceType.name + '_' + val.aviationFreightFactor.name] = val;
                        } else if (item === "seaFreightSeaTankerSizeMaster") {
                            data[item][val.seaFreightActivity.name + '_' + val.seaFreightType.name + '_' + val.seaFreightSeaTankerSize.name] = val;
                        } 
                        else if (item === "seaFreightCargoShipSizeMaster") {
                            data[item][val.seaFreightActivity.name + '_' + val.seaFreightType.name + '_' + val.seaFreightCargoShipSize.name] = val;
                        }
                         else if (item === "electricityFactorMaster" || item === "peopleFactorMaster" || item === "waterFactorMaster" || item === "hotelFactorMaster") {
                            if (!data[item][val.country.name]) {
                                data[item][val.country.name] = {};
                            }
                            data[item][val.country.name] = val;
                        } else if (item === "transportFactorMaster" || item === "foodFactorMaster" || item === "wasteFactorMaster" || item === "digitalFactorMaster") {
                            if (!data[item][val.category]) {
                                data[item][val.category] = {};
                            }
                            data[item][val.category] = val;
                        } else if (item === "serverType") {
                            if (!data[item][val.type.toString().toLowerCase().split(" ")[0]]) {
                                data[item][val.type.toString().toLowerCase().split(" ")[0]] = {};
                            }
                            data[item][val.type.toString().toLowerCase().split(" ")[0]] = val;
                        } else if (item === "country") {
                            if (val.name !== "Other") {
                                data[item].push({ value: val.name, label: val.name, [(val.shortName === undefined) ? "label" : "shortName"]: ((val.shortName === undefined) ? val.name : val.shortName), [(val.order === undefined) ? "label" : "order"]: ((val.order === undefined) ? val.name : val.order), [(val.activities === undefined) ? "label" : "activities"]: ((val.activities === undefined) ? val.name : val.activities) });
                            }
                        } else {
                            data[item].push({ value: val.name, label: val.name, [(val.shortName === undefined) ? "label" : "shortName"]: ((val.shortName === undefined) ? val.name : val.shortName), [(val.order === undefined) ? "label" : "order"]: ((val.order === undefined) ? val.name : val.order), [(val.activities === undefined) ? "label" : "activities"]: ((val.activities === undefined) ? val.name : val.activities) });
                        }
                    }
                });

                data["country"].push({ value: "Other", label: "Other" });
                data["fuelVehicle"] = {};
                if (Object.keys(data["vehicleType"]).length > 0) {
                    for (const k in data["vehicleType"]) {
                        for (const vt of data["vehicleType"][k]) {
                            if (!data["fuelVehicle"][k.toLowerCase()]) {
                                data["fuelVehicle"][k.toLowerCase()] = {};
                            }
                            data["fuelVehicle"][k.toLowerCase()] = { ...data["fuelVehicle"][k.toLowerCase()], [vt.name]: { name: vt.name, state: false, cf: 0 } }
                        }
                    }
                }
                data["fuelVehicle1"] = {};
                if (Object.keys(data["vehicleType1"]).length > 0) {
                    for (const k in data["vehicleType1"]) {
                        for (const vt of data["vehicleType1"][k]) {
                            if (!data["fuelVehicle1"][k.toLowerCase()]) {
                                data["fuelVehicle1"][k.toLowerCase()] = {};
                            }
                            data["fuelVehicle1"][k.toLowerCase()] = { ...data["fuelVehicle1"][k.toLowerCase()], [vt.name]: { name: vt.name, state: false, cf: 0 } }
                        }
                    }
                }

                dispatch({
                    type: "SET_SALES_STAGE",
                    payload: { salesStage: data["salesStage"] }
                });
                dispatch({
                    type: "SET_SECTOR",
                    payload: { sector: data["sector"] }
                });
                dispatch({
                    type: "SET_DELIVERY_LINE",
                    payload: { deliveryLine: data["deliveryLine"] }
                });
                dispatch({
                    type: "SET_TYPE_OF_ENGAGEMENT",
                    payload: { typeOfEngagement: data["typeOfEngagement"] }
                });
                dispatch({
                    type: "SET_COUNTRY",
                    payload: { country: data["country"] }
                });
                dispatch({
                    type: "SET_CONTINENT",
                    payload: { continent: data["continent"] }
                });
                dispatch({
                    type: "SET_ACTIVITY",
                    payload: { activity: data["activity"] }
                });
                dispatch({
                    type: "SET_WATER_SOURCE_TYPE",
                    payload: { waterSourceType: data["waterSourceType"] }
                });
                dispatch({
                    type: "SET_DATA_CENTRE_SOURCE_TYPE",
                    payload: { dataCentreSourceType: data["dataCentreSourceType"] }
                });
                dispatch({
                    type: "SET_ELECTRICITY_SOURCE_TYPE",
                    payload: { electricitySourceType: data["electricitySourceType"] }
                });
                dispatch({
                    type: "SET_PEOPLE_SOURCE_TYPE",
                    payload: { peopleSourceType: data["peopleSourceType"] }
                });
                dispatch({
                    type: "SET_FUEL_SOURCE_TYPE",
                    payload: { fuelSourceType: data["fuelSourceType"] }
                });
                dispatch({
                    type: "SET_FUEL_TYPE",
                    payload: { fuelType: data["fuelType"] }
                });
                dispatch({
                    type: "SET_UNIT_OF_MEASUREMENT_TYPE",
                    payload: { unitOfMeasurementType: data["unitOfMeasurementType"] }
                });
                dispatch({
                    type: "SET_UNIT_OF_MEASUREMENT",
                    payload: { unitOfMeasurement: data["unitOfMeasurement"] }
                });
                dispatch({
                    type: "SET_COUNTRY_ELECTRIC_EFFICIENCY",
                    payload: { countryElectricEfficiency: data["countryElectricEfficiency"] }
                });
                dispatch({
                    type: "SET_VEHICLE_TYPE",
                    payload: { vehicleType: data["vehicleType"] }
                });
                dispatch({
                    type: "SET_VEHICLE_TYPE1",
                    payload: { vehicleType1: data["vehicleType1"] }
                });
                dispatch({
                    type: "SET_FUEL_VEHICLE",
                    payload: { fuelVehicle: data["fuelVehicle"] }
                });
                dispatch({
                    type: "SET_FUEL_VEHICLE1",
                    payload: { fuelVehicle1: data["fuelVehicle1"] }
                });
                dispatch({
                    type: "SET_FUEL_FACTOR_MASTER",
                    payload: { fuelFactorMaster: data["fuelFactorMaster"] }
                });
                dispatch({
                    type: "SET_FUEL_FACTOR_VOLUME",
                    payload: { fuelFactorVolume: data["fuelFactorVolume"] }
                });
                dispatch({
                    type: "SET_ELECTRICITY_FACTOR_MASTER",
                    payload: { electricityFactorMaster: data["electricityFactorMaster"] }
                });
                dispatch({
                    type: "SET_PEOPLE_FACTOR_MASTER",
                    payload: { peopleFactorMaster: data["peopleFactorMaster"] }
                });
                dispatch({
                    type: "SET_WATER_FACTOR_MASTER",
                    payload: { waterFactorMaster: data["waterFactorMaster"] }
                });
                dispatch({
                    type: "SET_TRANSPORT_FACTOR_MASTER",
                    payload: { transportFactorMaster: data["transportFactorMaster"] }
                });
                dispatch({
                    type: "SET_FOOD_FACTOR_MASTER",
                    payload: { foodFactorMaster: data["foodFactorMaster"] }
                });
                dispatch({
                    type: "SET_WASTE_FACTOR_MASTER",
                    payload: { wasteFactorMaster: data["wasteFactorMaster"] }
                });
                dispatch({
                    type: "SET_DIGITAL_FACTOR_MASTER",
                    payload: { digitalFactorMaster: data["digitalFactorMaster"] }
                });
                dispatch({
                    type: "SET_HOTEL_FACTOR_MASTER",
                    payload: { hotelFactorMaster: data["hotelFactorMaster"] }
                });
                dispatch({
                    type: "SET_SERVER_TYPE",
                    payload: { serverType: data["serverType"] }
                });
                dispatch({
                    type: "SET_TEST_PROJECT_OPTIONS",
                    payload: { testProjectOptions: data["testProjectOptions"] }
                });
                dispatch({
                    type: "SET_FLAG_CF_OPTIONS",
                    payload: { flagCfOptions: data["flagCfOptions"] }
                });
                dispatch({
                    type: "SET_PAPER_SOURCE_TYPE",
                    payload: { paperSourceType: data["paperSourceType"] }
                });
                dispatch({
                    type: "SET_PAPER_MATERIAL_USE",
                    payload: { paperMaterialUse: data["paperMaterialUse"] }
                });
                dispatch({
                    type: "SET_PAPER_WASTE_DISPOSAL",
                    payload: { paperWasteDisposal: data["paperWasteDisposal"] }
                });
                dispatch({
                    type: "SET_PAPER_POSTAGE",
                    payload: { paperPostage: data["paperPostage"] }
                });
                dispatch({
                    type: "SET_PAPER_MATERIALUSE_MASTER",
                    payload: { paperMaterialUseMaster: data["paperMaterialUseMaster"] }
                });
                dispatch({
                    type: "SET_PAPER_WASTEDISPOSAL_MASTER",
                    payload: { paperWasteDisposalMaster: data["paperWasteDisposalMaster"] }
                });
                dispatch({
                    type: "SET_PAPER_POSTAGE_MASTER",
                    payload: { paperPostageMaster: data["paperPostageMaster"] }
                });
                dispatch({
                    type: "SET_PAPER_UNIT_OF_MEASUREMENT",
                    payload: { paperUnitMeasurment: data["paperUnitMeasurment"] }
                });
                dispatch({
                    type: "SET_PLASTIC_SOURCE_TYPE",
                    payload: { plasticSourceType: data["plasticSourceType"] }
                });
                dispatch({
                    type: "SET_PLASTIC_MATERIAL_USE",
                    payload: { plasticMaterialUse: data["plasticMaterialUse"] }
                });
                dispatch({
                    type: "SET_PLASTIC_WASTE_DISPOSAL",
                    payload: { plasticWasteDisposal: data["plasticWasteDisposal"] }
                });
                dispatch({
                    type: "SET_PLASTIC_MATERIALUSE_MASTER",
                    payload: { plasticMaterialUseMaster: data["plasticMaterialUseMaster"] }
                });
                dispatch({
                    type: "SET_PLASTIC_WASTEDISPOSAL_MASTER",
                    payload: { plasticWasteDisposalMaster: data["plasticWasteDisposalMaster"] }
                });
                dispatch({
                    type: "SET_PLASTIC_UNIT_OF_MEASUREMENT",
                    payload: { plasticUnitMeasurment: data["plasticUnitMeasurment"] }
                });
                dispatch({
                    type: "SET_METAL_SOURCE_TYPE",
                    payload: { metalSourceType: data["metalSourceType"] }
                });
                dispatch({
                    type: "SET_METAL_MATERIAL_USE",
                    payload: { metalMaterialUse: data["metalMaterialUse"] }
                });
                dispatch({
                    type: "SET_METAL_WASTE_DISPOSAL",
                    payload: { metalWasteDisposal: data["metalWasteDisposal"] }
                });
                dispatch({
                    type: "SET_METAL_MATERIALUSE_MASTER",
                    payload: { metalMaterialUseMaster: data["metalMaterialUseMaster"] }
                });
                dispatch({
                    type: "SET_METAL_WASTEDISPOSAL_MASTER",
                    payload: { metalWasteDisposalMaster: data["metalWasteDisposalMaster"] }
                });
                dispatch({
                    type: "SET_METAL_UNIT_OF_MEASUREMENT",
                    payload: { metalUnitMeasurement: data["metalUnitMeasurement"] }
                });
                dispatch({
                    type: "SET_TRANSPORT_TYPE",
                    payload: { transportType: data["transportType"] }
                });
                dispatch({
                    type: "SET_TRANSPORT_LIQUID_FACTOR_MASTER",
                    payload: { transportLiquidFactorMaster: data["transportLiquidFactorMaster"] }
                });
                dispatch({
                    type: "SET_TRANSPORT_UNIT_OF_MEASUREMENT",
                    payload: { transportUnitMeasurement: data["transportUnitMeasurement"] }
                });
                dispatch({
                    type: "SET_ELECTRICAL_ITEMS_SOURCE_TYPE",
                    payload: { electricalItemsSourceType: data["electricalItemsSourceType"] }
                });
                dispatch({
                    type: "SET_ELECTRICAL_ITEMS_MATERIAL_USE",
                    payload: { electricalItemsMaterialUse: data["electricalItemsMaterialUse"] }
                });
                dispatch({
                    type: "SET_ELECTRICAL_ITEMS_WASTE_DISPOSAL",
                    payload: { electricalItemsWasteDisposal: data["electricalItemsWasteDisposal"] }
                });
                dispatch({
                    type: "SET_ELECTRICAL_ITEMS_MATERIALUSE_MASTER",
                    payload: { electricalItemsMaterialUseMaster: data["electricalItemsMaterialUseMaster"] }
                });
                dispatch({
                    type: "SET_ELECTRICAL_ITEMS_WASTEDISPOSAL_MASTER",
                    payload: { electricalItemsWasteDisposalMaster: data["electricalItemsWasteDisposalMaster"] }
                });
                dispatch({
                    type: "SET_ELECTRICAL_ITEMS_UNIT_OF_MEASUREMENT",
                    payload: { electricalItemsUnitMeasurement: data["electricalItemsUnitMeasurement"] }
                });
                dispatch({
                    type: "SET_AVIATION_TYPE",
                    payload: { aviationType: data["aviationType"] }
                });
                dispatch({
                    type: "SET_AVIATION_DISTANCE_TYPE",
                    payload: { aviationDistanceType: data["aviationDistanceType"] }
                });
                dispatch({
                    type: "SET_AVIATION_CLASS_FACTOR",
                    payload: { aviationClassFactor: data["aviationClassFactor"] }
                });
                dispatch({
                    type: "SET_AVIATION_CLASS_FACTOR_MASTER",
                    payload: { aviationClassFactorMaster: data["aviationClassFactorMaster"] }
                });
                dispatch({
                    type: "SET_AVIATION_FREIGHT_FACTOR",
                    payload: { aviationFreightFactor: data["aviationFreightFactor"] }
                });
                dispatch({
                    type: "SET_AVIATION_FREIGHT_FACTOR_MASTER",
                    payload: { aviationFreightFactorMaster: data["aviationFreightFactorMaster"] }
                });
                dispatch({
                    type: "SET_SEA_FREIGHT_ACTIVITY",
                    payload: { seaFreightActivity: data["seaFreightActivity"] }
                });
                dispatch({
                    type: "SET_SEA_FREIGHT_TYPE",
                    payload: { seaFreightType: data["seaFreightType"] }
                });
                dispatch({
                    type: "SET_SEA_FREIGHT_SEA_TANKER_SIZE",
                    payload: { seaFreightSeaTankerSize: data["seaFreightSeaTankerSize"] }
                });
                dispatch({
                    type: "SET_SEA_FREIGHT_SEA_TANKER_SIZE_MASTER",
                    payload: { seaFreightSeaTankerSizeMaster: data["seaFreightSeaTankerSizeMaster"] }
                });
                dispatch({
                    type: "SET_SEA_FREIGHT_CARGO_SHIP_SIZE",
                    payload: { seaFreightCargoShipSize: data["seaFreightCargoShipSize"] }
                });
                dispatch({
                    type: "SET_SEA_FREIGHT_CARGO_SHIP_SIZE_MASTER",
                    payload: { seaFreightCargoShipSizeMaster: data["seaFreightCargoShipSizeMaster"] }
                });
                return true;
            })
            .then(status => {
                if (status === true) {
                    dispatch({
                        type: "SET_MASTER_STATUS",
                        payload: { status: true }
                    });
                } else {
                    dispatch({
                        type: "SET_MASTER_STATUS",
                        payload: { status: false }
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: "SET_MASTER_STATUS",
                    payload: { status: false }
                });
            });
    }
}

export default getAllMasters;