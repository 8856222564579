import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FocusTrap from "focus-trap-react";

export const confirm = (question, answer1, answer2, callback1, callback2) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <FocusTrap>
                    <div className="react-confirm-alert-body confirm trap">
                        <p style={{ padding: "20px", fontSize: "14px" }}>{question}</p>
                        <div className="react-confirm-alert-button-group">
                            <button className="cancelBtn" onClick={() => { callback1(); onClose(); }}>{answer1}</button>
                            <button className="cancelBtn" onClick={() => { callback2(); onClose(); }}>{answer2}</button>
                        </div>
                    </div>
                </FocusTrap>
            );
        },
        closeOnEscape: true,
        closeOnClickOutside: true
    });
};