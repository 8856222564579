const { isNumeric } = require('./utils.js');

require('./utils.js')

/**
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for fuel used.
 * @param  {number} volume Volume (litres) [user(text)]
 * @param  {number} consumptionFactor Consumption factor (KgCO<sub>2</sub>e/l) [fuel type based]
 * @param  {number} upstreamFactor Upstream factor (KgCO<sub>2</sub>e/l) [fuel type based]
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)     
 */
function footprintForFuelByVolume(volume, consumptionFactor, upstreamFactor) {
  if (!isNumeric(volume)) throw TypeError("'volume' must be numeric")
  if (!isNumeric(consumptionFactor)) throw TypeError("'consumptionFactor' must be numeric")
  if (!isNumeric(upstreamFactor)) throw TypeError("''upstreamFactor'' must be numeric")
  // if (volume < 0) throw RangeError("'volume' can't be less than 0")
  if (consumptionFactor < 0) throw RangeError("'consumptionFactor' can't be less than 0")
  if (upstreamFactor < 0) throw RangeError("'upstreamFactor' can't be less than 0")

  return volume * (
    consumptionFactor +
    upstreamFactor
  );
}

/**
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for fuel used.
 * @param  {number} value Value spent on fuel (euros) [user(text)]
 * @param  {number} price Price of fuel (euros/litre) [user(text) or API]
 * @param  {number} consumptionFactor Consumption factor (KgCO<sub>2</sub>e/l) [fuel type based]
 * @param  {number} upstreamFactor Upstream factor (KgCO<sub>2</sub>e/l) [fuel type based]
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */
function footprintForFuelByValue(value, price, consumptionFactor, upstreamFactor) {
  if (!isNumeric(value)) throw TypeError("'value' must be numeric")
  if (!isNumeric(price)) throw TypeError("'price' must be numeric")
  // if (value < 0) throw RangeError("'value' can't be less than 0")
  // if (price < 0) throw RangeError("'price' can't be less than 0")
  return footprintForFuelByVolume(value / price, consumptionFactor, upstreamFactor);
}

/**
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for fuel used.
 * @param  {number} distance Distance travelled (km) [user(text)]
 * @param  {number} efficiency Fuel efficiency ((l|kWH)/100km) [user(text)]
 * @param  {number} consumptionFactor Consumption factor (KgCO<sub>2</sub>e/(l|kwH)) [fuel type based]
 * @param  {number} upstreamFactor Upstream factor (KgCO<sub>2</sub>e/(l|kWH)) [fuel type based]
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */
function footprintForFuelByDistance(distance, efficiency, consumptionFactor, upstreamFactor) {
  if (!isNumeric(distance)) throw TypeError("'distance' must be numeric")
  if (!isNumeric(efficiency)) throw TypeError("'efficiency' must be numeric")
  // if (distance < 0) throw RangeError("'distance' can't be less than 0")
  if (efficiency < 0) throw RangeError("'efficiency' can't be less than 0")
  return footprintForFuelByVolume(distance * 100 / efficiency, consumptionFactor, upstreamFactor);
}

function footprintForFuelLaden(distance,scope1Laden0, scope1Laden100,scope3Laden0,scope3Laden100,inputLaden) {
  if (!isNumeric(scope1Laden0) || !isNumeric(scope1Laden100) ||!isNumeric(scope3Laden0) ||!isNumeric(scope3Laden100) || !isNumeric(inputLaden)) {
    throw TypeError("All inputs must be numeric");
  }
  
  if (inputLaden <0 || inputLaden >100 ) {
    return 0
  }

  const scope1 = scope1Laden0 + ((scope1Laden100 - scope1Laden0) / 100) * inputLaden;
  const scope3 = scope3Laden0 + ((scope3Laden100 - scope3Laden0) / 100) * inputLaden;
 
  return distance * ( scope1 + scope3);
}

function footprintForFuelByDistanceNew(distance,scope1,scope3) {
  if (!isNumeric(distance)) throw TypeError("'distance' must be numeric")
  if (!isNumeric(scope1)) throw TypeError("'scope1' must be numeric")
  if (!isNumeric(scope3)) throw TypeError("'scope3' must be numeric")
  if (scope1 < 0) throw RangeError("'scope1' can't be less than 0") 
  if (scope3 < 0) throw RangeError("'scope3' can't be less than 0")

  return distance * ( scope1 + scope3);
  
}

/** 
 * Calculates total carbon footprint (kgCO<sub>2</sub>e) for fuel used. Aggregates all electricity-to-footprint formulas.
 * @see footprintForFuelByVolume
 * @see footprintForFuelByValue
 * @see footprintForFuelByDistance
 * @param  {number} consumptionFactor Consumption factor (litres) [fuel type based]
 * @param  {number} upstreamFactor Upstream factor (litres) [fuel type based] 
 *                  (if electric, provide scope 2+3 factors for a given country (which is taken from project details); otherwise just scope 3 factor)
 * @return {number} Carbon footprint (kgCO<sub>2</sub>e)
 */
function footprintForFuel(volume, value, price, distance, efficiency, consumptionFactor, upstreamFactor,laden,scope1, scope3,scope1Laden0, scope1Laden100,scope3Laden0,scope3Laden100,inputLaden) {
  if (volume != null) {
    return footprintForFuelByVolume(volume, consumptionFactor, upstreamFactor)
  } else if (value != null && price != null) {
    return footprintForFuelByValue(value, price, consumptionFactor, upstreamFactor)
  } else if (distance != null && efficiency != null) {
    return footprintForFuelByDistance(distance, efficiency, consumptionFactor, upstreamFactor)
  } else if (distance != null) {
    return footprintForFuelByDistanceNew (distance,laden,scope1,scope3)
  } else if(distance != null && laden != null){
    return footprintForFuelLaden(distance,scope1,scope3,scope1Laden0, scope1Laden100,scope3Laden0,scope3Laden100,inputLaden)
  }
  else throw new Error("Not enough data to calculate");
  
}

module.exports = {
  footprintForFuelByVolume: footprintForFuelByVolume,
  footprintForFuelByDistance: footprintForFuelByDistance,
  footprintForFuelByDistanceNew: footprintForFuelByDistanceNew,
  footprintForFuelLaden:footprintForFuelLaden,
  footprintForFuelByValue: footprintForFuelByValue,
  footprintForFuel: footprintForFuel,
}