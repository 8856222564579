import React from "react";
import Toast from "react-bootstrap/toast";
import "./ToastCustom.css";

export default function ToastCustom(props) {
    let styles = {};

    if (props.variant === "warning") {
        styles = { backgroundColor: "#f7f5d5", color: "rgb(212 159 0)", borderColor: "#ffc107" }
    }
    if (props.variant === "bw") {
        styles = { backgroundColor: "#525252", color: "#f0f0f0", borderColor: "#f0f0f0" }
    }

    return (
        <Toast className="custom-toast" style={styles} onClose={props.onClose} show={props.show} delay={props.delay} autohide={props.autoHide}>
            <Toast.Header style={styles}>
                <span style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <i className="fa fa-info-circle" aria-hidden="true" style={{ marginTop: "4px", color: props.header === "Success" ? "lightgreen" : (props.header === "Error" || props.header === "Alert") ? "orange" : "unset" }}>&nbsp;&nbsp;</i>
                    <strong className="mr-auto" style={{ color: props.header === "Success" ? "lightgreen" : (props.header === "Error" || props.header === "Alert") ? "orange" : "unset" }}>{props.header}&emsp;&emsp;&emsp;</strong>
                    <small style={{ marginTop: "1.5px" }}>just now</small>
                </span>
            </Toast.Header>
            <Toast.Body>{props.body}</Toast.Body>
        </Toast>
    )
}
