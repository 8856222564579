import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import { Controller } from "react-hook-form";
import Select from "../select/SelectCustom";
import Collapse from "react-bootstrap/collapse";
import convert from "convert-units";
import { Element } from "react-scroll";
import { footprintForAviationByPassenger } from "../../calculatorFunctions/aviation_calc";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const CollapseAviationPassengerCustom = (props) => {

    let { collapseControll, id, inputName, passengerVal, inputKm, label,distanceLabel, inputAveragePassenger, inputEconomyClass, inputPremiumEconomyClass, inputBusinessClass, inputFirstClass, inputPlaceholder, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [km, setKm] = useState((inputKm === undefined || inputKm === "") ? 0 : inputKm);
    const [averagePassenger, setInputAveragePassenger] = useState((inputAveragePassenger === undefined || inputAveragePassenger === "") ? 0 : inputAveragePassenger);
    const [economyClass, setInputEconomyClass] = useState((inputEconomyClass === undefined || inputEconomyClass === "") ? 0 : inputEconomyClass);
    const [premiumEconomyClass, setInputPremiumEconomyClass] = useState((inputPremiumEconomyClass === undefined || inputPremiumEconomyClass === "") ? 0 : inputPremiumEconomyClass);
    const [businessClass, setInputBusinessClass] = useState((inputBusinessClass === undefined || inputBusinessClass === "") ? 0 : inputBusinessClass);
    const [firstClass, setInputFirstClass] = useState((inputFirstClass === undefined || inputFirstClass === "") ? 0 : inputFirstClass);

    const inputKmChange = (val) => {
        setKm((val !== undefined && val !== "") ? val : 0);
    }

    const inputAveragePassengerChange = (val) => {
        setInputAveragePassenger((val !== undefined && val !== "") ? val : 0);
    }

    const inputEconomyClassChange = (val) => {
        setInputEconomyClass((val !== undefined && val !== "") ? val : 0);
    }

    const inputPremiumEconomyClassChange = (val) => {
        setInputPremiumEconomyClass((val !== undefined && val !== "") ? val : 0);
    }

    const inputBusinessClassChange = (val) => {
        setInputBusinessClass((val !== undefined && val !== "") ? val : 0);
    }

    const inputFirstClassChange = (val) => {
        setInputFirstClass((val !== undefined && val !== "") ? val : 0);
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    const aviationDistance = [
        { value: 'Average Passenger', label: 'Unknown Class' },
        { value: 'Economy Class', label: 'Economy Class' },
        { value: 'Premium Economy Class', label: 'Premium Economy Class' },
        { value: 'Business Class', label: 'Business Class' },
        { value: 'First Class', label: 'First Class' },
    ];

    const setCF = () => {
        var cf = 0;
        let name = aviationDistance.map(item => {
            return `Passenger_${inputName}_${item.value}`;
        });
        let AvgPassenger = averagePassenger === undefined || averagePassenger === "" ? 0 : averagePassenger;
        let EcoPassenger = economyClass === undefined || economyClass === "" ? 0 : economyClass;
        let PreEcoPassenger = premiumEconomyClass === undefined || premiumEconomyClass === "" ? 0 : premiumEconomyClass;
        let BCPassenger = businessClass === undefined || businessClass === "" ? 0 : businessClass;
        let FCPassenger = firstClass === undefined || firstClass === "" ? 0 : firstClass;
        let distance = km === undefined || km === "" ? 0 : km;

        let emissionFactorAvg = props.master.aviationClassFactorMaster.hasOwnProperty(name[0]) && props.master.aviationClassFactorMaster[name[0]].emissionFactor !== undefined ? props.master.aviationClassFactorMaster[name[0]].emissionFactor : 0;
        let emissionFactorWTTAvg = props.master.aviationClassFactorMaster.hasOwnProperty(name[0]) && props.master.aviationClassFactorMaster[name[0]].emissionFactorWTT !== undefined ? props.master.aviationClassFactorMaster[name[0]].emissionFactorWTT : 0;
        let emissionFactorEco = props.master.aviationClassFactorMaster.hasOwnProperty(name[1]) && props.master.aviationClassFactorMaster[name[1]].emissionFactor !== undefined ? props.master.aviationClassFactorMaster[name[1]].emissionFactor : 0;
        let emissionFactorWTTEco = props.master.aviationClassFactorMaster.hasOwnProperty(name[1]) && props.master.aviationClassFactorMaster[name[1]].emissionFactorWTT !== undefined ? props.master.aviationClassFactorMaster[name[1]].emissionFactorWTT : 0;
        let emissionFactorPreEco = props.master.aviationClassFactorMaster.hasOwnProperty(name[2]) && props.master.aviationClassFactorMaster[name[2]].emissionFactor !== undefined ? props.master.aviationClassFactorMaster[name[2]].emissionFactor : 0;
        let emissionFactorWTTPreEco = props.master.aviationClassFactorMaster.hasOwnProperty(name[2]) && props.master.aviationClassFactorMaster[name[2]].emissionFactorWTT !== undefined ? props.master.aviationClassFactorMaster[name[2]].emissionFactorWTT : 0;
        let emissionFactorBC = props.master.aviationClassFactorMaster.hasOwnProperty(name[3]) && props.master.aviationClassFactorMaster[name[3]].emissionFactor !== undefined ? props.master.aviationClassFactorMaster[name[3]].emissionFactor : 0;
        let emissionFactorWTTBC = props.master.aviationClassFactorMaster.hasOwnProperty(name[3]) && props.master.aviationClassFactorMaster[name[3]].emissionFactorWTT !== undefined ? props.master.aviationClassFactorMaster[name[3]].emissionFactorWTT : 0;
        let emissionFactorFC = props.master.aviationClassFactorMaster.hasOwnProperty(name[4]) && props.master.aviationClassFactorMaster[name[4]].emissionFactor !== undefined ? props.master.aviationClassFactorMaster[name[4]].emissionFactor : 0;
        let emissionFactorWTTFC = props.master.aviationClassFactorMaster.hasOwnProperty(name[4]) && props.master.aviationClassFactorMaster[name[4]].emissionFactorWTT !== undefined ? props.master.aviationClassFactorMaster[name[4]].emissionFactorWTT : 0;
        let source = props.master.aviationClassFactorMaster.hasOwnProperty(name[0]) && props.master.aviationClassFactorMaster[name[0]].source !== undefined ? props.master.aviationClassFactorMaster[name[0]].source : null;
        let year = props.master.aviationClassFactorMaster.hasOwnProperty(name[0]) && props.master.aviationClassFactorMaster[name[0]].year !== undefined ? props.master.aviationClassFactorMaster[name[0]].year : 0;
        let aviationTypeVal = props.master.aviationClassFactorMaster.hasOwnProperty(name[0]) && props.master.aviationClassFactorMaster[name[0]].aviationType !== undefined ? props.master.aviationClassFactorMaster[name[0]].aviationType.name : null;
        let aviationDistanceTypeVal = props.master.aviationClassFactorMaster.hasOwnProperty(name[0]) && props.master.aviationClassFactorMaster[name[0]].aviationDistanceType !== undefined ? props.master.aviationClassFactorMaster[name[0]].aviationDistanceType.name : null;

        cf = footprintForAviationByPassenger(distance, AvgPassenger,
            EcoPassenger, PreEcoPassenger,
            BCPassenger, FCPassenger,
            emissionFactorAvg, emissionFactorWTTAvg,
            emissionFactorEco, emissionFactorWTTEco,
            emissionFactorPreEco, emissionFactorWTTPreEco,
            emissionFactorBC, emissionFactorWTTBC,
            emissionFactorFC, emissionFactorWTTFC);

        if (passengerVal === "passenger") {
            props.cFootprint({
                name: inputName,
                parent: "passenger",
                cf: {
                    value: cf, distance, AvgPassenger,
                    EcoPassenger, PreEcoPassenger,
                    BCPassenger, FCPassenger,
                    emissionFactorAvg, emissionFactorWTTAvg,
                    emissionFactorEco, emissionFactorWTTEco,
                    emissionFactorPreEco, emissionFactorWTTPreEco,
                    emissionFactorBC, emissionFactorWTTBC,
                    emissionFactorFC, emissionFactorWTTFC, year, source,
                    aviationTypeVal, aviationDistanceTypeVal,

                },
            });
        }
        setCarbonFootprint(cf);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let isFollowSetCF = false;
            if ((km !== undefined && km !== "") ||
                (averagePassenger !== undefined && averagePassenger !== "") ||
                (economyClass !== undefined && economyClass !== "") ||
                (premiumEconomyClass !== undefined && premiumEconomyClass !== "") ||
                (businessClass !== undefined && businessClass !== "") ||
                (firstClass !== undefined && firstClass !== "")
            ) {
                isFollowSetCF = true;
            } else {
                isFollowSetCF = false;
            }
            if (isFollowSetCF === true) {
                setCF();
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [km, averagePassenger, economyClass, premiumEconomyClass, businessClass, firstClass]);

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    return (
        <Collapse in={collapseControll}>
            <Element name={inputName !== "km" ? `scrollAviationPassenger${inputName.charAt(0).toUpperCase() + inputName.slice(1)}` : "scrollAviationPassenger"} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", fontWeight: "bold", color: "#6f91ad", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label} </span>
                    <Row>
                        <Col lg={10} md={6} sm={12}  >
                            <div className={`${id}-${inputName}-passengerCollapse`} >
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-averagePassenger`} name={`${id}-${inputName}-averagePassenger`} label="Unknown Class" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "The number should be greater than or equal to 0" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputAveragePassengerChange(e.target.value)} />
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-economyClass`} name={`${id}-${inputName}-economyClass`} label="Economy Class" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "The number should be greater than or equal to 0" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputEconomyClassChange(e.target.value)} />
                                {inputName === "Shorthaul" ? <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-businessClass`} name={`${id}-${inputName}-businessClass`} label="Business Class" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "The number should be greater than or equal to 0" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "190px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputBusinessClassChange(e.target.value)} /> :
                                    <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-premiumEconomyClass`} name={`${id}-${inputName}-premiumEconomyClass`} label="Premium Economy Class" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "The number should be greater than or equal to 0" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "25%", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputPremiumEconomyClassChange(e.target.value)} />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <div className={`${id}-${inputName}-passengerCollapse`} >
                                {inputName === "Shorthaul" ? null :
                                    <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-businessClass`} name={`${id}-${inputName}-businessClass`} label="Business Class" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "The number should be greater than or equal to 0" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "190px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputBusinessClassChange(e.target.value)} />
                                }
                                {inputName === "Shorthaul" ? null :
                                    <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-firstClass`} name={`${id}-${inputName}-firstClass`} label="First Class" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 ? true : "The number should be greater than or equal to 0" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "190px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputFirstClassChange(e.target.value)} />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span style={{ backgroundColor: "black", fontWeight: "bold", color: "#6f91ad", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{distanceLabel}</span>
                            <div className={`${id}-${inputName}-passengerCollapse`} >
                                <Input className="numberType" onWheel={(e) => onWheel(e)} type="number" id={`${id}-${inputName}-km`} name={`${id}-${inputName}-km`} label="KM" placeholder={inputPlaceholder} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 1 ? true : "The number should be greater than or equal to 1" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ width: "190px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px", fontSize: "16px" }} onChange={(e) => inputKmChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col  >
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                            </div>
                        </Col>
                    </Row>
                    <hr style={{
                        color: '#6f91ad',
                        backgroundColor: '#6f91ad',
                        height: .5,
                        borderColor: '#6f91ad'
                    }} />
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master
    };
}

export default connect(mapStateToProps)(memo(CollapseAviationPassengerCustom));