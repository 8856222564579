import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Input from "../input/InputCustom";
import Collapse from "react-bootstrap/collapse";
import { Element } from "react-scroll";
import { footprintForBusinessTravel, footprintForCommuting, footprintForDigital, footprintForOffice, footprintForWfh, footprintForGoodsAndServices } from "../../calculatorFunctions/people_calc";
import Switch from '@mui/material/Switch';
import "./CollapseDeliveryPeopleCustom.css";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
}

function isInt(n) {
    return n % 1 === 0;
}

const switchStyle = {
    borderRadius: 2,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: '#7444DA'
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: '#a186d9'
    },
  }

var startDate;
var endDate;
var totalMonths = 1;

const CollapseDeliveryPeopleCustom = (props) => {
    let { collapseControll, id, label, inputName, inputMembersValue, inputPlaceholder, inputDaysValue, goodsAndServicesToggleVal, businessTravelToggleVal, register, errors } = props;
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [members, setMembers] = useState(inputMembersValue === undefined || inputMembersValue === "" || inputMembersValue === null ? 0 : inputMembersValue);
    const [days, setDays] = useState(inputDaysValue === undefined || inputDaysValue === "" || inputDaysValue === null ? 0 : inputDaysValue);
    const [isGoodsAndServicesIncluded, setIsGoodsAndServicesIncluded] = useState(goodsAndServicesToggleVal === undefined || goodsAndServicesToggleVal === "" || goodsAndServicesToggleVal === null ? false : goodsAndServicesToggleVal);
    const [isBusinessTravelIncluded, setIsBusinessTravelIncluded] = useState(businessTravelToggleVal === undefined || businessTravelToggleVal === "" || businessTravelToggleVal === null ? false : businessTravelToggleVal);

    const onMembersChange = (val) => {
        setMembers((val !== undefined && val !== "" && parseFloat(val) >= 0 ) ? val : 0);
    }

    const onDaysChange = (val) => {
        setDays((val !== undefined && val !== "" && parseFloat(val) <= 5 && parseFloat(val) >= 0) ? parseFloat(val, 10) : 0);
    }

    const onWheel = (e) => {
        e.currentTarget.blur();
    }

    const setCF = (isGoodsAndServicesIncluded, isBusinessTravelIncluded) => {
        var cf = 0;
        var businessTravel = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].businessTravel !== undefined ? props.master.peopleFactorMaster[inputName].businessTravel : 0;
        var office = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].office !== undefined ? props.master.peopleFactorMaster[inputName].office : 0;
        var commute = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].commute !== undefined ? props.master.peopleFactorMaster[inputName].commute : 0;
        var dataCentre = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].dataCentre !== undefined ? props.master.peopleFactorMaster[inputName].dataCentre : 0;
        var goodsAndServices = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].goodsAndServices !== undefined ? props.master.peopleFactorMaster[inputName].goodsAndServices : 0;
        var wfh = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].wfh !== undefined ? props.master.peopleFactorMaster[inputName].wfh : 0;
        var year = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].businessTravelYear !== undefined ? props.master.peopleFactorMaster[inputName].businessTravelYear : new Date().getFullYear();
        var source = props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].businessTravelSource !== undefined ? props.master.peopleFactorMaster[inputName].businessTravelSource : null;
        var subValue = {};
        var tot = 0;
        var avgDaysWFHPerWeek =  props.master.peopleFactorMaster[inputName] !== undefined && props.master.peopleFactorMaster[inputName].avgDaysWFHPerWeek !== undefined ? props.master.peopleFactorMaster[inputName].avgDaysWFHPerWeek : 0;

        if(isBusinessTravelIncluded) {
            tot += subValue["footprintForBusinessTravel"] = parseFloat(parseFloat(((footprintForBusinessTravel(members, days, businessTravel) / 1000) / 12) * totalMonths).toFixed(3));
        } else {
            tot += subValue["footprintForBusinessTravel"] = 0;
        }
        tot += subValue["footprintForOffice"] = parseFloat(parseFloat(((footprintForOffice(members, office) / 1000) / 12) * totalMonths).toFixed(3));
        tot += subValue["footprintForCommuting"] = parseFloat(parseFloat(((footprintForCommuting(members, days, commute, avgDaysWFHPerWeek) / 1000) / 12) * totalMonths).toFixed(3));
        tot += subValue["footprintForWfh"] = parseFloat(parseFloat(((footprintForWfh(members, days, wfh, avgDaysWFHPerWeek) / 1000) / 12) * totalMonths).toFixed(3));
        tot += subValue["footprintForDataCentre"] = parseFloat(parseFloat(((footprintForDigital(members, dataCentre) / 1000) / 12) * totalMonths).toFixed(3));
        tot += subValue["footprintForGoodsAndServices"] = parseFloat(parseFloat(((footprintForGoodsAndServices(members, goodsAndServices) / 1000) / 12) * totalMonths).toFixed(3));
        // if (isGoodsAndServicesIncluded) {
        //     tot += subValue["footprintForGoodsAndServices"] = parseFloat(parseFloat(((footprintForGoodsAndServices(members, goodsAndServices) / 1000) / 12) * totalMonths).toFixed(3));
        // } else {
        //     tot += subValue["footprintForGoodsAndServices"] = 0;
        // }
        subValue["total"] = tot;
        cf = tot;
        props.cFootprint({ name: inputName, parent: "deliveryPeople", cf: { value: cf, subValue, businessTravel, office, commute, dataCentre, goodsAndServices, avgDaysWFHPerWeek, isGoodsAndServicesIncluded, isBusinessTravelIncluded, wfh, year, source } });
        setCarbonFootprint(cf);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (members !== undefined && members !== "" && days !== undefined && days !== "") {
                startDate = new Date(props.project.currentProjectStartDate);
                endDate = new Date(props.project.currentProjectEndDate);
                totalMonths = monthDiff(startDate, endDate) + 1;
                setCF(
                    isGoodsAndServicesIncluded,
                    isBusinessTravelIncluded
                );
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line
    }, [members, days, props.project.currentProjectStartDate, props.project.currentProjectEndDate, isGoodsAndServicesIncluded, isBusinessTravelIncluded]);

    return (
        <Collapse in={collapseControll}>
            <Element name={`scrollDeliveryDeliverypeople${inputName.replace(" ", "")}`} className="element" >
                <div style={{ margin: "10px", marginLeft: "20px", textAlign: "left", height: "100%" }}>
                    <span style={{ backgroundColor: "black", color: "#CBB8F4", paddingLeft: "0.5rem", borderRadius: "0.25rem", paddingTop: "0.25rem", paddingRight: "0.5rem", paddingBottom: "0.25rem" }}>{label}</span>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className={`${id}-${inputName}-deliveryPeopleCollapse`} >
                                <Input className="numberType" type="number" id={`${id}-${inputName}-members`} name={`${id}-${inputName}-members`} label={"Number of project members"} placeholder={inputPlaceholder} onWheel={(e) => onWheel(e)} tooltip={true} tooltipMessage="If resource start or end dates are staggered, estimate the number of full time team members for the duration of the project." register={register({ validate: { positive: (value) => value !== "" ? value >= 0 ? true : "Only non-negative integer is allowed" : true } })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => onMembersChange(e.target.value)} />
                                <Input className="numberType" type="number" id={`${id}-${inputName}-days`} name={`${id}-${inputName}-days`} label={"Average days working from home"} placeholder={inputPlaceholder} onWheel={(e) => onWheel(e)} tooltip={true} register={register({ validate: (value) => value !== "" ? value >= 0 && value <= 5 ? true : "Only non-negative number between 0 - 5 is allowed" : true })} errors={errors} labelStyle={{ fontSize: "10px" }} formGroupStyle={{ flexGrow: 1, width: "185px", marginRight: "10px" }} style={{ textAlign: "right", borderBottomWidth: "3px", height: "40px" }} onChange={(e) => onDaysChange(e.target.value)} />
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={12} style={{ paddingTop: "44px", paddingLeft: "0px", paddingRight: "0px", color: "#eb4f6b" }}>
                            {/* {
                                props.master.electricityFactorMaster[inputName] === undefined ? (
                                    <span>This country is currently not available !</span>
                                ) : (<></>)
                            } */}
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                            <div className="carbonFootprint" >
                                <span>Carbon Footprint</span>
                                <br />
                                <br />
                                <h5 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(parseFloat(carbonFootprint).toFixed(3))}</h5>
                                <span style={{ fontSize: "8px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e emitted / project duration</span>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <span className="toggleTextGS">Purchased Goods & Services</span>
                        <span className="toggleTextNotInclude">Don't Include</span>
                        <Switch checked={isGoodsAndServicesIncluded} color="default" sx={switchStyle}
                            onChange={(event) => {
                                setIsGoodsAndServicesIncluded(event.target.checked)
                                setCF(event.target.checked)
                            }}
                        />
                        <span className="toggleTextInclude">Include</span>
                    </Row> */}
                    <Row>
                        <span className="toggleTextGS">Business Travel</span>
                        <span className="businessTraveltoggleTextNotInclude">Don't Include</span>
                        <Switch checked={isBusinessTravelIncluded} color="default" sx={switchStyle}
                            onChange={(event) => {
                                setIsBusinessTravelIncluded(event.target.checked)
                                setCF(event.target.checked)
                            }}
                        />
                        <span className="toggleTextInclude">Include</span>
                    </Row>
                </div>
            </Element>
        </Collapse>
    )
}

const mapStateToProps = (state) => {
    return {
        master: state.master,
        project: state.project
    };
}

export default connect(mapStateToProps)(CollapseDeliveryPeopleCustom);