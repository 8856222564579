import React, { useState, useReducer, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import axios from "axios";
import Tabs from "react-bootstrap/tabs";
import Tab from "react-bootstrap/tab";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Nav from "react-bootstrap/nav";
import "./DataCentreTabs.css";
import Select from "../select/SelectCustomCountry";
import CollapseDataCentreMigration from "./CollapseDataCentreMigrationCustom";
import errorBoundaryWrapper from "../../utilities/errorBoundaryWrapper/errorBoundaryWrapper";
import ErrorBoundaryForPage from "../errorBoundaries/ErrorBoundaryForPage";
import { mainCountries } from "../../calculatorFunctions/mainCountries";
import { deleteConfirm } from "../deleteConfirm";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const environment = process.env.REACT_APP_API_ENVIRONMENT;
const basePath = environment === "development" ? process.env.REACT_APP_API_ROOT_PATH_DEV : process.env.REACT_APP_API_ROOT_PATH_PROD;

function useIsMountedRef() {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });
    return isMountedRef;
}

const collapseReducer = (state, action) => {
    switch (action.type) {
        case "COLLAPSE_ADD":
            state = {
                ...state,
                [action.payload.name]: { parent: action.payload.parent, cf: {}, status: false }
            };
            break;
        case "COLLAPSE_REMOVE":
            var temp = { ...state };
            delete temp[action.payload.name];
            state = temp;
            if (Object.keys(state).length === 0) {
                state = {};
            }
            break;
        case "COLLAPSE_SET_PARENT":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent }
            };
            break;
        case "COLLAPSE_SET_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], status: action.payload.status }
            };
            break;
        case "COLLAPSE_SET_CF":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], cf: action.payload.cf }
            };
            break;
        case "COLLAPSE_SET_PARENT_STATUS":
            state = {
                ...state,
                [action.payload.name]: { ...state[action.payload.name], parent: action.payload.parent, status: action.payload.status }
            };
            break;
        default:
            break;
    }
    return state;
}

var componentId;
var sources = {};
var years = [];
var data = {};
var countryData = {};
var sTime = new Date();
var currentParent = "";

const DataCentreTabs = (props) => {

    const isMountedRef = useIsMountedRef();
    componentId = props.id;
    var projectId = props.projectId || props.project.currentProjectId;
    const [refresh, setRefresh] = useState(0);
    const [key, setKey] = useState("");
    const [collapseState, dispatchCollapseState] = useReducer(collapseReducer, {});
    const form = useForm({ mode: 'onChange', shouldFocusError: true });
    const { register, errors, getValues, setValue, control, formState, handleSubmit } = form;
    const [totalCarbonFootprint, setTotalCarbonFootprint] = useState(0);
    const [dataCentreSourceType, setDataCentreSourceType] = useState({});
    const [shouldRender, setShouldRender] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [savedTime, setSavedTime] = useState(new Date());
    const [monitorSavedTime, setMonitorSavedTime] = useState(false);
    const [lastSaved, setLastSaved] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [clickedSave, setClickedSave] = useState(false);

    const timeDiffCalc = (dateFuture, dateNow) => {
        dateFuture = new Date(dateFuture);
        dateNow = new Date(dateNow);
        var msec = dateFuture - dateNow;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        var yrs = Math.floor(days / 365);
        if (yrs !== 0) return `Last saved: ${yrs} ${yrs === 1 ? "year" : "years"}`;
        if (days !== 0) return `Last saved: ${days} ${days === 1 ? "day" : "days"}`;
        if (hrs !== 0) return `Last saved: ${hrs} ${hrs === 1 ? "hour" : "hours"}`;
        if (mins !== 0) return `Last saved: ${mins} ${mins === 1 ? "minute" : "minutes"}`;
        return `Last saved: just now`;
    }

    const getLogic = (item, parent) => {
        return (collapseState[item].parent === parent && collapseState[item].status === true)
    }

    const filterCountry = (inputValue) => {
        const grouped = groupBy(countries, country => country.label);
        const top = grouped.get("top")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        const bottom = grouped.get("bottom")[0].options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return [
            {
                label: "top",
                options: top
            },
            {
                label: "bottom",
                options: bottom
            }
        ];
    };

    const getCountryOptionsData = (inputValue, callback) => {
        callback(filterCountry(inputValue));
    }

    const saveData = () => {
        setClickedSave(false);
        if (projectId !== undefined && projectId !== "") {
            data["dataSource"] = key.split("-")[(key.split("-").length - 1)];
            data["data"] = {};
            Object.keys(collapseState).forEach(item => {
                if (collapseState[item].parent === "migration") {
                    data["data"] = {
                        ...data["data"],
                        [item]: {
                            status: collapseState[item].status,
                            dataSource: collapseState[item].parent,
                            physicalServerBefore: getValues(`${key}-${item}-physical-before`),
                            physicalServerAfter: getValues(`${key}-${item}-physical-after`),
                            privateServerBefore: getValues(`${key}-${item}-private-before`),
                            privateServerAfter: getValues(`${key}-${item}-private-after`),
                            publicServerBefore: getValues(`${key}-${item}-public-before`),
                            publicServerAfter: getValues(`${key}-${item}-public-after`),
                            smallPhysicalServerBefore: getValues(`${key}-${item}-small-before`),
                            smallPhysicalServerAfter: getValues(`${key}-${item}-small-after`),
                            mediumPhysicalServerBefore: getValues(`${key}-${item}-medium-before`),
                            mediumPhysicalServerAfter: getValues(`${key}-${item}-medium-after`),
                            largePhysicalServerBefore: getValues(`${key}-${item}-large-before`),
                            largePhysicalServerAfter: getValues(`${key}-${item}-large-after`),
                            physicalServerUtilizationBefore: getValues(`${key}-${item}-physicalUtilization-before`),
                            physicalServerUtilizationAfter: getValues(`${key}-${item}-physicalUtilization-after`),
                            privateServerUtilizationBefore: getValues(`${key}-${item}-privateUtilization-before`),
                            privateServerUtilizationAfter: getValues(`${key}-${item}-privateUtilization-after`),
                            publicServerUtilizationBefore: getValues(`${key}-${item}-publicUtilization-before`),
                            publicServerUtilizationAfter: getValues(`${key}-${item}-publicUtilization-after`),
                            premisePueBefore: getValues(`${key}-${item}-premisePUE-before`),
                            premisePueAfter: getValues(`${key}-${item}-premisePUE-after`),
                            publicPueBefore: getValues(`${key}-${item}-publicPUE-before`),
                            publicPueAfter: getValues(`${key}-${item}-publicPUE-after`),
                            onPremiseSmallPhysicalPowerUsageBefore: collapseState[item].cf.onPremiseSmallPhysicalPowerUsageBefore,
                            onPremiseMediumPhysicalPowerUsageBefore: collapseState[item].cf.onPremiseMediumPhysicalPowerUsageBefore,
                            onPremiseLargePhysicalPowerUsageBefore: collapseState[item].cf.onPremiseLargePhysicalPowerUsageBefore,
                            onPremiseVirtualPowerUsageBefore: collapseState[item].cf.onPremiseVirtualPowerUsageBefore,
                            publicCloudPowerUsageBefore: collapseState[item].cf.publicCloudPowerUsageBefore,
                            onPremiseSmallPhysicalPowerUsageAfter: collapseState[item].cf.onPremiseSmallPhysicalPowerUsageAfter,
                            onPremiseMediumPhysicalPowerUsageAfter: collapseState[item].cf.onPremiseMediumPhysicalPowerUsageAfter,
                            onPremiseLargePhysicalPowerUsageAfter: collapseState[item].cf.onPremiseLargePhysicalPowerUsageAfter,
                            onPremiseVirtualPowerUsageAfter: collapseState[item].cf.onPremiseVirtualPowerUsageAfter,
                            publicCloudPowerUsageAfter: collapseState[item].cf.publicCloudPowerUsageAfter,
                            cfPhysicalServersBefore: collapseState[item].cf.cfPhysicalServersBefore,
                            cfPhysicalServersAfter: collapseState[item].cf.cfPhysicalServersAfter,
                            cfPrivateCloudServersBefore: collapseState[item].cf.cfPrivateCloudServersBefore,
                            cfPrivateCloudServersAfter: collapseState[item].cf.cfPrivateCloudServersAfter,
                            cfPublicCloudServersBefore: collapseState[item].cf.cfPublicCloudServersBefore,
                            cfPublicCloudServersAfter: collapseState[item].cf.cfPublicCloudServersAfter,
                            carbonFootprint: collapseState[item].cf.value,
                            assumptions: getValues(`${key}-${item}-assumptions`),
                            generation: collapseState[item].cf.generation,
                            tnd: collapseState[item].cf.tnd,
                            year: collapseState[item].cf.year
                        }
                    }
                }
            });
            const dataArray = data["data"];
            // console.log(dataArray[item][carbonFootprint],'dataArrayyyyyyyyyyyyyyyyyyyy');
            if (Object.keys(dataArray).length > 0) {
                if (Object.keys(dataArray).filter(item => dataArray[item].status === true).length > 0) {
                    Object.keys(dataArray).filter(item => dataArray[item].status === true).forEach(item => {
                        var jsonData = {};
                        if (dataCentreSourceType[dataArray[item]["dataSource"]] === "Migration") {
                            if ((dataArray[item]["physicalServerBefore"] || dataArray[item]["physicalServerAfter"] || dataArray[item]["privateServerBefore"] || dataArray[item]["privateServerAfter"] || dataArray[item]["publicServerBefore"] || dataArray[item]["publicServerAfter"] > 0)) { // && (dataArray[item]["carbonFootprint"] > 0)  
                                jsonData = {
                                    "projectId": projectId,
                                    "data": [
                                        {
                                            "physicalServerBefore": dataArray[item]["physicalServerBefore"] === undefined || dataArray[item]["physicalServerBefore"] === "" ? null : parseFloat(dataArray[item]["physicalServerBefore"]),
                                            "physicalServerAfter": dataArray[item]["physicalServerAfter"] === undefined || dataArray[item]["physicalServerAfter"] === "" ? null : parseFloat(dataArray[item]["physicalServerAfter"]),
                                            "privateServerBefore": dataArray[item]["privateServerBefore"] === undefined || dataArray[item]["privateServerBefore"] === "" ? null : parseFloat(dataArray[item]["privateServerBefore"]),
                                            "privateServerAfter": dataArray[item]["privateServerAfter"] === undefined || dataArray[item]["privateServerAfter"] === "" ? null : parseFloat(dataArray[item]["privateServerAfter"]),
                                            "publicServerBefore": dataArray[item]["publicServerBefore"] === undefined || dataArray[item]["publicServerBefore"] === "" ? null : parseFloat(dataArray[item]["publicServerBefore"]),
                                            "publicServerAfter": dataArray[item]["publicServerAfter"] === undefined || dataArray[item]["publicServerAfter"] === "" ? null : parseFloat(dataArray[item]["publicServerAfter"]),
                                            "smallPhysicalServerBefore": dataArray[item]["smallPhysicalServerBefore"] === undefined || dataArray[item]["smallPhysicalServerBefore"] === "" ? null : parseFloat(dataArray[item]["smallPhysicalServerBefore"]),
                                            "smallPhysicalServerAfter": dataArray[item]["smallPhysicalServerAfter"] === undefined || dataArray[item]["smallPhysicalServerAfter"] === "" ? null : parseFloat(dataArray[item]["smallPhysicalServerAfter"]),
                                            "mediumPhysicalServerBefore": dataArray[item]["mediumPhysicalServerBefore"] === undefined || dataArray[item]["mediumPhysicalServerBefore"] === "" ? null : parseFloat(dataArray[item]["mediumPhysicalServerBefore"]),
                                            "mediumPhysicalServerAfter": dataArray[item]["mediumPhysicalServerAfter"] === undefined || dataArray[item]["mediumPhysicalServerAfter"] === "" ? null : parseFloat(dataArray[item]["mediumPhysicalServerAfter"]),
                                            "largePhysicalServerBefore": dataArray[item]["largePhysicalServerBefore"] === undefined || dataArray[item]["largePhysicalServerBefore"] === "" ? null : parseFloat(dataArray[item]["largePhysicalServerBefore"]),
                                            "largePhysicalServerAfter": dataArray[item]["largePhysicalServerAfter"] === undefined || dataArray[item]["largePhysicalServerAfter"] === "" ? null : parseFloat(dataArray[item]["largePhysicalServerAfter"]),
                                            "physicalServerUtilizationBefore": dataArray[item]["physicalServerUtilizationBefore"] === undefined || dataArray[item]["physicalServerUtilizationBefore"] === "" ? null : parseFloat(dataArray[item]["physicalServerUtilizationBefore"]),
                                            "physicalServerUtilizationAfter": dataArray[item]["physicalServerUtilizationAfter"] === undefined || dataArray[item]["physicalServerUtilizationAfter"] === "" ? null : parseFloat(dataArray[item]["physicalServerUtilizationAfter"]),
                                            "privateServerUtilizationBefore": dataArray[item]["privateServerUtilizationBefore"] === undefined || dataArray[item]["privateServerUtilizationBefore"] === "" ? null : parseFloat(dataArray[item]["privateServerUtilizationBefore"]),
                                            "privateServerUtilizationAfter": dataArray[item]["privateServerUtilizationAfter"] === undefined || dataArray[item]["privateServerUtilizationAfter"] === "" ? null : parseFloat(dataArray[item]["privateServerUtilizationAfter"]),
                                            "publicServerUtilizationBefore": dataArray[item]["publicServerUtilizationBefore"] === undefined || dataArray[item]["publicServerUtilizationBefore"] === "" ? null : parseFloat(dataArray[item]["publicServerUtilizationBefore"]),
                                            "publicServerUtilizationAfter": dataArray[item]["publicServerUtilizationAfter"] === undefined || dataArray[item]["publicServerUtilizationAfter"] === "" ? null : parseFloat(dataArray[item]["publicServerUtilizationAfter"]),
                                            "premisePueBefore": dataArray[item]["premisePueBefore"] === undefined || dataArray[item]["premisePueBefore"] === "" ? null : parseFloat(dataArray[item]["premisePueBefore"]),
                                            "premisePueAfter": dataArray[item]["premisePueAfter"] === undefined || dataArray[item]["premisePueAfter"] === "" ? null : parseFloat(dataArray[item]["premisePueAfter"]),
                                            "publicPueBefore": dataArray[item]["publicPueBefore"] === undefined || dataArray[item]["publicPueBefore"] === "" ? null : parseFloat(dataArray[item]["publicPueBefore"]),
                                            "publicPueAfter": dataArray[item]["publicPueAfter"] === undefined || dataArray[item]["publicPueAfter"] === "" ? null : parseFloat(dataArray[item]["publicPueAfter"]),
                                            "smallPowerUsageBefore": dataArray[item]["onPremiseSmallPhysicalPowerUsageBefore"] === undefined || dataArray[item]["onPremiseSmallPhysicalPowerUsageBefore"] === "" ? null : parseFloat(dataArray[item]["onPremiseSmallPhysicalPowerUsageBefore"]).toFixed(3),
                                            "smallPowerUsageAfter": dataArray[item]["onPremiseSmallPhysicalPowerUsageAfter"] === undefined || dataArray[item]["onPremiseSmallPhysicalPowerUsageAfter"] === "" ? null : parseFloat(dataArray[item]["onPremiseSmallPhysicalPowerUsageAfter"]).toFixed(3),
                                            "mediumPowerUsageBefore": dataArray[item]["onPremiseMediumPhysicalPowerUsageBefore"] === undefined || dataArray[item]["onPremiseMediumPhysicalPowerUsageBefore"] === "" ? null : parseFloat(dataArray[item]["onPremiseMediumPhysicalPowerUsageBefore"]).toFixed(3),
                                            "mediumPowerUsageAfter": dataArray[item]["onPremiseMediumPhysicalPowerUsageAfter"] === undefined || dataArray[item]["onPremiseMediumPhysicalPowerUsageAfter"] === "" ? null : parseFloat(dataArray[item]["onPremiseMediumPhysicalPowerUsageAfter"]).toFixed(3),
                                            "largePowerUsageBefore": dataArray[item]["onPremiseLargePhysicalPowerUsageBefore"] === undefined || dataArray[item]["onPremiseLargePhysicalPowerUsageBefore"] === "" ? null : parseFloat(dataArray[item]["onPremiseLargePhysicalPowerUsageBefore"]).toFixed(3),
                                            "largePowerUsageAfter": dataArray[item]["onPremiseLargePhysicalPowerUsageAfter"] === undefined || dataArray[item]["onPremiseLargePhysicalPowerUsageAfter"] === "" ? null : parseFloat(dataArray[item]["onPremiseLargePhysicalPowerUsageAfter"]).toFixed(3),
                                            "privatePowerUsageBefore": dataArray[item]["onPremiseVirtualPowerUsageBefore"] === undefined || dataArray[item]["onPremiseVirtualPowerUsageBefore"] === "" ? null : parseFloat(dataArray[item]["onPremiseVirtualPowerUsageBefore"]).toFixed(3),
                                            "privatePowerUsageAfter": dataArray[item]["onPremiseVirtualPowerUsageAfter"] === undefined || dataArray[item]["onPremiseVirtualPowerUsageAfter"] === "" ? null : parseFloat(dataArray[item]["onPremiseVirtualPowerUsageAfter"]).toFixed(3),
                                            "publicPowerUsageBefore": dataArray[item]["publicCloudPowerUsageBefore"] === undefined || dataArray[item]["publicCloudPowerUsageBefore"] === "" ? null : parseFloat(dataArray[item]["publicCloudPowerUsageBefore"]).toFixed(3),
                                            "publicPowerUsageAfter": dataArray[item]["publicCloudPowerUsageAfter"] === undefined || dataArray[item]["publicCloudPowerUsageAfter"] === "" ? null : parseFloat(dataArray[item]["publicCloudPowerUsageAfter"]).toFixed(3),
                                            "cfPhysicalBefore": dataArray[item]["cfPhysicalServersBefore"] === undefined || dataArray[item]["cfPhysicalServersBefore"] === "" ? null : parseFloat(dataArray[item]["cfPhysicalServersBefore"]).toFixed(3),
                                            "cfPhysicalAfter": dataArray[item]["cfPhysicalServersAfter"] === undefined || dataArray[item]["cfPhysicalServersAfter"] === "" ? null : parseFloat(dataArray[item]["cfPhysicalServersAfter"]).toFixed(3),
                                            "cfPrivateBefore": dataArray[item]["cfPrivateCloudServersBefore"] === undefined || dataArray[item]["cfPrivateCloudServersBefore"] === "" ? null : parseFloat(dataArray[item]["cfPrivateCloudServersBefore"]).toFixed(3),
                                            "cfPrivateAfter": dataArray[item]["cfPrivateCloudServersAfter"] === undefined || dataArray[item]["cfPrivateCloudServersAfter"] === "" ? null : parseFloat(dataArray[item]["cfPrivateCloudServersAfter"]).toFixed(3),
                                            "cfPublicBefore": dataArray[item]["cfPublicCloudServersBefore"] === undefined || dataArray[item]["cfPublicCloudServersBefore"] === "" ? null : parseFloat(dataArray[item]["cfPublicCloudServersBefore"]).toFixed(3),
                                            "cfPublicAfter": dataArray[item]["cfPublicCloudServersAfter"] === undefined || dataArray[item]["cfPublicCloudServersAfter"] === "" ? null : parseFloat(dataArray[item]["cfPublicCloudServersAfter"]).toFixed(3),
                                            "carbonFootprint": dataArray[item]["carbonFootprint"] === undefined || dataArray[item]["carbonFootprint"] === "" ? null : parseFloat(dataArray[item]["carbonFootprint"]).toFixed(3),
                                            "assumptions": dataArray[item]["assumptions"] === undefined || dataArray[item]["assumptions"] === "" ? '' : dataArray[item]["assumptions"],
                                            "projectId": projectId,
                                            "dataCentreFactor": {
                                                "generation": dataArray[item]["generation"],
                                                "tnd": dataArray[item]["tnd"],
                                                "year": dataArray[item]["year"]
                                            },
                                            "country": {
                                                "name": item
                                            },
                                            "dataCentreSourceType": {
                                                "name": dataCentreSourceType[dataArray[item]["dataSource"]]
                                            }
                                        }
                                    ]
                                };
                            } else {
                                props.toastMessage({ state: true, header: "Alert", body: "Please enter the input values before saving" });
                                return false;
                            }
                        }
                        axios.post(`${basePath}/calculator/dataCentre`, jsonData, {
                            headers: {
                                authorization: `Bearer ${props.login.token}`
                            }
                        })
                            .then(res => {
                                sTime = new Date();
                                setIsDataPresent(true);
                                setSavedTime(sTime);
                                props.calculateSummary();
                                props.toastMessage({ state: true, header: "Success", body: "Data centre calculator data saved!" });
                            })
                            .catch(err => {
                                props.toastMessage({ state: true, header: "Error", body: "Server Error!" });
                            });
                    });
                } else {
                    props.toastMessage({ state: true, header: "Alert", body: "Please select a country in data centre calculator and fill in the details!" });
                }
            } else {
                props.toastMessage({ state: true, header: "Alert", body: "Please select a country in data centre calculator and fill in the details!" });
            }
        } else {
            props.toastMessage({ state: true, header: "Alert", body: "Please fill the project details first and then save the calculators individually!" })
        }
    }

    const setUnsetCountry = (val, parent) => {
        currentParent = parent;
        if (val !== null) {
            setSelectedCountries(val);
        } else {
            setSelectedCountries([]);
        }
    }

    const calulateTotalCF = (val) => {
        if (collapseState[val.name].parent === val.parent) {
            dispatchCollapseState({ type: "COLLAPSE_SET_CF", payload: { name: val.name, cf: val.cf } });
        }
    }

    const deleteCurrentCalculator = () => {
        if (isMountedRef.current) {
            if (projectId !== undefined && projectId !== "") {
                axios.delete(`${basePath}/calculator/dataCentre/${projectId}`, {
                    headers: {
                        authorization: `Bearer ${props.login.token}`
                    }
                })
                    .then(res => {
                        if (isMountedRef.current) {
                            props.onDelete(componentId);
                            props.calculateSummary();
                        }
                    })
                    .catch(err => {
                        props.toastMessage({ state: true, header: "Alert", body: "Data centre cannot be deleted! Server Error!" });
                    });
            } else {
                if (isMountedRef.current) {
                    props.onDelete(componentId);
                }
            }
        }
    }

    const formatGroupLabel = (data) => {
        if (data.label !== "top") {
            return (
                <hr style={{ left: "-10px", height: "0.5px", backgroundColor: "#f0f0f0", margin: "0px", padding: "0px", marginTop: "-10px" }} />
            )
        } else {
            return false;
        }
    };

    const handleSave = () => {
        handleSubmit(saveData)();
        setClickedSave(true);
    }

    useEffect(() => {
        if (isMountedRef.current) {
            projectId = props.projectId || props.project.currentProjectId;
        }
    }, [props.projectId, props.project.currentProjectId]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (selectedCountries.length > 0) {
                Object.keys(collapseState).forEach(k => {
                    var found = false;
                    for (const item of selectedCountries) {
                        if (k === item.value) {
                            found = true;
                        }
                    }
                    if (!found) {
                        dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                    }
                });
                for (const item of selectedCountries) {
                    if (!collapseState.hasOwnProperty(item.value)) {
                        dispatchCollapseState({ type: "COLLAPSE_ADD", payload: { name: item.value, parent: currentParent } });
                    }
                }
            } else {
                Object.keys(collapseState).forEach(k => {
                    dispatchCollapseState({ type: "COLLAPSE_REMOVE", payload: { name: k } });
                });
            }
        }
        // eslint-disable-next-line
    }, [selectedCountries])

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.master.status === true) {
                var data = {}
                if (Object.keys(dataCentreSourceType).length === 0) {
                    for (const fst of props.master.dataCentreSourceType) {
                        data[camelize(fst.value)] = fst.value
                    }
                    setDataCentreSourceType(data);
                }
                if (countries.length === 0) {
                    var tempGroup = [];
                    let temp = [];
                    for (const c of Object.keys(mainCountries)) {
                        if (props.master.electricityFactorMaster.hasOwnProperty(c)) {
                            temp.push({ value: c, label: c });
                        }
                    }
                    tempGroup.push(
                        {
                            label: "top",
                            options: temp
                        }
                    );
                    temp = [];
                    for (const c of props.master.country) {
                        if (props.master.electricityFactorMaster.hasOwnProperty(c.value) && !mainCountries.hasOwnProperty(c.value) && c.value !== "Other") {
                            temp.push({ value: c.value, label: c.value });
                        }
                    }
                    temp.push({ value: "Other", label: "Other" });
                    tempGroup.push(
                        {
                            label: "bottom",
                            options: temp
                        }
                    );
                    setCountries(tempGroup);
                }
                if (Object.keys(sources).length === 0) {
                    for (const efm of Object.keys(props.master.electricityFactorMaster)) {
                        for (const ft of Object.keys(props.master.electricityFactorMaster[efm])) {
                            if (ft.split("Source").length > 1) {
                                if (!years.includes(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"])) {
                                    years.push(props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]);
                                }

                                if (sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] === undefined) {
                                    sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]] = { source: [] };
                                }
                                if (!sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.includes(props.master.electricityFactorMaster[efm][ft])) {
                                    sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.push(props.master.electricityFactorMaster[efm][ft]);
                                    sources[props.master.electricityFactorMaster[efm][ft.split("Source")[0] + "Year"]].source.sort();
                                }
                            }
                        }
                    }
                    years = years.sort((a, b) => a - b);
                }
            }
        }
        // eslint-disable-next-line
    }, [props.master.status, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (props.data !== undefined && props.data.data.length > 0) {
                if (props.data.data[0].dataCentreSourceType.name === "Migration") {
                    data["dataSource"] = "migration";
                    setKey(`${componentId}-migration`);
                }
            } else {
                countryData = {};
                setKey(`${componentId}-migration`);
                setIsDataPresent(false);
            }
        }
        // eslint-disable-next-line
    }, [isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            var total = 0;
            Object.keys(collapseState).forEach(key => {
                if (typeof collapseState[key].cf === "object" && collapseState[key].cf.value !== undefined && collapseState[key].cf.value !== "" && collapseState[key].status === true) {
                    total += parseFloat(collapseState[key].cf.value);
                }
            });
            setTotalCarbonFootprint(parseFloat(total).toFixed(3));
        }
    }, [collapseState, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            props.onCFUpdate({ name: componentId, cf: totalCarbonFootprint });
        }
        // eslint-disable-next-line
    }, [totalCarbonFootprint, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && savedTime !== "") {
                sTime = savedTime;
                setMonitorSavedTime(true);
                setShouldRender(true);
            } else {
                sTime = new Date();
                setMonitorSavedTime(false);
                if (props.type === "new") {
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [savedTime, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isDataPresent === true && monitorSavedTime === true) {
                setLastSaved(timeDiffCalc(new Date(), sTime));
                setInterval(() => {
                    if (isMountedRef.current) {
                        if (monitorSavedTime === true) {
                            setLastSaved(timeDiffCalc(new Date(), sTime));
                        }
                    }
                }, 1000);
            } else {
                setLastSaved("");
            }
        }
        // eslint-disable-next-line
    }, [monitorSavedTime, isMountedRef])

    useEffect(() => {
        if (isMountedRef.current) {
            if (refresh === 0) {
                // console.log("Data Centre Component Added");
                setRefresh(prev => ++prev);
            }
        }
    }, [refresh, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (key !== "") {
                if (props.data !== undefined && props.data.data.length > 0) {
                    data["data"] = {};
                    var sCountries = [];
                    var st = "";
                    countryData = {};
                    props.data.data.forEach(obj => {
                        var fSourceType = "";
                        countryData = {
                            ...countryData,
                            [obj.country.name]: obj
                        }
                        switch (obj.dataCentreSourceType.name) {
                            case "Migration":
                                fSourceType = "migration";
                                break;
                            default:
                                fSourceType = "migration";
                                break;
                        }
                        dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: obj.country.name, parent: fSourceType, status: true } });
                        for (const gc of countries) {
                            for (const c of gc.options) {
                                if (c.value === obj.country.name) {
                                    sCountries.push(c);
                                    break;
                                }
                            }
                        }
                        if (fSourceType === "migration") {
                            setValue(`${componentId}-migration-${obj.country.name}-physical-before`, obj.physicalServerBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-physical-after`, obj.physicalServerAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-private-before`, obj.privateServerBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-private-after`, obj.privateServerAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-public-before`, obj.publicServerBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-public-after`, obj.publicServerAfter);

                            setValue(`${componentId}-migration-${obj.country.name}-small-before`, obj.smallPhysicalServerBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-small-after`, obj.smallPhysicalServerAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-medium-before`, obj.mediumPhysicalServerBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-medium-after`, obj.mediumPhysicalServerAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-large-before`, obj.largePhysicalServerBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-large-after`, obj.largePhysicalServerAfter);

                            setValue(`${componentId}-migration-${obj.country.name}-physicalUtilization-before`, obj.physicalServerUtilizationBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-physicalUtilization-after`, obj.physicalServerUtilizationAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-privateUtilization-before`, obj.privateServerUtilizationBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-privateUtilization-after`, obj.privateServerUtilizationAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-publicUtilization-before`, obj.publicServerUtilizationBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-publicUtilization-after`, obj.publicServerUtilizationAfter);

                            setValue(`${componentId}-migration-${obj.country.name}-premisePUE-before`, obj.premisePueBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-premisePUE-after`, obj.premisePueAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-publicPUE-before`, obj.publicPueBefore);
                            setValue(`${componentId}-migration-${obj.country.name}-publicPUE-after`, obj.publicPueAfter);
                            setValue(`${componentId}-migration-${obj.country.name}-assumptions`, obj.assumptions);
                        }
                        st = obj.createdAt;
                    });
                    setSelectedCountries(sCountries);
                    setSavedTime(st);
                    setIsDataPresent(true);
                    setShouldRender(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [key, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            if (clickedSave === true && Object.keys(errors).length > 0) {
                props.toastMessage({ state: true, header: "Alert", body: "Please fill in the required fields in a correct format!" });
                setClickedSave(false);
            }
        }
    }, [clickedSave, errors, isMountedRef]);

    const ColoredLine = ({ color, height, styles }) => (
        <hr
            style={{
                backgroundColor: color,
                height: height,
                ...styles
            }}
        />
    );

    return (
        <React.Fragment>
            {
                shouldRender ? (
                    <>
                        <div style={{ background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)", marginBottom: "15px" }} >
                            <div style={{ borderTopWidth: "1px", borderTopStyle: "solid", borderTopColor: "#66FFCC", padding: "0px", position: "sticky", top: "50px", zIndex: 2, background: "linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18)" }}>
                                <p style={{ padding: "10px", textAlign: "left", fontSize: "25px", marginBottom: "5px" }}>
                                    <span>Data Centre</span><span style={{ marginLeft: "20px", fontSize: "10px", fontStyle: "italic" }}>Calculator sponsored by the North American CSR team</span>
                                    <Link className={"calculatorActionDelete"} disabled={!formState.isValid} to="/calculator" style={{ float: "right" }} onClick={() => deleteConfirm("Do you want to delete Data Centre calculator ?", "Delete", "Cancel", deleteCurrentCalculator)}>Delete</Link>
                                    <Link className={"calculatorActionSave"} to="/calculator" style={{ float: "right", marginRight: "30px" }} onClick={handleSave} >Save</Link>
                                    <span style={{ float: "right", color: "rgb(108 117 125)", fontSize: "12px", paddingTop: "3px", marginRight: "30px" }}>{lastSaved}</span>
                                </p>
                            </div>
                            <p style={{ paddingTop: "0px", paddingLeft: "10px", paddingRight: "10px", textAlign: "justify", fontSize: "12px" }}>The carbon calculator provides a high level estimate of the carbon emissions from data centres and cloud migration. The CCIC is not a replacement for Clear Sights IT Decision Maker which is a Capgemini offering which provides a more detailed view of the IT estate and its carbon emissions. For more information please visit
                                <a href="https://x-port.capgemini.com/group-priority-offer/cloud-transformation-advisory-services/eapm/" target="_blank" style={{ color: "#ffffff" }}> <u>https://x-port.capgemini.com/group-priority-offer/cloud-transformation-advisory-services/eapm/</u></a>
                            </p>
                            <div className="dataCentreTabs">
                                <Tabs
                                    id={`${componentId}-dataCentreTabs`}
                                    activeKey={key}
                                    onSelect={(k) => {
                                        if (key === "") {
                                            setKey(k);
                                        }
                                    }}
                                >
                                    <Tab eventKey={`${componentId}-migration`} title="Migration" disabled={key !== "" && key !== `${componentId}-migration`} key={`${componentId}-migration`}>
                                        <Tab.Container>
                                            <ColoredLine color="#161616" height="1.5px" styles={{ margin: "0px" }} />
                                            <Row>
                                                <Col sm={12} style={{ textAlign: "left", paddingTop: "10px" }}>
                                                    <Controller
                                                        render={({ onChange, onBlur, value, name }) => (
                                                            <Select name={name} label={"Country"} formatGroupLabel={formatGroupLabel} closeMenuOnSelect={true} isMulti={true} onChange={(val) => { setUnsetCountry(val, "migration"); return onChange(val) }} onBlur={onBlur} value={selectedCountries} loadOptions={getCountryOptionsData} defaultOptions={true} labelStyle={{ fontWeight: "300", fontSize: "12px", paddingLeft: "12px", marginBottom: "0px" }} formGroupStyle={{ zIndex: 10, width: "auto", flexGrow: 1, marginLeft: "10px", marginRight: "10px", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", borderRadius: 0 }} style={{ borderWidth: "0px", borderBottomWidth: "0px", borderRadius: 0, borderColor: "transparent", borderBottomColor: "white", height: "auto", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #212121", fontSize: "10px", color: "rgba(255, 255, 255, 0.87)" }} optionStyle={{ color: "rgba(255, 255, 255, 0.87)", background: "linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212", zIndex: 10 }} onClickColor="#66FFCC80" singleValueFontSize={"12px"} />
                                                        )}
                                                        control={control}
                                                        name="country2"
                                                        defaultValue={''}
                                                    />
                                                </Col>
                                                <Col sm={12}>
                                                    <Nav variant="pills" id={`${componentId}-dataCentreMigrationPills`} className="flex-row dataCentrePills">
                                                        {
                                                            Object.keys(collapseState).map(item => {
                                                                return (
                                                                    <Nav.Item key={`${componentId}-migration-${item}-collapsePills`}>
                                                                        <Nav.Link eventKey={item} active={collapseState[item].parent === "migration" && collapseState[item].status === true} disabled={collapseState[item].parent !== "migration" && collapseState[item].status === true} aria-controls={`${componentId}-migration-${item}-collapse`} onClick={() => dispatchCollapseState({ type: "COLLAPSE_SET_PARENT_STATUS", payload: { name: item, parent: "migration", status: !collapseState[item].status } })}>{`${item.charAt(0).toUpperCase()}${item.slice(1)}`}</Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            })
                                                        }
                                                    </Nav>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="dataCentrePillsCollapse">
                                                        {
                                                            Object.keys(collapseState).map(item => (
                                                                <CollapseDataCentreMigration
                                                                    key={`${componentId}-migration-${item}-collapse`}
                                                                    collapseControll={getLogic(item, "migration")}
                                                                    data={countryData[item]}
                                                                    label={`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                                                    id={`${componentId}-migration`}
                                                                    inputPlaceholder={`0`}
                                                                    inputName={item}
                                                                    control={control}
                                                                    register={register}
                                                                    errors={errors}
                                                                    cFootprint={(val) => calulateTotalCF(val)}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Tab>
                                    {/* )
                            } */}
                                </Tabs>
                            </div>
                        </div>
                        <div className="dataCentreBottom" >
                            <div className="content" >
                                <Row>
                                    <Col sm={12} md={5} lg={4}>
                                        <h5 style={{ lineHeight: "0.3", paddingTop: "5px", fontWeight: "normal" }}>Total</h5>
                                        <span style={{ fontSize: "12px", fontWeight: "normal" }}>{totalCarbonFootprint >= 0 ? 'reduction in data centre consumption' : 'data centre emission'}</span>
                                    </Col>
                                    <Col sm={12} md={7} lg={8}>
                                        <h1 style={{ lineHeight: "0.5", paddingTop: "5px" }} className="numberType">{numberWithCommas(totalCarbonFootprint)}</h1>
                                        <span style={{ fontSize: "10px", fontWeight: "normal" }}>tonnes CO<sub>2</sub>e / year</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12} style={{ textAlign: "left", color: "#a6a6a6", letterSpacing: "0.4px", fontSize: "12px" }} >
                                        {
                                            (
                                                Object.keys(sources).length > 0 && years.length > 0
                                            ) ? (
                                                <p>
                                                    <span>This calculation was done using reference data from</span>
                                                    <span style={{ color: "#f0f0f0" }}>
                                                        {
                                                            years.map(year => {
                                                                return sources[year].source.map(source => ` ${source} ${year}${years[years.length - 1] === year && sources[year].source[sources[year].source.length - 1] === source ? "" : ","}`)
                                                            })
                                                        }
                                                    </span>
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        master: state.master,
        project: state.project
    };
}

export default errorBoundaryWrapper(ErrorBoundaryForPage, connect(mapStateToProps)(DataCentreTabs));